<div class="espacamento">
    <div class="flex flex-column   align-items-center  pb-3">
        <img src="./assets/logo.svg" />
        <div class=" fontcinza">
            <div class="flex align-items-center justify-content-center">
                <span>{{nomeapp}}</span>
            </div>
            <div class="flex align-items-center justify-content-center">
                <span> {{site}}</span>
            </div>
        </div>
    </div>


    <div class="flex flex-column   align-items-center  pb-3">
        <div class=" fontcinza">
            <div class="flex align-items-center justify-content-center">
                <span> Suporte: {{telefone1}} / {{telefone2}}</span>
            </div>
            <div class="flex align-items-center justify-content-center">
                <span> {{email1}}</span>
            </div>
            <div class="flex align-items-center justify-content-center">
                <span> {{email2}}</span>
            </div>
        </div>
    </div>
</div>

<div class="flex flex-column   align-items-center  pb-3">
    <div class="flex align-items-center justify-content-center fontcinza">
        <span> 2023</span>
    </div>
    <div class="flex align-items-center justify-content-center fontcinzanegrito">
        <span> Versão {{versao}}</span>
    </div>
</div>

<div class="xl:flex xl:justify-content-center xl:flex-wrap">

    <div class=" flex align-items-center justify-content-center xl:col-5 ">
        <button id="botaoSobre" pButton type="button" label="Termos e Política" class="col-12" (click)="mostraSobre()"></button>
    </div>
</div>

<!--  DIALOG DO SOBRE      -->
<p-dialog id="dialogSobre" header="Termos" [(visible)]="dialog" [modal]="true" [style]="{'width': '80%'}">
    {{termos}}



</p-dialog>