import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { AlertService } from 'src/alert.service';
import { Estabelecimento } from 'src/assets/estabelecimento';
import { Cliente } from 'src/assets/cliente';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { Produto } from 'src/assets/produto';
import { UtilitariosService } from 'src/assets/utilitarios.service';
import { EstabelecimentoService } from 'src/estabelecimento.service';
import { DescontoService } from 'src/desconto.service';
import { ParceirosService } from 'src/parceiros.service';
import { ProdutoService } from 'src/produto.service';
import { TraducaoService } from 'src/traducao.service';
import { UsuarioService } from 'src/usuario.service';
import { VendaService } from 'src/venda.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-venda-direta',
  templateUrl: './venda-direta.component.html',
  styleUrls: ['./venda-direta.component.scss'],
  providers: [MessageService],
  encapsulation: ViewEncapsulation.None
})
export class VendaDiretaComponent implements OnInit {
  temDescontoCarrinho: boolean = false;

  /**MENU */
  activeIndex: number = 0;

  showResumoPedido: boolean = false;

  /**VARIAVEIS DO COMPONENT */
  codemp: number = 0;
  codcli: number = 0;
  codusu: string = '';
  total: number = 0;
  teveRespostaEstabelecimento: boolean = false;
  estabelecimento: Estabelecimento[] = [];
  estabelecimentoSelec: any;
  cliente: Cliente[] = [];
  clienteSelec: any = [];
  descontoManual: any;
  detalhes: string = '';
  nuid: any;
  codfunc: any;
  titulo: string = '';

  descontoAcumulado: number = 0; //variavel para somar o valor de todos os descontos
  vlrTotalComDesconto: number = 0;
  descontoTotal: number = 0;
  totalGeral: number = 0;

  carrinho: any[] = [];
  //carrinho: any[] = [];
  itensVenda: any[] = [];

  venda: any;

  qtdItens: number = 0;


  /**VARIAVEIS PRODUTO */
  produtos: Produto[] = [];

  resMobile: boolean = false;

  constructor(public translate: TranslateService, public primeNGConfig: PrimeNGConfig, private decodedTokenService: DecodedTokenService, private estabelecimentoService: EstabelecimentoService,
    private messageService: MessageService, private router: Router, private alertService: AlertService, private traducaoService: TraducaoService, private descontoService: DescontoService,
    private produtoService: ProdutoService, private parceirosService: ParceirosService, private vendaService: VendaService, private usuarioService: UsuarioService,
    private utilitariosService: UtilitariosService) {

    /**TRADUÇÃO */
    this.traducaoService.changeLang();

    this.verificaResolucao();
  }

  ngOnInit(): void {
    this.recuperaCodUsu();
    this.recuperaCodCli();
    this.recuperaCodfunc();
    this.recuperaVenda();
    // this.recuperaProdutos();
    this.buscarEstabelecimentosPorCodCliente();
  }

  /**METODO QUE VERIFICA A RESOLUÇÃO E APRESENTA O CAMPO DE ACORDO COM ELA */
  verificaResolucao() {
    if (screen.width < 1024 || screen.height < 768) {
      this.resMobile = true;
    } else {
      this.resMobile = false;
    }
    window.addEventListener('resize', () => {
      if (screen.width < 1024 || screen.height < 768) {
        this.resMobile = true;
      } else {
        this.resMobile = false;
      }
    });
  }

  /** METODO PARA FAZER A BUSCA DE CLIENTES/PARCEIROS */
  buscarProdutos() {
    let filter = (document.getElementById('pesquisa') as HTMLInputElement)!.value.toUpperCase();
    let myTable = document.getElementById('tabela');
    let tr = myTable?.getElementsByTagName('tr');
    for (let i = 0; i < tr!.length; i++) {
      let td = tr![i].getElementsByTagName('td')[0];
      if (td) {
        let textv = td.textContent || td.innerHTML;
        if (textv.toUpperCase().indexOf(filter) > -1) {
          console.log(textv)
          tr![i].style.display = "";
        } else {
          tr![i].style.display = "none";
        }
      }
    }
  }


  /**RECUPERA O CODCLI PARA FAZER AS BUSCA DE PRODUTOS, BARBEARIAS, CLIENTES... */
  recuperaCodCli() {
    this.codcli = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODCLI;
  }

  /**METODO QUE RECUPERA O CODUSU DO JWT */
  recuperaCodUsu() {
    this.codusu = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }


  recuperaProdutos() {
    this.produtoService.buscarProdutosPorCodCli(this.codcli, this.codusu).subscribe((res) => {
      this.produtos = res;

      /**STARTA O CARRINHO COMO 0 */
      for (let i = 0; i < this.produtos.length; i++) {
        this.produtos[i].qtdneg = 0;

        if (this.produtos[i].filename! === '0' || this.produtos[i].filename! === undefined || this.produtos[i].filename! === null) {
          this.produtos[i].filename = './assets/imgMascaraInformativo.png';
         // this.produtos[i].filename = './assets/FotoPadraoEmp.png'
        } else {
          this.produtos[i].filename = environment.imgProduto + this.produtos[i].filename;
        }
      }





      /********************************************************************************
      *                                     VENDA EDITADA                             *
      ********************************************************************************/
      if (this.nuid) {
        this.itensVenda = JSON.parse(this.venda.json).itens
        console.log(this.itensVenda)
        for (let i = 0; i < this.produtos.length; i++) {
          for (let j = 0; j < this.itensVenda.length; j++) {
            if (this.itensVenda[j].codprod === this.produtos[i].codprod) {
              this.produtos[i].qtdneg = this.itensVenda[j].qtdneg;
              this.somaTotal(this.itensVenda[j]);
            }
          }
        }
        // this.somaTotal(this.itensVenda[j]);
      }

    });
  }



  /**METODO QUE SOMA O VALOR DO CARRINHO */
  somaTotal(produtos: any) {
    this.qtdItens = 0;
    this.total = 0;


    //if (!this.nuid) {



    /*FILTRO QUE PERMITE INSERIR APENAS ITENS UNICOS NO CARRINHO */
    this.carrinho = this.carrinho.filter((x: any) => x.codprod != produtos.codprod)
    this.carrinho.push(produtos)

    /**RETIRA OS ITENS QUE A QUANTIDADE ESTÁ 0 */
    this.carrinho = this.carrinho.filter((x: any) => x.qtdneg > 0)


    /**SOMA O TOTAL E A QTD DE ITENS DO CARRINHO */
    for (let i = 0; i < this.carrinho.length; i++) {
      if (this.carrinho[i].qtdneg > this.carrinho[i].saldo) {
        this.showError('Atenção', 'A quantidade selecionada excede o estoque disponível');
        this.carrinho[i].qtdneg -= 1;
        console.log('epaa')
      }


      this.qtdItens += this.carrinho[i].qtdneg;
      this.total += this.carrinho[i].preco * this.carrinho[i].qtdneg;
    }

    this.totalGeral = this.total;

    this.calculaDesconto();
    /**-------------------- */
    // this.carrinho.push(produtos)
    // console.log(this.carrinho)
    //  this.carrinho = [...new Set(this.carrinho)]
    // console.log(this.carrinho)

    // this.carrinho = this.carrinho.filter((x: any) => x.qtdneg > 0)

    // for (let i = 0; i < this.carrinho.length; i++) {
    //   this.qtdItens += this.carrinho[i].qtdneg;
    //   this.total += this.carrinho[i].preco * this.carrinho[i].qtdneg;
    // }

    // console.log(this.carrinho)

    // console.log(this.produtos)
    /**-------------------- */



    // }else{
    //   for (let i = 0; i < produtos.length; i++) {
    //     this.qtdItens += produtos[i].qtdneg;
    //     this.total += produtos[i].preco * produtos[i].qtdneg;
    //   }
    // }
    //console.log( this.carrinho)

  }

  /**CALCULA O VALOR APOS O DESCONTO DIGITADO */
  calculaDesconto() {
    /**SE O DESCONTO FOR MAIOR QUE O VALOR DE VENDA, ZERA ELE */

    //  console.log(JSON.parse(this.venda.json))

    if (this.descontoManual > this.total) {
      this.vlrTotalComDesconto = 0;
      this.descontoManual = 0;
      this.temDescontoCarrinho = false;
      this.showError('Atenção', 'O valor de desconto não pode ser maior que a venda.')
      return
    }

    if (this.descontoManual > 0) {
      this.vlrTotalComDesconto = this.total - this.descontoManual;
      this.temDescontoCarrinho = true;
    } else {
      this.temDescontoCarrinho = false;
    }
    /**ADICIONA O DESCONTO DIGITADO AO DESCONTO GERAL QUE SERÁ ENVIADO AO BANCO */
    console.log(this.descontoTotal)
    this.descontoTotal = this.descontoManual;
    console.log(this.descontoTotal)
    // this.total -= this.descontoManual;
    console.log(this.vlrTotalComDesconto)
  }

  /**METODO QUE CRIA O TITULO A SER ENVIADO NA VENDA */
  criaTitulo() {
    var auxTitulo = []
    console.log(this.carrinho)
    for (let i = 0; i < this.carrinho.length; i++) {
      if (this.carrinho[i]) {

        if (i == 0) {
          auxTitulo[i] = this.carrinho[i].nomeprod;
        } else {
          auxTitulo[i] = ' ' + this.carrinho[i].nomeprod;
        }
      }
    }
    this.titulo = auxTitulo.toString()
    console.log(this.titulo)
  }

  /**RECUPERA OS DADOS DA VENDA A SER EDITADA */
  recuperaVenda() {
    this.venda = this.vendaService.getVenda();
    if (this.venda != undefined) {
      this.nuid = this.venda.nuid;
      this.estabelecimentoSelec = this.venda.codemp;
      this.detalhes = this.venda.obs;
      this.titulo = this.venda.titulo;
      /**RECUPERA O DESCONTO DIGITADO MANUALMENTE */
      if (this.nuid) {
        let auxJson = JSON.parse(this.venda.json);
        this.descontoManual = auxJson.descManual;
        console.log(JSON.parse(this.venda.json))
      }

      /**RECUPERA OS CLIENTES DA BARBEARIA SELECIONADA */
      this.buscaClientesPorCodemp();

      //this.clienteSelec = this.venda.codusu;

    }
    this.recuperaProdutos();
  }


  /**METODOS DO MENU */
  irPagina2() {
    // if (this.barbeiroSelecionado.codfunc === undefined) {
    //   this.showError('Atenção', 'Selecione um barbeiro.')
    //   //this.alertService.showError1('Atenção', 'Selecione um barbeiro.');
    // }
    // else {


    if (this.qtdItens === 0) {
      this.showError('Atenção', 'Selecione um produto.');
      return;
    }

    this.activeIndex = this.activeIndex + 1;
    //}
  }

  voltarPagina() {
    this.activeIndex = this.activeIndex - 1;
    // if (this.activeIndex === 1) {
    //   this.total += this.desconto;

    /**AO VOLTAR A PAGINA, RETIRA OS VALORES COM DESCONTO MANUAL (APENAS EM NOVAS VENDAS) */
    if (!this.nuid) {
      this.descontoTotal -= this.descontoManual;
      this.vlrTotalComDesconto += this.descontoManual;
      this.descontoManual = 0;

      /**ENQUANTO NÃO TEM DESCONTO NOS PRODUTOS NÃO PRECISA DE VALIDAÇÃO */
      this.temDescontoCarrinho = false;
    }





    //   this.somaTotal();
    // }
  }


  /**METODO QUE BUSCA AS BARBEARIAS PARA FAZER A VENDA */
  buscarEstabelecimentosPorCodCliente() {
    this.estabelecimentoService.buscarEstabelecimentosporCodCli(this.codcli, this.codusu).subscribe((res) => {
      console.log(res);
      this.estabelecimento = res;
    });
  }

  /**METODO QUE BUSCA OS CLIENTES DE ACORDO COM A BARBEARIA SELECIONADA, PARA FAZER A VENDA */
  buscaClientesPorCodemp() {
    this.teveRespostaEstabelecimento = true;
    this.parceirosService.buscaClientesGeralPorCodEmp(this.estabelecimentoSelec, this.codusu).subscribe((res) => {
      console.log(res);
      this.cliente = res;
      this.teveRespostaEstabelecimento = false;


      /**ATRIBUI O CLIENTE NA EDIÇÃO DE VENDA */
      if (this.venda != undefined) {
        console.log(this.cliente.filter((x: any) => x.codusu === this.venda.codusu))
        this.clienteSelec = this.cliente.filter((x: any) => x.codusu === this.venda.codusu)[0]
      }
    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
        this.teveRespostaEstabelecimento = false;
      });
  }


  /**METODO QUE RECUPERA O CODFUNC QUE ESTÁ REALIZANDO A VENDA */
  recuperaCodfunc() {
    this.usuarioService.getPerfil(this.codusu, this.codusu).subscribe((res) => {
      this.codfunc = Object(res)[0].codfunc;
    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
      });
  }

  /**METODO QUE CRIA/EDITA A VENDA */
  criaVenda() {

    if (this.utilitariosService.validaCampo([this.clienteSelec, this.estabelecimentoSelec])) {
      console.log('É necessário preencher todos os campos.')
      this.showError('Atenção', 'Ainda há campos para serem preenchidos.')
      return;
    }

    // if (!(this.codfunc > 0)) {
    //   console.log('É necessário preencher todos os campos.')
    //   this.showError('Atenção', 'Ação permitida apenas para funcionários.')
    //   return;
    // }


    /**NÃO PERMITE QUE SEJA ENVIADOS VALORES NULL DE DESCONTO PARA O BANCO */
    if (this.descontoManual === undefined) {
      this.descontoManual = 0;
    }
    if (this.descontoTotal === undefined) {
      this.descontoTotal = 0;
    }


    /**DEBITA OS DESCONTOS DO TOTAL A SER ENVIADO */
    this.total -= this.descontoManual;


    /**PEGA O DIA DE HOJE */
    var hoje: any = new Date();
    var dd = String(hoje.getDate()).padStart(2, '0');
    var mm = String(hoje.getMonth() + 1).padStart(2, '0');
    var yyyy = hoje.getFullYear();
    hoje = yyyy + '-' + mm + '-' + dd;


    /*LEMBRAR DE VOLTAR O CODFUNC*/
    // this.vendaService.createVenda(this.nuid, hoje, this.codfunc, this.clienteSelec.codusu, this.estabelecimentoSelec, this.total, 'VENDA', this.carrinho,
    this.vendaService.createVenda(this.nuid, hoje, 8, this.clienteSelec.codusu, this.estabelecimentoSelec, this.total, 'VENDA', this.carrinho,
      this.titulo, this.clienteSelec.nomeparc, this.detalhes, this.codusu, this.descontoManual, this.descontoManual).
      subscribe((res) => {
        this.alertService.alert('Sua venda foi realizada com sucesso!', 'success');
        setTimeout(() => {
          this.router.navigate(['/pedidos']);
        }, 1500)
        console.log(res);
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')
        });
  }


  /**METODO DE TOAST */
  showError(titulo: string, mensagem: string) {
    if (screen.width < 640 || screen.height < 480) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else if (screen.width < 1024 || screen.height < 768) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else {
      this.messageService.add({
        key: 'tr',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    }
  }

  mostraDialogResumoPedido(){
    this.showResumoPedido = true;

  }

  fechaDialogResumoPedido(){
    this.showResumoPedido = false;
  }

  print(){
    console.log(this.clienteSelec)
  }
}
