import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { AgendaService } from 'src/agenda.service';
import { AlertService } from 'src/alert.service';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { UtilitariosService } from 'src/assets/utilitarios.service';
import { UsuarioService } from 'src/usuario.service';
import { VendaService } from 'src/venda.service';

@Component({
  selector: 'app-pedidos',
  templateUrl: './pedidos.component.html',
  styleUrls: ['./pedidos.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ConfirmationService, MessageService],
})
export class PedidosComponent implements OnInit {
  itemsVendas: MenuItem[] = [];

  /**VARIAVEIS DO COMPONENT */
  codcli: number = 0;
  codusu: string = '';
  codfunc: number = 0;
  vendas: any;

  vendaSelec: any;

  vetorItens: any[] = [];

  loading: boolean = false;

  /**DATA */
  data: Date;
  hoje: Date;
  dataIni: Date;
  dataFin: Date;

  /**VARIAVEIS DE FILTRO */
  showAlterar: boolean = false;
  valorSelecionadoAlterar: string = '';
  liberaCalendario1: boolean = true;
  liberaCalendario2: boolean = true;
  dataInicial: any = '';
  dataFinal: any;
  opcoesFiltro: any[];
  opcoesFiltroSelecionado: string = '';
  showCarregando: boolean = false;
  auxDiaIni: any;
  auxDiaFin: any;

  constructor(private decodedTokenService: DecodedTokenService, private vendaService: VendaService, private usuarioService: UsuarioService, private router: Router,
    private messageService: MessageService, private confirmationService: ConfirmationService, private agendaService: AgendaService,
    private alertService: AlertService, private utilitariosService: UtilitariosService) {

    this.data = new Date();
    this.hoje = new Date(this.data);

    /**VARIAVEIS DO PERIODO A SER ESCOLHIDO */
    this.dataIni = new Date(this.data);
    this.dataFin = new Date(this.data);


    this.opcoesFiltro = [{ 'opcao': 'T', 'nome': 'Todos' },
    { 'opcao': 'N', 'nome': 'Pago' },
    { 'opcao': 'S', 'nome': 'Aberto' }
    ]

  }

  ngOnInit(): void {
    this.recuperaCodUsu();
    this.recuperaCodCli();
    this.recuperaCodfunc();


    this.itemsVendas = [{
      items: [{
        label: 'Editar',
        command: () => {
          this.editarVenda(this.vendaSelec)
        },
        // routerLink: ['/barbeiro'],
      }]
    },
    {
      items: [{
        label: 'Excluir',
        styleClass: 'linhaMenuPedidos',
        command: () => {
          this.confirmaCancelarVenda();
        }
      }]
    },
    {
      items: [{
        label: 'Ir p/ Financeiro',
        styleClass: 'linhaMenuPedidos',
        command: () => {
          this.redirecionaMovFinanceiro();
        }
      }]
    },
    ];
  }

  /**RECUPERA O CODCLI PARA FAZER AS BUSCA DAS VENDAS */
  recuperaCodCli() {
    this.codcli = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODCLI;
  }

  /**METODO QUE RECUPERA O CODUSU DO JWT */
  recuperaCodUsu() {
    this.codusu = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }

  /**METODO QUE BUSCA AS VENDAS POR CODCLI */
  // buscaVendas() {
  //   this.vendaService.listaVendaDireta(this.codcli, this.codfunc, this.codusu).subscribe((res) => {
  //     this.vendas = res;


  //     /**CONTA A QTD DE ITENS DE CADA VENDA */
  //     for (let i = 0; i < this.vendas.length; i++) {
  //       this.vetorItens = JSON.parse(this.vendas[i].json).itens

  //       let auxCountItens = 0;
  //       for (let j = 0; j < this.vetorItens.length; j++) {
  //         //console.log()
  //         auxCountItens += (this.vetorItens[j].qtdneg);
  //       }
  //       this.vendas[i].QTDITENS = auxCountItens;
  //     }

  //   });
  // }

  /**METODO QUE RECUPERA O CODFUNC QUE SERA USADO NA BUSCA DAS VENDAS */
  recuperaCodfunc() {
    this.usuarioService.getPerfil(this.codusu, this.codusu).subscribe((res) => {
      this.codfunc = Object(res)[0].codfunc;
      //this.codfunc = 8;
      console.log(this.codfunc, this.codcli)
      this.buscaVendasPorPeriodo();
    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
      });
  }


  /** METODO PARA FAZER A BUSCA DE CLIENTES/PARCEIROS */
  buscarPedidos() {
    let filter = (document.getElementById('pesquisa') as HTMLInputElement)!.value.toUpperCase();
    let myTable = document.getElementById('tabela');
    let tr = myTable?.getElementsByTagName('tr');
    for (let i = 0; i < tr!.length; i++) {
      let td = tr![i].getElementsByTagName('td')[0];
      if (td) {
        let textv = td.textContent || td.innerHTML;
        if (textv.toUpperCase().indexOf(filter) > -1) {
          console.log(textv)
          tr![i].style.display = "";
        } else {
          tr![i].style.display = "none";
        }
      }
    }
  }


  novaVenda() {
    /**VERIFICA SEM TEM PERMISSÃO PARA ESSA AÇÃO */
    //  if (this.cadastrar || this.codcli > 0) {
    var auxVenda: any = {}
    this.vendaService.setVenda(auxVenda);
    this.router.navigate([('/vendaDireta')]);
    // else {
    //   this.showError('Atenção', 'Ação não permitida.')
    //   return
    // }
  }

  /**METODO DE TOAST */
  showError(titulo: string, mensagem: string) {
    if (screen.width < 640 || screen.height < 480) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else if (screen.width < 1024 || screen.height < 768) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else {
      this.messageService.add({
        key: 'tr',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    }
  }

  /**SELECIONOU A VENDA PARA EDIÇÃO/EXCLUSÃO */
  vendaSelecionada(venda: any) {
    this.vendaSelec = venda;

    console.log(this.vendaSelec)
  }


  /**USA A VENDA SELECIONADA PARA SER EDITADA NA TELA SEGUINTE */
  editarVenda(venda: any) {
    /**VERIFICA SEM TEM PERMISSÃO PARA ESSA AÇÃO */
    // if ((this.alterar || this.inativar) || this.codcli > 0) {
    this.vendaService.setVenda(venda);
    this.router.navigate([('/vendaDireta')]);
    // }
    // else {
    //   this.showError('Atenção', 'Ação não permitida.')
    //   return
    // }
  }


  /**METODOS DE CANCELAR A VENDA */
  cancelarVenda() {
    this.vendaService.cancelarVenda(this.vendaSelec.nuid!, this.codusu, 'VENDA').subscribe((res) => {
      console.log(res);
      this.vendas = this.vendas.filter((x: any) => x.nuid != res.nuid); //FILTRA TUDO O QUE É DIFERENTE DO QUE FOI EXCLUIDO
    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
        this.alertService.alert('Algo deu errado.', 'error');
      });
  }


  confirmaCancelarVenda() {
    /**VERIFICA SEM TEM PERMISSÃO PARA ESSA AÇÃO */
    // if (this.excluir || this.codcli > 0) {
    this.confirmationService.confirm({
      message: 'Deseja mesmo cancelar a venda de ' + this.vendaSelec.descr! + ' ?',
      header: 'Cancelar',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.cancelarVenda();
      },
      reject: () => {
      }
    });
    // }
    // else {
    //   this.showError('Atenção', 'Ação não permitida.')
    //   return
    // }
  }

  mostraDialogPeriodoVendas() {
    this.showAlterar = true;
  }


  /**METODO QUE REDIRECIONA PARA O MOV FINANCEIRO DE ACORDO COM O NUID SELECIONADO*/
  redirecionaMovFinanceiro() {
    console.log(this.vendaSelec.nuid);
    this.agendaService.setNuid(this.vendaSelec.nuid);
    this.router.navigate([('/financeiro')]);
  }

  /**METODO QUE MUDA O PERIODO DO FILTRO DE DATAS DE PEDIDOS */
  clickOutroPeriodo() {
    console.log(this.valorSelecionadoAlterar)
    if (this.valorSelecionadoAlterar === 'outroPeriodo') {
      console.log('erwgferg')
      this.liberaCalendario1 = false;
      console.log(this.liberaCalendario1)
    } else {
      this.liberaCalendario1 = true;
      this.liberaCalendario2 = true;
      this.dataInicial = '';
      this.dataFinal = '';
    }
  }

  /**METODO QUE LIBERA O SEGUNDO CALENDARIO */
  clickCalendario1() {
    if (this.dataInicial !== '') {
      this.liberaCalendario2 = false;
      console.log(this.dataInicial)
    }
  }

  buscaVendasPorPeriodo() {
    this.showCarregando = true;
    this.loading = true;

    console.log(this.valorSelecionadoAlterar)
    if (this.valorSelecionadoAlterar === '') {
      this.valorSelecionadoAlterar = 'hoje';
    }
    console.log(this.valorSelecionadoAlterar)
    if (this.opcoesFiltroSelecionado === '') {
      this.opcoesFiltroSelecionado = 'T';
    }


    if (this.utilitariosService.validaCampo([this.dataInicial, this.dataFinal]) && this.valorSelecionadoAlterar === "outroPeriodo") {
      this.showError('Atenção', 'Selecione o período das datas.')
      this.loading = false;
      this.showCarregando = false;
      return;
    }

  


    /********************************************************************************
   *                                      HOJE                                      *
   ********************************************************************************/
    if (this.valorSelecionadoAlterar === "hoje") {

      console.log('gregertgergerge')
      /**FAZ A DATA INICIAL E FINAL SER HOJE*/
      this.dataIni = new Date(this.data);
      this.dataFin = new Date(this.data);

      // this.auxDiaIni = this.dataIni.getFullYear() + '-' + ('0' + (this.dataIni.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataIni.getDate()).slice(-2);
      // this.auxDiaFin = this.dataFin.getFullYear() + '-' + ('0' + (this.dataFin.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataFin.getDate()).slice(-2);
      this.auxDiaIni = this.utilitariosService.ajustaDataBanco(this.dataIni);
      this.auxDiaFin = this.utilitariosService.ajustaDataBanco(this.dataFin);

      this.vendaService.listaVendaDireta(this.codcli, this.codfunc, this.auxDiaIni, this.auxDiaFin, this.opcoesFiltroSelecionado, this.codusu).subscribe((res) => {
        console.log(res);

        this.vendas = res;
        /**CONTA A QTD DE ITENS DE CADA VENDA */
        for (let i = 0; i < this.vendas.length; i++) {
          this.vetorItens = JSON.parse(this.vendas[i].json).itens

          let auxCountItens = 0;
          for (let j = 0; j < this.vetorItens.length; j++) {
            //console.log()
            auxCountItens += (this.vetorItens[j].qtdneg);
          }
          this.vendas[i].QTDITENS = auxCountItens;
        }


        this.showAlterar = false;
        this.loading = false;
        this.showCarregando = false;

      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.loading = false;
          this.showAlterar = false;
          this.showCarregando = false;
          this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')
        });
    }

    /********************************************************************************
    *                                      ONTEM                                    *
    ********************************************************************************/

    if (this.valorSelecionadoAlterar === "ontem") {
      /**FAZ A DATA INICIAL SER ONTEM*/
      this.dataIni = new Date(this.data);
      this.dataIni.setDate(this.data.getDate() - 1);

      // this.auxDiaIni = this.dataIni.getFullYear() + '-' + ('0' + (this.dataIni.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataIni.getDate()).slice(-2);
      // this.auxDiaFin = this.dataFin.getFullYear() + '-' + ('0' + (this.dataFin.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataFin.getDate()).slice(-2);
      this.auxDiaIni = this.utilitariosService.ajustaDataBanco(this.dataIni);
      this.auxDiaFin = this.utilitariosService.ajustaDataBanco(this.dataFin);
      console.log(this.auxDiaIni)
      console.log(this.auxDiaFin)

      this.vendaService.listaVendaDireta(this.codcli, this.codfunc, this.auxDiaIni, this.auxDiaFin, this.opcoesFiltroSelecionado, this.codusu).subscribe((res) => {
        console.log(res);

        this.vendas = res;
        /**CONTA A QTD DE ITENS DE CADA VENDA */
        for (let i = 0; i < this.vendas.length; i++) {
          this.vetorItens = JSON.parse(this.vendas[i].json).itens

          let auxCountItens = 0;
          for (let j = 0; j < this.vetorItens.length; j++) {
            //console.log()
            auxCountItens += (this.vetorItens[j].qtdneg);
          }
          this.vendas[i].QTDITENS = auxCountItens;
        }

        this.showAlterar = false;
        this.loading = false;
        this.showCarregando = false;

      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.loading = false;
          this.showAlterar = false;
          this.showCarregando = false;
          this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')
        });
    }

    /********************************************************************************
    *                                      7 DIAS                                    *
    ********************************************************************************/
    if (this.valorSelecionadoAlterar === "7Dias") {
      /**FAZ A DATA INICIAL SER 7 DIAS ATRAS*/
      this.dataIni = new Date(this.data);
      this.dataIni.setDate(this.data.getDate() - 7);


      // this.auxDiaIni = this.dataIni.getFullYear() + '-' + ('0' + (this.dataIni.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataIni.getDate()).slice(-2);
      // this.auxDiaFin = this.dataFin.getFullYear() + '-' + ('0' + (this.dataFin.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataFin.getDate()).slice(-2);
      this.auxDiaIni = this.utilitariosService.ajustaDataBanco(this.dataIni);
      this.auxDiaFin = this.utilitariosService.ajustaDataBanco(this.dataFin);

      this.vendaService.listaVendaDireta(this.codcli, this.codfunc, this.auxDiaIni, this.auxDiaFin, this.opcoesFiltroSelecionado, this.codusu).subscribe((res) => {
        console.log(res);

        this.vendas = res;
        /**CONTA A QTD DE ITENS DE CADA VENDA */
        for (let i = 0; i < this.vendas.length; i++) {
          this.vetorItens = JSON.parse(this.vendas[i].json).itens

          let auxCountItens = 0;
          for (let j = 0; j < this.vetorItens.length; j++) {
            //console.log()
            auxCountItens += (this.vetorItens[j].qtdneg);
          }
          this.vendas[i].QTDITENS = auxCountItens;
        }

        this.showAlterar = false;
        this.loading = false;
        this.showCarregando = false;
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.loading = false;
          this.showAlterar = false;
          this.showCarregando = false;
          this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')
        });
    }


    /********************************************************************************
    *                                      15 DIAS                                   *
    ********************************************************************************/

    if (this.valorSelecionadoAlterar === "15Dias") {
      /**FAZ A DATA INICIAL SER 15 DIAS ATRAS*/
      this.dataIni = new Date(this.data);
      this.dataIni.setDate(this.data.getDate() - 15);

      // this.auxDiaIni = this.dataIni.getFullYear() + '-' + ('0' + (this.dataIni.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataIni.getDate()).slice(-2);
      // this.auxDiaFin = this.dataFin.getFullYear() + '-' + ('0' + (this.dataFin.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataFin.getDate()).slice(-2);
      this.auxDiaIni = this.utilitariosService.ajustaDataBanco(this.dataIni);
      this.auxDiaFin = this.utilitariosService.ajustaDataBanco(this.dataFin);

      this.vendaService.listaVendaDireta(this.codcli, this.codfunc, this.auxDiaIni, this.auxDiaFin, this.opcoesFiltroSelecionado, this.codusu).subscribe((res) => {
        console.log(res);

        this.vendas = res;
        /**CONTA A QTD DE ITENS DE CADA VENDA */
        for (let i = 0; i < this.vendas.length; i++) {
          this.vetorItens = JSON.parse(this.vendas[i].json).itens

          let auxCountItens = 0;
          for (let j = 0; j < this.vetorItens.length; j++) {
            //console.log()
            auxCountItens += (this.vetorItens[j].qtdneg);
          }
          this.vendas[i].QTDITENS = auxCountItens;
        }

        this.showAlterar = false;
        this.loading = false;
        this.showCarregando = false;
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.loading = false;
          this.showAlterar = false;
          this.showCarregando = false;
          this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')
        });
    }

    /********************************************************************************
    *                                      30 DIAS                                  *
    ********************************************************************************/
    if (this.valorSelecionadoAlterar === "ultimoMes") {
      /**FAZ A DATA INICIAL SER 30 DIAS ATRAS*/
      this.dataIni = new Date(this.data);
      this.dataIni.setDate(this.data.getDate() - 30);


      // this.auxDiaIni = this.dataIni.getFullYear() + '-' + ('0' + (this.dataIni.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataIni.getDate()).slice(-2);
      // this.auxDiaFin = this.dataFin.getFullYear() + '-' + ('0' + (this.dataFin.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataFin.getDate()).slice(-2);
      this.auxDiaIni = this.utilitariosService.ajustaDataBanco(this.dataIni);
      this.auxDiaFin = this.utilitariosService.ajustaDataBanco(this.dataFin);
      this.vendaService.listaVendaDireta(this.codcli, this.codfunc, this.auxDiaIni, this.auxDiaFin, this.opcoesFiltroSelecionado, this.codusu).subscribe((res) => {
        console.log(res);

        this.vendas = res;
        /**CONTA A QTD DE ITENS DE CADA VENDA */
        for (let i = 0; i < this.vendas.length; i++) {
          this.vetorItens = JSON.parse(this.vendas[i].json).itens

          let auxCountItens = 0;
          for (let j = 0; j < this.vetorItens.length; j++) {
            //console.log()
            auxCountItens += (this.vetorItens[j].qtdneg);
          }
          this.vendas[i].QTDITENS = auxCountItens;
        }

        this.showAlterar = false;
        this.loading = false;
        this.showCarregando = false;
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.loading = false;
          this.showAlterar = false;
          this.showCarregando = false;
          this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')
        });
    }


    /********************************************************************************
    *                                  OUTRO PERIODO                                 *
    ********************************************************************************/

    if (this.valorSelecionadoAlterar === "outroPeriodo") {
      // this.auxDiaIni = this.dataInicial.getFullYear() + '-' + ('0' + (this.dataInicial.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataInicial.getDate()).slice(-2);
      //this.auxDiaFin = this.dataFinal.getFullYear() + '-' + ('0' + (this.dataFinal.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataFinal.getDate()).slice(-2);
      this.auxDiaIni = this.utilitariosService.ajustaDataBanco(this.dataInicial);
      this.auxDiaFin = this.utilitariosService.ajustaDataBanco(this.dataFinal);
      this.vendaService.listaVendaDireta(this.codcli, this.codfunc, this.auxDiaIni, this.auxDiaFin, this.opcoesFiltroSelecionado, this.codusu).subscribe((res) => {
        console.log(res);

        this.vendas = res;
        /**CONTA A QTD DE ITENS DE CADA VENDA */
        for (let i = 0; i < this.vendas.length; i++) {
          this.vetorItens = JSON.parse(this.vendas[i].json).itens

          let auxCountItens = 0;
          for (let j = 0; j < this.vetorItens.length; j++) {
            //console.log()
            auxCountItens += (this.vetorItens[j].qtdneg);
          }
          this.vendas[i].QTDITENS = auxCountItens;
        }

        this.showAlterar = false;
        this.loading = false;
        this.showCarregando = false;
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.loading = false;
          this.showAlterar = false;
          this.showCarregando = false;
          this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')
        });
    }
  }
}


