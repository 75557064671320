<p-toast position="bottom-center" key="bc"></p-toast>
<p-toast position="top-right" key="tr"></p-toast>
<div class="pt-7">

    <div class="flex flex-column  pb-3">
        <div class="flex align-items-center justify-content-center">
            <img src="./assets/iconeCadastrarBarbeiros.svg" class="iconeProfissional" />
        </div>
        <div class=" titulonegritoCadProfissional flex align-items-center justify-content-center">
            <span>Profissionais</span>
        </div>
    </div>

    <div class="flex flex-column  pb-3">
        <div class="flex justify-content-center flex-wrap ">


            <div class="scroll-divCadProfissionals">
                <table id="tabela">
                    <tr *ngFor="let profissional of profissional">
                        <td>
                            <div class="linhahorizontalCadastrarProfissionals pt-1"></div>
                            <div class="col-12 sm-12 md-4 ">
                                <div class="flex flex-column">

                                    <div class="flex align-items-center justify-content-center tamanhoLinhaTabela">
                                        <div class="col "
                                            [ngStyle]="profissional.ativo==='S'?{'opacity': ' 1'} : {'opacity': ' 0.5'}">
                                            <div class="fontTituloCadProfissional">
                                                <span class="xl:text-xl">{{profissional.nomefunc}} -
                                                    ({{profissional.apelido}})</span>
                                            </div>
                                            <div class="formgroup-inline justify-content-between flex-wrap">
                                                <div class="fontEmail pb-2 ">
                                                    <div class="flex gap-2">
                                                        <div
                                                            class="flex align-items-center justify-content-center gap-1 ">
                                                            <img class="iconeEmail" src="./assets/iconeEmail.svg" />
                                                            <span class="xl:text-base"> {{profissional.email}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex align-items-center justify-content-center ">
                                            <img class="cursor-pointer" src="./assets/iconeMenuAdm.png"
                                                (click)="menu.toggle($event); estabelecimentoSelecionada(profissional)" />
                                            <p-menu #menu [popup]="true" [model]="itemsProfissional"
                                                styleClass="menuCadProfissionals"></p-menu>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <p-confirmDialog #cd [closable]="false" id="confirmCadastraProfissionals">
            <ng-template pTemplate="footer">
                <button type="button" pButton icon="pi pi-times" label="Retornar" (click)="cd.reject()"
                    id="confirmBotaoProfissionals"></button>
                <button type="button" pButton icon="pi pi-check" label="Confirmar" (click)="cd.accept()"
                    id="confirmBotaoProfissionals"></button>
            </ng-template>
        </p-confirmDialog>


        <div class="flex justify-content-center flex-wrap ">
            <div class="flex align-items-center justify-content-center">

                <footer class="xl:w-5" id="footerCadastrarProfissionals">
                    <p-button label="Cadastrar" id="botaoCadastrarProfissional" (click)="cadastrarProfissional()"
                        [style]="{width: '100%'}">
                    </p-button>
                </footer>
            </div>
        </div>
    </div>
</div>