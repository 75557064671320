import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { AlertService } from 'src/alert.service';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { UtilitariosService } from 'src/assets/utilitarios.service';
import { FinanceiroService } from 'src/financeiro.service';

@Component({
  selector: 'app-cadastrar-conta',
  templateUrl: './cadastrar-conta.component.html',
  styleUrls: ['./cadastrar-conta.component.scss'],
  providers: [MessageService, ConfirmationService],
  encapsulation: ViewEncapsulation.None
})
export class CadastrarContaComponent implements OnInit {
  showCadastroConta: boolean = false;
  loading: boolean = false;

  /**MENU */
  itemsConta: MenuItem[] = [];

  /**CODIGOS */
  codcli: number = 0;
  nucon: any;
  descr: string = '';
  codusuresp: string = '';


  contas: any;
  contaSelec: any;


  constructor(private financeiroService: FinanceiroService, private decodedTokenService: DecodedTokenService, private confirmationService: ConfirmationService,
    private utilitariosService: UtilitariosService, private alertService: AlertService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.recuperaCodUsu();
    this.recuperaCodCli();

    this.itemsConta = [{
      items: [{
        label: 'Detalhes',
        command: () => {
          this.preencheDadosDialogConta();
        }
      }
      ]
    },
    {
      items: [{
        label: 'Excluir',
        styleClass: 'linhaMenu',
        command: () => {
          this.confirmaExclusaoConta();
        }
      }
      ]
    }
    ];
  }

  /**RECUPERA O CODUSU PARA SER USADO NA AUDITORIA DOS METODOS */
  recuperaCodUsu() {
    this.codusuresp = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }

  /**RECUPERA O CODUSU PARA FAZER AÇÕES DOS METODOS */
  recuperaCodCli() {
    this.codcli = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODCLI;
    this.buscaContaPorCodcli();
  }

  /**ABRE O DIALOG PARA CADASTRO DE UMA NOVA CONTA*/
  mostraDialogConta() {
    this.nucon = null;
    this.descr = '';
    this.showCadastroConta = true;
  }

  /**METODO QUE PREENCHE O DIALOG DA CONTA APOS CLICAR EM 'Detalhes'*/
  preencheDadosDialogConta() {
    this.nucon = this.contaSelec.nucon;
    this.descr = this.contaSelec.descr;

    this.showCadastroConta = true;
  }




  /**METODO DE CRIAÇÃO/EDIÇÃO DA CONTA */
  criaConta() {
    this.loading = true;

    /**FAZ VALIDAÇÃO DOS CAMPOS */
    if (this.utilitariosService.validaCampo([this.descr])) {
      console.log('É necessário preencher todos os campos.')
      this.loading = false;
      this.showError('Atenção', 'É necessário preencher todos os campos.')
      return;
    }

    if (this.nucon > 0) {
      this.financeiroService.createContaFinanceira(this.nucon, this.descr, this.codcli, this.codusuresp).subscribe((res) => {
        this.alertService.alert('Conta alterada!', 'success')
        this.loading = false;
        this.showCadastroConta = false;
        this.buscaContaPorCodcli();
        console.log(res)
      },
        (error) => {
          console.log('deu ruim')
          this.loading = false;
          this.showCadastroConta = false;
          this.alertService.alert('Algo deu errado.', 'error');
        })
    } else {
      console.log(this.descr)
      console.log(this.codcli)
      this.financeiroService.createContaFinanceira(null, this.descr, this.codcli, this.codusuresp).subscribe((res) => {
        this.alertService.alert('Conta criada!', 'success')
        this.loading = false;
        this.showCadastroConta = false;
        this.buscaContaPorCodcli();
        console.log(res)
      },
        (error) => {
          console.log('deu ruim')
          this.loading = false;
          this.showCadastroConta = false;
          this.alertService.alert('Algo deu errado.', 'error');
        })
    }


  }



  /**METODOS PARA BUSCAS AS CONTAS DO CLIENTE E MOSTRA-LAS NA TABELA */
  buscaContaPorCodcli() {
    this.contas = [];
    this.financeiroService.buscaContaPorCodCli(this.codcli, this.codusuresp).subscribe((res) => {
      this.contas = res;
    },
      (error) => {
        console.log(error.message)
        console.log('deu ruim')
      })
  }

  /**METODOS PARA PREENCHIMENTO DOS DADOS DA CONTA SELECIONADA EM 'Detalhes' */
  contaSelecionada(conta: any) {
    this.contaSelec = conta;
    console.log(this.contaSelec)
  }


  /**METODOS DE EXCLUSÃO DA CONTA */
  excluiContaPorNucon() {
    this.financeiroService.excluiConta(this.contaSelec.nucon!, this.codusuresp).subscribe((res) => {
      console.log(res);
      this.contas = this.contas.filter((x: any) => x.nucon != res.nucon); //FILTRA TUDO O QUE É DIFERENTE DO QUE FOI EXCLUIDO

    });
  }

  confirmaExclusaoConta() {
    this.confirmationService.confirm({
      message: 'Deseja mesmo excluir a Conta: ' + this.contaSelec.descr,
      header: 'Excluir Conta',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.excluiContaPorNucon();
      },
      reject: () => {
      }
    });
  }


  /**METODO DE TOAST */
  showError(titulo: string, mensagem: string) {
    if (screen.width < 640 || screen.height < 480) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else if (screen.width < 1024 || screen.height < 768) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else {
      this.messageService.add({
        key: 'tr',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    }
  }

}
