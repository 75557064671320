<p-toast position="bottom-center" key="bc"></p-toast>
<p-toast position="top-right" key="tr"></p-toast>

<div class="pt-7  overflow-hidden">


    <div class="xl:flex xl:justify-content-center xl:flex-wrap">


        <div class="xl:col-5">


            <div class="flex justify-content-around  flex-wrap align-items-center ">
                <div class="linhaMenuAgendar "></div>
                <div class="circulo flex align-items-center justify-content-center"
                    [ngStyle]="activeIndex==0?{'background-color': 'var(--cor1)'} : {'background-color': 'var(--cor2)'}">
                    <div class="menuProgresso ">1</div>
                    <div class="menuTexto"> Selecinar Profissional</div>
                </div>

                <div class="circulo flex align-items-center justify-content-center"
                    [ngStyle]="activeIndex==1?{'background-color': 'var(--cor1)'} : {'background-color': 'var(--cor2)'}">
                    <div class="menuProgresso">2</div>
                    <div class="menuTexto"> Selecionar Serviços </div>
                </div>
                <div class="circulo flex align-items-center justify-content-center"
                    [ngStyle]="activeIndex==2?{'background-color': 'var(--cor1)'} : {'background-color': 'var(--cor2)'}">
                    <div class="menuProgresso">3</div>
                    <div class="menuTexto"> Encontrar Data e Horário</div>
                </div>
                <div class="circulo flex align-items-center justify-content-cente"
                    [ngStyle]="activeIndex==3?{'background-color': 'var(--cor1)'} : {'background-color': 'var(--cor2)'}">
                    <div class="menuProgresso">4</div>
                    <div class="menuTexto"> Confirmar Agendamento</div>
                </div>


            </div>
        </div>
    </div>

    <!-- PRIMEIRA ABA DO MENU            -->

    <div *ngIf="activeIndex == 0">

        <div class="xl:flex xl:justify-content-center xl:flex-wrap">
            <div class="xl:col-5">

                <div class="flex justify-content-center flex-wrap">
                    <div class="fontcinzatituloAgendar col pt-5">
                        <span>Selecionar Profissional</span>
                    </div>
                </div>
                <div class="linhahorizontalAgendar">
                </div>


                <div *ngIf="teveRespostaProfissional">
                    <div class="col">
                        <p-button id="botaoSpinnerAgendar" label="- Escolher Profissional -" [disabled]="true"
                            class="p-button-text">
                            <i class="pi pi-spin pi-spinner flex-order-1" style="font-size: 1rem"></i>
                        </p-button>
                    </div>
                </div>

                <div *ngIf="!teveRespostaProfissional">
                    <div class="col">
                        <p-dropdown id="dropAgendar" [options]="profissional" [(ngModel)]="profissionalSelecionado"
                            placeholder="- Escolher Profissional -" optionLabel="apelido" [style]="{'width': '100%'}"
                            scrollHeight="130px"
                            (onChange)="buscarServicoPorCodFuncionario(); listaPlanosComprados(); horariosFuncionarioSelecionado();">


                            <!------------------------------------------PARA ADICIONAR A FOTO DO BARBEIRO NO DROPDOWN ------------------------------------------>


                            <ng-template pTemplate="selectedItem">
                                <div class="flex align-items-center gap-2" *ngIf="profissionalSelecionado">
                                    <!-- <img *ngIf="profissionalSelecionado.filename !== null" [src]="profissionalSelecionado.filename"  class="fotoProfissionalSelecionado"/> -->
                                    <div>{{ profissionalSelecionado.apelido }}</div>
                                </div>
                            </ng-template>
                            <ng-template let-profissional pTemplate="item">
                                <div class="flex align-items-center gap-2"
                                    [ngStyle]="profissional.filename !== null?{  'padding-right': '4%'} : { 'padding-right': '18%'}">
                                    <img *ngIf="profissional.filename !== null" [src]="profissional.filename"
                                        class="fotoProfissionalListagem" />
                                    <div>{{ profissional.apelido }}</div>
                                </div>
                            </ng-template>




                        </p-dropdown>
                    </div>
                </div>


                <div class="flex justify-content-end flex-wrap col-11 pt-7 ">
                    <div class=" flex align-items-center justify-content-center ">
                        <button pButton type="button" label="Próximo" class="col-12" id="botaoAgendar"
                            (click)="irPagina2();"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- SEGUNDA ABA DO MENU            -->
    <div *ngIf="activeIndex == 1">
        <div class="xl:flex xl:justify-content-center xl:flex-wrap">
            <div class="xl:col-5">
                <div class="  pt-6 ">
                    <div class="contornoAbas formgroup-inline gap-3 ">
                        <div (click)="clickServicos()">
                            <span class="col cursor-pointer fontAbas"
                                [ngClass]="{'linhahorizontalAba': selecionouServicos}"
                                [ngStyle]="selecionouServicos?{  'color:': 'var(--cor22) !imporant', 'font-weight': 700} : {'color:': 'var(--cor1)', 'font-weight': 400}">Serviços</span>
                        </div>
                        <div class=" flex justify-content-center flex-wrap "
                            *ngIf="planosDisponiveis.length >0 || meusPlanos.length >0">
                            <div class="vertical">
                            </div>
                        </div>
                        <div (click)="clickPlanos()" *ngIf="planosDisponiveis.length >0 || meusPlanos.length >0">
                            <span class="col cursor-pointer fontAbas"
                                [ngClass]="{'linhahorizontalAba': selecionouPlanos}"
                                [ngStyle]="selecionouPlanos?{'color:': 'var(--cor22) !imporant', 'font-weight': 700} : {'color:': 'var(--cor1)', 'font-weight': 400}">Planos</span>
                        </div>
                    </div>
                </div>
                <div class="linhahorizontal2Agendar ">
                </div>

                <!--                                ABAS DOS PLANOS                        -->

                <div class="flex justify-content-around flex-wrap contornoPlanos" *ngIf="selecionouPlanos">
                    <div (click)="clickMeusPlanos()" *ngIf="meusPlanos.length >0">
                        <span class="col cursor-pointer fontAbas"
                            [ngClass]="{'linhahorizontalAba': selecionouMeusPlanos}"
                            [ngStyle]="selecionouMeusPlanos?{  'color:': 'var(--cor22) !imporant', 'font-weight': 700} : {'color:': 'var(--cor1)', 'font-weight': 400}">
                            Meus Planos</span>
                    </div>

                    <div class=" flex justify-content-center flex-wrap "
                        *ngIf="meusPlanos.length >0 && planosDisponiveis.length >0">
                        <div class="vertical">
                        </div>
                    </div>

                    <div (click)="clickPlanosDisponiveis()" *ngIf="planosDisponiveis.length >0">
                        <span class="col cursor-pointer fontAbas"
                            [ngClass]="{'linhahorizontalAba': selecionouPlanosDisponiveis}"
                            [ngStyle]="selecionouPlanosDisponiveis?{'color:': 'var(--cor22) !imporant', 'font-weight': 700} : {'color:': 'var(--cor1)', 'font-weight': 400}">
                            Planos Disponíveis</span>
                    </div>
                </div>

                <div class="scroll-divAgendar" *ngIf="selecionouPlanos">


                    <!-- MEU PLANOS-->
                    <div *ngIf="selecionouMeusPlanos">
                        <div *ngFor="let meusPlanos of meusPlanos">
                            <div class="linhahorizontalAgendar  pt-1">
                            </div>
                            <div class="flex flex-column ">
                                <div class="col ">
                                    <div class="formgroup-inline  flex justify-content-between flex-wrap pb-1">
                                        <div class="formgroup-inline gap-2 ">
                                            <div class="flex ">
                                                <div class="justify-content-center align-items-center">
                                                    <img src="./assets/seta.svg" class="cursor-pointer"
                                                        (click)="expandeInfoPlano(meusPlanos)"
                                                        *ngIf="!meusPlanos.expande" />
                                                    <img src="./assets/seta2.svg" class="cursor-pointer"
                                                        (click)="guardaInfoPlano(meusPlanos)"
                                                        *ngIf="meusPlanos.expande" />
                                                </div>
                                            </div>

                                            <div>
                                                <span class="fontPlanosAgd">{{meusPlanos.nome}}</span>
                                                <div class=" fontmarronvalores" *ngIf="selecionouMeusPlanos">
                                                    <span>{{meusPlanos.dhaquisicao | date:'dd/MM/yyyy HH:mm'}}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="formgroup-inline">
                                            <div class=" flex justify-content-between flex-wrap">

                                                <div class="flex flex-column align-items-center  contornoPlanos2"
                                                    *ngIf="selecionouMeusPlanos">
                                                    <span class="fontPrecoPlanos">{{meusPlanos.qtds}}</span>
                                                    <span
                                                        class="fontDataExpirarPlano contornoTempoExpirar white-space-nowrap ">{{meusPlanos.d_a_expirar}}
                                                        dias à
                                                        expirar</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex flex-column">
                                        <div class="flex  pb-2">
                                            <span
                                                class="fontcinzaAgendar   maximoCaracterDescrPlanos white-space-normal overflow-hidden text-overflow-ellipsis">{{meusPlanos.descr}}</span>
                                        </div>
                                        <div class=" fontcinzaAgendar">
                                            <span>{{meusPlanos.categoria}}</span>
                                        </div>
                                    </div>


                                    <div *ngIf="meusPlanos.expande">
                                        <span
                                            class="fontcinzaAgendar   maximoCaracterDescrPlanos white-space-normal overflow-hidden text-overflow-ellipsis">
                                            {{meusPlanos.descrprods}}
                                        </span>
                                    </div>

                                </div>
                            </div>

                            <div class="linhahorizontalAgendar  pt-1">
                            </div>


                            <div class="col">
                                <span class="fontcinzaAgendar"> Serviços / Produtos</span>
                            </div>



                            <!--                                SERVIÇOS DOS MEUS PLANOS                        -->

                            <ng-container *ngFor="let servicosMeusPlanos of servicosMeusPlanos">
                                <div class="col " *ngIf="servicosMeusPlanos.nupla == meusPlanos.nupla">
                                    <div class="flex gap-2  justify-content-between">

                                        <div class="flex justify-content-center align-items-center gap-2">
                                            <p-checkbox [value]="servicosMeusPlanos" [(ngModel)]="valorSelecionado"
                                                (onChange)="somaTotal();criaTitulo();checaServico(servicosMeusPlanos, 2);printaServicos()"
                                                [disabled]="servicosMeusPlanos.desabilita">
                                            </p-checkbox>
                                            <div class="flex flex-column   align-items-start">
                                                <div class=" fontmarronAgendar">
                                                    <span>{{servicosMeusPlanos.nomeprod}} |
                                                        ({{servicosMeusPlanos.minutos}} min)</span>
                                                </div>
                                                <div class="fontcinzaAgendar">
                                                    <span>{{servicosMeusPlanos.descrprod}}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="selecionouMeusPlanos">
                                            <div *ngIf="servicosMeusPlanos.status==='LIVRE'">
                                                <div class="flex justify-content-center flex-wrap">
                                                    <div
                                                        class="flex flex-column align-items-center contornoStatusSerico">

                                                        <span class="fontLetaStatusServico contornoLivre">L</span>
                                                        <span class="fontStatusServico">disponível</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="servicosMeusPlanos.status==='USADO'">
                                                <div class="flex flex-column align-items-center contornoStatusSerico">
                                                    <span class="fontLetaStatusServico contornoUsado">U</span>
                                                    <span class="fontStatusServico">usado</span>
                                                </div>
                                            </div>

                                            <div *ngIf="servicosMeusPlanos.status==='EXPIRADO'">
                                                <div class="flex flex-column align-items-center contornoStatusSerico">
                                                    <span class="fontLetaStatusServico contornoExpirou">E</span>
                                                    <span class="fontStatusServico">expirou</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>


                        </div>
                    </div>






                    <!--  PLANOS DISPONIVEIS PARA COMPRA -->
                    <div *ngIf="selecionouPlanosDisponiveis">


                        <div *ngFor="let planosDisponiveis of planosDisponiveis">
                            <div class="linhahorizontalAgendar  pt-1">
                            </div>
                            <div class="flex flex-column ">
                                <div class="col ">
                                    <div class="formgroup-inline  flex justify-content-between flex-wrap pb-1">
                                        <div class="formgroup-inline gap-2 ">
                                            <div class="flex">
                                                <div class="justify-content-center align-items-center">
                                                    <div class="formgroup-inline">


                                                        <!-- <img src="./assets/seta.svg" class="cursor-pointer"
                                                            (click)="expandeInfoPlano(planosDisponiveis)"
                                                            *ngIf="!planosDisponiveis.expande" />
                                                        <img src="./assets/seta2.svg" class="cursor-pointer"
                                                            (click)="guardaInfoPlano(planosDisponiveis)"
                                                            *ngIf="planosDisponiveis.expande" /> -->


                                                        <!-- <p-checkbox [value]="planosDisponiveis"
                                                        [(ngModel)]="planosDisponiveisSelecionado" class="pl-2"
                                                        (click)="somaTotal();criaTitulo();"
                                                        *ngIf="selecionouPlanosDisponiveis">
                                                         </p-checkbox> -->

                                                        <div
                                                            *ngFor="let planosDisponiveisSelecionado of planosDisponiveisSelecionado">
                                                            <div
                                                                *ngIf="planosDisponiveisSelecionado.nupla == planosDisponiveis.nupla">
                                                                <i class="pi pi-check checkVerde pl-1"
                                                                    style="font-size: 1rem"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <span class="fontPlanosAgd">{{planosDisponiveis.nome}}</span>
                                                <div class=" fontmarronvalores" *ngIf="selecionouMeusPlanos">
                                                    <span>{{planosDisponiveis.data}}</span>
                                                </div>

                                                <!-- <div>
                                                    <p-chip id="infoPlano" label="Info" icon="pi pi-info"></p-chip>
                                                </div> -->


                                                <div class="contornoInfoPlano"
                                                    (click)="expandeInfoPlano(planosDisponiveis)"
                                                    *ngIf="!planosDisponiveis.expande">
                                                    <div
                                                        class="fontCorInfoPlano  flex justify-content-center flex-wrap gap-2 cursor-pointer">
                                                        <i class="pi pi-info-circle infoPlano "></i>
                                                        <span>Info</span>
                                                    </div>
                                                </div>


                                                <div class="contornoInfoPlano"
                                                    (click)="guardaInfoPlano(planosDisponiveis)"
                                                    *ngIf="planosDisponiveis.expande">
                                                    <div
                                                        class="fontCorInfoPlano  flex justify-content-center flex-wrap gap-2 cursor-pointer">
                                                        <i class="pi pi-info-circle infoPlano "></i>
                                                        <span>Info</span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="formgroup-inline">
                                            <div class=" flex justify-content-between flex-wrap">
                                                <div class="flex flex-column align-items-center  contornoPlanos2"
                                                    *ngIf="selecionouPlanosDisponiveis">
                                                    <span class="fontPrecoPlanos">{{planosDisponiveis.preco | currency
                                                        :'BRL' }}</span>
                                                    <span class="fontPrecoPlanos pt-1">{{planosDisponiveis.minutos}}
                                                        min</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div>
                                        <p-chip id="infoPlano" label="Info" icon="pi pi-info"></p-chip>
                                    </div> -->

                                    <div class="flex flex-column">
                                        <div class="flex  pb-2">
                                            <span
                                                class="fontcinzaAgendar   maximoCaracterDescrPlanos white-space-normal overflow-hidden text-overflow-ellipsis">{{planosDisponiveis.descr}}</span>
                                        </div>
                                        <div class=" fontcinzaAgendar">
                                            <span>{{planosDisponiveis.categoria}}</span>
                                        </div>
                                    </div>



                                    <div *ngIf="planosDisponiveis.expande">
                                        <span
                                            class="fontcinzaAgendar   maximoCaracterDescrPlanos white-space-normal overflow-hidden text-overflow-ellipsis">
                                            {{planosDisponiveis.descrprods}}
                                        </span>
                                    </div>

                                </div>
                            </div>

                            <div class="linhahorizontalAgendar  pt-1">
                            </div>


                            <div class="col">
                                <span class="fontcinzaAgendar"> Serviços / Produtos</span>
                            </div>

                            <!-- <div class="linhahorizontalAgendar  pt-1">
                        </div> -->

                            <!--                                SERVIÇOS DOS PLANOS DISPONIVEIS                       -->

                            <ng-container *ngFor="let servicosPlanosDisponiveis of servicosPlanosDisponiveis">
                                <div class="col " *ngIf="servicosPlanosDisponiveis.nupla == planosDisponiveis.nupla">
                                    <div class=" formgroup-inline  flex justify-content-between">
                                        <div class="formgroup-inline gap-2 ">
                                            <p-checkbox [value]="servicosPlanosDisponiveis"
                                                [(ngModel)]="valorSelecionado"
                                                (onChange)="adicionaPlano(servicosPlanosDisponiveis);somaTotal();criaTitulo();checaServico(servicosPlanosDisponiveis, 2);"
                                                [disabled]="servicosPlanosDisponiveis.desabilita">
                                            </p-checkbox>
                                            <div class="flex flex-column   align-items-start">
                                                <div class=" fontmarronAgendar">
                                                    <span>{{servicosPlanosDisponiveis.nomeprod}} |
                                                        ({{servicosPlanosDisponiveis.minutos}} min)</span>
                                                </div>
                                                <div class="fontcinzaAgendar">
                                                    <span>{{servicosPlanosDisponiveis.descrprod}}</span>
                                                </div>

                                            </div>
                                        </div>
                                        <div class=" flex justify-content-between flex-wrap">
                                            <div class="flex flex-column align-items-center  contornoPlanos3"
                                                *ngIf="selecionouPlanosDisponiveis">
                                                <span class="fontPrecoPlanos"
                                                    *ngIf="servicosPlanosDisponiveis.qtd < 100">{{servicosPlanosDisponiveis.qtd}}</span>
                                                <span class="fontQtdInfinito"
                                                    *ngIf="servicosPlanosDisponiveis.qtd > 100">&infin;</span>
                                                <span class="fontPrecoPlanos pt-1">
                                                    usos</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <!-- <div class="linhahorizontalAgendar  pt-1">
                            </div> -->
                            </ng-container>


                        </div>
                    </div>

                </div>


                <!--                                ABAS DOS SERVIÇOS POR BARBEIRO                        -->
                <div class="scroll-divAgendar " *ngIf="selecionouPlanos === false">
                    <table>
                        <tr *ngFor="let servico of servico">
                            <td>
                                <div class="flex flex-column   align-items-start">
                                    <div class="col tamanhoLinhaTabelaAgendar">
                                        <div class="flex gap-2 ">
                                            <p-checkbox [value]="servico" [(ngModel)]="valorSelecionado"
                                                (onChange)="somaTotal();criaTitulo();checaServico(servico, 1)"
                                                [disabled]="servico.desabilita!">
                                            </p-checkbox>
                                            <div class="flex flex-column   align-items-start">
                                                <div class=" fontmarronAgendar">
                                                    <span>{{servico.nomeprod}} | ({{servico.minutos}} min)</span>
                                                </div>
                                                <div class="fontcinzaAgendar">
                                                    <span>{{servico.descrprod}}</span>
                                                </div>
                                                <div class="flex align-items-center flex-wrap"
                                                    *ngIf="temDesconto || servico.temdesconto">
                                                    <div class="formgroup-inline ">
                                                        <div
                                                            class="flex align-items-center justify-content-center  gap-3">
                                                            <div class="flex align-items-center justify-content-center">
                                                                <span class="fontpreco line-through">{{servico.preco|
                                                                    currency :
                                                                    'BRL'}}</span>
                                                            </div>
                                                            <div class="flex align-items-center justify-content-center">
                                                                <span
                                                                    class="fontprecoDescontoProduto">{{servico.precoAposDesconto|
                                                                    currency :
                                                                    'BRL'}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="flex align-items-center flex-wrap">
                                                    <div class="formgroup-inline gap-2 pt-1">
                                                        <div class="contornoValorDesconto"
                                                            *ngIf="profissionalSelecionado.tipdesc === 'R' && temDesconto">
                                                            <div
                                                                class="fontCorDesconto  flex justify-content-center flex-wrap">
                                                                <span>-{{profissionalSelecionado.desc|currency
                                                                    :'BRL'}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="contornoValorDesconto"
                                                            *ngIf="profissionalSelecionado.tipdesc === 'P'  && temDesconto">
                                                            <div
                                                                class="fontCorDesconto  flex justify-content-center flex-wrap">
                                                                <span>-% {{profissionalSelecionado.desc}}</span>
                                                            </div>
                                                        </div>


                                                        <div class="contornoValorDesconto"
                                                            *ngIf="servico.tipdesc === 'R' && servico.temdesconto">
                                                            <div
                                                                class="fontCorDesconto  flex justify-content-center flex-wrap">
                                                                <span>-{{servico.desc|currency :'BRL'}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="contornoValorDesconto"
                                                            *ngIf="servico.tipdesc === 'P' && servico.temdesconto">
                                                            <div
                                                                class="fontCorDesconto  flex justify-content-center flex-wrap">
                                                                <span>-% {{servico.desc}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="fontcinzaAgendar"
                                                    *ngIf="!temDesconto  && !servico.temdesconto">
                                                    <span class="fontpreco">{{servico.preco|currency :'BRL'}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="xl:col-5"> -->

                                <div class="linhahorizontalAgendar  pt-1">
                                </div>
                                <!-- </div> -->
                            </td>
                        </tr>

                    </table>
                </div>

                <!-- <div class="linhahorizontalAgendar pt-1">
                </div> -->

                <div class="formgrid grid align-items-center justify-content-center rodape pt-3"
                    [style]="{'gap': '9rem'}">
                    <button pButton type="button" label="Voltar" class="col-12" (click)="voltarPagina()"
                        id="voltar"></button>
                    <button pButton type="button" label="Próximo" class="col-12" (click)="irPagina3()"
                        id="botaoAgendar"></button>
                </div>

            </div>
        </div>
        <footer id="footerAgendar">
            <div class="col">
                <div class="col fontmarronnegrito">
                    <span>Total</span>
                </div>
                <div clas="flex justify-content-center flex-wrap">
                    <div class="flex align-items-center justify-content-center">
                        <div class="formgroup-inline gap-3">
                            <div class="fontmarrontotal">
                                <span>Tempo: </span>
                            </div>
                            <div class="fontmarronvalores">
                                <span>{{totalMin}} Minutos</span>
                            </div>
                        </div>
                    </div>


                    <div class="flex align-items-center justify-content-center" *ngIf="!temDescontoCarrinho">
                        <div class="formgroup-inline gap-3">
                            <div class="fontmarrontotal">
                                <span>Valor: </span>
                            </div>
                            <div class="fontmarronvalores">
                                <span>{{totalSemDesc | currency : 'BRL'}}</span>
                            </div>
                        </div>
                    </div>


                    <div class="flex align-items-center flex-wrap justify-content-center" *ngIf="temDescontoCarrinho">
                        <div class="formgroup-inline pl-5 gap-3">
                            <div class="fontmarrontotal">
                                <span>Valor: </span>
                            </div>
                            <div class="formgroup-inline gap-2">


                                <div class="flex align-items-center justify-content-center">
                                    <span class="fontmarronvalores line-through">{{totalSemDesc|
                                        currency :
                                        'BRL'}}</span>
                                </div>
                                <div class="flex align-items-center justify-content-center">
                                    <span class="fontmarronvaloresDescontoTotal">{{total|
                                        currency :
                                        'BRL'}}</span>
                                </div>
                            </div>
                        </div>
                    </div>






                </div>
            </div>
        </footer>
    </div>


    <!-- TERCEIRA ABA DO MENU            -->

    <div *ngIf="activeIndex == 2">
        <div class="xl:flex xl:justify-content-center xl:flex-wrap">
            <div class="xl:col-5">
                <div class="fontcinzatituloAgendar col pt-5">
                    <span>Selecionar dia</span>
                </div>
                <div class="linhahorizontalAgendar">
                </div>
                <div class="col">
                    <p-calendar [touchUI]="true" [readonlyInput]="false" inputId="touchui" dateFormat="dd/mm/yy"
                        [minDate]="ontem" [required]="true" [readonlyInput]="true" id="calendarioAgendar"
                        [(ngModel)]="diaSelecionado" (click)="selecionouDia()" placeholder="DD/MM/AAAA">
                    </p-calendar>

                </div>
                <div class="fontcinzatituloAgendar col">
                    <span>Selecionar Horário</span>
                </div>
                <div class="linhahorizontalAgendar ">
                </div>

                <div class="col">
                    <button pButton pRipple type="button" [label]=horarioSelecionado class="p-button-text"
                        [style]="{'width': '100%'}" (click)="horarios(); logAutomaticoFila()" id="horariosButtonAgendar"
                        [ngStyle]="horarioSelecionado === 'HH:MM'?{  'padding-right': '4%'} : { 'padding-right': '18%'}"></button>
                </div>


                <!-- DIALOG DE HORARIOS -->

                <p-dialog id="dialoHor" [(visible)]="mostraHorarios" [modal]="true" header="Horários"
                    [style]="{'width': '350px'}">
                    <div class="flex justify-content-center">
                        <div *ngIf="horarioMatriz.length===0">
                            <!-- <div class="flex align-items-center justify-content-center">
                                <div class="flex flex-column sm:flex-row  align-items-center">
                                    <div class="">
                                        <div class="fontEsgotados flex align-items-center justify-content-center">
                                            <img class="iconeSemHorarios" src="./assets/semHorarios.png" />
                                        </div>
                                        <div class="fontEsgotados flex align-items-center justify-content-center">
                                            <span>Hórarios para atendimentos esgotados</span>
                                        </div>

                                        <button pButton type="button" label="Fila de Espera" class="col-12"
                                            (click)="gravaFilaEspera()" id="botaoAgendar"></button>
                                    </div>
                                </div>
                            </div> -->

                            <div class="flex align-items-center justify-content-center">
                                <div class="flex flex-column  align-items-center">
                                    <div class="">
                                        <div class="fontEsgotados flex align-items-center justify-content-center">
                                            <img class="iconeSemHorarios" src="./assets/semHorarios.png" />
                                        </div>
                                        <div class="fontEsgotados flex align-items-center justify-content-center">
                                            <span>Hórarios para atendimentos esgotados.</span>
                                        </div>
                                    </div>
                                    <div class="" *ngIf="horarioMatrizFilaEspera.length > 0">
                                        <div
                                            class="fontEsgotados font-bold flex align-items-center justify-content-center pb-2">
                                            <span>Deseja entrar na fila de espera?</span>
                                        </div>
                                        <button pButton type="button" label="Fila de Espera" class="col-12"
                                            id="botaoFilaEspera" (click)="showDialogFila()"></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex align-items-center justify-content-center">
                            <div class="flex flex-column  align-items-center">
                                <div class="pb-2" *ngIf="horarioMatriz.length>0">
                                    <div class=" flex align-items-center justify-content-center contornoNaoAchouHor "
                                        (click)="showDialogFila()">
                                        <span class="fontNaoAchouHor font-bold underline cursor-pointer">Não encontrou o
                                            horário que procurava?
                                        </span> <span> 😮</span>
                                    </div>
                                </div>

                                <table class="">
                                    <tr *ngFor="let horarioMatriz of horarioMatriz">
                                        <td *ngFor="let horario of horarioMatriz">
                                            <p-button label='{{horario[1].horario }}'
                                                (click)="agendaHorario(horario[1].horario)" id="horarioEscolhido"
                                                [style]="{'background-color': 'var(--cor2)', 'color': 'var(--cor1)'}"
                                                [ngStyle]="horarioSelecionado == horario[1].horario?{  'color': 'var(--cor7)'} : { 'color': 'var(--cor1)'}">
                                            </p-button>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </p-dialog>



                <!-- DIALOG FILA DE ESPERA -->
                <p-dialog id="dialoHor" [(visible)]="mostraFilaEspera" [modal]="true" header="Lista de espera"
                    [style]="{'width': '350px'}">

                    <div class="flex flex-column   align-items-center">
                        <div class="flex justify-content-center flex-wrap col">
                            <div class="fontDialogEstabelecimento flex align-items-center justify-content-center ">
                                <span class="fontNaoAchouHor">Informe abaixo um horário de sua preferência. Se
                                    ficar disponível, te
                                    informaremos! 😃</span>
                            </div>
                        </div>

                        <table class="pb-2">
                            <tr *ngFor="let horarioMatrizFilaEspera of horarioMatrizFilaEspera">
                                <td *ngFor="let horario of horarioMatrizFilaEspera">
                                    <p-button label='{{horario[1].horario }}' id="horarioEscolhido"
                                        (click)="horarioParaFila(horario[1].horario)"
                                        [style]="{'background-color': 'var(--cor2)', 'color': 'var(--cor1)'}"
                                        [ngStyle]="horarioSelecionado == horario[1].horario?{  'color': 'var(--cor7)'} : { 'color': 'var(--cor1)'}">
                                    </p-button>
                                </td>
                            </tr>
                        </table>


                        <button pButton type="button" label="Confirmar preferência" class="col-12" id="botaoFilaEspera"
                            (click)="queroEntrarFila()"></button>

                    </div>
                </p-dialog>


                <div class="formgrid grid align-items-center justify-content-center rodape pt-7"
                    [style]="{'gap': '9rem'}">
                    <button pButton type="button" label="Voltar" class="col-12" (click)="voltarPagina()"
                        id="voltar"></button>
                    <button pButton type="button" label="Próximo" class="col-12" (click)="irPagina4()"
                        id="botaoAgendar"></button>
                </div>
            </div>
        </div>
    </div>

    <!-- QUARTA ABA DO MENU            -->

    <div *ngIf="activeIndex == 3">
        <div class="xl:flex xl:justify-content-center xl:flex-wrap">
            <div class="xl:col-5">
                <div class="fontcinzatituloAgendar col pt-5">
                    <span>Resumo do Agendamento</span>
                </div>
                <div class="flex flex-column sm:flex-row  align-items-start">
                    <div class="col">
                        <div class="fontcinzaAgendar ">
                            <span> {{nomeemp}} </span>
                        </div>
                        <div class="fontcinzaAgendar ">
                            <span> Profissional: {{nomefunc}}</span>
                        </div>
                        <div class="">
                            <div class="fontcinzaAgendar ">
                                <span> Serviços: {{titulo}}</span>
                            </div>
                            <!-- <div class="fontcinzaAgendar ">
                                <span> Valor: {{total | currency : 'BRL'}}</span>
                            </div> -->
                            <div class="fontcinzaAgendar ">
                                <span> Duração Média: {{totalMin}} Minutos</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-column sm:flex-row  align-items-start">
                    <div class="col">
                        <div class="formgroup-inline align-items-center  gap-6">
                            <div class="fontcinzaAgendar pb-2 ">
                                <div class="flex gap-2">
                                    <img class="" src="./assets/calendario.svg" />
                                    <div class="flex align-items-center justify-content-center">
                                        <span>{{diaSelecionado | date: 'dd/MM/yyyy'}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="fontcinzaAgendar pb-2 ">
                                <div class="flex gap-2">
                                    <img class="" src="./assets/relogio.svg" />
                                    <div class="flex align-items-center justify-content-center">
                                        <span> {{horarioSelecionado}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="fontcinzaAgendar pb-2">
                            <div class="flex gap-2">
                                <img class="" src="./assets/dol.svg" />
                                <div class="flex align-items-center justify-content-center">
                                    <span> {{this.total| currency:'BRL'}}</span>
                                </div>
                            </div>
                        </div> -->
                        <div class="">
                            <div class="flex flex-column sm:flex-row  align-items-start">
                                <div class="fontcinzaAgendar ">
                                    <div class="flex gap-2">
                                        <img class="" src="./assets/localizacao.svg" />
                                        <div class="fontcinzaAgendar pb-2">
                                            <span> {{logradouro}}, {{complemento}} {{numero}}</span>
                                            <div>
                                                <span> {{bairro}} - {{nomecid}}</span>
                                            </div>
                                            <div>
                                                <span> {{telefone}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <!-- <div class="fontcinzatituloAgendar col pt-1">
                        <span>Descontos</span>
                    </div> -->
                    <div class="flex flex-column sm:flex-row  align-items-start" *ngIf="habilitacupom === 'S'">
                        <div class="col">
                            <!-- <div class="flex flex-column sm:flex-row  align-items-start">
                                <div class="fontcinzaAgendar pb-1 formgroup-inline justify-content-between ">
                                    <div class="flex gap-2">
                                        <img class="" src="./assets/dol.svg" />
                                        <div class=" flex justify-content-between flex-wrap gap-8">
                                            <div class="flex align-items-center justify-content-center">
                                                <span>{{descontoAcumulado | currency : 'BRL'}}</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div> -->
                            <div class="fontcinzatituloAgendar col pt-1">
                                <span>Cupom</span>
                            </div>

                            <div class="formgroup-inline gap-2">
                                <div class="w-8 ">
                                    <input type="text" [(ngModel)]="cupom" pInputText placeholder="Cupom"
                                        [style]="{'width': '100%'}" class="uppercase" id="inputCupom">
                                </div>
                                <div class="w-3 ">
                                    <button pButton type="button" label="Aplicar" class="col-12" id="aplicaCupom"
                                        (click)="validaCupom()" [style]="{'width':'100%', 'height': '45px'}"></button>
                                </div>
                            </div>
                            <div class="pl-3">
                                <div *ngIf="cupomValido">
                                    <small class="fontCupomVal">Cupom Válido</small>
                                </div>
                                <div *ngIf="cupomInvalido">
                                    <small class="fontCupomInv">Cupom Inválido</small>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="fontcinzatituloAgendar col pt-1">
                        <span>Total Geral</span>
                    </div>
                    <div class="flex flex-column sm:flex-row  align-items-start">
                        <div class="col">
                            <div class="flex flex-column sm:flex-row  align-items-start">
                                <div class="fontcinzaAgendar  formgroup-inline justify-content-between ">
                                    <div class="flex gap-2">
                                        <img class="" src="./assets/dol.svg" />
                                        <div class=" flex justify-content-between flex-wrap gap-8">
                                            <div class="flex align-items-center justify-content-center gap-2">
                                                <span
                                                    [ngStyle]="descontoAcumulado > 0?{'text-decoration': 'line-through'} : {'text-decoration': 'none'}">{{totalGeral
                                                    | currency : 'BRL'}}</span>
                                                <span *ngIf="descontoAcumulado > 0" class="font-bold">{{totalGeralComDesconto | currency : 'BRL'}}</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->



                    <div class="flex justify-content-center">
                        <div class="contornoDollar">
                            <img class="" src="./assets/dol.svg" />
                        </div>

                    </div>

                    <div class="col">
                        <div class="contornoResumo col">
                            <div>
                                <div class="flex justify-content-between pb-1">
                                    <div class="flex align-items-center justify-content-center">
                                        <span class="fontcinzatituloAgendar">Resumo</span>
                                    </div>

                                    <div class="flex align-items-center justify-content-center fontcinzaValorsResumo">
                                        <span
                                            [ngStyle]="descontoAcumulado > 0?{'text-decoration': 'line-through'} : {'text-decoration': 'none'}">{{totalGeral
                                            | currency : 'BRL'}}</span>

                                    </div>
                                </div>

                                <div class="flex justify-content-between">
                                    <div class="flex align-items-center justify-content-center">
                                        <span class="fontcinzaResumoAgendar">Descontos</span>
                                    </div>

                                    <div class="flex align-items-center justify-content-center">
                                        <span class="fontcinzaValorsResumo font-bold">- {{descontoAcumulado | currency :
                                            'BRL'}}</span>
                                    </div>
                                </div>

                                <div class="flex justify-content-between">
                                    <div class="flex align-items-center justify-content-center">
                                        <span class="fontcinzaResumoAgendar">Total</span>
                                    </div>

                                    <div class="flex align-items-center justify-content-center fontcinzaValorsResumo">
                                        <span *ngIf="descontoAcumulado === 0" class="font-bold">{{totalGeral
                                            | currency : 'BRL'}}</span>
                                        <span *ngIf="descontoAcumulado > 0" class="font-bold">{{totalGeralComDesconto |
                                            currency : 'BRL'}}</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>












                </div>
                <div class="flex flex-column   align-items-start">
                    <div class="col">
                        <div class="fontcinzaAgendar">
                            <div class="flex gap-2">
                                <p-checkbox [binary]="true" [(ngModel)]="receberNotificacao"></p-checkbox>
                                <div class="flex align-items-center justify-content-center">
                                    <span>Receber alerta do atendimento</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <div class=" formgrid grid align-items-center justify-content-center gap-4">
                        <button pButton type="button" label="Voltar" class="col-12" (click)="voltarPagina()" id="voltar"
                            [style]="{'width':'144px', 'height': '45px'}"></button>
                        <button pButton type="button" label="Confirmar" class="col-12" id="confirmar"
                            (click)="criarAgenda();"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- <p-dialog id="confirm" [(visible)]="showConfirmado" [modal]="true" [style]="{'width': '350px', 'height': '200px'}"
    [closable]="false">
    <div class="flex justify-content-center flex-wrap pb-4">
        <img src="./assets/iconeConfirmado.png" class="iconeConfirmado" />

    </div>
    <div class="flex justify-content-center flex-wrap fontConfirmAgdRgd">
        <span>
            {{descDialogConfirm}}
        </span>
        <div class="pt-2">

            <button id="botaoAgdConfirmado" pButton type="button" label="Confirmar" class="col-12 "
                (click)="confirmaAgdRgd()"></button>
        </div>
    </div>

</p-dialog> -->