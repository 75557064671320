import { Injectable, Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import Swal, { SweetAlertIcon } from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})



export class AlertService {

  constructor() { }


  private showAlert(titulo: string, icone: SweetAlertIcon, mensagem: string) {
    if (icone === 'success') {
      Swal.fire({
        title: titulo,
        text: mensagem,
        showConfirmButton: false,
        timer: 1500,
        iconColor: 'var(--cor2)',
        icon: icone,
      })
    } else {
      Swal.fire({
        title: titulo,
        text: mensagem,
        showConfirmButton: false,
        timer: 2000,
        iconColor: 'var(--cor11)',
        icon: icone,
      })
    }

  }

  public alert(mensagem: string, icone: SweetAlertIcon, titulo?: string) {
    this.showAlert(titulo!, icone, mensagem);
  }

  // public error(mensagem: string, titulo?: string) {
  //   this.showAlert(titulo!, mensagem, 'error');
  // }

  /**METODO DE TOAST */
  // showError1(titulo: string, mensagem: string) {
  //   console.log(titulo + '   ' + mensagem)
  //   this.messageService.add({
  //     key: 'bc',
  //     severity: 'error',
  //     summary: titulo,
  //     detail: mensagem
  //   });
  // }
}
