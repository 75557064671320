import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { first, Observable, Subject, tap } from 'rxjs';
import { environment } from './environments/environment';
import { LoginService } from './login/login.service';


@Injectable({
  providedIn: 'root'
})
export class PermissoesService {
  permissao: any;

  public permissao$ = new Subject();

  constructor(private http: HttpClient, private loginService: LoginService) { }

  createPermissao(nutel: number, nuaco: number, codfunc: number, permite: string, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const tela = { "nutel": nutel, "nuaco": nuaco, "codfunc": codfunc, "permite": permite, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/permissao/createpermissao`, tela, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  bucaPermissaoPorCodfunc(codfunc: number): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const cliente = { "codfunc": codfunc }

    return this.http
      .post<any>(`${environment.BASE_URL}/permissao/getpermissaobycodfunc`, cliente, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  bucaPermissaoPorCodCli(codcli: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const cliente = { "codcli": codcli }

    return this.http
      .post<any>(`${environment.BASE_URL}/permissao/getpermissaobycodcli`, cliente, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  /**METODO QUE ARMAZENA AS PERMISSÕES QUANDO ENTRA NO PainelBarber */
  recuperaPermissao(codfunc: number) {
    /**DEIXA O VETOR VAZIO PARA QUE NÃO RECUPERE PERMISSÕES DE OUTROS USUARIOS AO FAZER LOGIN */
    this.permissao = [];

    /**SE O CODFUNC FOR = 0 SIGNIFICA QUE NÃO É FUNCIONARIO E NÃO FAZ A BUSCA POR PERMISSÕES */
    if (codfunc === 0) {
      return;
    } else {
      this.bucaPermissaoPorCodfunc(codfunc).subscribe((res) => {
        this.permissao = res;

        for (let i = 0; i < this.permissao.length; i++) {
          this.permissao[i].acao = JSON.parse(Object(this.permissao[i].acao));
          /**RETIRA OS ESPAÇOS EM BRANCO */
          this.permissao[i].habilita = this.permissao[i].habilita.replace(/\s/g, '');
        }

        /**INSCREVE AS PERMISSÕES*/
        console.log('oi do permissoes service')
        this.permissao$.next(this.permissao);
      },
        (error) => {
          console.log(error.message)
          console.log('deu ruim')
          return false;
        });
    }

  }

  limpaPermissao() {
    //this.permissao$ = new Subject();
    this.permissao$ = new Subject();
    this.permissao = [];
    console.log(this.permissao$)
  }


  /**RETORNA O VETOR DE PERMISSÕES PARA OS COMPONENTS SER USADO NAS TELAS */
  getPermissao() {
    console.log(this.permissao)
    console.log('getpermissao↑')
    if (this.permissao) {
      return this.permissao;
    } else {
      /**DEIXA O VETOR VAZIO PARA QUE NÃO RECUPERE PERMISSÕES DE OUTROS USUARIOS AO FAZER LOGIN */
      this.permissao = [];
    }


  }


  /**METODO QUE RECUPERA AS PERMISSÕES DO FUNCIONARIO PARA LIBERAR A TELA */
  validaPermissao(rota: string) {
    console.log(this.permissao)
    console.log(rota)
    console.log('↑rota')

    // if(this.permissao === undefined){
    //   console.log('falso')
    //   return false;
    // }

    //console.log(this.permissao.length)

    /**VERIFICA SE TEM PERMISSÕES */
    if (this.permissao.length > 0) {

      /**PERCORRE O VETOR DE PERMISSOES PARA VERIFICAR O ACESSO A TELA */
      for (let i = 0; i < this.permissao.length; i++) {
        // console.log('ESTOU validando as permissoes  DO VETOR→')
        // console.log(this.permissao[i])



        //**SE A ROTA FOR A MESMA QUE ESTÁ NO BANCO, ENTÃO LIBERA O ACESSO */
        if (this.permissao[i].descr === 'BARBEARIAS' && this.permissao[i].habilita === 'S' && rota === '/cadastrarBarbearia') {
          return true;
        }
        else
          if (this.permissao[i].descr === 'PROFISSIONAIS' && this.permissao[i].habilita === 'S' && rota === '/cadastrarBarbeiros') {
            return true;
          }

          else
            if (this.permissao[i].descr === 'HORÁRIOS' && this.permissao[i].habilita === 'S' && rota === '/cadastrarHorarios') {
              return true;
            }

            else
              if (this.permissao[i].descr === 'SERVIÇOS' && this.permissao[i].habilita === 'S' && rota === '/cadastrarServicos') {
                return true;
              }

              else
                if (this.permissao[i].descr === 'INFORMATIVOS' && this.permissao[i].habilita === 'S' && rota === '/cadastrarInformativos') {
                  return true;
                }

                else
                  if (this.permissao[i].descr === 'PESQUISAS' && this.permissao[i].habilita === 'S' && rota === '/cadastrarPesquisa') {
                    return true;
                  }

                  else
                    if (this.permissao[i].descr === 'NOTIFICAÇÕES' && this.permissao[i].habilita === 'S' && rota === '/notificacoes') {
                      return true;
                    }

                    else
                      if (this.permissao[i].descr === 'COMISSÕES' && this.permissao[i].habilita === 'S' && rota === '/cadastrarComissao') {
                        return true;
                      }

                      else
                        if (this.permissao[i].descr === 'DESCONTOS' && this.permissao[i].habilita === 'S' && (rota === '/descontos' || rota === '/cadastrarCupom' || rota === '/descontoTotal')) {
                          return true;
                        }

                        else
                          if (this.permissao[i].descr === 'MOV. FINANCEIRO' && this.permissao[i].habilita === 'S' && rota === '/financeiro') {
                            return true;
                          }

                          else
                            if (this.permissao[i].descr === 'ACESSOS' && this.permissao[i].habilita === 'S' && rota === '/acessos') {
                              return true;
                            }
        //else {

        //  return false;
        //}

      }

      /**VERIFICA A PERMISSÕES DAS AÇÕES PARA AS TELAS QUE TEM 2 TELAS DE CADASTRO (SE TIVER PERMISSÃO PARA CADASTRAR OU ALTERAR)*/
      if ((this.permissao[0].acao[0].PERMITE === 'S' || this.permissao[0].acao[1].PERMITE === 'S' || this.permissao[0].acao[2].PERMITE === 'S') && rota === '/barbearia') {
        return true;
      } else
        if ((this.permissao[1].acao[0].PERMITE === 'S' || this.permissao[1].acao[1].PERMITE === 'S' || this.permissao[1].acao[2].PERMITE === 'S') && rota === '/barbeiro') {
          return true;
        }
        else
          if ((this.permissao[2].acao[0].PERMITE === 'S' || this.permissao[2].acao[1].PERMITE === 'S' || this.permissao[2].acao[2].PERMITE === 'S') && rota === '/servico') {
            return true;
          }
          else
            if ((this.permissao[3].acao[0].PERMITE === 'S' || this.permissao[3].acao[1].PERMITE === 'S' || this.permissao[3].acao[2].PERMITE === 'S') && rota === '/horarios') {
              return true;
            }
            else
              if ((this.permissao[4].acao[0].PERMITE === 'S' || this.permissao[4].acao[1].PERMITE === 'S' || this.permissao[4].acao[2].PERMITE === 'S') && rota === '/informativos') {
                return true;
              }
              else
                if ((this.permissao[5].acao[0].PERMITE === 'S' || this.permissao[5].acao[1].PERMITE === 'S' || this.permissao[5].acao[2].PERMITE === 'S') && rota === '/pesquisa') {
                  return true;
                }
                else
                  if ((this.permissao[7].acao[0].PERMITE === 'S' || this.permissao[7].acao[1].PERMITE === 'S') && rota === '/comissao') {
                    return true;
                  }
      // else
      // if ((this.permissao[8].acao[0].PERMITE === 'S' || this.permissao[8].acao[1].PERMITE === 'S' || this.permissao[8].acao[1].PERMITE === 'S') && rota === '/comissao') {
      //   return true;
      // }
    }
    return false;
  }



}
