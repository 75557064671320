<p-toast position="bottom-center" key="bc"></p-toast>
<p-toast position="top-right" key="tr"></p-toast>
<div class="pt-7">

    <div class="flex flex-column  pb-3">
        <div class="flex align-items-center justify-content-center">
            <img src="./assets/iconeCadastrarBarbeiros.svg" class="iconeCadastrarBarbeiro" />
        </div>
        <div class=" flex align-items-center justify-content-center">
            <span
                class=" tituloNegritoBarbeiro maximoCaracterBarbeiro white-space-nowrap overflow-hidden text-overflow-ellipsis">
                {{exibeNome}} </span>
        </div>
    </div>


    <div class="flex justify-content-center flex-wrap ">
        <div class="xl:col-5 lg:col-5 md:col-5 sm:col-5">
            <div class="shadow-4">
                <p-card id="cardBarbeiro">

                    <ng-template pTemplate="header">
                        <div class="col">
                            <div class="flex align-items-center gap-2 xl:gap-3 pl-2">
                                <img [src]="filename" class="fotoBarbeiro" *ngIf="filename!==''" />
                                <img src="./assets/iconeCadastrarBarbeiros.svg" class="fotoBarbeiro" *ngIf="filename ===''" />
                                <div class="tituloNegritoBarbeiro2">{{ exibeNome }}</div>
                            </div>
                        </div>

                    </ng-template>
                    <div>
                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            (click)="mostraDialogInfo()">
                            <div class="flex gap-2 ">
                                <img src="./assets/imgCredencialCadastro.svg" class="iconeCadastroBarbeiro"
                                    class=" xl:w-3rem" />
                                <div class="flex flex-column align-items-start">
                                    <div class=" fontTituloBarbeiro">
                                        <span class="xl:text-xl">Informações Pessoais</span>
                                    </div>
                                    <div class="fontCinzaBarbeiro">
                                        <span class="xl:text-base">Preencher Nome, Apelido e E-mail</span>
                                    </div>
                                </div>
                            </div>
                            <div class="align-items-center justify-content-center ">
                                <img src="./assets/imgBarbeariaPopup.png" class="iconePopUpBarbeiro" />
                            </div>
                        </div>

                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            (click)="mostraDialogImagens()">
                            <!-- <div class="col"> -->
                            <div class="flex gap-2 ">
                                <img src="./assets/imgCamera.svg" class="iconeCamera" class=" xl:w-3rem" />
                                <div class="flex flex-column align-items-start">
                                    <div class="fontTituloBarbeiro">
                                        <span class="xl:text-xl">Foto do Profissional</span>
                                    </div>
                                    <div class="fontCinzaBarbeiro ">
                                        <span class="xl:text-base">Seus clientes visualizarão essa foto ao
                                            escolher um profissional</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex align-items-center justify-content-end ">
                                <img src="./assets/imgBarbeariaPopup.png" />
                            </div>

                            <!-- </div> -->
                        </div>

                        <div class="formgroup-inline justify-content-between flex-wrap "
                            (click)="mostraDialogAtivarDesativarProfissional()">
                            <div class="flex gap-2 ">
                                <img src="./assets/imgLigarCadastro.svg" class="iconeCadastroBarbeiro "
                                    class=" xl:w-3rem" />
                                <div class="flex flex-column align-items-start">
                                    <div class=" fontTituloBarbeiro">
                                        <span class="xl:text-xl">Ativar/Desativar </span>
                                    </div>
                                    <div class="fontCinzaBarbeiro">
                                        <span class="xl:text-base">Tornar funcionário ativo para executar
                                            serviços</span>
                                    </div>
                                </div>
                            </div>
                            <div class="align-items-center justify-content-center">
                                <img src="./assets/imgBarbeariaPopup.png" class="iconePopUpBarbeiro" />
                            </div>
                        </div>


                    </div>
                </p-card>
            </div>
        </div>
        <!--                          DIALOG DO BARBEIRO              -->
        <p-dialog header="Dados do Barbeiro" [(visible)]="showInfoPessoal" [modal]="true"
            [style]="{'width': '350px', 'height': '370px'}" id="dialogBarbeiro">
            <div class="col-12">
                <div class="fontDialogBarbeiro flex align-items-center justify-content-center ">
                    <span>Informe alguns dados do barbeiro</span>
                </div>
            </div>
            <input type="text" pInputText placeholder="Nome" [(ngModel)]="nomefunc" [style]="{width: '100%'}">
            <input type="text" pInputText placeholder="Apelido" [(ngModel)]="apelido" [style]="{width: '100%'}"
                class="mt-1 mb-1">
            <input type="text" pInputText placeholder="E-mail" [(ngModel)]="email" [style]="{width: '100%'}"
                class="mt-1 mb-1">
            <p-dropdown id="drop" [options]="estabelecimento" [(ngModel)]="estabelecimentoSelecionado" placeholder="Barbearia"
                optionLabel="nome" optionValue="codemp" [style]="{'width': '100%'}" id="drop" scrollHeight="80px">
            </p-dropdown>



            <div *ngIf="!loading" [style]="{width: '100%'}">
                <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                    'border-radius': '11px'}" (click)="salvarFuncionario()"></button>
            </div>
            <div *ngIf="loading" [style]="{width: '100%'}">
                <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 mt-3"
                    [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                    'border-radius': '11px'}"></button>
            </div>
        </p-dialog>



        <!--                          DIALOG DAS IMAGENS              -->
        <p-dialog header="Foto do Profissional" [(visible)]="showImagens" [modal]="true"
            [style]="{'width': '350px', 'height': '220px'}" [closable]="true" id="dialogBarbeiro">
            <div class="flex flex-column  align-items-center">
                <div class="flex justify-content-center flex-wrap col">
                    <div class="fontDialogBarbeiro flex align-items-center justify-content-center ">
                        <span>Informe a foto de seu profissional.</span>
                    </div>
                </div>

                <input #fileInput accept="image/png, image/gif, image/jpeg" type="file" id="botaoImg"
                    (change)="mudaNomeFoto()" />

                <div class="mt-2 ">
                    <div class="flex align-items-center justify-content-start labelImagem">
                        <label for="">
                            <img src="./assets/uploadImgs.png" class="ajustaInputImagem pl-2" />
                        </label>
                        <div class="fontUploadImagem flex align-items-center justify-content-center pl-2 ">
                            <label for="botaoImg" class=""> {{nomeImagem}}</label>
                        </div>
                    </div>
                </div>



                <div *ngIf="!loading" [style]="{width: '100%'}">
                    <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
            'border-radius': '11px'}" (click)="onFileUpload()"></button>
                </div>
                <div *ngIf="loading" [style]="{width: '100%'}">
                    <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando"
                        class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
            'border-radius': '11px'}"></button>
                </div>
            </div>
        </p-dialog>











        <!--                          DIALOG ATIVA/DESATIVA             -->

        <p-dialog header="Ativar/ Desativar Barbeiro" [(visible)]="showAtivarDesativarProfissional" [modal]="true"
            [style]="{'width': '350px', 'height': '290px'}" id="dialogAtvDstBarbeiro">
            <div class="flex justify-content-center flex-wrap col">
                <div class="fontDialogAtvBarbeiro flex align-items-center justify-content-center">
                    <span>Se ativo, o barbeiro estará disponível como opção para realizar serviços</span>
                </div>
            </div>
            <div class="fontDialogAtvObsBarbeiro flex align-items-center justify-content-center pb-4 pt-2">
                <span>Obs: Para ativar o barbeiro, é necessário que ele tenha conta!</span>
            </div>

            <div class="p-field-radiobutton pb-1">
                <p-radioButton value="S" [(ngModel)]="valorSelecionadoProfissional"></p-radioButton>
                <label class="pl-2">Ativar barbeiro</label>
            </div>
            <div class="p-field-radiobutton pb-1">
                <p-radioButton value="N" [(ngModel)]="valorSelecionadoProfissional"></p-radioButton>
                <label class="pl-2">Desativar barbeiro</label>
            </div>

            <div *ngIf="!loading" [style]="{width: '100%'}">
                <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                        'border-radius': '11px'}" (click)="ativaDesativaProfissional()"></button>
            </div>
            <div *ngIf="loading" [style]="{width: '100%'}">
                <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 mt-3"
                    [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                        'border-radius': '11px'}"></button>
            </div>
        </p-dialog>
    </div>
    <br>