import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { AlertService } from 'src/alert.service';
import { Estabelecimento } from 'src/assets/estabelecimento';
import { Profissional } from 'src/assets/profissional';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { Servico } from 'src/assets/servico';
import { UtilitariosService } from 'src/assets/utilitarios.service';
import { EstabelecimentoService } from 'src/estabelecimento.service';
import { ProfissionalService } from 'src/profissional.service';
import { DescontoService } from 'src/desconto.service';
import { PermissoesService } from 'src/permissoes.service';
import { ServicoService } from 'src/servico.service';
import { TraducaoService } from 'src/traducao.service';

@Component({
  selector: 'app-cadastrar-cupom',
  templateUrl: './cadastrar-cupom.component.html',
  styleUrls: ['./cadastrar-cupom.component.scss'],
  providers: [MessageService, ConfirmationService],
  encapsulation: ViewEncapsulation.None
})
export class CadastrarCupomComponent implements OnInit {
  showCupom: boolean = false;
  caracteresRestantes: number = 0;

  /**VARIAVEIS DO BOTAO DE LOADING */
  teveRespostaEstabelecimento: boolean = false;
  teveRespostaServico: boolean = false;
  showAtivarDesativarCupom: boolean = false;

  /**DATA */
  data: Date;
  ontem: Date;
  dataInicial: any;
  dataFinal: any;
  dataInicialCupom: any;
  dataFinalCupom: any;

  /**MENU */
  itemsCupons: MenuItem[] = [];

  /**RADIO BUTTON */
  radioButtonSelecionado: string = '';

  descVazio: any;

  nucup: number = 0;
  codcli: number = 0;
  codusuresp: string = '';
  nomecupom: string = '';
  descrcupom: string = '';
  estabelecimento: Estabelecimento[] = [];
  estabelecimentoSelecionado: any;
  profissional: Profissional[] = [];
  profissionalSelecionado: any;
  servicoSelecionado: any;
  servico: Servico[] = [];
  qtdmax: any;
  tipoCupom: any[];
  tipdesc: string = '';
  desc: any;
  loading: boolean = false;
  todasEstabelecimentos: any;
  todosProfissionals: any;
  desabilitaServico: boolean = false;
  tamanhoCampoQtd: number = 0;
  cupom: any[] = [];
  cupomSelec: any;


  /**VARIAVEIS DE PERMISSÕES */
  permissao: any;
  alterar: boolean = false;
  excluir: boolean = false;
  cadastrar: boolean = false
  inativar: boolean = false;

  constructor(private servicoService: ServicoService, private estabelecimentoService: EstabelecimentoService, private messageService: MessageService, private traducaoService: TraducaoService,
    private profissionalService: ProfissionalService, private decodedTokenService: DecodedTokenService, private alertService: AlertService, private descontoService: DescontoService,
    private confirmationService: ConfirmationService, private utilitariosService: UtilitariosService, private permissoesService: PermissoesService) {
    /**TRADUÇÃO */
    this.traducaoService.changeLang();

    this.data = new Date();
    this.ontem = new Date(this.data);
    this.ontem.setDate(this.data.getDate());

    this.tipoCupom = [{ 'tipo': 'P', 'opcao': '% Percentual' }, { 'tipo': 'R', 'opcao': 'R$ Valor' }];
    this.todasEstabelecimentos = { "nome": "Todas Estabelecimentos", "codemp": 0 };
    this.todosProfissionals = { "nomefunc": "Todos Profissionals", "codfunc": 0 };
  }

  ngOnInit(): void {
    this.recuperaCodUsu();
    this.recuperaCodCli();
    this.recuperaPermissao();
    this.buscarEstabelecimentosPorCodCliente();
    this.buscaCupomPorCodCli();




    this.itemsCupons = [{
      items: [{
        label: 'Detalhes',
        command: () => {
          this.preencheDadosDialogCupom();
        }
      }
      ]
    },
    {
      items: [{
        label: 'Ativa/Desativa',
        styleClass: 'linhaMenu',
        command: () => {
          this.mostraDialogAtvDstCupom();
        }
      }
      ]
    },
    {
      items: [{
        label: 'Excluir',
        styleClass: 'linhaMenu',
        command: () => {
          this.confirmaExclusaoCupom();
        }
      }
      ]
    }
    ];
  }

  /**RECUPERA O CODUSU PARA SER USADO NA AUDITORIA DOS METODOS */
  recuperaCodUsu() {
    this.codusuresp = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }

  /**RECUPERA O CODCLI PARA SER USADO NA BUSCA DAS BARBEARIAS */
  recuperaCodCli() {
    this.codcli = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODCLI;
  }

  /**METODO QUE ABRE O POPUP DE DESCONTOS, OS CAMPOS SÃO ZERADOS */
  mostraDialogCupom() {

    /**VERIFICA SEM TEM PERMISSÃO PARA ESSA AÇÃO */
    if ((this.cadastrar && this.permissao != undefined && this.permissao.length > 0) || (this.codcli > 0 && (this.permissao === undefined || this.permissao.length === 0))) {
      this.nomecupom = '';
      this.descrcupom = '';
      this.estabelecimentoSelecionado = '';
      this.profissionalSelecionado = '';
      this.servicoSelecionado = '';
      this.qtdmax = '';
      this.dataInicial = '';
      this.dataFinal = '';
      this.tipdesc = '';
      this.desc = '';
      this.showCupom = true;
      this.descVazio = null;
    } else {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
  }

  mostraDialogAtvDstCupom() {
    /**VERIFICA SEM TEM PERMISSÃO PARA ESSA AÇÃO */
    if ((this.inativar && this.permissao != undefined && this.permissao.length > 0) || (this.codcli > 0 && (this.permissao === undefined || this.permissao.length === 0))) {
      this.radioButtonSelecionado = this.cupomSelec.ativo;
      this.showAtivarDesativarCupom = true;
    }
    else {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
  }


  /**METODO QUE RECUPERA A LISTA DE BARBEARIAS E OS COLOCA NO DROPDOWN*/
  buscarEstabelecimentosPorCodCliente() {


    this.estabelecimentoService.buscarEstabelecimentosporCodCli(this.codcli, this.codusuresp).subscribe((res) => {
      this.estabelecimento = res;

      /**INSERE A OPÇÃO DE TODAS BARBEARIAS */
      this.estabelecimento.push(this.todasEstabelecimentos);
      this.estabelecimento.sort(function (x, y) {
        return Number(x.codemp) - Number(y.codemp);
      })
    },
      (error) => {
        console.log(error.message)
        console.log('deu ruim')

      })


  }


  /**METODO QUE RECUPERA A LISTA DE BARBEIROS E OS COLOCA NO DROPDOWN*/
  buscarProfissionals() {
    this.teveRespostaEstabelecimento = true;




    /**VERIFICA SE TODAS BARBEARIAS FOI SELECIONADO (CODEMP = 0)*/
    if (this.estabelecimentoSelecionado === 0) {
      /**SE SIM, BUSCA TODOS OS BARBEIROS, POR CODCLI */
      setTimeout(() => {
        this.profissionalService.buscarProfissionalporCodCli(this.codcli, this.codusuresp).subscribe((res) => {
          this.teveRespostaEstabelecimento = false;
          this.profissional = res;

          /**INSERE A OPÇÃO DE TODOS BARBEIROS */
          this.profissional.push(this.todosProfissionals);
          this.profissional.sort(function (x, y) {
            return Number(x.codfunc) - Number(y.codfunc);
          })
        },
          (error) => {
            this.teveRespostaEstabelecimento = false;
            console.log(error.message)
            console.log('deu ruim')

          })
      }, 500);
    } else {
      /**SE NÃO BUSCA, BUSCA OS BARBEIROS PELA EMPRESA SELECIONADA, CODEMP */
      setTimeout(() => {
        this.profissionalService.buscarProfissionalporCodEmp(this.estabelecimentoSelecionado, this.codusuresp).subscribe((res) => {
          this.teveRespostaEstabelecimento = false;
          this.profissional = res;
        },
          (error) => {
            this.teveRespostaEstabelecimento = false;
            console.log(error.message)
            console.log('deu ruim')

          })
      }, 500);
    }

  }

  /**METODO QUE RECUPERA A LISTA DE SERVIÇOS E OS COLOCA NO DROPDOWN*/
  buscarServicoPorCodFuncionario(profissionalSelecionado: any) {
    console.log(profissionalSelecionado)
    this.desabilitaServico = false;
    this.teveRespostaServico = true;
    console.log(this.profissionalSelecionado)
    if (this.profissionalSelecionado === 0) {
      this.teveRespostaServico = false;
      this.desabilitaServico = true;
      this.servicoSelecionado = null;
      return
    }
    setTimeout(() => {

      this.servicoService.buscarServicoPorCodFunc(profissionalSelecionado, this.codusuresp).subscribe((res) => {
        this.teveRespostaServico = false;
        this.servico = res;
        console.log(this.servico)
      },
        (error) => {
          console.log(error.message)
          console.log('deu ruim')
          this.teveRespostaServico = false;
          this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')

        })
    }, 500);

  }

  /**METODO PARA CONTAR OS CARACTERES DA PERGUNTA */
  contaCaracter() {
    if (this.caracteresRestantes < 151) {
      this.caracteresRestantes = this.descrcupom.length;
    } else {
      return;
    }
  }

  /**METODO QUE RECUPERA A LISTA DE CUPONS CADASTRADOS*/
  buscaCupomPorCodCli() {
    this.cupom = [];

    this.descontoService.buscaCuponsPorCodCli(this.codcli, this.codusuresp).subscribe((res) => {
      this.cupom = res;
      console.log(this.cupom)
    },
      (error) => {
        console.log(error.message);
        console.log('deu ruim');
        this.alertService.alert('Algo deu errado.', 'error');
      })

  }

  /**METODOS PARA PREENCHIMENTO DOS DADOS DO CUPOM SELECIONADO EM 'Detalhes' */
  cupomSelecionado(cupom: any) {
    this.cupomSelec = cupom;
    console.log(this.cupomSelec)
  }

  /**SEMPRE QUE MUDAR A OPÇÃO DO TIPO DE DESCONTO VAI ZERAR O CAMPO DE DESCONTO */
  zeraCampoDesconto() {
    this.desc = 0;
  }

  /**METODO QUE PREENCHE O DIALOG DE CUPOM APOS CLICAR EM 'Detalhes'*/
  preencheDadosDialogCupom() {

    /**VERIFICA SEM TEM PERMISSÃO PARA ESSA AÇÃO */
    if ((this.alterar && this.permissao != undefined && this.permissao.length > 0) || (this.codcli > 0 && (this.permissao === undefined || this.permissao.length === 0))) {
      console.log(this.cupomSelec.nomecupom);
      this.nucup = this.cupomSelec.nucup;
      this.nomecupom = this.cupomSelec.nomecupom;
      this.descrcupom = this.cupomSelec.descrcupom;

      /**CONTA OS CARACTERES DA DESCRIÇÃO */
      this.contaCaracter();

      this.estabelecimentoSelecionado = this.cupomSelec.codemp;
      /**FAZ A BUSCA DOS BARBEIROS PARA O DROPDOWN*/
      this.buscarProfissionals();
      this.profissionalSelecionado = this.cupomSelec.codfunc;

      /**FAZ A BUSCA DOS SERVIÇOS  PARA O DROPDOWN*/
      this.buscarServicoPorCodFuncionario(this.profissionalSelecionado);
      this.servicoSelecionado = JSON.parse(this.cupomSelec.codprod);
      console.log(this.servicoSelecionado)
      //this.servicoSelecionado = 23
      this.qtdmax = this.cupomSelec.qtdmax;


      if (this.cupomSelec.dtvigini != null && this.cupomSelec.dtvigfin != null) {
        this.dataInicial = new Date(this.cupomSelec.dtvigini + 'GMT-0300'); //'GMT-0300' PARA FICAR NO FUSO HORÁRIO DO BRASIL (SE NÃO FIZER ISSO FICA 1 DIA ANTERIOR)
        this.dataFinal = new Date(this.cupomSelec.dtvigfin + 'GMT-0300'); //'GMT-0300' PARA FICAR NO FUSO HORÁRIO DO BRASIL (SE NÃO FIZER ISSO FICA 1 DIA ANTERIOR)
        console.log(this.dataInicial)
        console.log(this.dataFinal)
      }

      this.tipdesc = this.cupomSelec.tipdesc;
      this.desc = this.cupomSelec.desc;
      this.showCupom = true;
    }
    else {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
  }

  /**METODO DE CRIAÇÃO/EDIÇÃO DO CUPOM */
  criaCupom() {
    this.loading = true;

    if (this.profissionalSelecionado === 0) {
      this.servicoSelecionado = null
    }

    if (this.utilitariosService.validaCampo([this.nomecupom, this.descrcupom, this.qtdmax,] || !(this.estabelecimentoSelecionado >= 0)) || this.estabelecimentoSelecionado === ''
      || this.profissionalSelecionado === '' || this.dataInicial === '' || this.dataFinal === '') {
      console.log('É necessário preencher todos os campos.')
      this.loading = false;
      this.showError('Atenção', 'É necessário preencher todos os campos.')
      return;
    }


    /**DEIXA AS DATAS NO PADRÃO QUE O BANCO ESPERA: YYYY-MM-DD */
    let auxInicial = this.dataInicial.getFullYear() + '-' + ('0' + (this.dataInicial.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataInicial.getDate()).slice(-2);
    let auxFinal = this.dataFinal.getFullYear() + '-' + ('0' + (this.dataFinal.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataFinal.getDate()).slice(-2);


    if (this.nucup > 0) {
      this.descontoService.createCupom(this.cupomSelec.nucup, this.nomecupom.toUpperCase(), this.descrcupom, this.codcli, this.estabelecimentoSelecionado, this.profissionalSelecionado, this.servicoSelecionado,
        this.qtdmax, auxInicial, auxFinal, this.tipdesc, this.desc, this.codusuresp).subscribe((res) => {
          this.alertService.alert('Cupom alterado!', 'success')
          this.loading = false;
          this.showCupom = false;
          this.buscaCupomPorCodCli();
          console.log(res)
        },
          (error) => {
            console.log('deu ruim')
            this.loading = false;
            this.showCupom = false;
            this.alertService.alert('Algo deu errado.', 'error');
          })
    } else {
      this.descontoService.createCupom(null, this.nomecupom.toUpperCase(), this.descrcupom, this.codcli, this.estabelecimentoSelecionado, this.profissionalSelecionado, this.servicoSelecionado,
        this.qtdmax, auxInicial, auxFinal, this.tipdesc, this.desc, this.codusuresp).subscribe((res) => {
          this.alertService.alert('Cupom criado!', 'success')
          this.loading = false;
          this.showCupom = false;
          this.buscaCupomPorCodCli();
          console.log(res)
        },
          (error) => {
            console.log('deu ruim')
            this.loading = false;
            this.showCupom = false;
            this.alertService.alert('Algo deu errado.', 'error');
          })
    }

  }



  /**METODOS DE EXCLUSÃO DO CUPOM */
  excluiCupomPorNucup() {
    this.descontoService.excluiCupomPorNucup(this.cupomSelec.nucup!, this.codusuresp).subscribe((res) => {
      console.log(res);
      this.cupom = this.cupom.filter((x: any) => x.nucup != res.nucup); //FILTRA TUDO O QUE É DIFERENTE DO QUE FOI EXCLUIDO

    });
  }

  confirmaExclusaoCupom() {
    /**VERIFICA SEM TEM PERMISSÃO PARA ESSA AÇÃO */
    if ((this.excluir && this.permissao != undefined && this.permissao.length > 0) || (this.codcli > 0 && (this.permissao === undefined || this.permissao.length === 0))) {
      this.confirmationService.confirm({
        message: 'Deseja mesmo excluir ' + this.cupomSelec.nomecupom + ' ?',
        header: 'Excluir Profissional',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.excluiCupomPorNucup();
        },
        reject: () => {
        }
      });
    }
    else {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
  }




  /**METODO PARA ATIVAR/DESATIVAR O CUPOM */
  ativaDesativaCupom() {
    this.loading = true;
    if (this.cupomSelec.ativo != this.radioButtonSelecionado) {
      this.descontoService.ativarDesativarCupomPorNucup(this.cupomSelec.nucup, this.codusuresp).subscribe((res) => {
        console.log(res)
        this.showAtivarDesativarCupom = false;
        if (Object(res).ativo === 'S') {
          this.showAtivarDesativarCupom = false;
          this.loading = false;
          this.alertService.alert('Cupom ativado!', 'success')
        }
        if (Object(res).ativo === 'N') {
          this.showAtivarDesativarCupom = false;
          this.loading = false;
          this.alertService.alert('Cupom desativado!', 'success')
        }
        this.buscaCupomPorCodCli();
      },
        (error) => {
          console.log(error.message)
          console.log('deu ruim')
          this.loading = false;
          this.showAtivarDesativarCupom = false;
          this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')
        });
    } else {
      this.loading = false;
    }
  }


  /**METODO DE TOAST */
  showError(titulo: string, mensagem: string) {
    if (screen.width < 640 || screen.height < 480) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else if (screen.width < 1024 || screen.height < 768) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else {
      this.messageService.add({
        key: 'tr',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    }
  }


  /**RECUPERA AS PERMISSÕES */
  recuperaPermissao() {
    console.log('recuperei as permissoes')
    this.permissao = this.permissoesService.getPermissao();
    console.log(this.permissao)
    if (this.permissao != undefined && this.permissao.length > 0) {
      if (this.permissao[8].acao[0].PERMITE === 'S') {
        this.cadastrar = true;
      }
      if (this.permissao[8].acao[1].PERMITE === 'S') {
        this.alterar = true;
      }
      if (this.permissao[8].acao[2].PERMITE === 'S') {
        this.excluir = true;
      }
      if (this.permissao[8].acao[3].PERMITE === 'S') {
        this.inativar = true;
      }
      // /**ATRIBUI O CODCLI VINCULADO NA PERMISSAO DO FUNCIONARIO, PARA FAZER AS OPERAÇÕES DA TELA */
      this.codcli = this.permissao[0].codcli;
    }
  }

}
