<p-toast position="bottom-center" key="bc"></p-toast>
<p-toast position="top-right" key="tr"></p-toast>
<div class="pt-7">

    <div class="flex flex-column  pb-3">
        <div class="flex align-items-center justify-content-center">
            <img src="./assets/iconeMovFinanceiro.svg" class="iconeCadastrarServico" />
        </div>

        <div class=" flex align-items-center justify-content-center">
            <span class="tituloNegritoFinanceiro  ">Movimento Financeiro</span>
        </div>
        <div class=" flex align-items-center justify-content-center">
            <span class="fontFiltros">Extratos, movimentações, saldo...</span>
        </div>
    </div>

    <div class=" flex justify-content-center flex-wrap">
        <div class=" sm-8 md-6 xl:col-7 lg:col-7 md:col-7">
            <div>

                <div class="shadow-4">
                    <p-card id="cardFinanceiro" class="shadow-4">
                        <div class="flex flex-column col-12">
                            <div class=" flex justify-content-center flex-wrap pb-1">
                                <span class="fontDescCard">Visualize, crie, edite ou remova movimentos
                                    financeiros</span>
                            </div>

                            <div class="contorno1Filtros pb-1">
                                <span class="fontFiltros">Filtros</span>
                            </div>

                            <div class="filtros">
                                <!-- <input type="checkbox" id="check" (click)="mostraFiltros()"> -->

                                <div
                                    class="formgroup-inline xl:justify-content-evenly lg:justify-content-evenly md:justify-content-evenly">
                                    <div>
                                        <div class="flex flex-column ajustaChecks">
                                            <div class="field-checkbox xl:gap-1 ">
                                                <p-checkbox id="checkboxFinanceiro" value="R" [(ngModel)]="receitas">
                                                </p-checkbox>

                                                <span class="fontFiltros pb-1">Receitas</span>
                                            </div>

                                            <div class="field-checkbox xl:gap-1 pt-2 xl:pt-4 lg:pt-4 md:pt-4">
                                                <p-checkbox id="checkboxFinanceiro" value="D" [(ngModel)]="despesas">
                                                </p-checkbox>
                                                <span class="fontFiltros  pb-1">Despesas</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div class="flex flex-column ajustaChecks">
                                            <div class="field-checkbox xl:gap-1  ">
                                                <p-checkbox id="checkboxFinanceiro" value="R" [(ngModel)]="real">
                                                </p-checkbox>
                                                <span class="fontFiltros  pb-1">Real</span>
                                            </div>

                                            <div class="field-checkbox xl:gap-1 pt-2 xl:pt-4 lg:pt-4 md:pt-4">
                                                <p-checkbox id="checkboxFinanceiro" value="P" [(ngModel)]="provisao">
                                                </p-checkbox>
                                                <span class="fontFiltros  pb-1">Provisão</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div class="flex flex-column ajustaChecks">
                                            <div class="field-checkbox xl:gap-1 ">
                                                <p-checkbox id="checkboxFinanceiro" value="P" [(ngModel)]="pendentes">
                                                </p-checkbox>
                                                <span class="fontFiltros  pb-1">Pendentes</span>
                                            </div>

                                            <div class="field-checkbox xl:gap-1 pt-2 xl:pt-4 lg:pt-4 md:pt-4">
                                                <p-checkbox id="checkboxFinanceiro" value="B" [(ngModel)]="baixados">
                                                </p-checkbox>
                                                <span class="fontFiltros  pb-1">Baixados</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div class="flex flex-column ajustaBotoes">
                                            <div class=" pl-1  paddingTop">
                                                <button pButton type="button" label="Buscar" id="buscar"
                                                    (click)="filtraFinanceiro() "></button>
                                            </div>

                                            <div class="  pl-1 pt-2 xl:pt-3">
                                                <button pButton type="button" label="Limpar" id="Botaofiltro"
                                                    (click)="limpaFiltros()"></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <!-- FILTROS ESCONDIDOS -->
                                <!-- <div class="filtrosExpandido "> -->
                                <div *ngIf="expandeFiltros">
                                    <div class="pt-1">
                                        <div class="xl:col paddingDropDownBusca espacamentoDropDown">
                                            <div class="formgrid grid ajustaDropDownBusca">
                                                <div class="field col">
                                                    <p-dropdown id="dropFinanceiro" [options]="estabelecimento"
                                                        [(ngModel)]="estabelecimentoSelecionadaBusca"
                                                        placeholder="Estabelecimento" optionLabel="nome"
                                                        optionValue="codemp" scrollHeight="130px" [filter]="true"
                                                        class="pl-1"
                                                        (onChange)="buscarProfissionals(estabelecimentoSelecionadaBusca);recuperaClientes()">
                                                    </p-dropdown>
                                                </div>
                                                <div class="field col">
                                                    <!-- <div *ngIf="!teveRespostaEstabelecimento" class=""> -->
                                                    <p-dropdown id="dropFinanceiro" [options]="clientes"
                                                        [(ngModel)]="parceiroSelecionadoBusca" placeholder="Parceiro"
                                                        optionLabel="nomeparc" optionValue="codparc"
                                                        scrollHeight="130px" [filter]="true">
                                                    </p-dropdown>
                                                    <!-- </div> -->
                                                    <!-- <div *ngIf="teveRespostaEstabelecimento" class="">
                                                        <p-button id="botaoSpinnerBusca" label="Parceiro"
                                                            [disabled]="true" class="p-button-text">
                                                            <i class="pi pi-spin pi-spinner teste flex-order-1"
                                                                style="font-size: 0.7rem"></i>
                                                        </p-button>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>

                                        <div class="xl:col paddingDropDownBusca espacamentoDropDown">
                                            <div class="formgrid grid ajustaDropDownBusca">
                                                <div class="field col">
                                                    <div *ngIf="!teveRespostaEstabelecimento" class="">
                                                        <p-dropdown id="dropFinanceiro" [options]="profissional"
                                                            [(ngModel)]="profissionalSelecionadoBusca"
                                                            placeholder="Funcionário" optionLabel="apelido"
                                                            optionValue="codfunc" scrollHeight="130px" [filter]="true"
                                                            class="pl-1">
                                                        </p-dropdown>
                                                    </div>
                                                    <div *ngIf="teveRespostaEstabelecimento" class="">
                                                        <p-button id="botaoSpinnerBusca" label="Funcionário"
                                                            [disabled]="true" class="p-button-text pl-1">
                                                            <i class="pi pi-spin pi-spinner teste flex-order-1"
                                                                style="font-size: 0.7rem"></i>
                                                        </p-button>
                                                    </div>
                                                </div>
                                                <div class="field col">
                                                    <p-dropdown id="dropFinanceiro" [options]="categoria"
                                                        [(ngModel)]="categoriaSelecionadaBusca" placeholder="Categoria"
                                                        optionLabel="descr" optionValue="nucat" scrollHeight="130px"
                                                        [filter]="true">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="xl:col paddingDropDownBusca espacamentoDropDown">
                                            <div class="formgrid grid ajustaDropDownBusca">
                                                <div class="field col">
                                                    <p-dropdown id="dropFinanceiro" [options]="origem"
                                                        [(ngModel)]="origemSelecionadaBusca" placeholder="Origem"
                                                        optionLabel="origem" optionValue="origem" scrollHeight="130px"
                                                        [filter]="true" class="pl-1">
                                                    </p-dropdown>
                                                </div>
                                                <div class="field col">
                                                    <p-dropdown id="dropFinanceiro" [options]="conta"
                                                        [(ngModel)]="contaSelecionadaBusca" placeholder="Conta"
                                                        optionLabel="descr" optionValue="nucon" scrollHeight="130px"
                                                        [filter]="true">
                                                    </p-dropdown>
                                                </div>

                                            </div>
                                        </div>


                                        <div class=" col p-fluid">
                                            <p-dropdown id="dropFinanceiroTipNeg" [options]="negociacaoBusca"
                                                [(ngModel)]="negociacaoBuscaSelec" placeholder="Tipo Negociação"
                                                optionLabel="descr" optionValue="codtipneg" scrollHeight="130px"
                                                [filter]="true">
                                            </p-dropdown>
                                        </div>

                                        <!-- VENCIMENTO -->
                                        <div class="col ajustaCalendario ">


                                            <div class="formgrid grid">
                                                <div class="field col">
                                                    <p-calendar [touchUI]="true" [readonlyInput]="false"
                                                        inputId="touchui" dateFormat="dd/mm/yy" [readonlyInput]="true"
                                                        id="calendarioFinanceiro" [(ngModel)]="dataInicialVenc"
                                                        placeholder="Dt. Venc. Inicial">
                                                    </p-calendar>
                                                </div>
                                                <div class="field col">
                                                    <p-calendar [touchUI]="true" [readonlyInput]="false"
                                                        inputId="touchui" dateFormat="dd/mm/yy"
                                                        [minDate]="dataInicialVenc" [readonlyInput]="true"
                                                        id="calendarioFinanceiro" [(ngModel)]="dataFinalVenc"
                                                        placeholder="Dt. Venc. Final">
                                                    </p-calendar>
                                                </div>
                                            </div>
                                        </div>


                                        <!-- LANÇAMENTO -->
                                        <div class=" col calendarios ajustaCalendario">
                                            <div class="formgrid grid">
                                                <div class="field col">
                                                    <p-calendar [touchUI]="true" [readonlyInput]="false"
                                                        inputId="touchui" dateFormat="dd/mm/yy" [readonlyInput]="true"
                                                        id="calendarioFinanceiro" [(ngModel)]="dataInicialLanc"
                                                        placeholder="Dt. Lanc. Inicial" [showClear]="true">
                                                    </p-calendar>
                                                </div>
                                                <div class="field col">
                                                    <p-calendar [touchUI]="true" [readonlyInput]="false"
                                                        inputId="touchui" dateFormat="dd/mm/yy"
                                                        [minDate]="dataInicialLanc" [readonlyInput]="true"
                                                        id="calendarioFinanceiro" [(ngModel)]="dataFinalLanc"
                                                        placeholder="Dt. Lanc. Final" [showClear]="true">
                                                    </p-calendar>
                                                </div>
                                            </div>

                                        </div>

                                        <!-- BAIXA -->
                                        <div class="col calendarios ajustaCalendario">
                                            <div class="formgrid grid">
                                                <div class="field col">
                                                    <p-calendar [touchUI]="true" [readonlyInput]="false"
                                                        inputId="touchui" dateFormat="dd/mm/yy" [readonlyInput]="true"
                                                        id="calendarioFinanceiro" [(ngModel)]="dataInicialBaixa"
                                                        placeholder="Dt. Baixa Inicial" [showClear]="true">
                                                    </p-calendar>
                                                </div>
                                                <div class="field col">
                                                    <p-calendar [touchUI]="true" [readonlyInput]="false"
                                                        inputId="touchui" dateFormat="dd/mm/yy"
                                                        [minDate]="dataInicialBaixa" [readonlyInput]="true"
                                                        id="calendarioFinanceiro" [(ngModel)]="dataFinalBaixa"
                                                        placeholder="Dt. Baixa Final" [showClear]="true">
                                                    </p-calendar>
                                                </div>
                                            </div>

                                        </div>


                                        <div class="campoDetalhes calendarios pt-2">
                                            <input type="text" [(ngModel)]="detalhesBusca" pInputText
                                                placeholder="Detalhes" class="inputFinanceiro" id="inputFinanceiro">
                                        </div>


                                    </div>

                                    <!-- BOTÃO QUE ESCONDE OS FILTROS-->
                                    <div class=" flex align-items-center justify-content-center "
                                        (click)="guardaFiltros()">
                                        <label for="check" class="cursor-pointer"><i
                                                class="pi pi-angle-double-up  "></i></label>
                                    </div>

                                </div>


                                <!-- BOTÃO QUE MOSTRA OS FILTROS-->
                                <label for="check" class="flex align-items-center justify-content-center cursor-pointer"
                                    (click)="mostraFiltros()" *ngIf="!expandeFiltros"><i
                                        class="pi pi-angle-double-down pt-1"></i></label>

                            </div>
                        </div>

                        <!-- BOTÕES DA TABELA -->
                        <div class=" col-12">
                            <div class="contornoBotoes">

                                <!-- <div class="flex flex-wrap gap-1 pl-2 pb-2 xl:gap-1 "> -->

                                <div class="flex flex-wrap  gap-1 pl-2 ">


                                    <!-- <button id="cadastrarFinanceiro" pButton type="button" class="col-12 mt-2" label="Cadastrar"
                                    (click)="mostraDialogCadLancamento()"></button>
                                    <button id="excluirFinanceiro" pButton type="button" class="col-12 mt-2 " label="Excluir"></button> -->


                                    <button id="cadastrarFinanceiro" pButton type="button" class=" mt-2"
                                        label="Cadastrar" (click)="mostraDialogCadLancamento()"></button>


                                    <button id="excluirFinanceiro" pButton type="button" class=" mt-2 " label="Excluir"
                                        (click)="confirmaExcluirFinanceiro()"></button>


                                    <button pButton type="button" label="Baixar" class=" mt-2" id="tabelaFinanceiro"
                                        (click)="mostraDialogBaixa()"></button>


                                    <button pButton type="button" label="Estornar" class=" mt-2" id="tabelaFinanceiro2"
                                        (click)="confirmaEstornaFinanceiro()"></button>







                                    <!-- <button pButton type="button" label="Renegociar" class="col-12 mt-2"
                                    id="tabelaFinanceiro2"></button>
                                    <button pButton type="button" label="Compensar" class="col-12 mt-2"
                                    id="tabelaFinanceiro2"></button> -->

                                </div>
                            </div>


                            <!-- TABELA -->
                            <p-table [value]="lancamento" [scrollable]="true" scrollHeight="238px" id="tabela">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="width:1px">
                                            <!-- <p-checkbox id="checkboxFinanceiro" [value]="lancamento"
                                            [(ngModel)]="selecionouTodosLancamentosExcluir">
                                        </p-checkbox> -->
                                        </th>
                                        <th class="fontTituloTabela" style="width:20px">Tipo</th>
                                        <th class="fontTituloTabela" style="min-width:52px">Origem</th>
                                        <th class="fontTituloTabela" style="min-width:74px">Dt. Venc.</th>
                                        <th class="fontTituloTabela white-space-nowrap" style="min-width:74px">Vlr.
                                            Lanc.
                                        </th>
                                        <th class="fontTituloTabela" style="min-width:100px">Parceiro</th>
                                        <th class="fontTituloTabela" style="min-width:80px">Categoria</th>
                                        <th class="fontTituloTabela" style="min-width:96px">Estabelecimento</th>
                                        <th class="fontTituloTabela" style="min-width:80px">Profissional</th>
                                        <th class="fontTituloTabela" style="min-width:80px">Detalhes</th>
                                        <th class="fontTituloTabela" style="min-width:80px">Vlr. Baixa</th>
                                        <th class="fontTituloTabela" style="min-width:80px">Dt. Baixa</th>
                                        <th class="fontTituloTabela" style="min-width:80px">Conta</th>
                                        <th class="fontTituloTabela white-space-nowrap" style="min-width:80px ">Tp.
                                            Negoc.
                                        </th>
                                        <th style="min-width:1px" alignFrozen="right" pFrozenColumn
                                            (click)="menu.toggle($event);">
                                            <img class="cursor-pointer" src="./assets/iconeMenuAdm.png">
                                            <!-- <img class="cursor-pointer" src="./assets/excelFin.svg"
                                                (click)="geraRelatorioExcel()"> -->

                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-lancamento>
                                    <tr style="height:30px">
                                        <td style="width:1px">
                                            <p-checkbox id="checkboxFinanceiro" id="checkboxFinanceiro"
                                                [value]="lancamento" [(ngModel)]="lancamentoSelecionado"
                                                (click)="verificaCheckboxBaixa()">
                                            </p-checkbox>
                                        </td>
                                        <td *ngIf="lancamento.tipo === 'R'" style="width:20px">
                                            <img src="./assets/financeiroR.svg" />
                                        </td>
                                        <td *ngIf="lancamento.tipo === 'D'" style="width:20px">
                                            <img src="./assets/financeiroD.svg" />
                                        </td>
                                        <td class="fontConteudoTabela" style="min-width:50px">{{lancamento.origem}}
                                        </td>
                                        <td class="fontConteudoTabela" style="min-width:74px">{{lancamento.dtvenc |
                                            date:'dd/MM/yyyy'}}</td>
                                        <td class="fontConteudoTabela white-space-nowrap" style="min-width:74px">
                                            {{lancamento.vlrlanc | currency : 'BRL'}}</td>
                                        <td class="fontConteudoTabela maximoCaracter block white-space-nowrap overflow-hidden text-overflow-ellipsis reticenciasTabela"
                                            style="min-width:100px">{{lancamento.nomeparc}}
                                        </td>


                                        <td class="fontConteudoTabela " style="min-width:80px">{{lancamento.catdescr}}
                                        </td>
                                        <td class="fontConteudoTabela " style="min-width:80px">{{lancamento.nomeemp}}
                                        </td>
                                        <td class="fontConteudoTabela " style="min-width:80px">{{lancamento.apelido}}
                                        </td>
                                        <td class="fontConteudoTabela maximoCaracter white-space-nowrap overflow-hidden text-overflow-ellipsis reticenciasTabela block"
                                            style="min-width:80px">{{lancamento.detalhe}}</td>
                                        <td class="fontConteudoTabela maximoCaracter white-space-nowrap overflow-hidden text-overflow-ellipsis"
                                            style="min-width:80px">{{lancamento.vlrbaixa | currency : 'BRL'}}</td>
                                        <td class="fontConteudoTabela maximoCaracter white-space-nowrap overflow-hidden text-overflow-ellipsis"
                                            style="min-width:80px">{{lancamento.dtbaixa | date:'dd/MM/yyyy'}}</td>
                                        <td class="fontConteudoTabela maximoCaracter white-space-nowrap overflow-hidden text-overflow-ellipsis"
                                            style="min-width:80px">{{lancamento.descr}}</td>
                                        <td class="fontConteudoTabela " style="min-width:80px">
                                            {{lancamento.tipnegdescr}}</td>


                                        <td style="min-width:1px; height:30px" alignFrozen="right" pFrozenColumn>
                                            <img class="iconeTabela cursor-pointer"
                                                src="./assets/iconePesquisaTabela.svg"
                                                (click)="selecionouLancamentoEdicao(lancamento)" />
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>

                            <p-menu #menu [popup]="true" [model]="itemsRelatorio" styleClass="menuFinanceiro"></p-menu>

                        </div>
                        <br>
                        <div class=" flex justify-content-evenly flex-wrap">
                            <div class="fontConteudoTabela flex flex-column contornoVlrFinanca">
                                <span>Receitas:</span>
                                <span class=" font-bold white-space-nowrap">+ {{totalReceitas | currency : 'BRL'}}
                                </span>
                            </div>

                            <div class="fontConteudoTabela flex flex-column contornoVlrFinanca ">
                                <span>Despesas:</span>
                                <span class=" font-bold white-space-nowrap">- {{totalDespesas | currency :
                                    'BRL'}}</span>
                            </div>

                            <div class="fontConteudoTabela flex flex-column contornoVlrFinanca">
                                <span>Baixado:</span>
                                <!-- <span class=" font-bold white-space-nowrap"> + {{totalBaixado | currency : 'BRL'}} </span> -->
                                <span class=" font-bold white-space-nowrap">
                                    <span *ngIf="this.totalBaixado > 0">+</span>
                                    <!-- <span *ngIf="this.totalBaixado < 0"> - </span> -->
                                    {{totalBaixado | currency : 'BRL'}} </span>


                            </div>

                            <div class="fontConteudoTabela flex flex-column contornoVlrFinanca">
                                <span>À Baixar:</span>
                                <span class=" font-bold white-space-nowrap">
                                    <span *ngIf="this.totalABaixar > 0">+</span>
                                    {{totalABaixar | currency : 'BRL'}} </span>
                            </div>

                        </div>
                    </p-card>
                </div>
                <br>
            </div>
        </div>








        <!--                          DIALOG DE LANÇAMENTO               -->
        <p-dialog header="Lançamento Financeiro" [(visible)]="showLancamento" [modal]="true" [draggable]="false"
            [breakpoints]="{'960px': '534px', '640px': '335px'}" id="dialogLancamento">

            <!-- <div class="balao cursor-pointer">
            <div class="posicaoBotao">
                <img src="./assets/iconeMenuAdm.png" />
                <div class="msgBalao">
                    Copiar lançamento
                </div>
            </div>
        </div> -->



            <div class="posicaoBotao cursor-pointer">
                <img src="./assets/iconeMenuAdm.png" (click)="clicouMenuCopiaLancamento()" />
                <div *ngIf="botaoCopia">
                    <div class="msgBalao" (click)="copiaLancamento()">
                        Copiar lançamento
                    </div>
                </div>
            </div>








            <div class="flex justify-content-center flex-wrap col ajustaDialog">
                <div class="fontDialogLancamento flex align-items-center justify-content-center">
                    <span>Informações gerais sobre esse lançamento</span>
                </div>
            </div>


            <div class="flex justify-content-center flex-wrap col pb-3 ">
                <div class="fontDialogLancamento flex align-items-center justify-content-center gap-4">
                    <span>Dt. Lanc - {{dataLanc | date: 'dd/MM/yyyy'}}</span>
                    <span>Origem - {{origemLancamento}}</span>
                </div>
            </div>

            <div class="grid">
                <div class="col ">
                    <div class="grid  col ">
                        <div class="w-6">
                            <p-dropdown [options]="tipoLancamento" [(ngModel)]="tipoSelecionado" placeholder="*Tipo"
                                optionLabel="nome" optionValue="tipo" [style]="{'width': '100%'}" id="dropDialog"
                                scrollHeight="130px" [disabled]="desabilitaCamposLancamento">
                            </p-dropdown>
                        </div>

                        <!-- <div class="w-4 ">
                        <p-dropdown [options]="realProvi" [(ngModel)]="realProviSelecionado" placeholder="Real/Prov"
                            optionLabel="nome" optionValue="tipo" [style]="{'width': '100%'}" id="dropDialog"
                            scrollHeight="130px">
                        </p-dropdown>
                    </div> -->

                        <div class="w-6 ">
                            <p-calendar [touchUI]="true" [readonlyInput]="false" inputId="touchui" dateFormat="dd/mm/yy"
                                [required]="false" [readonlyInput]="true" id="calendarioFinanceiroBusca"
                                [(ngModel)]="dataVenc" placeholder="*Dt. Venc" [disabled]="desabilitaCamposAGDVND">
                            </p-calendar>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mb-1">
                <p-dropdown [options]="tipoNegociacao" [(ngModel)]="tipoNegociacaoSelecionada"
                    placeholder="Tipo de Negociação" optionLabel="conta" optionLabel="descr" optionValue="codtipneg"
                    [style]="{'width': '100%'}" id="dropDialog" scrollHeight="130px">
                </p-dropdown>
            </div>

            <div class="mb-1">
                <p-dropdown [options]="conta" [(ngModel)]="contaSelecionada" placeholder="Conta" optionLabel="conta"
                    optionLabel="descr" optionValue="nucon" [style]="{'width': '100%'}" id="dropDialog"
                    scrollHeight="130px">
                </p-dropdown>
            </div>

            <div class="mb-1">
                <p-dropdown [options]="categoria" [(ngModel)]="categoriaSelecionada" placeholder="Categoria"
                    optionLabel="nome" optionLabel="descr" optionValue="nucat" [style]="{'width': '100%'}"
                    id="dropDialog" scrollHeight="130px" >
                </p-dropdown>
            </div>



            <div class="mb-1">
                <p-dropdown [options]="estabelecimento" [(ngModel)]="estabelecimentoSelecionada"
                    placeholder="Estabelecimento" [disabled]="desabilitaCamposLancamento" optionLabel="nome"
                    optionValue="codemp" [style]="{'width': '100%'}" id="dropDialog" scrollHeight="130px"
                    (onChange)="buscarProfissionals(estabelecimentoSelecionada);recuperaClientes()">
                </p-dropdown>
            </div>


            <div class="mb-1">
                <div *ngIf=" teveRespostaEstabelecimento" class="">
                    <p-button id="botaoSpinner" label="Profissional" [disabled]="true" class="p-button-text">
                        <i class="pi pi-spin pi-spinner teste flex-order-1" style="font-size: 1rem"></i>
                    </p-button>
                </div>
                <div *ngIf="!teveRespostaEstabelecimento" class="">
                    <p-dropdown [options]="profissional" [(ngModel)]="profissionalSelecionado"
                        placeholder="Profissional" [disabled]="desabilitaCamposLancamento" optionLabel="apelido"
                        optionValue="codfunc" [style]="{'width': '100%'}" id="dropDialog" scrollHeight="130px">
                    </p-dropdown>
                </div>
            </div>



            <!-- <div *ngIf="teveRespostaEstabelecimento" class="">
                <p-button id="botaoSpinner" label="*Parceiro/Cliente" [disabled]="true" class="p-button-text">
                    <i class="pi pi-spin pi-spinner teste flex-order-1" style="font-size: 1rem"></i>
                </p-button>
            </div>
            <div *ngIf="!teveRespostaEstabelecimento" class=""> -->
            <p-dropdown [options]="clientes" [(ngModel)]="clienteSelecionado" placeholder="*Parceiro/Cliente"
                [disabled]="desabilitaCamposLancamento" optionLabel="nomeparc" optionValue="codparc"
                [style]="{'width': '100%'}" id="dropDialog" scrollHeight="130px" [filter]="true">
            </p-dropdown>
            <!-- </div> -->





            <div class=" pt-1">
                <textarea pInputTextarea placeholder="Detalhes" [(ngModel)]="detalhesLancamento" [autoResize]="true"
                    maxlength="150" class="expandeTextArea" [disabled]="desabilitaCamposAGDVND"></textarea>

            </div>

            <div class="pt-1 pb-1 p-fluid">
                <!-- <input type="number" pInputText [(ngModel)]="vlrLancamento" placeholder="Vlr Lançamento"
                [style]="{width: '100%'}" [min]="0" /> -->
                <p-inputNumber [(ngModel)]="vlrLancamento" mode="currency" currency="BRL" locale="pt-BR"
                    placeholder="*Vlr Lançamento" [minFractionDigits]="2" id="inputVlrLancamento"
                    [disabled]="desabilitaCamposAGDVND">
                </p-inputNumber>
                <!-- <p-inputNumber [(ngModel)]="vlrLancamento" inputId="currency-br" mode="currency" currency="BRL"
                locale="pt-BR" id="descontoServicoSelec" [min]="0" placeholder="Vlr Lançamento"> </p-inputNumber> -->

            </div>

            <div class="grid">
                <div class=" col">
                    <div class="grid  col">
                        <div class="w-6 ">
                            <!-- <input type="number" pInputText [(ngModel)]="vlrBaixa" placeholder="Vlr Baixa"
                            [style]="{width: '100%'}" [min]="0" inputId="currency-br" mode="currency" currency="BRL"
                            locale="pt-BR" /> -->
                            <p-inputNumber [(ngModel)]="vlrBaixa" inputId="currency-br" mode="currency" currency="BRL"
                                locale="pt-BR" id="inputVlrBaixa" [min]="0" placeholder="Vlr Baixa"
                                [disabled]="desabilitaCamposAGDVND"> </p-inputNumber>

                        </div>
                        <div class="w-6">
                            <p-calendar [touchUI]="true" [readonlyInput]="false" inputId="touchui" dateFormat="dd/mm/yy"
                                [required]="false" [readonlyInput]="true" id="calendarioFinanceiroBusca"
                                [(ngModel)]="dataBaixa" placeholder="Dt. Baixa" [showClear]="true"
                                [disabled]="desabilitaCamposAGDVND">
                            </p-calendar>
                        </div>
                    </div>
                </div>
            </div>


            <div *ngIf="!loading" [style]="{width: '100%'}">
                <button pButton type="button" label="Salvar" class="col-12 " [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
    'border-radius': '11px'}" (click)="criaFinanceiro()"></button>
            </div>
            <div *ngIf="loading" [style]="{width: '100%'}">
                <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 "
                    [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
    'border-radius': '11px'}"></button>
            </div>
        </p-dialog>
    </div>

    <!--                          DIALOG DE BAIXA             -->
    <p-dialog header="Baixa" [(visible)]="showBaixa" [modal]="true" [breakpoints]="{'960px': '339px', '640px': '335px'}"
        id="dialogBaixa">
        <div class="flex justify-content-center flex-wrap col">
            <div class="fontDialogLancamento flex align-items-center justify-content-center">
                <span>Informe o valor da baixa</span>
            </div>
        </div>


        <div class="flex justify-content-start flex-wrap fontVlrBaixado col"><span>Valor já baixado:
                {{vlrJaBaixado | currency : 'BRL'}}</span>
        </div>

        <div class="p-fluid pb-2">
            <!-- <input type="number" pInputText [(ngModel)]="vlrBaixa" placeholder="Vlr Baixa"
                            [style]="{width: '100%'}" [min]="0" inputId="currency-br" mode="currency" currency="BRL"
                            locale="pt-BR" /> -->
            <p-inputNumber [(ngModel)]="vlrParaBaixa" placeholder="Valor da baixa" inputId="currency-br" mode="currency"
                currency="BRL" locale="pt-BR" id="campoBaixa" [min]="0" [style]="{width: '100%'}">
            </p-inputNumber>
        </div>
        <!-- <div class="flex justify-content-start flex-wrap fontContadorCaracter col"><span>Valor já baixado:
            {{vlrJaBaixado | currency : 'BRL'}}</span>
    </div> -->

        <div class="pb-2">
            <p-calendar [touchUI]="true" [readonlyInput]="false" inputId="touchui" dateFormat="dd/mm/yy"
                [required]="false" [readonlyInput]="true" id="calendarioFinanceiroBusca" [(ngModel)]="dataParaBaixa"
                placeholder="Dt. Baixa">
            </p-calendar>
        </div>





        <div *ngIf="!loading" [style]="{width: '100%'}">
            <button pButton type="button" label="Salvar" class="col-12 " [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
    'border-radius': '11px'}" (click)="verificaAcaoBaixa()"></button>
        </div>
        <div *ngIf="loading" [style]="{width: '100%'}">
            <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 " [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
    'border-radius': '11px'}"></button>
        </div>
    </p-dialog>



    <!--                          DIALOG DE DESMEMBRA FINANCEIRO             -->

    <p-dialog header="Baixa (Valor restante)" [(visible)]="showDesmembraFinanceiro" [modal]="true" [draggable]="false"
        [breakpoints]="{'960px': '534px', '640px': '335px'}" id="dialogLancamento">

        <div class="flex justify-content-center flex-wrap col ajustaDialog">
            <div class="fontDialogLancamento flex align-items-center justify-content-center">
                <span>Informações gerais sobre esse lançamento</span>
            </div>
        </div>


        <div class="flex justify-content-center flex-wrap col pb-3 ">
            <div class="fontDialogLancamento flex align-items-center justify-content-center gap-4">
                <span>Dt. Lanc - {{dataLanc | date: 'dd/MM/yyyy'}}</span>
                <span>Origem - FIN</span>
            </div>
        </div>

        <div class="grid">
            <div class="col ">
                <div class="grid  col ">
                    <div class="w-6">
                        <p-dropdown [options]="tipoLancamento" [(ngModel)]="tipoSelecionado" placeholder="Tipo"
                            optionLabel="nome" optionValue="tipo" [style]="{'width': '100%'}" id="dropDialog"
                            scrollHeight="130px" [disabled]="true">
                        </p-dropdown>
                    </div>

                    <div class="w-6 ">
                        <p-calendar [touchUI]="true" [readonlyInput]="false" inputId="touchui" dateFormat="dd/mm/yy"
                            [required]="false" [readonlyInput]="true" id="calendarioFinanceiroBusca"
                            [(ngModel)]="dataVenc" placeholder="Dt. Venc">
                        </p-calendar>
                    </div>
                </div>
            </div>
        </div>

        <div class="mb-1">
            <p-dropdown [options]="tipoNegociacao" [(ngModel)]="tipoNegociacaoSelecionada"
                placeholder="Tipo de Negociação" optionLabel="conta" optionLabel="descr" optionValue="codtipneg"
                [style]="{'width': '100%'}" id="dropDialog" scrollHeight="130px">
            </p-dropdown>
        </div>

        <div class="mb-1">
            <p-dropdown [options]="conta" [(ngModel)]="contaSelecionada" placeholder="Conta" optionLabel="conta"
                optionLabel="descr" optionValue="nucon" [style]="{'width': '100%'}" id="dropDialog"
                scrollHeight="130px">
            </p-dropdown>
        </div>

        <div class=" pt-1">
            <textarea pInputTextarea placeholder="Detalhes" [(ngModel)]="detalhesLancamento" [autoResize]="true"
                maxlength="150" class="expandeTextArea"></textarea>

        </div>

        <div class="pt-1 pb-1 p-fluid">
            <p-inputNumber [(ngModel)]="vlrLancamento" mode="currency" currency="BRL" locale="pt-BR"
                placeholder="Vlr Lançamento" [minFractionDigits]="2" id="inputVlrLancamento" [disabled]="true">
            </p-inputNumber>
        </div>


        <div class="grid">
            <div class=" col">
                <div class="grid  col">
                    <div class="w-6 ">
                        <p-inputNumber [(ngModel)]="vlrBaixa" inputId="currency-br" mode="currency" currency="BRL"
                            locale="pt-BR" id="inputVlrBaixa" [min]="0" placeholder="Vlr Baixa"> </p-inputNumber>

                    </div>
                    <div class="w-6">
                        <p-calendar [touchUI]="true" [readonlyInput]="false" inputId="touchui" dateFormat="dd/mm/yy"
                            [showClear]="true" [required]="false" [readonlyInput]="true" id="calendarioFinanceiroBusca"
                            [(ngModel)]="dataBaixa" placeholder="Dt. Baixa">
                        </p-calendar>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="!loading" [style]="{width: '100%'}">
            <button pButton type="button" label="Salvar" class="col-12 " [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
    'border-radius': '11px'}" (click)="desmembraFinanceiro()"></button>
        </div>
        <div *ngIf="loading" [style]="{width: '100%'}">
            <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 " [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
    'border-radius': '11px'}"></button>
        </div>
    </p-dialog>



    <!--                          DIALOG DO CARREGANDO COPIA              -->
    <p-dialog id="dialogFinanceiro" [(visible)]="mostraCarregandoCopia" [modal]="true"
        [style]="{'width': '150px', 'height': '140px'}" [closable]="false">
        <div class="flex flex-column align-items-center mt-2">
            <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
                fill="var(--surface-ground)" animationDuration="1s"></p-progressSpinner>
            <div class="fontCarregando text-center">
                <span>Copiando lançamento...</span>
            </div>
        </div>
    </p-dialog>

    <!--                          DIALOG DO CARREGANDO BUSCA              -->
    <p-dialog id="dialogFinanceiro" [(visible)]="mostraCarregandoBusca" [modal]="true"
        [style]="{'width': '150px', 'height': '140px'}" [closable]="false">
        <div class="flex flex-column align-items-center mt-2">
            <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
                fill="var(--surface-ground)" animationDuration="1s"></p-progressSpinner>
            <div class="fontCarregando">
                <span>Carregando...</span>
            </div>
        </div>
    </p-dialog>

    <!--                          DIALOG DE CONFIRMAÇÃO DE ESTORNO              -->
    <p-confirmDialog #cd [closable]="false" id="confirmFinanceiro">
        <ng-template pTemplate="footer">
            <button type="button" pButton icon="pi pi-times" label="Retornar" (click)="cd.reject()"
                id="confirmBotaoFinanceiro"></button>
            <button type="button" pButton icon="pi pi-check" label="Confirmar" (click)="cd.accept()"
                id="confirmBotaoFinanceiro"></button>
        </ng-template>
    </p-confirmDialog>

    <!--                          DIALOG DE CONFIRMAÇÃO DE DESMEMBRAMENTO              -->
    <!-- <p-confirmDialog #cd1 [closable]="false" id="confirmFinanceiro">
    <ng-template pTemplate="footer">
        <button type="button" pButton icon="pi pi-times" label="Retornar" (click)="cd.reject();cd1.reject()"
            id="confirmBotaoFinanceiro"></button>
        <button type="button" pButton icon="pi pi-check" label="Confirmar" (click)="cd.accept();cd1.accept()"
            id="confirmBotaoFinanceiro"></button>
    </ng-template>
</p-confirmDialog> -->