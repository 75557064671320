import { AlertService } from '../alert.service';
import { UF } from '../assets/modelsUF';
import { EstabelecimentoService } from '../estabelecimento.service';
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { Estabelecimento } from 'src/assets/estabelecimento';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { UtilitariosService } from 'src/assets/utilitarios.service';
import { TraducaoService } from 'src/traducao.service';
import { PermissoesService } from 'src/permissoes.service';
import { WhatsappService } from 'src/whatsapp.service';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-estabelecimento',
  templateUrl: './estabelecimento.component.html',
  styleUrls: ['./estabelecimento.component.scss'],
  providers: [MessageService],
  encapsulation: ViewEncapsulation.None
})



export class EstabelecimentoComponent implements OnInit {
  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef;



  estabelecimentoTitulo: string = '';
  nomeEstabelecimento: string = '';
  hashEstabelecimento: any;
  showNome: boolean = false;
  showEndereco: boolean = false;
  showContato: boolean = false;
  showCarregando: boolean = false;
  showImagens: boolean = false;
  showAtivarDesativarEstabelecimento: boolean = false;
  showCadHash: boolean = false;
  showRedeSocial: boolean = false;
  nomeImagem: string = 'Escolha uma foto';
  loading: boolean = false;
  linkFacebook: string = '';
  linkTiktok: string = '';
  linkInstagram: string = '';
  showQrWpp: boolean = false;
  mostraCarregando: boolean = false;


  end: any = ''; //informações do endereço vinculado ao cep
  cep: any;
  numero: any;
  cidade: any;
  bairro: any;
  complemento: any;
  logradouro: any;

  /**DROPDOWN */
  ufs = UF;
  ufSelecionado: any;

  /* variaveis do componente */
  codcli: any;
  estabelecimento: Estabelecimento = {}
  contato: string = '';
  email: string = '';
  codemp: any;
  ativo: string = ''
  auxiliarEstabelecimento: Estabelecimento[] = [];
  imageEmpresa: any;
  file: any;
  codusuresp: string = '';
  imgQR: string = '';

  /**RADIO BUTTON */
  valorSelecionadoEstabelecimento: string = '';

  /**VARIAVEIS DE PERMISSÕES */
  alterar: boolean = false;
  cadastrar: boolean = false;
  inativar: boolean = false;
  permissao: any;
  loopQRCode: boolean = true;

  constructor(private estabelecimentoService: EstabelecimentoService, private decodedTokenService: DecodedTokenService, private http: HttpClient,
    private messageService: MessageService, private utilitariosService: UtilitariosService, private alertService: AlertService,
    private traducaoSerice: TraducaoService, private permissoesService: PermissoesService, private whatsappService: WhatsappService, private router: Router) {
    /**TRADUÇÃO */
    this.traducaoSerice.changeLang();



    /**PARA O LOOP DE REQUISIÇÃO DE QR CODE AO SAIR DESSA TELA */
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.loopQRCode = false;
      }
    });
  }

  ngOnInit(): void {
    this.recuperaCodUsu();
    this.recuperaCodCli();
    this.recuperaEstabelecimento();
    this.recuperaDadosEstabelecimento();
    this.recuperaPermissao();
    // this.buscarEstabelecimentosPorCodCliente()
    console.log('estabelecimento component')
    console.log(this.estabelecimento);
    console.log(this.codemp)
  }

  recuperaEstabelecimento() {
    this.estabelecimento = this.estabelecimentoService.getestabelecimento();
    console.log(this.estabelecimento)
  }

  recuperaCodCli() {
    this.codcli = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODCLI;
  }

  recuperaCodUsu() {
    this.codusuresp = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }

  mostraDialogNome() {
    /**VERIFICA SE TEM PERMISSÃO PARA ESSA AÇÃO */
    if (!this.alterar && this.permissao != undefined && this.codemp > 0 && this.permissao.length > 0) {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
    this.showNome = true;
  }

  mostraDialogRedeSocial() {
    /**VERIFICA SE TEM PERMISSÃO PARA ESSA AÇÃO */
    if (!this.alterar && this.permissao != undefined && this.permissao.length > 0) {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
    this.showRedeSocial = true;
  }

  mostraDialogEndereco() {
    /**VERIFICA SE TEM PERMISSÃO PARA ESSA AÇÃO */
    if (!this.alterar && this.permissao != undefined && this.permissao.length > 0) {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }

    if (this.codemp != undefined && this.codemp != null && this.codemp != '') {
      this.showEndereco = true;
    }
  }

  mostraDialogContato() {
    /**VERIFICA SE TEM PERMISSÃO PARA ESSA AÇÃO */
    if (!this.alterar && this.permissao != undefined && this.permissao.length > 0) {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
    if (this.codemp != undefined && this.codemp != null && this.codemp != '') {
      this.showContato = true;
    }
  }

  mostraDialogImagens() {
    /**VERIFICA SE TEM PERMISSÃO PARA ESSA AÇÃO */
    if (!this.alterar && this.permissao != undefined && this.permissao.length > 0) {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
    if (this.codemp != undefined && this.codemp != null && this.codemp != '') {
      this.showImagens = true;
    }
  }

  mostraDialogCarregando() {
    this.showCarregando = true;
  }

  mostraDialogAtivarDesativarEstabelecimento() {
    /**VERIFICA SE TEM PERMISSÃO PARA ESSA AÇÃO */
    if (!this.inativar && this.permissao != undefined && this.permissao.length > 0) {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
    // this.valorSelecionadoEstabelecimento = this.auxiliarEstabelecimento[0].ativo!
    if (this.codemp != undefined && this.codemp != null && this.codemp != '') {
      this.buscarEstabelecimentosPorCodCliente();
      this.showAtivarDesativarEstabelecimento = true;
    }
  }

  mostraDialogCadHash() {
    /**VERIFICA SE TEM PERMISSÃO PARA ESSA AÇÃO */
    if (!this.inativar && this.permissao != undefined && this.permissao.length > 0) {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
    if (this.codemp != undefined && this.codemp != null && this.codemp != '') {
      this.showCadHash = true;
    }
  }

  /**METODO QUE MOSTRA O QR CODE NA TELA */
  mostraDialogQRWpp() {
    /**VERIFICA SE TEM PERMISSÃO PARA ESSA AÇÃO */
    if (!this.inativar && this.permissao != undefined && this.permissao.length > 0) {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
    if (this.codemp != undefined && this.codemp != null && this.codemp != '') {
      this.showQrWpp = true;
      this.criaSessaoWPP();

      setTimeout(() => {// INICIA A BUSCA DE QR CODE
        this.buscaQRCode();
      }, 15000)
    }
  }


  recuperaDadosEstabelecimento() {
    this.codemp = this.estabelecimento.codemp!;
    this.nomeEstabelecimento = this.estabelecimento.nome!;
    this.cep = this.estabelecimento.cep!;
    this.complemento = this.estabelecimento.complemento!;
    this.numero = this.estabelecimento.numero!;
    this.bairro = this.estabelecimento.setor!;
    this.logradouro = this.estabelecimento.rua!;
    this.ufSelecionado = this.estabelecimento.uf!;
    this.cidade = this.estabelecimento.cidade!;
    this.contato = this.estabelecimento.contato!;
    this.email = this.estabelecimento.email!;
    this.estabelecimentoTitulo = this.estabelecimento.nome!;
    this.ativo = this.estabelecimento.ativo!;
    this.linkFacebook = this.estabelecimento.linkfacebook!;
    this.linkInstagram = this.estabelecimento.linkinsta!;
    this.linkTiktok = this.estabelecimento.linktiktok!;
    this.hashEstabelecimento = this.estabelecimento.hash!;
  }


  salvarNome() {

    this.loading = true;
    console.log(this.nomeEstabelecimento);

    if (this.utilitariosService.validaCampo([this.nomeEstabelecimento])) {
      console.log('É necessário informar um nome para sua Estabelecimento.')
      this.loading = false;
      this.showError('Atenção', 'É necessário informar um nome para sua Estabelecimento.')
      return;
    }


    this.estabelecimentoService.preencherNomeEstabelecimento(this.codemp, this.nomeEstabelecimento, this.codcli, this.codusuresp, environment.CODTIPSEG).subscribe((res) => {

      this.codemp = Object(res).codemp;
      this.estabelecimentoTitulo = this.nomeEstabelecimento;
      this.alertService.alert('Nome da Estabelecimento alterado!', 'success')
      this.showNome = false;
      this.loading = false;
    },
      (error) => {
        console.log(error)
        if (error.error.message != undefined) {
          const erro = error.error.message
          // const testeRegex = erro.match(/[^,]*,[^,]*,  (.*\..*)(.*\..*)/)
          const testeRegex = erro.match(/(?:[^,]*, ){2}([^,]*)/)
          this.loading = false;
          this.showNome = false;
          this.alertService.alert('Algo deu errado. ' + testeRegex[1], 'error')
        } else {
          this.loading = false;
          this.showNome = false;
          this.alertService.alert('Algo deu errado.', 'error')
        }


      });

  }



  salvarEnd() {

    this.loading = true;
    if (this.codemp === undefined || this.codemp === null || this.codemp === '') {
      this.loading = false;
      return
    }
    this.estabelecimentoService.preencherEnd(this.codemp, this.cep, this.logradouro, this.numero, this.complemento, this.bairro,
      this.cidade, this.ufSelecionado, this.codusuresp, environment.CODTIPSEG).subscribe((res) => {
        this.alertService.alert('Endereço da Estabelecimento alterado!', 'success')
        this.showEndereco = false;
        this.loading = false;


      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.loading = false;
          this.showEndereco = false;
          this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')
        });
  }

  salvarContato() {
    this.loading = true;
    if (this.codemp === undefined || this.codemp === null || this.codemp === '') {
      this.loading = false;
      return
    }
    this.estabelecimentoService.preencherContatoEstabelecimento(this.codemp, this.contato, this.email, this.codusuresp).subscribe((res) => {

      this.alertService.alert('Contato da Estabelecimento alterado!', 'success')
      this.codemp = Object(res).codemp;
      this.showContato = false;
      this.loading = false;

    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
        this.loading = false;
        this.showContato = false;
        this.alertService.alert('Algo deu errado.', 'error');
        //this.showError('Atenção', 'Algo deu errado.')
      });
  }


  buscaCep(cep: string) {
    this.mostraDialogCarregando();

    this.estabelecimentoService.buscarCep(cep).subscribe((res) => {
      this.showCarregando = false;
      this.end = res;
      console.log(res.end)
      this.logradouro = res.logradouro;
      this.cidade = res.localidade;
      this.bairro = res.bairro;
      this.complemento = res.complemento;
      this.ufSelecionado = res.uf;
    });
  }


  buscarEstabelecimentosPorCodCliente() {
    this.estabelecimentoService.contatoEstabelecimento(this.codemp, this.codusuresp).subscribe((res) => {
      this.auxiliarEstabelecimento = res;
      this.valorSelecionadoEstabelecimento = this.auxiliarEstabelecimento[0].ativo!
    });
  }


  ativaDesativaEstabelecimento() {
    // this.buscarEstabelecimentosPorCodCliente();

    this.estabelecimentoService.contatoEstabelecimento(this.codemp, this.codusuresp).subscribe((res) => {
      this.auxiliarEstabelecimento = res;

      if (this.auxiliarEstabelecimento[0].ativo != this.valorSelecionadoEstabelecimento) {
        this.loading = true;
        this.estabelecimentoService.ativarDesativarEmpresaPorCodEmp(this.codemp, this.codusuresp).subscribe((res) => {
          console.log(res)
          this.showAtivarDesativarEstabelecimento = false;
          this.loading = false;
          if (Object(res).ativo === 'S') {
            this.alertService.alert('Estabelecimento ativado!', 'success')
          }
          if (Object(res).ativo === 'N') {
            this.alertService.alert('Estabelecimento desativado!', 'success')
          }

        },
          (error) => {
            console.log(error)
            console.log('deu ruim')
            this.loading = false;
            this.showAtivarDesativarEstabelecimento = false;
            this.alertService.alert('Algo deu errado.', 'error');
            //this.showError('Atenção', 'Algo deu errado.')
          });
      }
    }
    );
  }

  mudaNomeFoto() {
    const imageBlob = this.fileInput!.nativeElement.files[0];
    this.nomeImagem = imageBlob.name;
    if (this.nomeImagem.length > 24) {
      console.log('oiwf')
      this.nomeImagem = this.nomeImagem.substr(0, 24) + '...';
    }
    console.log(this.nomeImagem.substr(0, 20) + '...')
  }

  onFileUpload() {
    this.loading = true;
    const imageBlob = this.fileInput!.nativeElement.files[0];
    this.nomeImagem = imageBlob.name;
    const file = new FormData();
    file.append('imagem_empresa', imageBlob);
    file.append('codemp', this.codemp);
    file.append('codusuresp', this.codusuresp);
    console.log(file)
    console.log(this.codemp)
    this.estabelecimentoService.uploadImagemEmpresa(this.codemp, file).subscribe((res) => {
      this.alertService.alert('Imagem da Estabelecimento alterado!', 'success')

      console.log(res)

      this.showImagens = false;
      this.loading = false;
      this.nomeImagem = "Escolha uma foto";
    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
        this.loading = false;
        this.showImagens = false;
        this.alertService.alert('Algo deu errado.', 'error');
        //this.showError('Atenção', 'Algo deu errado.')
      });
  }

  salvaRedeSocial() {
    this.loading = true;
    this.estabelecimentoService.preencheRedeSocial(this.linkInstagram, this.linkFacebook, this.linkTiktok, this.codemp, this.codusuresp).subscribe((res) => {
      this.alertService.alert('Rede Social da Estabelecimento alterado!', 'success')
      this.showRedeSocial = false;
      this.loading = false;
      console.log(res)
    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
        this.loading = false;
        this.showImagens = false;
        this.alertService.alert('Algo deu errado.', 'error');
        //this.showError('Atenção', 'Algo deu errado.')
      })
  }

  /**METODO DE TOAST */
  showError(titulo: string, mensagem: string) {
    if (screen.width < 640 || screen.height < 480) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else if (screen.width < 1024 || screen.height < 768) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else {
      this.messageService.add({
        key: 'tr',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    }
  }


  /**RECUPERA AS PERMISSÕES */
  recuperaPermissao() {
    console.log('recuperei as permissoes')
    this.permissao = this.permissoesService.getPermissao();
    console.log(this.permissao)
    if (this.permissao != undefined && this.permissao.length > 0) {
      if (this.permissao[0].acao[0].PERMITE === 'S') {
        this.cadastrar = true;
      }
      if (this.permissao[0].acao[1].PERMITE === 'S') {
        this.alterar = true;
      }
      if (this.permissao[0].acao[2].PERMITE === 'S') {
        this.inativar = true;
      }
      /**ATRIBUI O CODCLI VINCULADO NA PERMISSAO DO FUNCIONARIO, PARA FAZER AS OPERAÇÕES DA TELA */
      this.codcli = this.permissao[0].codcli;
    }
  }


  /**METODO QUE CRIA A HASH DA BARBEARIA PARA AGENDAMENTOS SEM CONTA */
  salvarHash() {
    this.loading = true;

    // if (this.utilitariosService.validaCampo([this.hashEstabelecimento])) {
    //   console.log('É necessário informar um nome para sua Estabelecimento.')
    //   this.loading = false;
    //   this.showError('Atenção', 'É necessário informar uma hash para sua Estabelecimento.')
    //   return;
    // }

    if (this.hashEstabelecimento === '') {
      this.hashEstabelecimento = null;
    }

    this.estabelecimentoService.preencherHashEstabelecimento(this.hashEstabelecimento, this.codemp, this.codusuresp).subscribe((res) => {
      console.log(res)
      this.alertService.alert('Hash da Estabelecimento alterado!', 'success')
      this.showCadHash = false;
      this.loading = false;
    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
        this.loading = false;
        this.showCadHash = false;
        this.alertService.alert('Algo deu errado.', 'error');
      });

  }

  /**METODO QUE GERA SESSÃO DO WPP */
  criaSessaoWPP() {
    this.mostraCarregando = true;

    this.loopQRCode = true;
    let nomeWpp = `wpp${this.estabelecimentoTitulo}$${this.codemp}$`
    nomeWpp = nomeWpp.replace(/\s/g, "");
    console.log(nomeWpp)
    this.whatsappService.criaSessao(nomeWpp).subscribe((res) => {
      console.log(res)
      this.showQrWpp = false;
      this.imgQR = '';
      this.alertService.alert('Conexão criada.', 'success');
      this.loopQRCode = false;

    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
        this.showQrWpp = false;
        this.mostraCarregando = false;
        this.loopQRCode = false;
        this.alertService.alert('Tempo limite para leitura do QR Code alcançado.', 'error');
      });
  }

  /**METODO QUE BUSCA E PRINTA NA TELA O QR CODE */
  buscaQRCode() {
    // this.mostraCarregando = true;
    console.log('oioioi1')
    // while (this.loopQRCode) {
    console.log(this.loopQRCode + ' variavel de parada')
    let cronometro = setInterval(() => { //A CADA 12s BUSCA UM NOVO QR CODE

      /**CONDIÇÃO DE PARADA DO LOOP DO QR CODE */
      if (!this.loopQRCode) {
        clearInterval(cronometro);
      }

      console.log('oioioi2')
      let nomeWpp = `wpp${this.estabelecimentoTitulo}$${this.codemp}$`
      nomeWpp = nomeWpp.replace(/\s/g, "");
      this.whatsappService.buscaQRCode(nomeWpp).subscribe((res) => {
        console.log(res)
        this.imgQR = res;
        this.mostraCarregando = false;
        //this.loopQRCode = false;
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.showQrWpp = false;
          this.mostraCarregando = false;
          // this.loopQRCode = false;
          this.alertService.alert('Algo deu errado.', 'error');
        });
    }, 12000)

  }

  //}
}

