<p-toast position="bottom-center" key="bc"></p-toast>
<p-toast position="top-right" key="tr"></p-toast>
<div class="pt-7">

    <div class="flex flex-column  pb-3">
        <div class="flex align-items-center justify-content-center">
            <img src="./assets/iconeCadastrarHorario.svg" class="iconeCadastrarHorario" />
        </div>
        <div class="flex align-items-center justify-content-center">
            <span
                class=" tituloNegritoHorarios maximoCaracter white-space-nowrap overflow-hidden text-overflow-ellipsis">{{exibeNomeHorario}}</span>
        </div>
    </div>



    <div class="flex justify-content-center flex-wrap ">
        <div class="xl:col-5 lg:col-5 md:col-5 sm:col-5">
            <div class="shadow-4">
                <p-card header="Dados do Horário" id="cardHorario">
                    <div>
                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            (click)="mostraDialogProfissional()">
                            <div class="flex gap-2 ">
                                <img src="./assets/imgHorarioFunc.svg" class="iconeCadastro" class=" xl:w-3rem" />
                                <div class="flex flex-column  align-items-start">
                                    <div class=" fontTituloHorarios">
                                        <span class="xl:text-xl">Horário por Funcionário</span>
                                    </div>
                                    <div class="fontCinzaHorarios">
                                        <span class="xl:text-base">Preencher a quem o horário se destina</span>
                                    </div>
                                </div>
                            </div>
                            <div class="align-items-center justify-content-center">
                                <img src="./assets/imgBarbeariaPopup.png" class="iconePopUp" />
                            </div>
                        </div>

                        <div class="formgroup-inline justify-content-between flex-wrap "
                            (click)="mostraDialogDiaSemana()">
                            <div class="flex gap-2 ">
                                <img src="./assets/imgHorarioDia.svg" class="iconeCadastro" class=" xl:w-3rem" />
                                <div class="flex flex-column align-items-start">
                                    <div class=" fontTituloHorarios">
                                        <span class="xl:text-xl">Configurar dias da semana </span>
                                    </div>
                                    <div class="fontCinzaHorarios">
                                        <span class="xl:text-base">Preencher horários e intervalos</span>
                                    </div>
                                </div>
                            </div>
                            <div class="align-items-center justify-content-center">
                                <img src="./assets/imgBarbeariaPopup.png" class="iconePopUp" />
                            </div>
                        </div>
                    </div>

                </p-card>
            </div>
        </div>
    </div>
    <!--                          DIALOG DO FUNCIONARIO              -->
    <p-dialog header="Horário do funcionário" [(visible)]="showHorarioProfissional" [modal]="true" id="dialogHorarios">
        <div class="col-12">
            <div class="fontDialogHorarios flex align-items-center justify-content-center ">
                <span>Identificação do horário</span>
            </div>
        </div>
        <div class="mb-1">
            <input type="text" pInputText placeholder="Nome do Horário" [(ngModel)]="nomehor" [style]="{width: '100%'}">
        </div>
        <div>
            <p-dropdown [options]="profissional" [(ngModel)]="profissionalSelecionado" placeholder="Profissional"
                optionLabel="nomefunc" optionValue="codfunc" [style]="{'width': '100%'}" id="drop"
                scrollHeight="80px"></p-dropdown>
        </div>



        <div *ngIf="!loading" [style]="{width: '100%'}">
            <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                'border-radius': '11px'}" (click)="salvarHorarioFuncionario()"></button>
        </div>
        <div *ngIf="loading" [style]="{width: '100%'}">
            <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 mt-3"
                [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                'border-radius': '11px'}"></button>
        </div>
    </p-dialog>


    <!--                          DIALOG DOS HORARIOS DIA DA SEMANA              -->
    <p-dialog header="Configurar dias da semana" [(visible)]="showDiaSemana" [modal]="true" id="configDiaSemana">
        <div class="col-12">
            <div class="fontDialogHorarios flex align-items-center justify-content-center ">
                <span>Preencha os horários conforme sua necessidade</span>
            </div>
        </div>
        <div class="flex flex-column align-items-start">
            <div class="grid p-fluid col espacamentoHorarios">
                <div class="fontDialogHorarios " id="diaSemana">
                    <span id="diaSemana"> Segunda - Feira</span>
                    <!-- <div class="linhaDiaSemana"></div> -->

                </div>
                <div class="formgroup-inline">
                    <div class="w-3 ">
                        <p-inputMask mask="99:99" placeholder="Hor 1" id="inputHorarios" [(ngModel)]="diaDaSemana.seg1"
                            [style]="{width: '100%'}"></p-inputMask>
                    </div>
                    <div class="w-3 ">
                        <p-inputMask mask="99:99" placeholder="Hor 2" id="inputHorarios" [(ngModel)]="diaDaSemana.seg2"
                            [style]="{width: '100%'}"></p-inputMask>
                    </div>
                    <div class="w-3 ">
                        <p-inputMask mask="99:99" placeholder="Hor 3" id="inputHorarios" [(ngModel)]="diaDaSemana.seg3"
                            [style]="{width: '100%'}"></p-inputMask>
                    </div>
                    <div class="w-3 ">
                        <p-inputMask mask="99:99" placeholder="Hor 4" id="inputHorarios" [(ngModel)]="diaDaSemana.seg4"
                            [style]="{width: '100%'}"></p-inputMask>
                    </div>
                </div>
            </div>
            <div class="grid p-fluid col espacamentoHorarios mt-1">
                <div class="fontDialogHorarios flex align-items-center justify-content-center " id="diaSemana">
                    <span> Terça - Feira</span>
                </div>
                <div class="formgroup-inline">
                    <div class="w-3 ">
                        <p-inputMask mask="99:99" placeholder="Hor 1" [(ngModel)]="diaDaSemana.ter1"
                            [style]="{width: '100%'}"></p-inputMask>
                    </div>
                    <div class="w-3 ">
                        <p-inputMask mask="99:99" placeholder="Hor 2" [(ngModel)]="diaDaSemana.ter2"
                            [style]="{width: '100%'}"></p-inputMask>
                    </div>
                    <div class="w-3 ">
                        <p-inputMask mask="99:99" placeholder="Hor 3" [(ngModel)]="diaDaSemana.ter3"
                            [style]="{width: '100%'}"></p-inputMask>
                    </div>
                    <div class="w-3 ">
                        <p-inputMask mask="99:99" placeholder="Hor 4" [(ngModel)]="diaDaSemana.ter4"
                            [style]="{width: '100%'}"></p-inputMask>
                    </div>
                </div>
            </div>
            <div class="grid p-fluid col espacamentoHorarios mt-1">
                <div class="fontDialogHorarios flex align-items-center justify-content-center " id="diaSemana">
                    <span> Quarta - Feira</span>
                </div>
                <div class="formgroup-inline">
                    <div class="w-3 ">
                        <p-inputMask mask="99:99" placeholder="Hor 1" [(ngModel)]="diaDaSemana.qua1"
                            [style]="{width: '100%'}"></p-inputMask>
                    </div>
                    <div class="w-3 ">
                        <p-inputMask mask="99:99" placeholder="Hor 2" [(ngModel)]="diaDaSemana.qua2"
                            [style]="{width: '100%'}"></p-inputMask>
                    </div>
                    <div class="w-3 ">
                        <p-inputMask mask="99:99" placeholder="Hor 3" [(ngModel)]="diaDaSemana.qua3"
                            [style]="{width: '100%'}"></p-inputMask>
                    </div>
                    <div class="w-3 ">
                        <p-inputMask mask="99:99" placeholder="Hor 4" [(ngModel)]="diaDaSemana.qua4"
                            [style]="{width: '100%'}"></p-inputMask>
                    </div>
                </div>
            </div>
            <div class="grid p-fluid col espacamentoHorarios mt-1">
                <div class="fontDialogHorarios flex align-items-center justify-content-center " id="diaSemana">
                    <span> Quinta - Feira</span>
                </div>
                <div class="formgroup-inline">
                    <div class="w-3 ">
                        <p-inputMask mask="99:99" placeholder="Hor 1" [(ngModel)]="diaDaSemana.qui1"
                            [style]="{width: '100%'}"></p-inputMask>
                    </div>
                    <div class="w-3 ">
                        <p-inputMask mask="99:99" placeholder="Hor 2" [(ngModel)]="diaDaSemana.qui2"
                            [style]="{width: '100%'}"></p-inputMask>
                    </div>
                    <div class="w-3 ">
                        <p-inputMask mask="99:99" placeholder="Hor 3" [(ngModel)]="diaDaSemana.qui3"
                            [style]="{width: '100%'}"></p-inputMask>
                    </div>
                    <div class="w-3 ">
                        <p-inputMask mask="99:99" placeholder="Hor 4" [(ngModel)]="diaDaSemana.qui4"
                            [style]="{width: '100%'}"></p-inputMask>
                    </div>
                </div>
            </div>
            <div class="grid p-fluid col espacamentoHorarios mt-1">
                <div class="fontDialogHorarios flex align-items-center justify-content-center" id="diaSemana">
                    <span> Sexta - Feira</span>

                </div>

                <div class="formgroup-inline">
                    <div class="w-3 ">
                        <p-inputMask mask="99:99" placeholder="Hor 1" [(ngModel)]="diaDaSemana.sex1"
                            [style]="{width: '100%'}"></p-inputMask>
                    </div>
                    <div class="w-3 ">
                        <p-inputMask mask="99:99" placeholder="Hor 2" [(ngModel)]="diaDaSemana.sex2"
                            [style]="{width: '100%'}"></p-inputMask>
                    </div>
                    <div class="w-3 ">
                        <p-inputMask mask="99:99" placeholder="Hor 3" [(ngModel)]="diaDaSemana.sex3"
                            [style]="{width: '100%'}"></p-inputMask>
                    </div>
                    <div class="w-3 ">
                        <p-inputMask mask="99:99" placeholder="Hor 4" [(ngModel)]="diaDaSemana.sex4"
                            [style]="{width: '100%'}"></p-inputMask>
                    </div>
                </div>
            </div>
            <div class="grid p-fluid col espacamentoHorarios mt-1">
                <div class="fontDialogHorarios flex align-items-center justify-content-center " id="diaSemana">
                    <span> Sábado</span>
                </div>
                <div class="formgroup-inline">
                    <div class="w-3 ">
                        <p-inputMask mask="99:99" placeholder="Hor 1" [(ngModel)]="diaDaSemana.sab1"
                            [style]="{width: '100%'}"></p-inputMask>
                    </div>
                    <div class="w-3 ">
                        <p-inputMask mask="99:99" placeholder="Hor 2" [(ngModel)]="diaDaSemana.sab2"
                            [style]="{width: '100%'}"></p-inputMask>
                    </div>
                    <div class="w-3 ">
                        <p-inputMask mask="99:99" placeholder="Hor 3" [(ngModel)]="diaDaSemana.sab3"
                            [style]="{width: '100%'}"></p-inputMask>
                    </div>
                    <div class="w-3 ">
                        <p-inputMask mask="99:99" placeholder="Hor 4" [(ngModel)]="diaDaSemana.sab4"
                            [style]="{width: '100%'}"></p-inputMask>
                    </div>
                </div>
            </div>
            <div class="grid p-fluid col mt-1">
                <div class="fontDialogHorarios flex align-items-center justify-content-center " id="diaSemana">
                    <span> Domingo</span>
                </div>
                <div class="formgroup-inline">
                    <div class="w-3 ">
                        <p-inputMask mask="99:99" placeholder="Hor 1" [(ngModel)]="diaDaSemana.dom1"
                            [style]="{width: '100%'}"></p-inputMask>
                    </div>
                    <div class="w-3 ">
                        <p-inputMask mask="99:99" placeholder="Hor 2" [(ngModel)]="diaDaSemana.dom2"
                            [style]="{width: '100%'}"></p-inputMask>
                    </div>
                    <div class="w-3 ">
                        <p-inputMask mask="99:99" placeholder="Hor 3" [(ngModel)]="diaDaSemana.dom3"
                            [style]="{width: '100%'}"></p-inputMask>
                    </div>
                    <div class="w-3 ">
                        <p-inputMask mask="99:99" placeholder="Hor 4" [(ngModel)]="diaDaSemana.dom4"
                            [style]="{width: '100%'}"></p-inputMask>
                    </div>
                </div>
            </div>
        </div>


        <div *ngIf="!loading" [style]="{width: '100%'}">
            <button pButton type="button" label="Salvar" class="col-12 " [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                        'border-radius': '11px'}" (click)="salvarHorarioDiaSemana()"></button>
        </div>
        <div *ngIf="loading" [style]="{width: '100%'}">
            <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                        'border-radius': '11px'}"></button>
        </div>

    </p-dialog>
</div>
<br>