<p-toast position="bottom-center" key="bc"></p-toast>
<p-toast position="top-right" key="tr"></p-toast>
<div class="pt-7  overflow-hidden">

    <div class="flex flex-column  pb-3">
        <div class="flex align-items-center justify-content-center">
            <img src="./assets/iconeClientes.svg" class="iconeClientes" />
        </div>

        <div class="flex justify-content-evenly flex-wrap">
            <div class="posicaoClientes flex align-items-center justify-content-center ">
                <span class="tituloNegritoClientes ">Clientes</span>
            </div>

            <div class="formgroup-inline gap-1 flex align-items-center justify-content-center ">
                <div class="contornoCliParc flex flex-column">
                    <span class="fontTotalClientes ">Clientes</span>
                    <span class="fontTotalClientes ">
                        {{nuClientes}}
                    </span>
                </div>

                <div class="contornoCliParc flex flex-column">
                    <span class="fontTotalClientes ">Parceiros</span>
                    <span class="fontTotalClientes ">
                        {{nuParceiros}}
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 sm-12 md-4 ">
        <div class=" flex align-items-center justify-content-center gap-2 ">
            <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input type="text" class="col-12" pInputText placeholder="Pesquisar" id="pesquisa"
                    (keydown)="buscarCliParc()">
            </span>
            <img class="cursor-pointer ml-2" src="./assets/iconeMenuAdm.png" (click)="menu.toggle($event);" />
            <p-menu #menu [popup]="true" [model]="itemsRelatorio" styleClass="menuClientesFiliados"></p-menu>
        </div>
    </div>



    <div class="flex flex-column  pb-3">
        <div class="flex justify-content-center flex-wrap ">

            <div class="scroll-divCadClientes">
                <table id="tabela">
                    <tr *ngFor="let clientes of clientes">
                        <td>
                            <div class="linhahorizontalClientesFiliados pt-1"></div>
                            <div class="col-12 sm-12 md-4 ">
                                <div class="flex flex-column">

                                    <div class="flex align-items-center justify-content-center tamanhoLinhaTabela">
                                        <div class="col ">
                                            <div class="fontTituloClientesFiliados">
                                                <span class="xl:text-xl">{{clientes.nomeparc}}</span>
                                            </div>
                                            <div class="formgroup-inline justify-content-between flex-wrap">
                                                <div class="pb-2 fontContato">
                                                    <div class="flex gap-2">
                                                        <div
                                                            class="white-space-nowrap overflow-hidden text-overflow-ellipsis maximoCaracterClientes">
                                                            <img src="./assets/iconeTel.svg" />
                                                            <span class="xl:text-base"> {{clientes.telefone}} -
                                                                {{clientes.nomeemp}}</span>
                                                            <span *ngIf="clientes.tipcli === 'CLIENTE'"
                                                                class="xl:text-base"> [C] - </span>
                                                            <span *ngIf="clientes.tipcli === 'PARCEIRO'"
                                                                class="xl:text-base"> [P] - </span>
                                                            <span class="xl:text-base">{{clientes.email}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex align-items-center justify-content-center ">
                                            <img class="cursor-pointer" src="./assets/iconeMenuAdm.png"
                                                (click)="menu.toggle($event);usuarioSelecionado(clientes)" />
                                            <p-menu #menu [popup]="true" [model]="itemsClientes"
                                                styleClass="menuClientesFiliados"></p-menu>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>

    <p-confirmDialog #cd [closable]="false" id="confirmCadastraCliente">
        <ng-template pTemplate="footer">
            <button type="button" pButton icon="pi pi-times" label="Retornar" (click)="cd.reject()"></button>
            <button type="button" pButton icon="pi pi-check" label="Confirmar" (click)="cd.accept()"></button>
        </ng-template>
    </p-confirmDialog>

    <div class="flex justify-content-center flex-wrap ">
        <div class="flex align-items-center justify-content-center">
            <footer class="xl:w-5" id="footerCadastraCliente">
                <p-button label="Cadastrar" id="botaoCadastrarCliente" (click)="mostraDialogCliParc()">
                </p-button>
            </footer>
        </div>
    </div>



    <!--                          DIALOG DE CADASTRO/EDIÇÃO DE CLIENTE/PACEIRO               -->
    <p-dialog header="Cliente/Parceiro" [(visible)]="showCadastra" [modal]="true" id="dialogCadastroCliParc"
        [draggable]="false">
        <div class="flex justify-content-center flex-wrap col">
            <div class="fontDialogCadCliParc flex align-items-center justify-content-center">
                <span>Informações do Cliente/Parceiro</span>
            </div>
        </div>

        <div class="pb-2">
            <p-selectButton [options]="tipoUsuario" [(ngModel)]="tipoUsuarioSelecionado" optionLabel="tipo"
                optionValue="tipo" (onChange)="escolheCliParc()" [disabled]="desabilitaTipo"></p-selectButton>
        </div>

        <input type="text" pInputText placeholder="*Nome" [(ngModel)]="nome" 
            [style]="{'width': '100%'}">

        <!-- <div *ngIf="tipoUsuarioSelecionado==='CLIENTE'" class="pt-1">
            <input type="text" pInputText placeholder="*Sobrenome" [(ngModel)]="sobrenome" [style]="{'width': '100%'}">
        </div> -->

        <div class="mt-1">
            <p-calendar [touchUI]="true" [readonlyInput]="false" inputId="touchui" dateFormat="dd/mm/yy"
                [required]="true" [readonlyInput]="true" id="calendarioCadastroCliParc" [(ngModel)]="dtNasc"
                placeholder="*Data de Nascimento" [maxDate]="hoje" >
            </p-calendar>
        </div>

        <div class="mt-1">
            <input type="text" pInputText placeholder="*Email" [(ngModel)]="email" 
                [style]="{'width': '100%'}" [disabled]="desabilitaCampos">
        </div>

        <div class="mt-1 p-fluid">
            <!-- <input type="text" pInputText placeholder="*Telefone" type="number" 
                [(ngModel)]="telefone" [style]="{'width': '100%'}"> -->
            <p-inputMask mask="(99) 99999-9999" [(ngModel)]="telefone" placeholder="*Telefone"
                 id="telefone"></p-inputMask>
        </div>

        <div *ngIf="tipoUsuarioSelecionado==='CLIENTE'" class="pt-1">
            <input type="text" [(ngModel)]="cpfCli" pInputText [mask]=" '000.000.000-00'" placeholder="CPF"
                [style]="{'width': '100%'}">
            <!-- <input type="text" [(ngModel)]="cpfcnpj" (onChange)="ajustarMascara()" pInputText [mask]="mascara"
                placeholder="CPF/CNPJ" [style]="{'width': '100%'}"> -->
            <!-- <input type="text" [(ngModel)]="cpfcnpj" (input)="ajustarMascara()" [mask]="isCpf ? '000.000.000-00' : '00.000.000/0000-00'" placeholder="CPF/CNPJ"> -->

        </div>

        <!-- <div *ngIf="tipoUsuarioSelecionado==='PARCEIRO'"> -->
        <p-dropdown id="dropCadCliParc" [options]="estabelecimentos" [(ngModel)]="estabelecimentoSelecionada"
            placeholder="*Estabelecimento" optionLabel="nome" optionValue="codemp" [style]="{'width': '100%'}"
            scrollHeight="130px" [filter]="false" [disabled]="desabilitaCampos">
        </p-dropdown>
        <!-- </div> -->

        <div *ngIf="tipoUsuarioSelecionado==='CLIENTE' &&  desabilitaCampos === false">
            <div class="mt-1">
                <input type="text" pInputText placeholder="Senha: 123456" [(ngModel)]="senha"
                    [style]="{'width': '100%'}" [disabled]="true">
            </div>
        </div>

        <div *ngIf="tipoUsuarioSelecionado==='PARCEIRO'" class="pt-1">
            <input type="text" [(ngModel)]="cpfcnpj" (input)="ajustarMascara()" pInputText
                [mask]="isCpf ? '000.000.000-0000000' : '00.000.000/0000-00'" placeholder="CPF/CNPJ"
                [style]="{'width': '100%'}">
            <!-- <input type="text" [(ngModel)]="cpfcnpj" (onChange)="ajustarMascara()" pInputText [mask]="mascara"
                placeholder="CPF/CNPJ" [style]="{'width': '100%'}"> -->
            <!-- <input type="text" [(ngModel)]="cpfcnpj" (input)="ajustarMascara()" [mask]="isCpf ? '000.000.000-00' : '00.000.000/0000-00'" placeholder="CPF/CNPJ"> -->

        </div>

        <div *ngIf="tipoUsuarioSelecionado==='PARCEIRO'" class="mt-1">
            <textarea pInputTextarea placeholder="Detalhes" [(ngModel)]="detalhes" [autoResize]="true"
                class="expandeTextArea"></textarea>
        </div>

        <div *ngIf="!loading" [style]="{width: '100%'}">
            <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
              'border-radius': '11px'}" (click)="preencheUsuario()" ></button>
        </div>
        <div *ngIf="loading" [style]="{width: '100%'}">
            <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 mt-3"
                [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
              'border-radius': '11px'}"></button>
        </div>
    </p-dialog>


</div>