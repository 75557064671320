import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from './environments/environment';
import { LoginService } from './login/login.service';


@Injectable({
  providedIn: 'root'
})
export class ParceirosService {

  constructor(private http: HttpClient, private loginService: LoginService) { }




  buscaClientesGeralPorCodFunc(codfunc: string, codcli: any, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const funcionario = { "codfunc": codfunc, "codcli": codcli, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/parceiros/buscaclientesgeralbycodfunc`, funcionario, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  buscaClientesGeralEspecial(codfunc: number, codcli: any, codemp: number, opc: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const funcionario = { "codfunc": codfunc, "codcli": codcli, "codemp": codemp, "opc": opc, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/parceiros/buscaclientesgeralespecialbycodfunc`, funcionario, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  buscaClientesGeralPorCodEmp(codemp: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const empresa = { "codemp": codemp, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/parceiros/buscaclientesgeralbycodemp`, empresa, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  cadastraParceiro(codparc: any, nomeparc: string, email: string, telefone: string, dtnasc: any, codemp: number,
    detalhes: string, cgc_cpf_cnpj: string, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const parceiro = {
      "codparc": codparc, "nomeparc": nomeparc, "email": email, "telefone": telefone, "dtnasc": dtnasc, "codemp": codemp,
      "detalhes": detalhes, "cgc_cpf_cnpj": cgc_cpf_cnpj, "codusuresp": codusuresp
    }

    return this.http
      .post<any>(`${environment.BASE_URL}/parceiros/preencheparceiro`, parceiro, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  baneParceiro(codusu: string, codemp: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const parceiro = { "codusu": codusu, "codemp": codemp, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/parceiros/baneparceiro`, parceiro, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  geraRelatorioPDFClienteParceiro(codfunc: number, codcli: any, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const funcionario = { "codfunc": codfunc, "codcli": codcli, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/rel/getUrlRelCliParc`, funcionario, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  geraRelatorioExcelClienteParceiro(codfunc: number, codcli: any, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const funcionario = { "codfunc": codfunc, "codcli": codcli, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/parceiros/relcliparcexcel`, funcionario, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

}
