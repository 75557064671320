import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AlertService } from 'src/alert.service';
import { Estabelecimento } from 'src/assets/estabelecimento';
import { Profissional } from 'src/assets/profissional';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { Servico } from 'src/assets/servico';
import { UtilitariosService } from 'src/assets/utilitarios.service';
import { EstabelecimentoService } from 'src/estabelecimento.service';
import { ProfissionalService } from 'src/profissional.service';
import { DescontoService } from 'src/desconto.service';
import { PermissoesService } from 'src/permissoes.service';
import { ServicoService } from 'src/servico.service';
import { TraducaoService } from 'src/traducao.service';

@Component({
  selector: 'app-descontos',
  templateUrl: './descontos.component.html',
  styleUrls: ['./descontos.component.scss'],
  providers: [MessageService],
  encapsulation: ViewEncapsulation.None
})
export class DescontosComponent implements OnInit {

  /**VARIAVEIS DOS DIALOGS */
  showServicos: boolean = false;
  showDescontoMassa: boolean = false;
  showPontosMassa: boolean = false;
  showProfissionais: boolean = false;
  showFidelidade: boolean = false;

  /**VARIAVEIS DO BOTAO DE LOADING */
  teveRespostaEstabelecimento: boolean = false;
  teveRespostaServico: boolean = false;

  /**RADIO BUTTON */
  valorSelecionadoDesconto: string = '';

  codcli: number = 0;
  codusuresp: string = '';
  servico: Servico[] = [];
  loading: boolean = false;
  descontoServico: any;

  /**CHECKBOX DO DESCONTO EM SERVIÇO */
  selecionouServico: any[] = [];
  selecionouProfissional2: any;

  tipoDesconto: string = ''
  profissional: Profissional[] = [];

  /**CHECKBOX DO DESCONTO EM PROFISSIONAL */
  selecionouProfissional: any[] = [];

  /**DESCONTO EM MASSA */
  descontoMassaServico: boolean = false;
  descontoMassaFidelidade: boolean = false;
  descontoMassaProfissional: boolean = false;
  descontoMassaSelecionado: string = 'R';
  descontoMassa: number = 0;

  /**PONTOS EM MASSA */
  habPontuacao: boolean = false;
  pontos: number = 0;

  /**SWITCH DOS PONTOS */
  habilitaPontuacao: string = '';

  /**VARIAVEIS DE PERMISSÕES */
  permissao: any;
  alterar: boolean = false;

  constructor(private alertService: AlertService, private messageService: MessageService, private servicoService: ServicoService, private estabelecimentoService: EstabelecimentoService,
    private profissionalService: ProfissionalService, private utilitariosService: UtilitariosService, private traducaoService: TraducaoService, private decodedTokenService: DecodedTokenService,
    private descontoService: DescontoService, private permissoesService: PermissoesService) {
    this.traducaoService.changeLang();

  }

  ngOnInit(): void {
    this.recuperaCodCli();
    this.recuperaPermissao();
    this.buscaProfissionalsPorCodCli();

    this.buscaServicos();
  }

  /**METODO QUE CONTROLA A SELEÇÃO DOS SERVIÇOS DO CHECKBOX CASO SEJA ESCOLHIDO O BARBEIRO */
  clicouCheckProfissional() {
    this.selecionouServico = []
    for (let j = 0; j < this.selecionouProfissional2.length; j++) {
      for (let i = 0; i < this.servico.length; i++) {
        if (this.servico[i].apelido === this.selecionouProfissional2[j]) {
          this.selecionouServico.push(this.servico[i]);
        }
      }
    }
    console.log(this.selecionouServico)
  }

  /**METODO QUE PREENCHE OS CAMPOS DE DESCONTO QUE FORAM SELECIONADOS PELO CHECKBOX */
  preencheEmMassa() {
    if (this.descontoMassaServico === true) {
      console.log(this.descontoMassa)
      for (let i = 0; i < this.selecionouServico.length; i++) {
        this.selecionouServico[i].tipdesc = this.descontoMassaSelecionado;
        this.selecionouServico[i].desc = this.descontoMassa;
        console.log(this.selecionouServico[i])
      }

      /**LIMPA AS VARIAVEIS*/
      this.selecionouProfissional2 = [];
      this.selecionouServico = [];
      this.descontoMassaSelecionado = 'R';
      this.descontoMassa = 0;
      this.descontoMassaServico = false;
      this.showDescontoMassa = false;
    }
    else {
      for (let i = 0; i < this.selecionouProfissional.length; i++) {
        this.selecionouProfissional[i].tipdesc = this.descontoMassaSelecionado;
        this.selecionouProfissional[i].desc = this.descontoMassa;
      }


      /**LIMPA AS VARIAVEIS*/
      this.selecionouProfissional = [];
      this.descontoMassaSelecionado = 'R';
      this.descontoMassa = 0;
      this.descontoMassaProfissional = false;
      this.showDescontoMassa = false;

    }
    if (this.descontoMassaFidelidade === true) {
      for (let i = 0; i < this.selecionouServico.length; i++) {
        this.selecionouServico[i].habilitaPontuacao = this.habPontuacao;
        this.selecionouServico[i].pont = this.pontos;

        /**CHAMA O METODO PARA ATRIBUIR 'S' OU 'N' */
        this.verificaHabilitaPontuacao(this.selecionouServico[i]);
      }
    }

    /**LIMPA AS VARIAVEIS*/
    this.selecionouProfissional2 = [];
    this.selecionouServico = [];
    this.pontos = 0;
    this.descontoMassaFidelidade = false;
    this.habPontuacao = false;
    this.showPontosMassa = false;
  }



  /**METODO PARA ATUALIZAR O DESCONTO DO SERVIÇO SELECIONADO */
  atualizaDescontoServico() {
    this.loading = true;

    /**VALIDA OS CAMPOS */
    for (let i = 0; i < this.servico.length; i++) {
      if (this.servico[i].tipdesc === 'R' && this.servico[i].preco! < this.servico[i].desc!) {
        this.showError('Atenção', 'O valor do desconto em R$ não pode ser maior que o preço do produto.')
        this.loading = false;
        return
      }
    }

    for (let i = 0; i < this.servico.length; i++) {
      this.descontoService.atualizarDescontoProduto(this.servico[i].codprod!, this.servico[i].tipdesc!, this.servico[i].desc!, this.codusuresp).subscribe((res) => {
        console.log(res)
        this.alertService.alert('Desconto dos serviços alterado!', 'success')
        this.loading = false;
        this.showServicos = false;
      },
        (error) => {
          console.log('deu ruim')
          this.loading = false;
          this.showServicos = false;
          this.alertService.alert('Algo deu errado.', 'error');
        })

      console.log(this.servico[i].codprod, this.servico[i].tipdesc, this.servico[i].desc)
    }
  }

  /**METODO PARA ATUALIZAR O DESCONTO DO SERVIÇO SELECIONADO */
  atualizaDescontoProfissional() {
    this.loading = true;

    for (let i = 0; i < this.profissional.length; i++) {
      this.descontoService.atualizarDescontoProfissional(this.profissional[i].codfunc, this.profissional[i].tipdesc!, this.profissional[i].desc!, this.codusuresp).subscribe((res) => {
        console.log(res)
        this.alertService.alert('Desconto dos profissionais alterado!', 'success')
        this.loading = false;
        this.showProfissionais = false;
      },
        (error) => {
          console.log('deu ruim')
          this.loading = false;
          this.showProfissionais = false;
          this.alertService.alert('Algo deu errado.', 'error');
        })

      console.log(this.profissional[i].codfunc, this.profissional[i].tipdesc, this.profissional[i].desc)
    }
  }

  /**METODO PARA ATUALIZAR O DESCONTO DO SERVIÇO SELECIONADO */
  atualizaPontosDoProduto() {
    this.loading = true;

    for (let i = 0; i < this.servico.length; i++) {
      this.verificaHabilitaPontuacao(this.servico[i]);

      this.descontoService.atualizaPontosPronduto(this.servico[i].codprod!, this.servico[i].habilitapont!, this.servico[i].pont!, this.codusuresp).subscribe((res) => {
        console.log(res)
        this.alertService.alert('Pontuação dos produtos alterada!', 'success')
        this.loading = false;
        this.showFidelidade = false;
      },
        (error) => {
          console.log('deu ruim')
          this.loading = false;
          this.showFidelidade = false;
          this.alertService.alert('Algo deu errado.', 'error');
        })
    }
  }



  /**RECUPERA O CODUSU PARA SER USADO NA AUDITORIA DOS METODOS */
  recuperaCodUsu() {
    this.codusuresp = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }

  /**RECUPERA O CODCLI PARA SER USADO NA BUSCA DAS BARBEARIAS */
  recuperaCodCli() {
    this.codcli = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODCLI;
  }

  /**MOSTRA O DIALOG DO DESCONTO EM SERVIÇO */
  mostraDialogServicos() {
    if ((this.alterar && this.permissao != undefined && this.permissao.length > 0) || (this.codcli > 0 && (this.permissao === undefined || this.permissao.length === 0))) {
      this.selecionouServico = [];
      this.selecionouProfissional2 = [];
      this.buscaServicos();
      this.showServicos = true;
    } else {
      this.showError('Atenção', 'Ação não permitida.');
      return;
    }
  }

  /**MOSTRA O DIALOG PARA PREENCHIMENTO EM MASSA DO SERVIÇO */
  mostraDialogDescontoMassaServico() {
    this.descontoMassaServico = true;
    this.showDescontoMassa = true;
  }

  /**MOSTRA O DIALOG PARA PREENCHIMENTO EM MASSA DO PROFISSIONAL */
  mostraDialogDescontoMassaProfissionais() {
    this.descontoMassaProfissional = true;
    this.showDescontoMassa = true;
  }

  /**MOSTRA O DIALOG DO DESCONTO  DO PROFISSIONAL*/
  mostraDialogProfissionais() {
    if ((this.alterar && this.permissao != undefined && this.permissao.length > 0) || (this.codcli > 0 && (this.permissao === undefined || this.permissao.length === 0))) {
      this.buscaProfissionalsPorCodCli();
      this.showProfissionais = true;
    } else {
      this.showError('Atenção', 'Ação não permitida.');
      return;
    }
  }

  /**MOSTRA O DIALOG PARA PREENCHIMENTO EM MASSA DO PONTOS DO SERVIÇO */
  mostraDialogPontosMassaServico() {
    this.showPontosMassa = true;
    this.descontoMassaFidelidade = true;
  }

  /**MOSTRA O DIALOG DA FIDELIDADE*/
  mostraDialogFidelidade() {
    if ((this.alterar && this.permissao != undefined && this.permissao.length > 0) || (this.codcli > 0 && (this.permissao === undefined || this.permissao.length === 0))) {
      this.selecionouServico = [];
      this.selecionouProfissional2 = [];
      this.buscaServicos();
      this.showFidelidade = true;
    } else {
      this.showError('Atenção', 'Ação não permitida.');
      return;
    }
  }

  /**METODO DE TOAST */
  showError(titulo: string, mensagem: string) {
    if (screen.width < 640 || screen.height < 480) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else if (screen.width < 1024 || screen.height < 768) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else {
      this.messageService.add({
        key: 'tr',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    }
  }

  /**SEMPRE QUE MUDAR A OPÇÃO DO TIPO DE DESCONTO VAI ZERAR O CAMPO DE DESCONTO */
  zeraCampoDesconto(desconto: any) {
    desconto.desc = 0;
  }


  /**METODO PARA QUE BUSCA OS BARBEIROS PARA A TABELA DE DESCONTO EM PROFISSIONAL */
  buscaProfissionalsPorCodCli() {
    this.profissionalService.buscarProfissionalporCodCli(this.codcli, this.codusuresp).subscribe((res) => {
      this.profissional = res;
      console.log(this.profissional)
    },
      (error) => {
        console.log(error.message)
        console.log('deu ruim')
      })
  }

  /**METODO PARA QUE BUSCA OS SERVIÇOS PARA A TABELA DE DESCONTO DO SERVIÇO */
  buscaServicos() {
    this.servicoService.buscarServicoPorCodCli(this.codcli, this.codusuresp).subscribe((res) => {
      this.teveRespostaServico = false;
      this.servico = res;
      console.log(this.servico)

      /**ATRIBUI TRUE OU FALSE PARA O SWITCH DA PONTUAÇÃO DO SERVIÇO */
      for (let i = 0; i < this.servico.length; i++) {
        if (this.servico[i].habilitapont === 'S') {
          this.servico[i].habilitaPontuacao = true;
        } else {
          this.servico[i].habilitaPontuacao = false;
        }
      }
    },
      (error) => {
        console.log(error.message)
        console.log('deu ruim')
        this.teveRespostaServico = false;
        this.alertService.alert('Algo deu errado.', 'error');
        //this.showError('Atenção', 'Algo deu errado.')

      })
  }

  /**METODO QUE FAZ ATRIBUIÇÃO DE TRUE OU FALSE PARA O inputswitch */
  verificaHabilitaPontuacao(servico: Servico) {
    if (servico.habilitaPontuacao === true) {
      servico.habilitapont = 'S'
    } else {
      servico.habilitapont = 'N'
    }
  }

  zeraPontos(servico: any) {
    servico.pont = 0;
  }


  /**RECUPERA AS PERMISSÕES */
  recuperaPermissao() {
    this.permissao = this.permissoesService.getPermissao();
    console.log(this.permissao)
    if (this.permissao != undefined && this.permissao.length > 0) {
      if (this.permissao[8].acao[1].PERMITE === 'S') {
        this.alterar = true;
      }
      // /**ATRIBUI O CODCLI VINCULADO NA PERMISSAO DO FUNCIONARIO, PARA FAZER AS OPERAÇÕES DA TELA */
      this.codcli = this.permissao[0].codcli;
    }

  }


}
