<p-toast position="bottom-center" key="bc"></p-toast>
<p-toast position="top-right" key="tr"></p-toast>
<div class="pt-7">

    <div class="flex flex-column  pb-3">
        <div class="flex align-items-center justify-content-center">
            <img src="./assets/iconeCadastrarCupom.svg" />
        </div>
        <div class=" titulonegritoCadCupom flex align-items-center justify-content-center">
            <span>Cupom de desconto</span>
        </div>
    </div>



    <div class="flex justify-content-center flex-wrap ">
        <div class="flex align-items-center justify-content-center">
            <div class="scroll-divCadCupom">
                <table id="tabela">
                    <tr *ngFor="let cupom of cupom">
                        <td>
                            <div class="linhahorizontalCadastrarCupom pt-1"></div>
                            <div class="col-12 sm-12 md-4 ">
                                <div class="flex flex-column">

                                    <div
                                        class="flex align-items-center justify-content-center tamanhoLinhaTabelaCadCupom">
                                        <div class="col "
                                            [ngStyle]="cupom.ativo==='S'?{'opacity': ' 1'} : {'opacity': ' 0.5'}">
                                            <div class="fontTituloCadCupom">
                                                <span class="xl:text-xl">{{cupom.nomecupom}}</span>
                                            </div>
                                            <div class="formgroup-inline justify-content-between flex-wrap">
                                                <div class="fontDescCupom pb-2 ">
                                                    <div class="flex gap-2">
                                                        <div
                                                            class="flex align-items-center justify-content-center gap-1 ">
                                                            <img class="iconeEmail" src="./assets/iconeEstrela.svg" />
                                                            <span
                                                                class="xl:text-base maximoCaracter white-space-nowrap overflow-hidden text-overflow-ellipsis">
                                                                {{cupom.descrcupom}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex align-items-center justify-content-center ">
                                            <img class="cursor-pointer" src="./assets/iconeMenuAdm.png"
                                                (click)="menu.toggle($event); cupomSelecionado(cupom)" />
                                            <p-menu #menu [popup]="true" [model]="itemsCupons"
                                                styleClass="menuCadCupom"></p-menu>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <p-confirmDialog #cd [closable]="false" id="confirmCadastraCupom">
            <ng-template pTemplate="footer">
                <button type="button" pButton icon="pi pi-times" label="Retornar" (click)="cd.reject()"
                    id="confirmBotaoCupom"></button>
                <button type="button" pButton icon="pi pi-check" label="Confirmar" (click)="cd.accept()"
                    id="confirmBotaoCupom"></button>
            </ng-template>
        </p-confirmDialog>
    </div>
</div>

<div class="flex justify-content-center flex-wrap ">
    <div class="flex align-items-center justify-content-center">
        <footer class="xl:w-5" id="footerCadastrarCupom">
            <p-button label="Cadastrar" id="botaoCadastrarCupom" (click)="mostraDialogCupom()"
                [style]="{width: '100%'}">
            </p-button>
        </footer>
    </div>
</div>

<!--                          DIALOG DE CONFIGURAÇÕES DO CUPOM               -->
<p-dialog header="Cupom de desconto" [(visible)]="showCupom" [modal]="true" [draggable]="false"
    [breakpoints]="{'960px': '534px', '640px': '327px'}" id="dialogInfoCupom">


    <div class="balao">
        <p-button class=" posicao " styleClass="p-button-rounded" id="botaoIzinho"></p-button>
        <div class="msgBalao">
            Caso exista promoção concorrente, será habilitado a mais antiga
        </div>
    </div>

    <div class="flex justify-content-center flex-wrap col">
        <div class="fontDialogInfoCupom flex align-items-center justify-content-center">
            <span>Informações sobre o cupom</span>
        </div>
    </div>

    <input type="text" [(ngModel)]="nomecupom" pInputText placeholder="Nome do Cupom" [style]="{width: '100%'}"
        class="mt-2 uppercase">

    <div class="pb-2 pt-1">
        <textarea pInputTextarea placeholder="Descrição do cupom" [(ngModel)]="descrcupom" [autoResize]="true"
            maxlength="150" class="expandeTextArea" (ngModelChange)=" contaCaracter()"></textarea>
        <div class="flex justify-content-end flex-wrap fontContadorCaracter">{{caracteresRestantes}}/150</div>
    </div>

    <p-dropdown [options]="estabelecimento" [(ngModel)]="estabelecimentoSelecionado" placeholder="Estabelecimento" optionLabel="nome"
        optionValue="codemp" [style]="{'width': '100%'}" id="dropDesconto" scrollHeight="80px"
        (onChange)="buscarProfissionals()">
    </p-dropdown>

    <div *ngIf="teveRespostaEstabelecimento" class="">
        <p-button id="botaoSpinner" label="Profissional" [disabled]="true" class="p-button-text">
            <i class="pi pi-spin pi-spinner  flex-order-1" style="font-size: 1rem"></i>
        </p-button>
    </div>

    <div *ngIf="!teveRespostaEstabelecimento" class="">
        <p-dropdown [options]="profissional" [(ngModel)]="profissionalSelecionado" placeholder="Profissional" optionLabel="nomefunc"
            optionValue="codfunc" [style]="{'width': '100%'}" id="dropDesconto" scrollHeight="80px"
            (onChange)="buscarServicoPorCodFuncionario(profissionalSelecionado)">
        </p-dropdown>
    </div>

    <div *ngIf="teveRespostaServico">
        <p-button id="botaoSpinner" label="Serviço" [disabled]="true" class="p-button-text">
            <i class="pi pi-spin pi-spinner  flex-order-1" style="font-size: 1rem"></i>
        </p-button>
    </div>

    <div *ngIf="!teveRespostaServico">
        <p-multiSelect [options]="servico" [(ngModel)]="servicoSelecionado" optionLabel="nomeprod" scrollHeight="130px"
            [style]="{'width': '100%'}" placeholder="Serviço" optionValue="codprod" [filter]="false"
            [showHeader]="false" [disabled]="desabilitaServico"></p-multiSelect>
    </div>

    <div class="pt-1">
        <input type="number" pInputText [(ngModel)]="qtdmax" placeholder="Quantidade de uso permitida?"
            [style]="{width: '100%'}" [min]="0" />

    </div>

    <div class="grid">
        <div class=" col">
            <div class="grid  col">
                <div class="w-6 ">
                    <p-calendar [touchUI]="true" [readonlyInput]="false" inputId="touchui" dateFormat="dd/mm/yy"
                        [readonlyInput]="true" id="calendarioDesconto" [(ngModel)]="dataInicial"
                        placeholder="Data Inicial">
                    </p-calendar>
                </div>
                <div class="w-6">
                    <p-calendar [touchUI]="true" [readonlyInput]="false" inputId="touchui" dateFormat="dd/mm/yy"
                        [minDate]="dataInicial" [readonlyInput]="true" id="calendarioDesconto" [(ngModel)]="dataFinal"
                        placeholder="Data Final">
                    </p-calendar>
                </div>
            </div>
        </div>
    </div>
    <div class="grid">
        <div class="col">
            <div class="grid  col">
                <div class="w-6 ">
                    <p-dropdown [options]="tipoCupom" [(ngModel)]="tipdesc" placeholder="Tipo" optionLabel="opcao"
                        optionValue="tipo" id="dropTipoCupom" scrollHeight="80px" [style]="{width: '100%'}"
                        (onChange)="zeraCampoDesconto()">
                    </p-dropdown>
                </div>
                <div class="w-6">
                    <div *ngIf="tipdesc==='P'">
                        <!-- <p-inputMask mask="%99" placeholder="Desconto" [style]="{width: '100%'}" inputmode="numeric"
                            [(ngModel)]="desc" [max]="100"></p-inputMask> -->
                        <p-inputNumber [(ngModel)]="desc" inputId="percent" prefix="%" placeholder="Desconto"
                            id="CampoDesconto" [min]="0" [max]="99"> </p-inputNumber>
                    </div>
                    <div *ngIf="tipdesc==='R'">
                        <!-- <p-inputMask mask="R$99,99" placeholder="Desconto" [style]="{width: '100%'}" inputmode="numeric"
                            [(ngModel)]="desc"></p-inputMask> -->
                        <p-inputNumber [(ngModel)]="desc" inputId="currency-br" mode="currency" currency="BRL"
                            locale="pt-BR" id="CampoDesconto" [min]="0"> </p-inputNumber>

                    </div>
                    <div *ngIf="tipdesc===''">
                        <!-- <p-inputMask mask="R$99,99" placeholder="Desconto" [style]="{width: '100%'}" inputmode="numeric"
                            [(ngModel)]="desc"></p-inputMask> -->
                        <p-inputNumber [(ngModel)]="descVazio" placeholder="Desconto" id="CampoDesconto" [min]="0">
                        </p-inputNumber>

                    </div>
                </div>

            </div>
        </div>
    </div>

    <div *ngIf="!loading" [style]="{width: '100%'}">
        <button pButton type="button" label="Salvar" class="col-12 " [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
        'border-radius': '11px'}" (click)="criaCupom()"></button>
    </div>
    <div *ngIf="loading" [style]="{width: '100%'}">
        <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 " [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
        'border-radius': '11px'}"></button>
    </div>
</p-dialog>

<!--                          DIALOG ATIVA/DESATIVA             -->

<p-dialog header="Ativar/ Desativar Cupom" [(visible)]="showAtivarDesativarCupom" [modal]="true"
    [style]="{'width': '350px', 'height': '240px'}" id="dialogAtvDst">
    <div class="flex justify-content-center flex-wrap col">
        <div class="fontDialogInfoCupom flex align-items-center justify-content-center">
            <span>Se ativo, o cupom estará disponível para ser utilizado</span>
        </div>
    </div>

    <div class="p-field-radiobutton pb-1">
        <p-radioButton value="S" [(ngModel)]="radioButtonSelecionado"></p-radioButton>
        <label class="pl-2">Ativar cupom</label>
    </div>
    <div class="p-field-radiobutton pb-1">
        <p-radioButton value="N" [(ngModel)]="radioButtonSelecionado"></p-radioButton>
        <label class="pl-2">Desativar cupom</label>
    </div>




    <div *ngIf="!loading" [style]="{width: '100%'}">
        <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
        'border-radius': '11px'}" (click)="ativaDesativaCupom()"></button>
    </div>
    <div *ngIf="loading" [style]="{width: '100%'}">
        <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
        'border-radius': '11px'}"></button>
    </div>
</p-dialog>