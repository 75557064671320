<p-toast position="top-center" key="tc"></p-toast>
<p-toast position="top-right" key="tr"></p-toast>

<body id="cadastro">
    <div class="flex flex-column align-items-center  redimensionamentoCadastro ">
        <div class="col-12 sm-12 md-4 xl:col-5 lg:col-5 md:col-5 sm:col-5">
            <div class="flex align-items-center justify-content-center">
                <div class="flex align-items-center justify-content-center">

                    <img src="./assets/logo.svg" />
                </div>
            </div>
            <!-- <div class="align-items-center justify-content-center ">
                <div *ngIf="this.credInvalida">
                    <p-messages severity="error">
                        <ng-template pTemplate>
                            <div>{{msgErro}}</div>
                        </ng-template>
                    </p-messages>
                </div>
            </div> -->
            <div class="subir">
                <div class="col-12 flex align-items-center justify-content-center divPersonCadastro">
                    <input id="cadastro" class="col-12" type="text" [(ngModel)]="nome" pInputText placeholder="Nome" />
                </div>
                <div class="col-12 divPersonCadastro">
                    <p-inputMask id="cadastro" mask="99/99/9999" [(ngModel)]="dataNasc" placeholder="Data de Nascimento"
                        [style]="{width: '100%', 'border-radius': '31px', 'color': 'var(--cor3)', 'font-family': 'Roboto', 'font-size': '18px', 'height':'40px', 'font-style': 'normal'}"></p-inputMask>
                </div>
                <div class="col-12 flex align-items-center justify-content-center divPersonCadastro">
                    <input id="cadastro" class="col-12" type="text" [(ngModel)]="email" pInputText
                        placeholder="E-mail" />
                </div>
                <div class="col-12  divPersonCadastro ">
                    <div class="p-fluid">


                        <!-- <input id="cadastro" class="col-12" type="number" [(ngModel)]="telefone" pInputText
                        placeholder="Telefone" /> -->
                        <p-inputMask mask="(99) 99999-9999" [(ngModel)]="telefone" placeholder="Telefone"
                            id="telefone"></p-inputMask>
                    </div>
                </div>
                <div class="col-12 flex align-items-center justify-content-center divPersonCadastro">
                    <input id="cadastro" class="col-12" type="password" [(ngModel)]="senha" pInputText
                        placeholder="Senha" />
                </div>
                <div class="col-12 pt-2">
                    <div class=" grid p-flup-2 flex align-items-center justify-content-center">
                        <div class="field col  gap-2">
                            <button id="voltarCadastro" pButton type="button" label="Voltar" class="col-12"
                                routerLink="/login"></button>
                        </div>
                        <!-- 
                    <div class="field col">
                        <button pButton type="button" label="Criar Conta" (click)="criarUsuario();"
                            class="col-12"></button>
                    </div> -->



                        <div *ngIf="!loading" [style]="{width: '100%'}" class="field col">
                            <button id="criarConta" pButton type="button" label="Criar Conta"
                                (click)="criarUsuario()"></button>
                        </div>
                        <div *ngIf="loading" [style]="{width: '100%'}" class="field col">
                            <button id="botaoLoadingCadastro" pButton [disabled]="true" type="button" label=""></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>