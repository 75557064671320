<p-toast position="bottom-center" key="bc"></p-toast>
<p-toast position="top-right" key="tr"></p-toast>
<div class="pt-7">

    <div class="flex flex-column  pb-3">
        <div class="flex align-items-center justify-content-center">
            <img src="./assets/iconeCadastrarServicos.svg" class="iconeCadastrarServico" />
        </div>

        <div class=" flex align-items-center justify-content-center">
            <span
                class="tituloNegritoServico  maximoCaracter white-space-nowrap overflow-hidden text-overflow-ellipsis">{{exibeNome}}</span>
        </div>
    </div>

    <div class="flex justify-content-center flex-wrap ">
        <div class="xl:col-5 lg:col-5 md:col-5 sm:col-5">
            <div class="shadow-4">
                <p-card header="Dados do Serviço" id="cardServico">
                    <div>
                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            (click)="mostraDialogServico()">
                            <div class="flex gap-2 ">
                                <img src="./assets/imgServicoCadastro.svg" class="iconeCadastroServico"
                                    class=" xl:w-3rem" />
                                <div class="flex flex-column  align-items-start">
                                    <div class=" fontTituloServico">
                                        <span class="xl:text-xl">Serviços</span>
                                    </div>
                                    <div class="fontCinzaServico">
                                        <span class="xl:text-base">Preencher serviços, preços, tempo</span>
                                    </div>
                                </div>
                            </div>
                            <div class="align-items-center justify-content-center ">
                                <img src="./assets/imgBarbeariaPopup.png" class="iconePopUpServico" />
                            </div>

                        </div>

                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            (click)="mostraDialogCategoria()">
                            <div class="flex gap-2 ">
                                <img src="./assets/imgServicoCadastro.svg" class="iconeCadastroServico"
                                    class=" xl:w-3rem" />
                                <div class="flex flex-column  align-items-start">
                                    <div class=" fontTituloServico">
                                        <span class="xl:text-xl">Categorias</span>
                                    </div>
                                    <div class="fontCinzaServico">
                                        <span class="xl:text-base">Preencher categoria do serviço</span>
                                    </div>
                                </div>
                            </div>
                            <div class="align-items-center justify-content-center ">
                                <img src="./assets/imgBarbeariaPopup.png" class="iconePopUpServico" />
                            </div>

                        </div>

                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            (click)="mostraDialogAtivarDesativarServico()">
                            <div class="flex gap-2 ">
                                <img src="./assets/imgLigarCadastro.svg" class="iconeCadastroServico"
                                    class=" xl:w-3rem" />
                                <div class="flex flex-column align-items-start">
                                    <div class=" fontTituloServico">
                                        <span class="xl:text-xl">Ativar/Desativar </span>
                                    </div>
                                    <div class="fontCinzaServico">
                                        <span class="xl:text-base">Tornar serviço ativo na listagem ao
                                            cliente</span>
                                    </div>
                                </div>
                            </div>
                            <div class="align-items-center justify-content-center ">
                                <img src="./assets/imgBarbeariaPopup.png" class="iconePopUpServico" />
                            </div>
                        </div>
                    </div>
                </p-card>
            </div>
        </div>
        <!--                          DIALOG DO Servico              -->
        <p-dialog header="Informações do Serviço" [(visible)]="showServico" [modal]="true"
            [style]="{'width': '350px', 'height': '540px'}" id="dialogServico">
            <div class="col-12">
                <div class="fontDialogServico flex align-items-center justify-content-center ">
                    <span>Informe alguns dados do serviço</span>
                </div>
            </div>
            <input type="text" pInputText placeholder="Nome do Serviço" [(ngModel)]="nomeprod"
                [style]="{'width': '100%'}">

            <div>
                <textarea pInputTextarea placeholder="Descrição do Serviço" [(ngModel)]="descrprod" [autoResize]="false"
                    class="mt-1 " [style]="{'width': '100%', 'height': '20%'}"></textarea>
            </div>
            <div class="pt-1 p-fluid">
                <p-inputNumber [(ngModel)]="preco" mode="currency" currency="BRL" locale="pt-BR"
                    placeholder="Valor do Serviço" [minFractionDigits]="2" id="inputPreco">
                </p-inputNumber>
            </div>

            <p-dropdown [options]="estabelecimento" [(ngModel)]="estabelecimentoSelecionado" placeholder="Barbearia"
                optionLabel="nome" optionValue="codemp" [style]="{'width': '100%'}" id="dropServico" scrollHeight="80px"
                (onChange)="buscarProfissionalsPorCodEmp()">
            </p-dropdown>

            <div *ngIf="teveRespostaEstabelecimento" class="pb-1">
                <p-button id="botaoSpinner" label="Barbeiro" [disabled]="true" class="p-button-text">
                    <i class="pi pi-spin pi-spinner teste flex-order-1" style="font-size: 1rem"></i>
                </p-button>
            </div>

            <div *ngIf="!teveRespostaEstabelecimento" class="pb-1">
                <p-dropdown [options]="profissional" [(ngModel)]="profissionalSelecionado" placeholder="Barbeiro"
                    optionLabel="apelido" optionValue="codfunc" [style]="{'width': '100%'}" id="dropServico"
                    scrollHeight="80px">
                </p-dropdown>
            </div>
            <div class=" mb-1">
                <p-inputMask mask="99:99" placeholder="Tempo de Atendimento" [style]="{width: '100%'}"
                    inputmode="numeric" [(ngModel)]="tempom"></p-inputMask>
            </div>


            <div class="p-fluid">
                <p-inputNumber [(ngModel)]="comissao" suffix="%" id="percentServico" placeholder="% Comissão"
                    [minFractionDigits]="2" [max]="100" [min]="0"></p-inputNumber>
            </div>

            <div *ngIf="!loading" [style]="{width: '100%'}">
                <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                'border-radius': '11px'}" (click)="salvarServico()"></button>
            </div>
            <div *ngIf="loading" [style]="{width: '100%'}">
                <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 mt-3"
                    [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                'border-radius': '11px'}"></button>
            </div>

        </p-dialog>

        <!--                          DIALOG DA CATEGORIA              -->
        <p-dialog header="Categoria do Serviço" [(visible)]="showCategoria" [modal]="true"
            [style]="{'width': '350px', 'height': '230px'}" id="dialogServico">
            <div class="col-12">
                <div class="fontDialogServico flex align-items-center justify-content-center ">
                    <span>Informe a categoria do serviço</span>
                </div>
            </div>
            <p-dropdown [options]="categorias" [(ngModel)]="categoriaSelecionada" placeholder="Categoria"
                [style]="{'width': '100%'}" id="dropServico" optionValue="categoria" optionLabel="categoria"
                scrollHeight="80px">
            </p-dropdown>

            <div *ngIf="!loading" [style]="{width: '100%'}">
                <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                    'border-radius': '11px'}" (click)="atualizaCategoria()"></button>
            </div>
            <div *ngIf="loading" [style]="{width: '100%'}">
                <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 mt-3"
                    [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                    'border-radius': '11px'}"></button>
            </div>

        </p-dialog>


        <!--                          DIALOG ATIVA/DESATIVA             -->

        <p-dialog header="Ativar/ Desativar Serviço" [(visible)]="showAtivarDesativarServico" [modal]="true"
            [style]="{'width': '350px', 'height': '249px'}" id="dialogAtvDstServico">
            <div class="flex justify-content-center flex-wrap col">
                <div class="fontDialogAtvServico flex align-items-center justify-content-center">
                    <span>Se ativo, o serviço estará disponível como opção para ser realizado</span>
                </div>
            </div>
            <!-- <div class="fontDialogAtvObsServico flex align-items-center justify-content-center pb-4 pt-2">
                <span>Obs: Para ativar o profissional, é necessário que ele tenha conta!</span>
            </div> -->

            <div class="p-field-radiobutton pb-1">
                <p-radioButton value="S" [(ngModel)]="valorSelecionadoServico"></p-radioButton>
                <label class="pl-2">Ativar serviço</label>
            </div>
            <div class="p-field-radiobutton pb-1">
                <p-radioButton value="N" [(ngModel)]="valorSelecionadoServico"></p-radioButton>
                <label class="pl-2">Desativar serviço</label>
            </div>



            <div *ngIf="!loading" [style]="{width: '100%'}">
                <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                        'border-radius': '11px'}" (click)="ativaDesativaServico()"></button>
            </div>
            <div *ngIf="loading" [style]="{width: '100%'}">
                <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 mt-3"
                    [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                        'border-radius': '11px'}"></button>
            </div>
        </p-dialog>
    </div>
    <br>