<p-toast position="bottom-center" key="bc"></p-toast>
<p-toast position="top-right" key="tr"></p-toast>
<div class="pt-7">


    <div class="flex flex-column  pb-3">
        <div class="flex align-items-center justify-content-center">
            <img src="./assets/iconeCadastrarNotificacoes.svg" />
        </div>
        <div class=" flex align-items-center justify-content-center">
            <span
                class=" tituloNegritoNotificacao maximoCaracter white-space-nowrap overflow-hidden text-overflow-ellipsis">Notificações</span>
        </div>
    </div>


    <div class="flex justify-content-center flex-wrap ">


        <div class="xl:col-5 lg:col-5 md:col-5 sm:col-5">
            <div class="shadow-4">


                <p-card header="Preferências das Notificações" id="cardNotificacao">
                    <div>
                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            (click)="mostraDialogWhats()">
                            <div class="flex gap-2 ">
                                <img src="./assets/whats.svg" class=" xl:w-3rem" />
                                <div class="flex flex-column align-items-start">
                                    <div class=" fontTituloNotificacao">
                                        <span class="xl:text-xl">Habilitar notificação whastapp?</span>
                                        <img *ngIf="statusWpp" src="./assets/bolinhaVerde.svg" class="pl-1" />
                                        <img *ngIf="!statusWpp" src="./assets/bolinhaLaranja.svg" class="pl-1" />
                                    </div>
                                    <div class="fontCinzaNotificacao">
                                        <span class="xl:text-base">Envia notificação via WhatsApp</span>
                                    </div>
                                </div>
                            </div>
                            <div class="align-items-center justify-content-center  ">
                                <img src="./assets/imgBarbeariaPopup.png" />
                            </div>
                        </div>

                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            (click)="mostraDialogPush()">
                            <div class="flex gap-2 ">
                                <img src="./assets/notificacao.svg" class=" xl:w-3rem" />
                                <div class="flex flex-column  align-items-start">
                                    <div class=" fontTituloNotificacao">
                                        <span class="xl:text-xl">Habilitar notificação push? </span>
                                        <img *ngIf="statusPush" src="./assets/bolinhaVerde.svg" class="pl-1" />
                                        <img *ngIf="!statusPush" src="./assets/bolinhaLaranja.svg" class="pl-1" />
                                    </div>
                                    <div class="fontCinzaNotificacao">
                                        <span class="xl:text-base">Envia notificações dentro do BarberExpress</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex align-items-center justify-content-center ">
                                <img src="./assets/imgBarbeariaPopup.png" class="iconePopUpBarbearia" />
                            </div>
                        </div>

                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            (click)="mostraDialogRetorno()">
                            <div class="flex gap-2 ">
                                <img src="./assets/retorno.svg" class=" xl:w-3rem" />
                                <div class="flex flex-column align-items-start">
                                    <div class="fontTituloNotificacao">
                                        <span class="xl:text-xl">Habilitar notificação de retorno?</span>
                                        <img *ngIf="statusRet" src="./assets/bolinhaVerde.svg" class="pl-1" />
                                        <img *ngIf="!statusRet" src="./assets/bolinhaLaranja.svg" class="pl-1" />
                                    </div>
                                    <div class="fontCinzaNotificacao">
                                        <span class="xl:text-base">Envia notificação automática de retorno</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex align-items-center justify-content-end  ">
                                <img src="./assets/imgBarbeariaPopup.png" class="iconePopUpBarbearia" />
                            </div>
                        </div>

                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            (click)="mostraDialogAniver()">
                            <div class="flex gap-2 ">
                                <img src="./assets/aniversariantes.svg" class=" xl:w-3rem" />
                                <div class="flex flex-column align-items-start">
                                    <div class="fontTituloNotificacao">
                                        <span class="xl:text-xl">Habilitar notificação aniversariante?</span>
                                        <img *ngIf="statusAni" src="./assets/bolinhaVerde.svg" class="pl-1" />
                                        <img *ngIf="!statusAni" src="./assets/bolinhaLaranja.svg" class="pl-1" />
                                    </div>
                                    <div class="fontCinzaNotificacao">
                                        <span class="xl:text-base">Envia notificação de felicitações aos
                                            aniversariantes</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex align-items-center justify-content-end ">
                                <img src="./assets/imgBarbeariaPopup.png" />
                            </div>
                        </div>

                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            (click)="mostraDialogConfirmaAgd()">
                            <div class="flex gap-2 ">
                                <img src="./assets/confirmaAgd.svg" class=" xl:w-3rem" />
                                <div class="flex flex-column align-items-start">
                                    <div class="fontTituloNotificacao">
                                        <span class="xl:text-xl">Notificação de agendamento</span>
                                        <img *ngIf="statusAgd" src="./assets/bolinhaVerde.svg" class="pl-1" />
                                        <img *ngIf="!statusAgd" src="./assets/bolinhaLaranja.svg" class="pl-1" />
                                    </div>
                                    <div class="fontCinzaNotificacao">
                                        <span class="xl:text-base">Mensagem de confirmação de agendamento</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex align-items-center justify-content-end ">
                                <img src="./assets/imgBarbeariaPopup.png" />
                            </div>
                        </div>

                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            (click)="mostraDialogLembrete()">
                            <div class="flex gap-2 ">
                                <img src="./assets/lembreteAgd.svg" class=" xl:w-3rem" />
                                <div class="flex flex-column align-items-start">
                                    <div class="fontTituloNotificacao">
                                        <span class="xl:text-xl">Notificação de lembrete</span>
                                        <img *ngIf="statusLembrete" src="./assets/bolinhaVerde.svg" class="pl-1" />
                                        <img *ngIf="!statusLembrete" src="./assets/bolinhaLaranja.svg" class="pl-1" />
                                    </div>
                                    <div class="fontCinzaNotificacao">
                                        <span class="xl:text-base">Mensagem de lembrete sobre o agendamento</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex align-items-center justify-content-end ">
                                <img src="./assets/imgBarbeariaPopup.png" />
                            </div>
                        </div>


                        <div class="formgroup-inline justify-content-between flex-wrap" (click)="mostraDialogTransmi()">
                            <div class="flex gap-2 ">
                                <img src="./assets/transmissao.svg" class=" xl:w-3rem" />
                                <div class="flex flex-column align-items-start">
                                    <div class="fontTituloNotificacao">
                                        <span class="xl:text-xl">Lista de transmissão</span>
                                    </div>
                                    <div class="fontCinzaNotificacao">
                                        <span class="xl:text-base">Envia notificação personalizada aos clientes</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex align-items-center justify-content-end ">
                                <img src="./assets/imgBarbeariaPopup.png" />
                            </div>
                        </div>
                    </div>
                </p-card>
            </div>
        </div>
    </div>
    <br>


    <!--                          DIALOG NOTIFICAÇÃO WHATS             -->

    <p-dialog header="Ativar/Desativar Not. WhatsApp" [(visible)]="showWhats" [modal]="true"
        [style]="{'width': '327px', 'height': '240px'}" id="dialogAtvDst">
        <div class="flex justify-content-center flex-wrap col">
            <div class="fontDialogAtv flex align-items-center justify-content-center">
                <span>Se ativo, o cliente recebe notificações via WhatsApp (número: BarberExpress)</span>
            </div>
        </div>

        <div class="p-field-radiobutton pb-1">
            <p-radioButton value="S" [(ngModel)]="valorSelecionadoWhats"></p-radioButton>
            <label class="pl-2 fontRadioButton">Ativar notificação</label>
        </div>
        <div class="p-field-radiobutton pb-1">
            <p-radioButton value="N" [(ngModel)]="valorSelecionadoWhats"></p-radioButton>
            <label class="pl-2 fontRadioButton">Desativar notificação</label>
        </div>

        <div *ngIf="!loading" [style]="{width: '100%'}">
            <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                  'border-radius': '11px'}" (click)="ativaDesativaNotWpp()"></button>
        </div>
        <div *ngIf="loading" [style]="{width: '100%'}">
            <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 mt-3"
                [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                  'border-radius': '11px'}"></button>
        </div>
    </p-dialog>


    <!--                          DIALOG NOTIFICAÇÃO PUSH             -->

    <p-dialog header="Ativar/Desativar Not. Push" [(visible)]="showPush" [modal]="true"
        [style]="{'width': '327px', 'height': '220px'}" id="dialogAtvDst">
        <div class="flex justify-content-center flex-wrap col">
            <div class="fontDialogAtv flex align-items-center justify-content-center">
                <span>Se ativo, o cliente recebe notificações aplicativo ou sistema BarberExpress</span>
            </div>
        </div>

        <div class="p-field-radiobutton pb-1">
            <p-radioButton value="S" [(ngModel)]="valorSelecionadoPush"></p-radioButton>
            <label class="pl-2 fontRadioButton">Ativar notificação</label>
        </div>
        <div class="p-field-radiobutton pb-1">
            <p-radioButton value="N" [(ngModel)]="valorSelecionadoPush"></p-radioButton>
            <label class="pl-2 fontRadioButton">Desativar notificação</label>
        </div>

        <div *ngIf="!loading" [style]="{width: '100%'}">
            <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                  'border-radius': '11px'}" (click)="ativaDesativaNotPush()"></button>
        </div>
        <div *ngIf="loading" [style]="{width: '100%'}">
            <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 mt-3"
                [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                  'border-radius': '11px'}"></button>
        </div>
    </p-dialog>

    <!--                 DIALOG NOTIFICAÇÃO DE RETORNO             -->
    <p-dialog header="Notificação de Retorno" [(visible)]="showRetorno" [modal]="true" [style]="{'width': '327px'}"
        id="dialogRetorno">
        <div class="flex justify-content-center flex-wrap col">
            <div class="fontDialogAtv flex align-items-center justify-content-center">
                <span>Notificação automática que aparecerá para o
                    cliente lembrando de seu retorno</span>
            </div>
        </div>

        <div class="pb-2">
            <p-selectButton [options]="opcoesNot" [(ngModel)]="opcaoSelecionadaRetorno" optionLabel="opcao"
                optionValue="opTipo" (onChange)="ativaCampoNot()"></p-selectButton>
        </div>

        <div class="flex align-items-center justify-content-center retanguloNotificacoes">
            <span class="fontRetangulo">Variáveis disponíveis para a construção da notificação: $ESTABELECIMENTO$,
                $NOMECLIENTE$, $DTULTIMOAGENDAMENTO$</span>
        </div>

        <div class="pb-2 pt-1">
            <p-dropdown id="drop" [options]="tipoEnvio" [(ngModel)]="tipoEnvioSelecRet" placeholder="Tipo de envio"
                optionLabel="tipo" optionValue="nuTipo" [style]="{'width': '100%'}" id="drop" scrollHeight="200px"
                [disabled]="statusCampoRet">
            </p-dropdown>
        </div>

        <div>
            <textarea pInputTextarea placeholder="Notificação" [(ngModel)]="notificacaoRetorno" [autoResize]="true"
                class="expandeTextArea2" maxlength="350" (ngModelChange)=" contaCaracter()"
                [disabled]="statusCampoRet"></textarea>
            <div class="flex justify-content-end flex-wrap fontContadorCaracter">{{caracteresRestantesRetorno}}/350
            </div>
        </div>

        <div *ngIf="!loading" [style]="{width: '100%'}">
            <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                  'border-radius': '11px'}" (click)="criaNotRetorno()"></button>
        </div>
        <div *ngIf="loading" [style]="{width: '100%'}">
            <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 mt-3"
                [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                  'border-radius': '11px'}"></button>
        </div>
    </p-dialog>


    <!--                 DIALOG NOTIFICAÇÃO DE ANIVERSARIO             -->
    <p-dialog header="Notificação de Aniversariante" [(visible)]="showAniver" [modal]="true" id="dialogRetorno">
        <div class="flex justify-content-center flex-wrap col">
            <div class="fontDialogAtv flex align-items-center justify-content-center">
                <span>Notificação que aparecerá para o cliente
                    sobre seu aniversário</span>
            </div>
        </div>

        <div class="pb-2">
            <p-selectButton [options]="opcoesNot" [(ngModel)]="opcaoSelecionadaAniver" optionLabel="opcao"
                optionValue="opTipo" (onChange)="ativaCampoNot()"></p-selectButton>
        </div>

        <div class="flex align-items-center justify-content-center retanguloNotificacoes">
            <span class="fontRetangulo">Variáveis disponíveis para a construção da notificação: $ESTABELECIMENTO$,
                $NOMECLIENTE$, $DTANIVERSARIO$</span>
        </div>

        <div class="pb-2 pt-1">
            <p-dropdown id="drop" [options]="tipoEnvio" [(ngModel)]="tipoEnvioSelecAni" placeholder="Tipo de envio"
                optionLabel="tipo" optionValue="nuTipo" [style]="{'width': '100%'}" id="drop" scrollHeight="200px"
                [disabled]="statusCampoAni">
            </p-dropdown>
        </div>

        <div>
            <textarea pInputTextarea placeholder="Notificação" [(ngModel)]="notificacaoAniver" [autoResize]="true"
                class="expandeTextArea2" maxlength="350" (ngModelChange)="contaCaracter()"
                [disabled]="statusCampoAni"></textarea>
            <div class="flex justify-content-end flex-wrap fontContadorCaracter">{{caracteresRestantesAniver}}/350</div>
        </div>

        <div *ngIf="!loading" [style]="{width: '100%'}">
            <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
               'border-radius': '11px'}" (click)="criaNotAniversario()"></button>
        </div>
        <div *ngIf="loading" [style]="{width: '100%'}">
            <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 mt-3"
                [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
               'border-radius': '11px'}"></button>
        </div>
    </p-dialog>

    <!--                 DIALOG NOTIFICAÇÃO DE TRANSMISSÃO             -->
    <p-dialog header="Lista de transmissão" [(visible)]="showTransmi" [modal]="true" id="dialogListaTransmissao">
        <div class="flex justify-content-center flex-wrap col">
            <div class="fontDialogAtv flex align-items-center justify-content-center">
                <span>Notificação que aparecerá para o cliente</span>

            </div>
            <div class="fontDialogAtv flex align-items-center justify-content-center">
                <span> Obs: as notificações não serão enviadas todas imediatamente</span>
            </div>
        </div>

        <div class="flex align-items-center justify-content-center retanguloNotificacoes">
            <span class="fontRetangulo">Variáveis disponíveis para a construção da notificação: $ESTABELECIMENTO$,
                $NOMECLIENTE$, $DTATUAL$, $HRATUAL$</span>
        </div>

        <div class="pt-1">
            <p-dropdown id="drop" [options]="tipoEnvio" [(ngModel)]="tipoEnvioSelecTra" placeholder="Tipo de envio"
                optionLabel="tipo" optionValue="nuTipo" [style]="{'width': '100%'}" id="drop" scrollHeight="300px">
            </p-dropdown>
        </div>

        <!-- <div *ngIf="teveRespostaCliente">
            <p-button id="botaoSpinner" label="Barbearia" [disabled]="true" class="p-button-text">
                <i class="pi pi-spin pi-spinner teste flex-order-1" style="font-size: 1rem"></i>
            </p-button>
        </div> -->

        <!-- <div *ngIf="!teveRespostaCliente" class="pb-1"> -->
        <p-dropdown id="dropNotificacoes" [options]="estabelecimentos" [(ngModel)]="estabelecimentoSelecionada"
            placeholder="Estabelecimento" optionLabel="nome" optionValue="codemp" [style]="{'width': '100%'}"
            scrollHeight="130px" [filter]="false" (onChange)="recuperaClientes()">
        </p-dropdown>
        <!-- </div> -->

        <p-dropdown id="dropNotificacoes" [options]="opcoesCliente" [(ngModel)]="opcaoSelecionadaCliente"
            placeholder="Tipo de Cliente" optionLabel="descr" optionValue="opcao" [style]="{'width': '100%'}"
            scrollHeight="130px" [filter]="false" (onChange)="recuperaClientes()">
        </p-dropdown>


        <div *ngIf="teveRespostaCliente" class="pb-1">
            <p-button id="botaoSpinner" label="Cliente" [disabled]="true" class="p-button-text">
                <i class="pi pi-spin pi-spinner teste flex-order-1" style="font-size: 1rem"></i>
            </p-button>
        </div>

        <div *ngIf="!teveRespostaCliente" class="pb-1">
            <!-- <p-dropdown id="dropNotificacoes" [options]="clientes" [(ngModel)]="clienteSelecionado"
                placeholder="Cliente" optionLabel="nomeparc" [style]="{'width': '100%'}" scrollHeight="130px"
                [filter]="true" >
            </p-dropdown> -->
            <p-multiSelect id="dropNotifTra" [options]="clientes" [(ngModel)]="clienteSelecionado"
                optionLabel="nomeparc" scrollHeight="130px" [style]="{'width': '100%'}" placeholder="Cliente"
                optionValue="codusu">
            </p-multiSelect>
        </div>


        <!-- <p-multiSelect [options]="clientes" [(ngModel)]="clienteSelecionado" optionLabel="nomeprod" scrollHeight="130px"
            [style]="{'width': '100%'}" placeholder="Cliente" [filter]="false" [showHeader]="false"></p-multiSelect> -->

        <div>
            <textarea pInputTextarea placeholder="Notificação" [(ngModel)]="notificacaoTransmi" [autoResize]="true"
                class="expandeTextArea2" maxlength="350" (ngModelChange)=" contaCaracter()"></textarea>
            <div class="flex justify-content-end flex-wrap fontContadorCaracter">{{caracteresRestantesTransmi}}/350
            </div>
        </div>

        <div *ngIf="!loading" [style]="{width: '100%'}">
            <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
            'border-radius': '11px'}" (click)="criaNotificacaoInstantanea()"></button>
        </div>
        <div *ngIf="loading" [style]="{width: '100%'}">
            <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 mt-3"
                [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
            'border-radius': '11px'}"></button>
        </div>
    </p-dialog>


    <!--                 DIALOG NOTIFICAÇÃO DE CONFIRMAÇÃO DE AGENDAMENTO            -->
    <p-dialog header="Notificação de confirmação" [(visible)]="showConfirmaAgd" [modal]="true" id="dialogRetorno">
        <div class="flex justify-content-center flex-wrap col">
            <div class="fontDialogAtv flex align-items-center justify-content-center white-space-nowrap">
                <span>Notificação que aparecerá para o cliente
                    após o agendamento</span>
            </div>
        </div>

        <div class="pb-2">
            <p-selectButton [options]="opcoesNot" [(ngModel)]="opcaoSelecionadaConfirmaAgd" optionLabel="opcao"
                optionValue="opTipo" (onChange)="ativaCampoNot()"></p-selectButton>
        </div>

        <div class="flex align-items-center justify-content-center retanguloNotificacoes">
            <span class="fontRetangulo">Variáveis disponíveis para a construção da notificação: $NOMECLIENTE$,
                $NOMEESTABELECIMENTO$, $HRATUAL$, $DTATUAL$, $NOMEPROFISSIONAL$, $DIASERV$, $HORSERV$</span>
        </div>

        <div class="pb-2 pt-1">
            <p-dropdown id="drop" [options]="tipoEnvio" [(ngModel)]="tipoEnvioSelecConfirmaAgd"
                placeholder="Tipo de envio" optionLabel="tipo" optionValue="nuTipo" [style]="{'width': '100%'}"
                id="drop" scrollHeight="200px" [disabled]="statusCampoAgd">
            </p-dropdown>
        </div>

        <div>
            <textarea pInputTextarea placeholder="Notificação" [(ngModel)]="notificacaoConfirmaAgd" [autoResize]="true"
                class="expandeTextArea2" [disabled]="statusCampoAgd"  maxlength="350"  (ngModelChange)="contaCaracter()"></textarea>
            <div class="flex justify-content-end flex-wrap fontContadorCaracter">{{caracteresRestantesConfirmaAgd}}/350</div>
        </div>

        <div *ngIf="!loading" [style]="{width: '100%'}">
            <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
               'border-radius': '11px'}" (click)="criaNotAgendamento()"></button>
        </div>
        <div *ngIf="loading" [style]="{width: '100%'}">
            <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 mt-3"
                [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
               'border-radius': '11px'}"></button>
        </div>
    </p-dialog>



    <!--                 DIALOG NOTIFICAÇÃO DE LEMBRETE             -->
    <p-dialog header="Notificação de lembrete" [(visible)]="showLembrete" [modal]="true" id="dialogRetorno">
        <div class="flex justify-content-center flex-wrap col">
            <div class="fontDialogAtv flex align-items-center justify-content-center">
                <span>Notificação de lembrete de agendamento</span>
            </div>
        </div>

        <div class="pb-2">
            <p-selectButton [options]="opcoesNot" [(ngModel)]="opcaoSelecionadaLembrete" optionLabel="opcao"
                optionValue="opTipo" (onChange)="ativaCampoNot()"></p-selectButton>
        </div>

        <div class="flex align-items-center justify-content-center retanguloNotificacoes">
            <span class="fontRetangulo">Variáveis disponíveis para a construção da notificação: $NOMECLIENTE$,
                $NOMEESTABELECIMENTO$, $HRATUAL$, $DTATUAL$, $NOMEPROFISSIONAL$, $DIASERV$, $HORSERV$</span>
        </div>

        <div class="pb-2 pt-1">
            <p-dropdown id="drop" [options]="tipoEnvio" [(ngModel)]="tipoEnvioSelecLembrete" placeholder="Tipo de envio"
                optionLabel="tipo" optionValue="nuTipo" [style]="{'width': '100%'}" id="drop" scrollHeight="200px"
                [disabled]="statusCampoLembrete">
            </p-dropdown>
        </div>

        <div>
            <textarea pInputTextarea placeholder="Notificação" [(ngModel)]="notificacaoLembrete" [autoResize]="true"
                class="expandeTextArea2" [disabled]="statusCampoLembrete"  maxlength="350"  (ngModelChange)="contaCaracter()"></textarea>
                <div class="flex justify-content-end flex-wrap fontContadorCaracter">{{caracteresRestantesLembrete}}/350</div>
        </div>

        <div *ngIf="!loading" [style]="{width: '100%'}">
            <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                   'border-radius': '11px'}" (click)="criaNotLembrete()"></button>
        </div>
        <div *ngIf="loading" [style]="{width: '100%'}">
            <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 mt-3"
                [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                   'border-radius': '11px'}"></button>
        </div>
    </p-dialog>

</div>