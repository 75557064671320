<p-toast position="bottom-center" key="bc"></p-toast>
<p-toast position="top-right" key="tr"></p-toast>
<div class="pt-7">


    <div class="flex flex-column  pb-3">
        <div class="flex align-items-center justify-content-center">
            <img src="./assets/iconeBarbearia.svg" class="iconeCadastrarEstabelecimento" />
        </div>
        <div class=" flex align-items-center justify-content-center">
            <span
                class=" tituloNegritoEstabelecimento maximoCaracterEstabelecimento white-space-nowrap overflow-hidden text-overflow-ellipsis">
                {{estabelecimentoTitulo}}</span>
        </div>

    </div>


    <div class="flex justify-content-center flex-wrap ">


        <div class="xl:col-5 lg:col-5 md:col-5 sm:col-5">
            <div class="shadow-4">


                <p-card header="Dados da Barbearia" id="cardEstabelecimento">
                    <div>
                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            (click)="mostraDialogNome()">
                            <div class="flex gap-2 ">
                                <img src="./assets/imgBarbeariaCadastro.svg" class=" xl:w-3rem" />
                                <div class="flex flex-column align-items-start">
                                    <div class=" fontTituloEstabelecimento">
                                        <span class="xl:text-xl">Nome</span>
                                    </div>
                                    <div class="fontCinzaEstabelecimento ">
                                        <span class="xl:text-base">Será como seus clientes o encontrará</span>
                                    </div>
                                </div>
                            </div>
                            <div class="align-items-center justify-content-center   ">
                                <img src="./assets/imgBarbeariaPopup.png" class="iconePopUpEstabelecimento " />
                            </div>
                        </div>

                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            (click)="mostraDialogEndereco()">
                            <!-- <div class="col"> -->
                            <div class="flex gap-2 ">
                                <img src="./assets/imgLocalizacaoCadastro.svg" class=" xl:w-3rem" />
                                <div class="flex flex-column  align-items-start">
                                    <div class=" fontTituloEstabelecimento">
                                        <span class="xl:text-xl">Endereço </span>
                                    </div>
                                    <div class="fontCinzaEstabelecimento ">
                                        <span class="xl:text-base">Será como seus clientes o localizarão</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex align-items-center justify-content-center ">
                                <img src="./assets/imgBarbeariaPopup.png" class="iconePopUpEstabelecimento" />
                            </div>

                            <!-- </div> -->
                        </div>

                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            (click)="mostraDialogContato()">
                            <!-- <div class="col"> -->
                            <div class="flex gap-2 ">
                                <img src="./assets/imgContatoCadastro.svg" class=" xl:w-3rem" />
                                <div class="flex flex-column align-items-start">
                                    <div class="fontTituloEstabelecimento">
                                        <span class="xl:text-xl">Contato</span>
                                    </div>
                                    <div class="fontCinzaEstabelecimento ">
                                        <span class="xl:text-base">Serão os meios de seu cliente falar como
                                            você</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex align-items-center justify-content-end  ">
                                <img src="./assets/imgBarbeariaPopup.png" class="iconePopUpEstabelecimento" />
                            </div>

                            <!-- </div> -->
                        </div>

                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            (click)="mostraDialogImagens()">
                            <!-- <div class="col"> -->
                            <div class="flex gap-2 ">
                                <img src="./assets/imgCamera.svg" class="iconeCamera" class=" xl:w-3rem" />
                                <div class="flex flex-column align-items-start">
                                    <div class="fontTituloEstabelecimento">
                                        <span class="xl:text-xl">Foto de Capa</span>
                                    </div>
                                    <div class="fontCinzaEstabelecimento ">
                                        <span class="xl:text-base">Seus clientes visualizarão essa foto ao
                                            filiar-se</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex align-items-center justify-content-end ">
                                <img src="./assets/imgBarbeariaPopup.png" />
                            </div>

                            <!-- </div> -->
                        </div>


                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            (click)="mostraDialogRedeSocial()">
                            <!-- <div class="col"> -->
                            <div class="flex gap-2 ">
                                <img src="./assets/cadRedeSocial.svg" class="iconeCadastroBarbeiro"
                                    class=" xl:w-3rem" />
                                <div class="flex flex-column  align-items-start">
                                    <div class=" fontTituloEstabelecimento">
                                        <span class="xl:text-xl">Redes Sociais</span>
                                    </div>
                                    <div class="fontCinzaEstabelecimento ">
                                        <span class="xl:text-base">Redes sociais de sua estabelecimento </span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex align-items-center justify-content-end ">
                                <img src="./assets/imgBarbeariaPopup.png" class="iconePopUpEstabelecimento" />
                            </div>

                            <!-- </div> -->
                        </div>


                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            (click)="mostraDialogCadHash()">
                            <div class="flex gap-2 ">
                                <img src="./assets/imgCadHash.svg" class="iconeCadastroBarbeiro" class=" xl:w-3rem" />
                                <div class="flex flex-column  align-items-start">
                                    <div class=" fontTituloEstabelecimento">
                                        <span class="xl:text-xl">Identificação (agd. sem conta)</span>
                                    </div>
                                    <div class="fontCinzaEstabelecimento ">
                                        <span class="xl:text-base">Hash da identificação de agendamento sem conta</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex align-items-center justify-content-end">
                                <img src="./assets/imgBarbeariaPopup.png" class="iconePopUpEstabelecimento" />
                            </div>
                        </div>

                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            (click)="mostraDialogQRWpp()">
                            <div class="flex gap-2 ">
                                <img src="./assets/imgLerQR.svg" class="iconeCadastroBarbeiro" class=" xl:w-3rem" />
                                <div class="flex flex-column  align-items-start">
                                    <div class=" fontTituloEstabelecimento">
                                        <span class="xl:text-xl">QR code</span>
                                    </div>
                                    <div class="fontCinzaEstabelecimento ">
                                        <span class="xl:text-base">Leia o QR code para conectar seu WhatsApp</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex align-items-center justify-content-end">
                                <img src="./assets/imgBarbeariaPopup.png" class="iconePopUpEstabelecimento" />
                            </div>
                        </div>

                        <div class="formgroup-inline justify-content-between flex-wrap"
                            (click)="mostraDialogAtivarDesativarEstabelecimento()">
                            <!-- <div class="col"> -->
                            <div class="flex gap-2 ">
                                <img src="./assets/imgLigarCadastro.svg" class="iconeCadastroBarbeiro"
                                    class=" xl:w-3rem" />
                                <div class="flex flex-column  align-items-start">
                                    <div class=" fontTituloEstabelecimento">
                                        <span class="xl:text-xl">Ativar/Desativar </span>
                                    </div>
                                    <div class="fontCinzaEstabelecimento ">
                                        <span class="xl:text-base">Tornar estabelecimento ativo para executar
                                            serviços</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex align-items-center justify-content-end">
                                <img src="./assets/imgBarbeariaPopup.png" class="iconePopUpEstabelecimento" />
                            </div>

                            <!-- </div> -->
                        </div>
                    </div>
                </p-card>
            </div>
        </div>
    </div>
    <br>

    <!--                          DIALOG DO NOME               -->
    <p-dialog header="Nome da Estabelecimento" [(visible)]="showNome" [modal]="true"
        [style]="{'width': '350px', 'height': '220px'}" [closable]="true" id="dialogEstabelecimento">
        <div class="flex flex-column   align-items-center">
            <div class="flex justify-content-center flex-wrap col">
                <div class="fontDialogEstabelecimento flex align-items-center justify-content-center ">
                    <span>Informe o nome que seus clientes te identificarão</span>
                </div>
            </div>
            <input type="text" [(ngModel)]="nomeEstabelecimento" pInputText placeholder="Nome da Estabelecimento"
                [style]="{width: '100%'}" class="mt-2">

            <div *ngIf="!loading" [style]="{width: '100%'}">
                <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                'border-radius': '11px'}" (click)="salvarNome()"></button>
            </div>
            <div *ngIf="loading" [style]="{width: '100%'}">
                <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 mt-3"
                    [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                'border-radius': '11px'}"></button>
            </div>

        </div>
    </p-dialog>

    <!--                          DIALOG DO ENDEREÇO               -->
    <p-dialog header="Endereço da Estabelecimento" [(visible)]="showEndereco" [modal]="true"
        [style]="{'width': '350px', 'height': '430px'}" id="dialogEstabelecimento">

        <div class="flex justify-content-center flex-wrap col">

            <div class="fontDialogEstabelecimento flex align-items-center justify-content-center ">
                <span>Informe o endereço de sua Estabelecimento</span>
            </div>
        </div>
        <!-- <input type="text" pInputText placeholder="CEP" [style]="{width: '100%'}"> -->

        <p-inputMask mask="99999-999" [style]="{'width':'100%'}" placeholder="CEP" (onComplete)="buscaCep(cep)"
            [(ngModel)]="cep"></p-inputMask>
        <!-- <form [formGroup]="form"> -->
        <input type="text" pInputText placeholder="Logradouro" [(ngModel)]="logradouro" [style]="{width: '100%'}"
            class="mt-1 mb-1">

        <div class="grid p-fluid col">
            <div class="w-5 ">
                <input type="text" pInputText placeholder="Número" [(ngModel)]="numero" [style]="{width: '100%'}">
            </div>
            <div class="w-7 ">
                <input type="text" pInputText placeholder="Complemento" [(ngModel)]="complemento"
                    [style]="{width: '100%'}">
            </div>
        </div>

        <input type="text" pInputText placeholder="Bairro" [(ngModel)]="bairro" [style]="{width: '100%'}">



        <div class="grid p-fluid col">
            <div class="w-7 ">
                <input type="text" pInputText placeholder="Cidade" [(ngModel)]="cidade" [style]="{width: '100%'}"
                    class="mt-1">
            </div>

            <div class="w-5 ">
                <p-dropdown [options]="ufs" placeholder="UF" optionLabel="sigla" optionValue="sigla"
                    [(ngModel)]="ufSelecionado" [style]="{'width': '100%'}" id="drop" scrollHeight="80px">
                </p-dropdown>
            </div>

        </div>

        <div *ngIf="!loading" [style]="{width: '100%'}">
            <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
            'border-radius': '11px'}" (click)="salvarEnd()"></button>
        </div>
        <div *ngIf="loading" [style]="{width: '100%'}">
            <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 mt-3"
                [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
            'border-radius': '11px'}"></button>
        </div>


    </p-dialog>

    <!--                          DIALOG DO CONTATO               -->
    <p-dialog header="Contado da Estabelecimento" [(visible)]="showContato" [modal]="true"
        [style]="{'width': '350px', 'height': '270px'}" id="dialogEstabelecimento">
        <div class="flex justify-content-center flex-wrap col">
            <div class="fontDialogEstabelecimento flex align-items-center justify-content-center ">
                <span>Informe o contato de sua Estabelecimento</span>
            </div>
        </div>
        <p-inputMask mask="(99) 99999-9999" [(ngModel)]="contato" placeholder="Telefone" [style]="{width: '100%'}">
        </p-inputMask>
        <!-- <div class="mt-1">
            <p-inputMask mask="(99) 99999-9999" placeholder="Telefone 2 (Opcional)" [style]="{width: '100%'}">
            </p-inputMask>
        </div> -->
        <input type="text" pInputText placeholder="Email" [style]="{width: '100%'}" [(ngModel)]="email" class="mt-1">


        <div *ngIf="!loading" [style]="{width: '100%'}">
            <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
            'border-radius': '11px'}" (click)="salvarContato()"></button>
        </div>
        <div *ngIf="loading" [style]="{width: '100%'}">
            <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 mt-3"
                [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
            'border-radius': '11px'}"></button>
        </div>

    </p-dialog>


    <!--                          DIALOG DO CARREGANDO               -->
    <p-dialog id="dialogCarregandoEstabelecimento" [(visible)]="showCarregando" [modal]="true"
        [style]="{'width': '150px', 'height': '140px'}" [closable]="false">
        <div class="flex flex-column   align-items-center mt-2">
            <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
                fill="var(--surface-ground)" animationDuration="1s"></p-progressSpinner>
            <div class="fontCarregando">
                <span>Carregando...</span>
            </div>
        </div>
    </p-dialog>



    <!--                          DIALOG DAS IMAGENS              -->
    <p-dialog header="Imagens da Estabelecimento" [(visible)]="showImagens" [modal]="true"
        [style]="{'width': '350px', 'height': '220px'}" [closable]="true" id="dialogEstabelecimento">
        <div class="flex flex-column  align-items-center">
            <div class="flex justify-content-center flex-wrap col">
                <div class="fontDialogEstabelecimento flex align-items-center justify-content-center ">
                    <span>Informe as imagens de sua estabelecimento.</span>
                </div>
            </div>

            <input #fileInput accept="image/png, image/gif, image/jpeg" type="file" id="botaoImg"
                (change)="mudaNomeFoto()" />

            <div class="mt-2 ">
                <div class="flex align-items-center justify-content-start labelImagem">
                    <label for="">
                        <img src="./assets/uploadImgs.png" class="ajustaInputImagem pl-2" />
                    </label>
                    <div class="fontUploadImagem flex align-items-center justify-content-center pl-2 ">
                        <label for="botaoImg" class=""> {{nomeImagem}}</label>
                    </div>
                </div>
            </div>



            <div *ngIf="!loading" [style]="{width: '100%'}">
                <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
            'border-radius': '11px'}" (click)="onFileUpload()"></button>
            </div>
            <div *ngIf="loading" [style]="{width: '100%'}">
                <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 mt-3"
                    [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
            'border-radius': '11px'}"></button>
            </div>
        </div>
    </p-dialog>

    <!--                          DIALOG DAS REDES SOCIAIS               -->
    <p-dialog header="Redes Sociais" [(visible)]="showRedeSocial" [modal]="true"
        [style]="{'width': '327px', 'height': '350px'}" [closable]="true" id="dialogRedeSocial">
        <div class="flex flex-column   align-items-center">
            <div class="flex justify-content-center flex-wrap col">
                <div class="fontDialogEstabelecimento flex align-items-center justify-content-center ">
                    <span>Informe o link das redes sociais que seus clientes te encontrarão</span>
                </div>
            </div>
            <input type="text" [(ngModel)]="linkFacebook" pInputText placeholder="Link do Facebook"
                [style]="{width: '100%'}" class="mt-1">

            <input type="text" [(ngModel)]="linkTiktok" pInputText placeholder="Link do TikTok"
                [style]="{width: '100%'}" class="mt-1">

            <input type="text" [(ngModel)]="linkInstagram" pInputText placeholder="Link do Instagram"
                [style]="{width: '100%'}" class="mt-1">

            <div *ngIf="!loading" [style]="{width: '100%'}">
                <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
           'border-radius': '11px'}" (click)="salvaRedeSocial()"></button>
            </div>
            <div *ngIf="loading" [style]="{width: '100%'}">
                <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 mt-3"
                    [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
           'border-radius': '11px'}"></button>
            </div>

        </div>
    </p-dialog>






    <!--                          DIALOG ATIVA/DESATIVA             -->

    <p-dialog header="Ativar/ Desativar Estabelecimento" [(visible)]="showAtivarDesativarEstabelecimento" [modal]="true"
        [style]="{'width': '350px', 'height': '240px'}" id="dialogAtvDst">
        <div class="flex justify-content-center flex-wrap col">
            <div class="fontDialogEstabelecimento flex align-items-center justify-content-center">
                <span>Se ativo, a estabelecimento estará disponível como opção para realizar serviços</span>
            </div>
        </div>
        <!-- <div class="fontDialogAtvObsEstabelecimento flex align-items-center justify-content-center pb-4 pt-2">
        <span>Obs: Para ativar o barbeiro, é necessário que ele tenha conta!</span>
    </div> -->

        <div class="p-field-radiobutton pb-1">
            <p-radioButton value="S" [(ngModel)]="valorSelecionadoEstabelecimento"></p-radioButton>
            <label class="pl-2">Ativar estabelecimento</label>
        </div>
        <div class="p-field-radiobutton pb-1">
            <p-radioButton value="N" [(ngModel)]="valorSelecionadoEstabelecimento"></p-radioButton>
            <label class="pl-2">Desativar estabelecimento</label>
        </div>




        <div *ngIf="!loading" [style]="{width: '100%'}">
            <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
        'border-radius': '11px'}" (click)="ativaDesativaEstabelecimento()"></button>
        </div>
        <div *ngIf="loading" [style]="{width: '100%'}">
            <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 mt-3"
                [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
        'border-radius': '11px'}"></button>
        </div>
    </p-dialog>

    <!--                          DIALOG DA HASH               -->
    <p-dialog header="Hash da Estabelecimento" [(visible)]="showCadHash" [modal]="true"
        [style]="{'width': '350px', 'height': '230px'}" [closable]="true" id="dialogEstabelecimento">
        <div class="flex flex-column   align-items-center">
            <div class="flex justify-content-center flex-wrap col">
                <div
                    class="fontDialogEstabelecimento flex align-items-center justify-content-center white-space-nowrap">
                    <span>Informe a hash para agendamento de seus clientes</span>
                </div>
            </div>
            <input type="text" [(ngModel)]="hashEstabelecimento" pInputText placeholder="Hash da Estabelecimento"
                [style]="{width: '100%'}" class="mt-2">

            <div *ngIf="!loading" [style]="{width: '100%'}">
                <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                'border-radius': '11px'}" (click)="salvarHash()"></button>
            </div>
            <div *ngIf="loading" [style]="{width: '100%'}">
                <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 mt-3"
                    [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                'border-radius': '11px'}"></button>
            </div>

        </div>
    </p-dialog>



    <!--                          DIALOG DO QR               -->
    <!-- <p-dialog header="QR Code do Estabelecimento" [(visible)]="showQrWpp" [modal]="true"
        [style]="{'width': '350px', 'height': '230px'}" [closable]="true" id="dialogEstabelecimento"> -->
    <p-dialog header="QR Code WhatsApp" [(visible)]="showQrWpp" [modal]="true" [closable]="true"
        id="dialogEstabelecimento">
        <div class="flex flex-column   align-items-center">
            <div class="flex justify-content-center flex-wrap col">
                <div
                    class="fontDialogEstabelecimento flex align-items-center justify-content-center white-space-nowrap">
                    <span>Leia o QRCode para se conectar ao WhatsApp</span>
                </div>
            </div>


            <div class="flex flex-column align-items-center mt-2" *ngIf="mostraCarregando">
                <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
                    fill="var(--surface-ground)" animationDuration="1s"></p-progressSpinner>
                <div class="fontCarregando">
                    <span>Processando QR Code...</span>
                </div>
            </div>

            <div *ngIf="!mostraCarregando">

                <img [src]="imgQR" />
            </div>

        </div>
    </p-dialog>


    <!--                          DIALOG DO CARREGANDO               -->
    <!-- <p-dialog id="dialogCarregandoEstabelecimento" [(visible)]="mostraCarregando" [modal]="true"
        [style]="{'width': '150px', 'height': '140px'}" [closable]="false">
        <div class="flex flex-column align-items-center mt-2">
            <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
                fill="var(--surface-ground)" animationDuration="1s"></p-progressSpinner>
            <div class="fontCarregando">
                <span>Carregando...</span>
            </div>
        </div>
    </p-dialog> -->
</div>