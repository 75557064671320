import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MenuItem, PrimeNGConfig } from 'primeng/api';
import { Estabelecimento } from 'src/assets/estabelecimento';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { EstabelecimentoService } from 'src/estabelecimento.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { PermissoesService } from 'src/permissoes.service';



@Component({
  selector: 'app-cadastrar-estabelecimento',
  templateUrl: './cadastrar-estabelecimento.component.html',
  styleUrls: ['./cadastrar-estabelecimento.component.scss'],
  providers: [ConfirmationService, MessageService],
  encapsulation: ViewEncapsulation.None
})
export class CadastrarEstabelecimentoComponent implements OnInit {

  /* variaveis do formulário */
  estabelecimento: Estabelecimento[] = [];
  itemsEstabelecimentos: MenuItem[] = [];

  /* variaveis do componente */
  codusuresp: string = '';
  codcli: number = 0;
  estabelecimentoSelec: Estabelecimento = {};
  codemp: string = '';

  /**VARIAVEIS DE PERMISSÕES */
  permissao: any;
  alterar: boolean = false;
  inativar: boolean = false;
  cadastrar: boolean = false

  constructor(private primengConfig: PrimeNGConfig, private estabelecimentoService: EstabelecimentoService, private decodedTokenService: DecodedTokenService,
    private confirmationService: ConfirmationService, private router: Router, private permissoesService: PermissoesService, private messageService: MessageService,) {

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {

        console.log(Object(event).url);
      })
  }

  ngOnInit(): void {
    this.recuperaCodUsu();
    this.recuperaCodCli();
    this.recuperaPermissao();
    this.buscarEstabelecimentosPorCodCliente();

    this.itemsEstabelecimentos = [{
      items: [{
        label: 'Detalhes',
        command: () => {
          this.editarEstabelecimento(this.estabelecimentoSelec)
        },
        // routerLink: ['/estabelecimento'],
      }
      ]
    },
    {
      items: [{
        label: 'Excluir',
        styleClass: 'linhaMenu',
        command: () => {
          console.log(this.estabelecimentoSelec.codemp);
          this.confirmaExclusaoEstabelecimento();
        }
      }
      ]
    }
    ];
  }

  recuperaCodCli() {
    this.codcli = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODCLI;
  }

  recuperaCodUsu() {
    this.codusuresp = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }

  buscarEstabelecimentosPorCodCliente() {
    this.estabelecimento = [];
    if (this.permissao != undefined && this.permissao.length > 0) {
      this.estabelecimentoService.buscarEstabelecimentosporCodCli(this.permissao[0].codcli, this.codusuresp).subscribe((res) => {
        console.log(res);
        this.estabelecimento = res;
      });
    } else {
      this.estabelecimentoService.buscarEstabelecimentosporCodCli(this.codcli, this.codusuresp).subscribe((res) => {
        console.log(res);
        this.estabelecimento = res;
      });
    }
  }


  excluiEstabelecimentoPorCodEmp() {
    this.estabelecimentoService.excluiEmpresaPorCodEmp(this.estabelecimentoSelec.codemp!, this.codusuresp).subscribe((res) => {
      console.log(res);
      this.estabelecimento = this.estabelecimento.filter((x: any) => x.codemp != res.result); //FILTRA TUDO O QUE É DIFERENTE DO QUE FOI EXCLUIDO
      console.log(this.estabelecimento)
    });
  }

  estabelecimentoSelecionada(estabelecimento: Estabelecimento) {
    console.log('estou no botão')
    this.estabelecimentoSelec = estabelecimento;
    console.log(estabelecimento)
  }


  editarEstabelecimento(estabelecimento: Estabelecimento) {
    /**VERIFICA SEM TEM PERMISSÃO PARA ESSA AÇÃO */
    if (this.alterar || this.codcli > 0) {
      this.estabelecimentoService.setestabelecimento(estabelecimento);
      this.router.navigate([('/estabelecimento')]);
    }
    else {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
  }

  cadastrarEstabelecimento() {
    console.log(this.cadastrar, this.codcli)

    /**VERIFICA SEM TEM PERMISSÃO PARA ESSA AÇÃO */
    if (this.cadastrar || this.codcli > 0) {
      this.router.navigate([('/estabelecimento')]);
      var auxEstabelecimento: Estabelecimento = {}
      this.estabelecimentoService.setestabelecimento(auxEstabelecimento);
    }
    else {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
  }

  confirmaExclusaoEstabelecimento() {

    /**VERIFICA SEM TEM PERMISSÃO PARA ESSA AÇÃO */
    if (this.inativar || this.codcli > 0) {
      this.confirmationService.confirm({
        message: 'Deseja mesmo excluir ' + this.estabelecimentoSelec.nome + ' ?',
        header: 'Excluir Estabelecimento',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.excluiEstabelecimentoPorCodEmp();
        },
        reject: () => {
        }
      });
    }
    else {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
  }

  /**RECUPERA AS PERMISSÕES */
  recuperaPermissao() {
    this.permissao = this.permissoesService.getPermissao();
    console.log(this.permissao)
    if (this.permissao != undefined && this.permissao.length > 0) {
      if (this.permissao[0].acao[0].PERMITE === 'S') {
        this.cadastrar = true;
      }
      if (this.permissao[0].acao[1].PERMITE === 'S') {
        this.alterar = true;
      }
      if (this.permissao[0].acao[2].PERMITE === 'S') {
        this.inativar = true;
      }
    }
  }


  /**METODO DE TOAST */
  showError(titulo: string, mensagem: string) {
    if (screen.width < 640 || screen.height < 480) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else if (screen.width < 1024 || screen.height < 768) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else {
      this.messageService.add({
        key: 'tr',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    }
  }
}
