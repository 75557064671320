<div class="pt-7">


    <div class="flex flex-column  pb-3">
        <div class="flex align-items-center justify-content-center">
            <img src="./assets/iconeAcessos.svg" />
        </div>
        <div class=" flex align-items-center justify-content-center">
            <span class=" tituloNegritoAcessos">Acessos</span>
        </div>
    </div>

    <!-- <p-dialog header="Acessos" [(visible)]="showAcessos" [modal]="true" [draggable]="false"
        [breakpoints]="{'960px': '534px', '640px': '360px'}" [closable]="true" id="dialogAcessos"> -->

    <div class="flex justify-content-center flex-wrap ">


        <div class="xl:col-5 lg:col-5 md:col-5 sm:col-5">
            <div class="shadow-4">


                <p-card header="Financeiro" id="cardAcessos">
                    <div class="flex justify-content-center flex-wrap col">
                        <div
                            class="fontDialogAcessos flex align-items-center justify-content-center white-space-nowrap">
                            <span>Defina os acessos que seus profissionais poderão ter no painel barber</span>
                        </div>
                    </div>

                    <p-table [value]="permissao" sortField="apelido" sortMode="single" [scrollable]="true"
                        scrollHeight="400px" rowGroupMode="subheader" groupRowsBy="apelido">

                        <ng-template pTemplate="header">
                            <tr style="height: 30px;">
                                <th class="fontTituloTabela white-space-nowrap" style="width:215px; flex: 0 0 190px">
                                    Telas por
                                    Profissional</th>
                                <th class="fontTituloTabela white-space-nowrap" style="flex: 0 0 187px;">Permitir
                                    acesso?</th>
                                <th class="fontTituloTabela" style="min-width:1px"> <img src="./assets/iconeMenuAdm.png"
                                        (click)="mostraDialogAcaoMassa()"></th>
                            </tr>
                        </ng-template>


                        <ng-template pTemplate="groupheader" let-permissao>
                            <tr pRowGroupHeader style="background-color: var(--cor8); height: 35px;">
                                <td style="flex: 0 0 10px;">
                                    <p-checkbox [value]="permissao.apelido" [(ngModel)]="selecionouProfissional"
                                        (click)="clicouCheckProfissional()"></p-checkbox>
                                </td>
                                <td style="flex: 0 0 100px;">
                                    <div>
                                        <span class="fontNomeProfissional">{{permissao.apelido}}</span>
                                    </div>
                                </td>
                                <!-- <td></td>
                    <td></td> -->
                            </tr>
                        </ng-template>


                        <ng-template pTemplate="body" let-permissao>
                            <tr style="height: 35px;">
                                <td style="flex: 0 0 30px;">
                                    <p-checkbox [value]="permissao" [(ngModel)]="selecionouTela"></p-checkbox>
                                </td>
                                <td class="fontConteudoTabela" style="flex: 0 0 180px;">{{permissao.descr}}</td>
                                <td style="flex: 0 0 116px;">
                                    <p-inputSwitch [(ngModel)]="permissao.habilitaPermissao"
                                        (click)="verificaHabilitaTela(permissao)"></p-inputSwitch>
                                </td>
                                <td>

                                </td>
                                <!-- <td>
                        <div *ngIf="permissao.habilita==='S'">
                            <p-inputNumber [(ngModel)]="permissao.pont" id="descontoServicoSelec" [min]="0"
                                placeholder="Pontos"> </p-inputNumber>
                        </div>
                    </td> -->
                            </tr>

                            <tr *ngIf="permissao.habilitaPermissao === true">
                                <td style="flex: 0 0 120px;">

                                </td>
                                <div class="flex flex-column align-items-center justify-content-center">
                                    <td style="flex: 0 0 100px;">
                                        <div *ngFor="let acao of permissao.acao">
                                            <div class="formgroup-inline justify-content-around gap-4 pt-2">
                                                <div class="field-checkbox gap-2">
                                                    <p-checkbox [value]="acao"
                                                        [(ngModel)]="selecionouAcao"></p-checkbox>
                                                    <span class="fontConteudoTabela">{{acao.DESCR}}</span>
                                                </div>
                                                <p-inputSwitch [(ngModel)]="acao.habilitaAcao"
                                                    (click)="verificaHabilitaAcao(acao)"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </td>
                                </div>
                                <td></td>
                            </tr>
                        </ng-template>
                    </p-table>


                    <div *ngIf="!loading" [style]="{width: '100%'}" class="pt-3">
                        <button pButton type="button" label="Atualizar" class="col-12 " [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
        'border-radius': '11px'}" (click)="atualizaPermissao()"></button>
                    </div>
                    <div *ngIf="loading" [style]="{width: '100%'}" class="pt-3">
                        <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando"
                            class="col-12 " [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
        'border-radius': '11px'}"></button>
                    </div>
                    <!-- </p-dialog> -->

                </p-card>
            </div>
        </div>

    </div>

<br>

    <p-dialog header="Atualizar pontos em massa" [(visible)]="showAcaoMassa" [modal]="true" [draggable]="false"
        [style]="{'width': '350px', 'height': '200px'}" [closable]="true" id="dialogAcaoMassa">
        <div class="flex justify-content-center flex-wrap col">
            <div class="fontDialogAcessos flex align-items-center justify-content-center">
                <span>Informação de ação em massa</span>
            </div>
        </div>
        <div class="mt-2">
            <div class=" flex align-items-center justify-content-center">
                <p-inputSwitch [(ngModel)]="habAcao"></p-inputSwitch>
            </div>
        </div>

        <button pButton type="button" label="Preencher" class="col-12 mt-2" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
        'border-radius': '11px'}" (click)="preencheEmMassa()"></button>

    </p-dialog>


</div>