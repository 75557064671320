<div class="pt-8">
    <div class="">
        <div class="">
            <div class="flex justify-content-center flex-wrap  ">
                <img [src]="imagemEstabelecimento" class="imgRedonda   " />
            </div>
            <div class=" flex justify-content-center flex-wrap">

                <div class=" xl:col-5 lg:col-5 md:col-5 sm:col-5">
                    <div class="shadow-4">


                        <p-card id="cardAdm1">
                            <div class="flex flex-column pt-7">
                                <div
                                    class="fontNomeEstabelecimento flex justify-content-center align-items-center flex-wrap pb-1">
                                    <span class="xl:text-4xl"> {{exibeNome}}</span>
                                    <img src="./assets/iconeDropDown.png" class="iconeDropdownMenu"
                                        (click)="menu.toggle($event)" />
                                    <p-menu #menu [popup]="true" [model]="itemsAdmin" styleClass="menuAdmin"></p-menu>
                                </div>
                                <div class="fontApresentacao flex justify-content-center flex-wrap pb-1">
                                    <span class="xl:text-2xl">{{nome}}</span>
                                </div>
                                <div class="flex justify-content-center flex-wrap">
                                    <div class="linhahorizontal "></div>
                                </div>
                                <div class="fontApresentacao flex justify-content-center flex-wrap pt-1">
                                    <span class="">Serviços do Mês</span>
                                </div>
                                <div class="fontNomeEstabelecimento flex justify-content-center flex-wrap ">
                                    <div class="flex align-items-center justify-content-center ">
                                        <input id="faturamento" [(ngModel)]="mostraFaturamento" readonly pInputText />
                                    </div>
                                </div>

                                <div class="pt-2 flex justify-content-center flex-wrap ">
                                    <div class="flex align-items-center justify-content-center gap-1">
                                        <div *ngIf="show">
                                            <p-button id="botaoShow" (click)="clickShow()"> </p-button>
                                        </div>
                                        <div *ngIf="!show">
                                            <p-button id="botaoHide" (click)="clickShow()"> </p-button>
                                        </div>
                                        <p-button label="Métricas" id="botaoMetricas" routerLink="/relatorios">
                                        </p-button>
                                    </div>
                                </div>

                            </div>

                        </p-card>
                    </div>
                </div>
            </div>

            <!-- <footer class=""> -->
            <div class="flex justify-content-center flex-wrap pt-4">
                <div class="xl:col-5 lg:col-5 md:col-5 sm:col-5">
                    <div class="shadow-4">
                        <p-card id="cardAdm2">
                            <div class="flex align-content-evenly  flex-wrap column-gap-3 xl:gap-5">
                                <div class="flex flex-column  cursor-pointer">
                                    <img src="./assets/barbeariaMenuAdm.svg" routerLink="/cadastrarEstabelecimento"
                                        class="iconeMenuAdm " />
                                    <div class="fontMarronIcones flex align-items-center justify-content-center">
                                        <span class="">Barbearia</span>
                                    </div>
                                </div>
                                <div class="flex flex-column  cursor-pointer">
                                    <img src="./assets/barbeirosAdm.svg" routerLink="/cadastrarProfissionais"
                                        class="iconeMenuAdm " />
                                    <div class="fontMarronIcones flex align-items-center justify-content-center">
                                        <span class=" ">Profissionais</span>
                                    </div>
                                </div>
                                <div class="flex flex-column cursor-pointer">
                                    <img src="./assets/servicosAdm.svg" routerLink="/cadastrarServicos"
                                        class="iconeMenuAdm " />
                                    <div class="fontMarronIcones flex align-items-center justify-content-center">
                                        <span class="">Serviços</span>
                                    </div>
                                </div>
                                <div class="flex flex-column cursor-pointer">
                                    <img src="./assets/produtosAdm.svg" routerLink="/cadastrarProdutos"
                                        class="iconeMenuAdm " />
                                    <div class="fontMarronIcones flex align-items-center justify-content-center">
                                        <span class="">Produtos</span>
                                    </div>
                                </div>
                                <div class="flex flex-column pt cursor-pointer">
                                    <img src="./assets/vendaAdm.svg" routerLink="/pedidos" class="iconeMenuAdm " />
                                    <div class="fontMarronIcones flex align-items-center justify-content-center">
                                        <span class="">Venda</span>
                                    </div>
                                </div>
                                <div class="flex flex-column pt cursor-pointer">
                                    <img src="./assets/horariosAdm.svg" routerLink="/cadastrarHorarios"
                                        class="iconeMenuAdm " />
                                    <div class="fontMarronIcones flex align-items-center justify-content-center">
                                        <span class="">Horários</span>
                                    </div>
                                </div>


                                <div class="flex flex-column pt cursor-pointer">
                                    <img src="./assets/clientesAdm.svg" routerLink="/clientesFiliados"
                                        class="iconeMenuAdm " />
                                    <div class="fontMarronIcones flex align-items-center justify-content-center">
                                        <span class="">Clientes</span>
                                    </div>
                                </div>

                                <div class="flex flex-column pt cursor-pointer">
                                    <img src="./assets/Informativos.svg" class="iconeMenuAdm "
                                        routerLink="/cadastrarInformativos" />
                                    <div class="fontMarronIcones flex align-items-center justify-content-center">
                                        <span>Informativos</span>
                                    </div>
                                </div>

                                <div class="flex flex-column pt cursor-pointer">
                                    <img src="./assets/pesquisasAdm.svg" class="iconeMenuAdm "
                                        routerLink="/cadastrarPesquisa" />
                                    <div class="fontMarronIcones flex align-items-center justify-content-center">
                                        <span>Pesquisas</span>
                                    </div>
                                </div>

                                <div class="flex flex-column pt cursor-pointer">
                                    <img src="./assets/notificacoesAdm.svg" class="iconeMenuAdm "
                                        routerLink="/notificacoes" />
                                    <div class="fontMarronIcones flex align-items-center justify-content-center">
                                        <span>Notificações</span>
                                    </div>
                                </div>

                                <div class="flex flex-column pt cursor-pointer">
                                    <img src="./assets/iconeComissao.svg" class="iconeMenuAdm "
                                        routerLink="/cadastrarComissao" />
                                    <div class="fontMarronIcones flex align-items-center justify-content-center">
                                        <span>Comissão</span>
                                    </div>
                                </div>


                                <div class="flex flex-column pt cursor-pointer">
                                    <img src="./assets/descontosAdm.svg" class="iconeMenuAdm "
                                        routerLink="/descontos" />
                                    <div class="fontMarronIcones flex align-items-center justify-content-center">
                                        <span>Descontos</span>
                                    </div>
                                </div>


                                <div class="flex flex-column pt cursor-pointer">
                                    <img src="./assets/acessosAdm.svg" class="iconeMenuAdm " routerLink="/acessos" />
                                    <div class="fontMarronIcones flex align-items-center justify-content-center">
                                        <span>Acessos</span>
                                    </div>
                                </div>

                                <div class="flex flex-column pt cursor-pointer">
                                    <img src="./assets/financeiroAdm.svg" class="iconeMenuAdm "
                                        routerLink="/financeiro" />
                                    <div class="fontMarronIcones flex align-items-center justify-content-center">
                                        <span>Financeiro</span>
                                    </div>
                                </div>

                                <div class="flex flex-column pt cursor-pointer" *ngIf="escondePlanos">
                                    <img src="./assets/planosAdm.svg" class="iconeMenuAdm "
                                        routerLink="/cadastrarPlano" />
                                    <div class="fontMarronIcones flex align-items-center justify-content-center">
                                        <span>Planos</span>
                                    </div>
                                </div>

                                <div class="flex flex-column pt cursor-pointer">
                                    <img src="./assets/configFinanceiraAdm.svg" class="iconeMenuAdm "
                                        routerLink="/configuracaoFinanceira" />
                                    <div class="fontMarronIcones flex align-items-center justify-content-center">
                                        <span>Config. Fin.</span>
                                    </div>
                                </div>

                            </div>
                        </p-card>
                    </div>
                </div>
            </div>
            <br>
        </div>
    </div>
</div>
<!--                          DIALOG DO CARREGANDO               -->
<p-dialog id="dialogCarregandoMAdm" [(visible)]="mostraCarregando" [modal]="true"
    [style]="{'width': '150px', 'height': '140px'}" [closable]="false">
    <div class="flex flex-column align-items-center mt-2">
        <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
            fill="var(--surface-ground)" animationDuration="1s"></p-progressSpinner>
        <div class="fontCarregando">
            <span>Carregando...</span>
        </div>
    </div>
</p-dialog>