import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { Produto } from 'src/assets/produto';
import { PermissoesService } from 'src/permissoes.service';
import { ProdutoService } from 'src/produto.service';
import { ServicoService } from 'src/servico.service';

@Component({
  selector: 'app-cadastrar-produtos',
  templateUrl: './cadastrar-produtos.component.html',
  styleUrls: ['./cadastrar-produtos.component.scss'],
  providers: [ConfirmationService, MessageService],
  encapsulation: ViewEncapsulation.None
})
export class CadastrarProdutosComponent implements OnInit {

  itemsProduto: MenuItem[] = [];


  /* variaveis do componente */
  codcli: number = 0;
  produtoSelec: Produto = {};
  codusuresp: string = '';
  produto: Produto[] = [];

  /**VARIAVEIS DE PERMISSÕES */
  // permissao: any;
  // alterar: boolean = false;
  // excluir: boolean = false;
  // inativar: boolean = false;
  // cadastrar: boolean = false;



  constructor(private decodedTokenService: DecodedTokenService, private produtoService: ProdutoService, private confirmationService: ConfirmationService,
    private router: Router, private permissoesService: PermissoesService, private messageService: MessageService, private servicoService: ServicoService) { }

  ngOnInit(): void {

    this.recuperaCodUsu();
    this.recuperaCodCli();
    this.buscarProdutoPorCodCliente();


    this.itemsProduto = [{
      items: [{
        label: 'Detalhes',
        command: () => {
          this.editarProduto(this.produtoSelec)
        },
        // routerLink: ['/servico'],
      }
      ]
    },
    {
      items: [{
        label: 'Excluir',
        styleClass: 'linhaMenu',
        command: () => {
          this.confirmaExclusaoProduto()
        }
      }
      ]
    }
    ];
  }


  /**METODO QUE RECUPERA O CODUSU DO JWT */
  recuperaCodUsu() {
    this.codusuresp = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }

  /**METODO QUE RECUPERA O CODCLI DO JWT */
  recuperaCodCli() {
    this.codcli = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODCLI;
  }


  /**BUSCA OS PRODUTOS */
  buscarProdutoPorCodCliente() {
    /**VERIFICA SE VAI FAZER A BUSCA PELO CODCLI DO TOKEN OU DA PERMISSÃO */
    // if (this.permissao != undefined && this.permissao.length > 0) {
    //   this.servicoService.buscarServicoPorCodCli(this.permissao[0].codcli, this.codusuresp).subscribe((res) => {
    //     this.servico = res;
    //   });
    // }
    // else {
    this.produtoService.buscarProdutosPorCodCli(this.codcli, this.codusuresp).subscribe((res) => {
      this.produto = res;
    });
    // }
  }




  /**METODOS QUE SETA O PRODUTO ESCOLHIDO PARA SER MOSTRADO NA TELA SEGUINTE PARA EDITAR */
  produtoSelecionado(produto: Produto) {
    this.produtoSelec = produto;
  }

  /**SETA O PRODUTO PARA SER RECUPERADO E EDITADO NA PROXIMA TELA */
  editarProduto(produto: Produto) {
    /**VERIFICA SEM TEM PERMISSÃO PARA ESSA AÇÃO */
    //if ((this.alterar || this.inativar) || this.codcli > 0) {
    this.produtoService.setProduto(produto);
    this.router.navigate([('/produto')]);
    //} else {
    //   this.showError('Atenção', 'Ação não permitida.')
    //   return
    // }
  }



  /**SETA O PRODUTO COMO 'VAZIO' PARA SER CADASTRADO UM NOVO */
  cadastrarProduto() {
    /**VERIFICA SEM TEM PERMISSÃO PARA ESSA AÇÃO */
    // if (this.cadastrar || this.codcli > 0) {
    var auxProduto: Produto = {}
    this.produtoService.setProduto(auxProduto);
    this.router.navigate([('/produto')]);
    // } else {
    //   this.showError('Atenção', 'Ação não permitida.')
    //   return
    // }
  }



  excluiProdutoPorCodProd() {
    this.servicoService.excluiServicoPorCodProd(this.produtoSelec.codprod!, this.codusuresp).subscribe((res) => {
      this.produto = this.produto.filter((x: any) => x.codprod != res.codprod); //FILTRA TUDO O QUE É DIFERENTE DO QUE FOI EXCLUIDO
      console.log(this.produto)
    });
  }


  confirmaExclusaoProduto() {
    /**VERIFICA SEM TEM PERMISSÃO PARA ESSA AÇÃO */
    //if (this.excluir || this.codcli > 0) {
    this.confirmationService.confirm({
      message: 'Deseja mesmo excluir ' + this.produtoSelec.nomeprod + ' ?',
      header: 'Excluir Produto',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.excluiProdutoPorCodProd();
      },
      reject: () => {
      }
    });
    // } else {
    //   this.showError('Atenção', 'Ação não permitida.')
    //   return
    // }
  }


  /**METODO DE TOAST */
  showError(titulo: string, mensagem: string) {
    if (screen.width < 640 || screen.height < 480) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else if (screen.width < 1024 || screen.height < 768) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else {
      this.messageService.add({
        key: 'tr',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    }
  }

}
