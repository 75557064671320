import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AlertService } from 'src/alert.service';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { PermissoesService } from 'src/permissoes.service';

@Component({
  selector: 'app-acessos',
  templateUrl: './acessos.component.html',
  styleUrls: ['./acessos.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AcessosComponent implements OnInit {

  /**CODIGOS */
  codcli: number = 0;
  codUsu: string = '';

  showAcaoMassa: boolean = false;
  showAcessos: boolean = true;
  loading: boolean = false;

  selecionouProfissional: any;
  selecionouTela: any;
  selecionouAcao: any;

  telas: any;
  acao: any;

  permissao: any;

  /**AÇÃO EM MASSA */
  habAcao: boolean = false;


  /**VARIAVEIS DE PERMISSÕES */
  permissaoFunc: any;

  constructor(private decodedTokenService: DecodedTokenService, private permissoesService: PermissoesService, private alertService: AlertService) { }


  ngOnInit(): void {
    // console.log(this.telas)
    this.recuperaCodCli();
    this.recuperaPermissao();
    this.recuperaCodUsu();
    this.recuperaPermissoes();
  }

  /**METODO QUE RECUPERA O CODCLI PARA FAZER A BUSCA DE PERMISSOES */
  recuperaCodCli() {
    this.codcli = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODCLI;
  }

  recuperaCodUsu() {
    this.codUsu = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }

  /**MOSTRA O DIALOG DO DESCONTO EM SERVIÇO */
  mostraDialogAcaoMassa() {
    this.showAcaoMassa = true;
  }

  /**METODOS QUE FAZ ATRIBUIÇÃO DE TRUE OU FALSE PARA O inputswitch */
  verificaHabilitaTela(permissao: any) {
    console.log(permissao)
    if (permissao.habilitaPermissao === true) {
      permissao.habilita = 'S'
    } else {
      permissao.habilita = 'N'
    }
  }

  verificaHabilitaAcao(acao: any) {
    if (acao.habilitaAcao === true) {
      acao.PERMITE = 'S'
    } else {
      acao.PERMITE = 'N'
    }
  }

  /**METODO QUE RECUPERA AS PERMISSÕES PARA A TABELA */
  recuperaPermissoes() {
    this.permissoesService.bucaPermissaoPorCodCli(this.codcli, this.codUsu).subscribe((res) => {
      this.permissao = res;

      /**TRANSFORMA EM JSON O VETOR DE AÇÕES (ELE VEM COMO STRING DO BACKEND) */
      for (let i = 0; i < this.permissao.length; i++) {
        this.permissao[i].acao = JSON.parse(Object(this.permissao[i].acao));
        /**RETIRA OS ESPAÇOS EM BRANCO */
        this.permissao[i].habilita = this.permissao[i].habilita.replace(/\s/g, '');

        if (this.permissao[i].habilita === 'S') {
          this.permissao[i].habilitaPermissao = true;
        } else {
          this.permissao[i].habilitaPermissao = false;
        }

        for (let j = 0; j < this.permissao[i].acao.length; j++) {
          if (this.permissao[i].acao[j].PERMITE === 'S') {
            this.permissao[i].acao[j].habilitaAcao = true;
          } else {
            this.permissao[i].acao[j].habilitaAcao = false;
          }
        }




      }

      console.log(this.permissao)
    })
  }


  /**METODO QUE CONTROLA A SELEÇÃO DOS SERVIÇOS DO CHECKBOX CASO SEJA ESCOLHIDO O Profissional */
  clicouCheckProfissional() {
    this.selecionouTela = []
    for (let j = 0; j < this.selecionouProfissional.length; j++) {
      for (let i = 0; i < this.permissao.length; i++) {
        if (this.permissao[i].apelido === this.selecionouProfissional[j]) {
          this.selecionouTela.push(this.permissao[i]);
        }
      }
    }
    console.log(this.selecionouTela)
  }


  /**METODO QUE PREENCHE OS CAMPOS DE AÇÃO QUE FORAM SELECIONADOS PELO CHECKBOX */
  preencheEmMassa() {

    if (this.selecionouTela) {
      for (let i = 0; i < this.selecionouTela.length; i++) {
        //  console.log(this.selecionouTela[i])
        this.selecionouTela[i].habilitaPermissao = this.habAcao;
        console.log(this.selecionouTela[i].habilitaPermissao)
        if (this.selecionouTela[i].habilitaPermissao === true) {
          this.selecionouTela[i].habilita = 'S';
        } else {
          this.selecionouTela[i].habilita = 'N';
        }
      }
    }

    if (this.selecionouAcao) {
      console.log(this.habAcao)
      console.log(this.selecionouAcao)
      for (let i = 0; i < this.selecionouAcao.length; i++) {
        console.log('wqefwergfwggw')
        this.selecionouAcao[i].habilitaAcao = this.habAcao;
        console.log(this.selecionouAcao[i].habilitaAcao)
        if (this.selecionouAcao[i].habilitaAcao === true) {
          this.selecionouAcao[i].PERMITE = 'S';
        } else {
          this.selecionouAcao[i].PERMITE = 'N';
        }
      }
    }

    this.habAcao = false;
    this.selecionouTela = [];
    this.selecionouAcao = [];
    this.selecionouProfissional = [];
    this.showAcaoMassa = false;
  }




  /**METODO QUE ATUALIZA AS PERMISSÕES DO Profissional */
  atualizaPermissao() {
    this.loading = true;
    let countError = 0;

    for (let i = 0; i < this.permissao.length; i++) {
      /**PRECISA DE UM SEGUNDO FOR PARA O VETOR DE AÇÕES DA TELA */
      for (let j = 0; j < this.permissao[i].acao.length; j++) {
        console.log(this.permissao[i].nutel, this.permissao[i].acao[j].NUACO, this.permissao[i].codfunc, this.permissao[i].acao[j].PERMITE, this.codUsu)


        /**VERIFICA SE O SWITCH DA TELA ESTÁ ATIVO */
        if (this.permissao[i].habilita === 'S') {
          this.permissoesService.createPermissao(this.permissao[i].nutel, this.permissao[i].acao[j].NUACO, this.permissao[i].codfunc,
            this.permissao[i].acao[j].PERMITE, this.codUsu).subscribe((res) => {
              console.log(res)
            },
              (error) => {
                countError++;
                console.log(error)
                console.log('deu ruim')
                // this.alertService.alert('Algo deu errado.', 'error');
                //this.showError('Atenção', 'Algo deu errado.')
              });
        }
        /**SE O SWITCH NÃO ESTIVER ATIVO, TODAS AS AÇÕES SÃO NEGADAS */
        else {
          this.permissoesService.createPermissao(this.permissao[i].nutel, this.permissao[i].acao[j].NUACO, this.permissao[i].codfunc,
            'N', this.codUsu).subscribe((res) => {
              console.log(res)
            },
              (error) => {
                countError++;
                console.log(error)
                console.log('deu ruim')
                // this.alertService.alert('Algo deu errado.', 'error');
                //this.showError('Atenção', 'Algo deu errado.')
              });
        }


      }
      this.loading = false;
      this.showAcessos = false;
      if (countError > 0) {
        this.alertService.alert('Algo deu errado.', 'error');
      } else {
        this.alertService.alert('Ações atualizadas!', 'success');
      }
    }

  }


  /**RECUPERA AS PERMISSÕES */
  recuperaPermissao() {
    this.permissaoFunc = this.permissoesService.getPermissao();
    if (this.permissaoFunc != undefined && this.permissaoFunc.length > 0) {
      this.codcli = this.permissaoFunc[0].codcli;
    }
  }

}
