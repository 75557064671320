import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, Observable, tap } from 'rxjs';
import { Comissao } from './assets/comissao';
import { environment } from './environments/environment';
import { LoginService } from './login/login.service';

@Injectable({
  providedIn: 'root'
})
export class ComissaoService {

  comissao: Comissao = {};

  constructor(private http: HttpClient, private loginService: LoginService) { }


  buscaComissaoPorCodcli(codcli: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const cliente = { "codcli": codcli, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/comissao/getcomissoesbycodcli`, cliente, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  buscaAjusteComissaoPorNucom(nucom: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const comissao = { "nucom": nucom, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/comissao/getajustesbycomissao`, comissao, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  buscaServicoPorNucom(codfunc: number, dtini: any, dtfin: any, nucom: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const comissao = { "codfunc": codfunc, "dtini": dtini, "dtfin": dtfin, "nucom": nucom, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/comissao/listarservicosbyfechamento`, comissao, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  criaFechamentoComissao(nucom: number, dtini: any, dtfin: any, codfunc: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const comissao = { "nucom": nucom, "dtini": dtini, "dtfin": dtfin, "codfunc": codfunc, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/comissao/createfechamentocomissao`, comissao, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  criaAjusteComissao(nuajuste: any, nucom: number, descricao: string, valor: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const comissao = { "nuajuste": nuajuste, "nucom": nucom, "descricao": descricao, "valor": valor, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/comissao/createajustecomissao`, comissao, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  buscaFechamentoPorNucom(codfunc: number, dtini: any, dtfin: any, nucom: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const comissao = { "codfunc": codfunc, "dtini": dtini, "dtfin": dtfin, "nucom": nucom, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/comissao/listarservicosbyfechamento`, comissao, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  atualizaValorComissao(valor: number, nucom: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const comissao = { "valor": valor, "nucom": nucom, "codusuresp": codusuresp }

    return this.http
      .put<any>(`${environment.BASE_URL}/comissao/atualizavalorcomissao`, comissao, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  atualizaNuComissao(nucom: any, nuid: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const comissao = { "nucom": nucom, "nuid": nuid, "codusuresp": codusuresp }

    return this.http
      .put<any>(`${environment.BASE_URL}/comissao/atualizanucomnoservico`, comissao, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  fechaComissao(nucom: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const comissao = { "nucom": nucom, "codusuresp": codusuresp }

    return this.http
      .put<any>(`${environment.BASE_URL}/comissao/fecharcomissao`, comissao, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  excluiAjusteComissao(nuajuste: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const ajuste = { "nuajuste": nuajuste, "codusuresp": codusuresp }

    return this.http
      .put<any>(`${environment.BASE_URL}/comissao/excluiajustecomissao`, ajuste, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  excluiTodosAjusteComissao(nucom: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const comissao = { "nucom": nucom, "codusuresp": codusuresp }

    return this.http
      .put<any>(`${environment.BASE_URL}/comissao/excluitodosajustecomissao`, comissao, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  excluiComissao(nucom: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const comissao = { "nucom": nucom, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/comissao/excluicomissao`, comissao, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  geraRelatorioPorNucom(nucom: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    //let headers = new HttpHeaders().append('Authorization', token!);
    // let headers= new HttpHeaders( {  responseType: 'blob' as 'json', observe: 'response' });
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/pdf',
      'Authorization': token!
    });

    const comissao = { "nucom": nucom, "codusuresp": codusuresp }
    return this.http
      .post<any>(`${environment.BASE_URL}/rel/getRelByNucom`, comissao, { headers: headers, responseType: 'blob' as 'json' })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  geraRelatorioUrlPorNucom(nucom: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const comissao = { "nucom": nucom, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/rel/getUrlRelByNucom`, comissao, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  /**SELECT PARA O EXCEL */
  geraRelatorioExcel(nucom: number, codusuresp: string): Observable<any> {
    // const token = localStorage.getItem('token');
    // // let headers = new HttpHeaders().append('Authorization', token!);

    // let headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    //   'Authorization': token!
    // });

    // const comissao = { "nucom": nucom }

    // return this.http
    //   .post<any>(`${environment.BASE_URL}/comissao/relatoriocomissao`, comissao, { headers: headers, responseType: 'blob' as 'json' })
    //   .pipe(
    //     first(),
    //     //delay(5000),
    //     tap((r: any) => console.log(r), (e: any) => console.log(e)));

    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    // let headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    //   'Authorization': token!
    // });

    const comissao = { "nucom": nucom, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/comissao/relatoriocomissao`, comissao, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  public setComissao(c: Comissao) { //SETA O BARBEIRO NO VETOR
    this.comissao = c;
  }

  public getComissao() { //RECUPERA O CONTEUDO DO BARBEIRO PARA VISUALIZAÇÃO
    return this.comissao;
  }
}
