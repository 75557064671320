import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, Observable, tap } from 'rxjs';
import { Horario } from './assets/horario';
import { environment } from './environments/environment';
import { LoginService } from './login/login.service';

@Injectable({
  providedIn: 'root'
})
export class HorarioService {
  horario: Horario = {}

  constructor(private http: HttpClient, private loginService: LoginService) { }

  buscarHorarioDiaPorCodHor(codhor: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const horario = { "codhor": codhor, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/horario/buscahorporcodhor`, horario, { headers: headers })
      .pipe(
        first(),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );

  }

  buscarHorarioFuncPorCodCli(codcli: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const cliente = { "codcli": codcli, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/horario/buscahorporcodcli`, cliente, { headers: headers })
      .pipe(
        first(),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );

  }

  preencherHorarioFuncionario(codhor: number, nomehor: string, codfunc: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const horarioFuncionario = { "codhor": codhor, "nomehor": nomehor, "codfunc": codfunc, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/horario/createhor`, horarioFuncionario, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  preencherHorarioDia(horarioDia: any, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const horario = { "horarioDia": horarioDia, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/horario/preencherhors`, horarioDia, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  buscaHorarioPorCodFuncEDia(codfunc: number, dia: any, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const dataInfo = { "codfunc": codfunc, "dia": dia, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/horario/buscahorporcodfuncdia`, dataInfo)
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
        //    this.loginService.verificaToken(e.status);
          })
      );
  }

  buscaHorarioPorCodFuncEDiaReagendamento(codfunc: number, dia: any, nuid: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const dataInfo = { "codfunc": codfunc, "dia": dia, "nuid": nuid, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/horario/buscahorporcodfuncdiareag`, dataInfo, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
          //  this.loginService.verificaToken(e.status);
          })
      );
  }



  excluiHorarioPorCodHor(codhor: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const horario = { "codhor": codhor, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/horario/excluihorario`, horario, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  public setHorario(h: Horario) { //SETA O SERVICO NO VETOR
    this.horario = h;
  }

  public getServico() { //RECUPERA O CONTEUDO DO SERVICO PARA VISUALIZAÇÃO
    return this.horario;
  }
}
