import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, Observable, Subject, tap } from 'rxjs';
import { Notificacao } from './assets/notificacao';
import { environment } from './environments/environment';
import { LoginService } from './login/login.service';

@Injectable({
  providedIn: 'root'
})
export class NotificacoesService {

  notificacao: Notificacao = {}

  /**VARIAVEL PARA MONITAR O SELECIONAR DA NOTIFICAÇÃO */
  public notificacao$ = new Subject();

  constructor(private http: HttpClient, private loginService: LoginService) { }


  ativarDesativarNotWpp(codcli: string, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const cliente = { "codcli": codcli, "codusuresp": codusuresp }

    return this.http
      .put<any>(`${environment.BASE_URL}/notificacoes/ativdesatnotwpp`, cliente, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  ativarDesativarNotPush(codcli: string, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const cliente = { "codcli": codcli, "codusuresp": codusuresp }

    return this.http
      .put<any>(`${environment.BASE_URL}/notificacoes/ativdesatnotpush`, cliente, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  atualizarNotificacaoRetorno(tipnotretorno: any, notretorno: any, codcli: string, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const notRetorno = { "tipnotretorno": tipnotretorno, "notretorno": notretorno, "codcli": codcli, "codusuresp": codusuresp }

    return this.http
      .put<any>(`${environment.BASE_URL}/notificacoes/atualizanotificacaoretorno`, notRetorno, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  atualizarNotificacaoAniversariante(tipnotaniversariante: any, notaniversariante: any, codcli: string, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const notAniversario = { "tipnotaniversariante": tipnotaniversariante, "notaniversariante": notaniversariante, "codcli": codcli, "codusuresp": codusuresp }

    return this.http
      .put<any>(`${environment.BASE_URL}/notificacoes/atualizanotificacaoaniversariante`, notAniversario, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  atualizarNotificacaoAgendamento(tipnotagd: any, notagd: any, codcli: string, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const notAniversario = { "tipnotagd": tipnotagd, "notagd": notagd, "codcli": codcli, "codusuresp": codusuresp }

    return this.http
      .put<any>(`${environment.BASE_URL}/notificacoes/atualizanotificacaoagendamento`, notAniversario, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }
  
  atualizarNotificacaoLembrete(tipnotlembrete: any, notlembrete: any, codcli: string, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const notAniversario = { "tipnotlembrete": tipnotlembrete, "notlembrete": notlembrete, "codcli": codcli, "codusuresp": codusuresp }

    return this.http
      .put<any>(`${environment.BASE_URL}/notificacoes/atualizanotificacaolembrete`, notAniversario, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }



  buscaParametrosNotificacoesPorCodcli(codcli: string, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const cliente = { "codcli": codcli, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/notificacoes/buscaparametrosnotificacoesbycodcli`, cliente, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  criaNotificacaoPush(titulo: string, mensagem: string, remetente: string, codusu: string, dtini: any, dtfin: any, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const notificacao = { "titulo": titulo, "mensagem": mensagem, "remetente": remetente, "codusu": codusu, "dtini": dtini, "dtfin": dtfin, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/notificacoes/createnotificacaopush`, notificacao, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  buscaNotificacoesPorCodusu(codusu: string, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const usuario = { "codusu": codusu, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/notificacoes/getnotificacoesbycodusu`, usuario, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  leNotificacao(nunot2: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const notificacao = { "nunot2": nunot2, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/notificacoes/marcarnot2comolido`, notificacao, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  criaNotificacaoInstantanea(codusu: string, tipnot: any, mensagem: string, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const notificacao = { "codusu": codusu, "tipnot": tipnot, "mensagem": mensagem, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/notificacoes/createnotificacaoinstantanea`, notificacao, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  notificacaoWebEnviada(nunot2: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const notificacao = { "nunot2": nunot2, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/notificacoes/marcarnot2comoenviadoweb`, notificacao, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r), (e: any) => console.log(e)));
  }


  public setNotificacao(n: Notificacao) {
    this.notificacao = n;
    this.notificacao$.next(this.notificacao);
  }

  public getNotificacao() {
    return this.notificacao;
  }
}

