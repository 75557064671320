import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { AlertService } from 'src/alert.service';
import { Estabelecimento } from 'src/assets/estabelecimento';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { UtilitariosService } from 'src/assets/utilitarios.service';
import { EstabelecimentoService } from 'src/estabelecimento.service';
import { ProfissionalService } from 'src/profissional.service';
import { MetricasService } from 'src/metricas.service';
import { TraducaoService } from 'src/traducao.service';
import { UsuarioService } from 'src/usuario.service';

@Component({
  selector: 'app-meus-resultados',
  templateUrl: './meus-resultados.component.html',
  styleUrls: ['./meus-resultados.component.scss'],
  providers: [MessageService],
  encapsulation: ViewEncapsulation.None
})
export class MeusResultadosComponent implements OnInit {
  /**DATA */
  data: Date;
  hoje: Date;
  dataIni: Date;
  dataFin: Date;
  //dataMaior: Date;


  /**VARIAVEIS DOS GRAFICOS */
  graficoBarra: any;
  graficoDonut: any;
  basicOptions: any;
  optionDonut: any;


  /**VARIAVEIS DO COMPONENT */
  opcoes: any[];
  opcoesDonut: any[];
  opcaoSelecionada: string = 'R';
  opcaoSelecionadaDonut: string = 'R';
  horizontalOptions: any;
  showAlterar: boolean = false;
  valorSelecionadoAlterar: string = '';
  dataInicial: any = '';
  dataFinal: any;
  liberaCalendario1: boolean = true;
  liberaCalendario2: boolean = true;
  loading: boolean = false;
  receita: number = 0;
  qtdAgendamentos: number = 0;
  difCli: number = 0;
  positivacao: number = 0;
  ticketMedio: number = 0;
  qtdClienteAtivos: number = 0
  auxDiaIni: any;
  auxDiaFin: any;
  dadosGeraisTop20: any;
  dadosGeraisTop5: any;
  nomeProfissional: string = '';
  dataMostrada: any;
  prefixoData: string = '';
  showCarregando: boolean = false;
  codusu: string = '';
  codfunc: number = 0;

  resMobile: boolean = false;
  resPc: boolean = false;

  constructor(public translate: TranslateService, public primeNGConfig: PrimeNGConfig, private metricasService: MetricasService, private estabelecimentoService: EstabelecimentoService,
    private alertService: AlertService, private messageService: MessageService, private decodedTokenService: DecodedTokenService, private utilitariosService: UtilitariosService,
    private traducaoSerice: TraducaoService, private usuarioService: UsuarioService) {
    /**TRADUÇÃO */
    this.traducaoSerice.changeLang();

    this.opcoes = [{ 'opcao': 'R', 'nome': 'R$' },
    { 'opcao': 'Q', 'nome': 'Qtd. Serviço' },
    { 'opcao': 'T', 'nome': 'Tempo' }
    ]

    this.opcoesDonut = [{ 'opcao': 'R', 'nome': 'R$' },
    { 'opcao': 'Q', 'nome': 'Qtd. Serviço' },
    { 'opcao': 'T', 'nome': 'Tempo' }
    ]

    this.data = new Date();
    this.hoje = new Date(this.data);

    /**VARIAVEIS DO PERIODO A SER ESCOLHIDO */
    this.dataIni = new Date(this.data);
    this.dataFin = new Date(this.data);
  }

  ngOnInit(): void {
    this.recuperaCodUsu();
    this.meuPerfil();


    this.opcaoSelecionada = this.opcoes[0].opcao;
    this.opcaoSelecionadaDonut = this.opcoesDonut[0].opcao;
    this.escolhePeriodo();
    this.checaResolucao();
    /**GRAFICOS*/


    this.basicOptions = {
      plugins: {
        legend: {
          labels: {
            color: '#432611'
          }
        }
      }
    };

    this.optionDonut = {
      plugins: {
        legend: {
          position: 'right'
        }
      }
    }

  }

  /**RECUPERA O CODUSU DO JWT PARA FAZER A BUSCA NO MEUPERFIL */
  recuperaCodUsu() {
    this.codusu = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }

  /**RECUPERA O NOME E CODFUNC DO FUNCIONARIO */
  meuPerfil(): void {
    this.usuarioService.getPerfil(this.codusu, this.codusu).subscribe((res) => {
      this.nomeProfissional = Object(res)[0].nomeparc;
      this.codfunc = Object(res)[0].codfunc;
      this.escolhePeriodo();
    },
      (error) => {
        console.log(error.message)
        console.log('deu ruim')
        this.alertService.alert('Algo deu errado.', 'error');
        //this.showError('Atenção', 'Algo deu errado.')
      });
  }









  /**MOSTRA O TIPO DE GRAFICO SELECIONADO*/
  selecionaTipoBarras() {
    console.log(this.opcaoSelecionada)

    this.metricasService.buscaDadosGeraisTop20ClientesPorCodfunc(this.auxDiaIni, this.auxDiaFin, this.codfunc, this.opcaoSelecionada, this.codusu).subscribe((res) => {
      console.log(res);

      this.dadosGeraisTop20 = res;
      console.log(this.dadosGeraisTop20.length);

      let clientes = [];
      let dados = [];
      let legenda;

      if (this.dadosGeraisTop20.length > 0) {
        for (let i = 0; i < this.dadosGeraisTop20.length; i++) {
          clientes.push(this.dadosGeraisTop20[i].nomeusu);
          if (this.opcaoSelecionada === "R") {
            legenda = 'R$'
            dados.push(this.dadosGeraisTop20[i].vlrtot);
          }
          if (this.opcaoSelecionada === "Q") {
            legenda = 'Quantidade'
            dados.push(this.dadosGeraisTop20[i].qtd);
          }
          if (this.opcaoSelecionada === "T") {
            legenda = 'Tempo(min)'
            dados.push(this.dadosGeraisTop20[i].tempom);
          }
        }
      } else {
        if (this.opcaoSelecionada === "R") {
          legenda = 'R$'
        }
        if (this.opcaoSelecionada === "Q") {
          legenda = 'Quantidade'
        }
        if (this.opcaoSelecionada === "T") {
          legenda = 'Tempo(min)'
        }
      }




      this.graficoBarra = {
        labels: clientes,
        datasets: [
          {
            label: legenda,
            backgroundColor: '#432611',
            data: dados
          }
        ]
      };


    })


  }

  selecionaTipoDonut() {

    this.metricasService.buscaDadosGeraisTop5ServicosPorCodfunc(this.auxDiaIni, this.auxDiaFin, this.codfunc, this.opcaoSelecionadaDonut, this.codusu).subscribe((res) => {
      console.log(res)

      this.dadosGeraisTop5 = res;

      let servicos = [];
      let dados = [];
      let legenda;



      if (this.dadosGeraisTop5.length > 0) {
        for (let i = 0; i < this.dadosGeraisTop5.length; i++) {
          servicos.push(this.dadosGeraisTop5[i].nomeprod);
          if (this.opcaoSelecionadaDonut === "R") {
            legenda = 'R$'
            dados.push(this.dadosGeraisTop5[i].sum);
          }
          if (this.opcaoSelecionadaDonut === "Q") {
            legenda = 'Quantidade'
            dados.push(this.dadosGeraisTop5[i].qtd);
          }
          if (this.opcaoSelecionadaDonut === "T") {
            legenda = 'Tempo(min)'
            dados.push(this.dadosGeraisTop5[i].tempom);
          }
        }
      } else {
        if (this.opcaoSelecionada === "R") {
          legenda = 'R$'
        }
        if (this.opcaoSelecionada === "Q") {
          legenda = 'Quantidade'
        }
        if (this.opcaoSelecionada === "T") {
          legenda = 'Tempo(min)'
        }
      }
      this.graficoDonut = {
        labels: servicos,
        datasets: [
          {
            label: legenda,
            backgroundColor: ['#BFA58E', '#432611', 'rgba(67, 38, 17, 0.8)', '#A0A0A0', '#DCD3CB', 'rgba(191, 165, 142, 0.5)'],
            data: dados
          }
        ]
      };
    })
  }

  mostraDialogAlterar() {
    this.showAlterar = true;
  }



  clickOutroPeriodo() {
    console.log(this.valorSelecionadoAlterar)
    if (this.valorSelecionadoAlterar === 'outroPeriodo') {
      console.log('erwgferg')
      this.liberaCalendario1 = false;
      console.log(this.liberaCalendario1)
    } else {
      this.liberaCalendario1 = true;
      this.liberaCalendario2 = true;
      this.dataInicial = '';
      this.dataFinal = '';
    }
  }
  clickCalendario1() {
    if (this.dataInicial !== '') {
      this.liberaCalendario2 = false;
      console.log(this.dataInicial)
    }
  }



  escolhePeriodo() {
    this.showCarregando = true;
    this.loading = true;

    console.log(this.valorSelecionadoAlterar)
    if (this.valorSelecionadoAlterar === '') {
      this.valorSelecionadoAlterar = 'hoje';
    }

    // if (this.utilitariosService.validaCampo([this.valorSelecionadoAlterar])) {
    //   this.showError('Atenção', 'Selecione um período.')
    //   this.loading = false;
    //   return;
    // }

    if (this.utilitariosService.validaCampo([this.dataInicial, this.dataFinal]) && this.valorSelecionadoAlterar === "outroPeriodo") {
      this.showError('Atenção', 'Selecione o período das datas.')
      this.loading = false;
      this.showCarregando = false;
      return;
    }




    /********************************************************************************
   *                                      HOJE                                      *
   ********************************************************************************/
    if (this.valorSelecionadoAlterar === "hoje") {

      console.log('gregertgergerge')
      /**FAZ A DATA INICIAL E FINAL SER HOJE*/
      this.dataIni = new Date(this.data);
      this.dataFin = new Date(this.data);

      this.prefixoData = 'Hoje | ';
      this.dataMostrada = this.dataIni;

      // this.auxDiaIni = this.dataIni.getFullYear() + '-' + ('0' + (this.dataIni.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataIni.getDate()).slice(-2);
      // this.auxDiaFin = this.dataFin.getFullYear() + '-' + ('0' + (this.dataFin.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataFin.getDate()).slice(-2);
      this.auxDiaIni = this.utilitariosService.ajustaDataBanco(this.dataIni);
      this.auxDiaFin = this.utilitariosService.ajustaDataBanco(this.dataFin);
      this.metricasService.buscaDadosGeraisCardPorCodfunc(this.auxDiaIni, this.auxDiaFin, this.codfunc, this.codusu).subscribe((res) => {
        console.log(res);

        if (res.length) {
          this.positivacao = Object(res)[0].positivacao;
          this.receita = Object(res)[0].receita;
          this.qtdAgendamentos = Object(res)[0].qtd_agendamentos;
          this.ticketMedio = Object(res)[0].ticket_medio;
          this.qtdClienteAtivos = Object(res)[0].qtd_cli_ativos;
          this.difCli = Object(res)[0].dif_cli;
        } else {
          this.positivacao = 0;
          this.receita = 0;
          this.qtdAgendamentos = 0;
          this.ticketMedio = 0;
          this.qtdClienteAtivos = 0;
          this.difCli = 0;
        }

        this.selecionaTipoBarras();
        this.selecionaTipoDonut();
        this.showAlterar = false;
        this.loading = false;
        this.showCarregando = false;

      });
    }

    /********************************************************************************
    *                                      ONTEM                                    *
    ********************************************************************************/

    if (this.valorSelecionadoAlterar === "ontem") {
      /**FAZ A DATA INICIAL SER ONTEM*/
      this.dataIni = new Date(this.data);
      this.dataIni.setDate(this.data.getDate() - 1);

      this.prefixoData = 'Ontem | ';
      this.dataMostrada = this.dataIni;

      // this.auxDiaIni = this.dataIni.getFullYear() + '-' + ('0' + (this.dataIni.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataIni.getDate()).slice(-2);
      // this.auxDiaFin = this.dataFin.getFullYear() + '-' + ('0' + (this.dataFin.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataFin.getDate()).slice(-2);
      this.auxDiaIni = this.utilitariosService.ajustaDataBanco(this.dataIni);
      this.auxDiaFin = this.utilitariosService.ajustaDataBanco(this.dataFin);

      console.log(this.auxDiaIni)
      console.log(this.auxDiaFin)
      this.metricasService.buscaDadosGeraisCardPorCodfunc(this.auxDiaIni, this.auxDiaFin, this.codfunc, this.codusu).subscribe((res) => {
        console.log(res);

        if (res.length) {
          this.positivacao = Object(res)[0].positivacao;
          this.receita = Object(res)[0].receita;
          this.qtdAgendamentos = Object(res)[0].qtd_agendamentos;
          this.ticketMedio = Object(res)[0].ticket_medio;
          this.qtdClienteAtivos = Object(res)[0].qtd_cli_ativos;
          this.difCli = Object(res)[0].dif_cli;
        } else {
          this.positivacao = 0;
          this.receita = 0;
          this.qtdAgendamentos = 0;
          this.ticketMedio = 0;
          this.qtdClienteAtivos = 0;
          this.difCli = 0;
        }
        this.selecionaTipoBarras();
        this.selecionaTipoDonut();
        this.showAlterar = false;
        this.loading = false;
        this.showCarregando = false;
      },
        (error) => {

          console.log('deu ruim')
          this.loading = false;
          this.showAlterar = false;
          this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')
        });
    }

    /********************************************************************************
    *                                      7 DIAS                                    *
    ********************************************************************************/
    if (this.valorSelecionadoAlterar === "7Dias") {
      /**FAZ A DATA INICIAL SER 7 DIAS ATRAS*/
      this.dataIni = new Date(this.data);
      this.dataIni.setDate(this.data.getDate() - 7);

      this.prefixoData = 'Últimos 7 dias';
      this.dataMostrada = '';

      // this.auxDiaIni = this.dataIni.getFullYear() + '-' + ('0' + (this.dataIni.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataIni.getDate()).slice(-2);
      // this.auxDiaFin = this.dataFin.getFullYear() + '-' + ('0' + (this.dataFin.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataFin.getDate()).slice(-2);
      this.auxDiaIni = this.utilitariosService.ajustaDataBanco(this.dataIni);
      this.auxDiaFin = this.utilitariosService.ajustaDataBanco(this.dataFin);


      this.metricasService.buscaDadosGeraisCardPorCodfunc(this.auxDiaIni, this.auxDiaFin, this.codfunc, this.codusu).subscribe((res) => {
        console.log(res);


        if (res.length) {
          this.positivacao = Object(res)[0].positivacao;
          this.receita = Object(res)[0].receita;
          this.qtdAgendamentos = Object(res)[0].qtd_agendamentos;
          this.ticketMedio = Object(res)[0].ticket_medio;
          this.qtdClienteAtivos = Object(res)[0].qtd_cli_ativos;
          this.difCli = Object(res)[0].dif_cli;
        } else {
          this.positivacao = 0;
          this.receita = 0;
          this.qtdAgendamentos = 0;
          this.ticketMedio = 0;
          this.qtdClienteAtivos = 0;
          this.difCli = 0;
        }
        this.selecionaTipoBarras();
        this.selecionaTipoDonut();
        this.showAlterar = false;
        this.loading = false;
        this.showCarregando = false;
      },
        (error) => {

          console.log('deu ruim')
          this.loading = false;
          this.showAlterar = false;
          this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')
        });
    }


    /********************************************************************************
    *                                      15 DIAS                                   *
    ********************************************************************************/

    if (this.valorSelecionadoAlterar === "15Dias") {
      /**FAZ A DATA INICIAL SER 15 DIAS ATRAS*/
      this.dataIni = new Date(this.data);
      this.dataIni.setDate(this.data.getDate() - 15);

      this.prefixoData = 'Últimos 15 dias';
      this.dataMostrada = '';

      // this.auxDiaIni = this.dataIni.getFullYear() + '-' + ('0' + (this.dataIni.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataIni.getDate()).slice(-2);
      // this.auxDiaFin = this.dataFin.getFullYear() + '-' + ('0' + (this.dataFin.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataFin.getDate()).slice(-2);
      this.auxDiaIni = this.utilitariosService.ajustaDataBanco(this.dataIni);
      this.auxDiaFin = this.utilitariosService.ajustaDataBanco(this.dataFin);


      this.metricasService.buscaDadosGeraisCardPorCodfunc(this.auxDiaIni, this.auxDiaFin, this.codfunc, this.codusu).subscribe((res) => {
        console.log(res);

        if (res.length) {
          this.positivacao = Object(res)[0].positivacao;
          this.receita = Object(res)[0].receita;
          this.qtdAgendamentos = Object(res)[0].qtd_agendamentos;
          this.ticketMedio = Object(res)[0].ticket_medio;
          this.qtdClienteAtivos = Object(res)[0].qtd_cli_ativos;
          this.difCli = Object(res)[0].dif_cli;
        } else {
          this.positivacao = 0;
          this.receita = 0;
          this.qtdAgendamentos = 0;
          this.ticketMedio = 0;
          this.qtdClienteAtivos = 0;
          this.difCli = 0;
        }
        this.selecionaTipoBarras();
        this.selecionaTipoDonut();
        this.showAlterar = false;
        this.loading = false;
        this.showCarregando = false;
      },
        (error) => {

          console.log('deu ruim')
          this.loading = false;
          this.showAlterar = false;
          this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')
        });
    }

    /********************************************************************************
    *                                      30 DIAS                                  *
    ********************************************************************************/
    if (this.valorSelecionadoAlterar === "ultimoMes") {
      /**FAZ A DATA INICIAL SER 30 DIAS ATRAS*/
      this.dataIni = new Date(this.data);
      this.dataIni.setDate(this.data.getDate() - 30);

      this.prefixoData = 'Últimos 30 dias';
      this.dataMostrada = '';

      // this.auxDiaIni = this.dataIni.getFullYear() + '-' + ('0' + (this.dataIni.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataIni.getDate()).slice(-2);
      // this.auxDiaFin = this.dataFin.getFullYear() + '-' + ('0' + (this.dataFin.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataFin.getDate()).slice(-2);
      this.auxDiaIni = this.utilitariosService.ajustaDataBanco(this.dataIni);
      this.auxDiaFin = this.utilitariosService.ajustaDataBanco(this.dataFin);

      this.metricasService.buscaDadosGeraisCardPorCodfunc(this.auxDiaIni, this.auxDiaFin, this.codfunc, this.codusu).subscribe((res) => {
        console.log(res);

        if (res.length) {
          this.positivacao = Object(res)[0].positivacao;
          this.receita = Object(res)[0].receita;
          this.qtdAgendamentos = Object(res)[0].qtd_agendamentos;
          this.ticketMedio = Object(res)[0].ticket_medio;
          this.qtdClienteAtivos = Object(res)[0].qtd_cli_ativos;
          this.difCli = Object(res)[0].dif_cli;
        } else {
          this.positivacao = 0;
          this.receita = 0;
          this.qtdAgendamentos = 0;
          this.ticketMedio = 0;
          this.qtdClienteAtivos = 0;
          this.difCli = 0;
        }

        this.selecionaTipoBarras();
        this.selecionaTipoDonut();
        this.showAlterar = false;
        this.loading = false;
        this.showCarregando = false;
      },
        (error) => {

          console.log('deu ruim')
          this.loading = false;
          this.showAlterar = false;
          this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')
        });
    }


    /********************************************************************************
    *                                  OUTRO PERIODO                                 *
    ********************************************************************************/

    if (this.valorSelecionadoAlterar === "outroPeriodo") {
      // this.auxDiaIni = this.dataInicial.getFullYear() + '-' + ('0' + (this.dataInicial.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataInicial.getDate()).slice(-2);
      // this.auxDiaFin = this.dataFinal.getFullYear() + '-' + ('0' + (this.dataFinal.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataFinal.getDate()).slice(-2);
      this.auxDiaIni = this.utilitariosService.ajustaDataBanco(this.dataInicial);
      this.auxDiaFin = this.utilitariosService.ajustaDataBanco(this.dataFinal);

      this.metricasService.buscaDadosGeraisCardPorCodfunc(this.auxDiaIni, this.auxDiaFin, this.codfunc, this.codusu).subscribe((res) => {
        console.log(res);

        this.prefixoData = 'Período personalizado';
        this.dataMostrada = '';

        if (res.length) {
          this.positivacao = Object(res)[0].positivacao;
          this.receita = Object(res)[0].receita;
          this.qtdAgendamentos = Object(res)[0].qtd_agendamentos;
          this.ticketMedio = Object(res)[0].ticket_medio;
          this.qtdClienteAtivos = Object(res)[0].qtd_cli_ativos;
          this.difCli = Object(res)[0].dif_cli;
        } else {
          this.positivacao = 0;
          this.receita = 0;
          this.qtdAgendamentos = 0;
          this.ticketMedio = 0;
          this.qtdClienteAtivos = 0;
          this.difCli = 0;
        }
        this.selecionaTipoBarras();
        this.selecionaTipoDonut();
        this.showAlterar = false;
        this.loading = false;
        this.showCarregando = false;
      },
        (error) => {

          console.log('deu ruim')
          this.loading = false;
          this.showAlterar = false;
          this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')
        });
    }

  }

  checaResolucao() {
    if (screen.width < 1024 || screen.height < 768) {
      this.resPc = false;
      this.resMobile = true;
    } else {
      this.resMobile = false;
      this.resPc = true;
    }
    window.addEventListener('resize', () => {
      if (screen.width < 1024 || screen.height < 768) {
        this.resPc = false;
        this.resMobile = true;
      } else {
        this.resMobile = false;
        this.resPc = true;
      }
    });

  }

  /**METODO DE TOAST */
  showError(titulo: string, mensagem: string) {
    if (screen.width < 640 || screen.height < 480) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else if (screen.width < 1024 || screen.height < 768) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else {
      this.messageService.add({
        key: 'tr',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    }
  }
}
