<div class="pt-7">

    <div class="flex flex-column  pb-3">
        <div class="flex align-items-center justify-content-center">
            <img src="./assets/iconeCadastrarNotificacoes.svg" />
        </div>
        <div class=" tituloNegritoCadNotificacoes flex align-items-center justify-content-center">
            <span>Notificações</span>
        </div>
    </div>







    <div class="flex justify-content-center flex-wrap ">
        <div class="flex align-items-center justify-content-center">

            <footer class="xl:w-5" id="footerCadastrarNotificacoes">
                <p-button label="Cadastrar" id="botaoCadastrarNotificacoes" routerLink="/notificacoes"
                    [style]="{width: '100%'}">
                </p-button>
            </footer>
        </div>
    </div>

</div>