<div class="pt-7">

    <div class="flex flex-column  pb-3">
        <div class="flex align-items-center justify-content-center">
            <img src="./assets/iconeCadastrarNotificacoes.svg" />
        </div>
        <div class=" flex align-items-center justify-content-center">
            <span class=" tituloNegritoFaturas"> Faturas </span>
        </div>
    </div>

    <div class="flex flex-column  pb-3">
        <div class="flex justify-content-center flex-wrap ">


            <div class="scroll-div">
                <table id="tabela">
                    <tr *ngFor="let faturas of faturas">
                        <td>
                            <div class="linhahorizontalCadastrarBarbeiros pt-1"></div>
                            <div class="col-12 sm-12 md-4 ">
                                <div class="flex flex-column">

                                    <div class="flex align-items-center justify-content-center tamanhoLinhaTabela">
                                        <div class="col ">
                                            <div class="fontInfoFaturas">
                                                <span class="xl:text-xl">Nº {{faturas.id}} </span>
                                            </div>

                                            <div class="fontInfoFaturas">
                                                <span class="xl:text-base">Data Fatura: {{faturas.dtFatura}}</span>
                                            </div>

                                            <div class="fontInfoFaturas">
                                                <span class="xl:text-base">Vencimento: {{faturas.vencimento}}</span>
                                            </div>

                                            <div class="fontInfoFaturas">
                                                <span class="xl:text-base">Valor: {{faturas.valor| currency :
                                                    'BRL'}}</span>
                                            </div>

                                            <div class="flex gap-2">
                                                <div *ngIf="faturas.situacao === 'A'">
                                                    <div class="flex align-items-center flex-wrap gap-1">
                                                        <img src="./assets/bolinhaLaranja.svg" />
                                                        <div
                                                            class="flex align-items-center justify-content-center pt-1">
                                                            <span class="xl:text-base fontSitFaturas"> EM ABERTO</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="faturas.situacao === 'F'">
                                                    <div class="flex align-items-center flex-wrap gap-1">
                                                        <img src="./assets/bolinhaVerde.svg" />
                                                        <div
                                                            class="flex align-items-center justify-content-center pt-1">
                                                            <span class="xl:text-base fontSitFaturas"> FECHADO</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                        <div class="flex align-items-center justify-content-center ">
                                            <img class="iconeMenu" src="./assets/iconeMenuAdm.png"
                                                (click)="menu.toggle($event);" />
                                            <p-menu #menu [popup]="true" [model]="itemsFatura"
                                                styleClass="menuCadBarbeiros"></p-menu>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>



    <!--                          DIALOG DA FATURA              -->
    <p-dialog header="Fatura #42853" [(visible)]="showFatura" [modal]="true"
        [style]="{'width': '350px', 'height': '420px'}" id="dialogFatura">
        <div class="flex justify-content-center flex-wrap col">
            <div class="fontDialogFaturas flex align-items-center justify-content-center ">
                <span>Evite transtornos e efeute o pagamento até a data de vencimento. Agradecemos a pontualidade!
                </span>
            </div>
        </div>


        <div class="col">
            <div class="flex flex-column pb-1">
                <span class="fontDialogInfo"> Data da Fatura</span>
                <span class="fontDialogInfoCampo">06/02/2023</span>
            </div>


            <div class="flex flex-column pb-1">
                <span class="fontDialogInfo"> Data de Vencimento</span>
                <span class="fontDialogInfoCampo">11/02/2023</span>
            </div>

            <div class="flex flex-column pb-1">
                <span class="fontDialogInfo"> Forma de pagamento</span>
                <span class="fontDialogInfoCampo">PIX</span>
            </div>

            <div class="flex flex-column pb-1">
                <span class="fontDialogInfo"> Resumo</span>
                <span class="fontDialogInfoCampo">Contrato 401:</span>
                <span class="fontDialogInfoCampo">Acesso a 3 Funcionários, 1 Barbearia e até 500
                    Clientes</span>

            </div>

            <div class="flex flex-column pt-1">
                <span class="fontDialogInfo"> Valor</span>
                <span class="fontDialogInfoCampo">R$ 135,00</span>
            </div>
        </div>





        <div *ngIf="!loading" [style]="{width: '100%'}">
            <button pButton type="button" label="Pagar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
         'border-radius': '11px'}"></button>
        </div>
        <div *ngIf="loading" [style]="{width: '100%'}">
            <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 mt-3"
                [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
         'border-radius': '11px'}"></button>
        </div>
    </p-dialog>











    <div class="flex justify-content-center flex-wrap ">
        <div class="flex align-items-center justify-content-center">

            <footer class="xl:w-5" id="footerCadastrarFatura">
                <p-button label="Cadastrar" id="botaoCadastrarFatura" [style]="{width: '100%'}">
                </p-button>
            </footer>
        </div>
    </div>




</div>