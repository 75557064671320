<body>
    <p-toast position="bottom-center" key="bc"></p-toast>
    <p-toast position="top-right" key="tr"></p-toast>

    <!-- PASSO 1 -->
    <div *ngIf="passos===0">
        <div class="paddingTopFiliar1 ">


            <div class="flex justify-content-center flex-wrap">
                <div class="flex justify-content-center ">
                    <img [src]="filename" class="imgEstabelecimento" />
                </div>
            </div>

            <!-- <div class="flex justify-content-center flex-wrap ">
                <img [src]="filename" class="imgRedondaPasso1  " />
            </div> -->

            <div class=" paddingTopFiliar2 flex align-items-center justify-content-center">
                <div class="containerFiliar">
                    <div class="align-items-center justify-content-between  formgroup-inline">


                        <div class="flex flex-column  alinhaInfoEstabelecimento">
                            <div class="fontcinza pb-2 ">
                                <div class="fontcinzanegrita">
                                    <span>{{nomeemp}}</span>
                                </div>
                                <span> {{logradouro}} {{complemento}}, {{numero}}</span>
                                <div>
                                    <span> {{bairro}} - {{nomecid}} </span>
                                </div>
                                <div>
                                    <span> {{telefone}}</span>
                                </div>
                            </div>
                        </div>



                        <div class="flex align-items-center justify-content-center  fontAvalicacao">
                            <span>5</span>
                            <img src="./assets/iconeAvaliacao.png" class="imgAvaliacao" />

                        </div>


                    </div>
                </div>
            </div>

            <div class=" flex justify-content-center pb-2  ">
                <p-button label="Agendar" id="botaoFiliar" (click)="clickAgendar()">
                </p-button>
            </div>

            <div class="flex alinhaInfoEstabelecimento  flex-wrap ">
                <table>
                    <div class="linhahorizontalFiliar2 pt-1"></div>

                    <div class="flex justify-content-center flex-wrapp">
                        <div class="col fontcinzanegrita flex  alinhaInfoEstabelecimento">
                            <span>Serviços</span>
                        </div>
                    </div>

                    <div class="linhahorizontalFiliar2 pb-1"></div>
                </table>
            </div>
            <div class="flex flex-column  pb-3">
                <div class="flex justify-content-center flex-wrap ">
                    <div class="scroll-divAgendamento">
                        <table>
                            <tr *ngFor="let servicosEstabelecimento of servicosEstabelecimento">
                                <td>
                                    <div class="flex flex-column   align-items-center">
                                        <div class="col tamanhoLinhaTabelaAgendamento">
                                            <div class="flex gap-2 ">
                                                <div class="flex flex-column align-items-start">
                                                    <div class=" fontmarron">
                                                        <span>{{servicosEstabelecimento.nomeprod}} |
                                                            ({{servicosEstabelecimento.minutos}} min)</span>
                                                    </div>
                                                    <div class="fontcinza">
                                                        <span>{{servicosEstabelecimento.descrprod}}</span>
                                                    </div>
                                                    <div class="fontcinza">
                                                        <span>Profissional: {{servicosEstabelecimento.apelido}}</span>
                                                    </div>
                                                    <div class="fontpreco">
                                                        <span>{{servicosEstabelecimento.preco| currency : 'BRL'}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="linhahorizontalFiliar1">
                                    </div>
                                </td>

                            </tr>

                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>




    <!-- PASSO 2 -->

    <div *ngIf="passos===1">
        <div class="flex justify-content-center flex-wrap ">
            <img [src]="filename" class="imgRedondaPasso2  " />
        </div>
        <div class="flex justify-content-center flex-wrap ptop">
            <span class="flex align-items-center justify-content-center fontmarronNomeEstabelecimento">{{nomeemp}}</span>
        </div>

        <div class="xl:flex xl:justify-content-center xl:flex-wrap ">
            <div class="xl:col-5">


                <div class="flex justify-content-around  flex-wrap align-items-center ">
                    <div class="linhaMenuAgendar "></div>
                    <div class="circulo flex align-items-center justify-content-center"
                        [ngStyle]="activeIndex==0?{'background-color': 'var(--cor1)'} : {'background-color': 'var(--cor2)'}">
                        <div class="menuProgresso ">1</div>
                        <div class="menuTexto"> Selecinar Profissional</div>
                    </div>

                    <div class="circulo flex align-items-center justify-content-center"
                        [ngStyle]="activeIndex==1?{'background-color': 'var(--cor1)'} : {'background-color': 'var(--cor2)'}">
                        <div class="menuProgresso">2</div>
                        <div class="menuTexto"> Selecionar Serviços </div>
                    </div>
                    <div class="circulo flex align-items-center justify-content-center"
                        [ngStyle]="activeIndex==2?{'background-color': 'var(--cor1)'} : {'background-color': 'var(--cor2)'}">
                        <div class="menuProgresso">3</div>
                        <div class="menuTexto"> Encontrar Data e Horário</div>
                    </div>
                    <div class="circulo flex align-items-center justify-content-cente"
                        [ngStyle]="activeIndex==3?{'background-color': 'var(--cor1)'} : {'background-color': 'var(--cor2)'}">
                        <div class="menuProgresso">4</div>
                        <div class="menuTexto"> Confirmar Agendamento</div>
                    </div>


                </div>
            </div>
        </div>

        <!-- PRIMEIRA ABA DO MENU            -->

        <div *ngIf="activeIndex == 0">


            <div class="xl:flex xl:justify-content-center xl:flex-wrap">
                <div class="xl:col-5">

                    <div class="flex justify-content-center flex-wrap">
                        <div class="fontcinzatituloAgendar col pt-5">
                            <span>Selecionar Profissional</span>
                        </div>
                    </div>
                    <div class="linhahorizontalAgendar">
                    </div>


                    <div *ngIf="teveRespostaProfissional">
                        <div class="col">
                            <p-button id="botaoSpinnerAgendar" label="- Escolher Profissional -" [disabled]="true"
                                class="p-button-text">
                                <i class="pi pi-spin pi-spinner testeAgd flex-order-1" style="font-size: 1rem"></i>
                            </p-button>
                        </div>
                    </div>

                    <div *ngIf="!teveRespostaProfissional">
                        <div class="col">
                            <p-dropdown id="dropAgendar" [options]="profissional" [(ngModel)]="profissionalSelecionado"
                                placeholder="- Escolher Profissional -" optionLabel="apelido"
                                [style]="{'width': '100%'}" scrollHeight="130px"
                                (onChange)="buscarServicoPorCodFuncionario()">


                                <!------------------------------------------PARA ADICIONAR A FOTO DO BARBEIRO NO DROPDOWN ------------------------------------------>


                                <ng-template pTemplate="selectedItem">
                                    <div class="flex align-items-center gap-2" *ngIf="profissionalSelecionado">
                                        <!-- <img *ngIf="profissionalSelecionado.filename !== null" [src]="profissionalSelecionado.filename"  class="fotoBarbeiroSelecionado"/> -->
                                        <div>{{ profissionalSelecionado.apelido }}</div>
                                    </div>
                                </ng-template>
                                <ng-template let-profissional pTemplate="item">
                                    <div class="flex align-items-center gap-2"
                                        [ngStyle]="profissional.filename !== null?{  'padding-right': '4%'} : { 'padding-right': '18%'}">
                                        <img *ngIf="profissional.filename !== null" [src]="profissional.filename"
                                            class="fotoBarbeiroListagem" />
                                        <div>{{ profissional.apelido }}</div>
                                    </div>
                                </ng-template>




                            </p-dropdown>
                        </div>
                    </div>


                    <div class="flex justify-content-end flex-wrap col-11 pt-7 ">
                        <div class=" flex align-items-center justify-content-center ">
                            <button pButton type="button" label="Próximo" class="col-12" id="botaoAgendar"
                                (click)="irPagina2();"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- SEGUNDA ABA DO MENU            -->
        <div *ngIf="activeIndex == 1">
            <div class="xl:flex xl:justify-content-center xl:flex-wrap">
                <div class="xl:col-5">
                    <div class="fontcinzatituloAgendar col pt-5">
                        <span>Serviços</span>
                    </div>
                    <div class="linhahorizontalAgendar pt-1">
                    </div>

                    <div class="scroll-divAgendar ">
                        <table>
                            <tr *ngFor="let servico of servico">
                                <td>
                                    <div class="flex flex-column   align-items-start">
                                        <div class="col tamanhoLinhaTabelaAgendar">
                                            <div class="flex gap-2 ">
                                                <p-checkbox [value]="servico" [(ngModel)]="valorSelecionado"
                                                    (click)="somaTotal();criaTitulo()">
                                                </p-checkbox>
                                                <div class="flex flex-column   align-items-start">
                                                    <div class=" fontmarronAgendar">
                                                        <span>{{servico.nomeprod}} | ({{servico.minutos}} min)</span>
                                                    </div>
                                                    <div class="fontcinzaAgendar">
                                                        <span>{{servico.descrprod}}</span>
                                                    </div>
                                                    <div class="flex align-items-center flex-wrap">
                                                        <div class="formgroup-inline gap-2 pt-1">

                                                        </div>
                                                    </div>
                                                    <div class="fontcinzaAgendar">
                                                        <span class=" fontpreco">{{servico.preco|currency
                                                            :'BRL'}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="xl:col-5"> -->

                                    <div class="linhahorizontalAgendar  pt-1">
                                    </div>
                                    <!-- </div> -->
                                </td>
                            </tr>

                        </table>
                    </div>

                    <!-- <div class="linhahorizontalAgendar pt-1">
                    </div> -->

                    <div class="formgrid grid align-items-center justify-content-center rodape pt-3"
                        [style]="{'gap': '9rem'}">
                        <button pButton type="button" label="Voltar" class="col-12" (click)="voltarPagina()"
                            id="voltar"></button>
                        <button pButton type="button" label="Próximo" class="col-12" (click)="irPagina3()"
                            id="botaoAgendar"></button>
                    </div>

                </div>
            </div>
            <footer id="footerAgendar">
                <div class="col">
                    <div class="col fontmarronnegrito">
                        <span>Total</span>
                    </div>
                    <div clas="flex justify-content-center flex-wrap">
                        <div class="flex align-items-center justify-content-center">
                            <div class="formgroup-inline gap-3">
                                <div class="fontmarrontotal">
                                    <span>Tempo: </span>
                                </div>
                                <div class="fontmarronvalores">
                                    <span>{{totalMin}} Minutos</span>
                                </div>
                            </div>
                        </div>


                        <div class="flex align-items-center justify-content-center">
                            <div class="formgroup-inline gap-3">
                                <div class="fontmarrontotal">
                                    <span>Valor: </span>
                                </div>
                                <div class="fontmarronvalores">
                                    <span>{{total | currency : 'BRL'}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>


        <!-- TERCEIRA ABA DO MENU            -->

        <div *ngIf="activeIndex == 2">
            <div class="xl:flex xl:justify-content-center xl:flex-wrap">
                <div class="xl:col-5">
                    <div class="fontcinzatituloAgendar col pt-5">
                        <span>Selecionar dia</span>
                    </div>
                    <div class="linhahorizontalAgendar">
                    </div>
                    <div class="col">
                        <p-calendar [touchUI]="true" [readonlyInput]="false" inputId="touchui" dateFormat="dd/mm/yy"
                            [minDate]="ontem" [required]="true" [readonlyInput]="true" id="calendarioAgendar"
                            [(ngModel)]="diaSelecionado" (click)="selecionouDia()" placeholder="DD/MM/AAAA">
                        </p-calendar>

                    </div>
                    <div class="fontcinzatituloAgendar col">
                        <span>Selecionar Horário</span>
                    </div>
                    <div class="linhahorizontalAgendar ">
                    </div>

                    <div class="col">
                        <button pButton pRipple type="button" [label]=horarioSelecionado class="p-button-text"
                            [style]="{'width': '100%'}" (click)="horarios()" id="horariosButtonAgendar"
                            [ngStyle]="horarioSelecionado === 'HH:MM'?{  'padding-right': '4%'} : { 'padding-right': '18%'}"></button>
                    </div>

                    <p-dialog id="dialoHor" [(visible)]="mostraHorarios" [modal]="true" header="Horários"
                        [style]="{'width': '350px', 'height': '232px'}">
                        <div class="flex justify-content-center">
                            <div *ngIf="horarioMatriz.length===0">
                                <div class="flex align-items-center justify-content-center">
                                    <div class="flex flex-column sm:flex-row  align-items-center">
                                        <div class="">
                                            <div class="fontEsgotados flex align-items-center justify-content-center">
                                                <img class="iconeSemHorarios" src="./assets/semHorarios.png" />
                                            </div>
                                            <div class="fontEsgotados flex align-items-center justify-content-center">
                                                <span>Hórarios para atendimentos esgotados</span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex align-items-center justify-content-center">
                                <table class="">
                                    <tr *ngFor="let horarioMatriz of horarioMatriz">
                                        <td *ngFor="let horario of horarioMatriz">
                                            <p-button label='{{horario[1].horario }}'
                                                (click)="agendaHorario(horario[1].horario)" id="horarioEscolhido"
                                                [style]="{'background-color': 'var(--cor2)', 'color': 'var(--cor1)'}"
                                                [ngStyle]="horarioSelecionado == horario[1].horario?{  'color': 'var(--cor7)'} : { 'color': 'var(--cor1)'}">
                                            </p-button>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </p-dialog>

                    <div class="formgrid grid align-items-center justify-content-center rodape pt-7"
                        [style]="{'gap': '9rem'}">
                        <button pButton type="button" label="Voltar" class="col-12" (click)="voltarPagina()"
                            id="voltar"></button>
                        <button pButton type="button" label="Próximo" class="col-12" (click)="irPagina4()"
                            id="botaoAgendar"></button>
                    </div>
                </div>
            </div>
        </div>






        <!-- QUARTA ABA DO MENU            -->

        <div *ngIf="activeIndex == 3">
            <div class="xl:flex xl:justify-content-center xl:flex-wrap">
                <div class="xl:col-5">
                    <div class="fontcinzatituloAgendar col pt-5">
                        <span>Resumo do Agendamento</span>
                    </div>
                    <div class="flex flex-column sm:flex-row  align-items-start">
                        <div class="col">
                            <div class="fontcinzaAgendar ">
                                <span> {{nomeemp}} </span>
                            </div>
                            <div class="fontcinzaAgendar ">
                                <span> Profissional: {{nomefunc}}</span>
                            </div>
                            <div class="">
                                <div class="fontcinzaAgendar ">
                                    <span> Serviços: {{titulo}}</span>
                                </div>
                                <!-- <div class="fontcinzaAgendar ">
                                    <span> Valor: {{total | currency : 'BRL'}}</span>
                                </div> -->
                                <div class="fontcinzaAgendar ">
                                    <span> Duração Média: {{totalMin}} Minutos</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-column sm:flex-row  align-items-start">
                        <div class="col">
                            <div class="formgroup-inline align-items-center  gap-6">
                                <div class="fontcinzaAgendar pb-2 ">
                                    <div class="flex gap-2">
                                        <img class="" src="./assets/calendario.svg" />
                                        <div class="flex align-items-center justify-content-center">
                                            <span>{{diaSelecionado | date: 'dd/MM/yyyy'}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="fontcinzaAgendar pb-2 ">
                                    <div class="flex gap-2">
                                        <img class="" src="./assets/relogio.svg" />
                                        <div class="flex align-items-center justify-content-center">
                                            <span> {{horarioSelecionado}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="fontcinzaAgendar pb-2">
                                <div class="flex gap-2">
                                    <img class="" src="./assets/dol.svg" />
                                    <div class="flex align-items-center justify-content-center">
                                        <span> {{this.total| currency:'BRL'}}</span>
                                    </div>
                                </div>
                            </div> -->
                            <div class="">
                                <div class="flex flex-column sm:flex-row  align-items-start">
                                    <div class="fontcinzaAgendar ">
                                        <div class="flex gap-2">
                                            <img class="" src="./assets/localizacao.svg" />
                                            <div class="fontcinzaAgendar pb-2">
                                                <span> {{logradouro}}, {{complemento}} {{numero}}</span>
                                                <div>
                                                    <span> {{bairro}} - {{nomecid}}</span>
                                                </div>
                                                <div>
                                                    <span> {{telefone}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>

                        <div class="flex justify-content-center">
                            <div class="contornoDollar">
                                <img class="" src="./assets/dol.svg" />
                            </div>

                        </div>

                        <div class="col">
                            <div class="contornoResumo col">
                                <div>
                                    <div class="flex justify-content-between pb-1">
                                        <div class="flex align-items-center justify-content-center">
                                            <span class="fontcinzatituloAgendar">Resumo</span>
                                        </div>

                                        <div
                                            class="flex align-items-center justify-content-center fontcinzaValorsResumo">
                                            <span>{{total
                                                | currency : 'BRL'}}</span>

                                        </div>
                                    </div>

                                    <!-- <div class="flex justify-content-between">
                                        <div class="flex align-items-center justify-content-center">
                                            <span class="fontcinzaResumoAgendar">Descontos</span>
                                        </div>

                                        <div class="flex align-items-center justify-content-center">
                                            <span class="fontcinzaValorsResumo font-bold">- {{0 |
                                                currency :
                                                'BRL'}}</span>
                                        </div>
                                    </div> -->

                                    <div class="flex justify-content-between">
                                        <div class="flex align-items-center justify-content-center">
                                            <span class="fontcinzaResumoAgendar">Total</span>
                                        </div>

                                        <div
                                            class="flex align-items-center justify-content-center fontcinzaValorsResumo">
                                            <span class="font-bold">{{total
                                                | currency : 'BRL'}}</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>












                    </div>

                    <!-- <div class="flex flex-column   align-items-start">
                        <div class="col">
                            <div class="fontcinzaAgendar">
                                <div class="flex gap-2">
                                    <p-checkbox [binary]="true" [(ngModel)]="receberNotificacao"></p-checkbox>
                                    <div class="flex align-items-center justify-content-center">
                                        <span>Receber alerta do atendimento</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <div class="col-12">
                        <div class=" formgrid grid align-items-center justify-content-center gap-4">
                            <button pButton type="button" label="Voltar" class="col-12" (click)="voltarPagina()"
                                id="voltar" [style]="{'width':'144px', 'height': '45px'}"></button>
                            <button pButton type="button" label="Confirmar" class="col-12" id="confirmar"
                                (click)="mostraDialogContatoCliente();"></button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>


    <!--                          DIALOG DO CONTATO DO CLIENTE              -->
    <p-dialog header="Dados do Cliente" [(visible)]="showDialogContato" [modal]="true"
        [style]="{'width': '350px', 'height': '370px'}" id="dialogContatoCliente" [draggable]="false">
        <div class="col-12">
            <div class="fontDialogBarbeiro flex align-items-center justify-content-center ">
                <span>Informe seus dados</span>
            </div>
        </div>
        <input type="text" pInputText placeholder="*Nome" [(ngModel)]="nomeCliente" [style]="{width: '100%'}"
            class="mb-1">

        <p-inputMask mask="(99) 99999-9999" [(ngModel)]="telefoneCliente" placeholder="*Telefone"
            [style]="{width: '100%'}">
        </p-inputMask>

        <input type="text" pInputText placeholder="Email" [(ngModel)]="emailCliente" [style]="{width: '100%'}"
            class="mb-1 mt-1">

        <input type="text" [(ngModel)]="cpfCliente" pInputText [mask]=" '000.000.000-00'" placeholder="CPF" class="mt-1"
            [style]="{'width': '100%'}">


        <div *ngIf="!loading" [style]="{width: '100%'}">
            <button pButton type="button" label="Agendar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                    'border-radius': '11px'}" (click)="criaAgenda()"></button>
        </div>
        <div *ngIf="loading" [style]="{width: '100%'}">
            <button id="botaoLoading" pButton [disabled]="true" type="button" label="Agendando" class="col-12 mt-3"
                [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                    'border-radius': '11px'}"></button>
        </div>
    </p-dialog>


    <!-- PASSO 3 RESUMO DO AGENDAMENTO-->

    <div *ngIf="passos===2">
        <div class="flex justify-content-center flex-wrap ">
            <img [src]="filename" class="imgRedondaPasso2  " />
        </div>
        <div class="flex justify-content-center flex-wrap ptop">
            <span class="flex align-items-center justify-content-center fontmarronNomeEstabelecimento">{{nomeemp}}</span>
        </div>

        <div class="pt-1 ">
            <div class="flex justify-content-center flex-wrap pb-3">
                <div lass="flex align-items-center justify-content-center">
                    <div class=" titulonegrito  flex align-items-center justify-content-center">
                        <span>Resumo do agendamento</span>
                    </div>
                </div>
            </div>


            <div class="flex flex-column pb-3">
                <div class="flex justify-content-center flex-wrap ">
                    <div class="col-12 sm-12 md-4 ">
                        <div class="flex justify-content-center flex-wrap ">
                            <div class="xl:col-5">
                                <div class="formgroup-inline justify-content-between flex-wrap">
                                    <div class="negritoResumo">
                                        <span>Local: {{nomeemp}}</span>
                                    </div>
                                    <div class="fontcinzaResumo  ">
                                        <div class="flex ">
                                            <img class="iconeResumo" src="./assets/dol.svg" />
                                            <div class="flex align-items-center justify-content-center">
                                                <span>{{total | currency :
                                                    'BRL'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="formgroup-inline justify-content-between flex-wrap">
                                    <div class="fontcinzaResumo ">
                                        <span>Profissional: {{profissionalSelecionado.apelido}}</span>
                                    </div>
                                    <div class="fontcinzaResumo ">
                                        <div class="flex gap-1">
                                            <img class="iconeResumo" src="./assets/calendario.svg" />
                                            <div class="flex align-items-center justify-content-center">
                                                <span>{{auxDia| date:
                                                    'dd/MM/yyyy'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="formgroup-inline justify-content-between flex-wrap">
                                    <div class="fontcinzaResumo ">
                                        <span>Duração Média: {{totalMin}}
                                            minutos</span>
                                    </div>
                                    <div class="fontcinzaResumo ">
                                        <div class="flex gap-1">
                                            <img class="iconeResumo" src="./assets/relogio.svg" />
                                            <div class="flex align-items-center justify-content-center">
                                                <span>{{horarioSelecionado}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="fontcinzaResumo ">
                                    <span>Serviços: {{titulo}} </span>
                                </div>

                                <div class="fontlocalizacaoResumo pt-1 ">
                                    <div class="flex gap-2">
                                        <img class="iconeResumo" src="./assets/localizacao.svg" />
                                        <div class="flex align-items-center justify-content-center ">
                                            <span>{{logradouro}},
                                                {{complemento}}
                                                ,{{numero}} {{bairro}}
                                                -
                                                {{nomecid}} -
                                                {{telefone}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex justify-content-center flex-wrap ">
            <div class="flex align-items-center justify-content-center">
                <footer class="xl:w-5" id="footerResumo">
                    <p-button label="Novo agendamento" id="botaoResumo" (click)="novoAgendamento()">
                    </p-button>
                </footer>
            </div>
        </div>
    </div>

</body>