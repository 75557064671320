<p-toast position="bottom-center" key="bc"></p-toast>
<p-toast position="top-right" key="tr"></p-toast>
<div class="pt-7">

    <div class="flex flex-column  pb-3">
        <div class="flex align-items-center justify-content-center">
            <img src="./assets/iconeCadastrarCupom.svg" />
        </div>
        <div class=" titulonegritoDescontoTotal flex align-items-center justify-content-center">
            <span>Desconto no total</span>
        </div>

        <div class="flex justify-content-center flex-wrap ">
            <div class="flex align-items-center justify-content-center">
                <div class="scroll-divCadDescTotal">
                    <table id="tabela">
                        <tr *ngFor="let descontoTotal of descontoTotal">
                            <td>
                                <div class="linhahorizontalDescontoTotal pt-1"></div>
                                <div class="col-12 sm-12 md-4 ">
                                    <div class="flex flex-column">

                                        <div
                                            class="flex align-items-center justify-content-center tamanhoLinhaDescontoTotal">
                                            <div class="col "
                                                [ngStyle]="descontoTotal.ativo==='S'?{'opacity': ' 1'} : {'opacity': ' 0.5'}">
                                                <div class="fontTituloDescontoTotal">
                                                    <span class="xl:text-xl">{{descontoTotal.nomedesconto}}</span>
                                                </div>
                                                <div class="formgroup-inline justify-content-between flex-wrap">
                                                    <div class="fontDescDescontoTotal pb-2 ">
                                                        <div class="flex gap-2">
                                                            <div
                                                                class="flex align-items-center justify-content-center gap-1 ">
                                                                <img class="iconeEmail"
                                                                    src="./assets/iconePorcentagem.svg" />
                                                                <span
                                                                    class="xl:text-base maximoCaracter white-space-nowrap overflow-hidden text-overflow-ellipsis">
                                                                    Desconto se aplica quanto o total for >
                                                                    {{descontoTotal.liminf}} e <
                                                                        {{descontoTotal.limsup}} =</span>
                                                                        <span
                                                                            *ngIf="descontoTotal.tipdesc === 'P'">%{{descontoTotal.desc}}
                                                                        </span>
                                                                        <span
                                                                            *ngIf="descontoTotal.tipdesc === 'R'">{{descontoTotal.desc | currency : 'BRL'}}
                                                                        </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="flex align-items-center justify-content-center ">
                                                <img class="cursor-pointer" src="./assets/iconeMenuAdm.png"
                                                    (click)="menu.toggle($event); descontoSelecionado(descontoTotal)" />
                                                <p-menu #menu [popup]="true" [model]="itemsDescontoTotal"
                                                    styleClass="menuDescontoTotal"></p-menu>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <p-confirmDialog #cd [closable]="false" id="confirmDescontoTotal">
                <ng-template pTemplate="footer">
                    <button type="button" pButton icon="pi pi-times" label="Retornar" (click)="cd.reject()"
                        id="confirmBotaoDescontoTotal"></button>
                    <button type="button" pButton icon="pi pi-check" label="Confirmar" (click)="cd.accept()"
                        id="confirmBotaoDescontoTotal"></button>
                </ng-template>
            </p-confirmDialog>
        </div>

        <div class="flex justify-content-center flex-wrap ">
            <div class="flex align-items-center justify-content-center">
                <footer class="xl:w-5" id="footerDescontoTotal">
                    <p-button label="Cadastrar" id="botaoDescontoTotal" [style]="{width: '100%'}"
                        (click)="mostraDialogDescontoTotal()">
                    </p-button>
                </footer>
            </div>
        </div>
    </div>

    <!--                          DIALOG DE CRIAÇÃO DO DESCONTO             -->
    <p-dialog header="Desconto total" [(visible)]="showDescontoTotal" [modal]="true"
        [breakpoints]="{'960px': '339px', '640px': '327px'}" id="dialogDescontoTotal">
        <div class="flex justify-content-center flex-wrap col">
            <div class="fontDialogDescontoTotal flex align-items-center justify-content-center">
                <span>Informações sobre o desconto no total</span>
            </div>
        </div>

        <input type="text" [(ngModel)]="nomeDesconto" pInputText placeholder="Nome da faixa" [style]="{width: '100%'}"
            class="mt-2">

        <p-dropdown [options]="estabelecimento" [(ngModel)]="estabelecimentoSelecionado" placeholder="Estabelecimento" optionLabel="nome"
            optionValue="codemp" [style]="{'width': '100%'}" id="dropDesconto" scrollHeight="80px"
            (onChange)="buscarProfissionalsPorCodEmp()">
        </p-dropdown>

        <div *ngIf="teveRespostaEstabelecimento" class="">
            <p-button id="botaoSpinner" label="Profissional" [disabled]="true" class="p-button-text">
                <i class="pi pi-spin pi-spinner teste flex-order-1" style="font-size: 1rem"></i>
            </p-button>
        </div>

        <div *ngIf="!teveRespostaEstabelecimento" class="">
            <p-dropdown [options]="profissional" [(ngModel)]="profissionalSelecionado" placeholder="Profissional"
                optionLabel="nomefunc" optionValue="codfunc" [style]="{'width': '100%'}" id="dropDesconto"
                scrollHeight="80px">
            </p-dropdown>
        </div>

        <div class="flex justify-content-start flex-wrap fontContadorCaracter pt-1 col"><span>Desconto se aplica quanto
                for:</span>
        </div>

        <div class="grid p-fluid col">
            <div class="w-6 ">
                <p-inputNumber [(ngModel)]="faixaMaiorQue" [min]="0" placeholder="Maior que?"> </p-inputNumber>
            </div>
            <div class="w-6">
                <p-inputNumber [(ngModel)]="faixaMenorQue" [min]="0" placeholder="Menor que?"> </p-inputNumber>
            </div>

        </div>

        <div class="grid">
            <div class="col">
                <div class="grid  col">
                    <div class="w-6 ">
                        <p-dropdown [options]="tipoCupom" [(ngModel)]="tipdesc" placeholder="Tipo" optionLabel="opcao"
                            optionValue="tipo" id="dropTipoCupom" scrollHeight="80px" [style]="{width: '100%'}"
                            (onChange)="zeraCampoDesconto()">
                        </p-dropdown>
                    </div>
                    <div class="w-6">
                        <div *ngIf="tipdesc==='P'">
                            <!-- <p-inputMask mask="%99" placeholder="Desconto" [style]="{width: '100%'}" inputmode="numeric"
                                [(ngModel)]="desc" [max]="100"></p-inputMask> -->
                            <p-inputNumber [(ngModel)]="desc" inputId="percent" prefix="%" placeholder="Desconto"
                                id="CampoDesconto" [min]="0" [max]="99"> </p-inputNumber>
                        </div>
                        <div *ngIf="tipdesc==='R'">
                            <!-- <p-inputMask mask="R$99,99" placeholder="Desconto" [style]="{width: '100%'}" inputmode="numeric"
                                [(ngModel)]="desc"></p-inputMask> -->
                            <p-inputNumber [(ngModel)]="desc" inputId="currency-br" mode="currency" currency="BRL"
                                locale="pt-BR" id="CampoDesconto" [min]="0"> </p-inputNumber>
                        </div>
                        
                        <div *ngIf="tipdesc===''">
                            <!-- <p-inputMask mask="R$99,99" placeholder="Desconto" [style]="{width: '100%'}" inputmode="numeric"
                                [(ngModel)]="desc"></p-inputMask> -->
                            <p-inputNumber [(ngModel)]="descVazio" placeholder="Desconto" id="CampoDesconto" [min]="0">
                            </p-inputNumber>
    
                        </div>
                    </div>

                </div>
            </div>
        </div>


        <div *ngIf="!loading" [style]="{width: '100%'}">
            <button pButton type="button" label="Salvar" class="col-12 " [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
        'border-radius': '11px'}" (click)="criaDesconto()"></button>
        </div>
        <div *ngIf="loading" [style]="{width: '100%'}">
            <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 " [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
        'border-radius': '11px'}"></button>
        </div>
    </p-dialog>


    <!--                          DIALOG ATIVA/DESATIVA             -->

    <p-dialog header="Ativar/ Desativar Desconto" [(visible)]="showAtivarDesativarDesconto" [modal]="true"
        [style]="{'width': '350px', 'height': '240px'}" id="dialogAtvDst">
        <div class="flex justify-content-center flex-wrap col">
            <div class="fontDialogDescontoTotal flex align-items-center justify-content-center">
                <span>Se ativo, o cupom estará disponível para ser utilizado</span>
            </div>
        </div>

        <div class="p-field-radiobutton pb-1">
            <p-radioButton value="S" [(ngModel)]="radioButtonSelecionado"></p-radioButton>
            <label class="pl-2">Ativar desconto</label>
        </div>
        <div class="p-field-radiobutton pb-1">
            <p-radioButton value="N" [(ngModel)]="radioButtonSelecionado"></p-radioButton>
            <label class="pl-2">Desativar desconto</label>
        </div>




        <div *ngIf="!loading" [style]="{width: '100%'}">
            <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
    'border-radius': '11px'}" (click)="ativaDesativaCupom()"></button>
        </div>
        <div *ngIf="loading" [style]="{width: '100%'}">
            <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 mt-3"
                [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
    'border-radius': '11px'}"></button>
        </div>
    </p-dialog>