<div class="pt-7">


    <div class="flex flex-column  pb-3">
        <div class="flex align-items-center justify-content-center">
            <img src="./assets/iconeConfigFinanceiro.svg" class="iconeConfig" />
        </div>
        <div class="titulonegritoConfig flex align-items-center justify-content-center">
            <span>Configuração Financeira</span>
        </div>
    </div>


    <div class="flex justify-content-center flex-wrap ">


        <div class="xl:col-5 lg:col-5 md:col-5 sm:col-5">
            <div class="shadow-4">


                <p-card header="Financeiro" id="cardConfig">
                    <div>
                        <div class="formgroup-inline justify-content-between flex-wrap pb-2" routerLink="/contas">
                            <div class="flex gap-2 ">
                                <img src="./assets/iconeMenuContas.svg" class=" xl:w-3rem" />
                                <div class="flex flex-column align-items-start">
                                    <div class=" fontTituloConfig">
                                        <span class="xl:text-xl">Contas</span>
                                    </div>
                                    <div class="fontCinzaConfig ">
                                        <span class="xl:text-base">Será as contas usadas no seu financeiro</span>
                                    </div>
                                </div>
                            </div>
                            <div class="align-items-center justify-content-center   ">
                                <img src="./assets/imgBarbeariaPopup.png" class="iconePopUpBarbearia " />
                            </div>
                        </div>

                        <div class="formgroup-inline justify-content-between flex-wrap pb-2" routerLink="/categorias">
               
                            <div class="flex gap-2 ">
                                <img src="./assets/iconeMenuCategorias.svg" class=" xl:w-3rem" />
                                <div class="flex flex-column  align-items-start">
                                    <div class=" fontTituloConfig">
                                        <span class="xl:text-xl">Categorias </span>
                                    </div>
                                    <div class="fontCinzaConfig ">
                                        <span class="xl:text-base">Será as categorias usadas no seu financeiro</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex align-items-center justify-content-center ">
                                <img src="./assets/imgBarbeariaPopup.png" class="iconePopUpBarbearia" />
                            </div>

                           
                        </div>

                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"  routerLink="/tipoNegociacao">
               
                            <div class="flex gap-2 ">
                                <img src="./assets/iconeTipoNegociacao.svg" class=" xl:w-3rem" />
                                <div class="flex flex-column align-items-start">
                                    <div class="fontTituloConfig">
                                        <span class="xl:text-xl">Tipo de Negociação</span>
                                    </div>
                                    <div class="fontCinzaConfig ">
                                        <span class="xl:text-base">Meios e formas de pagamentos</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex align-items-center justify-content-end  ">
                                <img src="./assets/imgBarbeariaPopup.png" class="iconePopUpBarbearia" />
                            </div>

                           
                        </div>

                        <div class="formgroup-inline justify-content-between flex-wrap"  routerLink="/configuracaoBaixa">
               
                            <div class="flex gap-2 ">
                                <img src="./assets/iconeBaixaAutomatica.svg" class=" xl:w-3rem" />
                                <div class="flex flex-column align-items-start">
                                    <div class="fontTituloConfig">
                                        <span class="xl:text-xl">Baixa automática</span>
                                    </div>
                                    <div class="fontCinzaConfig ">
                                        <span class="xl:text-base">Baixar agendamentos automaticamente?</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex align-items-center justify-content-end  ">
                                <img src="./assets/imgBarbeariaPopup.png" class="iconePopUpBarbearia" />
                            </div>

                           
                        </div>

                    </div>
                </p-card>
            </div>
        </div>

    </div>
</div>