import { MetricasService } from './../metricas.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core'; //TRADUÇÃO
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';//TRADUÇÃO
import { EstabelecimentoService } from 'src/estabelecimento.service';
import { Estabelecimento } from 'src/assets/estabelecimento';
import { AlertService } from 'src/alert.service';
import { UtilitariosService } from 'src/assets/utilitarios.service';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { TraducaoService } from 'src/traducao.service';
import { environment } from 'src/environments/environment';

declare var cordova: any;

@Component({
  selector: 'app-relatorios',
  templateUrl: './relatorios.component.html',
  styleUrls: ['./relatorios.component.scss'],
  providers: [MessageService],
  encapsulation: ViewEncapsulation.None
})
export class RelatoriosComponent implements OnInit {

  lang: string = "en";
  //subscription: Subscription;

  /**DATA */
  data: Date;
  hoje: Date;
  dataIni: Date;
  dataFin: Date;
  //dataMaior: Date;


  /**VARIAVEIS DOS GRAFICOS */
  graficoBarra: any;
  graficoBarraDeitado: any;
  graficoBarraEmPe: any;
  graficoDonut: any;
  graficoEmpilhado: any;
  optionBarra: any;
  optionEmpilhado: any;
  optionDonut: any;
  optionsPizza: any;
  optionBarraDeitado: any;
  optionBarraEmPe: any;



  dataPizza: any;

  /**VARIAVEIS DO COMPONENT */
  opcoes: any[];
  opcoesDonut: any[];
  opcaoSelecionada: string = 'R';
  opcaoSelecionadaDonut: string = 'R';
  opcoesOcorrencia: any[];
  opcoesTipNeg: any[];
  opcaoSelecionadaTipNeg: string = 'R';
  opcaoSelecionadaOcorrencia: string = 'R';
  horizontalOptions: any;
  showAlterar: boolean = false;
  valorSelecionadoAlterar: string = '';
  dataInicial: any = '';
  dataFinal: any;
  liberaCalendario1: boolean = true;
  liberaCalendario2: boolean = true;
  loading: boolean = false;
  estabelecimento: Estabelecimento = {}
  receita: number = 0;
  qtdAgendamentos: number = 0;
  difCli: number = 0;
  positivacao: number = 0;
  ticketMedio: number = 0;
  qtdClienteAtivos: number = 0
  auxDiaIni: any;
  auxDiaFin: any;
  dadosGeraisTop20: any;
  dadosGeraisProfissionais: any;
  dadosGeraisDiaSemana: any;
  dadosGeraisTipNeg: any;
  dadosGeraisTop5: any;
  nomeEstabelecimento: string = '';
  dataMostrada: any;
  prefixoData: string = '';
  showCarregando: boolean = false;
  codusuresp: string = '';
  dadosGeraisComparativoOcorrencias: any;

  resMobile: boolean = false;
  resPc: boolean = false;

  constructor(public translate: TranslateService, public primeNGConfig: PrimeNGConfig, private metricasService: MetricasService, private estabelecimentoService: EstabelecimentoService,
    private alertService: AlertService, private messageService: MessageService, private decodedTokenService: DecodedTokenService, private utilitariosService: UtilitariosService,
    private traducaoSerice: TraducaoService) {
    /**TRADUÇÃO */
    this.traducaoSerice.changeLang();
    // translate.addLangs(['en', 'pt']);
    // translate.setDefaultLang('pt');
    // const browserLang = translate.getBrowserLang();
    // let lang = browserLang!.match(/en|pt/) ? browserLang : 'pt';
    // this.changeLang(lang!);
    // this.subscription = this.translate.stream('primeng').subscribe(data => {
    //   this.primeNGConfig.setTranslation(data);
    // });



    this.opcoes = [{ 'opcao': 'R', 'nome': 'R$' },
    { 'opcao': 'Q', 'nome': 'Qtd. Serviço' },
    { 'opcao': 'T', 'nome': 'Tempo' }
    ]

    this.opcoesDonut = [{ 'opcao': 'R', 'nome': 'R$' },
    { 'opcao': 'Q', 'nome': 'Qtd. Serviço' },
    { 'opcao': 'T', 'nome': 'Tempo' }
    ]

    this.opcoesOcorrencia = [{ 'opcao': 'R', 'nome': 'R$' },
    { 'opcao': 'Q', 'nome': 'Qtd. Ocorrência' },
    { 'opcao': 'T', 'nome': 'Tempo' }
    ]

    this.opcoesTipNeg = [{ 'opcao': 'R', 'nome': 'Receita' },
    { 'opcao': 'D', 'nome': 'Despesa' }
    ]


    this.data = new Date();
    this.hoje = new Date(this.data);

    /**VARIAVEIS DO PERIODO A SER ESCOLHIDO */
    this.dataIni = new Date(this.data);
    this.dataFin = new Date(this.data);

  }

  ngOnInit(): void {
    this.recuperaCodUsu();
    this.opcaoSelecionada = this.opcoes[0].opcao;
    this.opcaoSelecionadaDonut = this.opcoesDonut[0].opcao;
    this.recuperaEstabelecimento();
    this.escolhePeriodo();
    this.checaResolucao();
    /**GRAFICOS*/


    this.optionBarra = {
      plugins: {
        legend: {
          labels: {
            color: '#432611'
          }
        }
      }
    };

    this.optionDonut = {
      plugins: {
        legend: {
          position: 'right',
          labels: {
            color: '#432611'
          }
        }
      }
    }

    this.optionsPizza = {
      plugins: {
        legend: {
          position: 'right',
          labels: {
            //usePointStyle: true,
            color: '#432611'
          }
        }
      }
    };

    this.optionBarraDeitado = {
      indexAxis: 'y',
      plugins: {
        legend: {
          labels: {
            color: '#432611'
          }
        }
      }
    };

    this.optionBarraEmPe = {
      //indexAxis: 'y',
      plugins: {
        legend: {
          labels: {
            color: '#432611'
          }
        }
      }
    };

    this.optionEmpilhado = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        tooltips: {
          mode: 'index',
          intersect: false
        },
        legend: {
          labels: {
            color: '#432611'
          }
        }
      },
      scales: {
        x: {
          stacked: true,
          ticks: {
            color: '#432611'
          },
          grid: {
            color: '#BFA58E',
            drawBorder: false
          }
        },
        y: {
          stacked: true,
          ticks: {
            color: '#432611'
          },
          grid: {
            color: '#BFA58E',
            drawBorder: false
          }
        }
      }
    }

  }

  recuperaCodUsu() {
    this.codusuresp = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }

  /**TRADUÇÃO */
  changeLang(lang: string) {
    this.translate.use(lang);
  }

  /**PLOTA O GRAFICO DO DADOS DE RENDA POR TIPO DE NEGOCIACAO */
  buscaDadosPorTipoNegociacao() {
    this.metricasService.buscaDadosGeraisPorTipoNeg(this.auxDiaIni, this.auxDiaFin, Number(this.estabelecimento.codemp), this.codusuresp).subscribe((res) => {
      console.log(res);
      this.dadosGeraisTipNeg = res;

      this.selecionaTipoNegociacao();
    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
        this.alertService.alert('Algo deu errado.', 'error');
      })
  }


  selecionaTipoNegociacao() {
    let tipoNegociacao = [];
    let dados1 = [];
    let dados2 = [];


    if (this.dadosGeraisTipNeg.length > 0) {
      for (let i = 0; i < this.dadosGeraisTipNeg.length; i++) {
        if (this.opcaoSelecionadaTipNeg === "R" && this.dadosGeraisTipNeg[i].tipo === "R") {
          tipoNegociacao.push(this.dadosGeraisTipNeg[i].tipnegdescr);
          dados1.push(this.dadosGeraisTipNeg[i].vlrbaixado);
          dados2.push(this.dadosGeraisTipNeg[i].vlrpendente);
        }
        if (this.opcaoSelecionadaTipNeg === "D" && this.dadosGeraisTipNeg[i].tipo === "D") {
          tipoNegociacao.push(this.dadosGeraisTipNeg[i].tipnegdescr);
          dados1.push(this.dadosGeraisTipNeg[i].vlrbaixado);
          dados2.push(this.dadosGeraisTipNeg[i].vlrpendente);
        }
        // dados1.push(this.dadosGeraisTipNeg[i].vlrbaixado);
        // dados2.push(this.dadosGeraisTipNeg[i].vlrpendente);
      }
    }

    this.graficoEmpilhado = {
      labels: tipoNegociacao,
      datasets: [
        { label: 'Valor baixado', backgroundColor: '#432611', data: dados1 },
        { label: 'Valor pendente', backgroundColor: '#BFA58E', data: dados2 },
      ]
    };
  }


  /**PLOTA O GRAFICO DO DADOS DE RENDA QUE O PROFISSIONAL GERAL */
  buscaDadosProfissionais() {
    this.metricasService.buscaDadosGeraisProfissionaisPorCodEmp(this.auxDiaIni, this.auxDiaFin, Number(this.estabelecimento.codemp), this.codusuresp).subscribe((res) => {
      this.dadosGeraisProfissionais = res;
      console.log(this.dadosGeraisProfissionais)
      let profissionais = [];

      let dados1 = [];
      let dados2 = [];
      let dados3 = [];

      if (this.dadosGeraisProfissionais.length > 0) {
        for (let i = 0; i < this.dadosGeraisProfissionais.length; i++) {
          profissionais.push(this.dadosGeraisProfissionais[i].apelido);
          dados1.push(this.dadosGeraisProfissionais[i].vlrtot);
          dados2.push(this.dadosGeraisProfissionais[i].qtd);
          dados3.push(this.dadosGeraisProfissionais[i].tempom);
        }
      }
      this.graficoBarraDeitado = {
        labels: profissionais,
        datasets: [
          { label: 'R$', backgroundColor: '#432611', data: dados1 },
          { label: 'Quantidade', backgroundColor: '#BFA58E', data: dados2 },
          { label: 'Tempo(min)', backgroundColor: 'rgba(67, 38, 17, 0.8)', data: dados3 }
        ]
      };

    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
        this.alertService.alert('Algo deu errado.', 'error');
      })
  }



  /**MOSTRA O TIPO DE GRAFICO SELECIONADO*/
  selecionaTipoBarras() {
    console.log(this.opcaoSelecionada)

    this.metricasService.buscaDadosGeraisTop20ClientesPorCodEmp(this.auxDiaIni, this.auxDiaFin, Number(this.estabelecimento.codemp), this.opcaoSelecionada, this.codusuresp).subscribe((res) => {
      console.log(res);

      this.dadosGeraisTop20 = res;
      console.log(this.dadosGeraisTop20.length);

      let clientes = [];
      let dados = [];
      let legenda;

      if (this.dadosGeraisTop20.length > 0) {
        for (let i = 0; i < this.dadosGeraisTop20.length; i++) {
          clientes.push(this.dadosGeraisTop20[i].nomeusu);
          if (this.opcaoSelecionada === "R") {
            legenda = 'R$'
            dados.push(this.dadosGeraisTop20[i].vlrtot);
          }
          if (this.opcaoSelecionada === "Q") {
            legenda = 'Quantidade'
            dados.push(this.dadosGeraisTop20[i].qtd);
          }
          if (this.opcaoSelecionada === "T") {
            legenda = 'Tempo(min)'
            dados.push(this.dadosGeraisTop20[i].tempom);
          }
        }
      } else {
        if (this.opcaoSelecionada === "R") {
          legenda = 'R$'
        }
        if (this.opcaoSelecionada === "Q") {
          legenda = 'Quantidade'
        }
        if (this.opcaoSelecionada === "T") {
          legenda = 'Tempo(min)'
        }
      }




      this.graficoBarra = {
        labels: clientes,
        datasets: [
          {
            label: legenda,
            backgroundColor: '#432611',
            data: dados
          }
        ]
      };
    })
  }

  /**GRAFICO DE DESEMPENHO POR DIA DA SEMANA*/
  buscaDadosDiaSemana() {
    this.metricasService.buscaDadosGeraisPorDiaSemana(this.auxDiaIni, this.auxDiaFin, Number(this.estabelecimento.codemp), this.codusuresp).subscribe((res) => {
      this.dadosGeraisDiaSemana = res;
      console.log(this.dadosGeraisDiaSemana)
      let diaSemana = [];

      let dados1 = [];
      let dados2 = [];
      let dados3 = [];

      if (this.dadosGeraisDiaSemana.length > 0) {
        for (let i = 0; i < this.dadosGeraisDiaSemana.length; i++) {
          diaSemana.push(this.dadosGeraisDiaSemana[i].diasem);
          dados1.push(this.dadosGeraisDiaSemana[i].vlrtot);
          dados2.push(this.dadosGeraisDiaSemana[i].qtd);
          dados3.push(this.dadosGeraisDiaSemana[i].tempom);
        }
      }
      this.graficoBarraEmPe = {
        labels: diaSemana,
        datasets: [
          { label: 'R$', backgroundColor: '#432611', data: dados1 },
          { label: 'Quantidade', backgroundColor: '#BFA58E', data: dados2 },
          { label: 'Tempo(min)', backgroundColor: 'rgba(67, 38, 17, 0.8)', data: dados3 }
        ]
      };

    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
        this.alertService.alert('Algo deu errado.', 'error');
      })


  }

  selecionaTipoDonut() {

    this.metricasService.buscaDadosGeraisTop5ServicosPorCodEmp(this.auxDiaIni, this.auxDiaFin, Number(this.estabelecimento.codemp), this.opcaoSelecionadaDonut, this.codusuresp).subscribe((res) => {
      console.log(res)

      this.dadosGeraisTop5 = res;

      let servicos = [];
      let dados = [];
      let legenda;



      if (this.dadosGeraisTop5.length > 0) {
        for (let i = 0; i < this.dadosGeraisTop5.length; i++) {
          servicos.push(this.dadosGeraisTop5[i].nomeprod);
          if (this.opcaoSelecionadaDonut === "R") {
            legenda = 'R$'
            dados.push(this.dadosGeraisTop5[i].sum);
          }
          if (this.opcaoSelecionadaDonut === "Q") {
            legenda = 'Quantidade'
            dados.push(this.dadosGeraisTop5[i].qtd);
          }
          if (this.opcaoSelecionadaDonut === "T") {
            legenda = 'Tempo(min)'
            dados.push(this.dadosGeraisTop5[i].tempom);
          }
        }
      } else {
        if (this.opcaoSelecionada === "R") {
          legenda = 'R$'
        }
        if (this.opcaoSelecionada === "Q") {
          legenda = 'Quantidade'
        }
        if (this.opcaoSelecionada === "T") {
          legenda = 'Tempo(min)'
        }
      }
      this.graficoDonut = {
        labels: servicos,
        datasets: [
          {
            label: legenda,
            backgroundColor: ['#BFA58E', '#432611', 'rgba(67, 38, 17, 0.8)', '#A0A0A0', '#DCD3CB'],
            data: dados
          }
        ]
      };
    })
  }

  buscaDadosGeraisComparativo() {
    this.metricasService.buscaDadosGeraisComparativoPorCodEmp(this.auxDiaIni, this.auxDiaFin, Number(this.estabelecimento.codemp), this.codusuresp).subscribe((res) => {
      // let eventos = [];
      // let dados = [];
      this.dadosGeraisComparativoOcorrencias = res;

      this.selecionaTipoOcorrencia();

    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
        this.alertService.alert('Algo deu errado.', 'error');
      });
  }

  selecionaTipoOcorrencia() {
    let eventos = [];
    let dados = [];

    if (this.opcaoSelecionadaOcorrencia === 'R') {
      for (let i = 0; i < this.dadosGeraisComparativoOcorrencias.length; i++) {
        eventos.push(this.dadosGeraisComparativoOcorrencias[i].evento);
        dados.push(this.dadosGeraisComparativoOcorrencias[i].vlr);
      }

      this.dataPizza = {
        labels: eventos,
        datasets: [
          {
            label: 'R$',
            data: dados,
            backgroundColor: ['#BFA58E', '#432611', 'rgba(67, 38, 17, 0.8)', '#9c734f', '#A0A0A0', '#DCD3CB'],
            // hoverBackgroundColor: [documentStyle.getPropertyValue('--blue-400'), documentStyle.getPropertyValue('--yellow-400'), documentStyle.getPropertyValue('--green-400')]
          }
        ]
      };
    }

    if (this.opcaoSelecionadaOcorrencia === 'Q') {
      for (let i = 0; i < this.dadosGeraisComparativoOcorrencias.length; i++) {
        eventos.push(this.dadosGeraisComparativoOcorrencias[i].evento);
        dados.push(this.dadosGeraisComparativoOcorrencias[i].qtdocorrencia);
      }

      this.dataPizza = {
        labels: eventos,
        datasets: [
          {
            label: 'Quantidade',
            data: dados,
            backgroundColor: ['#BFA58E', '#432611', 'rgba(67, 38, 17, 0.8)', '#9c734f', '#A0A0A0', '#DCD3CB'],
            // hoverBackgroundColor: [documentStyle.getPropertyValue('--blue-400'), documentStyle.getPropertyValue('--yellow-400'), documentStyle.getPropertyValue('--green-400')]
          }
        ]
      };
    }

    if (this.opcaoSelecionadaOcorrencia === 'T') {
      for (let i = 0; i < this.dadosGeraisComparativoOcorrencias.length; i++) {
        eventos.push(this.dadosGeraisComparativoOcorrencias[i].evento);
        dados.push(this.dadosGeraisComparativoOcorrencias[i].minutos);
      }

      this.dataPizza = {
        labels: eventos,
        datasets: [
          {
            label: 'Tempo(min)',
            data: dados,
            backgroundColor: ['#BFA58E', '#432611', 'rgba(67, 38, 17, 0.8)', '#9c734f', '#A0A0A0', '#DCD3CB'],
            // hoverBackgroundColor: [documentStyle.getPropertyValue('--blue-400'), documentStyle.getPropertyValue('--yellow-400'), documentStyle.getPropertyValue('--green-400')]
          }
        ]
      };
    }
  }

  mostraDialogAlterar() {
    this.showAlterar = true;
  }



  clickOutroPeriodo() {
    console.log(this.valorSelecionadoAlterar)
    if (this.valorSelecionadoAlterar === 'outroPeriodo') {
      console.log('erwgferg')
      this.liberaCalendario1 = false;
      console.log(this.liberaCalendario1)
    } else {
      this.liberaCalendario1 = true;
      this.liberaCalendario2 = true;
      this.dataInicial = '';
      this.dataFinal = '';
    }
  }
  clickCalendario1() {
    if (this.dataInicial !== '') {
      this.liberaCalendario2 = false;
      console.log(this.dataInicial)
    }
  }

  recuperaEstabelecimento() {
    this.estabelecimento = this.estabelecimentoService.getestabelecimento();
    this.nomeEstabelecimento = this.estabelecimento.nome!;
    console.log(this.estabelecimento)
  }


  escolhePeriodo() {
    this.showCarregando = true;
    this.loading = true;

    console.log(this.valorSelecionadoAlterar)
    if (this.valorSelecionadoAlterar === '') {
      this.valorSelecionadoAlterar = 'hoje';
    }

    // if (this.utilitariosService.validaCampo([this.valorSelecionadoAlterar])) {
    //   this.showError('Atenção', 'Selecione um período.')
    //   this.loading = false;
    //   return;
    // }

    if (this.utilitariosService.validaCampo([this.dataInicial, this.dataFinal]) && this.valorSelecionadoAlterar === "outroPeriodo") {
      this.showError('Atenção', 'Selecione o período das datas.')
      this.loading = false;
      this.showCarregando = false;
      return;
    }




    /********************************************************************************
   *                                      HOJE                                      *
   ********************************************************************************/
    if (this.valorSelecionadoAlterar === "hoje") {

      console.log('gregertgergerge')
      /**FAZ A DATA INICIAL E FINAL SER HOJE*/
      this.dataIni = new Date(this.data);
      this.dataFin = new Date(this.data);

      this.prefixoData = 'Hoje | ';
      this.dataMostrada = this.dataIni;

      // this.auxDiaIni = this.dataIni.getFullYear() + '-' + ('0' + (this.dataIni.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataIni.getDate()).slice(-2);
      // this.auxDiaFin = this.dataFin.getFullYear() + '-' + ('0' + (this.dataFin.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataFin.getDate()).slice(-2);
      this.auxDiaIni = this.utilitariosService.ajustaDataBanco(this.dataIni);
      this.auxDiaFin = this.utilitariosService.ajustaDataBanco(this.dataFin);

      this.metricasService.buscaDadosGeraisCardPorCodEmp(this.auxDiaIni, this.auxDiaFin, Number(this.estabelecimento.codemp), this.codusuresp).subscribe((res) => {
        console.log(res);

        if (res.length) {
          this.positivacao = Object(res)[0].positivacao;
          this.receita = Object(res)[0].receita;
          this.qtdAgendamentos = Object(res)[0].qtd_agendamentos;
          this.ticketMedio = Object(res)[0].ticket_medio;
          this.qtdClienteAtivos = Object(res)[0].qtd_cli_ativos;
          this.difCli = Object(res)[0].dif_cli;
        } else {
          this.positivacao = 0;
          this.receita = 0;
          this.qtdAgendamentos = 0;
          this.ticketMedio = 0;
          this.qtdClienteAtivos = 0;
          this.difCli = 0;
        }

        /**BUSCA OS DADOS DOS GRAFICOS APOS SELECIONAR AS DATAS */
        this.selecionaTipoBarras();
        this.selecionaTipoDonut();
        this.buscaDadosGeraisComparativo();

        this.showAlterar = false;
        this.loading = false;
        this.showCarregando = false;

      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.loading = false;
          this.showAlterar = false;
          this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')
        });
    }

    /********************************************************************************
    *                                      ONTEM                                    *
    ********************************************************************************/

    if (this.valorSelecionadoAlterar === "ontem") {
      /**FAZ A DATA INICIAL SER ONTEM*/
      this.dataIni = new Date(this.data);
      this.dataIni.setDate(this.data.getDate() - 1);

      this.prefixoData = 'Ontem | ';
      this.dataMostrada = this.dataIni;

      // this.auxDiaIni = this.dataIni.getFullYear() + '-' + ('0' + (this.dataIni.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataIni.getDate()).slice(-2);
      // this.auxDiaFin = this.dataFin.getFullYear() + '-' + ('0' + (this.dataFin.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataFin.getDate()).slice(-2);
      this.auxDiaIni = this.utilitariosService.ajustaDataBanco(this.dataIni);
      this.auxDiaFin = this.utilitariosService.ajustaDataBanco(this.dataFin);
      console.log(this.auxDiaIni)
      console.log(this.auxDiaFin)
      console.log(this.estabelecimento.codemp)
      this.metricasService.buscaDadosGeraisCardPorCodEmp(this.auxDiaIni, this.auxDiaFin, Number(this.estabelecimento.codemp), this.codusuresp).subscribe((res) => {
        console.log(res);

        if (res.length) {
          this.positivacao = Object(res)[0].positivacao;
          this.receita = Object(res)[0].receita;
          this.qtdAgendamentos = Object(res)[0].qtd_agendamentos;
          this.ticketMedio = Object(res)[0].ticket_medio;
          this.qtdClienteAtivos = Object(res)[0].qtd_cli_ativos;
          this.difCli = Object(res)[0].dif_cli;
        } else {
          this.positivacao = 0;
          this.receita = 0;
          this.qtdAgendamentos = 0;
          this.ticketMedio = 0;
          this.qtdClienteAtivos = 0;
          this.difCli = 0;
        }
        /**BUSCA OS DADOS DOS GRAFICOS APOS SELECIONAR AS DATAS */
        this.selecionaTipoBarras();
        this.selecionaTipoDonut();
        this.buscaDadosGeraisComparativo();
        this.showAlterar = false;
        this.loading = false;
        this.showCarregando = false;
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.loading = false;
          this.showAlterar = false;
          this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')
        });
    }

    /********************************************************************************
    *                                      7 DIAS                                    *
    ********************************************************************************/
    if (this.valorSelecionadoAlterar === "7Dias") {
      /**FAZ A DATA INICIAL SER 7 DIAS ATRAS*/
      this.dataIni = new Date(this.data);
      this.dataIni.setDate(this.data.getDate() - 7);

      this.prefixoData = 'Últimos 7 dias';
      this.dataMostrada = '';

      // this.auxDiaIni = this.dataIni.getFullYear() + '-' + ('0' + (this.dataIni.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataIni.getDate()).slice(-2);
      // this.auxDiaFin = this.dataFin.getFullYear() + '-' + ('0' + (this.dataFin.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataFin.getDate()).slice(-2);
      this.auxDiaIni = this.utilitariosService.ajustaDataBanco(this.dataIni);
      this.auxDiaFin = this.utilitariosService.ajustaDataBanco(this.dataFin);

      this.metricasService.buscaDadosGeraisCardPorCodEmp(this.auxDiaIni, this.auxDiaFin, Number(this.estabelecimento.codemp), this.codusuresp).subscribe((res) => {
        console.log(res);


        if (res.length) {
          this.positivacao = Object(res)[0].positivacao;
          this.receita = Object(res)[0].receita;
          this.qtdAgendamentos = Object(res)[0].qtd_agendamentos;
          this.ticketMedio = Object(res)[0].ticket_medio;
          this.qtdClienteAtivos = Object(res)[0].qtd_cli_ativos;
          this.difCli = Object(res)[0].dif_cli;
        } else {
          this.positivacao = 0;
          this.receita = 0;
          this.qtdAgendamentos = 0;
          this.ticketMedio = 0;
          this.qtdClienteAtivos = 0;
          this.difCli = 0;
        }
        /**BUSCA OS DADOS DOS GRAFICOS APOS SELECIONAR AS DATAS */
        this.selecionaTipoBarras();
        this.selecionaTipoDonut();
        this.buscaDadosGeraisComparativo();
        this.showAlterar = false;
        this.loading = false;
        this.showCarregando = false;
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.loading = false;
          this.showAlterar = false;
          this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')
        });
    }


    /********************************************************************************
    *                                      15 DIAS                                   *
    ********************************************************************************/

    if (this.valorSelecionadoAlterar === "15Dias") {
      /**FAZ A DATA INICIAL SER 15 DIAS ATRAS*/
      this.dataIni = new Date(this.data);
      this.dataIni.setDate(this.data.getDate() - 15);

      this.prefixoData = 'Últimos 15 dias';
      this.dataMostrada = '';

      // this.auxDiaIni = this.dataIni.getFullYear() + '-' + ('0' + (this.dataIni.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataIni.getDate()).slice(-2);
      // this.auxDiaFin = this.dataFin.getFullYear() + '-' + ('0' + (this.dataFin.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataFin.getDate()).slice(-2);
      this.auxDiaIni = this.utilitariosService.ajustaDataBanco(this.dataIni);
      this.auxDiaFin = this.utilitariosService.ajustaDataBanco(this.dataFin);

      this.metricasService.buscaDadosGeraisCardPorCodEmp(this.auxDiaIni, this.auxDiaFin, Number(this.estabelecimento.codemp), this.codusuresp).subscribe((res) => {
        console.log(res);

        if (res.length) {
          this.positivacao = Object(res)[0].positivacao;
          this.receita = Object(res)[0].receita;
          this.qtdAgendamentos = Object(res)[0].qtd_agendamentos;
          this.ticketMedio = Object(res)[0].ticket_medio;
          this.qtdClienteAtivos = Object(res)[0].qtd_cli_ativos;
          this.difCli = Object(res)[0].dif_cli;
        } else {
          this.positivacao = 0;
          this.receita = 0;
          this.qtdAgendamentos = 0;
          this.ticketMedio = 0;
          this.qtdClienteAtivos = 0;
          this.difCli = 0;
        }
        /**BUSCA OS DADOS DOS GRAFICOS APOS SELECIONAR AS DATAS */
        this.selecionaTipoBarras();
        this.selecionaTipoDonut();
        this.buscaDadosGeraisComparativo();
        this.showAlterar = false;
        this.loading = false;
        this.showCarregando = false;
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.loading = false;
          this.showAlterar = false;
          this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')
        });
    }

    /********************************************************************************
    *                                      30 DIAS                                  *
    ********************************************************************************/
    if (this.valorSelecionadoAlterar === "ultimoMes") {
      /**FAZ A DATA INICIAL SER 30 DIAS ATRAS*/
      this.dataIni = new Date(this.data);
      this.dataIni.setDate(this.data.getDate() - 30);

      this.prefixoData = 'Últimos 30 dias';
      this.dataMostrada = '';

      // this.auxDiaIni = this.dataIni.getFullYear() + '-' + ('0' + (this.dataIni.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataIni.getDate()).slice(-2);
      // this.auxDiaFin = this.dataFin.getFullYear() + '-' + ('0' + (this.dataFin.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataFin.getDate()).slice(-2);
      this.auxDiaIni = this.utilitariosService.ajustaDataBanco(this.dataIni);
      this.auxDiaFin = this.utilitariosService.ajustaDataBanco(this.dataFin);
      this.metricasService.buscaDadosGeraisCardPorCodEmp(this.auxDiaIni, this.auxDiaFin, Number(this.estabelecimento.codemp), this.codusuresp).subscribe((res) => {
        console.log(res);

        if (res.length) {
          this.positivacao = Object(res)[0].positivacao;
          this.receita = Object(res)[0].receita;
          this.qtdAgendamentos = Object(res)[0].qtd_agendamentos;
          this.ticketMedio = Object(res)[0].ticket_medio;
          this.qtdClienteAtivos = Object(res)[0].qtd_cli_ativos;
          this.difCli = Object(res)[0].dif_cli;
        } else {
          this.positivacao = 0;
          this.receita = 0;
          this.qtdAgendamentos = 0;
          this.ticketMedio = 0;
          this.qtdClienteAtivos = 0;
          this.difCli = 0;
        }

        /**BUSCA OS DADOS DOS GRAFICOS APOS SELECIONAR AS DATAS */
        this.selecionaTipoBarras();
        this.selecionaTipoDonut();
        this.buscaDadosGeraisComparativo();
        this.showAlterar = false;
        this.loading = false;
        this.showCarregando = false;
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.loading = false;
          this.showAlterar = false;
          this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')
        });
    }


    /********************************************************************************
    *                                  OUTRO PERIODO                                 *
    ********************************************************************************/

    if (this.valorSelecionadoAlterar === "outroPeriodo") {
      // this.auxDiaIni = this.dataInicial.getFullYear() + '-' + ('0' + (this.dataInicial.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataInicial.getDate()).slice(-2);
      //this.auxDiaFin = this.dataFinal.getFullYear() + '-' + ('0' + (this.dataFinal.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataFinal.getDate()).slice(-2);
      this.auxDiaIni = this.utilitariosService.ajustaDataBanco(this.dataInicial);
      this.auxDiaFin = this.utilitariosService.ajustaDataBanco(this.dataFinal);
      this.metricasService.buscaDadosGeraisCardPorCodEmp(this.auxDiaIni, this.auxDiaFin, Number(this.estabelecimento.codemp), this.codusuresp).subscribe((res) => {
        console.log(res);

        this.prefixoData = 'Período personalizado';
        this.dataMostrada = '';

        if (res.length) {
          this.positivacao = Object(res)[0].positivacao;
          this.receita = Object(res)[0].receita;
          this.qtdAgendamentos = Object(res)[0].qtd_agendamentos;
          this.ticketMedio = Object(res)[0].ticket_medio;
          this.qtdClienteAtivos = Object(res)[0].qtd_cli_ativos;
          this.difCli = Object(res)[0].dif_cli;
        } else {
          this.positivacao = 0;
          this.receita = 0;
          this.qtdAgendamentos = 0;
          this.ticketMedio = 0;
          this.qtdClienteAtivos = 0;
          this.difCli = 0;
        }
        /**BUSCA OS DADOS DOS GRAFICOS APOS SELECIONAR AS DATAS */
        this.selecionaTipoBarras();
        this.selecionaTipoDonut();
        this.buscaDadosGeraisComparativo();
        this.showAlterar = false;
        this.loading = false;
        this.showCarregando = false;
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.loading = false;
          this.showAlterar = false;
          this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')
        });
    }

    this.buscaDadosPorTipoNegociacao();
    this.buscaDadosProfissionais();
    this.buscaDadosDiaSemana();
  }

  checaResolucao() {
    if (screen.width < 1024 || screen.height < 768) {
      this.resPc = false;
      this.resMobile = true;
    } else {
      this.resMobile = false;
      this.resPc = true;
    }
    window.addEventListener('resize', () => {
      if (screen.width < 1024 || screen.height < 768) {
        this.resPc = false;
        this.resMobile = true;
      } else {
        this.resMobile = false;
        this.resPc = true;
      }
    });

  }

  /**METODO DE TOAST */
  showError(titulo: string, mensagem: string) {
    if (screen.width < 640 || screen.height < 480) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else if (screen.width < 1024 || screen.height < 768) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else {
      this.messageService.add({
        key: 'tr',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    }
  }

  /********************************************************************************
  *                         RELATORIOS POR AGENDAMENTO                            *
  ********************************************************************************/
  geraRelatorioExcelAgdPorCodemp() {
    /**CHECA SE ESTÁ NA VERSÃO MOBILE */
    if (environment.MOBILE) {
      this.metricasService.geraRelatorioAgdExcelPorCodemp(this.auxDiaIni, this.auxDiaFin, Number(this.estabelecimento.codemp), this.codusuresp).subscribe((res) => {
        let url = environment.urlRelatorio + res[0]
        cordova.InAppBrowser.open(url, '_system');
      },
        (error) => {
          console.log('deu ruim')
          this.alertService.alert('Algo deu errado.', 'error');
        })
    } else {
      this.metricasService.geraRelatorioAgdExcelPorCodemp(this.auxDiaIni, this.auxDiaFin, Number(this.estabelecimento.codemp), this.codusuresp).subscribe((res) => {
        let url = environment.urlRelatorio + res[0]
        window.open(url);
      },
        (error) => {
          console.log('deu ruim')
          this.alertService.alert('Algo deu errado.', 'error');
        })
    }

  }

  geraRelatorioPDFAgd() {
    /**CHECA SE ESTÁ NA VERSÃO MOBILE */
    if (environment.MOBILE) {
      this.metricasService.geraRelatorioPDFAgdPorCodemp(this.auxDiaIni, this.auxDiaFin, Number(this.estabelecimento.codemp), this.codusuresp).subscribe((res) => {
        let url = environment.urlRelatorio + res[0];
        cordova.InAppBrowser.open(url, '_system');
      },
        (error) => {
          console.log('deu ruim')
          this.alertService.alert('Algo deu errado.', 'error');
        })
    } else {
      this.metricasService.geraRelatorioPDFAgdPorCodemp(this.auxDiaIni, this.auxDiaFin, Number(this.estabelecimento.codemp), this.codusuresp).subscribe((res) => {
        let url = environment.urlRelatorio + res[0];
        window.open(url);
      },
        (error) => {
          console.log('deu ruim')
          this.alertService.alert('Algo deu errado.', 'error');
        })
    }

  }

  /**RELATORIOS POR SERVIÇO */

  geraRelatorioExcelServicoPorCodemp() {
    /**CHECA SE ESTÁ NA VERSÃO MOBILE */
    if (environment.MOBILE) {
      this.metricasService.geraRelatorioServicoExcelPorCodemp(this.auxDiaIni, this.auxDiaFin, Number(this.estabelecimento.codemp), this.codusuresp).subscribe((res) => {
        let url = environment.urlRelatorio + res[0]
        cordova.InAppBrowser.open(url, '_system');
      },
        (error) => {
          console.log('deu ruim')
          this.alertService.alert('Algo deu errado.', 'error');
        })
    } else {
      this.metricasService.geraRelatorioServicoExcelPorCodemp(this.auxDiaIni, this.auxDiaFin, Number(this.estabelecimento.codemp), this.codusuresp).subscribe((res) => {
        let url = environment.urlRelatorio + res[0]
        window.open(url);
      },
        (error) => {
          console.log('deu ruim')
          this.alertService.alert('Algo deu errado.', 'error');
        })
    }

  }

  geraRelatorioPDFServico() {
    /**CHECA SE ESTÁ NA VERSÃO MOBILE */
    if (environment.MOBILE) {
      this.metricasService.geraRelatorioPDFServicoPorCodemp(this.auxDiaIni, this.auxDiaFin, Number(this.estabelecimento.codemp), this.codusuresp).subscribe((res) => {
        let url = environment.urlRelatorio + res[0];
        cordova.InAppBrowser.open(url, '_system');
      },
        (error) => {
          console.log('deu ruim')
          this.alertService.alert('Algo deu errado.', 'error');
        })
    } else {
      this.metricasService.geraRelatorioPDFServicoPorCodemp(this.auxDiaIni, this.auxDiaFin, Number(this.estabelecimento.codemp), this.codusuresp).subscribe((res) => {
        let url = environment.urlRelatorio + res[0];
        window.open(url);
      },
        (error) => {
          console.log('deu ruim')
          this.alertService.alert('Algo deu errado.', 'error');
        })
    }

  }

  /**RELATORIOS POR COMPARATIVO DE OCORRENCIA */
  geraRelatorioExcelComparativoPorCodemp() {
    /**CHECA SE ESTÁ NA VERSÃO MOBILE */
    if (environment.MOBILE) {
      this.metricasService.geraRelatorioComparativoExcelPorCodemp(this.auxDiaIni, this.auxDiaFin, Number(this.estabelecimento.codemp), this.codusuresp).subscribe((res) => {
        let url = environment.urlRelatorio + res[0]
        cordova.InAppBrowser.open(url, '_system');
      },
        (error) => {
          console.log('deu ruim')
          this.alertService.alert('Algo deu errado.', 'error');
        })
    } else {
      this.metricasService.geraRelatorioComparativoExcelPorCodemp(this.auxDiaIni, this.auxDiaFin, Number(this.estabelecimento.codemp), this.codusuresp).subscribe((res) => {
        let url = environment.urlRelatorio + res[0]
        window.open(url);
      },
        (error) => {
          console.log('deu ruim')
          this.alertService.alert('Algo deu errado.', 'error');
        })
    }

  }

  geraRelatorioPDFComparativo() {
    /**CHECA SE ESTÁ NA VERSÃO MOBILE */
    if (environment.MOBILE) {
      this.metricasService.geraRelatorioPDFComparativoPorCodemp(this.auxDiaIni, this.auxDiaFin, Number(this.estabelecimento.codemp), this.codusuresp).subscribe((res) => {
        let url = environment.urlRelatorio + res[0];
        cordova.InAppBrowser.open(url, '_system');
      },
        (error) => {
          console.log('deu ruim')
          this.alertService.alert('Algo deu errado.', 'error');
        })
    } else {
      this.metricasService.geraRelatorioPDFComparativoPorCodemp(this.auxDiaIni, this.auxDiaFin, Number(this.estabelecimento.codemp), this.codusuresp).subscribe((res) => {
        let url = environment.urlRelatorio + res[0];
        window.open(url);
      },
        (error) => {
          console.log('deu ruim')
          this.alertService.alert('Algo deu errado.', 'error');
        })
    }

  }


  /**RELATORIOS DE DESEMPENHO DE PROFISSIONAL */
  geraRelatorioExcelDesempenho() {
    /**CHECA SE ESTÁ NA VERSÃO MOBILE */
    if (environment.MOBILE) {
      this.metricasService.geraRelatorioDesempenhoProfissionalExcelPorCodemp(this.auxDiaIni, this.auxDiaFin, Number(this.estabelecimento.codemp), this.codusuresp).subscribe((res) => {
        let url = environment.urlRelatorio + res[0]
        cordova.InAppBrowser.open(url, '_system');
      },
        (error) => {
          console.log('deu ruim')
          this.alertService.alert('Algo deu errado.', 'error');
        })
    } else {
      this.metricasService.geraRelatorioDesempenhoProfissionalExcelPorCodemp(this.auxDiaIni, this.auxDiaFin, Number(this.estabelecimento.codemp), this.codusuresp).subscribe((res) => {
        let url = environment.urlRelatorio + res[0]
        window.open(url);
      },
        (error) => {
          console.log('deu ruim')
          this.alertService.alert('Algo deu errado.', 'error');
        })
    }

  }

  geraRelatorioPDFDesempenho() {
    /**CHECA SE ESTÁ NA VERSÃO MOBILE */
    if (environment.MOBILE) {
      this.metricasService.geraRelatorioPDFDesempenhoProfissional(this.auxDiaIni, this.auxDiaFin, Number(this.estabelecimento.codemp), this.codusuresp).subscribe((res) => {
        let url = environment.urlRelatorio + res[0];
        cordova.InAppBrowser.open(url, '_system');
      },
        (error) => {
          console.log('deu ruim')
          this.alertService.alert('Algo deu errado.', 'error');
        })
    } else {
      this.metricasService.geraRelatorioPDFDesempenhoProfissional(this.auxDiaIni, this.auxDiaFin, Number(this.estabelecimento.codemp), this.codusuresp).subscribe((res) => {
        let url = environment.urlRelatorio + res[0];
        window.open(url);
      },
        (error) => {
          console.log('deu ruim')
          this.alertService.alert('Algo deu errado.', 'error');
        })
    }
  }

}
