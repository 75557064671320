<div class="pt-7">


    <div class="flex flex-column  pb-3">
        <div class="flex align-items-center justify-content-center">
            <img src="./assets/iconePainel1.svg" class="iconeCadastrarBarbearia" />
        </div>
        <div class=" flex align-items-center justify-content-center pb-2">
            <span class="tituloNegritoPainel">Painel Barber</span>
        </div>

        <div class=" flex justify-content-center flex-wrap">
            <div class=" xl:col-5 lg:col-5 md:col-5 sm:col-5">
                <div class="shadow-4">

                    <p-card id="cardPainel1">
                        <div class="flex justify-content-between flex-wrap  ">
                            <div class="flex flex-column align-items-center justify-content-center cursor-pointer ">
                                <div>
                                    <img src="./assets/programacoesPainel.svg" class="iconePainel " routerLink="/agenda" />
                                </div>
                                <div class="fontMarronIcones flex align-items-center justify-content-center">
                                    <span class="">Programações</span>
                                </div>
                            </div>

                            <div class="flex flex-column align-items-center justify-content-center  cursor-pointer ">
                                <div>
                                    <img src="./assets/relatoriosPainel.svg" class="iconePainel " />
                                </div>
                                <div class="fontMarronIcones flex align-items-center justify-content-center">
                                    <span>Relatórios</span>
                                </div>
                            </div>

                            <div class="flex flex-column align-items-center justify-content-center  cursor-pointer ">
                                <div>
                                    <img src="./assets/meusResultadosPainel.svg" class="iconePainel " routerLink="/meusResultados" />
                                </div>
                                <div class="fontMarronIcones flex align-items-center justify-content-center pr-2">
                                    <span>Meus Resultados</span>
                                </div>
                            </div>
                        </div>
                    </p-card>
                </div>
            </div>
        </div>
    </div>





    <div class="flex flex-column  pb-3">
        <div class="flex align-items-center justify-content-center">
            <img src="./assets/iconePainel2.svg" class="iconeCadastrarBarbearia" />
        </div>
        <div class=" flex align-items-center justify-content-center pb-2">
            <span class="tituloNegritoPainel">Funções Administrativas</span>
        </div>

        <div class=" flex justify-content-center flex-wrap">
            <div class=" xl:col-5 lg:col-5 md:col-5 sm:col-5">
                <div class="shadow-4">

                    <p-card id="cardPainel2">
                        <div class="flex justify-content-between flex-wrap xl:gap-5">
                            <div *ngFor="let permissao of permissao">
                                <div *ngIf="permissao.descr ==='BARBEARIAS' && permissao.habilita === 'S'"
                                    class="flex flex-column align-items-center justify-content-center cursor-pointer tamanhoBotao">
                                    <div>
                                        <img src="./assets/barbeariaMenuAdm.svg" class="iconePainel"
                                            routerLink="/cadastrarBarbearia" />
                                    </div>
                                    <div class="fontMarronIcones flex align-items-center justify-content-center">
                                        <span class="">Barbearia</span>
                                    </div>
                                </div>

                                <div *ngIf="permissao.descr ==='PROFISSIONAIS' && permissao.habilita === 'S'"
                                    class="flex flex-column align-items-center justify-content-center cursor-pointer tamanhoBotao">
                                    <div>
                                        <img src="./assets/barbeirosAdm.svg" class="iconePainel"
                                            routerLink="/cadastrarBarbeiros" />
                                    </div>
                                    <div class="fontMarronIcones flex align-items-center justify-content-center">
                                        <span class="">Profissionais</span>
                                    </div>
                                </div>

                                <div *ngIf="permissao.descr ==='SERVIÇOS' && permissao.habilita === 'S'"
                                    class="flex flex-column align-items-center justify-content-center cursor-pointer tamanhoBotao">
                                    <div>
                                        <img src="./assets/servicosAdm.svg" class="iconePainel "
                                            routerLink="/cadastrarServicos" />
                                    </div>
                                    <div class="fontMarronIcones flex align-items-center justify-content-center">
                                        <span class="">Servicos</span>
                                    </div>
                                </div>

                                <div *ngIf="permissao.descr ==='HORÁRIOS' && permissao.habilita === 'S'"
                                    class="flex flex-column align-items-center justify-content-center cursor-pointer tamanhoBotao">
                                    <div>
                                        <img src="./assets/horariosAdm.svg" class="iconePainel "
                                            routerLink="/cadastrarHorarios" />
                                    </div>
                                    <div class="fontMarronIcones flex align-items-center justify-content-center">
                                        <span>Horários</span>
                                    </div>
                                </div>

                                <div *ngIf="permissao.descr ==='INFORMATIVOS' && permissao.habilita === 'S'"
                                    class="flex flex-column align-items-center justify-content-center cursor-pointer tamanhoBotao">
                                    <div>
                                        <img src="./assets/Informativos.svg" class="iconePainel "
                                            routerLink="/cadastrarInformativos" />
                                    </div>
                                    <div class="fontMarronIcones flex align-items-center justify-content-center pr-2">
                                        <span>Informativos</span>
                                    </div>
                                </div>

                                <div *ngIf="permissao.descr ==='PESQUISAS' && permissao.habilita === 'S'"
                                    class="flex flex-column align-items-center justify-content-center cursor-pointer tamanhoBotao">
                                    <div>
                                        <img src="./assets/pesquisasAdm.svg" class="iconePainel "
                                            routerLink="/cadastrarPesquisa" />
                                    </div>
                                    <div class="fontMarronIcones flex align-items-center justify-content-center pr-2">
                                        <span>Pesquisas</span>
                                    </div>
                                </div>

                                <div *ngIf="permissao.descr ==='NOTIFICAÇÕES' && permissao.habilita === 'S'"
                                    class="flex flex-column align-items-center justify-content-center cursor-pointer tamanhoBotao">
                                    <div>
                                        <img src="./assets/notificacoesAdm.svg" class="iconePainel"
                                            routerLink="/notificacoes" />
                                    </div>
                                    <div class="fontMarronIcones flex align-items-center justify-content-center pr-2">
                                        <span>Notificações</span>
                                    </div>
                                </div>

                                <div *ngIf="permissao.descr ==='COMISSÕES' && permissao.habilita === 'S'"
                                    class="flex flex-column align-items-center justify-content-center cursor-pointer tamanhoBotao">
                                    <div>
                                        <img src="./assets/iconeComissao.svg" class="iconePainel "
                                            routerLink="/cadastrarComissao" />
                                    </div>
                                    <div class="fontMarronIcones flex align-items-center justify-content-center pr-2">
                                        <span>Comissão</span>
                                    </div>
                                </div>

                                <div *ngIf="permissao.descr ==='DESCONTOS' && permissao.habilita === 'S'"
                                    class="flex flex-column align-items-center justify-content-center cursor-pointer tamanhoBotao">
                                    <div>
                                        <img src="./assets/descontosAdm.svg" class="iconePainel "
                                            routerLink="/descontos" />
                                    </div>
                                    <div class="fontMarronIcones flex align-items-center justify-content-center pr-2">
                                        <span>Descontos</span>
                                    </div>
                                </div>

                                <div *ngIf="permissao.descr ==='MOV. FINANCEIRO' && permissao.habilita === 'S'"
                                    class="flex flex-column align-items-center justify-content-center cursor-pointer tamanhoBotao">
                                    <div>
                                        <img src="./assets/financeiroAdm.svg" class="iconePainel "
                                            routerLink="/financeiro" />
                                    </div>
                                    <div class="fontMarronIcones flex align-items-center justify-content-center pr-2">
                                        <span>Financeiro</span>
                                    </div>
                                </div>

                                <div *ngIf="permissao.descr ==='ACESSOS' && permissao.habilita === 'S'"
                                    class="flex flex-column align-items-center justify-content-center cursor-pointer tamanhoBotao">
                                    <div>
                                        <img src="./assets/acessosAdm.svg" class="iconePainel " routerLink="/acessos" />
                                    </div>
                                    <div class="fontMarronIcones flex align-items-center justify-content-center pr-2">
                                        <span>Acessos</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </p-card>
                </div>
            </div>
        </div>
    </div>


    <!--                          DIALOG DO CARREGANDO               -->
    <p-dialog id="dialogCarregandoPainel" [(visible)]="mostraCarregando" [modal]="true"
        [style]="{'width': '150px', 'height': '140px'}" [closable]="false">
        <div class="flex flex-column align-items-center mt-2">
            <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
                fill="var(--surface-ground)" animationDuration="1s"></p-progressSpinner>
            <div class="fontCarregando">
                <span>Carregando...</span>
            </div>
        </div>
    </p-dialog>