import { ServicoFechamento } from './../assets/servico';
import { EstabelecimentoService } from '../estabelecimento.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { Estabelecimento } from 'src/assets/estabelecimento';
import { ProfissionalService } from 'src/profissional.service';
import { Profissional } from 'src/assets/profissional';
import { ComissaoService } from 'src/comissao.service';
import { Ajuste, Comissao } from 'src/assets/comissao';
import { UtilitariosService } from 'src/assets/utilitarios.service';
import { AlertService } from 'src/alert.service';
import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core'; //TRADUÇÃO
import { count, empty, Observable, Subscription } from 'rxjs';//TRADUÇÃO
import { TraducaoService } from 'src/traducao.service';
import { environment } from 'src/environments/environment';
import { PermissoesService } from 'src/permissoes.service';

declare var cordova: any;

@Component({
  selector: 'app-comissao',
  templateUrl: './comissao.component.html',
  styleUrls: ['./comissao.component.scss'],
  providers: [MessageService, ConfirmationService],
  encapsulation: ViewEncapsulation.None
})
export class ComissaoComponent implements OnInit {
  showFechamento: boolean = false;
  showServicos: boolean = false;
  showAjustes: boolean = false;
  showCadAjuste: boolean = false;

  /**CODIGOS */
  codusuresp: string = '';
  codcli: number = 0;
  codemp: number = 0;
  nucom: number = 0;
  nuajuste: number = 0;

  /**TRADUCAO */
  lang: string = "en";
  //subscription: Subscription;

  /**DATA */
  hoje: Date;
  ontem: Date;
  // dataInicial: any;
  // dataFinal: any;
  dataInicialCom: any;
  dataFinalCom: any;
  tituloFechametoIni: any;
  tituloFechametoFin: any;

  loading: boolean = false;

  estabelecimento: Estabelecimento[] = [];
  estabelecimentoSelecionada: any;
  teveRespostaEstabelecimento: boolean = false;

  profissional: Profissional[] = [];
  auxProfissional: Profissional[] = [];
  profissionalSelecionado: any;

  comissao: Comissao = {};
  comissaoFechada: number = 0;
  comissaoStatus: boolean = false;

  ajuste: Ajuste[] = [];
  ajusteSelecionadoExcluir: Ajuste[] = [];
  ajusteSelecionadoEditar: Ajuste = {};
  selecionouTodosAjustesExcluir: Ajuste[] = [];

  caracteresRestantes: number = 0;
  descricaoAjuste: string = '';
  valorAjuste: number = -0;

  headerCard: string = '';
  apelido: string = '';

  servico: ServicoFechamento[] = [];
  selecionouTodosServicos: ServicoFechamento[] = [];
  servicoSelecionadoFechamento: ServicoFechamento[] = [];
  servicoAntesDaAtualizacao: ServicoFechamento[] = [];

  vlrSoma: number = 0;
  vlrComissao: number = 0;
  percentComissao: number = 0;
  vlrAjuste: number = 0;
  vlrTotalComissao: number = 0;

  /**VARIAVEIS DE PERMISSÕES */
  alterar: boolean = false;
  cadastrar: boolean = false;
  permissao: any;

  constructor(private estabelecimentoService: EstabelecimentoService, private decodedTokenService: DecodedTokenService, private comissaoService: ComissaoService, private permissoesService: PermissoesService,
    private profissionalService: ProfissionalService, private utilitariosService: UtilitariosService, private alertService: AlertService, private confirmationService: ConfirmationService,
    private messageService: MessageService, public translate: TranslateService, public primeNGConfig: PrimeNGConfig, private traducaoService: TraducaoService) {
    this.hoje = new Date();
    this.ontem = new Date(this.hoje);
    this.ontem.setDate(this.hoje.getDate() - 1);

    /**TRADUÇÃO */
    this.traducaoService.changeLang();
    // translate.addLangs(['en', 'pt']);
    // translate.setDefaultLang('pt');
    // const browserLang = translate.getBrowserLang();
    // let lang = browserLang!.match(/en|pt/) ? browserLang : 'pt';
    // this.changeLang(lang!);
    // this.subscription = this.translate.stream('primeng').subscribe(data => {
    //   this.primeNGConfig.setTranslation(data);
    // });
  }

  ngOnInit(): void {
    this.recuperaCodUsu();
    this.recuperaPermissao();
    this.recuperaCodCli();
    this.recuperaComissao();
  }

  /**TRADUÇÃO */
  changeLang(lang: string) {
    this.translate.use(lang);
  }


  mostraDialogFechamento() {
    /**VERIFICA SE TEM PERMISSÃO PARA ESSA AÇÃO */
    //if (!this.alterar && this.permissao != undefined && this.permissao.length > 0 && this.tituloFechametoIni != '' && this.tituloFechametoIni != undefined) {
    if (!this.alterar && this.permissao != undefined && this.permissao.length > 0 && (this.nucom > 0 || this.comissao.nucom! > 0)) {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
    this.showFechamento = true;
    this.checaStatusComissao();
  }

  mostraDialogServicos() {
    if (!this.alterar && this.permissao != undefined && this.permissao.length > 0) {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
    if (this.nucom || this.comissao.nucom) {
      this.showServicos = true;
    }
    this.checaStatusComissao();
    if (this.comissaoStatus === true) {
      this.showAjustes = false;
      return;
    }
  }

  mostraDialogAjustes() {
    if (!this.alterar && this.permissao != undefined && this.permissao.length > 0) {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }

    if (this.nucom || this.comissao.nucom) {
      this.showAjustes = true;
    }
  }

  clicouVoltar() {
    this.showAjustes = false
  }

  mostraDialogCadAjustes() {
    /**VERIFICA SE A COMISSÃO JÁ ESTÁ FECHADA */
    this.checaStatusComissao();
    if (this.comissaoStatus === true) {
      this.showAjustes = false;
      this.alertService.alert('Alteração em cima de comissão fechada não é permitida!', 'error');
      return;
    }
    this.showCadAjuste = true;
  }

  recuperaCodUsu() {
    this.codusuresp = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }

  recuperaCodCli() {
    this.codcli = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODCLI;
    this.buscarEstabelecimentosPorCodCliente();
  }

  recuperaComissao() {
    this.comissao = this.comissaoService.getComissao();
    this.nucom = this.comissao.nucom!;
    this.buscaAjustesPorNucom();
    this.apelido = this.comissao.apelido!;

    if (this.comissao.dtini != null && this.comissao.dtini != null) {
      this.dataInicialCom = new Date(this.comissao.dtini + 'GMT-0300');
      this.dataFinalCom = new Date(this.comissao.dtfin + 'GMT-0300');
    }

    /**RECUPERA O TITULO DA COMISSAO*/
    this.tituloFechametoIni = this.dataInicialCom;
    this.tituloFechametoFin = this.dataFinalCom;

    this.estabelecimentoSelecionada = this.comissao.codemp;
    this.buscaProfissionalPorCodEmp();
    this.profissionalSelecionado = this.comissao.codfunc;
    this.buscaServicos();
    this.somaComissao();
  }

  /**RECUPERA OS AJUSTES */
  buscaAjustesPorNucom() {
    this.comissaoService.buscaAjusteComissaoPorNucom(this.nucom, this.codusuresp).subscribe((res) => {
      this.ajuste = res;
      this.somaComissao();
      //this.atualizaValorComissao()
    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
      })
  }

  atualizaValorComAposAjuste() {
    this.comissaoService.buscaAjusteComissaoPorNucom(this.nucom, this.codusuresp).subscribe((res) => {
      this.ajuste = res;
      this.somaComissao();
      this.atualizaValorComissao()
    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
      })
  }

  /**RECUPERA AS BARBEARIAS */
  buscarEstabelecimentosPorCodCliente() {
    console.log('fwefwfwefwefe')
    /**VERIFICA SE VAI FAZER A BUSCA PELO CODCLI DO TOKEN OU DA PERMISSÃO */
    if (this.permissao != undefined && this.permissao.length > 0) {
      this.estabelecimentoService.buscarEstabelecimentosporCodCli(this.permissao[0].codcli, this.codusuresp).subscribe((res) => {
        this.estabelecimento = res;
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
        })
    } else {
      this.estabelecimentoService.buscarEstabelecimentosporCodCli(this.codcli, this.codusuresp).subscribe((res) => {
        this.estabelecimento = res;
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
        })
    }
  }

  /**RECUPERA OS BARBEIRO */
  buscaProfissionalPorCodEmp() {
    this.teveRespostaEstabelecimento = true;
    setTimeout(() => {
      this.profissionalService.buscarProfissionalporCodEmp(this.estabelecimentoSelecionada, this.codusuresp).subscribe((res) => {
        this.profissional = res;
        this.teveRespostaEstabelecimento = false;
      },
        (error) => {
          this.teveRespostaEstabelecimento = false;
          console.log(error)
          console.log('deu ruim')
        })
    }, 500);
  }

  /**CRIA/EDIATA O FECHAMENTO */
  salvaComissao() {
    this.loading = true;

    if (this.utilitariosService.validaCampo([this.dataInicialCom, this.dataFinalCom]) || this.profissionalSelecionado === undefined) {
      console.log('É necessário informar todos os dados Pesquisa.')
      this.loading = false;
      this.showError('Atenção', 'É necessário preencher todos os campos do Fechamento.')
      return;
    }

    /**VERIFICA SE A COMISSÃO JÁ ESTÁ FECHADA */
    console.log(this.comissaoStatus)
    this.checaStatusComissao();
    if (this.comissaoStatus === true) {
      this.showFechamento = false;
      this.alertService.alert('Alteração em cima de comissão fechada não é permitida!', 'error');
      return;
    }

    console.log(this.dataInicialCom.getFullYear() + '-' + ('0' + (this.dataInicialCom.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataInicialCom.getDate()).slice(-2))
    /**DEIXA AS DATAS NO PADRÃO QUE O BANCO ESPERA: YYYY-MM-DD */
    // let auxInicial = this.dataInicialCom.getFullYear() + '-' + ('0' + (this.dataInicialCom.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataInicialCom.getDate()).slice(-2);
    // let auxFinal = this.dataFinalCom.getFullYear() + '-' + ('0' + (this.dataFinalCom.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataFinalCom.getDate()).slice(-2);
    let auxInicial = this.utilitariosService.ajustaDataBanco(this.dataInicialCom);
    let auxFinal = this.utilitariosService.ajustaDataBanco(this.dataFinalCom);


    this.comissaoService.criaFechamentoComissao(this.nucom, auxInicial, auxFinal, this.profissionalSelecionado, this.codusuresp).subscribe((res) => {
      this.loading = false;
      console.log(res);
      this.showFechamento = false;
      this.nucom = res.nucom;
      this.tituloFechametoIni = auxInicial;
      this.tituloFechametoFin = auxFinal;
      this.buscaServicos();
      this.alertService.alert('Fechamento alterado!', 'success');
    },
      (error) => {
        this.loading = false;
        this.teveRespostaEstabelecimento = false;
        console.log(error)
        console.log('deu ruim')
      })
  }

  /**METODO PARA CONTAR OS CARACTERES DA PERGUNTA */
  contaCaracter() {
    if (this.caracteresRestantes < 151) {
      this.caracteresRestantes = this.descricaoAjuste.length;
    } else {
      return;
    }
  }


  /**CRIA O AJUSTE DA COMISSAO */
  criaAjuste() {
    this.loading = true;
    if (this.utilitariosService.validaCampo([this.descricaoAjuste, this.valorAjuste])) {
      console.log('É necessário informar todos os dados Pesquisa.')
      this.loading = false;
      this.showError('Atenção', 'É necessário preencher todos os campos do Ajuste.')
      return;
    }

    /**VERIFICA SE A COMISSÃO JÁ ESTÁ FECHADA */
    this.checaStatusComissao();
    if (this.comissaoStatus === true) {
      this.showCadAjuste = false;
      this.showAjustes = true;
      this.alertService.alert('Alteração em cima de comissão fechada não é permitida!', 'error');
      this.loading = false;
      return;
    }

    if (this.ajusteSelecionadoEditar.nuajuste) {
      this.comissaoService.criaAjusteComissao(this.ajusteSelecionadoEditar.nuajuste!, this.nucom, this.descricaoAjuste, this.valorAjuste, this.codusuresp).subscribe((res) => {
        console.log(res);
        this.alertService.alert('Ajuste alterado!', 'success');
        this.showCadAjuste = false;
        this.loading = false;
        // this.buscaAjustesPorNucom();
        // this.atualizaValorComissao();
        // this.somaComissao();
        this.atualizaValorComAposAjuste()
      },
        (error) => {
          console.log(error)
          this.loading = false;
          this.showCadAjuste = false;
          console.log(error)
          console.log('deu ruim')
        })
    } else {
      this.comissaoService.criaAjusteComissao(null, this.nucom, this.descricaoAjuste, this.valorAjuste, this.codusuresp).subscribe((res) => {
        console.log(res);
        this.loading = false;
        this.showCadAjuste = false;
        this.descricaoAjuste = '';
        this.valorAjuste = 0;
        this.alertService.alert('Ajuste cadastrado!', 'success');
        //this.buscaAjustesPorNucom();
        //this.atualizaValorComissao();
        // this.somaComissao();
        this.atualizaValorComAposAjuste();
      },
        (error) => {
          console.log(error)
          this.loading = false;
          this.showCadAjuste = false;
          console.log(error)
          console.log('deu ruim')
        })
    }
  }



  /**SELECIONOU A PERGUNTA PARA EXCLUSÃO (CHECKBOX) */
  selecionouPergExclusao() {
    console.log(this.ajusteSelecionadoExcluir)
  }

  /**SELECIONOU A PERGUNTA PARA EDIÇÃO (LAPIZINHO) */
  selecionouAjusteEdicao(ajuste: Ajuste) {
    /**VERIFICA SE A COMISSÃO JÁ ESTÁ FECHADA */
    this.checaStatusComissao();
    if (this.comissaoStatus === true) {
      this.showAjustes = false;
      this.alertService.alert('Alteração em cima de comissão fechada não é permitida!', 'error');
      return;
    }
    this.ajusteSelecionadoEditar = ajuste;
    this.descricaoAjuste = ajuste.descricao!;
    this.valorAjuste = ajuste.valor!;
    this.mostraDialogCadAjustes();
    this.contaCaracter();
  }


  /**METODO DE EXCLUSÃO DE UMA PERGUNTA */
  excluiAjustePorNuajuste() {

    /**VERIFICA SE A PESSOA QUER EXCLUIR TODAS AS PERGUNTAS  */
    if (this.ajuste.length != this.ajusteSelecionadoExcluir.length) {
      for (let i = 0; i < this.ajusteSelecionadoExcluir.length; i++) {
        this.comissaoService.excluiAjusteComissao(this.ajusteSelecionadoExcluir[i].nuajuste!, this.codusuresp).subscribe((res) => {
          console.log(res);
          /**FILTRA PARA RETIRAR A PERGUNTA QUE FOI EXCLUIDO */
          this.ajuste = this.ajuste.filter((x: any) => x.nuajuste != res.nuajuste);
          console.log(this.ajuste)
          this.somaComissao();
          this.atualizaValorComissao();
          this.ajusteSelecionadoExcluir = [];
        },
          (error) => {
            console.log(error)
            console.log('deu ruim')
            this.loading = false;
            this.alertService.alert('Algo deu errado.', 'error');
          })
      }
    } else {
      this.comissaoService.excluiTodosAjusteComissao(this.nucom, this.codusuresp).subscribe((res) => {
        console.log(res);
        this.ajuste = [];
        this.selecionouTodosAjustesExcluir = [];
        this.somaComissao();
        this.atualizaValorComissao();
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.loading = false;
          this.alertService.alert('Algo deu errado.', 'error');
        })
    }
  }

  /**CONFIRMA EXCLUSÃO DE UMA PERGUNTA */
  confirmaExclusaoAjuste() {
    /**VERIFICA SE A COMISSÃO JÁ ESTÁ FECHADA */
    this.checaStatusComissao();
    if (this.comissaoStatus === true) {
      this.showAjustes = false;
      this.alertService.alert('Alteração em cima de comissão fechada não é permitida!', 'error');
      return;
    }
    /**VERIFICA SE FOI SELECIONADO ALGUMA PERGUNTA PARA EXCLUIR E ABRIR O POPUP*/
    if (this.ajusteSelecionadoExcluir.length) {

      /**VERIFICA SE A PESSOA QUER EXCLUIR TODAS AS PERGUNTAS  */
      if (this.ajuste.length != this.ajusteSelecionadoExcluir.length) {
        let auxNomeAjuste = '';

        for (let i = 0; i < this.ajusteSelecionadoExcluir.length; i++) {
          if (i === 0) {
            auxNomeAjuste = this.ajusteSelecionadoExcluir[i].descricao!;
          } else {

            auxNomeAjuste = auxNomeAjuste + ', ' + this.ajusteSelecionadoExcluir[i].descricao;
          }
        }

        this.confirmationService.confirm({
          message: 'Deseja mesmo excluir ' + auxNomeAjuste + ' ?',
          header: 'Excluir Pergunta',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.excluiAjustePorNuajuste();
          },
          reject: () => {
          }
        });
      } else {
        this.confirmationService.confirm({
          message: 'Deseja mesmo excluir todos os ajustes ?',
          header: 'Excluir Pergunta',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.excluiAjustePorNuajuste();
          },
          reject: () => {
          }
        });
      }
    }
  }

  /**SELECIONA TODOS OS AJUSTES (VISUALMENTE) PARA EXCLUSÃO */
  clicouTodosAjustes() {
    this.ajusteSelecionadoExcluir = [];
    if (this.selecionouTodosAjustesExcluir.length > 0) {
      let auxTodosAjustes = JSON.stringify(this.selecionouTodosAjustesExcluir);
      auxTodosAjustes = auxTodosAjustes.substring(0, auxTodosAjustes.length - 1);
      auxTodosAjustes = auxTodosAjustes.substring(1);
      auxTodosAjustes = JSON.parse(auxTodosAjustes);
      for (let i = 0; i < this.ajuste.length; i++) {
        for (let j = 0; j < auxTodosAjustes.length; j++) {
          if (Object(auxTodosAjustes[j]).nuajuste === this.ajuste[i].nuajuste) {
            this.ajusteSelecionadoExcluir.push(this.ajuste[i]);
          }
        }
      }
    }
  }

  /**BUSCA OS SERVIÇOS PARA FECHAMENTO */
  buscaServicos() {
    console.log(this.profissionalSelecionado)
    console.log(this.nucom)
    this.comissaoService.buscaServicoPorNucom(this.profissionalSelecionado, this.tituloFechametoIni, this.tituloFechametoFin, this.nucom, this.codusuresp).subscribe((res) => {
      this.servico = res;

      /**JÁ DEIXA O CHECKBOX MARCADO*/
      //this.servicoSelecionadoFechamento = this.servico;
      for (let i = 0; i < this.servico.length; i++) {
        if (this.servico[i].nucom) {
          this.servicoSelecionadoFechamento.push(this.servico[i]);
        }
      }
      /**VARIAVEL QUE CONTROLA SE UM SERVIÇO VAI SER DESMARCADO EM UMA NOVA ATUALIZAÇÃO */
      this.servicoAntesDaAtualizacao = this.servicoSelecionadoFechamento;

      /**METODOS DE CALCULOS */
      this.somaTotal();
      this.somaComissao();
    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
        this.loading = false;
        this.alertService.alert('Algo deu errado.', 'error');
      });
  }



  cliclouTodosServicos() {
    console.log('wgrewrg')
    console.log(this.selecionouTodosServicos);
    if (!this.comissaoStatus) {
      this.servicoSelecionadoFechamento = [];
      if (this.selecionouTodosServicos.length > 0) {
        let auxTodosServicos = JSON.stringify(this.selecionouTodosServicos);

        auxTodosServicos = auxTodosServicos.substring(0, auxTodosServicos.length - 1);
        auxTodosServicos = auxTodosServicos.substring(1);
        auxTodosServicos = JSON.parse(auxTodosServicos);
        console.log(auxTodosServicos.length)
        for (let i = 0; i < this.servico.length; i++) {
          for (let j = 0; j < auxTodosServicos.length; j++) {
            if (Object(auxTodosServicos[j]).nuid === this.servico[i].nuid) {
              this.servicoSelecionadoFechamento.push(this.servico[i]);
            }
          }
        }
      }
    }
  }


  /**METODO PARA CALCULO DE COMISSAO */
  somaTotal() {
    this.vlrSoma = 0;
    this.vlrComissao = 0;
    this.percentComissao = 0;

    for (let i = 0; i < this.servicoSelecionadoFechamento.length; i++) {
      this.vlrSoma += this.servicoSelecionadoFechamento[i].vlrtot!;
      this.vlrComissao += this.servicoSelecionadoFechamento[i].comissao!;
    }
    if (this.vlrSoma != 0 && this.vlrComissao != 0) {
      this.percentComissao = Number(((this.vlrComissao / this.vlrSoma) * 100).toFixed(2));
    }
  }

  atualizaValorComissao() {
    this.loading = true;

    /**VERIFICA SE A COMISSÃO JÁ ESTÁ FECHADA */
    this.checaStatusComissao();
    if (this.comissaoStatus === true) {
      this.showServicos = false;
      this.loading = false;
      this.alertService.alert('Alteração em cima de comissão fechada não é permitida!', 'error');
      return;
    }

    this.somaComissao();
    this.comissaoService.atualizaValorComissao(this.vlrTotalComissao, this.nucom, this.codusuresp).subscribe((res) => {
      console.log('AJUSTE ' + this.vlrTotalComissao)
      this.atualizaCabecalho();
      this.showServicos = false;
      this.loading = false;
      this.alertService.alert('Valor da comissão alterado!', 'success');
    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
        this.showServicos = false;
        this.loading = false;
        this.alertService.alert('Algo deu errado.', 'error');
      });
  }

  atualizaCabecalho() {
    /**VERIFICA SE ALGUM SERVIÇO FOI DESMARCADO DESDE A ULTIMA ATUALIZACAO E SETA ELE COMO NULL NA TGFCAB */
    let diferenca = this.servicoAntesDaAtualizacao.filter(x => !this.servicoSelecionadoFechamento.includes(x));

    /**SALVA O NUCOM NA TGFCAB DO SERVIÇO SELECIONADO */
    for (let i = 0; i < this.servicoSelecionadoFechamento.length; i++) {
      this.comissaoService.atualizaNuComissao(this.nucom, this.servicoSelecionadoFechamento[i].nuid!, this.codusuresp).subscribe((res) => {
        console.log(res)
        console.log('deu certo atualizar o cab ↑');
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.showServicos = false;
          this.loading = false;
          this.alertService.alert('Algo deu errado.', 'error');
        });
    }

    /**TORNA O NUCOM NULL NA TGFCAB DO SERVIÇO DESMARCADO*/
    for (let i = 0; i < diferenca.length; i++) {
      this.comissaoService.atualizaNuComissao(null, diferenca[i].nuid!, this.codusuresp).subscribe((res) => {
        console.log(res)
        console.log('deu certo atualizar o cab ↑');
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.showServicos = false;
          this.loading = false;
          this.alertService.alert('Algo deu errado.', 'error');
        });
    }
  }

  /**SOMA A COMISSÃO DO FUNCIONARIO */
  somaComissao() {
    this.vlrAjuste = 0;
    for (let i = 0; i < this.ajuste.length; i++) {
      this.vlrAjuste += this.ajuste[i].valor!;
    }
    this.vlrTotalComissao = this.vlrAjuste + this.vlrSoma;

    if (this.comissao.nucom) {
      this.headerCard = 'Comissão: ' + this.apelido + ' - (' + this.vlrTotalComissao.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) + ')';
    } else {
      for (let i = 0; i < this.profissional.length; i++) {
        if (this.profissional[i].codfunc === this.profissionalSelecionado) {
          this.headerCard = 'Comissão: ' + this.profissional[i].apelido + ' - (' + this.vlrTotalComissao.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) + ')';
        }
      }
    }
  }

  fechaComissao() {
    this.loading = true;
    this.checaStatusComissao();
    if (this.comissaoStatus === true) {
      this.alertService.alert('Alteração em cima de comissão fechada não é permitida!', 'error');
      this.loading = false;
      return;
    } else if (this.comissaoFechada) {
      this.alertService.alert('Essa comissão já foi fechada!', 'error');
      this.loading = false;
    }
    else if (!this.comissao.nucom && !this.comissaoFechada && !this.nucom) {
      this.alertService.alert('Fechamento não iniciado!', 'error');
      this.loading = false;
      return;
    }
    else {
      this.comissaoService.fechaComissao(this.nucom, this.codusuresp).subscribe((res) => {
        console.log(res);
        this.comissaoFechada = res.nucom;
        this.loading = false;
        this.alertService.alert('Comissão fechada!', 'success');
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.showServicos = false;
          this.loading = false;
          this.alertService.alert('Algo deu errado.', 'error');
        });
    }
  }

  checaStatusComissao() {
    if (this.comissao.fechado === 'S') {
      console.log('aaaaa')
      this.loading = false;
      this.comissaoStatus = true;
      return;
    }
    // else if (!this.comissao.nucom && !this.comissaoFechada) {
    //   this.alertService.alert('Fechamento não iniciado!', 'error');
    //   this.loading = false;
    //   this.comissaoStatus = true;
    //   return;
    // }

  }

  geraRelatorioPDFComissao() {
    /**VERIFICA SE A COMISSÃO JÁ ESTÁ FECHADA */
    if (this.comissao.fechado === 'S' || this.comissaoFechada != 0) {

      /**CHECA SE ESTÁ NA VERSÃO MOBILE */
      if (environment.MOBILE) {
        this.comissaoService.geraRelatorioUrlPorNucom(this.nucom, this.codusuresp).subscribe((res) => {
          let url = environment.urlRelatorio + res[0];
          cordova.InAppBrowser.open(url, '_system');
        },
          (error) => {
            console.log('deu ruim')
            this.alertService.alert('Algo deu errado.', 'error');
          })
      } else {
        // this.comissaoService.geraRelatorioPorNucom(this.nucom).subscribe((res) => {
        //   var blob = new Blob([res], { type: 'application/pdf' });
        //   var blobURL = URL.createObjectURL(blob);
        //   console.log(blobURL)
        //   window.open(blobURL);
        // },
        //   (error) => {
        //     console.log('deu ruim')
        //     this.alertService.alert('Algo deu errado.', 'error');
        //   })

        this.comissaoService.geraRelatorioUrlPorNucom(this.nucom, this.codusuresp).subscribe((res) => {
          let url = environment.urlRelatorio + res[0];
          window.open(url);
        },
          (error) => {
            console.log(error)
            console.log('deu ruim')
            this.alertService.alert('Algo deu errado.', 'error');
          })
      }
    }
  }



  geraRelatorioExcelComissao() {
    /**VERIFICA SE A COMISSÃO JÁ ESTÁ FECHADA */
    if (this.comissao.fechado === 'S' || this.comissaoFechada != 0) {

      /**CHECA SE ESTÁ NA VERSÃO MOBILE */
      if (environment.MOBILE) {
        this.comissaoService.geraRelatorioExcel(this.nucom, this.codusuresp).subscribe((res) => {
          let url = environment.urlRelatorio + res[0]
          cordova.InAppBrowser.open(url, '_system');
        },
          (error) => {
            console.log('deu ruim')
            this.alertService.alert('Algo deu errado.', 'error');
          })
      } else {
        this.comissaoService.geraRelatorioExcel(this.nucom, this.codusuresp).subscribe((res) => {
          // var blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          // var blobURL = URL.createObjectURL(blob);
          // console.log(blobURL)
          let url = environment.urlRelatorio + res[0]
          window.open(url);
        },
          (error) => {
            console.log('deu ruim')
            this.alertService.alert('Algo deu errado.', 'error');
          })
      }
    }
  }



  /**METODO DE TOAST */
  showError(titulo: string, mensagem: string) {
    if (screen.width < 640 || screen.height < 480) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else if (screen.width < 1024 || screen.height < 768) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else {
      this.messageService.add({
        key: 'tr',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    }
  }



  /**RECUPERA AS PERMISSÕES */
  recuperaPermissao() {
    console.log('recuperei as permissoes')
    this.permissao = this.permissoesService.getPermissao();
    console.log(this.permissao)
    if (this.permissao != undefined && this.permissao.length > 0) {
      if (this.permissao[7].acao[0].PERMITE === 'S') {
        this.cadastrar = true;
      }
      if (this.permissao[7].acao[1].PERMITE === 'S') {
        this.alterar = true;
      }

      /**ATRIBUI O CODCLI VINCULADO NA PERMISSAO DO FUNCIONARIO, PARA FAZER AS OPERAÇÕES DA TELA */
      this.codcli = this.permissao[0].codcli;
    }
  }

}
