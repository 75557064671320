<div class="pt-7">


    <div class="flex flex-column  pb-3">
        <div class="flex align-items-center justify-content-center">
            <img src="./assets/iconeConfigFinanceiro.svg" class="iconeConfig" />
        </div>
        <div class="titulonegritoConfig flex align-items-center justify-content-center">
            <span>Configuração Financeira</span>
        </div>
    </div>

    <div class="flex justify-content-center flex-wrap ">


        <div class="xl:col-5 lg:col-5 md:col-5 sm:col-5">
            <div class="shadow-4">
                <p-card header="Baixa automática" id="cardConfigBaixa">

                    <div class="flex justify-content-center flex-wrap pb-3">
                        <span class="fontSubHeaderConfig">Preferência para baixar agendamentos automaticamente</span>
                    </div>

                    <p-table [value]="estabelecimento" sortField="nome" sortMode="single" [scrollable]="true"
                        rowGroupMode="subheader" groupRowsBy="nome">

                        <ng-template pTemplate="header">
                            <tr>
                                <th class="fontTituloTabela">Regra</th>
                            </tr>
                        </ng-template>


                        <ng-template pTemplate="groupheader" let-estabelecimento>
                            <tr pRowGroupHeader
                                style="background-color: var(--cor8); height: 30px;border-radius: 10px 10px 10px 10px;">
                                <td>
                                    <div>
                                        <span class="fontNomeEmpresa">{{estabelecimento.nome}}</span>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>


                        <ng-template pTemplate="body" let-estabelecimento>
                            <tr style="height: 38px;">
                                <td style="justify-content: start;">
                                    <span class="fontConteudoTabelaBaixa">
                                        Baixar agendamentos automaticamente?
                                    </span>
                                </td>
                                <td style="justify-content: end;">
                                    <p-inputSwitch  [(ngModel)]="estabelecimento.acaoBaixa"></p-inputSwitch>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>



                    <div *ngIf="!loading" [style]="{width: '100%'}">
                        <button pButton type="button" label="Atualizar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                    'border-radius': '11px'}" (click)="confirmaExclusaoEstabelecimento()"></button>
                    </div>
                    <div *ngIf="loading" [style]="{width: '100%'}">
                        <button id="botaoLoading" pButton [disabled]="true" type="button" label="Atualizando"
                            class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                    'border-radius': '11px'}"></button>
                    </div>
                </p-card>
            </div>
        </div>

    </div>
</div>

<p-confirmDialog #cd [closable]="false" id="confirmConfigBaixa">
    <ng-template pTemplate="footer">
        <button type="button" pButton icon="pi pi-times" label="Retornar" (click)="cd.reject()"></button>
        <button type="button" pButton icon="pi pi-check" label="Confirmar" (click)="cd.accept()"></button>
    </ng-template>
</p-confirmDialog>
