import { LoginService } from './../login/login.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { UtilitariosService } from 'src/assets/utilitarios.service';
import { MessageService } from 'primeng/api';
import { AlertService } from 'src/alert.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-alterar-senha',
  templateUrl: './alterar-senha.component.html',
  styleUrls: ['./alterar-senha.component.scss'],
  providers: [MessageService],
  encapsulation: ViewEncapsulation.None
})
export class AlterarSenhaComponent implements OnInit {

  oldpassword: string = '';
  newpassword1: string = '';
  newpassword2: string = '';
  email: string = '';
  loading: boolean = false;
  codusuresp: string = '';


  constructor(private decodedTokenService: DecodedTokenService, private loginService: LoginService, private utilitariosService: UtilitariosService,
    private messageService: MessageService, private router: Router, private alertService: AlertService) { }

  ngOnInit(): void {
    this.recuperaEmail();
    this.recuperaCodUsu();
  }

  recuperaEmail() {
    this.email = Object(this.decodedTokenService.getDecodedAccessToken()).data.EMAIL;
    console.log(this.email)
  }

  clickVoltar() {
    this.router.navigate(['/']);
  }

  recuperaCodUsu() {
    this.codusuresp = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }



  alterarSenha() {
    //var user = { email: this.email, oldPassword: this.oldpassword, newPassword: this.newpassword1 };
    this.loading = true;
    if (this.utilitariosService.validaCampo([this.newpassword1, this.newpassword2])) {
      this.showError('Atenção', 'Você precisa informar a senha.')
      this.loading = false;
      return;
    }
    if (this.newpassword1 === this.newpassword2 && this.oldpassword.length >= 3) {
      this.loginService.updatePasswordLogado(this.email, this.oldpassword, this.newpassword1, this.codusuresp, environment.CODTIPSEG).subscribe((res) => {
        console.log(res);
        this.loading = false;
        setTimeout(() => {
          this.router.navigate(['/']);
        }, 1500)
        this.alertService.alert('Sua senha foi alterada.', 'success')
      },
        (erro) => {
          console.log(Object(erro).error.message)
          this.loading = false;
          this.alertService.alert(Object(erro).error.message, 'error');
        });
    } else {
      this.showError('Atenção', 'As senhas não correspondem.')
      this.loading = false;
      this.loading = false;
    }
  }

  /**METODO DE TOAST */
  showError(titulo: string, mensagem: string) {
    if (screen.width < 640 || screen.height < 480) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else if (screen.width < 1024 || screen.height < 768) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else {
      this.messageService.add({
        key: 'tr',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    }
  }

}
