<p-toast position="bottom-center" key="bc"></p-toast>
<p-toast position="top-right" key="tr"></p-toast>
<div class="pt-7">

    <div class="flex flex-column  pb-3">
        <div class="flex align-items-center justify-content-center">
            <img src="./assets/iconeCadastrarProdutos.svg" class="iconeCadastrarServico" />
        </div>

        <div class=" flex align-items-center justify-content-center">
            <span
                class="tituloNegritoProduto  maximoCaracter white-space-nowrap overflow-hidden text-overflow-ellipsis">{{exibeNome}}</span>
        </div>
    </div>

    <div class="flex justify-content-center flex-wrap ">
        <div class="xl:col-5 lg:col-5 md:col-5 sm:col-5">
            <div class="shadow-4">
                <p-card header="Dados do Produto" id="cardProduto">
                    <div>
                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            (click)="mostraDialogProduto()">
                            <div class="flex gap-2 ">
                                <img src="./assets/imgServicoCadastro.svg" class=" xl:w-3rem" />
                                <div class="flex flex-column  align-items-start">
                                    <div class=" fontTituloProduto">
                                        <span class="xl:text-xl">Produtos</span>
                                    </div>
                                    <div class="fontCinzaProduto">
                                        <span class="xl:text-base">Preencher produtos, preços, comissão</span>
                                    </div>
                                </div>
                            </div>
                            <div class="align-items-center justify-content-center ">
                                <img src="./assets/imgBarbeariaPopup.png" />
                            </div>

                        </div>

                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            (click)="mostraDialogFotoProduto()">
                            <!-- <div class="col"> -->
                            <div class="flex gap-2 ">
                                <img src="./assets/imgCamera.svg" class="iconeCamera" class=" xl:w-3rem" />
                                <div class="flex flex-column align-items-start">
                                    <div class="fontTituloProduto">
                                        <span class="xl:text-xl">Foto do Produto</span>
                                    </div>
                                    <div class="fontCinzaProduto ">
                                        <span class="xl:text-base">Essa é a foto de seu produto durante a venda</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex align-items-center justify-content-end ">
                                <img src="./assets/imgBarbeariaPopup.png" />
                            </div>

                            <!-- </div> -->
                        </div>

                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            (click)="mostraDialogEstoque()">
                            <div class="flex gap-2 ">
                                <img src="./assets/imgEstoqueProduto.svg" class=" xl:w-3rem" />
                                <div class="flex flex-column align-items-start">
                                    <div class=" fontTituloProduto">
                                        <span class="xl:text-xl">Estoque</span>
                                    </div>
                                    <div class="fontCinzaProduto">
                                        <span class="xl:text-base">Informe o saldo geral para validação na venda</span>
                                    </div>
                                </div>
                            </div>
                            <div class="align-items-center justify-content-center ">
                                <img src="./assets/imgBarbeariaPopup.png" />
                            </div>
                        </div>

                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            (click)="mostraDialogAtivarDesativarProduto()">
                            <div class="flex gap-2 ">
                                <img src="./assets/imgLigarCadastro.svg" class=" xl:w-3rem" />
                                <div class="flex flex-column align-items-start">
                                    <div class=" fontTituloProduto">
                                        <span class="xl:text-xl">Ativar/Desativar </span>
                                    </div>
                                    <div class="fontCinzaProduto">
                                        <span class="xl:text-base">Tornar produto ativo na listagem ao
                                            cliente</span>
                                    </div>
                                </div>
                            </div>
                            <div class="align-items-center justify-content-center ">
                                <img src="./assets/imgBarbeariaPopup.png" />
                            </div>
                        </div>
                    </div>
                </p-card>
            </div>
        </div>

        <!--                          DIALOG DO Produto              -->
        <p-dialog header="Informações do Produto" [(visible)]="showProduto" [modal]="true"
            [style]="{'width': '350px', 'height': '410px'}" id="dialogProduto">
            <div class="col-12">
                <div class="fontDialogProduto flex align-items-center justify-content-center ">
                    <span>Informe alguns dados do produto</span>
                </div>
            </div>
            <input type="text" pInputText placeholder="Nome do Produto" [(ngModel)]="nomeprod"
                [style]="{'width': '100%'}">

            <div>
                <textarea pInputTextarea placeholder="Descrição do Produto" [(ngModel)]="descrprod" [autoResize]="false"
                    class="mt-1 " [style]="{'width': '100%', 'height': '20%'}"></textarea>
            </div>
            <div class="pt-1 p-fluid">
                <p-inputNumber [(ngModel)]="preco" mode="currency" currency="BRL" locale="pt-BR"
                    placeholder="Valor do Produto" [minFractionDigits]="2" id="inputPreco">
                </p-inputNumber>
            </div>

            <div class="pt-1 p-fluid">
                <p-inputNumber [(ngModel)]="comissao" suffix="%" id="percentProduto" placeholder="% Comissão"
                    [minFractionDigits]="2" [max]="100" [min]="0"></p-inputNumber>
            </div>

            <div *ngIf="!loading" [style]="{width: '100%'}">
                <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                'border-radius': '11px'}" (click)="salvarProduto()"></button>
            </div>
            <div *ngIf="loading" [style]="{width: '100%'}">
                <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 mt-3"
                    [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                'border-radius': '11px'}"></button>
            </div>

        </p-dialog>


        <!--                          DIALOG ATIVA/DESATIVA             -->

        <p-dialog header="Ativar/ Desativar Produto" [(visible)]="showAtivarDesativarProduto" [modal]="true"
            [style]="{'width': '350px', 'height': '249px'}" id="dialogAtvDstProduto">
            <div class="flex justify-content-center flex-wrap col">
                <div class="fontDialogAtvProduto flex align-items-center justify-content-center">
                    <span>Se ativo, o produto estará disponível como opção para ser comprado</span>
                </div>
            </div>
            <!-- <div class="fontDialogAtvObsServico flex align-items-center justify-content-center pb-4 pt-2">
                <span>Obs: Para ativar o barbeiro, é necessário que ele tenha conta!</span>
            </div> -->

            <div class="p-field-radiobutton pb-1">
                <p-radioButton value="S" [(ngModel)]="valorSelecionadoProduto"></p-radioButton>
                <label class="pl-2">Ativar produto</label>
            </div>
            <div class="p-field-radiobutton pb-1">
                <p-radioButton value="N" [(ngModel)]="valorSelecionadoProduto"></p-radioButton>
                <label class="pl-2">Desativar produto</label>
            </div>



            <div *ngIf="!loading" [style]="{width: '100%'}">
                <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                        'border-radius': '11px'}" (click)="ativaDesativaProduto()"></button>
            </div>
            <div *ngIf="loading" [style]="{width: '100%'}">
                <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 mt-3"
                    [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                        'border-radius': '11px'}"></button>
            </div>
        </p-dialog>



        <!--                          DIALOG DO ESTOQUE              -->
        <p-dialog header="Estoque do produto" [(visible)]="showEstoque" [modal]="true" [draggable]="false"
            [style]="{'width': '350px', 'height': '220px'}" id="dialogProduto">
            <div class="col-12">
                <div class="fontDialogProduto flex align-items-center justify-content-center ">
                    <span>Informe a quantidade do produto em estoque</span>
                </div>
            </div>

            <div class="p-fluid">
                <p-inputNumber [(ngModel)]="saldo" placeholder="Estoque" [min]="0" id="inputPreco">
                </p-inputNumber>
            </div>

            <div *ngIf="!loading" [style]="{width: '100%'}">
                <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
        'border-radius': '11px'}" (click)="preencheEstoque()"></button>
            </div>
            <div *ngIf="loading" [style]="{width: '100%'}">
                <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 mt-3"
                    [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
        'border-radius': '11px'}"></button>
            </div>

        </p-dialog>


        <!--                          DIALOG DA FOTO DO PRODUTO               -->
        <p-dialog header="Imagem do Produto" [(visible)]="showFotoProduto" [modal]="true"
            [style]="{'width': '350px', 'height': '220px'}" [closable]="true" id="dialogProduto">
            <div class="flex flex-column  align-items-center">
                <div class="flex justify-content-center flex-wrap col">
                    <div class="fontDialogProduto flex align-items-center justify-content-center ">
                        <span>Informe a imagem de seu produto.</span>
                    </div>
                </div>

                <input #fileInput accept="image/png, image/gif, image/jpeg" type="file" id="botaoImg"
                    (change)="mudaNomeFoto()" />

                <div class="mt-2 ">
                    <div class="flex align-items-center justify-content-start labelImagem">
                        <label for="">
                            <img src="./assets/uploadImgs.png" class="ajustaInputImagem pl-2" />
                        </label>
                        <div class="fontUploadImagem flex align-items-center justify-content-center pl-2 ">
                            <label for="botaoImg" class=""> {{nomeImagem}}</label>
                        </div>
                    </div>
                </div>



                <div *ngIf="!loading" [style]="{width: '100%'}">
                    <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
         'border-radius': '11px'}" (click)="onFileUpload()"></button>
                </div>
                <div *ngIf="loading" [style]="{width: '100%'}">
                    <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando"
                        class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
         'border-radius': '11px'}"></button>
                </div>
            </div>
        </p-dialog>

    </div>
    <br>