import { NotificacoesService } from './../notificacoes.service';
import { PesquisaService } from 'src/pesquisa.service';
import { environment } from './../environments/environment';
import { InformativoRecuperado } from './../assets/informativo';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { filter, Subject } from 'rxjs';
import { AgendaService } from 'src/agenda.service';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { InformativoService } from 'src/informativo.service';
import { UsuarioService } from 'src/usuario.service';
import { Estabelecimento } from 'src/assets/estabelecimento';
import { EstabelecimentoService } from 'src/estabelecimento.service';
import { Pergunta } from 'src/assets/pesquisa';
import { AlertService } from 'src/alert.service';
import { Notificacao } from 'src/assets/notificacao';
import { PermissoesService } from 'src/permissoes.service';
import { LoginService } from 'src/login/login.service';
import { PushNotificationsService } from 'ng-push';
import { OwlOptions } from 'ngx-owl-carousel-o';



declare var cordova: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None //!!!!!!!!!!!!!!!!!!!!MEU DEUS!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
})
export class HomeComponent implements OnInit {

  visibleSidebar1: boolean = false;
  visibleSidebar2: boolean = false;
  exibeBotoes: boolean = false;
  exibeMenu: boolean = true;
  itemsHome: MenuItem[] = [];
  menuPerfil: MenuItem[] = [];
  exibeHome: boolean = false;
  cabecalhoVazio = {};
  mostraCarregando: boolean = true;

  informativo: InformativoRecuperado[] = []
  informativoHome: InformativoRecuperado = {};

  estabelecimento: Estabelecimento[] = [];

  codUsu: string = '';
  nomeparc: string = '';
  codemp: number = 0;
  codcli: number = 0;
  codfunc: number = 0;
  resMobile: boolean = false;
  resPc: boolean = false;
  nomeemp: string = '';
  pergunta: Pergunta[] = [];
  resposta: any;
  resp: any[] = [];
  respostaRate: number = 5;
  respostaTexto: string = "";
  respostaDecimal: number = 0;
  respostaInteiro: number = 0;
  caracteresRestantes: number = 0;
  showPesquisa: boolean = false;
  index: number = 0;
  loading: boolean = false;
  notificacoes: any[] = [];
  qtdNot: number = 0;
  token: string = '';

  carouselOptionsMobile: any = {
    items: 2,
    loop: true,
    autoplay: true,
    autoplayTimeout: 3500,
  };

  carouselOptionsPc: any = {
    items: 1,
    loop: true,
    autoplay: true,
    autoplayTimeout: 3500,
  };


  constructor(private router: Router, private decodedTokenService: DecodedTokenService, private agendaService: AgendaService,
    private usuarioService: UsuarioService, private informativoService: InformativoService, private estabelecimentoService: EstabelecimentoService,
    private pesquisaService: PesquisaService, private alertService: AlertService, private notificacoesService: NotificacoesService,
    private loginService: LoginService, private _pushNotifications: PushNotificationsService,) {
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        console.log(Object(event).url);

        if (Object(event).url === '/') {
          this.exibeHome = true;
          this.recuperaInfoUsu();
          //this.buscaInformativoPorCodEmp();
          this.verificaResolucao();

        } else {
          this.exibeHome = false;
        } if (Object(event).url === '/detalheNotificacao') {
        }

        //this.exibeMenu = Object(event).url === '/login'  ? false : true;
      });
  }

  ngOnInit(): void {
    this.recuperaInfoUsu();

    /**CHECA SE ESTÁ NA VERSÃO MOBILE */

    if (environment.MOBILE) {
      this.buscaTokenCelular();
    }
  }


  criaMenu() {

    if (this.codcli > 0) {
      this.itemsHome = [
        {
          label: 'Menu Principal',
          icon: 'pi pi-fw pi-home',
          routerLink: ['/'],
          command: (event) => {
            this.visibleSidebar1 = false;
          },
        },
        {
          label: 'Agendar',
          icon: 'pi pi-fw pi-calendar-plus',
          command: (event) => {
            this.visibleSidebar1 = false;
            this.clickAgendar();
            //this.agendaService.setCabAgenda(this.cabecalhoVazio);
          },
          routerLink: ['/agendar'],
        },
        {
          label: 'Minha Agenda',
          icon: 'pi pi-fw pi-calendar',
          command: (event) => {
            this.visibleSidebar1 = false;
          },
          routerLink: ['/minhaAgenda'],
        },

        {
          label: 'Buscar Barbearia',
          icon: 'pi pi-search',
          routerLink: ['/buscarEstabelecimento'],
          command: (event) => {
            this.visibleSidebar1 = false;
          },
        },
        {
          label: 'Programações',
          icon: 'pi pi-fw pi-calendar-times',
          command: (event) => {
            this.visibleSidebar1 = false;
          },
          routerLink: ['/agenda'],
        },
        {
          label: 'Mov. Financeira',
          icon: 'pi pi-fw pi-wallet',
          command: (event) => {
            this.visibleSidebar1 = false;
          },
          routerLink: ['/financeiro'],
        },
        // {
        //   label: 'Pacotes',
        //   icon: 'pi pi-fw pi-wallet',
        //   command: (event) => {
        //     this.visibleSidebar1 = false;
        //   },
        // },
        // {
        //   label: 'Fidelidade',
        //   icon: 'pi pi-fw pi-star-fill',
        //   command: (event) => {
        //     this.visibleSidebar1 = false;
        //   },
        // },
        {
          label: 'Vendas',
          icon: 'pi pi-fw pi-shopping-cart',
          routerLink: ['/pedidos'],
          command: (event) => {
            this.visibleSidebar1 = false;
          },
        },

        {
          label: 'Menu Administrativo',
          icon: 'pi pi-cog',
          routerLink: ['/menuAdministrativo'],
          command: (event) => {
            this.visibleSidebar1 = false;
          },
        },
        {
          label: 'Contato',
          icon: 'pi pi-fw pi-whatsapp',
          routerLink: ['/contato'],
          command: (event) => {
            this.visibleSidebar1 = false;
            this.clickContato();
          },
        },
        {
          label: 'Sobre',
          icon: 'pi pi-info-circle',
          routerLink: ['/sobre'],
          styleClass: 'sobre',
          command: (event) => {
            this.visibleSidebar1 = false;
          },
        }
      ];
    }
    else if (this.codfunc > 0) {
      this.itemsHome = [
        {
          label: 'Menu Principal',
          icon: 'pi pi-fw pi-home',
          routerLink: ['/'],
          command: (event) => {
            this.visibleSidebar1 = false;
          },
        },
        {
          label: 'Agendar',
          icon: 'pi pi-fw pi-calendar-plus',
          command: (event) => {
            this.visibleSidebar1 = false;
            this.clickAgendar();
            //this.agendaService.setCabAgenda(this.cabecalhoVazio);
          },
          routerLink: ['/agendar'],
        },
        {
          label: 'Minha Agenda',
          icon: 'pi pi-fw pi-calendar',
          command: (event) => {
            this.visibleSidebar1 = false;
          },
          routerLink: ['/minhaAgenda'],
        },

        {
          label: 'Buscar Barbearia',
          icon: 'pi pi-search',
          routerLink: ['/buscarEstabelecimento'],
          command: (event) => {
            this.visibleSidebar1 = false;
          },
        },
        {
          label: 'Programações',
          icon: 'pi pi-fw pi-calendar-times',
          command: (event) => {
            this.visibleSidebar1 = false;
          },
          routerLink: ['/agenda'],
        },
        {
          label: 'Vendas',
          icon: 'pi pi-fw pi-shopping-cart',
          routerLink: ['/pedidos'],
          command: (event) => {
            this.visibleSidebar1 = false;
          },
        },
        {
          label: 'Painel Barber',
          icon: 'pi pi-fw pi-desktop',
          command: (event) => {
            this.visibleSidebar1 = false;
          },
          routerLink: ['/painelBarber'],
        },
        // {
        //   label: 'Pacotes',
        //   icon: 'pi pi-fw pi-wallet',
        //   command: (event) => {
        //     this.visibleSidebar1 = false;
        //   },
        // },
        // {
        //   label: 'Fidelidade',
        //   icon: 'pi pi-fw pi-star-fill',
        //   command: (event) => {
        //     this.visibleSidebar1 = false;
        //   },
        // },
        {
          label: 'Contato',
          icon: 'pi pi-fw pi-whatsapp',
          routerLink: ['/contato'],
          command: (event) => {
            this.visibleSidebar1 = false;
            this.clickContato();
          },
        },
        {
          label: 'Sobre',
          icon: 'pi pi-info-circle',
          routerLink: ['/sobre'],
          styleClass: 'sobre',
          command: (event) => {
            this.visibleSidebar1 = false;
          },
        }
      ];
    }

    else {
      this.itemsHome = [
        {
          label: 'Menu Principal',
          icon: 'pi pi-fw pi-home',
          routerLink: ['/'],
          command: (event) => {
            this.visibleSidebar1 = false;
          },
        },
        {
          label: 'Agendar',
          icon: 'pi pi-fw pi-calendar-plus',
          command: (event) => {
            this.visibleSidebar1 = false;
            this.clickAgendar();
            //this.agendaService.setCabAgenda(this.cabecalhoVazio);
          },
          routerLink: ['/agendar'],
        },
        {
          label: 'Minha Agenda',
          icon: 'pi pi-fw pi-calendar',
          command: (event) => {
            this.visibleSidebar1 = false;
          },
          routerLink: ['/minhaAgenda'],
        },

        // {
        //   label: 'Pacotes',
        //   icon: 'pi pi-fw pi-wallet',
        //   command: (event) => {
        //     this.visibleSidebar1 = false;
        //   },
        // },
        // {
        //   label: 'Fidelidade',
        //   icon: 'pi pi-fw pi-star-fill',
        //   command: (event) => {
        //     this.visibleSidebar1 = false;
        //   },
        // },

        {
          label: 'Buscar Barbearia',
          icon: 'pi pi-search',
          routerLink: ['/buscarEstabelecimento'],
          command: (event) => {
            this.visibleSidebar1 = false;
          },
        },
        {
          label: 'Contato',
          icon: 'pi pi-fw pi-whatsapp',
          routerLink: ['/contato'],
          command: (event) => {
            this.visibleSidebar1 = false;
            this.clickContato();
          },
        },
        {
          label: 'Sobre',
          icon: 'pi pi-info-circle',
          routerLink: ['/sobre'],
          styleClass: 'sobre',
          command: (event) => {
            this.visibleSidebar1 = false;
          },
        }
      ];
    }


    this.menuPerfil = [
      // {
      //   label: `Meus Pontos `,
      //   styleClass: 'pontos1',
      //  disabled: true,
      //   command: (event) => {
      //    // this.visibleSidebar2 = false;
      //   },
      // },
      // {
      //   label: ` 120 ptos`,
      //   styleClass: 'pontos2',
      //   disabled: true,
      //   command: (event) => {
      //    // this.visibleSidebar2 = false;
      //   },
      // },
      {
        label: 'Meu Perfil',
        command: (event) => {
          this.visibleSidebar2 = false;
        },
        routerLink: ['/perfil'],
      },
      {
        label: 'Alterar senha',
        command: (event) => {
          this.visibleSidebar2 = false;
        },
        routerLink: ['/alterarSenha'],
      },
      {
        label: 'Sair',
        command: (event) => {
          this.visibleSidebar2 = false;

          /**DESVINCULA O TOKEN DO APARECELHO APOS O LOGOUT */
          if (environment.MOBILE) {
            this.atualizaTokenCelular(null);
          }

          /**REMOVE O TOKEN DO LOCALSTORAGE PARA O LOGOUT */
          localStorage.removeItem('token');

          // this.permissoesService.limpaPermissao();
          // window.location.reload();
        },
        routerLink: ['/login']
      }
    ];
  }

  recuperaInfoUsu() {
    this.codUsu = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
    this.codcli = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODCLI;


    this.meuPerfil();
    this.buscaPerguntasPendentes();
    this.buscaNotificacoes();
    setInterval(() => {
      this.buscaNotificacoes();
    }, 300000);
  }


  meuPerfil(): void {
    this.usuarioService.getPerfil(this.codUsu, this.codUsu).subscribe((res) => {
      console.log(res)
      this.nomeparc = Object(res)[0].nomeparc;
      this.codfunc = Object(res)[0].codfunc;
      this.codemp = Object(res)[0].codemp;

      // this.permissoesGuard();

      this.buscaInformativoPorCodEmp();
      this.criaMenu();
      this.buscarEstabelecimento();
    });
  }



  clickAgendar() {
    this.usuarioService.getPerfil(this.codUsu, this.codUsu).subscribe((res) => {
      this.codemp = Object(res)[0].codemp;
      if (this.codemp != 0) {
        this.agendaService.setCabAgenda(this.cabecalhoVazio);
        this.router.navigate(['/agendar']);
      } else {
        this.router.navigate(['/buscarEstabelecimento']);
      }
    })
  }




  clickEstabelecimento() {
    this.router.navigate(['/buscarEstabelecimento']);
  }

  clickMinhaAgenda() {
    this.router.navigate(['/minhaAgenda']);
  }

  clickContato() {
    this.usuarioService.getPerfil(this.codUsu, this.codUsu).subscribe((res) => {
      this.codemp = Object(res)[0].codemp;
      if (this.codemp != 0) {
        this.agendaService.setCabAgenda(this.cabecalhoVazio);
        this.router.navigate(['/contato']);
      } else {
        this.router.navigate(['/buscarEstabelecimento']);
      }
    })
  }

  clickMieuPerfil() {
    this.router.navigate(['/perfil']);
  }



  buscaInformativoPorCodEmp() {
    this.informativoService.buscaInformativosPorCodEmp(this.codemp, this.codUsu, environment.CODTIPSEG).subscribe((res) => {
      this.mostraCarregando = false;
      this.informativo = res;
      for (let i = 0; i < this.informativo.length; i++) {
        this.informativo[i].filename = environment.imgInformativo + this.informativo[i].filename;
      }
    })
  }


  escolheuInformativo(informativo: InformativoRecuperado) {
    this.informativoService.setInformativo(informativo);
    this.router.navigate(['/detalheInformativo']);
  }

  verificaResolucao() {
    if (screen.width < 1024 || screen.height < 768) {
      this.resPc = false;
      this.resMobile = true;
    } else {
      this.resMobile = false;
      this.resPc = true;
    }
    window.addEventListener('resize', () => {
      if (screen.width < 1024 || screen.height < 768) {
        this.resPc = false;
        this.resMobile = true;
      } else {
        this.resMobile = false;
        this.resPc = true;
      }
    });

  }

  buscarEstabelecimento() {
    this.estabelecimentoService.contatoEstabelecimento(this.codemp.toString(), this.codUsu).subscribe((res) => {
      let auxRegex = Object(res)[0].nomeemp;
      auxRegex = auxRegex.match(/[^ ]+\s+[^ ]+$/)
      this.nomeemp = auxRegex[0];
      //const testeRegex = erro.match(/[^,]*,[^,]*,  (.*\..*)(.*\..*)/)
    });
  }

  fechaDialogPesquisa() {
    this.showPesquisa = false;
  }

  /**METODO PARA CONTAR OS CARACTERES DA PERGUNTA */
  contaCaracter() {
    if (this.caracteresRestantes < 151) {
      this.caracteresRestantes = this.respostaTexto.length;
    } else {
      return;
    }
  }


  buscaPerguntasPendentes() {
    this.pesquisaService.buscaPerguntasPendentesPorCodusu(this.codUsu, this.codUsu).subscribe((res) => {
      this.pergunta = res;
      if (this.pergunta.length > 0) {
        this.showPesquisa = true;
      }
    },
      (error) => {
        console.log('deu ruim a requisição')
        console.log(error)

      });
  }

  proximaPergunta() {
    let r: any;
    if (this.pergunta[0].tiporesp === 'E') {
      r = { "resposta": this.respostaRate, "nures": this.pergunta[0].nures };
    } else {
      r = { "resposta": this.resposta, "nures": this.pergunta[0].nures };
    }
    this.resp.push(r)
    this.pergunta.shift();
    this.resposta = '';
  }

  enviaResposta() {
    let r: any;
    if (this.pergunta[0].tiporesp === 'E') {
      r = { "resposta": this.respostaRate, "nures": this.pergunta[0].nures };
    } else {
      r = { "resposta": this.resposta, "nures": this.pergunta[0].nures };
    }
    this.resp.push(r)

    for (let i = 0; i < this.resp.length; i++) {
      this.pesquisaService.responderPerguntaPendente(this.resp[i].resposta, this.resp[i].nures, this.codUsu).subscribe((res) => {
        console.log(res)
        this.showPesquisa = false;
        this.loading = false;
        this.alertService.alert('Obrigado por responder nossa pesquisa!', 'success')
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.loading = false;
          this.showPesquisa = false;
          this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')
        });
    }
  }

  buscaNotificacoes() {
    this.qtdNot = 0;
    this.notificacoesService.buscaNotificacoesPorCodusu(this.codUsu, this.codUsu).subscribe((res) => {
      this.notificacoes = res;
      this.qtdNot = (this.notificacoes.filter((x: any) => x.lido === 'N')).length;

      if (!environment.MOBILE) {
        this.notificacaoWeb(this.notificacoes);
      }

    })
  }

  escolheuNotificacao(notificacao: Notificacao) {
    this.notificacoesService.setNotificacao(notificacao);
    this.router.navigate(['/detalheNotificacao']);

  }


  /**ATRIBUI AS PERMISSOES NO VETOR DO GUARD */
  // permissoesGuard() {
  //   this.permissoesService.recuperaPermissao(this.codfunc);
  // }



  /**CASO ESTEJA NO MOBILE IRÁ ATUALIZAR O TOKEN DO CODUSU */
  buscaTokenCelular() {
    cordova.plugins.firebase.messaging.getToken()
      .then((token: string) => {
        // Faça algo com o token, como enviar para o servidor
        this.token = token;
        console.log('Token do dispositivo:', token);
        this.atualizaTokenCelular(this.token);
      })
      .catch((error: any) => {
        console.error('Erro ao obter o token do dispositivo:', error);
      });
  }


  /**METODO QUE ATRIBUI O TOKEN DO APARELHO NO TSIUSU */
  atualizaTokenCelular(token: any) {
    this.loginService.atualizaTokenCelular(this.codUsu, token, this.codUsu).subscribe((res) => {
      console.log(res);
    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
        this.alertService.alert('Algo deu errado.', 'error');
        //this.showError('Atenção', 'Algo deu errado.')
      });
  }

  notificacaoWeb(notificacao: any) {
    for (let i = 0; i < notificacao.length; i++) {
      if (notificacao[i].statusweb === null) {
        let options = {
          //set options
          sticky: true,
          icon:
            '../assets/logo.png',
          body: notificacao[i].mensagem
        };


        this._pushNotifications.create(notificacao[i].titulo, options).subscribe(
          //creates a notification
          (res: any) => console.log(res),
          (err: any) => console.log(err)
        );


        this.notificacoesService.notificacaoWebEnviada(notificacao[i].nunot2, this.codUsu).subscribe((res) => {
          console.log(res);
        },
          (error) => {
            console.log(error)
            console.log('deu ruim')
          })
      }
    }




  }






}




