<p-toast position="bottom-center" key="bc"></p-toast>
<p-toast position="top-right" key="tr"></p-toast>

<div class="pt-7">


    <div class="flex flex-column  pb-3">
        <div class="flex align-items-center justify-content-center">
            <img src="./assets/iconeCadastrarCupom.svg" />
        </div>
        <div class=" flex align-items-center justify-content-center">
            <span
                class=" tituloNegritoDesconto ">Descontos</span>
        </div>
    </div>

    <div class="flex justify-content-center flex-wrap ">


        <div class="xl:col-5 lg:col-5 md:col-5 sm:col-5">
            <div class="shadow-4">


                <p-card header="Configurações para desconto" id="cardDesconto">
                    <div>
                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            routerLink="/cadastrarCupom">
                            <div class="flex gap-2 ">
                                <img src="./assets/cupom.svg" class=" xl:w-3rem" />
                                <div class="flex flex-column align-items-start">
                                    <div class=" fontTituloDesconto">
                                        <span class="xl:text-xl">Cupom de desconto</span>

                                    </div>
                                    <div class="fontCinzaDesconto">
                                        <span class="xl:text-base">Gerenciar cupons de desconto</span>
                                    </div>
                                </div>
                            </div>
                            <div class="align-items-center justify-content-center  ">
                                <img src="./assets/imgBarbeariaPopup.png" />
                            </div>
                        </div>

                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            (click)=" mostraDialogServicos()">
                            <div class="flex gap-2 ">
                                <img src="./assets/descontoProduto.svg" class=" xl:w-3rem" />
                                <div class="flex flex-column  align-items-start">
                                    <div class=" fontTituloDesconto">
                                        <span class="xl:text-xl">Desconto em serviço</span>

                                    </div>
                                    <div class="fontCinzaDesconto">
                                        <span class="xl:text-base">Desconto por serviço</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex align-items-center justify-content-center ">
                                <img src="./assets/imgBarbeariaPopup.png" class="iconePopUpEstabelecimento" />
                            </div>
                        </div>

                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            (click)="mostraDialogProfissionais()">
                            <div class="flex gap-2 ">
                                <img src="./assets/descontoProfissional.svg" class=" xl:w-3rem" />
                                <div class="flex flex-column align-items-start">
                                    <div class="fontTituloDesconto">
                                        <span class="xl:text-xl">Desconto por profissional</span>
                                    </div>
                                    <div class="fontCinzaDesconto">
                                        <span class="xl:text-base">Descontos em serviços do profissional</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex align-items-center justify-content-end  ">
                                <img src="./assets/imgBarbeariaPopup.png" class="iconePopUpEstabelecimento" />
                            </div>
                        </div>

                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            routerLink="/descontoTotal">
                            <div class=" flex gap-2 ">
                                <img src=" ./assets/descontoTotal.svg" class=" xl:w-3rem" />
                                <div class="flex flex-column align-items-start">
                                    <div class="fontTituloDesconto">
                                        <span class="xl:text-xl">Desconto no total dos serviços</span>

                                    </div>
                                    <div class="fontCinzaDesconto">
                                        <span class="xl:text-base">Desconto geral no agendamento</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex align-items-center justify-content-end ">
                                <img src="./assets/imgBarbeariaPopup.png" />
                            </div>
                        </div>

                        <div class="formgroup-inline justify-content-between flex-wrap"
                            (click)="mostraDialogFidelidade()">
                            <div class="flex gap-2 ">
                                <img src="./assets/fidelidade.svg" class=" xl:w-3rem" />
                                <div class="flex flex-column align-items-start">
                                    <div class="fontTituloDesconto">
                                        <span class="xl:text-xl">Programa de fidelidade</span>
                                    </div>
                                    <div class="fontCinzaDesconto">
                                        <span class="xl:text-base">Pontos por serviço e troca</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex align-items-center justify-content-end ">
                                <img src="./assets/imgBarbeariaPopup.png" />
                            </div>
                        </div>
                    </div>
                </p-card>
            </div>
        </div>
    </div>
    <br>
</div>

<!--                          DIALOG DE DESCONTO DOS SERVIÇOS               -->
<p-dialog header="Desconto em Serviços" [(visible)]="showServicos" [modal]="true"
    [breakpoints]="{'960px': '534px', '640px': '327px'}" [closable]="true" id="dialogServicos">
    <div class="flex justify-content-center flex-wrap col">
        <div class="fontDialogServicos flex align-items-center justify-content-center white-space-nowrap">
            <span>Habilite o desconto para cada serviço em específico</span>
        </div>
    </div>

    <p-table [value]="servico" sortField="apelido" sortMode="single" [scrollable]="true" scrollHeight="400px"
        rowGroupMode="subheader" groupRowsBy="apelido">

        <ng-template pTemplate="header">
            <tr style="height: 30px;">
                <th class="fontTituloTabela" style="width:215px; flex: 0 0 190px">Serviço</th>
                <th class="fontTituloTabela" style="flex: 0 0 140px;">Tipo desconto</th>
                <th class="fontTituloTabela" style="min-width:103px">Desconto</th>
                <th class="fontTituloTabela" style="min-width:1px"> <img src="./assets/iconeMenuAdm.png"
                        (click)="mostraDialogDescontoMassaServico()"></th>
            </tr>
        </ng-template>


        <ng-template pTemplate="groupheader" let-servico>
            <tr pRowGroupHeader style="background-color: var(--cor8); height: 35px;">
                <td style="flex: 0 0 10px;">
                    <p-checkbox (click)="clicouCheckProfissional()" [value]="servico.apelido"
                        [(ngModel)]="selecionouProfissional2"></p-checkbox>
                </td>
                <td style="flex: 0 0 100px;">
                    <div>
                        <span class="fontNomeProfissional">{{servico.apelido}}</span>
                    </div>
                </td>
                <td></td>
                <td></td>
            </tr>
        </ng-template>


        <ng-template pTemplate="body" let-servico>
            <tr style="height: 35px;">
                <td style="flex: 0 0 30px;">
                    <p-checkbox [value]="servico" [(ngModel)]="selecionouServico"></p-checkbox>
                </td>
                <td class="fontConteudoTabela" style="flex: 0 0 140px;">{{servico.nomeprod}}</td>
                <td style="flex: 0 0 138px;">
                    <p-radioButton value="P" [(ngModel)]="servico.tipdesc" (click)="zeraCampoDesconto(servico)"></p-radioButton>
                    <label class="pl-1 fontConteudoTabela"
                        [ngStyle]="servico.tipdesc==='R'?{'opacity': ' 0.3'} : {'opacity': '1'}">%</label>
                    <p-radioButton value="R" [(ngModel)]="servico.tipdesc" class="pl-2" (click)="zeraCampoDesconto(servico)"></p-radioButton>
                    <label class="pl-1 fontConteudoTabela"
                        [ngStyle]="servico.tipdesc==='P'?{'opacity': '0.3'} : {'opacity': '1'}">R$</label>

                </td>
                <td>
                    <div *ngIf="servico.tipdesc==='P'">
                        <p-inputNumber [(ngModel)]="servico.desc" inputId="percent" prefix="%" placeholder="Desconto"
                            id="descontoServicoSelec" [min]="0" [max]="99"> </p-inputNumber>
                    </div>

                    <div *ngIf="servico.tipdesc==='R'">
                        <p-inputNumber [(ngModel)]="servico.desc" inputId="currency-br" mode="currency" currency="BRL"
                            placeholder="Desconto" locale="pt-BR" id="descontoServicoSelec" [min]="0"> </p-inputNumber>

                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>


    <div *ngIf="!loading" [style]="{width: '100%'}" class="pt-3">
        <button pButton type="button" label="Atualizar" class="col-12 " [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
        'border-radius': '11px'}" (click)="atualizaDescontoServico()"></button>
    </div>
    <div *ngIf="loading" [style]="{width: '100%'}" class="pt-3">
        <button id="botaoLoading" pButton [disabled]="true" type="button" label="Atualizando" class="col-12 " [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
        'border-radius': '11px'}"></button>
    </div>
</p-dialog>


<!--                          DIALOG DO DESCONTO EM MASSA SERVIÇO              -->
<p-dialog header="Atualizar desconto em massa" [(visible)]="showDescontoMassa" [modal]="true"
    [style]="{'width': '350px', 'height': '200px'}" [closable]="true" id="dialogDescontoMassa">
    <div class="flex justify-content-center flex-wrap col">
        <div class="fontDialogServicos flex align-items-center justify-content-center">
            <span>Informação de desconto em massa</span>
        </div>

        <div class="mt-2">
            <div class=" flex ">

                <div class="flex-1 flex align-items-center justify-content-center mr-2">
                    <p-radioButton value="P" [(ngModel)]="descontoMassaSelecionado"></p-radioButton>
                    <label class="pl-1 fontConteudoTabela">%</label>

                    <p-radioButton value="R" [(ngModel)]="descontoMassaSelecionado" class="pl-2"></p-radioButton>
                    <label class="pl-1 fontConteudoTabela">R$</label>
                </div>




                <div *ngIf="descontoMassaSelecionado==='P'">
                    <p-inputNumber [(ngModel)]="descontoMassa" inputId="percent" prefix="%" placeholder="Desconto"
                        id="descontoServicoSelec" [min]="0" [max]="99"> </p-inputNumber>
                </div>

                <div *ngIf="descontoMassaSelecionado==='R'">
                    <p-inputNumber [(ngModel)]="descontoMassa" inputId="currency-br" mode="currency" currency="BRL"
                        locale="pt-BR" id="descontoServicoSelec" [min]="0"> </p-inputNumber>
                </div>

            </div>
        </div>

        <button pButton type="button" label="Preencher" class="col-12 mt-2" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
            'border-radius': '11px'}" (click)="preencheEmMassa()"></button>

    </div>
</p-dialog>

<!--                          DIALOG DE DESCONTO DOS PROFISSIONAIS               -->
<p-dialog header="Desconto por profissional" [(visible)]="showProfissionais" [modal]="true"
    [breakpoints]="{'960px': '534px', '640px': '327px'}" [closable]="true" id="dialogProfissionais">
    <div class="flex justify-content-center flex-wrap col">
        <div class="fontDialogServicos flex align-items-center justify-content-center">
            <span>Habilite o desconto para o profissional em questão</span>
        </div>
    </div>

    <p-table [value]="profissional" [scrollable]="true" scrollHeight="400px">

        <ng-template pTemplate="header">
            <tr style="height: 30px;">
                <th class="fontTituloTabela" style="width:215px; flex: 0 0 190px">Profissional</th>
                <th class="fontTituloTabela" style="flex: 0 0 140px;">Tipo desconto</th>
                <th class="fontTituloTabela" style="min-width:103px">Desconto</th>
                <th class="fontTituloTabela" style="min-width:1px"> <img src="./assets/iconeMenuAdm.png"
                        (click)="mostraDialogDescontoMassaProfissionais()"></th>
            </tr>
        </ng-template>



        <ng-template pTemplate="body" let-profissional>
            <tr style="height: 35px;">
                <td style="flex: 0 0 30px;">
                    <p-checkbox [value]="profissional" [(ngModel)]="selecionouProfissional"></p-checkbox>
                </td>
                <td class="fontConteudoTabela" style="flex: 0 0 140px;">{{profissional.apelido}}</td>
                <td style="flex: 0 0 138px;">
                    <p-radioButton value="P" [(ngModel)]="profissional.tipdesc" (click)="zeraCampoDesconto(profissional)"></p-radioButton>
                    <label class="pl-1 fontConteudoTabela"
                        [ngStyle]="profissional.tipdesc==='R'?{'opacity': ' 0.3'} : {'opacity': '1'}">%</label>
                    <p-radioButton value="R" [(ngModel)]="profissional.tipdesc" class="pl-2" (click)="zeraCampoDesconto(profissional)"></p-radioButton>
                    <label class="pl-1 fontConteudoTabela"
                        [ngStyle]="profissional.tipdesc==='P'?{'opacity': '0.3'} : {'opacity': '1'}">R$</label>

                </td>
                <td>
                    <div *ngIf="profissional.tipdesc==='P'">
                        <p-inputNumber [(ngModel)]="profissional.desc" inputId="percent" prefix="%" placeholder="Desconto"
                            id="descontoServicoSelec" [min]="0" [max]="99"> </p-inputNumber>
                    </div>

                    <div *ngIf="profissional.tipdesc==='R'">
                        <p-inputNumber [(ngModel)]="profissional.desc" inputId="currency-br" mode="currency" currency="BRL"
                            locale="pt-BR" id="descontoServicoSelec" [min]="0" placeholder="Desconto"> </p-inputNumber>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>

    <div *ngIf="!loading" [style]="{width: '100%'}" class="pt-3">
        <button pButton type="button" label="Atualizar" class="col-12 " [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
        'border-radius': '11px'}" (click)="atualizaDescontoProfissional()"></button>
    </div>
    <div *ngIf="loading" [style]="{width: '100%'}" class="pt-3">
        <button id="botaoLoading" pButton [disabled]="true" type="button" label="Atualizando" class="col-12 " [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
        'border-radius': '11px'}"></button>
    </div>
</p-dialog>


<!--                          DIALOG DE PONTOS DE FIDELIDADES DOS SERVIÇOS               -->
<p-dialog header="Programa de Fidelidade" [(visible)]="showFidelidade" [modal]="true"
    [breakpoints]="{'960px': '534px', '640px': '327px'}" [closable]="true" id="dialogServicos">
    <div class="flex justify-content-center flex-wrap col">
        <div class="fontDialogServicos flex align-items-center justify-content-center">
            <span>Habilite a pontuação que cada serviço gera ao cliente</span>
        </div>
    </div>

    <p-table [value]="servico" sortField="apelido" sortMode="single" [scrollable]="true" scrollHeight="400px"
        rowGroupMode="subheader" groupRowsBy="apelido">

        <ng-template pTemplate="header">
            <tr style="height: 30px;">
                <th class="fontTituloTabela" style="width:215px; flex: 0 0 190px">Serviço</th>
                <th class="fontTituloTabela" style="flex: 0 0 140px;">Habilita pontuação?</th>
                <th class="fontTituloTabela" style="min-width:103px">Pontos</th>
                <th class="fontTituloTabela" style="min-width:1px"> <img src="./assets/iconeMenuAdm.png"
                        (click)="mostraDialogPontosMassaServico()"></th>
            </tr>
        </ng-template>


        <ng-template pTemplate="groupheader" let-servico>
            <tr pRowGroupHeader style="background-color: var(--cor8); height: 35px;">
                <td style="flex: 0 0 10px;">
                    <p-checkbox (click)="clicouCheckProfissional()" [value]="servico.apelido"
                        [(ngModel)]="selecionouProfissional2"></p-checkbox>
                </td>
                <td style="flex: 0 0 100px;">
                    <div>
                        <span class="fontNomeProfissional">{{servico.apelido}}</span>
                    </div>
                </td>
                <td></td>
                <td></td>
            </tr>
        </ng-template>


        <ng-template pTemplate="body" let-servico>
            <tr style="height: 35px;">
                <td style="flex: 0 0 30px;">
                    <p-checkbox [value]="servico" [(ngModel)]="selecionouServico"></p-checkbox>
                </td>
                <td class="fontConteudoTabela" style="flex: 0 0 140px;">{{servico.nomeprod}}</td>
                <td style="flex: 0 0 138px;">
                    <!-- <p-inputSwitch [(ngModel)]="servico.habilitapont"></p-inputSwitch> -->
                    <p-inputSwitch [(ngModel)]="servico.habilitaPontuacao"
                        (click)="verificaHabilitaPontuacao(servico); zeraPontos(servico)"></p-inputSwitch>

                </td>
                <td>
                    <div *ngIf="servico.habilitapont==='S'">
                        <!-- <p-inputMask inputmode="numeric" [(ngModel)]="servico.ponto"
                            id="descontoServicoSelec"></p-inputMask> -->
                        <p-inputNumber [(ngModel)]="servico.pont" id="descontoServicoSelec" [min]="0"
                            placeholder="Pontos"> </p-inputNumber>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>


    <div *ngIf="!loading" [style]="{width: '100%'}" class="pt-3">
        <button pButton type="button" label="Atualizar" class="col-12 " [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
        'border-radius': '11px'}" (click)="atualizaPontosDoProduto()"></button>
    </div>
    <div *ngIf="loading" [style]="{width: '100%'}" class="pt-3">
        <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 " [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
        'border-radius': '11px'}"></button>
    </div>
</p-dialog>


<!--                          DIALOG DO PONTOS EM MASSA PONTOS              -->
<p-dialog header="Atualizar pontos em massa" [(visible)]="showPontosMassa" [modal]="true" [draggable]="false"
    [style]="{'width': '350px', 'height': '200px'}" [closable]="true" id="dialogDescontoMassa">
    <div class="flex justify-content-center flex-wrap col">
        <div class="fontDialogServicos flex align-items-center justify-content-center">
            <span>Informação de pontuação em massa</span>
        </div>

        <div class="mt-2">
            <div class=" flex ">
                <div class="flex-1 flex align-items-center justify-content-center mr-2">
                    <p-inputSwitch [(ngModel)]="habPontuacao"></p-inputSwitch>
                </div>
                <div>
                    <input type="number" pInputText [(ngModel)]="pontos" placeholder="Pontos" [min]="0"
                        [style]="{'width': '100%'}" [disabled]="!habPontuacao" />
                </div>
            </div>
        </div>

        <button pButton type="button" label="Preencher" class="col-12 mt-2" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
            'border-radius': '11px'}" (click)="preencheEmMassa()"></button>

    </div>
</p-dialog>