<div class="paddingTopFiliar1 ">


    <div class="flex justify-content-center flex-wrap">
        <div class="flex justify-content-center ">

            <img [src]="estabelecimento.filename" class="imgEstabelecimento" />
        </div>
    </div>

    <div class=" paddingTopFiliar2 flex align-items-center justify-content-center">
        <div class="containerFiliar">
            <div class="align-items-center justify-content-between  formgroup-inline">


                <div class="flex flex-column  alinhaInfoEstabelecimento">
                    <div class="fontcinza pb-2 ">
                        <div class="fontcinzanegrita">
                            <span>{{estabelecimento.nome}}</span>
                        </div>
                        <span> {{estabelecimento.rua}} {{estabelecimento.complemento}},
                            {{estabelecimento.numero}}</span>
                        <div>
                            <span> {{estabelecimento.setor}} - {{estabelecimento.cidade}} </span>
                        </div>
                        <div>
                            <span> {{estabelecimento.contato}}</span>
                        </div>
                    </div>
                </div>



                <div class="flex align-items-center justify-content-center  fontAvalicacao">
                    <span>5</span>
                    <img src="./assets/iconeAvaliacao.png" class="imgAvaliacao" />

                </div>


            </div>
        </div>
    </div>

    <div class=" flex justify-content-center pb-2  ">
        <div *ngIf="!teveResposta">
            <p-button id="botaoFiliarSpinner">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
            </p-button>
        </div>
        <div *ngIf="filiado===false && teveResposta===true">
            <p-button label="Filiar-se" id="botaoFiliar" (click)="filiarEmpresa()">
            </p-button>
        </div>
        <div *ngIf="filiado===true  && teveResposta===true ">
            <p-button label="Desfiliar-se" id="botaoFiliar" (click)="filiarEmpresa()">
            </p-button>
        </div>

    </div>
    <div class="flex alinhaInfoEstabelecimento  flex-wrap ">
        <table>
            <div class="contornoAbas formgroup-inline gap-3 ">
                <div (click)="clickServicos()">
                    <span class="col cursor-pointer fontAbas" [ngClass]="{'linhahorizontalAba': selecionouServicos}"
                        [ngStyle]="selecionouServicos?{  'color:': 'var(--cor22) !imporant', 'font-weight': 700} : {'color:': 'var(--cor1)', 'font-weight': 400}">Serviços</span>
                </div>
                <div class=" flex justify-content-center flex-wrap " *ngIf="escondePlanos">
                    <div class="vertical">
                    </div>
                </div>
                <div (click)="clickPlanos()" *ngIf="escondePlanos">
                    <span class="col cursor-pointer fontAbas" [ngClass]="{'linhahorizontalAba': selecionouPlanos}"
                        [ngStyle]="selecionouPlanos?{'color:': 'var(--cor22) !imporant', 'font-weight': 700} : {'color:': 'var(--cor1)', 'font-weight': 400}">Planos</span>
                </div>
            </div>

            <div class="linhahorizontalFiliar2 pb-1"></div>
        </table>
    </div>
    <div class="flex flex-column  pb-3">
        <div class="flex justify-content-center flex-wrap ">
            <div class="scroll-divFiliar">
                <table *ngIf="selecionouServicos">
                    <tr *ngFor="let servico of servico">
                        <td>
                            <div class="flex flex-column   align-items-center">
                                <div class="col tamanhoLinhaTabelaFiliar">
                                    <div class="flex gap-2 ">
                                        <div class="flex flex-column align-items-start">
                                            <div class=" fontmarron">
                                                <span>{{servico.nomeprod}} | ({{servico.minutos}} min)</span>
                                            </div>
                                            <div class="fontcinza">
                                                <span>{{servico.descrprod}}</span>
                                            </div>
                                            <div class="fontcinza">
                                                <span>Profissional: {{servico.apelido}}</span>
                                            </div>
                                            <div class="fontpreco">
                                                <span>{{servico.preco| currency : 'BRL'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="linhahorizontalFiliar1">
                            </div>
                        </td>
                    </tr>
                </table>

                <table *ngIf="selecionouPlanos">
                    <tr *ngFor="let plano of plano">
                        <td>
                            <div class="flex flex-column   align-items-center">
                                <div class="col tamanhoLinhaTabelaFiliar">
                                    <div class="flex gap-2 ">
                                        <div class="flex flex-column align-items-start">
                                            <div class="flex fontmarron">
                                                <div class="justify-content-center align-items-center">
                                                    <img src="./assets/seta.svg" class="cursor-pointer"
                                                        (click)="expandeInfoPlano(plano)" *ngIf="!plano.expande" />
                                                    <img src="./assets/seta2.svg" class="cursor-pointer"
                                                        (click)="guardaInfoPlano(plano)" *ngIf="plano.expande" />
                                                </div>
                                                <span>{{plano.nome}} |  </span>
                                             <span *ngIf="plano.duracao < 999"> ({{plano.duracao}} Dias)</span>
                                             <span *ngIf="plano.duracao == 999"> (Ilimitado)</span>

                                            </div>
                                            <div class="fontcinza">
                                                <span>{{plano.descr}}</span>
                                            </div>
                                            <div class="fontpreco">
                                                <span>{{plano.minutos}} minutos</span>
                                            </div>
                                            <div class="fontpreco">
                                                <span>{{plano.preco| currency : 'BRL'}}</span>
                                            </div>
                                            <div class="fontpreco">
                                                <span>{{plano.qtd}} usos</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="linhahorizontalFiliar1">
                            </div>

                            <div *ngIf="plano.expande">
                                <span class="fontcinza col"> Serviços / Produtos</span>
                                <div class="linhahorizontalFiliar1">
                                </div>

                                <div class="flex flex-column   align-items-center">

                                    <ng-container *ngFor="let servicosPlano of servicosPlano">
                                        <div class="col tamanhoLinhaTabelaFiliar"
                                            *ngIf="servicosPlano.nupla == plano.nupla">
                                            <div class="flex gap-2 ">
                                                <div class="flex flex-column align-items-start">
                                                    <div class=" fontmarron">
                                                        <span>{{servicosPlano.nomeprod}} | ({{servicosPlano.minutos}}
                                                            min)</span>
                                                    </div>
                                                    <div class="fontcinza">
                                                        <span>{{servicosPlano.descrprod}}</span>
                                                    </div>
                                                    <div class="fontcinza">
                                                        <span>Profissional: {{servicosPlano.apelido}}</span>
                                                    </div>
                                                    <div class="fontcinza">
                                                        <span>Qtd usos: {{servicosPlano.qtd}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="linhahorizontalFiliar1">
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>