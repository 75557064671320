import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, Observable, tap } from 'rxjs';
import { Servico } from './assets/servico';
import { environment } from './environments/environment';
import { LoginService } from './login/login.service';

@Injectable({
  providedIn: 'root'
})
export class ServicoService {
  servico: Servico = {};

  constructor(private http: HttpClient, private loginService: LoginService) { }

  buscarServicoPorCodFunc(codfunc: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const funcionario = { "codfunc": codfunc, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/produtos/getprodsporcodfunc`, funcionario)
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            //  this.loginService.verificaToken(e.status);
          })
      );
  }

  buscarServicoporCodEmp(codemp: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const empresa = { "codemp": codemp, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/produtos`, empresa)
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            //   this.loginService.verificaToken(e.status);
          })
      );
  }


  buscarServicoPorCodCli(codcli: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const cliente = { "codcli": codcli, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/produtos/getprodsporcodcli`, cliente, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  preencherServico(codprod: number, nomeprod: string, descrprod: string, codemp: number, codcli: number, tempom: string, preco: number, codfunc: number, codusuresp: string, comissao: number): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const servico = {
      "codprod": codprod, "nomeprod": nomeprod, "descrprod": descrprod, "codemp": codemp, "codcli": codcli,
      "tempom": tempom, "preco": preco, "codfunc": codfunc, "codusuresp": codusuresp, "comissao": comissao
    }

    return this.http
      .post<any>(`${environment.BASE_URL}/produtos/createprod`, servico, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  excluiServicoPorCodProd(codprod: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const servico = { "codprod": codprod, "codusuresp": codusuresp }

    return this.http
      .put<any>(`${environment.BASE_URL}/produtos/excluiprod`, servico, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  ativarDesativarServicoPorCodProd(codprod: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const servico = { "codprod": codprod, "codusuresp": codusuresp }

    return this.http
      .put<any>(`${environment.BASE_URL}/produtos/ativdesatprod`, servico, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  buscarCategoriasProd(codtipseg: number): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);
    const segmento = {
      "codtipseg": codtipseg
    }
    return this.http
      .post<any>(`${environment.BASE_URL}/produtos/getcategoriaprod`, segmento, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  updateCategoriaProd(codprod: number, categoria: string, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const servico = { "codprod": codprod, "categoria": categoria, "codusuresp": codusuresp }

    return this.http
      .put<any>(`${environment.BASE_URL}/produtos/updatecategoria`, servico, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  uploadImagemProduto(codprod: string, img: any) {
    //const imagem = { "codemp": codemp, "imagem_empresa": img }
    // const headers =  { 'Content-Type': 'multipart/form-data' }

    const token = localStorage.getItem('token');
    //let headers = new HttpHeaders().append('Authorization', token!);

    let headers = new HttpHeaders({
      'enctype': 'multipart/form-data',
      'Authorization': token!
    });
    return this.http
      .post<any>(`${environment.BASE_URL}/upload/imagem-produto`, img, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  public setServico(s: Servico) { //SETA O SERVICO NO VETOR
    this.servico = s;
  }

  public getServico() { //RECUPERA O CONTEUDO DO SERVICO PARA VISUALIZAÇÃO
    return this.servico;
  }
}
