import { EstabelecimentoService } from 'src/estabelecimento.service';
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Profissional } from 'src/assets/profissional';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { ProfissionalService } from 'src/profissional.service';
import { Estabelecimento } from 'src/assets/estabelecimento';
import { UsuarioService } from 'src/usuario.service';
import { MessageService } from 'primeng/api';
import { UtilitariosService } from 'src/assets/utilitarios.service';
import { AlertService } from 'src/alert.service';
import { TraducaoService } from 'src/traducao.service';
import { PermissoesService } from 'src/permissoes.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-profissional',
  templateUrl: './profissional.component.html',
  styleUrls: ['./profissional.component.scss'],
  providers: [MessageService],
  encapsulation: ViewEncapsulation.None
})
export class ProfissionalComponent implements OnInit {
  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef;






  showInfoPessoal: boolean = false;
  showAtivarDesativarProfissional: boolean = false;
  showImagens: boolean = false;


  /**DROPDOWN */
  estabelecimento: Estabelecimento[] = [];
  estabelecimentoSelecionado: any;

  /**RADIO BUTTON */
  valorSelecionadoProfissional: string = '';


  /** VARIAVEIS DO COMPONENT */
  codfuncUsu: string = '';
  codfunc: string = '';
  codemp: string = '';
  codUsu: string = '';
  codcli: number = 0;
  apelido: string = '';
  nomefunc: string = '';
  email: string = '';
  profissional: Profissional = {};
  exibeNome: string = '';
  loading: boolean = false;
  nomeImagem: string = 'Escolha uma foto';
  filename: string = '';

  /**VARIAVEIS DE PERMISSÕES */
  alterar: boolean = false;
  cadastrar: boolean = false;
  inativar: boolean = false;
  permissao: any;


  constructor(private profissionalService: ProfissionalService, private decodedTokenService: DecodedTokenService, private permissoesService: PermissoesService,
    private estabelecimentoService: EstabelecimentoService, private usuarioService: UsuarioService, private messageService: MessageService,
    private utilitariosService: UtilitariosService, private alertService: AlertService, private traducaoService: TraducaoService) {
    /**TRADUÇÃO */
    this.traducaoService.changeLang();
  }

  ngOnInit(): void {
    this.recuperaCodCli();
    this.recuperaCodUsu();
    this.recuperaProfissional();
    this.meuPerfil();
    this.recuperaDadosProfissional();
    this.recuperaPermissao();
    this.buscarEstabelecimentosPorCodCliente();

  }

  recuperaProfissional() {
    this.profissional = this.profissionalService.getProfissional();
    if (!this.profissional.codfunc) {
      console.log('não tem')
    }
    console.log(this.profissional)
  }

  recuperaCodCli() {
    this.codcli = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODCLI;
  }

  recuperaCodUsu() {
    this.codUsu = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }


  recuperaDadosProfissional() {
    this.nomefunc = this.profissional.nomefunc!;
    this.apelido = this.profissional.apelido!;
    this.email = this.profissional.email!;
    this.codemp = this.profissional.codemp!;
    this.estabelecimentoSelecionado = this.codemp;
    this.exibeNome = this.profissional.nomefunc!;
    this.codfunc = this.profissional.codfunc!;
    this.valorSelecionadoProfissional = this.profissional.ativo!;
    console.log(this.valorSelecionadoProfissional)

    if (this.profissional.filename !== null && this.profissional.filename !== undefined) {
      this.filename = environment.imgProfissional + this.profissional.filename;
    }
    // if(this.codfuncUsu != "0" && this.codfuncUsu != null){
    //   this.valorSelecionadoProfissional = "ativar"
    //   console.log('ativei')

    // }
    // if(this.codfuncUsu === "0"){
    //   this.valorSelecionadoProfissional = "desativar"
    //   console.log('desativei')
    // }

  }

  salvarFuncionario() {

    this.loading = true;
    if (this.utilitariosService.validaCampo([this.nomefunc, this.apelido, this.email, this.estabelecimentoSelecionado])) {
      console.log('É necessário preencher todos os campos.')
      this.loading = false;
      this.showError('Atenção', 'É necessário preencher todos os campos.')
      return;
    }



    console.log(this.nomefunc + ': nomefunc')
    console.log(this.apelido + ': apelido')
    console.log(this.email + ': email')
    console.log(this.estabelecimentoSelecionado + ': estabelecimentoSelecionado')


    this.profissionalService.preencherFuncionario(this.codfunc, this.nomefunc, this.apelido, this.estabelecimentoSelecionado, this.email, this.codUsu).subscribe((res) => {

      console.log('RESPOSTA')
      console.log(res)
      this.codfunc = Object(res).codfunc;
      this.exibeNome = this.nomefunc;
      this.showInfoPessoal = false;
      this.loading = false;
      this.alertService.alert('Informações do Profissional alteradas!', 'success')
    },
      (error) => {
        console.log(error)
        if (error.error.message != undefined) {
          const erro = error.error.message
          const testeRegex = erro.match(/[^,]*,[^,]*,  (.*\..*)(.*\..*)/)
          this.loading = false;
          this.showInfoPessoal = false;
          this.alertService.alert(testeRegex[1], 'error')
        } else {
          this.loading = false;
          this.showInfoPessoal = false;
          this.alertService.alert('Algo deu errado.', 'error')
        }

        //this.showError('Atenção', 'Algo deu errado.')
      });
  }

  buscarProfissionalPorCodEmp() {
    this.profissionalService.buscarProfissionalporCodEmp(Number(this.codemp), this.codUsu).subscribe((res) => {

    });
  }


  mostraDialogInfo() {
    /**VERIFICA SE TEM PERMISSÃO PARA ESSA AÇÃO */
    if (!this.alterar && this.permissao != undefined && this.permissao.length > 0 && this.exibeNome != '' && this.exibeNome != undefined) {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
    this.showInfoPessoal = true;
  }


  mostraDialogImagens() {
    /**VERIFICA SE TEM PERMISSÃO PARA ESSA AÇÃO */
    if (!this.alterar && this.permissao != undefined && this.permissao.length > 0 && this.exibeNome != '' && this.exibeNome != undefined) {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
    if (this.codfunc != undefined && this.codfunc != null && this.codfunc != '') {
      //  if (this.codfunc !== undefined) {

      this.showImagens = true;
      //}
    }
  }


  mostraDialogAtivarDesativarProfissional() {
    /**VERIFICA SE TEM PERMISSÃO PARA ESSA AÇÃO */
    if (!this.inativar && this.permissao != undefined && this.permissao.length > 0 && this.exibeNome != '' && this.exibeNome != undefined) {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
    if (this.codfunc != undefined && this.codfunc != null && this.codfunc != '') {
      this.buscarProfissionalPorCodCliente();
      this.showAtivarDesativarProfissional = true;
    }
  }

  /**METODO QUE RECUPERA A LISTA DE BARBEARIAS */
  buscarEstabelecimentosPorCodCliente() {
    this.estabelecimentoService.buscarEstabelecimentosporCodCli(this.codcli, this.codUsu).subscribe((res) => {
      this.estabelecimento = res;
    });
  }

  meuPerfil(): void {
    this.usuarioService.getPerfil(this.codUsu, this.codUsu).subscribe((res) => {
      console.log('meuperfil')
      console.log(res)
      this.codfuncUsu = Object(res)[0].codfunc;
    });
  }

  ativaDesativaProfissional() {
    console.log(this.codfunc)
    this.loading = true;
    if (this.valorSelecionadoProfissional === "S") {
      this.profissionalService.ativaProfissional(this.codfunc, this.email, this.codUsu).subscribe((res) => {
        console.log('funcionario ativado')
        this.showAtivarDesativarProfissional = false;
        this.loading = false;
        this.alertService.alert('Profissional ativado!', 'success')
        console.log(res)
      },
        (error) => {
          console.log(error)
          console.log(error.error.message)
          if (error.error.message != undefined) {
            const erro = error.error.message
            const testeRegex = erro.match(/(?:[^,]*, ){2}([^,]*)/)
            this.loading = false;
            this.showAtivarDesativarProfissional = false;
            this.alertService.alert(testeRegex[1], 'error')
          } else {
            this.loading = false;
            this.showAtivarDesativarProfissional = false;
            this.alertService.alert('Algo deu errado.', 'error')
          }
        });
    }

    if (this.valorSelecionadoProfissional === "N") {
      this.profissionalService.desativaProfissional(this.codfunc, this.email, this.codUsu).subscribe((res) => {
        this.showAtivarDesativarProfissional = false;
        this.loading = false;
        console.log('funcionario desativado')
        this.alertService.alert('Profissional desativado!', 'success')
        console.log(res)
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.loading = false;
          this.showAtivarDesativarProfissional = false;
          this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')
        });
    }
  }



  buscarProfissionalPorCodCliente() {
    this.profissionalService.buscarProfissionalporCodCli(this.codcli, this.codUsu).subscribe((res) => {
      console.log(res);
      this.profissional = Object(res.filter((x: any) => x.codfunc === this.codfunc))[0];
      console.log(this.profissional)
      this.recuperaDadosProfissional();
    });
  }


  /**METODO DE TOAST */
  showError(titulo: string, mensagem: string) {
    if (screen.width < 640 || screen.height < 480) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else if (screen.width < 1024 || screen.height < 768) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else {
      this.messageService.add({
        key: 'tr',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    }
  }

  /**RECUPERA AS PERMISSÕES */
  recuperaPermissao() {
    console.log('recuperei as permissoes')
    this.permissao = this.permissoesService.getPermissao();
    console.log(this.permissao)
    if (this.permissao != undefined && this.permissao.length > 0) {
      if (this.permissao[1].acao[0].PERMITE === 'S') {
        this.cadastrar = true;
      }
      if (this.permissao[1].acao[1].PERMITE === 'S') {
        this.alterar = true;
      }
      if (this.permissao[1].acao[2].PERMITE === 'S') {
        this.inativar = true;
      }
      /**ATRIBUI O CODCLI VINCULADO NA PERMISSAO DO FUNCIONARIO, PARA FAZER AS OPERAÇÕES DA TELA */
      this.codcli = this.permissao[0].codcli;
    }
  }




  mudaNomeFoto() {
    const imageBlob = this.fileInput!.nativeElement.files[0];
    this.nomeImagem = imageBlob.name;
    if (this.nomeImagem.length > 24) {
      console.log('oiwf')
      this.nomeImagem = this.nomeImagem.substr(0, 24) + '...';
    }
    console.log(this.nomeImagem.substr(0, 20) + '...')
  }

  onFileUpload() {
    this.loading = true;
    const imageBlob = this.fileInput!.nativeElement.files[0];
    this.nomeImagem = imageBlob.name;
    const file = new FormData();
    file.append('imagem_profissional', imageBlob);
    file.append('codfunc', this.codfunc);
    file.append('codusuresp', this.codUsu);
    console.log(file)
    console.log(this.codfunc)
    this.profissionalService.uploadImagemFunc(this.codfunc, file).subscribe((res) => {
      this.alertService.alert('Foto do Profissional alterada!', 'success')
      this.filename = environment.imgProfissional + res.filename;
      console.log(res)

      this.showImagens = false;
      this.loading = false;
      this.nomeImagem = "Escolha uma foto";
    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
        this.loading = false;
        this.showImagens = false;
        this.alertService.alert('Algo deu errado.', 'error');
        //this.showError('Atenção', 'Algo deu errado.')
      });
  }
}
