import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, Observable, tap } from 'rxjs';
import { Pergunta, Pesquisa } from './assets/pesquisa';
import { environment } from './environments/environment';
import { LoginService } from './login/login.service';

@Injectable({
  providedIn: 'root'
})
export class PesquisaService {
  pesquisa: Pesquisa = {}
  pergunta: Pergunta = {}

  constructor(private http: HttpClient, private loginService: LoginService) { }

  buscarPesquisaPorCodCli(codcli: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const cliente = { "codcli": codcli, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/pesquisa/getpesquisabycodcli`, cliente, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  buscarPerguntasPorNupes(nupes: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const pesquisa = { "nupes": nupes, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/pesquisa/getperguntasbypesquisa`, pesquisa, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  preencherPesquisa(nupes: number, nomepes: string, codcli: number, codemp: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const pesquisa = {
      "nupes": nupes, "nomepes": nomepes, "codcli": codcli, "codemp": codemp, "codusuresp": codusuresp
    }

    return this.http
      .post<any>(`${environment.BASE_URL}/pesquisa/createpes`, pesquisa, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  preencheVigencia(nupes: number, dtvigini: any, dtvigfin: any, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const vigencia = { "nupes": nupes, "dtvigini": dtvigini, "dtvigfin": dtvigfin, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/pesquisa/preenchevigencia`, vigencia, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  excluiPesquisaPorNupes(nupes: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const pesquisa = { "nupes": nupes, "codusuresp": codusuresp }

    return this.http
      .put<any>(`${environment.BASE_URL}/pesquisa/excluipes`, pesquisa, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  preencherPergunta(nuper: any, pergunta: string, tiporesp: string, nupes: number, tipopes: string, tipopergunta: string, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const pergunt4 = {
      "nuper": nuper, "pergunta": pergunta, "tiporesp": tiporesp, "nupes": nupes, "tipopes": tipopes, "tipopergunta": tipopergunta, "codusuresp": codusuresp
    }
    console.log(pergunt4)
    return this.http
      .post<any>(`${environment.BASE_URL}/pesquisa/createperg`, pergunt4, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  excluiPerguntaPorNuper(nuper: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const pergunta = { "nuper": nuper, "codusuresp": codusuresp }

    return this.http
      .put<any>(`${environment.BASE_URL}/pesquisa/excluiperg`, pergunta, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  excluiTodasPergunta(nupes: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const pergunta = { "nupes": nupes, "codusuresp": codusuresp }

    return this.http
      .put<any>(`${environment.BASE_URL}/pesquisa/excluitodasperg`, pergunta, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  buscaPerguntasPendentesPorCodusu(codusu: string, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const usuario = { "codusu": codusu, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/pesquisa/buscaperguntaspendentesbycodusu`, usuario, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  responderPerguntaPendente(resposta: string, nures: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const resp = { "resposta": resposta, "nures": nures, "codusuresp": codusuresp }

    return this.http
      .put<any>(`${environment.BASE_URL}/pesquisa/responderpesquisapendente`, resp, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  tabelaRelatorioPorNupes(nupes: number, dataini: any, datafin: any, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const pesquisa = { "nupes": nupes, "dataini": dataini, "datafin": datafin, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/pesquisa/relatoriobypesquisa`, pesquisa, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  geraRelatorioExcelPorNupes(nupes: number, dataini: any, datafin: any, codusuresp: string): Observable<any> {
    // const token = localStorage.getItem('token');
    // //let headers = new HttpHeaders().append('Authorization', token!);

    // let headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    //   'Authorization': token!
    // });

    // const pesquisa = { "nupes": nupes, "dataini": dataini, "datafin": datafin, "codusuresp": codusuresp }

    // return this.http
    //   .post<any>(`${environment.BASE_URL}/pesquisa/relatorioexcelbypesquisa`, pesquisa, { headers: headers, responseType: 'blob' as 'json' })
    //   .pipe(
    //     first(),
    //     //delay(5000),
    //     tap((r: any) => console.log(r), (e: any) => console.log(e)));

    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const pesquisa = { "nupes": nupes, "dataini": dataini, "datafin": datafin, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/pesquisa/relatorioexcelbypesquisa`, pesquisa, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  geraRelatorioPorNupes(nupes: number, dtini: any, dtfin: any, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    //let headers = new HttpHeaders().append('Authorization', token!);
    // let headers= new HttpHeaders( {  responseType: 'blob' as 'json', observe: 'response' });
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/pdf',
      'Authorization': token!
    });

    const pesquisa = { "nupes": nupes, "dtini": dtini, "dtfin": dtfin, "codusuresp": codusuresp }
    console.log(pesquisa)
    return this.http
      .post<any>(`${environment.BASE_URL}/rel/getRelByNupes`, pesquisa, { headers: headers, responseType: 'blob' as 'json' })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  geraRelatorioUrlPorNupes(nupes: number, dtini: any, dtfin: any, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const pesquisa = { "nupes": nupes, "dtini": dtini, "dtfin": dtfin, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/rel/getUrlRelByNupes`, pesquisa, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  public setPesquisa(pes: Pesquisa) {
    this.pesquisa = pes;
  }

  public getPesquisa() {
    return this.pesquisa;
  }

  public setPergunta(perg: Pergunta) {
    this.pergunta = perg;
  }

  public getPergunta() {
    return this.pergunta;
  }
}
