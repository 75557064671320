import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'src/alert.service';
import { UtilitariosService } from 'src/assets/utilitarios.service';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/login/login.service';

@Component({
  selector: 'app-alterar-senha-deslog',
  templateUrl: './alterar-senha-deslog.component.html',
  styleUrls: ['./alterar-senha-deslog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AlterarSenhaDeslogComponent implements OnInit {

  email: string = '';
  newPassword1: string = '';
  newPassword2: string = '';
  msgErro: string = '';
  teveErro: boolean = false;
  codigoRecuperacao: string = '';
  loading: boolean = false;

  constructor(private loginService: LoginService, private router: Router, private utilitariosService: UtilitariosService,
    private alertService: AlertService) { }

  ngOnInit(): void {
    this.email = this.loginService.getEmail();
    this.codigoRecuperacao = this.loginService.getCodigo();
    console.log(this.codigoRecuperacao)
    console.log(this.email)
  }


  alterarSenha() {
    this.loading = true;

    if (this.utilitariosService.validaCampo([this.newPassword1, this.newPassword2])) {
      this.teveErro = true;
      this.msgErro = "Você precisa informar a senha."
      this.loading = false;
      return;
    }

    if (this.newPassword1 === this.newPassword2) {
      this.loginService.updatePasswordDeslogado(this.email, this.newPassword1, this.codigoRecuperacao, environment.CODTIPSEG).subscribe((res) => {
        console.log(res);
        this.loading = false;
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 1500)
        this.alertService.alert('Sua senha foi alterada.', 'success')
      },
        (error) => {
          this.teveErro = true;
          this.msgErro = "Algo deu errado."
          this.loading = false;
        });

    } else {
      this.teveErro = true;
      this.msgErro = "As senhas não correspondem."
      this.loading = false;
    }

  }
}
