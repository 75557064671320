<p-toast position="bottom-center" key="bc"></p-toast>
<p-toast position="top-right" key="tr"></p-toast>
<div class="pt-7">

    <div class="flex flex-column  pb-3">
        <div class="flex align-items-center justify-content-center">
            <img src="./assets/iconeCadPlanos.svg" class="iconeCadastrarProfissional" />
        </div>
        <div class=" flex align-items-center justify-content-center">
            <span
                class=" tituloNegritoPlanoPacote maximoCaracterProfissional white-space-nowrap overflow-hidden text-overflow-ellipsis">
                {{nomePlanoTitulo}} </span>
        </div>
    </div>


    <div class="flex justify-content-center flex-wrap ">
        <div class="xl:col-5 lg:col-5 md:col-5 sm:col-5">
            <div class="shadow-4">
                <p-card header="Dados do Plano" id="cardPlanoPacote">
                    <div>
                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            (click)="mostraDialogInfoGerais()">
                            <div class="flex gap-2 ">
                                <img src="./assets/imgInfoPlanoCadastro.svg" class=" xl:w-3rem" />
                                <div class="flex flex-column align-items-start">
                                    <div class=" fontTituloPlanoPacote">
                                        <span class="xl:text-xl">Informações gerais do plano</span>
                                    </div>
                                    <div class="fontCinzaPlanoPacote ">
                                        <span class="xl:text-base">Informe o nome, descrição, preço, duração e
                                            comissão</span>
                                    </div>
                                </div>
                            </div>
                            <div class="align-items-center justify-content-center   ">
                                <img src="./assets/imgBarbeariaPopup.png" class="iconePopUpBarbearia " />
                            </div>
                        </div>

                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            (click)="mostraDialogLista()">
                            <!-- <div class="col"> -->
                            <div class="flex gap-2 ">
                                <img src="./assets/imgListaServicoPlanoCadastro.svg" class=" xl:w-3rem" />
                                <div class="flex flex-column  align-items-start">
                                    <div class=" fontTituloPlanoPacote">
                                        <span class="xl:text-xl">Lista de produtos/serviço</span>
                                    </div>
                                    <div class="fontCinzaPlanoPacote ">
                                        <span class="xl:text-base">Crie sua lista e informe a quantidade de
                                            crédito</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex align-items-center justify-content-center ">
                                <img src="./assets/imgBarbeariaPopup.png" class="iconePopUpBarbearia" />
                            </div>

                            <!-- </div> -->
                        </div>
<!-- 
                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            (click)="mostraDialogAtvDest()">
                            <div class="flex gap-2 ">
                                <img src="./assets/imgLigarCadastro.svg" class=" xl:w-3rem" />
                                <div class="flex flex-column align-items-start">
                                    <div class="fontTituloPlanoPacote">
                                        <span class="xl:text-xl">Ativar/Desativar</span>
                                    </div>
                                    <div class="fontCinzaPlanoPacote ">
                                        <span class="xl:text-base">Tornar plano ativo para aquisição do cliente</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex align-items-center justify-content-end  ">
                                <img src="./assets/imgBarbeariaPopup.png" class="iconePopUpBarbearia" />
                            </div>
                        </div> -->
                    </div>
                </p-card>
            </div>
        </div>
    </div>



    <!--                          DIALOG DE INFORMAÇÕES GERAIS DO PLANO/PACOTE               -->
    <p-dialog header="Informações gerais" [(visible)]="showInfoGerais" [modal]="true" [draggable]="false"
        [breakpoints]="{'960px': '534px', '640px': '327px'}" id="dialogInfoGerais">
        <div class="flex justify-content-center flex-wrap col">
            <div class="fontDialogPlanoPacote flex align-items-center justify-content-center">
                <span>Informações sobre o cupom</span>
            </div>
        </div>

        <input type="text" [(ngModel)]="nome" pInputText placeholder="Nome do plano" [style]="{width: '100%'}"
            class="mt-2">

        <div class="pb-1 pt-1">
            <textarea pInputTextarea placeholder="Descrição do plano" [(ngModel)]="descrplano" [autoResize]="true"
                maxlength="150" class="expandeTextArea" (ngModelChange)=" contaCaracter()"></textarea>
            <div class="flex justify-content-end flex-wrap fontContadorCaracter">{{caracteresRestantes}}/150</div>
        </div>
        <div class="pt-1 p-fluid">
            <p-inputNumber [(ngModel)]="preco" inputId="currency-br" mode="currency" currency="BRL" locale="pt-BR"
                id="CampoDesconto" [min]="0"> </p-inputNumber>
        </div>

        <div class="pb-1 pt-1">
            <p-dropdown [options]="duracao" [(ngModel)]="duracaoSelecionada" placeholder="Duração" optionLabel="nome"
                optionValue="tempo" id="dropDuracaoPlano" scrollHeight="130px" [style]="{width: '100%'}">
            </p-dropdown>
        </div>

        <div class="p-fluid pb-2">
            <p-inputNumber [(ngModel)]="comissao" suffix="%" id="percentServico" placeholder="% Comissão"
                [minFractionDigits]="2" [max]="100" [min]="0"></p-inputNumber>
        </div>

        <div *ngIf="!loading" [style]="{width: '100%'}">
            <button pButton type="button" label="Salvar" class="col-12 " [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
        'border-radius': '11px'}" (click)="criaPlano()"></button>
        </div>
        <div *ngIf="loading" [style]="{width: '100%'}">
            <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 " [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
        'border-radius': '11px'}"></button>
        </div>
    </p-dialog>


    <!--                          DIALOG DE ADICIONAR A LISTA DE SERVIÇOS               -->
    <p-dialog header="Adicionar Serviço" [(visible)]="showAdcProd" [modal]="true" [draggable]="false"
        id="dialogAdcServicos">
        <div class="flex justify-content-center flex-wrap col">
            <div class="fontDialogPlanoPacote flex align-items-center justify-content-center">
                <span>Adicione serviços e quantidade ao plano</span>
            </div>
        </div>

        <!-- <p-multiSelect id="dropListProds" [options]="servicos" [(ngModel)]="servicoSelecionado" optionLabel="nomeprod"
            scrollHeight="130px" [style]="{'width': '100%'}" placeholder="Serviços" optionValue="codprod"
            (onChange)="criaDescrProds()">
        </p-multiSelect> -->

        <p-multiSelect id="dropListProds" [options]="servicos" [(ngModel)]="servicoSelecionado" optionLabel="nomeprod"
            scrollHeight="80px" [style]="{'width': '100%'}" placeholder="Serviços" optionValue="codprod"
            [filter]="true" filterBy="nomeprod,apelido" (onChange)="criaDescrProds();">

            <ng-template let-servicos pTemplate="item">
                <div class="flex flex-column">
                    <div class="flex align-items-center ">

                        <span class="maximoCaracterNome white-space-nowrap overflow-hidden text-overflow-ellipsis">{{
                            servicos.nomeprod }}</span>
                    </div>
                    <div class="flex align-items-center ">
                        <span class="fontApelido">{{ servicos.apelido }}</span>
                    </div>
                </div>
            </ng-template>



        </p-multiSelect>

        <div class="pt-1 pb-2 p-fluid">
            <p-inputNumber [(ngModel)]="qtd" placeholder="Quantidade" [min]="0" [max]="999"> </p-inputNumber>
        </div>


        <div *ngIf="!loading" [style]="{width: '100%'}">
            <button pButton type="button" label="Salvar" class="col-12 " [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
        'border-radius': '11px'}" (click)="cadastraListaPlano(); "></button>
        </div>
        <div *ngIf="loading" [style]="{width: '100%'}">
            <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 " [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
        'border-radius': '11px'}"></button>
        </div>
    </p-dialog>



    <!--                          DIALOG ATIVA/DESATIVA             -->

    <p-dialog header="Ativar/ Desativar Plano" [(visible)]="showAtivarDesativarPlanoPacote" [modal]="true"
        [style]="{'width': '350px', 'height': '240px'}" id="dialogAtvDst">
        <div class="flex justify-content-center flex-wrap col">
            <div class="fontDialogPlanoPacote flex align-items-center justify-content-center">
                <span>Se ativo, o plano ficará disponivel para o cliente fazer a aquisição</span>
            </div>
        </div>
        <!-- <div class="fontDialogAtvObsBarbearia flex align-items-center justify-content-center pb-4 pt-2">
        <span>Obs: Para ativar o profissional, é necessário que ele tenha conta!</span>
    </div> -->

        <div class="p-field-radiobutton pb-1">
            <p-radioButton value="S" [(ngModel)]="valorSelecionadoRadioButton"></p-radioButton>
            <label class="pl-2">Ativar plano</label>
        </div>
        <div class="p-field-radiobutton pb-1">
            <p-radioButton value="N" [(ngModel)]="valorSelecionadoRadioButton"></p-radioButton>
            <label class="pl-2">Desativar plano</label>
        </div>




        <div *ngIf="!loading" [style]="{width: '100%'}">
            <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
        'border-radius': '11px'}"></button>
        </div>
        <div *ngIf="loading" [style]="{width: '100%'}">
            <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 mt-3"
                [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
        'border-radius': '11px'}"></button>
        </div>
    </p-dialog>




    <!--                          DIALOG DE LISTA DE PRODUTOS/SERVICOS               -->
    <p-dialog header="Listas de produtos e serviços" [(visible)]="showLista" [modal]="true"
        [breakpoints]="{'960px': '534px', '640px': '360px'}" [closable]="true" id="dialogListaServicos">
        <div class="flex flex-column align-items-center">
            <div class="col-12">
                <div class=" flex align-items-center justify-content-center ">
                    <span class="fontDialogPlanoPacote">Crie, edite ou remova as listas que seus clientes terão como
                        opção no plano/pacote</span>
                </div>
            </div>
        </div>

        <div class="contornoBotoes">


            <div class="flex flex-wrap gap-2 pl-2 pb-2">
                <button id="cadastrarServico" pButton type="button" label="Cadastrar" class="col-12 mt-2"
                    (click)="mostraDialogAdcProd()"
                    [style]="{'width':'100px', 'height': '24px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)','border-radius': '8px', 'border': 'none', 'font-size': '10px'}"></button>
                <button id="excluirServico" pButton type="button" label="Excluir" class="col-12 mt-2 "
                    (click)="confirmaExclusaoLista()"
                    [style]="{'width':'100px', 'height': '24px', 'background-color': 'var(--cor2)', 'color': 'var(--cor7)','border-radius': '8px', 'border': 'none', 'font-size': '10px'}"></button>
            </div>

        </div>
        <div class="pt-2"></div>
        <div class="contornoCampos ">
            <div class="col">
                <div class="flex  align-items-center justify-content-between gap-3">

                    <div class=" ">
                        <span class="fontTituloTabela " style="white-space: nowrap;">
                            Listas de produtos/ serviços
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="pt-2">
            <p-table [value]="listaServicos" [scrollable]="true" scrollHeight="300px" id="tabela">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width:1px"> <p-checkbox [value]="listaServicos"
                                [(ngModel)]="selecionouTodasListaExcluir" (click)="clicouCheckbox()">
                            </p-checkbox>
                        </th>
                        <th class="fontTituloTabela" style="width:1px">Nº</th>
                        <th class="fontTituloTabela white-space-nowrap" style="min-width:225px">Serviço/ produto</th>
                        <th class="fontTituloTabela" style="min-width:124px">Quantidade</th>
                        <th style="min-width:1px"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-listaServicos let-indice="rowIndex">
                    <tr>
                        <td style="width:1px">
                            <p-checkbox [value]="listaServicos" [(ngModel)]="listaSelecionadaExcluir">
                            </p-checkbox>
                        </td>
                        <td class="fontConteudoTabela" style="width:1px">{{indice+1}}</td>
                        <td class="fontConteudoTabela" style="min-width:225px">{{listaServicos.descrprods}}</td>
                        <td class="fontConteudoTabela justify-content-center" style="min-width:124px">
                            {{listaServicos.qtd}}</td>
                        <td style="min-width:1px" alignFrozen="right" pFrozenColumn>
                            <img src="./assets/iconePesquisaTabela.svg" class="cursor-pointer"
                                (click)="selecionouServicoEdicao(listaServicos)" />
                        </td>
                    </tr>
                </ng-template>
            </p-table>


        </div>

        <div [style]="{width: '100%'}">
            <button pButton type="button" label="Voltar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
     'border-radius': '11px'}" (click)="clicouVoltar()"></button>
        </div>

    </p-dialog>



</div>



<!-- CONFIRMDIALOG DE EXCLUSÃO -->
<p-confirmDialog #cd [closable]="false" id="confirmPlanos">
    <ng-template pTemplate="footer">
        <button type="button" pButton icon="pi pi-times" label="Retornar" (click)="cd.reject()"></button>
        <button type="button" pButton icon="pi pi-check" label="Confirmar" (click)="cd.accept()"></button>
    </ng-template>
</p-confirmDialog>