<!-- <img [src]="imagemNoticia" class="imagem" /> -->
<link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;900&display=swap" rel="stylesheet">

<div class="flex justify-content-center flex-wrap">
    <div class="flex align-items-center justify-content-center">
        <div class="pt-Personalizado xl:col-personalizado lg:col-5 md:col-5">
            <div class=" scroll-divNoticia">

                <div class="relative">
                    <img [src]="imagemNoticia" class="imagem " />
                    <div class="legenda fonteTituloNoticia">
                        <span style="background-color: var(--cor7)">{{informativo.titulo}}</span>
                    </div>
                </div>


                <div class="colPersonalizado ">
                    <span class="fonteSubTituloNoticia">{{informativo.subtitulo}}</span>

                    <div class="flex justify-content-end flex-wrap">

                        <span class="fonteStatusNoticia">{{informativo.status}}</span>
                    </div>
                </div>


                <div class="linhahorizontalInformativo"></div>

                <div class="colPersonalizado ">
                    <div id="htmlNotcia"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="botaoVoltar">

    <button pButton type="button" icon="pi pi-arrow-left" class="col-12" (click)="clickVoltar()"
        id="voltarDInformativo"></button>
</div>