import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, Observable, tap } from 'rxjs';
import { environment } from './environments/environment';
import { LoginService } from './login/login.service';

@Injectable({
  providedIn: 'root'
})
export class MetricasService {

  constructor(private http: HttpClient, private loginService: LoginService) { }


  buscaDadosGeraisCardPorCodEmp(dataini: string, datafin: string, codemp: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    var dados = { "dataini": dataini, "datafin": datafin, "codemp": codemp, "codusuresp": codusuresp }

    console.log(dados)
    return this.http
      .post(`${environment.BASE_URL}/dados_gerais/dadosgeraiscard`, dados, { headers: headers })
      .pipe(
        first(),
        // delay(5000),
        tap((r) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  buscaDadosGeraisTop20ClientesPorCodEmp(dataini: string, datafin: string, codemp: number, tipo: string, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    var dados = { "dataini": dataini, "datafin": datafin, "codemp": codemp, "tipo": tipo, "codusuresp": codusuresp }

    return this.http
      .post(`${environment.BASE_URL}/dados_gerais/dadosgeraistop20clientes`, dados, { headers: headers })
      .pipe(
        first(),
        // delay(5000),
        tap((r) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  buscaDadosGeraisTop5ServicosPorCodEmp(dataini: string, datafin: string, codemp: number, tipo: string, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    var dados = { "dataini": dataini, "datafin": datafin, "codemp": codemp, "tipo": tipo, "codusuresp": codusuresp }

    return this.http
      .post(`${environment.BASE_URL}/dados_gerais/dadosgeraistop5servicos`, dados, { headers: headers })
      .pipe(
        first(),
        // delay(5000),
        tap((r) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  buscaDadosGeraisProfissionaisPorCodEmp(dtini: any, dtfin: any, codemp: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    var dados = { "dtini": dtini, "dtfin": dtfin, "codemp": codemp, "codusuresp": codusuresp }

    return this.http
      .post(`${environment.BASE_URL}/dados_gerais/dadosgeraisprofissionaisbycodemp`, dados, { headers: headers })
      .pipe(
        first(),
        // delay(5000),
        tap((r) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }



  buscaDadosGeraisCardPorCodfunc(dataini: string, datafin: string, codfunc: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    var dados = { "dataini": dataini, "datafin": datafin, "codfunc": codfunc, "codusuresp": codusuresp }

    console.log(dados)
    return this.http
      .post(`${environment.BASE_URL}/dados_gerais/dadosgeraiscardbycodfunc`, dados, { headers: headers })
      .pipe(
        first(),
        // delay(5000),
        tap((r) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  buscaDadosGeraisTop20ClientesPorCodfunc(dataini: string, datafin: string, codfunc: number, tipo: string, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    var dados = { "dataini": dataini, "datafin": datafin, "codfunc": codfunc, "tipo": tipo, "codusuresp": codusuresp }

    return this.http
      .post(`${environment.BASE_URL}/dados_gerais/dadosgeraistop20clientesbycodfunc`, dados, { headers: headers })
      .pipe(
        first(),
        // delay(5000),
        tap((r) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  buscaDadosGeraisTop5ServicosPorCodfunc(dataini: string, datafin: string, codfunc: number, tipo: string, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    var dados = { "dataini": dataini, "datafin": datafin, "codfunc": codfunc, "tipo": tipo, "codusuresp": codusuresp }

    return this.http
      .post(`${environment.BASE_URL}/dados_gerais/dadosgeraistop5servicosbycodfunc`, dados, { headers: headers })
      .pipe(
        first(),
        // delay(5000),
        tap((r) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  geraRelatorioPDFAgdPorCodemp(dtini: any, dtfin: any, codemp: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const empresa = { "dtini": dtini, "dtfin": dtfin, "codemp": codemp, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/rel/getUrlRelAgdByCodemp`, empresa, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }



  geraRelatorioAgdExcelPorCodemp(dtini: any, dtfin: any, codemp: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const empresa = { "dtini": dtini, "dtfin": dtfin, "codemp": codemp, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/dados_gerais/relexcelagdbycodemp`, empresa, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  geraRelatorioPDFServicoPorCodemp(dtini: any, dtfin: any, codemp: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const empresa = { "dtini": dtini, "dtfin": dtfin, "codemp": codemp, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/rel/getUrlRelServicoByCodemp`, empresa, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }



  geraRelatorioServicoExcelPorCodemp(dtini: any, dtfin: any, codemp: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const empresa = { "dtini": dtini, "dtfin": dtfin, "codemp": codemp, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/dados_gerais/relservicoexcelbyCodemp`, empresa, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  geraRelatorioPDFComparativoPorCodemp(dtini: any, dtfin: any, codemp: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const empresa = { "dtini": dtini, "dtfin": dtfin, "codemp": codemp, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/rel/getUrlRelComparativoByCodemp`, empresa, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }



  geraRelatorioComparativoExcelPorCodemp(dtini: any, dtfin: any, codemp: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const empresa = { "dtini": dtini, "dtfin": dtfin, "codemp": codemp, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/dados_gerais/relcomparativoexcelbyCodemp`, empresa, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  buscaDadosGeraisComparativoPorCodEmp(dtini: any, dtfin: any, codemp: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const empresa = { "dtini": dtini, "dtfin": dtfin, "codemp": codemp, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/dados_gerais/dadoscomparativobycodemp`, empresa, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  geraRelatorioPDFDesempenhoProfissional(dtini: any, dtfin: any, codemp: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const empresa = { "dtini": dtini, "dtfin": dtfin, "codemp": codemp, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/rel/getUrlRelDesempenhoProfissional`, empresa, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }



  geraRelatorioDesempenhoProfissionalExcelPorCodemp(dtini: any, dtfin: any, codemp: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const empresa = { "dtini": dtini, "dtfin": dtfin, "codemp": codemp, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/dados_gerais/relprofissionaisexcelbyCodemp`, empresa, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  
  buscaDadosGeraisPorDiaSemana(dtini: any, dtfin: any, codemp: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const empresa = { "dtini": dtini, "dtfin": dtfin, "codemp": codemp, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/dados_gerais/dadosgeraisdiasemanabyCodemp`, empresa, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  buscaDadosGeraisPorTipoNeg(dtini: any, dtfin: any, codemp: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const empresa = { "dtini": dtini, "dtfin": dtfin, "codemp": codemp, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/dados_gerais/dadosgeraistiponegbyCodemp`, empresa, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

}
