import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UtilitariosService } from 'src/assets/utilitarios.service';
import { LoginService } from './login.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
  email: string = '';
  senha: string = ''
  credInvalida: any;
  msgErro: string = '';
  loading: boolean = false;

  constructor(private loginService: LoginService, private utilitariosService: UtilitariosService) { }

  ngOnInit(): void {
    this.verificaStatus();

  }

  doLogin(): void {
    this.loading = true;
    if (this.utilitariosService.validaCampo([this.email, this.senha])) {
      this.credInvalida = true;
      this.loading = false;
      this.msgErro = 'É necessário preencher todos os campos.'
      return;
    }
    let codtipseg = environment.CODTIPSEG
   // var user = { email: this.email, password: this.senha, codtipseg: codtipseg };
    //console.log(user)
   // this.loginService.login(user).subscribe((res) => {
    this.loginService.login(this.email, this.senha, environment.CODTIPSEG).subscribe((res) => {
      console.log(res)
    }, (erro) => {
      console.log(Object(erro).error.message)
      //this.loading = false;
      // this.msgErro = 'Algo deu errado.'
    })
    console.log(this.loading)
  }

  verificaStatus() {
    //this.loading = true;
    this.loginService.status$.subscribe((data) => {
      if (Object(data).error.message) {
        this.msgErro = Object(data).error.message;
      } else {
        this.msgErro = 'Algo deu errado.';
      }
      this.credInvalida = true;
      this.loading = false;
    }, (erro) => {
      this.loading = false;
      this.msgErro = 'Algo deu errado.';
    });
  }



}
