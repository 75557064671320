<div class="pt-7">

    <div class=" flex justify-content-center flex-wrap">
        <div class="flex align-items-center justify-content-center">
            <div class="flex flex-column pb-3">
                <div class="flex align-items-center justify-content-center">
                    <img class="iconePerfil" src="./assets/iconeCadastrarNotificacoes.svg" />
                </div>
                <div class=" fonteTituloNotificacao ">
                    <span> Notificação</span>
                </div>
            </div>
        </div>
    </div>


    <div class=" flex justify-content-center flex-wrap ">

        <div class=" sm-8 md-6 xl:col-5 lg:col-5 md:col-5">

            <div class="shadow-4">
                <p-card id="cardNotif" class="shadow-4">

                    <div class="flex flex-column col-12">
                        <div
                            class="fonteTituloNotificacao flex justify-content-center align-items-center flex-wrap pb-1 ">
                            <span class="xl:text-4xl"> {{notificacao.titulo}}</span>
                        </div>
                        <div class="flex justify-content-center flex-wrap pt-1">
                            <div class="linhahorizontalNotif pb-3 xl:w-26rem"></div>
                        </div>
                        <div class="flex justify-content-center align-items-center flex-wrap">
                            <div class="flex flex-column  xl:w-26rem containerNotif">
                                <span class="fonteMsgNotificacao xl:text-base">{{notificacao.mensagem}} </span>

                            </div>
                        </div>
                    </div>

                </p-card>
            </div>
        </div>
    </div>
    <br>
</div>