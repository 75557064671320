<p-toast position="bottom-center" key="bc"></p-toast>
<p-toast position="top-right" key="tr"></p-toast>
<div class="pt-7">

    <div class="flex flex-column  pb-3">
        <div class="flex align-items-center justify-content-center">
            <img src="./assets/iconeCadastrarServicos.svg" class="iconeServico" />
        </div>
        <div class=" titulonegritoCadServico flex align-items-center justify-content-center">
            <span>Serviços</span>
        </div>
    </div>

    <div class="flex flex-column  pb-3">
        <div class="flex justify-content-center flex-wrap">
            <div class="tamanhoLinhaTabela">
                <!-- <table id="tabela">
                    <tr *ngFor="let servico of servico">
                        <td>
                            <div class="linhahorizontalCadastrarServico pt-1"></div>
                            <div class="col-12 sm-12 md-4 ">
                                <div class="flex flex-column">
                                    <div class="flex align-items-center justify-content-center tamanhoLinhaTabela">
                                        <div class="col"
                                            [ngStyle]="servico.ativo==='S'?{'opacity': ' 1'} : {'opacity': ' 0.5'}">
                                            <div class="fontTituloCadServico">
                                                <span class="xl:text-xl">{{servico.nomeprod}} - Profissional: {{servico.apelido}}</span>
                                            </div>
                                            <div class="formgroup-inline justify-content-between flex-wrap">
                                                <div class="fontDescricao pb-2 ">
                                                    <div class="flex gap-2">
                                                        <div
                                                            class="flex align-items-center justify-content-center gap-1 ">
                                                            <img class="" src="./assets/iconeServico.svg" />
                                                            <span class="xl:text-base"> {{servico.descrprod}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex align-items-center justify-content-center ">
                                            <img class="cursor-pointer" src="./assets/iconeMenuAdm.png" 
                                                (click)="menu.toggle($event); servicoSelecionado(servico)" />
                                            <p-menu #menu [popup]="true" [model]="itemsServicos"
                                                styleClass="menuCadServicos"></p-menu>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </td>
                    </tr>
                </table> -->

                <p-table [value]="servico" sortField="apelido" sortMode="single" [scrollable]="true"
                    [scrollHeight]="tamanhoTabela" rowGroupMode="subheader" groupRowsBy="apelido">


                    <ng-template pTemplate="groupheader" let-servico>
                        <tr pRowGroupHeader style="background-color: var(--cor8); height: 35px;">
                            <td>
                                <div>
                                    <span class="fontNomeBarbeiro">{{servico.apelido}}</span>
                                </div>
                            </td>
                        </tr>
                    </ng-template>


                    <ng-template pTemplate="body" let-servico>
                        <tr>
                            <td class="fontConteudoTabela" style="flex: 0 0 90%;">
                                <div [ngStyle]="servico.ativo==='S'?{'opacity': ' 1'} : {'opacity': ' 0.3'}">
                                    <div class="fontTituloCadServico">
                                        {{servico.nomeprod}}
                                    </div>
                                    <div class=" gap-1 ">
                                        <img class="" src="./assets/iconeServico.svg" />
                                        <span class="xl:text-base fontDescricao"> {{servico.descrprod}}</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="flex align-items-center justify-content-center ">
                                    <img class="cursor-pointer" src="./assets/iconeMenuAdm.png"
                                        (click)="menu.toggle($event); servicoSelecionado(servico)" />
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
        
        <p-menu #menu [popup]="true" [model]="itemsServicos" styleClass="menuCadServicos"></p-menu>

        <p-confirmDialog #cd [closable]="false" id="confirmCadastraServicos">
            <ng-template pTemplate="footer">
                <button type="button" pButton icon="pi pi-times" label="Retornar" (click)="cd.reject()"></button>
                <button type="button" pButton icon="pi pi-check" label="Confirmar" (click)="cd.accept()"></button>
            </ng-template>
        </p-confirmDialog>

        <div class="flex justify-content-center flex-wrap ">
            <div class="flex align-items-center justify-content-center">
                <footer class="xl:w-5" id="footerCadastrarServicos">
                    <p-button label="Cadastrar" id="botaoCadastrarServico" (click)="cadastrarServico()"
                        [style]="{width: '100%'}">
                    </p-button>
                </footer>
            </div>
        </div>

    </div>
</div>