import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { AlertService } from 'src/alert.service';
import { Estabelecimento } from 'src/assets/estabelecimento';
import { Profissional } from 'src/assets/profissional';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { UtilitariosService } from 'src/assets/utilitarios.service';
import { EstabelecimentoService } from 'src/estabelecimento.service';
import { ProfissionalService } from 'src/profissional.service';
import { DescontoService } from 'src/desconto.service';
import { PermissoesService } from 'src/permissoes.service';
import { TraducaoService } from 'src/traducao.service';

@Component({
  selector: 'app-desconto-total',
  templateUrl: './desconto-total.component.html',
  styleUrls: ['./desconto-total.component.scss'],
  providers: [MessageService, ConfirmationService],
  encapsulation: ViewEncapsulation.None
})
export class DescontoTotalComponent implements OnInit {

  showDescontoTotal: boolean = false;
  showAtivarDesativarDesconto: boolean = false;

  /**VARIAVEIS DO BOTAO DE LOADING */
  teveRespostaEstabelecimento: boolean = false;
  loading: boolean = false;

  /**RADIO BUTTON */
  radioButtonSelecionado: string = '';

  /**MENU */
  itemsDescontoTotal: MenuItem[] = [];

  nudes: number = 0;
  codcli: number = 0;
  codusuresp: string = '';
  nomeDesconto: string = '';
  estabelecimento: Estabelecimento[] = [];
  profissional: Profissional[] = [];
  estabelecimentoSelecionado: any;
  profissionalSelecionado: any;
  faixaMaiorQue: any;
  faixaMenorQue: any;
  descontoTotal: any[] = [];
  descontoSelec: any;
  tipoCupom: any[];
  desc: any;
  tipdesc: string = '';
  todasEstabelecimentos: any;
  todosProfissionals: any;

  descVazio: any;


  /**VARIAVEIS DE PERMISSÕES */
  permissao: any;
  alterar: boolean = false;
  excluir: boolean = false;
  cadastrar: boolean = false
  inativar: boolean = false;

  constructor(private estabelecimentoService: EstabelecimentoService, private profissionalService: ProfissionalService, private decodedTokenService: DecodedTokenService,
    private descontoService: DescontoService, private traducaoService: TraducaoService, private alertService: AlertService, private confirmationService: ConfirmationService,
    private messageService: MessageService, private utilitariosService: UtilitariosService, private permissoesService: PermissoesService) {
    /**TRADUÇÃO */
    this.traducaoService.changeLang();

    this.tipoCupom = [{ 'tipo': 'P', 'opcao': '% Percentual' }, { 'tipo': 'R', 'opcao': 'R$ Valor' }];
    this.todasEstabelecimentos = { "nome": "Todas Estabelecimentos", "codemp": 0 };
    this.todosProfissionals = { "nomefunc": "Todos Profissionals", "codfunc": 0 };

  }

  ngOnInit(): void {
    this.recuperaCodUsu();
    this.recuperaCodCli();
    this.recuperaPermissao();
    this.buscarEstabelecimentosPorCodCliente();
    this.buscaDescontoTotalPorCodCli();


    this.itemsDescontoTotal = [{
      items: [{
        label: 'Detalhes',
        command: () => {
          this.preencheDadosDialogDesconto();
        }
      }
      ]
    },
    {
      items: [{
        label: 'Ativa/Desativa',
        styleClass: 'linhaMenu',
        command: () => {
          this.mostraDialogAtvDstDesconto();
        }
      }
      ]
    },
    {
      items: [{
        label: 'Excluir',
        styleClass: 'linhaMenu',
        command: () => {
          this.confirmaExclusaoDesconto();
        }
      }
      ]
    }
    ];
  }


  mostraDialogDescontoTotal() {
    if ((this.cadastrar && this.permissao != undefined && this.permissao.length > 0) || (this.codcli > 0 && (this.permissao === undefined || this.permissao.length === 0))) {

      this.nomeDesconto = '';
      this.estabelecimentoSelecionado = '';
      this.profissionalSelecionado = '';
      this.faixaMaiorQue = null;
      this.faixaMenorQue = null;
      this.showDescontoTotal = true;
      this.descVazio = null;
      this.tipdesc = '';
      this.desc = '';
    } else {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
  }

  mostraDialogAtvDstDesconto() {
    /**VERIFICA SEM TEM PERMISSÃO PARA ESSA AÇÃO */
    console.log(this.permissao, this.codcli)
    if ((this.inativar && this.permissao != undefined && this.permissao.length > 0) || (this.codcli > 0 && (this.permissao === undefined || this.permissao.length === 0))) {
      this.radioButtonSelecionado = this.descontoSelec.ativo;
      this.showAtivarDesativarDesconto = true;
    } else {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
  }


  /**RECUPERA O CODUSU PARA SER USADO NA AUDITORIA DOS METODOS */
  recuperaCodUsu() {
    this.codusuresp = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }

  /**RECUPERA O CODCLI PARA SER USADO NA BUSCA DAS BARBEARIAS */
  recuperaCodCli() {
    this.codcli = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODCLI;
  }

  /**METODO QUE PREENCHE O DIALOG DE DESCONTO APOS CLICAR EM 'Detalhes'*/
  preencheDadosDialogDesconto() {
    if ((this.alterar && this.permissao != undefined && this.permissao.length > 0) || (this.codcli > 0 && (this.permissao === undefined || this.permissao.length === 0))) {
      this.nudes = this.descontoSelec.nudes;
      this.estabelecimentoSelecionado = this.descontoSelec.codemp;

      /**FAZ A BUSCA DOS BARBEIROS PARA O DROPDOWN*/
      this.buscarProfissionalsPorCodEmp();

      this.profissionalSelecionado = this.descontoSelec.codfunc;
      this.faixaMaiorQue = this.descontoSelec.liminf;
      this.faixaMenorQue = this.descontoSelec.limsup;
      this.nomeDesconto = this.descontoSelec.nomedesconto;
      this.tipdesc = this.descontoSelec.tipdesc;
      this.desc = this.descontoSelec.desc;
      this.showDescontoTotal = true;
    } else {
      this.showError('Atenção', 'Ação não permitida.');
      return;
    }
  }

  /**METODO DE CRIAÇÃO/EDIÇÃO DO DESCONTO */
  criaDesconto() {
    this.loading = true;

    /**FAZ VALIDAÇÃO DOS CAMPOS */
    if (this.faixaMaiorQue > this.faixaMenorQue || this.faixaMaiorQue === this.faixaMenorQue) {
      this.loading = false;
      this.showError('Atenção', 'Verifique os valores das faixas de desconto.')
      return;
    }



    if (this.utilitariosService.validaCampo([this.nomeDesconto, this.faixaMenorQue])) {
      console.log('É necessário preencher todos os campos.')
      this.loading = false;
      this.showError('Atenção', 'É necessário preencher todos os campos.')
      return;
    }


    if (this.faixaMaiorQue === null) {
      this.faixaMaiorQue = 0;
    }
    if (this.nudes > 0) {
      this.descontoService.createDescontoTotal(this.nudes, this.nomeDesconto, this.codcli, this.estabelecimentoSelecionado, this.profissionalSelecionado, this.faixaMaiorQue,
        this.faixaMenorQue, this.tipdesc, this.desc, this.codusuresp).subscribe((res) => {
          this.alertService.alert('Desconto alterado!', 'success')
          this.loading = false;
          this.showDescontoTotal = false;
          this.buscaDescontoTotalPorCodCli();
          console.log(res)
        },
          (error) => {
            console.log('deu ruim')
            this.loading = false;
            this.showDescontoTotal = false;
            this.alertService.alert('Algo deu errado.', 'error');
          })
    } else {
      this.descontoService.createDescontoTotal(null, this.nomeDesconto, this.codcli, this.estabelecimentoSelecionado, this.profissionalSelecionado, this.faixaMaiorQue,
        this.faixaMenorQue, this.tipdesc, this.desc, this.codusuresp).subscribe((res) => {
          this.alertService.alert('Desconto Criado!', 'success')
          this.loading = false;
          this.showDescontoTotal = false;
          this.buscaDescontoTotalPorCodCli();
          console.log(res)
        },
          (error) => {
            console.log('deu ruim')
            this.loading = false;
            this.showDescontoTotal = false;
            this.alertService.alert('Algo deu errado.', 'error');
          })
    }
  }


  /**METODO QUE RECUPERA A LISTA DE BARBEARIAS E OS COLOCA NO DROPDOWN*/
  buscarEstabelecimentosPorCodCliente() {
    console.log(this.codcli)
    this.estabelecimentoService.buscarEstabelecimentosporCodCli(this.codcli, this.codusuresp).subscribe((res) => {
      this.estabelecimento = res;

      /**INSERE A OPÇÃO DE TODAS BARBEARIAS */
      this.estabelecimento.push(this.todasEstabelecimentos);
      this.estabelecimento.sort(function (x, y) {
        return Number(x.codemp) - Number(y.codemp);
      })
    },
      (error) => {
        console.log(error.message)
        console.log('deu ruim')

      })
  }

  /**METODO QUE RECUPERA A LISTA DE BARBEIROS E OS COLOCA NO DROPDOWN*/
  buscarProfissionalsPorCodEmp() {
    //   this.teveRespostaEstabelecimento = true;
    //   setTimeout(() => {
    //     this.profissionalService.buscarProfissionalporCodEmp(this.estabelecimentoSelecionado, this.codusuresp).subscribe((res) => {
    //       this.teveRespostaEstabelecimento = false;
    //       this.profissional = res;
    //     },
    //       (error) => {
    //         this.teveRespostaEstabelecimento = false;
    //         console.log(error.message)
    //         console.log('deu ruim')

    //       })
    //   }, 500);

    this.teveRespostaEstabelecimento = true;
    /**VERIFICA SE TODAS BARBEARIAS FOI SELECIONADO (CODEMP = 0)*/
    if (this.estabelecimentoSelecionado === 0) {
      /**SE SIM, BUSCA TODOS OS BARBEIROS, POR CODCLI */
      setTimeout(() => {
        this.profissionalService.buscarProfissionalporCodCli(this.codcli, this.codusuresp).subscribe((res) => {
          this.teveRespostaEstabelecimento = false;
          this.profissional = res;

          /**INSERE A OPÇÃO DE TODOS BARBEIROS */
          this.profissional.push(this.todosProfissionals);
          this.profissional.sort(function (x, y) {
            return Number(x.codfunc) - Number(y.codfunc);
          })
        },
          (error) => {
            this.teveRespostaEstabelecimento = false;
            console.log(error.message)
            console.log('deu ruim')

          })
      }, 500);
    } else {
      /**SE NÃO BUSCA, BUSCA OS BARBEIROS PELA EMPRESA SELECIONADA, CODEMP */
      setTimeout(() => {
        this.profissionalService.buscarProfissionalporCodEmp(this.estabelecimentoSelecionado, this.codusuresp).subscribe((res) => {
          this.teveRespostaEstabelecimento = false;
          this.profissional = res;
        },
          (error) => {
            this.teveRespostaEstabelecimento = false;
            console.log(error.message)
            console.log('deu ruim')

          })
      }, 500);
    }
  }





  /**METODO QUE RECUPERA A LISTA DE DESCONTO E OS COLOCA NA TABELA*/
  buscaDescontoTotalPorCodCli() {
    this.descontoTotal = [];

    this.descontoService.buscaDescontoTotalPorCodCli(this.codcli, this.codusuresp).subscribe((res) => {
      this.descontoTotal = res;
    },
      (error) => {
        console.log(error.message);
        console.log('deu ruim');
        this.alertService.alert('Algo deu errado.', 'error');
      })
  }

  /**METODOS PARA PREENCHIMENTO DOS DADOS DO CUPOM SELECIONADO EM 'Detalhes' */
  descontoSelecionado(descontoTotal: any) {
    this.descontoSelec = descontoTotal;
    console.log(this.descontoSelec)
  }

  /**METODOS DE EXCLUSÃO DO DESCONTO */
  excluiDescontoPorNudes() {
    this.descontoService.excluiDescontoTotalPorNudes(this.descontoSelec.nudes!, this.codusuresp).subscribe((res) => {
      console.log(res);
      this.descontoTotal = this.descontoTotal.filter((x: any) => x.nudes != res.nudes); //FILTRA TUDO O QUE É DIFERENTE DO QUE FOI EXCLUIDO

    });
  }

  confirmaExclusaoDesconto() {
    if ((this.excluir && this.permissao != undefined && this.permissao.length > 0) || (this.codcli > 0 && (this.permissao === undefined || this.permissao.length === 0))) {

      this.confirmationService.confirm({
        message: 'Deseja mesmo excluir ' + this.descontoSelec.nomedesconto + ' ?',
        header: 'Excluir Desconto',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.excluiDescontoPorNudes();
        },
        reject: () => {
        }
      });
    } else {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
  }

  /**SEMPRE QUE MUDAR A OPÇÃO DO TIPO DE DESCONTO VAI ZERAR O CAMPO DE DESCONTO */
  zeraCampoDesconto() {
    this.desc = 0;
  }



  /**METODO PARA ATIVAR/DESATIVAR O DESCONTO */
  ativaDesativaCupom() {
    this.loading = true;
    if (this.descontoSelec.ativo != this.radioButtonSelecionado) {
      this.descontoService.ativarDesativarDescontoTotalPorNudes(this.descontoSelec.nudes, this.codusuresp).subscribe((res) => {
        console.log(res)
        this.showAtivarDesativarDesconto = false;
        if (Object(res).ativo === 'S') {
          this.showAtivarDesativarDesconto = false;
          this.loading = false;
          this.alertService.alert('Desconto ativado!', 'success')
        }
        if (Object(res).ativo === 'N') {
          this.showAtivarDesativarDesconto = false;
          this.loading = false;
          this.alertService.alert('Desconto desativado!', 'success')
        }
        this.buscaDescontoTotalPorCodCli();
      },
        (error) => {
          console.log(error.message)
          console.log('deu ruim')
          this.loading = false;
          this.showAtivarDesativarDesconto = false;
          this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')
        });
    } else {
      this.loading = false;
    }
  }














  /**RECUPERA AS PERMISSÕES */
  recuperaPermissao() {
    console.log('recuperei as permissoes')
    this.permissao = this.permissoesService.getPermissao();
    console.log(this.permissao)
    if (this.permissao != undefined && this.permissao.length > 0) {
      if (this.permissao[8].acao[0].PERMITE === 'S') {
        this.cadastrar = true;
      }
      if (this.permissao[8].acao[1].PERMITE === 'S') {
        this.alterar = true;
      }
      if (this.permissao[8].acao[2].PERMITE === 'S') {
        this.excluir = true;
      }
      if (this.permissao[8].acao[3].PERMITE === 'S') {
        this.inativar = true;
      }
      // /**ATRIBUI O CODCLI VINCULADO NA PERMISSAO DO FUNCIONARIO, PARA FAZER AS OPERAÇÕES DA TELA */
      this.codcli = this.permissao[0].codcli;
    }
  }






  /**METODO DE TOAST */
  showError(titulo: string, mensagem: string) {
    if (screen.width < 640 || screen.height < 480) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else if (screen.width < 1024 || screen.height < 768) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else {
      this.messageService.add({
        key: 'tr',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    }
  }
}
