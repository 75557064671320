import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, first, map, Observable, of, Subject, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PermissoesService } from 'src/permissoes.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  status: any;
  public status$ = new Subject();
  public statusUpdLoginServer$ = new Subject();
  email: string = '';
  codRecuperacao: string = '';

  constructor(private http: HttpClient, private router: Router, private permissoesService: PermissoesService) { }

  /**----------- METODO ANTIGO DE LOGIN ----------- */
  // login(user: any): any {
  //   if (environment.HOMOLOGACAO) { //PARA EU PODER DAR LOGIN SEM API
  //     this.router.navigate(['/']);
  //     //localStorage.setItem('exibeMural', '1');
  //   }
  //   console.log(user);
  //   const result = this.http
  //     .post<any>(`${environment.BASE_URL}/login`, user)
  //     .subscribe((t) => {
  //       localStorage.setItem('token', t.token);
  //       //  localStorage.setItem('exibeMural', '1');
  //       this.router.navigate(['/']);
  //       this.status = t;
  //       this.status$.next(true);
  //       console.log(this.status)
  //     },
  //       (e) => {
  //         this.status$.next(e);
  //         console.log(e)
  //         console.error('ops!!! falha na requisição')
  //       });
  // }

  login(email: any, senha: any,  codtipseg: number): Observable<any> {
    if (environment.HOMOLOGACAO) { //PARA EU PODER DAR LOGIN SEM API
      this.router.navigate(['/']);
      //localStorage.setItem('exibeMural', '1');
    }
   // console.log(user);
      //var user = { email: this.email, password: this.senha, codtipseg: codtipseg };

    var usuario = { "email": email, "password": senha, "codtipseg": codtipseg };

    return this.http
      .post<any>(`${environment.BASE_URL}/login`, usuario)
      .pipe(
        first(),
        tap((t) => {
          this.permissoesService.limpaPermissao();
          localStorage.setItem('token', t.token);
          //  localStorage.setItem('exibeMural', '1');
          this.router.navigate(['/']);
          this.status = t;
          this.status$.next(true);
          console.log(this.status)
        },
          (e) => {
            this.status$.next(e);
            console.log(e)
            console.error('ops!!! falha na requisição')
          }))
  }





  validaLogin() {
    if (environment.HOMOLOGACAO) { //PARA EU PODER DAR LOGIN SEM API
      return of(true);
    }
    const token = localStorage.getItem('token');

    if (!token) {
      this.router.navigate(['/login']);
      return of(false);
    }

    const headers = { Authorization: token };
    const user = {};

    return this.http
      .post<any>(`${environment.BASE_URL}/login/validate`, user, { headers })
      .pipe(
        map((res: Response) => {
          return true;
        }),
        catchError((err) => {
          localStorage.removeItem('token');
          this.router.navigate(['/login']);
          return of(false);
        })
      );
  }

  logout() {
    //localStorage.removeItem('exibeMural');
    this.permissoesService.limpaPermissao();
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
  }

  updatePasswordLogado(email: string, oldPassword: string, newPassword: string, codusuresp: string, codtipseg: number) {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    var user = { "email": email, "oldPassword": oldPassword, "newPassword": newPassword, "codusuresp": codusuresp, "codtipseg": codtipseg };
    console.log(user)
    return this.http
      .put<any>(`${environment.BASE_URL}/login/updatepassword`, user, { headers: headers })
      .pipe(
        first(),
        tap((r: any) => {
          console.log(r);
          // login para renovar o token
         // var us = { email: user.email, password: user.newPassword };
          this.login(user.email, user.newPassword, codtipseg);
        }
          ,
          (e: any) => {
            this.statusUpdLoginServer$.next(e.status);
            console.error(e);
          }))
  }

  /**----------- METODO ANTIGO ----------- */
  // updatePasswordLogado(email: string, oldPassword: string, newPassword: string) { 
  //   var user = { "email": email, "oldPassword": oldPassword, "newPassword": newPassword };
  //   console.log(user)
  //   const result = this.http
  //     .put<any>(`${environment.BASE_URL}/login/updatepassword`, user)
  //     .subscribe((t) => {
  //       this.statusUpdLoginServer$.next(200);

  //       // login para renovar o token
  //       var us = { email: user.email, password: user.newPassword };

  //       this.login(us);
  //     },
  //       (e) => {
  //         this.statusUpdLoginServer$.next(e.status);
  //         console.error(e);
  //       });
  // }






  // updatePasswordDeslogado(email: string, newPassword: string, codigo: string) {
  //   const usuario = { "email": email, "newPassword": newPassword, "codigo": codigo }
  //   return this.http
  //     .put<any>(`${environment.BASE_URL}/login/updatepassworddeslog`, usuario)
  //     .pipe(
  //       first(),
  //       tap((r: any) => console.log(r), (e: any) => console.log(e)));
  // }





  // createUser(user: any): any {
  //   return this.http
  //     .post<any>(`${environment.BASE_URL}/login/createacount`, user)
  //     .subscribe((t) => {
  //       this.status = t;
  //       this.status$.next(true);
  //       console.log(t)

  //     },
  //       (e) => {
  //         this.status$.next(false);
  //         console.error('ops!!! falha na requisição')
  //         console.log(e)
  //       });
  // }

  createUser(nome: string, email: string, telefone: string, datanasc: any, senha: string, codemp: number, cgc_cpfcnpj: any, codtipseg: number): Observable<any> {

    const usuario = { "nome": nome, "email": email, "telefone": telefone, "datanasc": datanasc, "senha": senha, "codemp": codemp, "cgc_cpfcnpj": cgc_cpfcnpj, "codtipseg": codtipseg };

    return this.http
      .post<any>(`${environment.BASE_URL}/login/createacount`, usuario)
      .pipe(
        first(),
        tap((r: any) => console.log(r), (e: any) => console.log(e)));
  }

  updateUser(codusu: number, nome: string, email: string, telefone: string, datanasc: any, detalhes: any, cgc_cpfcnpj: any, codusuresp: any): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);
    const usuario = { "codusu": codusu, "nome": nome, "email": email, "telefone": telefone, "datanasc": datanasc, "detalhes": detalhes, "cgc_cpfcnpj": cgc_cpfcnpj, "codusuresp": codusuresp };

    return this.http
      .post<any>(`${environment.BASE_URL}/login/updateuser`, usuario, { headers: headers })
      .pipe(
        first(),
        tap((r: any) => console.log(r), (e: any) => console.log(e)));
  }

  filiarEmp(codemp: any, codusu: any) {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const usuario = { "codemp": codemp, "codusu": codusu }

    return this.http
      .put<any>(`${environment.BASE_URL}/login/filiaraemp`, usuario, { headers: headers })
      .pipe(
        first(),
        tap((r: any) => console.log(r), (e: any) => {
          this.verificaToken(e.status);
        }));
  }

  criaCodRecuperarConta(email: string, codtipseg: number) {
    const usuario = { "email": email, "codtipseg": codtipseg }
    return this.http
      .post<any>(`${environment.BASE_URL}/login/criarcodrecuperaconta`, usuario)
      .pipe(
        first(),
        tap((r: any) => console.log(r),
          (e: any) => {
            //this.status$.next(e);
            console.log(e)
            return e;
          }
        ));
  }

  validaCodRecuperarConta(email: string, codigo: string, codtipseg: number) {
    const emailCod = { "email": email, "codigo": codigo, "codtipseg": codtipseg }
    console.log(emailCod)
    return this.http
      .post<any>(`${environment.BASE_URL}/login/validacodrecuperaconta`, emailCod)
      .pipe(
        first(),
        tap((r: any) => console.log(r),
          (e: any) => {
            //this.status$.next(e);
            console.log(e)
            return e;
          }));
  }

  updatePasswordDeslogado(email: string, newPassword: string, codigo: string, codtipseg: number) {
    const usuario = { "email": email, "newPassword": newPassword, "codigo": codigo, "codtipseg": codtipseg }
    return this.http
      .put<any>(`${environment.BASE_URL}/login/updatepassworddeslog`, usuario)
      .pipe(
        first(),
        tap((r: any) => console.log(r), (e: any) => console.log(e)));
  }


  /**VERIFICA SE O TOKEN É VÁLIDO */
  verificaToken(token: number) {
    if (token === 401) {
      localStorage.removeItem('token');
      this.router.navigate(['/login']);
    }
  }
  atualizaTokenCelular( codusu: any, tokencel: any, codusuresp: any) {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const usuario = { "codusu": codusu, "tokencel": tokencel, "codusuresp": codusuresp }

    return this.http
      .put<any>(`${environment.BASE_URL}/login/atualizatokencel`, usuario, { headers: headers })
      .pipe(
        first(),
        tap((r: any) => console.log(r)
        , (e: any) => {
         this.verificaToken(e.status);
        }
        ));
  }


  public setEmail(email: string) { //SETA O EMAIL QUE PEDIU PARA RECUPERAR SENHA
    this.email = email;
  }

  public getEmail() { //RECUPERA O EMAIL QUE PEDIU PARA RECUPERAR SENHA
    return this.email;
  }

  public setCodigo(codRecuperacao: string) { //SETA O EMAIL QUE PEDIU PARA RECUPERAR SENHA
    this.codRecuperacao = codRecuperacao;
  }

  public getCodigo() { //RECUPERA O EMAIL QUE PEDIU PARA RECUPERAR SENHA
    return this.codRecuperacao;
  }
}


