<p-toast position="bottom-center" key="bc"></p-toast>
<p-toast position="top-right" key="tr"></p-toast>
<div class="pt-7">


    <div class="flex flex-column  pb-3">
        <div class="flex align-items-center justify-content-center">
            <img src="./assets/iconeBarbearia.svg" class="iconeEstabelecimento" />
        </div>
        <div class=" titulonegritoCadEstabelecimento flex align-items-center justify-content-center">
            <span>Estabelecimentos</span>
        </div>
    </div>

    <div class="flex flex-column  pb-3">
        <div class="flex justify-content-center flex-wrap ">


            <div class="scroll-divCadEstabelecimentos">
                <div class="">
                    <table id="tabela">
                        <tr *ngFor="let estabelecimento of estabelecimento">
                            <td>
                                <div class="linhahorizontalCadastrarEstabelecimento pt-1"></div>
                                <div class="col-12 sm-12 md-4 ">
                                    <div class="flex flex-column">
                                        <div class="flex align-items-center justify-content-center tamanhoLinhaTabela">
                                            <div class="col"
                                                [ngStyle]="estabelecimento.ativo==='S'?{'opacity': ' 1'} : {'opacity': ' 0.5'}">
                                                <div class="fontTituloCadEstabelecimento">
                                                    <span class="xl:text-xl">{{estabelecimento.nome}}</span>
                                                </div>
                                                <div class="fontlocalizacao pb-2 ">
                                                    <div class="flex gap-2">
                                                        <img src="./assets/localizacao.svg" class="iconeLocalizacao " />
                                                        <div
                                                            class="flex align-items-center justify-content-center gap-1">
                                                            <span class="xl:text-base"> {{estabelecimento.rua}}
                                                                {{estabelecimento.complemento}},
                                                                {{estabelecimento.numero}}</span>
                                                            <div>
                                                                <span class="xl:text-base"> {{estabelecimento.setor}} -
                                                                    {{estabelecimento.cidade}} -
                                                                    {{estabelecimento.uf}} </span>
                                                            </div>
                                                            <div>
                                                                <span class="xl:text-base"> {{estabelecimento.contato}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="flex align-items-center justify-content-center ">
                                                <img class="cursor-pointer" src="./assets/iconeMenuAdm.png"
                                                    (click)="menu.toggle($event); estabelecimentoSelecionada(estabelecimento)" />
                                                <p-menu #menu [popup]="true" [model]="itemsEstabelecimentos"
                                                    styleClass="menuCadEstabelecimentos"></p-menu>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

        <p-confirmDialog #cd [closable]="false" id="confirmCadastraEstabelecimentos">
            <ng-template pTemplate="footer">
                <button type="button" pButton icon="pi pi-times" label="Retornar" (click)="cd.reject()"></button>
                <button type="button" pButton icon="pi pi-check" label="Confirmar" (click)="cd.accept()"></button>
            </ng-template>
        </p-confirmDialog>

        <div class="flex justify-content-center flex-wrap ">
            <div class="flex align-items-center justify-content-center">
                <footer class="xl:w-5" id="footerCadastrarEstabelecimento">
                    <p-button label="Cadastrar" id="botaoCadastrarEstabelecimento" (click)="cadastrarEstabelecimento()">
                    </p-button>
                </footer>
            </div>
        </div>

    </div>
</div>