import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { PermissoesService } from 'src/permissoes.service';
import { UsuarioService } from 'src/usuario.service';

@Component({
  selector: 'app-painel-barber',
  templateUrl: './painel-barber.component.html',
  styleUrls: ['./painel-barber.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PainelBarberComponent implements OnInit {
  /**CODIGOS */
  codcli: number = 0;
  codUsu: string = '';
  codfunc: number = 0;
  permissao: any;

  mostraCarregando: boolean = true;

  constructor(private decodedTokenService: DecodedTokenService, private permissoesService: PermissoesService, private usuarioService: UsuarioService) { }

  ngOnInit(): void {
    this.recuperaCodCli();
    this.recuperaCodUsu();
    this.recuperaPerfil();
    // this.recuperaPermissoes();
  }

  /**METODO QUE RECUPERA O CODCLI PARA FAZER A BUSCA DE PERMISSOES */
  recuperaCodCli() {
    this.codcli = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODCLI;
  }

  /**METODO QUE RECUPERA O CODCLI PARA FAZER A BUSCA DO CODFUNC */
  recuperaCodUsu() {
    this.codUsu = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }

  /**METODO QUE RECUPERA AS PERMISSÕES DO FUNCIONARIO PARA A MOSTRAR OS BOTÕES DE REDIRECIONAMENTO */
  recuperaPermissoes() {
    //   this.permissoesService.bucaPermissaoPorCodfunc(this.codfunc).subscribe((res) => {
    //     this.permissao = res;

    //     /**TRANSFORMA EM JSON O VETOR DE AÇÕES (ELE VEM COMO STRING DO BACKEND) */
    //     for (let i = 0; i < this.permissao.length; i++) {
    //       this.permissao[i].acao = JSON.parse(Object(this.permissao[i].acao));
    //       /**RETIRA OS ESPAÇOS EM BRANCO */
    //       this.permissao[i].habilita = this.permissao[i].habilita.replace(/\s/g, '');

    //       // if(this.permissao[i].codfunc === this.codfunc){
    //       //   console.log(this.permissao[i]);
    //       // }
    //     }
    //     console.log(this.permissao)
    //   })
    this.permissao = this.permissoesService.getPermissao();
  }



  /**RECUPERA OS DADOS DO PERFIL DE USUARIO PARA SABER SEU CODFUNC */
  recuperaPerfil() {
    this.usuarioService.getPerfil(this.codUsu, this.codUsu).subscribe((res) => {
      this.codfunc = Object(res)[0].codfunc;
      console.log(this.codfunc)
      this.mostraCarregando = false;
      /**APOS RECUPERAR O CODFUNC BUSCA AS PERMISSOES */
      //this.recuperaPermissoes();


      this.permissoesGuard();
    },
      (error) => {
        console.log(error.message)
        console.log('deu ruim')
        this.mostraCarregando = false;
        //this.showError('Atenção', 'Algo deu errado.')
      });
  }


  /**ATRIBUI AS PERMISSOES NO VETOR DO GUARD */
  permissoesGuard() {
    this.permissoesService.recuperaPermissao(this.codfunc);

    /**QUANDO ENTRAR NO PainelBarber VAI RECUPERAR AS PERMISSÕES*/
    this.permissoesService.permissao$.subscribe((data) => {
      this.permissao = Object(data);
      console.log(this.permissao)
    })
    //this.recuperaPermissoes();
  }

}
