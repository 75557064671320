<p-toast position="bottom-center" key="bc"></p-toast>
<p-toast position="top-right" key="tr"></p-toast>
<div class="pt-7">

    <div class="flex flex-column  pb-3">
        <div class="flex align-items-center justify-content-center">
            <img src="./assets/iconeCadastrarInformativos.svg" class="iconeInformativos" />
        </div>
        <div class="tituloNegritoCadInformativos flex align-items-center justify-content-center">
            <span>Informativos</span>
        </div>
    </div>

    <div class="flex flex-column  pb-3">
        <div class="flex justify-content-center flex-wrap ">
            <div class="scroll-divCadInformativos">
                <table id="tabela">
                    <tr *ngFor="let informativo of informativo">
                        <td>
                            <div class="linhahorizontalCadastrarInformativos pt-1"></div>
                            <div class="col-12 sm-12 md-4 ">
                                <div class="flex flex-column">
                                    <div class="flex align-items-center justify-content-center  tamanhoLinhaTabela">
                                        <div class="col">
                                            <div
                                                class="fontTituloCadInformativos maximoCaracterTituloInformativo white-space-nowrap overflow-hidden text-overflow-ellipsis">
                                                <span class="xl:text-xl">{{informativo.titulo}}</span>
                                            </div>
                                        </div>
                                        <div class="flex align-items-center justify-content-center ">
                                            <img class="cursor-pointer" src="./assets/iconeMenuAdm.png"
                                                (click)="menu.toggle($event);informativoSelecionado(informativo) " />
                                            <p-menu #menu [popup]="true" [model]="itemsInformativos"
                                                styleClass="menuCadBarbearias"></p-menu>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <p-confirmDialog #cd [closable]="false" id="confirmCadastraInformativos" styleClass="">
            <ng-template pTemplate="footer">
                <button type="button" pButton icon="pi pi-times" label="Retornar" (click)="cd.reject()"
                    id="confirmBotaoInformativos"></button>
                <button type="button" pButton icon="pi pi-check" label="Confirmar" (click)="cd.accept()"
                    id="confirmBotaoInformativos"></button>
            </ng-template>
        </p-confirmDialog>

        <div class="flex justify-content-center flex-wrap ">
            <div class="flex align-items-center justify-content-center">
                <footer class="xl:w-5" id="footerCadastrarInformativos">
                    <p-button label="Cadastrar" id="botaoCadastrarInformativo" (click)="cadastrarInformativo()"
                        [style]="{width: '100%'}">
                    </p-button>
                </footer>
            </div>
        </div>

    </div>
</div>