<p-toast position="bottom-center" key="bc"></p-toast>
<p-toast position="top-right" key="tr"></p-toast>
<div class="pt-7">

    <div class="flex flex-column  pb-3">
        <div class="flex align-items-center justify-content-center">
            <img src="./assets/iconePedidos.svg" class="iconeServico" />
        </div>
        <div class="tituloNegritoPedidos flex align-items-center justify-content-center">
            <span>Lista de Pedidos </span>
        </div>
    </div>

    <div class="col-12 sm-12 md-4 ">
        <div class=" flex align-items-center justify-content-center gap-2">
            <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input type="text" class="col-12" pInputText placeholder="Pesquisar" id="pesquisa"
                    (keydown)="buscarPedidos()">
            </span>
            <img src="./assets/iconeFiltroPedidos.svg" class="cursor-pointer" (click)="mostraDialogPeriodoVendas()" />
        </div>
    </div>


    <div class="flex flex-column  pb-3">
        <div class="flex justify-content-center flex-wrap ">


            <div class="scroll-div">
                <table id="tabela">
                    <tr *ngFor="let vendas of vendas">
                        <td>
                            <div class="linhahorizontalPedidos pt-1"></div>
                            <div class="col-12 sm-12 md-4 ">
                                <div class="flex flex-column">

                                    <div
                                        class="flex align-items-center justify-content-center tamanhoLinhaTabelaPedidos">
                                        <div class="col ">
                                            <div class="flex justify-content-between">
                                                <div class="fontTituloPedidos">
                                                    <span class="xl:text-xl"># {{vendas.descr}}</span>
                                                </div>
                                                <!-- 
                                                <div *ngIf="vendas.qtdfinpend === 1">
                                                    <div class="statusAberto">
                                                        <div class="fontStatusVenda  flex justify-content-center flex-wrap">
                                                            <span>Aberto</span>
                                                        </div>
                                                    </div>
                                                </div>
                                
                                                <div *ngIf="vendas.qtdfinpend === 0">
                                                    <div class="statusPago">
                                                        <div class="fontStatusVenda  flex justify-content-center flex-wrap">
                                                            <span>Pago</span>
                                                        </div>
                                                    </div>
                                                </div> -->


                                            </div>
                                            <div class="formgroup-inline justify-content-between flex-wrap">
                                                <div class="fontInfos pb-2 ">
                                                    <div class="flex gap-2">
                                                        <div
                                                            class="flex align-items-center justify-content-center gap-1 ">
                                                            <img src="./assets/iconeTel.svg" />
                                                            <div class="flex flex-column">
                                                                <div>
                                                                    <span class="xl:text-base">Pedido com
                                                                        {{vendas.QTDITENS}}
                                                                        item(s) -
                                                                        {{vendas.dia | date:'dd/MM/yyyy'}}</span>
                                                                </div>
                                                                <div>
                                                                    <span class="xl:text-base">
                                                                        {{vendas.obs}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex align-items-center justify-content-center ">
                                            <div class="flex flex-column ">


                                                <div *ngIf="vendas.qtdfinpend > 0">
                                                    <div class="statusAberto">
                                                        <div
                                                            class="fontStatusVenda  flex justify-content-center flex-wrap">
                                                            <span>Aberto</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngIf="vendas.qtdfinpend === 0">
                                                    <div class="statusPago">
                                                        <div
                                                            class="fontStatusVenda  flex justify-content-center flex-wrap">
                                                            <span>Pago</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="fontValorPedidos pr-4">
                                                    <span class="xl:text-xl">{{vendas.vlrtot | currency : 'BRL'}}
                                                    </span>
                                                </div>
                                            </div>
                                            <img class="cursor-pointer" src="./assets/iconeMenuAdm.png"
                                                (click)="menu.toggle($event);vendaSelecionada(vendas)" />
                                            <p-menu #menu [popup]="true" [model]="itemsVendas"
                                                styleClass="menuPedidos"></p-menu>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </td>
                    </tr>
                </table>
            </div>
        </div>



        <!-- DIALOG ALTERAR DIA DO FILTRO -->
        <p-dialog header="Período" [(visible)]="showAlterar" [modal]="true" [style]="{'width': '350px'}"
            [closable]="true" [resizable]="false" id="dialogAlterarPeriodo"
            [ngStyle]="{'height': valorSelecionadoAlterar == 'outroPeriodo' ? '340px' : '280px' }">

            <p-dropdown id="dropPedidos" [options]="opcoesFiltro" [(ngModel)]="opcoesFiltroSelecionado"
                placeholder="Status" optionLabel="nome" optionValue="opcao" [style]="{'width': '100%'}"
                scrollHeight="130px">
            </p-dropdown>



            <div class="p-field-radiobutton pb-1 pt-2" (click)="clickOutroPeriodo()">
                <p-radioButton value="hoje" [(ngModel)]="valorSelecionadoAlterar"></p-radioButton>
                <label class="pl-2 fontCinzaPopUp">Hoje</label>
            </div>
            <div class="p-field-radiobutton pb-1" (click)="clickOutroPeriodo()">
                <p-radioButton value="ontem" [(ngModel)]="valorSelecionadoAlterar"></p-radioButton>
                <label class="pl-2 fontCinzaPopUp">Ontem</label>
            </div>
            <div class="p-field-radiobutton pb-1" (click)="clickOutroPeriodo()">
                <p-radioButton value="7Dias" [(ngModel)]="valorSelecionadoAlterar"></p-radioButton>
                <label class="pl-2 fontCinzaPopUp">Últimos 7 dias</label>
            </div>
            <div class="p-field-radiobutton pb-1" (click)="clickOutroPeriodo()">
                <p-radioButton value="15Dias" [(ngModel)]="valorSelecionadoAlterar"></p-radioButton>
                <label class="pl-2 fontCinzaPopUp">Últimos 15 dias</label>
            </div>
            <div class="p-field-radiobutton pb-1" (click)="clickOutroPeriodo()">
                <p-radioButton value="ultimoMes" [(ngModel)]="valorSelecionadoAlterar"></p-radioButton>
                <label class="pl-2 fontCinzaPopUp">Últimos mês</label>
            </div>
            <div class="p-field-radiobutton pb-1" (click)="clickOutroPeriodo()">
                <p-radioButton value="outroPeriodo" [(ngModel)]="valorSelecionadoAlterar"></p-radioButton>
                <label class="pl-2 fontCinzaPopUp">Outro período</label>
            </div>

            <div *ngIf="valorSelecionadoAlterar==='outroPeriodo'">
                <div class="formgrid grid">
                    <div class="field col">
                        <p-calendar [touchUI]="true" [readonlyInput]="false" inputId="touchui" dateFormat="dd/mm/yy"
                            [maxDate]="hoje" [readonlyInput]="true" id="calendarioPedidos" [(ngModel)]="dataInicial"
                            placeholder="Data Inicial" [style]="{ width: '100%' }" [disabled]="liberaCalendario1"
                            (click)="clickCalendario1()">
                        </p-calendar>
                    </div>
                    <div class="field col">
                        <p-calendar [touchUI]="true" [readonlyInput]="false" inputId="touchui" dateFormat="dd/mm/yy"
                            [readonlyInput]="true" id="calendarioPedidos" [(ngModel)]="dataFinal"
                            [minDate]="dataInicial" placeholder="Data Final" [style]="{ width: '100%' }"
                            [disabled]="liberaCalendario2">
                        </p-calendar>
                    </div>
                </div>
            </div>



            <div *ngIf="!loading" [style]="{width: '100%'}">
                <button pButton type="button" label="Confirmar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                    'border-radius': '11px'}" (click)="buscaVendasPorPeriodo()"></button>
            </div>
            <div *ngIf="loading" [style]="{width: '100%'}">
                <button id="botaoLoadingPedidos" pButton [disabled]="true" type="button" label="Carregando"
                    class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                    'border-radius': '11px'}"></button>
            </div>

        </p-dialog>


        <p-confirmDialog #cd [closable]="false" id="confirmPedidos">
            <ng-template pTemplate="footer">
                <button type="button" pButton icon="pi pi-times" label="Retornar" (click)="cd.reject()"
                    id="confirmBotaoPedidos"></button>
                <button type="button" pButton icon="pi pi-check" label="Confirmar" (click)="cd.accept()"
                    id="confirmBotaoPedidos"></button>
            </ng-template>
        </p-confirmDialog>

    </div>

    <footer id="footerPedidos">
        <div class="flex justify-content-end flex-wrap pt-3">
            <button pButton type="button" label="Novo" class="col-12" id="botaoPedidos" (click)="novaVenda()"></button>
        </div>
    </footer>


    <!--                          DIALOG DO CARREGANDO               -->
    <p-dialog id="dialogCarregandoVendas" [(visible)]="showCarregando" [modal]="true"
        [style]="{'width': '150px', 'height': '140px'}" [closable]="false">
        <div class="flex flex-column align-items-center mt-2">
            <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
                fill="var(--surface-ground)" animationDuration="1s"></p-progressSpinner>
            <div class="fontCarregando">
                <span>Carregando...</span>
            </div>
        </div>
    </p-dialog>
</div>