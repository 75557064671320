import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { AlertService } from 'src/alert.service';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { UtilitariosService } from 'src/assets/utilitarios.service';
import { FinanceiroService } from 'src/financeiro.service';

@Component({
  selector: 'app-tipo-negociacao',
  templateUrl: './tipo-negociacao.component.html',
  styleUrls: ['./tipo-negociacao.component.scss'],
  providers: [MessageService, ConfirmationService],
  encapsulation: ViewEncapsulation.None
})
export class TipoNegociacaoComponent implements OnInit {

  showCadastroTipoNegociacao: boolean = false;
  loading: boolean = false;

  /**MENU */
  itemsNegociacao: MenuItem[] = [];

  /**CODIGOS */
  codusuresp: string = '';
  codcli: number = 0;
  codtipneg: any;
  descr: string = '';
  negpadrao: any;

  negociacao: any;
  negociacaoSelec: any;

  constructor(private financeiroService: FinanceiroService, private decodedTokenService: DecodedTokenService, private confirmationService: ConfirmationService,
    private utilitariosService: UtilitariosService, private alertService: AlertService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.recuperaCodCli();


    this.itemsNegociacao = [{
      items: [{
        label: 'Detalhes',
        command: () => {
          this.preencheDadosDialogTipoNegociacao();
        }
      }
      ]
    },
    {
      items: [{
        label: 'Excluir',
        styleClass: 'linhaMenu',
        command: () => {
          this.confirmaTipoNegociacaoPorCodtipneg();
        }
      }
      ]
    }
    ];
  }
  /**RECUPERA O CODUSU PARA SER USADO NA AUDITORIA DOS METODOS */
  recuperaCodUsu() {
    this.codusuresp = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }

  /**RECUPERA O CODUSU PARA FAZER AÇÕES DOS METODOS */
  recuperaCodCli() {
    this.codcli = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODCLI;
    this.buscaTipoNegociacaoPorCodcli();
  }

  /**ABRE O DIALOG PARA CADASTRO DE UMA NOVA CATEGORIA*/
  mostraDialogTipoNegociacao() {
    this.codtipneg = null;
    this.descr = '';
    this.negpadrao = '';
    this.showCadastroTipoNegociacao = true;
  }


  /**METODOS PARA BUSCAS AS CATEGORIAS DO CLIENTE E MOSTRA-LAS NA TABELA */
  buscaTipoNegociacaoPorCodcli() {
    this.negociacao = [];
    this.financeiroService.buscaTipoNegociacaoPorCodCli(this.codcli, this.codusuresp).subscribe((res) => {
      this.negociacao = res;
    },
      (error) => {
        console.log(error.message)
        console.log('deu ruim')
      })
  }


  /**METODOS PARA PREENCHIMENTO DOS DADOS DA CATEGORIA SELECIONADA EM 'Detalhes' */
  tipoNegociacaoSelecionada(negociacao: any) {
    this.negociacaoSelec = negociacao;
    console.log(this.negociacaoSelec)
  }

  /**METODO QUE PREENCHE O DIALOG DA CATEGORIA APOS CLICAR EM 'Detalhes'*/
  preencheDadosDialogTipoNegociacao() {
    this.codtipneg = this.negociacaoSelec.codtipneg;
    this.descr = this.negociacaoSelec.descr;

    if (this.negociacaoSelec.negpadrao === 'S') {
      this.negpadrao = true;
    } else {
      this.negpadrao = false;
    }

    this.showCadastroTipoNegociacao = true;
  }


  /**METODO DE CRIAÇÃO/EDIÇÃO DA CATEGORIA */
  criaTipoNegociacao() {
    this.loading = true;

    let auxNegPadrao;
    if (this.negpadrao === true) {
      auxNegPadrao = 'S'
    } else {
      auxNegPadrao = null
    }

    /**FAZ VALIDAÇÃO DOS CAMPOS */
    if (this.utilitariosService.validaCampo([this.descr])) {
      console.log('É necessário preencher todos os campos.')
      this.loading = false;
      this.showError('Atenção', 'É necessário preencher todos os campos.')
      return;
    }

    if (this.codtipneg > 0) {
      this.financeiroService.createTipoNegociacaoFinanceira(this.codtipneg, this.descr, this.codcli, auxNegPadrao, this.codusuresp).subscribe((res) => {
        this.alertService.alert('Tipo de negociação alterada!', 'success')
        this.loading = false;
        this.showCadastroTipoNegociacao = false;
        this.buscaTipoNegociacaoPorCodcli();
        console.log(res)
      },
        (error) => {
          console.log('deu ruim')
          this.loading = false;
          this.showCadastroTipoNegociacao = false;
          this.alertService.alert('Algo deu errado.', 'error');
        })
    } else {
      console.log(this.descr)
      console.log(this.codcli)
      this.financeiroService.createTipoNegociacaoFinanceira(null, this.descr, this.codcli, auxNegPadrao, this.codusuresp).subscribe((res) => {
        this.alertService.alert('Tipo de negociação criada!', 'success')
        this.loading = false;
        this.showCadastroTipoNegociacao = false;
        this.buscaTipoNegociacaoPorCodcli();
        console.log(res)
      },
        (error) => {
          console.log('deu ruim')
          this.loading = false;
          this.showCadastroTipoNegociacao = false;
          this.alertService.alert('Algo deu errado.', 'error');
        })
    }
  }

  /**METODOS DE EXCLUSÃO DO TIPO DE NEGOCIAÇÃO */
  excluiTipoNegociacaoPorCodtipneg() {
    this.financeiroService.excluiTipoNegociacao(this.negociacaoSelec.codtipneg!, this.codusuresp).subscribe((res) => {
      console.log(res);
      this.negociacao = this.negociacao.filter((x: any) => x.codtipneg != res.codtipneg); //FILTRA TUDO O QUE É DIFERENTE DO QUE FOI EXCLUIDO

    });
  }

  confirmaTipoNegociacaoPorCodtipneg() {
    this.confirmationService.confirm({
      message: 'Deseja mesmo excluir o Tipo de Negociação: ' + this.negociacaoSelec.descr,
      header: 'Excluir Conta',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.excluiTipoNegociacaoPorCodtipneg();
      },
      reject: () => {
      }
    });
  }


  /**METODO DE TOAST */
  showError(titulo: string, mensagem: string) {
    if (screen.width < 640 || screen.height < 480) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else if (screen.width < 1024 || screen.height < 768) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else {
      this.messageService.add({
        key: 'tr',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    }
  }
}
