<p-toast position="bottom-center" key="bc"></p-toast>
<p-toast position="top-right" key="tr"></p-toast>
<div class="pt-7">

    <div class="flex flex-column  pb-3">
        <div class="flex align-items-center justify-content-center">
            <img src="./assets/iconeCadastrarInformativos.svg" class="iconeCadastrarInformativo" />
        </div>
        <div class=" flex align-items-center justify-content-center">
            <span
                class=" tituloNegritoInformativos maximoCaracter white-space-nowrap overflow-hidden text-overflow-ellipsis">{{informativoTitulo}}</span>
        </div>
    </div>



    <div class="flex justify-content-center flex-wrap ">
        <div class="xl:col-5 lg:col-5 md:col-5 sm:col-5">
            <div class="shadow-4">
                <p-card header="Detalhes da notícia" id="cardInformativo">
                    <div>
                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            (click)="mostraDialogCard()">
                            <div class="flex gap-2 ">
                                <img src="./assets/imgCardInformativo.svg" class="iconeCadastro" class=" xl:w-3rem" />
                                <div class="flex flex-column  align-items-start">
                                    <div class=" fontTituloInformativos">
                                        <span class="xl:text-xl">Card</span>
                                    </div>
                                    <div class="fontCinzaInformativos">
                                        <span class="xl:text-base">Preencher informações do card</span>
                                    </div>
                                </div>
                            </div>
                            <div class="align-items-center justify-content-center">
                                <img src="./assets/imgBarbeariaPopup.png" class="iconePopUp" />
                            </div>

                        </div>

                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            (click)="mostraDialogNoticia()">
                            <div class="flex gap-2 ">
                                <img src="./assets/imgNoticiaInformativo.svg" class="iconeCadastro"
                                    class=" xl:w-3rem" />
                                <div class="flex flex-column  align-items-start">
                                    <div class=" fontTituloInformativos">
                                        <span class="xl:text-xl">Notícia</span>
                                    </div>
                                    <div class="fontCinzaInformativos">
                                        <span class="xl:text-base">Preencher detalhes da notícia</span>
                                    </div>
                                </div>
                            </div>
                            <div class="align-items-center justify-content-center">
                                <img src="./assets/imgBarbeariaPopup.png" class="iconePopUp" />
                            </div>
                        </div>

                        <div class="formgroup-inline justify-content-between flex-wrap "
                            (click)="mostraDialogVigencia()">
                            <div class="flex gap-2 ">
                                <img src="./assets/imgVigenciaInformativo.svg" class="iconeCadastro"
                                    class=" xl:w-3rem" />
                                <div class="flex flex-column align-items-start">
                                    <div class=" fontTituloInformativos">
                                        <span class="xl:text-xl">Vigência</span>
                                    </div>
                                    <div class="fontCinzaInformativos">
                                        <span class="xl:text-base">Preencher o período de vigência da notícia</span>
                                    </div>
                                </div>
                            </div>
                            <div class="align-items-center justify-content-center">
                                <img src="./assets/imgBarbeariaPopup.png" class="iconePopUp" />
                            </div>

                        </div>

                    </div>

                </p-card>

            </div>
        </div>
    </div>
    <br>


    <!--                          DIALOG DO CARD               -->
    <p-dialog header="Card" [(visible)]="showCard" [modal]="true" [style]="{'width': '350px', 'height': '590px'}"
        [closable]="true" id="dialogInformativo">

        <div class="col-12">
            <div class="fontDialogInformativos flex align-items-center justify-content-center ">
                <span>Informações do card que estará no home</span>
            </div>
        </div>


        <div *ngIf="teveRespostaEstabelecimento">
            <p-button id="botaoSpinnerInformativo" label="Estabelecimento" [disabled]="true" class="p-button-text">
                <i class="pi pi-spin pi-spinner teste flex-order-1" style="font-size: 1rem"></i>
            </p-button>
        </div>

        <div *ngIf="!teveRespostaEstabelecimento">
            <p-dropdown id="dropEstabelecimento" [options]="estabelecimento" [(ngModel)]="estabelecimentoSelecionada"
                placeholder="Estabelecimento" optionLabel="nome" optionValue="codemp" [style]="{'width': '100%'}"
                scrollHeight="130px">
            </p-dropdown>
        </div>




        <input type="text" [(ngModel)]="titulo" pInputText placeholder="Titulo" [style]="{width: '100%'}" class="mt-1"
            (ngModelChange)="mudaTituloSubTitulo()">

        <input type="text" [(ngModel)]="subtitulo" pInputText placeholder="Subtítulo" [style]="{width: '100%'}"
            class="mt-1" (ngModelChange)="mudaTituloSubTitulo()">


        <!-- <div class="mt-1 ">
            <div class="flex align-items-center justify-content-start labelImagemInformativo">
                <label for="">
                    <img src="./assets/upload.png" class="ajustaInputImagemInformativo pl-1" />
                </label>
                <div class="fontUploadImagem flex align-items-center justify-content-center  ">
                    <label for="botaoImg" class=""> Escolha uma imagem</label>
                </div>
            </div>
        </div> -->
        <input #fileInput accept="image/png, image/gif, image/jpeg" type="file" name="botaoImg" id="botaoImg"
            (change)="mudaNomeFoto(); previewImagem()" />
        <div class="mt-1 ">
            <div class="flex align-items-center justify-content-start labelImagemInformativos">
                <label for="">
                    <img src="./assets/upload.svg" class="ajustaInputImagemInformativos pl-2" />
                </label>
                <div class="fontUploadImagem flex align-items-center justify-content-center pl-2 ">
                    <label for="botaoImg" class="">{{nomeImagem}}</label>
                </div>
            </div>
        </div>

        <div class="col-12">
            <div class="fontDialogInformativos flex align-items-center justify-content-center ">
                <span>Veja como ficará:</span>
            </div>
            <p-card id="cardNoticiaInformativo" class=" flex align-items-center justify-content-center pt-1">
                <img [src]="imagem" id="imgPhoto" name="preview" class="imagemNoticiaInformativo " />
                <div class="col  flex flex-column">
                    <div class="pb-2 maximoCaracterTitulo white-space-nowrap overflow-hidden text-overflow-ellipsis">
                        <span class="fonteNoticiasTitulo">{{tituloPadrao}}</span>
                    </div>
                    <span
                        class="fonteDescNoticias maximoCaracterSubtitulo white-space-normal overflow-hidden text-overflow-ellipsis">
                        {{subTituloPadrao}} </span>
                    <div class="pt-3">
                        <button pButton type="button" label="Saiba Mais" class="col-12"
                            id="botaoNoticiaInformativo"></button>
                    </div>

                </div>
                <div class="col fonteDescNoticias ">

                    <div class="">

                    </div>
                </div>


            </p-card>
        </div>

        <div *ngIf="!loading" [style]="{width: '100%'}">
            <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                'border-radius': '11px'}" (click)="criaInformativo()"></button>
        </div>
        <div *ngIf="loading" [style]="{width: '100%'}">
            <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 mt-3"
                [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                'border-radius': '11px'}"></button>
        </div>


    </p-dialog>


    <!--                          DIALOG DA NOTICIA               -->
    <p-dialog header="Notícia" [(visible)]="showNoticia" [modal]="true" [style]="{'width': '350px', 'height': '438px'}"
        [closable]="true" id="dialogInformativo">
        <div class="flex flex-column align-items-center">
            <div class="col-12">
                <div class="fontDialogInformativos flex align-items-center justify-content-center ">
                    <span>Noticia que aparecerá quando clicar em “saiba mais”</span>
                </div>
            </div>
            <p-editor [(ngModel)]="noticia" [style]="{'height':'200px'}"></p-editor>
            <div *ngIf="!loading" [style]="{width: '100%'}">
                <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                'border-radius': '11px'}" (click)="criaNoticia()"></button>
            </div>
            <div *ngIf="loading" [style]="{width: '100%'}">
                <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 mt-3"
                    [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                'border-radius': '11px'}"></button>
            </div>
        </div>
    </p-dialog>


    <!--                          DIALOG DE VIGENCIA               -->
    <p-dialog header="Vigência" [(visible)]="showVigencia" [modal]="true"
        [style]="{'width': '350px', 'height': '260px'}" [closable]="true" id="dialogInformativo">
        <div class="flex flex-column sm:flex-row  align-items-center">
            <div class="col-12">
                <div class="fontDialogInformativos flex align-items-center justify-content-center ">
                    <span>Informe o período que a notícia estará vigente</span>
                </div>
            </div>
        </div>

        <div class="">
            <p-calendar [touchUI]="true" [readonlyInput]="false" inputId="touchui" dateFormat="dd/mm/yy"
                [minDate]="ontem" [required]="true" [readonlyInput]="true" id="calendarioInformativos"
                [(ngModel)]="dataInicial" placeholder="Data Inicial">
            </p-calendar>
            <div class="mt-1">
                <p-calendar [touchUI]="true" [readonlyInput]="false" inputId="touchui" dateFormat="dd/mm/yy"
                    [minDate]="dataInicial" [required]="true" [readonlyInput]="true" id="calendarioInformativos"
                    [(ngModel)]="dataFinal" placeholder="Data Final">
                </p-calendar>
            </div>

        </div>

        <div *ngIf="!loading" [style]="{width: '100%'}">
            <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
            'border-radius': '11px'}" (click)="criaDataVigencia()"></button>
        </div>
        <div *ngIf="loading" [style]="{width: '100%'}">
            <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 mt-3"
                [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
            'border-radius': '11px'}"></button>
        </div>
    </p-dialog>


</div>