import { AlertService } from './../alert.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { UtilitariosService } from 'src/assets/utilitarios.service';
import { LoginService } from 'src/login/login.service';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.scss'],
  providers: [MessageService],
  encapsulation: ViewEncapsulation.None
})
export class CadastroComponent implements OnInit {
  nome: string = '';
  dataNasc: string = '';
  email: string = '';
  telefone: string = '';
  senha: string = '';
  emailRepetido: any;
  msgErro: string = '';
  loading: boolean = false;

  constructor(private loginService: LoginService, private router: Router, private messageService: MessageService,
    private utilitariosService: UtilitariosService, private alertService: AlertService) {
  }

  ngOnInit(): void {
    // this.verificaStatus();

  }

  criarUsuario() {
    //   this.dataNasc = this.dataNasc.replace(/\//g, "-");

    /**AJUSTA A DATA PARA O FORMATO ESPERADO PELO BANCO */
    const partesData = this.dataNasc.split("/"); // Dividindo a string em três partes: dia, mês e ano
    const data = new Date(Number(partesData[2]), Number(partesData[1]) - 1, Number(partesData[0])); // Construindo o objeto Date
    const novaData = `${data.getMonth() + 1}/${data.getDate()}/${data.getFullYear()}`; // Construindo a nova string formatada
    let dtNasc: any = new Date(novaData);
    dtNasc = this.utilitariosService.ajustaDataBanco(dtNasc);




    if (this.utilitariosService.validaCampo([this.nome, this.dataNasc, this.email, this.telefone, this.senha])) {
      this.showError('Atenção', 'É necessário preencher todos os campos.')
      return;
    }

    if (this.utilitariosService.validaDataNasc(this.dataNasc)) {
      this.showError('Atenção', 'É necessário informar uma data válida.')
      return;
    }

    if (!this.utilitariosService.validaEmail(this.email)) {
      this.showError('Atenção', 'É necessário informar uma e-mail válido.')
      return;
    }
    this.loading = true;

    //var user = { nome: this.nome, email: this.email, telefone: this.telefone, datanasc: this.dataNasc, senha: this.senha };
    //console.log(user)

    this.nome = this.nome.trimStart();
    this.loginService.createUser(this.nome, this.email, this.telefone, dtNasc, this.senha, 0, null, environment.CODTIPSEG).subscribe((res) => {
      this.loading = false;
      console.log(res)
      this.alertService.alert('Sua conta foi criada!', 'success');
      setTimeout(() => {
        this.router.navigate(['/login']);
      }, 1500)
    }
      ,
      (error) => {
        console.log(error.error.message)
        if (error.error.message != undefined) {
          this.showError('Atenção', 'E-mail já cadastrado.')
        } else {
          this.showError('Atenção', 'Algo deu errado.')
        }
        this.loading = false;
        console.log(error.message)
        console.log('deu ruim')

      }
    );
    // if (!this.credInvalida) {
    //   // this.alertService.success('Cadastro realizado com Sucesso!')
    //   // this.router.navigate(['/login']);
    // } else {
    //   this.showError('Atenção', 'E-mail já cadastrado.')
    //   return;
    // }



  }

  // verificaStatus() {
  //   this.loginService.status$.subscribe((data) => {
  //     // this.msgErro = Object(data).error.message;
  //    // this.msgErro = 'E-mail já cadastrado.'
  //    this.showError('Atenção', 'E-mail já cadastrado.')
  //     this.emailRepetido = true;
  //     console.log(this.msgErro)
  //   });
  // }

  /**METODO DE TOAST */
  showError(titulo: string, mensagem: string) {
    if (screen.width < 640 || screen.height < 480) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else if (screen.width < 1024 || screen.height < 768) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else {
      this.messageService.add({
        key: 'tr',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    }
  }
}
