import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { Pesquisa } from 'src/assets/pesquisa';
import { PermissoesService } from 'src/permissoes.service';
import { PesquisaService } from 'src/pesquisa.service';

@Component({
  selector: 'app-cadastrar-pesquisa',
  templateUrl: './cadastrar-pesquisa.component.html',
  styleUrls: ['./cadastrar-pesquisa.component.scss'],
  providers: [ConfirmationService, MessageService],
  encapsulation: ViewEncapsulation.None
})
export class CadastrarPesquisaComponent implements OnInit {

  pesquisa: Pesquisa[] = [];
  itemsPesquisas: MenuItem[] = [];

  /* variaveis do componente */
  codusuresp: string = '';
  codcli: number = 0;
  pesquisaSelec: Pesquisa = {};
  nupes: number = 0;

  /**VARIAVEIS DE PERMISSÕES */
  permissao: any;
  alterar: boolean = false;
  excluir: boolean = false;
  cadastrar: boolean = false


  constructor(private pesquisaService: PesquisaService, private decodedTokenService: DecodedTokenService, private confirmationService: ConfirmationService,
    private router: Router, private permissoesService: PermissoesService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.recuperaCodUsu();
    this.recuperaCodCli();
    this.recuperaPermissao();
    this.buscaPesquisaPorCodCli();


    this.itemsPesquisas = [{
      items: [{
        label: 'Detalhes',
        command: () => {
          this.editarPesquisa(this.pesquisaSelec)
        },
        // routerLink: ['/pesquisa'],
      }
      ]
    },
    {
      items: [{
        label: 'Excluir',
        styleClass: 'linhaMenu',
        command: () => {
          // console.log(this.barbeariaSelec.codemp);
          this.confirmaExclusaoPesquisa();
        }
      }
      ]
    }
    ];
  }

  buscaPesquisaPorCodCli() {
    /**VERIFICA SE VAI FAZER A BUSCA PELO CODCLI DO TOKEN OU DA PERMISSÃO */
    if (this.permissao != undefined && this.permissao.length > 0) {
      this.pesquisaService.buscarPesquisaPorCodCli(this.permissao[0].codcli, this.codusuresp).subscribe((res) => {
        this.pesquisa = res;
      });
    } else {
      this.pesquisaService.buscarPesquisaPorCodCli(this.codcli, this.codusuresp).subscribe((res) => {
        this.pesquisa = res;
      });
    }
  }

  recuperaCodCli() {
    this.codcli = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODCLI;
  }

  recuperaCodUsu() {
    this.codusuresp = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }

  excluiPesquisaPorCodEmp() {
    this.pesquisaService.excluiPesquisaPorNupes(this.pesquisaSelec.nupes!, this.codusuresp).subscribe((res) => {
      console.log(res.nupes);
      this.pesquisa = this.pesquisa.filter((x: any) => x.nupes != res.nupes); //FILTRA TUDO O QUE É DIFERENTE DO QUE FOI EXCLUIDO
      console.log(this.pesquisa)
    });
  }

  pesquisaSelecionada(pesquisa: Pesquisa) {
    console.log('estou no botão')
    this.pesquisaSelec = pesquisa;
    console.log(pesquisa);
  }


  editarPesquisa(pesquisa: Pesquisa) {
    /**VERIFICA SEM TEM PERMISSÃO PARA ESSA AÇÃO */
    if (this.alterar || this.codcli > 0) {
      this.pesquisaService.setPesquisa(pesquisa);
      this.router.navigate([('/pesquisa')]);
    } else {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
  }

  cadastrarPesquisa() {
    /**VERIFICA SEM TEM PERMISSÃO PARA ESSA AÇÃO */
    if (this.cadastrar || this.codcli > 0) {
      var auxPesquisa: Pesquisa = {};
      this.pesquisaService.setPesquisa(auxPesquisa);
      this.router.navigate([('/pesquisa')]);
    } else {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
  }
  confirmaExclusaoPesquisa() {
    /**VERIFICA SEM TEM PERMISSÃO PARA ESSA AÇÃO */
    if (this.excluir || this.codcli > 0) {
      this.confirmationService.confirm({
        message: 'Deseja mesmo excluir ' + this.pesquisaSelec.nome + ' ?',
        header: 'Excluir Barbearia',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.excluiPesquisaPorCodEmp();
        },
        reject: () => {
        }
      });
    } else {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
  }



  /**RECUPERA AS PERMISSÕES */
  recuperaPermissao() {
    this.permissao = this.permissoesService.getPermissao();
    console.log(this.permissao)
    if (this.permissao != undefined && this.permissao.length > 0) {
      if (this.permissao[5].acao[0].PERMITE === 'S') {
        this.cadastrar = true;
      }
      if (this.permissao[5].acao[1].PERMITE === 'S') {
        this.alterar = true;
      }
      if (this.permissao[5].acao[2].PERMITE === 'S') {
        this.excluir = true;
      }
    }
    console.log(this.cadastrar, this.alterar, this.excluir)
  }


  /**METODO DE TOAST */
  showError(titulo: string, mensagem: string) {
    if (screen.width < 640 || screen.height < 480) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else if (screen.width < 1024 || screen.height < 768) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else {
      this.messageService.add({
        key: 'tr',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    }
  }

}
