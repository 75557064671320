import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-faturas',
  templateUrl: './faturas.component.html',
  styleUrls: ['./faturas.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FaturasComponent implements OnInit {
  faturas: any[]

  itemsFatura: MenuItem[] = [];
  showFatura: boolean = true;
  loading: boolean = false;

  constructor() {
    this.faturas = [{ 'id': 42853, 'dtFatura': '06/02/2023', 'vencimento': '11/02/2023', 'valor': 135, 'situacao': 'A' },
                   { 'id': 42621, 'dtFatura': '06/01/2023', 'vencimento': '11/01/2023', 'valor': 135, 'situacao': 'F' }]
  }

  ngOnInit(): void {
    this.itemsFatura = [{
      items: [{
        label: 'Detalhes',
        command: () => {
          this.mostraDialogFatura();
        },
      }
      ]
    }]   
  }

  mostraDialogFatura(){
    this.showFatura = true;
  }

 
}
