<body id="AlterSenha">
    <div class="flex flex-column align-items-center  redimensionamentoSenhaDeslog ">
        <div class="col-12 sm-12 md-4 xl:col-5 lg:col-5 md:col-5 sm:col-5">
            <div class="flex align-items-center justify-content-center">
                <div class="flex align-items-center justify-content-center">

                    <img src="./assets/logo.svg" />
                </div>
            </div>
            <div class="">
                <div *ngIf="teveErro ">
                    <p-messages severity="error" id="erroDeslog">
                        <ng-template pTemplate>
                            <div class="ml-2" class="erroCodRec">{{msgErro}}</div>
                        </ng-template>
                    </p-messages>
                </div>
                <div class="col-12 flex align-items-center justify-content-center divPersonAlterSenha">
                    <input id="inputAltPass" class="col-12" type="password" pInputText placeholder="Nova Senha"
                        [(ngModel)]="newPassword1" />
                </div>
                <div class="col-12 flex align-items-center justify-content-center divPersonAlterSenha">
                    <input id="inputAltPass" class="col-12" type="password" pInputText
                        placeholder="Confirmar Nova Senha" [(ngModel)]="newPassword2" />
                </div>


                <div class="col-12 pt-2">
                    <div class=" grid p-flup-2 flex align-items-center justify-content-center">
                        <div class="field col  gap-2">
                            <button id="botaoAlterarPass" pButton type="button" label="Cancelar" class="col-12"
                                routerLink="/login"></button>
                        </div>
                        <div class="field col">
                            <div *ngIf="!loading" [style]="{width: '100%'}">
                                <button id="botaoAlterarPass" pButton type="button" label="Confirmar" class="col-12"
                                    (click)=" alterarSenha()"></button>
                            </div>
                            <div *ngIf="loading" [style]="{width: '100%'}">
                                <button id="botaoLoadingAltPass" [disabled]="true" pButton type="button"
                                    label="" class="col-12"></button>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</body>