import { LoginService } from 'src/login/login.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Estabelecimento } from 'src/assets/estabelecimento';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { Servico } from 'src/assets/servico';
import { EstabelecimentoService } from 'src/estabelecimento.service';
import { ServicoService } from 'src/servico.service';
import { UsuarioService } from 'src/usuario.service';
import { Router } from '@angular/router';
import { AgendaService } from 'src/agenda.service';
import { AlertService } from 'src/alert.service';
import { PlanoService } from 'src/plano.service';

@Component({
  selector: 'app-filiar-estabelecimento',
  templateUrl: './filiar-estabelecimento.component.html',
  styleUrls: ['./filiar-estabelecimento.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FiliarEstabelecimentoComponent implements OnInit {
  estabelecimento: Estabelecimento = {}
  servico: Servico[] = [];

  /**VARIAVEIS DAS ABAS DE PLANOS */
  selecionouServicos: boolean = true;
  selecionouPlanos: boolean = false;
  escondePlanos: boolean = false;  //VARIAVEL CRIADA PARA ESCONDER A ABA DE PLANOS ENQUANTO NÃO TEMOS AS REQUISIÇÕES PRONTAS


  /* variaveis do componente */
  // codemp: string = '';
  numero: any;
  cidade: any;
  bairro: any;
  complemento: any;
  logradouro: any;
  contato: string = '';
  nomeEstabelecimento: string = '';
  codusu: string = '';
  codemp: string = '';
  estabelecimentoImagem: string = '';
  teveResposta: boolean = false;
  filiado: boolean = false;
  cabecalhoVazio = {};
  planos: any[] = [];

  servicosPlano: any[] = [];
  plano: any[] = [];

  codusuresp: string = '';
  codcli: number = 0;
  codempBarbearia: number = 0;

  constructor(private estabelecimentoService: EstabelecimentoService, private decodedTokenService: DecodedTokenService, private agendaService: AgendaService,
    private servicoService: ServicoService, private loginService: LoginService, private usuarioService: UsuarioService, private router: Router,
    private alertService: AlertService, private planoService: PlanoService) { }

  ngOnInit(): void {
    this.recuperaCodUsu();
    this.buscarEstabelecimento();
    this.buscarServicoPorCodEmpresa();
    this.recuperaCodEmp();
    // this.buscarPlanosPorCodCliente();
  }

  recuperaCodEmp() {
    this.usuarioService.getPerfil(this.codusu, this.codusu).subscribe((res) => {
      this.teveResposta = true;
      this.codemp = Object(res)[0].codemp;
      if (this.estabelecimento.codemp != this.codemp) {
        this.filiado = false;
      } else {
        this.filiado = true;
      }
      console.log(Object(res)[0].codfunc)
    });
  }

  recuperaCodUsu() {
    this.codusu = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }


  recuperaDados() {
    //this.codemp = this.estabelecimento.codemp!;
    this.nomeEstabelecimento = this.estabelecimento.nome!;
    this.complemento = this.estabelecimento.complemento!;
    this.numero = this.estabelecimento.numero!;
    this.bairro = this.estabelecimento.setor!;
    this.logradouro = this.estabelecimento.rua!;
    this.cidade = this.estabelecimento.cidade!;
    this.contato = this.estabelecimento.contato!;
    this.codempBarbearia = Number(this.estabelecimento.codemp!);
    // this.codcli = this.estabelecimento.codcli!;
  }

  buscarEstabelecimento() {
    this.estabelecimento = this.estabelecimentoService.getestabelecimento();
    this.listaPlanos();
  }

  buscarServicoPorCodEmpresa() {
    console.log(this.estabelecimento.codemp)
    this.servicoService.buscarServicoporCodEmp(Number(this.estabelecimento.codemp), this.codusu).subscribe((res) => {
      console.log(res);
      this.servico = res;

      for (let i = 0; i < this.servico.length; i++) {
        this.servico[i].minutos = this.tempoEmMinutos(this.servico[i].tempom);
      }

    })
  }

  tempoEmMinutos(tempo: any) {
    console.log(tempo)
    const [horas, minutos] = tempo.split(':').map(Number);
    const totalMinutos = (horas * 60) + minutos;
    return totalMinutos;
  }

  filiarEmpresa() {
    this.loginService.filiarEmp(this.estabelecimento.codemp, this.codusu).subscribe((res) => {
      console.log(res);
      // var user = { codusu: Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU }; //REQUISIÇÃO NO getPerfil para recuperar o codemp e saber se é filiar ou desfiliar
      this.usuarioService.getPerfil(this.codusu, this.codusu).subscribe((res) => {
        this.codemp = Object(res)[0].codemp;

        if (Number(this.codemp) != 0) {
          this.agendaService.setCabAgenda(this.cabecalhoVazio);
          this.router.navigate(['/agendar']);
        }
        console.log(res)

        if (this.estabelecimento.codemp != this.codemp) {
          this.filiado = false;
        } else {
          this.filiado = true;
        }
      });

    },
      (error) => {
        if (error.error.message != undefined) {
          console.log(error.error.message)
          const erro = error.error.message
          const testeRegex = erro.match(/(?:[^,]*, ){2}([^,]*)/)
          console.log(testeRegex)
          // this.showBaixa = false;
          this.alertService.alert(testeRegex[1], 'error')
        } else {
          // this.showBaixa = false;
          // this.loading = false;
          this.alertService.alert('Algo deu errado.', 'error')
        }

      })

  }

  /**METODOS QUE CONTROLA A SELEÇÃO DA ABA (SERVIÇOS OU PLANOS) */
  clickServicos() {
    this.selecionouServicos = true;
    this.selecionouPlanos = false;
  }

  clickPlanos() {
    this.selecionouServicos = false;
    this.selecionouPlanos = true;
  }


  /**METODO QUE BUSCA OS PLANOS DA BARBEARIA */
  buscarPlanosPorCodCliente() {
    this.planos = [];
    //if (this.permissao != undefined && this.permissao.length > 0) {
    // this.planoService.buscaPlanoPorCodCli(this.permissao[0].codcli, this.codusuresp).subscribe((res) => {
    //   console.log(res);
    //   this.planos = res;
    // });
    // } else {
    this.planoService.buscaPlanoPorCodCli(3, this.codusuresp).subscribe((res) => {
      console.log(res);
      this.planos = res;
    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
      });
    // }
  }

  /**METODO QUE TRAZ TODOS OS SERVIÇOS DOS PLANOS DA BARBEARIA */
  listaServicosPlano() {
    console.log('qogf0giwgiwerg')
    console.log(this.estabelecimento.codemp)

    this.planoService.listaServicosPlanoPorCodemp(Number(this.estabelecimento.codemp)).subscribe((res) => {
      console.log(res);
      this.servicosPlano = res;


      /**SOMA OS MINUTOS DO PLANO */
      for (let i = 0; i < this.servicosPlano.length; i++) {
        this.servicosPlano[i].minutos = this.tempoEmMinutos(this.servicosPlano[i].tempom);
      }
      for (let i = 0; i < this.plano.length; i++) {
        let auxMinutos = 0;
        for (let j = 0; j < this.servicosPlano.length; j++) {
          if (this.servicosPlano[j].nupla === this.plano[i].nupla) {
            auxMinutos += this.servicosPlano[j].minutos;
          }
        }
        this.plano[i].minutos = auxMinutos;
      }


    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
      });
  }


  /**METODO QUE TRAZ TODOS OS PLANOS DA BARBEARIA */
  listaPlanos() {
    this.planoService.listaPlanoPorCodemp(Number(this.estabelecimento.codemp), this.codusu).subscribe((res) => {
      console.log(res);
      this.plano = res;

      this.listaServicosPlano();
    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
      });
  }

  expandeInfoPlano(plano: any) {
    console.log(plano)
    plano.expande = true;
    console.log(plano)
  }

  guardaInfoPlano(plano: any) {
    console.log(plano)
    plano.expande = false;
  }

}
