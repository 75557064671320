import { Injectable } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core'; //TRADUÇÃO

@Injectable({
  providedIn: 'root'
})
export class TraducaoService {
  lang: string = "en";

  constructor(public primeNGConfig: PrimeNGConfig, public translate: TranslateService) {
    let traducao = {
      "dayNamesMin": [
        "Dom",
        "Seg",
        "Ter",
        "Qua",
        "Qui",
        "Sex",
        "Sab"
      ],
      "monthNames": [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro"
      ],
      "monthNamesShort": [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez"
      ],
      "emptyMessage": "Dados não encontrados",
      "emptyFilterMessage": "Nenhum resultado encontrado",
      "passwordPrompt": "Insira uma senha",
      "weak": "Senha fraca",
      "medium": "Senha media",
      "strong": "Senha forte",
      "choose": "Escolher",
      "upload": "Upload",
      "cancel": "Cancelar",
      "empty": "Erro de busca"
    }
    this.primeNGConfig.setTranslation(traducao);
  }

  changeLang() {
    this.translate.use('pt');
  }
}
