import { Injectable } from '@angular/core';
import { Produto } from './assets/produto';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './environments/environment';
import { Observable, first, tap } from 'rxjs';
import { LoginService } from './login/login.service';

@Injectable({
  providedIn: 'root'
})
export class ProdutoService {
  produto: Produto = {};

  constructor(private http: HttpClient, private loginService: LoginService) { }

  buscarProdutosPorCodCli(codcli: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const cliente = { "codcli": codcli, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/produtos/getprodsporcodcli2`, cliente, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  preencherSaldoProduto(codprod: number, saldo: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const produto = { "codprod": codprod, "saldo": saldo, "codusuresp": codusuresp }

    return this.http
      .put<any>(`${environment.BASE_URL}/produtos/createsaldoproduto2`, produto, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  preencherProduto(codprod: number, nomeprod: string, descrprod: string, codcli: number, preco: number, comissao: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const produto = {
      "codprod": codprod, "nomeprod": nomeprod, "descrprod": descrprod, "codcli": codcli,
      "preco": preco, "comissao": comissao, "codusuresp": codusuresp
    }

    return this.http
      .post<any>(`${environment.BASE_URL}/produtos/createprod2`, produto, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  public setProduto(produto: Produto) { //SETA O PRODUTO NO VETOR
    this.produto = produto;
  }

  public getProduto() { //RECUPERA O CONTEUDO DO PRODUTO PARA VISUALIZAÇÃO
    return this.produto;
  }

}
