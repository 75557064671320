<body id="home">

    <header>
        <div class="col ">
            <div class="formgroup-inline justify-content-between flex-wrap pt-1">
                <div class="flex justify-content-start flex-wrap cursor-pointer">
                    <img alt="logo" src="./assets/botaoMenu.svg" (click)="visibleSidebar1 = true" />



                </div>
                <div class="flex justify-content-end flex-wrap gap-2">
                    <div (click)="op.toggle($event)">
                        <span class="qtdNotificacoes" *ngIf="qtdNot > 0"> {{qtdNot}}</span>
                        <img src="./assets/iconeSino.svg" class="iconeSino  cursor-pointer" />
                    </div>
                    <div>

                        <img src="./assets/iconeAvatar.svg" (click)="visibleSidebar2 = true" class=" cursor-pointer" />
                    </div>
                </div>

            </div>
        </div>
    </header>

    <!------------------------------------------------ NOTIFICAÇÕES --------------------------------------------------->
    <p-overlayPanel #op [style]="{width: '250px', top: '30px'}" [showTransitionOptions]="'250ms'"
        [hideTransitionOptions]="'100ms'">
        <div *ngIf="notificacoes.length === 0" class="flex flex-column align-items-center">
            <span class="fontTituloNot">Sem notificações.</span>
        </div>
        <div *ngIf="notificacoes.length > 0">
            <div *ngFor="let notificacoes of notificacoes" (click)="escolheuNotificacao(notificacoes); op.hide()"
                [ngStyle]="notificacoes.lido === 'N'?{'background-color': 'var(--cor18)'} : {'background-color': 'none'}">
                <div>
                    <span
                        class="fontTituloNot maximoCaracterNotTitulo white-space-normal overflow-hidden text-overflow-ellipsis">{{notificacoes.titulo}}</span>
                </div>
                <div>
                    <span
                        class="fontMsgNotificacao maximoCaracterNotInfo white-space-normal overflow-hidden text-overflow-ellipsis">{{notificacoes.mensagem}}</span>
                </div>
                <div class="linhahorizontalNot"></div>
            </div>
        </div>
    </p-overlayPanel>

    <!------------------------------------------------ SAUDAÇÕES --------------------------------------------------->
    <div *ngIf="exibeHome" class="pt-7 layoutHome ">
        <div class=" flex justify-content-center  ">
            <div class="flex flex-column align-items-center">
                <div>
                    <span class="fonteSaudacao ">Olá,&nbsp;</span>



                    <span class="fonteSaudacaoParc"> {{nomeparc}}</span>

                </div>
                <div class="linhahorizontalHome fonteSaudacaoMsg flex justify-content-center">
                    <span>que tal dar um toque no visual?</span>
                </div>
            </div>
        </div>

        <!-- ------------------------------------------------------------------------------------------------------- -->
        &nbsp;
        <div class="scroll-divHome">

            <!-- --------------------------------------------MENU PC------------------------------------------------------ -->
            <div *ngIf="resPc">
                <div class="flex justify-content-center flex-wrap pt-3">
                    <div class="xl:col-5">
                        <div class="flex justify-content-center flex-wrap">
                            <div class="shadow-4">
                                <p-card id="cardHome">
                                    <div class="flex justify-content-evenly flex-wrap ">
                                        <div class="flex flex-column align-items-center justify-content-center cursor-pointer"
                                            (click)="clickEstabelecimento()">
                                            <div>

                                                <img src="./assets/barbeariaAdm.svg" class="iconeHome" />
                                            </div>
                                            <div class="fontMarronIcones  ">
                                                <span>Barbearias</span>
                                            </div>
                                        </div>
                                        <div class="flex flex-column  align-items-center justify-content-center cursor-pointer"
                                            (click)="clickAgendar()">
                                            <div>
                                                <img src="./assets/iconeAgendarHome.svg" class="iconeHome" />
                                            </div>
                                            <div class="fontMarronIcones ">
                                                <span>Agendar</span>
                                            </div>
                                        </div>

                                        <div class="flex flex-column cursor-pointer" (click)="clickMinhaAgenda() ">
                                            <div>

                                                <img src="./assets/iconeMAgendaHome.svg" class="iconeHome" />
                                            </div>
                                            <div
                                                class="fontMarronIcones flex align-items-center justify-content-center ">
                                                <span>Minha Agenda</span>
                                            </div>

                                        </div>
                                        <div class="flex flex-column align-items-center justify-content-center cursor-pointer"
                                            (click)="clickContato()">
                                            <div>
                                                <img src="./assets/iconeContatoHome.svg" class="iconeHome" />
                                                <span
                                                    class="fontMarronIcones flex justify-content-center flex-wrap">Contato</span>
                                            </div>
                                        </div>
                                        <div class="flex flex-column cursor-pointer" (click)="clickMieuPerfil()">
                                            <div>
                                                <img src="./assets/iconeMPerfilHome.svg" class="iconeHome" />
                                                <span class="fontMarronIcones flex justify-content-center flex-wrap">Meu
                                                    Perfil</span>
                                            </div>
                                        </div>
                                    </div>
                                </p-card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- --------------------------------------------MENU MOBILE------------------------------------------------------ -->
            <div *ngIf="resMobile">
                <div class="flex justify-content-center flex-wrap pt-3">
                    <div class="shadow-4">
                        <p-card id="cardHome">
                            <div class="flex justify-content-between flex-wrap ">
                                <div class="flex flex-column align-items-center justify-content-center"
                                    (click)="clickEstabelecimento()">
                                    <div>
                                        <img src="./assets/barbeariaAdm.svg" class="iconeHome" />
                                    </div>
                                    <div class="fontMarronIcones  ">
                                        <span>Barbearias</span>
                                    </div>
                                </div>

                                <div class="flex flex-column  align-items-center justify-content-center"
                                    (click)="clickAgendar()">
                                    <div>
                                        <img src="./assets/iconeAgendarHome.svg" class="iconeHome" />
                                    </div>
                                    <div class="fontMarronIcones ">
                                        <span>Agendar</span>
                                    </div>
                                </div>

                                <div class="flex flex-column " (click)="clickMinhaAgenda()">
                                    <div class=" arrumaMA ">

                                        <img src="./assets/iconeMAgendaHome.svg" class="iconeHome" />
                                    </div>
                                    <div class="fontMarronIcones ">
                                        <span>Minha Agenda</span>
                                    </div>
                                </div>
                                <div class="flex align-content-center flex-wrap column-gap-5 ">
                                    <div class="flex flex-column align-items-center justify-content-center"
                                        (click)="clickContato()">
                                        <div>
                                            <img src="./assets/iconeContatoHome.svg" class="iconeHome" />
                                            <span
                                                class="fontMarronIcones flex justify-content-center flex-wrap">Contato</span>
                                        </div>
                                    </div>
                                    <div class="flex flex-column " (click)="clickMieuPerfil()">
                                        <div>
                                            <img src="./assets/iconeMPerfilHome.svg" class="iconeHome" />
                                            <span class="fontMarronIcones flex justify-content-center flex-wrap">Meu
                                                Perfil</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </p-card>
                    </div>

                </div>
            </div>


            <!-- --------------------------------------------INFORMATIVO MOBILE------------------------------------------------------ -->
            <div *ngIf="resMobile">

                <div class="flex justify-content-center flex-wrap pt-3 pb-1 fonteNoticias">
                    <span>Descubra Mais</span>
                </div>
                <div class=" ">
                    <div class="linhahorizontal2Home pb-2"></div>
                    <div class="">




                        <owl-carousel-o [options]="carouselOptionsMobile">
                            <ng-container *ngFor="let informativo of informativo">

                                <ng-template carouselSlide>


                                    <div class="leftCards">

                                        <p-card id="cardNoticia">
                                            <img [src]="informativo.filename" class="imagemNoticia " />
                                            <div class="col  flex flex-column sombra">
                                                <div
                                                    class="pb-2 maximoCaracterTituloHome white-space-nowrap overflow-hidden text-overflow-ellipsis">
                                                    <span class="fonteNoticiasTitulo  ">{{informativo.titulo}}</span>
                                                </div>
                                                <div class="">

                                                    <span
                                                        class="fonteDescNoticias maximoCaracterSubtituloHome white-space-normal overflow-hidden text-overflow-ellipsis ">
                                                        {{informativo.subtitulo}} </span>
                                                </div>
                                                <div class="pt-3">
                                                    <button pButton type="button" label="Saiba Mais" class="col-12"
                                                        id="botaoNoticia"
                                                        (click)="escolheuInformativo(informativo)"></button>
                                                </div>
                                                <br>
                                            </div>
                                        </p-card>
                                    </div>
                                    <br>
                                </ng-template>

                            </ng-container>
                        </owl-carousel-o>


                        <!-- <ng-container *ngFor="let informativo of informativo">

                                <p-card id="cardNoticia" class="">
                                    <img [src]="informativo.filename" class="imagemNoticia " />
                                    <div class="col  flex flex-column sombra">
                                        <div
                                            class="pb-2 maximoCaracterTituloHome white-space-nowrap overflow-hidden text-overflow-ellipsis">
                                            <span class="fonteNoticiasTitulo  ">{{informativo.titulo}}</span>
                                        </div>
                                        <div class="">

                                            <span
                                                class="fonteDescNoticias maximoCaracterSubtituloHome white-space-normal overflow-hidden text-overflow-ellipsis ">
                                                {{informativo.subtitulo}} </span>
                                        </div>
                                        <div class="pt-3">
                                            <button pButton type="button" label="Saiba Mais" class="col-12"
                                                id="botaoNoticia" (click)="escolheuInformativo(informativo)"></button>
                                        </div>
                                        <br>
                                    </div>
                                    <div class="col fonteDescNoticias ">
                                    </div>
                                </p-card>

                            </ng-container> -->

                    </div>
                </div>

            </div>

            <!-- --------------------------------------------INFORMATIVO PC------------------------------------------------------ -->
            <div *ngIf="resPc" class=" pt-3">
                <div class="flex justify-content-center flex-wrap">
                    <div class="xl:col-5">
                        <div class="flex justify-content-center flex-wrap">

                            <div class="linhahorizontal2Home "></div>
                        </div>
                        <div class="flex justify-content-center flex-wrap pt-1 pb-3 fonteNoticias">
                            <span>Descubra Mais</span>
                        </div>

                        <div class="pt-2   ">
                            <owl-carousel-o [options]="carouselOptionsPc">
                                <ng-container *ngFor="let informativo of informativo">

                                    <ng-template carouselSlide>

                                        <div class="flex justify-content-center flex-wrap">
                                            <p-card id="cardNoticia" class="">
                                                <img [src]="informativo.filename" class="imagemNoticia " />
                                                <div class="col  flex flex-column ">
                                                    <div
                                                        class="pb-2 maximoCaracterTituloHome white-space-nowrap overflow-hidden text-overflow-ellipsis pl-6">
                                                        <span
                                                            class="fonteNoticiasTitulo  xl:text-2xl">{{informativo.titulo}}</span>
                                                    </div>
                                                    <div class="containerInformativo">
                                                        <span
                                                            class="pl-6 fonteDescNoticias maximoCaracterSubtituloHome white-space-normal overflow-hidden text-overflow-ellipsis xl:text-base text-justify">
                                                            {{informativo.subtitulo}} </span>
                                                    </div>
                                                    <div class="pt-3 flex justify-content-center flex-wrap">
                                                        <button pButton type="button" label="Saiba Mais" class="col-12"
                                                            id="botaoNoticia"
                                                            (click)="escolheuInformativo(informativo)"></button>
                                                    </div>
                                                    <br>
                                                </div>
                                            </p-card>
                                        </div>
                                        <br>
                                    </ng-template>

                                </ng-container>
                            </owl-carousel-o>
                        </div>

                    </div>
                </div>
            </div>



            <!-- --------------------------------------------CONVIDE SEUS AMIGOS PC------------------------------------------------------ -->
            <div *ngIf="resPc">
                <div class="flex justify-content-center flex-wrap">
                    <div class="col-5">

                        <div class="flex justify-content-center flex-wrap">
                            <div class="linhahorizontal2Home "></div>
                        </div>
                        <div class="flex justify-content-center flex-wrap pt-1 pb-3 fonteNoticias">
                            <span>Convide seus amigos</span>
                        </div>
                        <div class="flex justify-content-center flex-wrap ">

                            <p-card id="cardIndicacao" class="shadow-4">
                                <div class="col">
                                    <div class="flex ">
                                        <div class="esconde">
                                            <img src="./assets/imgIndiqueAmigos.svg">
                                        </div>
                                        <div
                                            class="col  flex flex-column sombra flex align-items-center justify-content-center ">
                                            <span class="fontIndicacao  text-base "> Indique o Barber Express a todos
                                                seus amigos!</span>
                                            <div class="pt-3 flex  justify-content-center  flex-wrap ">
                                                <button pButton type="button" label="Convidar" class="col-12"
                                                    id="botaoConvidar"></button>
                                            </div>
                                        </div>

                                        <div class="flex justify-content-end">
                                            <img src="./assets/imgIndiqueAmigos.svg">
                                        </div>
                                    </div>
                                </div>
                            </p-card>

                        </div>
                    </div>
                </div>
            </div>

            <!-- --------------------------------------------CONVIDE SEUS AMIGOS MOBILE------------------------------------------------------ -->
            <div *ngIf="resMobile">
                <div class="flex justify-content-center flex-wrap pt-3 pb-1 fonteNoticias">
                    <span>Convide seus amigos</span>
                </div>
                <div class="linhahorizontal2Home pb-2"></div>
                <div class="flex justify-content-center flex-wrap ">

                    <p-card id="cardIndicacao" class="shadow-4">
                        <div class="col">

                            <div class="flex justify-content-end flex-wrap">

                                <div class="col  flex flex-column sombra">
                                    <span class="fontIndicacao"> Indique o Barber Express a todos seus amigos!</span>
                                    <div class="pt-3">
                                        <button pButton type="button" label="Convidar" class="col-12"
                                            id="botaoNoticia"></button>
                                    </div>
                                </div>
                                <div class="flex align-items-center justify-content-center">
                                    <img src="./assets/imgIndiqueAmigos.svg">
                                </div>
                            </div>
                        </div>
                    </p-card>

                </div>
            </div>
        </div>
    </div>


    <!--                          DIALOG DE PERGUNTAS               -->
    <p-dialog [(visible)]="showPesquisa" [modal]="true" [closable]="false" [style]="{'width': '343px'}"
        id="dialogPerguntasHome" [draggable]="false">
        <!-- <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;900&display=swap" rel="stylesheet"> -->
        <ng-template pTemplate="header">

            <div class="flex flex-column centralizaHeader">
                <div class="flex align-items-center justify-content-center">
                    <img src="./assets/iconePesquisa.svg" />
                </div>
                <div class=" flex align-items-center justify-content-center">
                    <span
                        class=" fontHeaderPergunta maximoCaracter white-space-nowrap overflow-hidden text-overflow-ellipsis">
                        Pesquisa</span>
                </div>
            </div>
            <div class="posicaoX" (click)="fechaDialogPesquisa()">
                <img src="./assets/x.svg" />
            </div>

        </ng-template>

        <div class="flex flex-column   align-items-center  ">
            <div class="line-height-1">
                <div class="fontDialogPesquisa flex align-items-center justify-content-center ">
                    <span>Sua opinião vale muito pra gente ;) </span>
                </div>
                <div class="fontDialogPesquisa flex align-items-center justify-content-center ">
                    <span>Através da sua resposta, podemos melhorar ainda mais!</span>
                </div>
            </div>
        </div>

        <div *ngIf="pergunta.length > 0">

            <!-- ESTRELINHA -->
            <div *ngIf="pergunta[0].tiporesp === 'E'">
                <div class="flex align-items-center justify-content-center pt-3 ">
                    <div class="containerPesquisaHome line-height-1">

                        <span class="fontePergunta">{{pergunta[0].pergunta}}
                            <!-- <span class="fontePergunta font-bold">{{nomeemp}}</span>? -->
                        </span>
                    </div>

                </div>
                <div class="flex align-items-center justify-content-center pt-4">
                    <p-rating [(ngModel)]="respostaRate" [cancel]="false"></p-rating>
                </div>

                <div *ngIf="pergunta.length > 1">
                    <button pButton type="button" label="Continuar" class="col-12 mt-5" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                    'border-radius': '11px'}" (click)="proximaPergunta()"></button>
                </div>

                <div *ngIf="pergunta.length === 1">
                    <div *ngIf="!loading" [style]="{width: '100%'}">
                        <button pButton type="button" label="Enviar" class="col-12 mt-5" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                        'border-radius': '11px'}" (click)="enviaResposta()"></button>
                    </div>
                    <div *ngIf="loading" [style]="{width: '100%'}">
                        <button id="botaoLoading" pButton [disabled]="true" type="button" label="Enviando"
                            class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                                'border-radius': '11px'}"></button>
                    </div>
                </div>

            </div>

            <!-- TEXTO -->
            <div *ngIf="pergunta[0].tiporesp === 'T'">
                <div class="flex align-items-center justify-content-center pt-3 ">
                    <div class="containerPesquisaHome line-height-1">
                        <div class="pb-1">
                            <span class="fontePergunta">{{pergunta[0].pergunta}}</span>
                        </div>
                    </div>
                </div>
                <textarea pInputTextarea placeholder="Resposta" [(ngModel)]="resposta" [autoResize]="true"
                    maxlength="150" class="expandeTextArea" (ngModelChange)=" contaCaracter()"></textarea>
                <div class="flex justify-content-end flex-wrap fontContadorCaracter">{{caracteresRestantes}}/150
                </div>
                <div *ngIf="pergunta.length > 1">
                    <div *ngIf="!loading" [style]="{width: '100%'}">
                        <button pButton type="button" label="Continuar" class="col-12 mt-5" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                    'border-radius': '11px'}" (click)="proximaPergunta()"></button>
                    </div>
                </div>

                <div *ngIf="pergunta.length === 1">
                    <div *ngIf="!loading" [style]="{width: '100%'}">
                        <button pButton type="button" label="Enviar" class="col-12 mt-5" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                        'border-radius': '11px'}" (click)="enviaResposta()"></button>
                    </div>
                    <div *ngIf="loading" [style]="{width: '100%'}">
                        <button id="botaoLoading" pButton [disabled]="true" type="button" label="Enviando"
                            class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                                'border-radius': '11px'}"></button>
                    </div>
                </div>
            </div>

            <!-- Nº Inteiro-->
            <div *ngIf="pergunta[0].tiporesp === 'I'">
                <div class="flex align-items-center justify-content-center pt-3 ">
                    <div class="containerPesquisaHome line-height-1">
                        <div class="pb-1">
                            <span class="fontePergunta">{{pergunta[0].pergunta}}</span>
                        </div>
                    </div>
                </div>
                <div class="flex align-items-center justify-content-center pt-4">
                    <p-inputNumber [(ngModel)]="resposta" mode="decimal" inputId="withoutgrouping"
                        [useGrouping]="false">
                    </p-inputNumber>
                </div>
                <div *ngIf="pergunta.length > 1">
                    <button pButton type="button" label="Continuar" class="col-12 mt-5" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                    'border-radius': '11px'}" (click)="proximaPergunta()"></button>
                </div>

                <div *ngIf="pergunta.length === 1">
                    <div *ngIf="!loading" [style]="{width: '100%'}">
                        <button pButton type="button" label="Enviar" class="col-12 mt-5" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                        'border-radius': '11px'}" (click)="enviaResposta()"></button>
                    </div>
                    <div *ngIf="loading" [style]="{width: '100%'}">
                        <button id="botaoLoading" pButton [disabled]="true" type="button" label="Enviando"
                            class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                                'border-radius': '11px'}"></button>
                    </div>
                </div>
            </div>

            <!-- Nº Decimal-->
            <div *ngIf="pergunta[0].tiporesp === 'D'">
                <div class="flex align-items-center justify-content-center pt-3 ">
                    <div class="containerPesquisaHome line-height-1">
                        <div class="pb-1">
                            <span class="fontePergunta">{{pergunta[0].pergunta}}</span>
                        </div>
                    </div>
                </div>
                <div class="flex align-items-center justify-content-center pt-4">
                    <p-inputNumber [(ngModel)]="resposta" mode="decimal" inputId="withoutgrouping" [useGrouping]="false"
                        [minFractionDigits]="1">
                    </p-inputNumber>
                </div>
                <div *ngIf="pergunta.length > 1">
                    <button pButton type="button" label="Continuar" class="col-12 mt-5" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                    'border-radius': '11px'}" (click)="proximaPergunta()"></button>
                </div>

                <div *ngIf="pergunta.length === 1">
                    <div *ngIf="!loading" [style]="{width: '100%'}">
                        <button pButton type="button" label="Enviar" class="col-12 mt-5" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                        'border-radius': '11px'}" (click)="enviaResposta()"></button>
                    </div>
                    <div *ngIf="loading" [style]="{width: '100%'}">
                        <button id="botaoLoading" pButton [disabled]="true" type="button" label="Enviando"
                            class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                                'border-radius': '11px'}"></button>
                    </div>
                </div>
            </div>
        </div>


    </p-dialog>




    <!--                          DIALOG DO CARREGANDO               -->
    <p-dialog id="dialogCarregandoHome" [(visible)]="mostraCarregando" [modal]="true"
        [style]="{'width': '150px', 'height': '140px'}" [closable]="false">
        <div class="flex flex-column align-items-center mt-2">
            <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
                fill="var(--surface-ground)" animationDuration="1s"></p-progressSpinner>
            <div class="fontCarregando">
                <span>Carregando...</span>
            </div>
        </div>
    </p-dialog>









    <p-sidebar [(visible)]="visibleSidebar1" [baseZIndex]="10000" [showCloseIcon]="false"
        [style]="{ 'background-color': 'var(--cor5)' }">
        <p-panelMenu [model]="itemsHome" [style]="{ 'background-color': 'transparent', width: '12em'}">
        </p-panelMenu>
    </p-sidebar>

    <p-sidebar [(visible)]="visibleSidebar2" position="right" [baseZIndex]="10000" [showCloseIcon]="false"
        [style]="{ 'background-color': 'var(--cor5)', 'top': '95px', 'height': '11rem', 'width': '50%'}">
        <p-panelMenu [model]="menuPerfil"
            [style]="{ 'background-color': 'transparent', width: '10em', 'height': '20%' }"></p-panelMenu>
    </p-sidebar>


    <router-outlet></router-outlet>




</body>