<body>

    <div *ngIf="!resMobile">
        <div id="inicio">
            <section id="pc1">
                <header>
                    <img src="./assets/logo.svg" alt="" class="logo">
                    <nav class="navegation">
                        <ul>
                            <li><a [routerLink]="['/site']" fragment="inicio">Início</a></li>
                            <li><a [routerLink]="['/site']" fragment="funcionalidades">Funcionalidades</a></li>
                            <li><a [routerLink]="['/site']" fragment="planos">Planos</a></li>
                            <li><a [routerLink]="['/site']" fragment="duvidas">Dúvidas</a></li>
                        </ul>
                    </nav>
                    <a href="https://www.appbarberexpress.com/" id="areaCliente"
                        class="flex align-items-center justify-content-center gap-2"> <img
                            src="./assets/iconAreaCliente.svg">Área do Cliente</a>

                </header>
                <div class="boxImg">
                    <img src="./assets/landing1.svg" alt="" class="img1Pc">
                </div>
                <div class="content">

                    <div class="text">
                        <h2>Aplicativo de gestão de barbearias
                            <br>
                            <span>Com o agendamento
                                de serviços, seu
                                cliente se sente
                                mais valorizado!</span>
                            <p>Já imaginou alavancar seu negócio entranto para a era digital?
                                Com o Barber Express isso é possível!</p>
                        </h2>
                        <a href="
                        https://api.whatsapp.com/send?phone=556493352776&text=Ol%C3%A1,%20quero%20saber%20mais%20sobre%20o%20Barber%20Express!"
                            class="flex align-items-center justify-content-center gap-2"> <img
                                src="./assets/wppTeste.svg">Teste Agora Mesmo</a>
                    </div>
                </div>
            </section>
        </div>
        <div id="funcionalidades">
            <section id="pc2">
                <!-- <div class="boxImg2">
                    <img src="./assets/landing2.svg" alt="" class="img2Pc">
                </div>
                <div class="content">

                    <div class="text">
                        <h2>Aplicativo de gestão de barbearias
                            <br>
                            <span>Facilite o
                                Agendamento de
                                horários dos seus
                                Serviços</span>
                            <p>Já imaginou alavancar seu negócio entranto para a era digital?
                                Com o Barber Express isso é possível!</p>
                        </h2>
                    </div>
                </div> -->
                <div class="boxImg2">
                    <img src="./assets/landing2.svg" alt="" class="img2Pc">
                </div>
                <div class="content2">

                    <div class="text">
                        <h2>Aplicativo de gestão de barbearias
                            <br>
                            <span>Nosso sistema é a solução para as seus problemas administrativos</span>
                            <!--  <p>Já imaginou alavancar seu negócio entranto para a era digital?
                                Com o Barber Express isso é possível!</p> -->
                        </h2>
                        <div class="pt-7">
                            <span class="fontLista">O </span> <span class="fontLista font-bold">Barber Express</span>
                            <span class="fontLista"> conta com: </span>

                        </div>
                        <ul>
                            <li class="fontLista">Agendamento online;</li>
                            <li class="fontLista">Envio de notificações;</li>
                            <li class="fontLista">Fechamento de comissão;</li>
                            <li class="fontLista">Descontos especiais;</li>
                            <li class="fontLista">Pesquisas de satisfação;</li>
                            <li class="fontLista">Mural de informações;</li>
                            <li class="fontLista">Métricas</li>
                            <li class="fontLista">E Muito mais!</li>
                        </ul>

                        <div class="fontLista pt-7">
                            <span>Entre em contato conosco para saber mais! </span>
                            <br>
                            <span>Teste grátis sem compromisso.</span>
                        </div>
                    </div>


                </div>


            </section>
        </div>

        <!-- CARDS DE PREÇOS PLANOS -->
        <div id="planos">
            <section class="sectionPlanos">
                <div class="flex justify-content-center flex-wrap pb-2 pt-6">
                    <div class="flex align-items-center justify-content-center tamanhoTexto">
                        <span class="conteudoPc"> CONHEÇA NOSSOS PLANOS</span>
                    </div>
                </div>

                <!-- <div class="card flex justify-content-center pt-3 pb-3">
                    <p-selectButton [options]="opcoesPlanos" [(ngModel)]="planoSelecionado" optionLabel="nome"
                        optionValue="plano" (onChange)="mudaValorPlanos()"></p-selectButton>
                </div> -->

                <div class="form-box">
                    <div class="button-box">
                        <div id="btn"></div>
                        <button type="button" class="toggle-btn" (click)="mensal()">Mensal</button>
                        <button type="button" class="toggle-btn" (click)="semestral()">Semestral</button>
                    </div>
                </div>

                <div class="pricing">
                    <div class="card-wrapper">
                        <div class="card-header">
                            <h2>
                                Plano Starter
                            </h2>
                        </div>

                        <div class="card-detail">
                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                <!-- <b>1</b> -->
                                Acesso completo
                            </p>
                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                <b> 1</b>
                                empresa
                            </p>
                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                <b> 1</b>
                                perfil de profissional
                            </p>
                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                Notificações via whatsapp
                            </p>

                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                Clientes ilimitados
                            </p>

                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                Cadastro de serviços/produtos ilimitados
                            </p>

                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                Acesso a relatórios e métricas
                            </p>

                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                Ativação rápida
                            </p>
                            
                            

                        </div>
                        <!-- *ngIf="planoSelecionado==='S'" -->


                        <div *ngIf="planoSelecionado==='S'" class="posicaoValorDesconto gap-2">
                            <span class="line-through valorAntigo">{{valorPlano1SemDesconto}}</span>
                            <span class="economize">Economize 5%</span>
                        </div>

                        <div class="card-price">

                            <p> <sup>R$</sup>{{valorPlano1Reais}}<sub>{{valorPlano1Centavos}}/mês</sub></p>
                        </div>
                        <a [href]=msgPlano1 class="no-underline"><button class="card-button"> Quero Testar Por 15
                                Dias!</button> </a>


                    </div>


                    <div class="card-wrapper">
                        <div class="card-popular">
                            <h2>
                                Mais popular
                            </h2>
                        </div>
                        <div class="card-header">
                            <h2>
                                Plano Top
                            </h2>
                        </div>

                        <div class="card-detail">
                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                <!-- <b>1</b> -->
                                Acesso completo
                            </p>
                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                <b> 1</b>
                                empresa
                            </p>
                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                <b> 2</b>
                                perfis de profissional
                            </p>
                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                Notificações via whatsapp
                            </p>

                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                Clientes ilimitados
                            </p>

                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                Cadastro de serviços/produtos ilimitados
                            </p>

                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                Acesso a relatórios e métricas
                            </p>

                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                Ativação rápida
                            </p>
                            
                            

                        </div>

                        <div *ngIf="planoSelecionado==='S'" class="posicaoValorDesconto gap-2">
                            <span class="line-through valorAntigo">{{valorPlano2SemDesconto}}</span>
                            <span class="economize">Economize 5%</span>
                        </div>
                        <div class="card-price">
                            <p> <sup>R$</sup>{{valorPlano2Reais}}<sub>{{valorPlano2Centavos}}/mês</sub></p>
                        </div>

                        <a [href]=msgPlano2 class="no-underline"><button class="card-button"> Quero Testar Por 15
                            Dias!</button> </a>
                    </div>

                    <div class="card-wrapper">

                        <div class="card-header">
                            <h2>
                                Plano Master
                            </h2>
                        </div>

                        <div class="card-detail">
                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                <!-- <b>1</b> -->
                                Acesso completo
                            </p>
                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                <b> 2</b>
                                empresas
                            </p>
                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                <b> 5</b>
                                perfis de profissional
                            </p>
                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                Notificações via whatsapp
                            </p>

                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                Clientes ilimitados
                            </p>

                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                Cadastro de serviços/produtos ilimitados
                            </p>

                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                Acesso a relatórios e métricas
                            </p>

                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                Ativação rápida
                            </p>
                            
                            

                        </div>

                        <div *ngIf="planoSelecionado==='S'" class="posicaoValorDesconto gap-2">
                            <span class="line-through valorAntigo">{{valorPlano3SemDesconto}}</span>
                            <span class="economize">Economize 5%</span>
                        </div>
                        <div class="card-price">
                            <p> <sup>R$</sup>{{valorPlano3Reais}}<sub>{{valorPlano3Centavos}}/mês</sub></p>
                        </div>

                        <a [href]=msgPlano3 class="no-underline"><button class="card-button"> Quero Testar Por 15
                            Dias!</button> </a>
                    </div>
                </div>

                <div class="flex justify-content-center flex-wrap pb-2">
                    <div class="flex align-items-center justify-content-center">
                        <span class="outroPlano">*Caso esteja buscando um plano diferente dos apresentados, entre em
                            contato conosco.</span>
                    </div>
                </div>

            </section>
        </div>



        <div class="rodapePc1" id="duvidas">
            <div class="flex flex-column">



                <div class="flex justify-content-center flex-wrap pb-2 pt-2">
                    <div class="flex align-items-center justify-content-center tamanhoTexto">
                        <span class="conteudoPc">Ficou com alguma dúvida?</span>
                    </div>
                </div>
                <div class="flex justify-content-center flex-wrap pb-2">
                    <div class="flex align-items-center justify-content-center tamanhoTexto">
                        <span class="descricaoPc">Entre em contato conosco</span>
                    </div>
                </div>
                <div class="botao2 flex justify-content-center flex-wrap ">
                    <div class="flex align-items-center justify-content-center">
                        <a href="
                        https://api.whatsapp.com/send?phone=556493352776&text=Ol%C3%A1,%20quero%20saber%20mais%20sobre%20o%20Barber%20Express!"
                            class="flex align-items-center justify-content-center gap-2"> <img
                                src="./assets/wppTeste.svg" class="">WhatsApp</a>
                    </div>
                </div>
            </div>
        </div>


        <div class="rodapePc2">
            <div class="flex justify-content-center flex-wrap pb-2 pt-2">
                <div class="flex align-items-center justify-content-center tamanhoTexto">
                    <img src="./assets/logo.svg" alt="" class="logoMobile">
                </div>
            </div>
            <div class="flex justify-content-center flex-wrap pb-2">
                <div class="flex align-items-center justify-content-center tamanhoTexto">
                    <span class="rodape1">©2023. Barber Express - Aplicativo de Gestão Barber Shop.</span>
                </div>
            </div>

            <div class="flex justify-content-center flex-wrap pb-2">
                <div class="flex align-items-center justify-content-center tamanhoTexto">
                    <span class="rodape2">Barber Express® é uma marca registrada.</span>
                </div>
            </div>

        </div>




    </div>
    <div *ngIf="resMobile">
        <input type="checkbox" id="toggle">

        <!-- <nav>

            <a class="navbar-brand">
                <img src="./assets/logo.svg" alt="" class="logoMobile">
            </a>

            <label class="navbar-toggler" for="toggle">
                <span class="bar"></span>
                <span class="bar"></span>
                <span class="bar"></span>
            </label>

            <ul class="nav-list">
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/site']" fragment="inicio" (click)=" clicou()">Início</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/site']" fragment="funcionalidades">Funcionalidades</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link">Demonstração</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link">Área do Cliente</a>
                </li>
            </ul>

        </nav> -->



        <section class="top-nav">
            <div class="pt-2">
                <img src="./assets/logo.svg" alt="" class="logoMobile">

            </div>
            <input id="menu-toggle" type="checkbox" />
            <label class='menu-button-container' for="menu-toggle">
                <div class='menu-button'></div>
            </label>
            <ul class="menu">
                <li> <a [routerLink]="['/site']" fragment="inicio" id="menuSanduiche">Início</a>
                </li>
                <li><a [routerLink]="['/site']" fragment="funcionalidades" id="menuSanduiche">Funcionalidades</a>
                </li>
                <li><a id="menuSanduiche" [routerLink]="['/site']" fragment="planos">Planos</a></li>
                <li><a id="menuSanduiche" [routerLink]="['/site']" fragment="duvidas">Dúvidas</a></li>
                <li> <a id="menuSanduiche" [routerLink]="['/login']">Área do Cliente</a></li>
            </ul>
        </section>


        <div id="inicio">
            <section id="mobile">
                <div class="flex justify-content-center flex-wrap">
                    <div class="flex align-items-center justify-content-center">

                        <img src="./assets/landing1Mobile.svg" alt="" class="img1">
                    </div>
                </div>
                <div class="flex justify-content-center flex-wrap pb-2">
                    <div class="flex align-items-center justify-content-center">
                        <span class="titulo">Aplicativo de gestão de barbearias</span>
                    </div>

                </div>
                <div class="flex justify-content-center flex-wrap pb-2">
                    <div class="flex align-items-center justify-content-center tamanhoTexto">
                        <span class="conteudo">Facilite o
                            Agendamento de
                            horários dos seus
                            Serviços</span>
                    </div>
                </div>

                <div class="flex justify-content-center flex-wrap pb-2">
                    <div class="flex align-items-center justify-content-center tamanhoTexto">
                        <span class="descricao">Já imaginou alavancar seu negócio entranto para a era digital?
                            Com o Barber Express isso é possível!</span>
                    </div>
                </div>

                <div class="botao1 flex justify-content-center flex-wrap">
                    <div class="flex align-items-center justify-content-center">

                        <a href="
                        https://api.whatsapp.com/send?phone=556493352776&text=Ol%C3%A1,%20quero%20saber%20mais%20sobre%20o%20Barber%20Express!"
                            class="flex align-items-center justify-content-center gap-2"> <img
                                src="./assets/wppTeste.svg" class="teste">Teste Agora Mesmo</a>
                    </div>
                </div>

            </section>
        </div>

        <div id="funcionalidades">
            <section id="mobile2">
                <div class="flex justify-content-center flex-wrap">
                    <div class="flex align-items-center justify-content-center">

                        <img src="./assets/landing2.svg" alt="" class="img1">
                    </div>
                </div>
                <div class="flex justify-content-center flex-wrap pb-2">
                    <div class="flex align-items-center justify-content-center">
                        <span class="titulo">Aplicativo de gestão de barbearias</span>
                    </div>

                </div>
                <div class="flex justify-content-center flex-wrap pb-2">
                    <div class="flex align-items-center justify-content-center tamanhoTexto">
                        <span class="conteudo">Nosso sistema é a solução para as seus problemas administrativos</span>
                    </div>
                </div>
                <div class=" pt-2">
                    <span class="fontListaMobile">O </span> <span class="fontListaMobile font-bold">Barber
                        Express</span> <span class="fontListaMobile"> conta com: </span>

                </div>
                <ul>
                    <li class="fontListaMobile text-left">Agendamento online;</li>
                    <li class="fontListaMobile text-left">Envio de notificações;</li>
                    <li class="fontListaMobile text-left">Fechamento de comissão;</li>
                    <li class="fontListaMobile text-left">Descontos especiais;</li>
                    <li class="fontListaMobile text-left">Pesquisas de satisfação;</li>
                    <li class="fontListaMobile text-left">Mural de informações;</li>
                    <li class="fontListaMobile text-left">Métricas</li>
                    <li class="fontListaMobile text-left">E Muito mais!</li>
                </ul>
                <div class="fontListaMobile  pt-3">
                    <span>Entre em contato conosco para saber mais! </span>
                    <br>
                    <span>Teste grátis sem compromisso.</span>
                </div>

            </section>
        </div>

        <!-- CARDS DE PREÇOS PLANOS -->
        <div id="planos">
            <section class="sectionPlanos">
                <div class="flex justify-content-center flex-wrap pb-2 pt-6">
                    <div class="flex align-items-center justify-content-center tamanhoTexto">
                        <span class="conteudo"> CONHEÇA NOSSOS PLANOS</span>
                    </div>
                </div>

                <!-- <div class="card flex justify-content-center pt-3 pb-3">
                    <p-selectButton [options]="opcoesPlanos" [(ngModel)]="planoSelecionado" optionLabel="nome"
                        optionValue="plano" (onChange)="mudaValorPlanos()"></p-selectButton>
                </div> -->
                <div class="form-box">
                    <div class="button-box">
                        <div id="btn"></div>
                        <button type="button" class="toggle-btn" (click)="mensal()">Mensal</button>
                        <button type="button" class="toggle-btn" (click)="semestral()">Semestral</button>
                    </div>
                </div>
                
                <div class="pricing">
                    <div class="card-wrapper">
                        <div class="card-header">
                            <h2>
                                Plano Starter
                            </h2>
                        </div>

                        <div class="card-detail">
                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                <!-- <b>1</b> -->
                                Acesso completo
                            </p>
                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                <b> 1</b>
                                empresa
                            </p>
                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                <b> 1</b>
                                perfil de profissional
                            </p>
                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                Notificações via whatsapp
                            </p>

                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                Clientes ilimitados
                            </p>

                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                Cadastro de serviços/produtos ilimitados
                            </p>

                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                Acesso a relatórios e métricas
                            </p>

                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                Ativação rápida
                            </p>
                            
                            

                        </div>
                        <!-- *ngIf="planoSelecionado==='S'" -->


                        <div *ngIf="planoSelecionado==='S'" class="posicaoValorDesconto gap-2">
                            <span class="line-through valorAntigo">{{valorPlano1SemDesconto}}</span>
                            <span class="economize">Economize 5%</span>
                        </div>

                        <div class="card-price">

                            <p> <sup>R$</sup>{{valorPlano1Reais}}<sub>{{valorPlano1Centavos}}/mês</sub></p>
                        </div>

                        <a [href]=msgPlano1 class="no-underline"><button class="card-button"> Quero Testar Por 15
                            Dias!</button> </a>
                    </div>


                    <div class="card-wrapper">
                        <div class="card-popular">
                            <h2>
                                Mais popular
                            </h2>
                        </div>
                        <div class="card-header">
                            <h2>
                                Plano Top
                            </h2>
                        </div>

                        <div class="card-detail">
                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                <!-- <b>1</b> -->
                                Acesso completo
                            </p>
                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                <b> 1</b>
                                empresa
                            </p>
                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                <b> 2</b>
                                perfis de profissional
                            </p>
                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                Notificações via whatsapp
                            </p>

                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                Clientes ilimitados
                            </p>

                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                Cadastro de serviços/produtos ilimitados
                            </p>

                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                Acesso a relatórios e métricas
                            </p>

                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                Ativação rápida
                            </p>
                            
                            

                        </div>

                        <div *ngIf="planoSelecionado==='S'" class="posicaoValorDesconto gap-2">
                            <span class="line-through valorAntigo">{{valorPlano2SemDesconto}}</span>
                            <span class="economize">Economize 5%</span>
                        </div>
                        <div class="card-price">
                            <p> <sup>R$</sup>{{valorPlano2Reais}}<sub>{{valorPlano2Centavos}}/mês</sub></p>
                        </div>

                        <a [href]=msgPlano2 class="no-underline"><button class="card-button"> Quero Testar Por 15
                            Dias!</button> </a>
                    </div>

                    <div class="card-wrapper">

                        <div class="card-header">
                            <h2>
                                Plano Master
                            </h2>
                        </div>

                        <div class="card-detail">
                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                <!-- <b>1</b> -->
                                Acesso completo
                            </p>
                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                <b> 2</b>
                                empresas
                            </p>
                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                <b> 5</b>
                                perfis de profissional
                            </p>
                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                Notificações via whatsapp
                            </p>

                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                Clientes ilimitados
                            </p>

                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                Cadastro de serviços/produtos ilimitados
                            </p>

                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                Acesso a relatórios e métricas
                            </p>

                            <p>
                                <span>
                                    <i class="pi pi-check check"></i>
                                </span>
                                Ativação rápida
                            </p>
                            
                            
                            

                        </div>

                        <div *ngIf="planoSelecionado==='S'" class="posicaoValorDesconto gap-2">
                            <span class="line-through valorAntigo">{{valorPlano3SemDesconto}}</span>
                            <span class="economize">Economize 5%</span>
                        </div>
                        <div class="card-price">
                            <p> <sup>R$</sup>{{valorPlano3Reais}}<sub>{{valorPlano3Centavos}}/mês</sub></p>
                        </div>

                        <a [href]=msgPlano3 class="no-underline"><button class="card-button"> Quero Testar Por 15
                            Dias!</button> </a>
                    </div>
                </div>


                <div class="flex justify-content-center flex-wrap pb-2">
                    <div class="flex align-items-center justify-content-center">
                        <span class="outroPlano">*Caso esteja buscando um plano diferente dos apresentados, entre em
                            contato conosco.</span>
                    </div>
                </div>

            </section>
        </div>

        <section id="duvidas">
            <div class="flex justify-content-center flex-wrap pb-2 pt-2">
                <div class="flex align-items-center justify-content-center tamanhoTexto">
                    <span class="conteudo">Ficou com alguma dúvida?</span>
                </div>
            </div>
            <div class="flex justify-content-center flex-wrap pb-2">
                <div class="flex align-items-center justify-content-center tamanhoTexto">
                    <span class="descricao">Entre em contato conosco</span>
                </div>
            </div>
            <div class="botao2 flex justify-content-center flex-wrap">
                <div class="flex align-items-center justify-content-center">

                    <a href="
                    https://api.whatsapp.com/send?phone=556493352776&text=Ol%C3%A1,%20quero%20saber%20mais%20sobre%20o%20Barber%20Express!"
                        class="flex align-items-center justify-content-center gap-2"> <img src="./assets/wppTeste.svg"
                            class="">WhatsApp</a>
                </div>
            </div>
        </section>

        <section id="mobile4">
            <div class="flex justify-content-center flex-wrap pb-2 pt-2">
                <div class="flex align-items-center justify-content-center tamanhoTexto">
                    <img src="./assets/logo.svg" alt="" class="logoMobile">
                </div>
            </div>
            <div class="flex justify-content-center flex-wrap pb-2">
                <div class="flex align-items-center justify-content-center tamanhoTexto">
                    <span class="rodape1">©2023. Barber Express - Aplicativo de Gestão Barber Shop.</span>
                </div>
            </div>

            <div class="flex justify-content-center flex-wrap pb-2">
                <div class="flex align-items-center justify-content-center tamanhoTexto">
                    <span class="rodape2">Barber Express® é uma marca registrada.</span>
                </div>
            </div>

        </section>


    </div>

</body>