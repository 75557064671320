import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AlertService } from 'src/alert.service';
import { Estabelecimento } from 'src/assets/estabelecimento';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { EstabelecimentoService } from 'src/estabelecimento.service';
import { environment } from 'src/environments/environment';
import { UsuarioService } from 'src/usuario.service';

@Component({
  selector: 'app-menu-administrativo',
  templateUrl: './menu-administrativo.component.html',
  styleUrls: ['./menu-administrativo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MenuAdministrativoComponent implements OnInit {
  codUsu: string = '';
  nome: string = '\xa0\xa0\xa0\xa0\xa0\xa0\xa0';
  codcli: number = 0;
  exibeNome: string = '\xa0\xa0\xa0\xa0\xa0\xa0\xa0';

  itemsAdmin: MenuItem[] = [];
  estabelecimento: Estabelecimento[] = [];
  imagemEstabelecimento: string = '';
  mostraCarregando: boolean = true;
  show: boolean = true;
  faturamento: string = '';
  mostraFaturamento: string = '';

  escondePlanos: boolean = true;

  constructor(private decodedTokenService: DecodedTokenService, private usuarioService: UsuarioService, private estabelecimentoService: EstabelecimentoService,
    private alertService: AlertService) { }

  ngOnInit(): void {
    this.recuperaCodCli();
    this.recuperaCodUsu();
    this.buscarEstabelecimentosPorCodCliente();
    // this.mostraFaturamento = this.faturamento;
  }


  recuperaCodCli() {
    this.codcli = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODCLI;
  }

  recuperaCodUsu() {
    this.codUsu = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }

  clickShow() {
    this.mostraFaturamento = '';
    if (this.show === true) {
      this.show = false;
      for (let i = 0; i < this.faturamento.length; i++) {
        this.mostraFaturamento += '•';
      }
    } else {
      this.show = true;
      this.mostraFaturamento = Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(Number(this.faturamento))
    }
    console.log(this.show + ' ↑')
  }

  buscaDadosPerfil(codUsu: string): void {
    this.usuarioService.getPerfil(codUsu, codUsu).subscribe((res) => {
      this.mostraCarregando = false;
      this.nome = 'Olá, ' + Object(res)[0].nomeparc.split(' ')[0] + '!';
    },
      (error) => {
        console.log(error.message)
        console.log('deu ruim')
        this.mostraCarregando = false;
        this.alertService.alert('Algo deu errado.', 'error');
        //this.showError('Atenção', 'Algo deu errado.')
      });
  }

  buscarEstabelecimentosPorCodCliente() {

    this.estabelecimentoService.buscarEstabelecimentosporCodCli(this.codcli, this.codUsu).subscribe((res) => {
      this.buscaDadosPerfil(this.codUsu);
      this.estabelecimento = res;
      this.imagemEstabelecimento = environment.imgEmpresa + this.estabelecimento[0].filename!
      this.exibeNome = this.estabelecimento[0].nome!
      let x = [];
      for (let i = 0; i < this.estabelecimento.length; i++) {
        x.push({
          items: [{
            label: this.estabelecimento[i].nome!,
            command: () => {
              this.escolheuEstabelecimento(this.estabelecimento[i])
              this.exibeNome = this.estabelecimento[i].nome!

            }
          },]
        }
        )
      }
      this.itemsAdmin = x
      this.escolheuEstabelecimento(this.estabelecimento[0])
    },
      (error) => {
        console.log(error.message)
        console.log('deu ruim')
        this.mostraCarregando = false;
        this.alertService.alert('Algo deu errado.', 'error');
        //this.showError('Atenção', 'Algo deu errado.')
      });

  }


  escolheuEstabelecimento(estabelecimento: Estabelecimento) {
    this.estabelecimentoService.setestabelecimento(estabelecimento);
    console.log(estabelecimento)
    this.imagemEstabelecimento = environment.imgEmpresa + estabelecimento.filename
    this.mostraFaturamento = '';

    this.faturamento = estabelecimento.receita!.toString();


    /**FAZ A VERIFICAÇÃO DO BOTÃO SHOW/HIDE PARA SABER COMO DEVERA SER MOSTRADO O VALOR DA RECEITA */
    if (this.show === true) {
     this.mostraFaturamento = Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(Number(this.faturamento)) //FORMATA O VALOR DA RECEITA PARA REAIS
    } else {
      for (let i = 0; i < this.faturamento.length; i++) {
        this.mostraFaturamento += '•';
      }
    }


  }

}
