<p-toast position="bottom-center" key="bc"></p-toast>
<p-toast position="top-right" key="tr"></p-toast>
<div class="pt-7">

    <div class="flex flex-column  pb-3">
        <div class="flex align-items-center justify-content-center">
            <img src="./assets/iconeConfigFinanceiro.svg"  class="iconeContas"/>
        </div>
        <div class=" titulonegritoConta flex align-items-center justify-content-center">
            <span>Contas</span>
        </div>


        <div class="flex justify-content-center flex-wrap ">
            <div class="flex align-items-center justify-content-center">
                <div class="scroll-divCadConta">
                    <table id="tabela">
                        <tr *ngFor="let contas of contas">
                            <td>
                                <div class="linhahorizontalConta pt-1"></div>
                                <div class="col-12 sm-12 md-4 ">
                                    <div class="flex flex-column">

                                        <div class="flex align-items-center justify-content-center tamanhoLinhaConta">
                                            <div class="col ">
                                                <div class="fontTituloConta">
                                                    <span class="xl:text-xl">{{contas.descr}}</span>
                                                </div>
                                            </div>
                                            <div class="flex align-items-center justify-content-center ">
                                                <img class="cursor-pointer" src="./assets/iconeMenuAdm.png"
                                                    (click)="menu.toggle($event); contaSelecionada(contas)" />
                                                <p-menu #menu [popup]="true" [model]="itemsConta"
                                                    styleClass="menuConta"></p-menu>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>

            <p-confirmDialog #cd [closable]="false" id="confirmConta">
                <ng-template pTemplate="footer">
                    <button type="button" pButton icon="pi pi-times" label="Retornar" (click)="cd.reject()"
                        id="confirmBotaoConta"></button>
                    <button type="button" pButton icon="pi pi-check" label="Confirmar" (click)="cd.accept()"
                        id="confirmBotaoConta"></button>
                </ng-template>
            </p-confirmDialog>

        </div>
        <div class="flex justify-content-center flex-wrap ">
            <div class="flex align-items-center justify-content-center">
                <footer class="xl:w-5" id="footerConta">
                    <p-button label="Cadastrar" id="botaoConta" [style]="{width: '100%'}" (click)="mostraDialogConta()">
                    </p-button>
                </footer>
            </div>
        </div>
    </div>
</div>

<!--                          DIALOG DE CADASTRO DA CONTA           -->
<p-dialog header="Conta" [(visible)]="showCadastroConta" [modal]="true"
    [breakpoints]="{'960px': '534px', '640px': '327px'}" id="dialogConta">
    <div class="flex justify-content-center flex-wrap col">
        <div class="fontDialogConta flex align-items-center justify-content-center">
            <span>Informações sobre a conta</span>
        </div>
    </div>

    <input type="text" [(ngModel)]="descr" pInputText placeholder="Nome da conta" [style]="{width: '100%'}"
        class="mt-2 mb-4">


    <!-- 
    <p-dropdown [options]="barbearia" [(ngModel)]="barbeariaSelecionado" placeholder="Barbearia" optionLabel="nome"
        optionValue="codemp" [style]="{'width': '100%'}" id="dropDesconto" scrollHeight="80px"
        (onChange)="buscarBarbeirosPorCodEmp()">
    </p-dropdown> -->


    <div *ngIf="!loading" [style]="{width: '100%'}">
        <button pButton type="button" label="Salvar" class="col-12 " [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
    'border-radius': '11px'}" (click)="criaConta()"></button>
    </div>
    <div *ngIf="loading" [style]="{width: '100%'}">
        <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 " [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
    'border-radius': '11px'}"></button>
    </div>
</p-dialog>