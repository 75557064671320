import { Injectable } from '@angular/core';
import { Produto } from './assets/produto';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './environments/environment';
import { Observable, first, tap } from 'rxjs';
import { LoginService } from './login/login.service';

@Injectable({
  providedIn: 'root'
})
export class VendaService {
  venda: any;

  constructor(private http: HttpClient, private loginService: LoginService) { }

  createVenda(nuid: any, dia: string, codfunc: any, codusu: any, codemp: any,
    vlrtot: number, status: string, itens: any, titulo: string, descricao: string, obs: string, codusuresp: string, vlrdesc: number, descManual: number): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    var venda = {
      "nuid": nuid, "dia": dia, "codfunc": codfunc, "codusu": codusu, "codemp": codemp,
      "vlrtot": vlrtot, "status": status, "itens": itens, "titulo": titulo, "descricao": descricao, "obs": obs, "codusuresp": codusuresp,
      "vlrdesc": vlrdesc, "descManual": descManual
    }

    return this.http
      .post(`${environment.BASE_URL}/venda/createvenda`, venda, { headers: headers })
      .pipe(
        first(),
        // delay(5000),
        tap((r) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );

  }


  listaVendaDireta(codcli: number, codfunc: number, dtini: string, dtfin: string, pendente: string, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    var cliente = { "codcli": codcli, "codfunc": codfunc, "dtini": dtini, "dtfin": dtfin, "pendente": pendente, "codusuresp": codusuresp }

    return this.http
      .post(`${environment.BASE_URL}/venda/listvendas`, cliente, { headers: headers })
      .pipe(
        first(),
        // delay(5000),
        tap((r) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  cancelarVenda(nuid: number, codusuresp: string, status: any): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    var venda = { "nuid": nuid, "codusuresp": codusuresp, "status": status }

    return this.http
      .post(`${environment.BASE_URL}/venda/cancelarvenda`, venda, { headers: headers })
      .pipe(
        first(),
        // delay(5000),
        tap((r) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }




  public setVenda(venda: any) { //SETA O BARBEIRO NO VETOR
    this.venda = venda;
  }

  public getVenda() { //RECUPERA O CONTEUDO DO BARBEIRO PARA VISUALIZAÇÃO
    return this.venda;
  }

}
