<p-toast position="bottom-center" key="bc"></p-toast>

<div class="pt-7">

    <div class=" flex justify-content-center flex-wrap">
        <div class="flex align-items-center justify-content-center">
            <div class="flex flex-column pb-3">
                <div class="flex align-items-center justify-content-center">
                    <img class="iconePerfil" src="./assets/iconeAvatar.svg" />
                </div>
                <div class=" fontNomeBarbeariaContato ">
                    <span> Meu Perfil</span>
                </div>
            </div>
        </div>
    </div>

    <div class=" flex justify-content-center flex-wrap">

        <div class=" sm-8 md-6 xl:col-5 lg:col-5 md:col-5">

            <div class="shadow-4">
                <p-card id="cardPerfil" class="shadow-4">
                    <div class="flex flex-column col-12">
                        <div
                            class="fontNomeBarbeariaContato flex justify-content-center align-items-center flex-wrap pb-1">
                            <span class="xl:text-4xl"> {{nome}}</span>
                        </div>
                        <div class="fontInfo flex justify-content-center flex-wrap pb-1">
                            <span class="xl:text-2xl">Meus dados</span>
                        </div>
                        <div class="flex justify-content-center flex-wrap">
                            <div class="linhahorizontalPerfil pb-3 xl:w-26rem"></div>
                        </div>
                        <div class="flex justify-content-center flex-wrap">
                            <div class="flex flex-column  xl:w-26rem containerPerfil">
                                <!-- <div class="flex flex-column tamanhoContato"> -->
                                <div class="">
                                    <div class="formgroup-inline justify-content-between flex-wrap pb-3 ">
                                        <div class="fonteCinzaContato">
                                            <span class="xl:text-base">Data de Nascimento</span>
                                            <div>
                                                <span class="fonteDadosContato xl:text-base">{{dtnasc| date:'dd/MM/yyyy'}} </span>
                                            </div>
                                        </div>
                                        <div class="flex gap-2">
                                            <img src="./assets/iconeBolo.svg" class=" xl:w-2rem" />
                                        </div>
                                    </div>

                                    <div class=" formgroup-inline justify-content-between flex-wrap pb-3">
                                        <div class="fonteCinzaContato">
                                            <span class="xl:text-base">Telefone</span>
                                            <div>
                                                <span class="fonteDadosContato xl:text-base"> {{telefone}}</span>
                                            </div>
                                        </div>
                                        <div class="flex">
                                            <img src="./assets/iconeContatoTel.svg" class=" xl:w-2rem" />
                                        </div>
                                    </div>

                                    <div class=" formgroup-inline justify-content-between flex-wrap pb-2">
                                        <div class="fonteCinzaContato">
                                            <span class="xl:text-base">E-mail</span>
                                            <div>
                                                <span class="fonteDadosContato xl:text-base"> {{email}}</span>
                                            </div>
                                        </div>
                                        <div class="flex">
                                            <img src="./assets/iconeContatoEmail.svg" class=" xl:w-2rem" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="flex flex-column sm:flex-row  align-items-start">

                        </div> -->
                    </div>
                </p-card>
            </div>
        </div>
    </div>
    <br>













</div>


<!--                          DIALOG DO CARREGANDO               -->
<p-dialog id="dialogCarregandoPerfil" [(visible)]="mostraCarregando" [modal]="true"
    [style]="{'width': '150px', 'height': '140px'}" [closable]="false">
    <div class="flex flex-column align-items-center mt-2">
        <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
            fill="var(--surface-ground)" animationDuration="1s"></p-progressSpinner>
        <div class="fontCarregando">
            <span>Carregando...</span>
        </div>
    </div>
</p-dialog>