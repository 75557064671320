import { ServicoService } from './../servico.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { EstabelecimentoService } from 'src/estabelecimento.service';
import { ProfissionalService } from 'src/profissional.service';
import { Estabelecimento } from 'src/assets/estabelecimento';
import { Profissional } from 'src/assets/profissional';
import { Servico } from 'src/assets/servico';
import { MessageService } from 'primeng/api';
import { UtilitariosService } from 'src/assets/utilitarios.service';
import { AlertService } from 'src/alert.service';
import { TraducaoService } from 'src/traducao.service';
import { PermissoesService } from 'src/permissoes.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-servico',
  templateUrl: './servico.component.html',
  styleUrls: ['./servico.component.scss'],
  providers: [MessageService],
  encapsulation: ViewEncapsulation.None
})
export class ServicoComponent implements OnInit {
  showServico: boolean = false;
  showAtivarDesativar: boolean = false;
  estabelecimento: Estabelecimento[] = [];
  estabelecimentoSelecionado: any;
  profissional: Profissional[] = [];
  profissionalSelecionado: any;
  servico: Servico = {};
  showAtivarDesativarServico: boolean = false;
  showCategoria: boolean = false;

  /**RADIO BUTTON */
  valorSelecionadoServico: string = '';

  /**VARIAVEIS DO BOTAO DE LOADING */
  teveRespostaEstabelecimento: boolean = false;

  /** VARIAVEIS DO COMPONENT */
  codusuresp: string = '';
  codfunc: string = '';
  codemp: string = '';
  codcli: number = 0;
  codprod: number = 0;
  hora: string = '';
  nomeprod: string = '';
  descrprod: string = '';
  tempom: string = '';
  preco: number = 0;
  exibeNome: string = ''
  loading: boolean = false;
  comissao: any;
  categorias: any[] = [];
  categoriaSelecionada: any;

  /**VARIAVEIS DE PERMISSÕES */
  alterar: boolean = false;
  cadastrar: boolean = false;
  inativar: boolean = false;
  permissao: any;


  constructor(private servicoService: ServicoService, private decodedTokenService: DecodedTokenService, private estabelecimentoService: EstabelecimentoService,
    private profissionalService: ProfissionalService, private messageService: MessageService, private utilitariosService: UtilitariosService,
    private alertService: AlertService, private traducaoService: TraducaoService, private permissoesService: PermissoesService) {
    /**TRADUÇÃO */
    this.traducaoService.changeLang();

  }

  ngOnInit(): void {
    this.recuperaCodUsu();
    this.recuperaCodCli();
    this.recuperaServico();
    this.recuperaPermissao();
    this.buscarEstabelecimentosPorCodCliente();
    this.buscarCategorias();
    // this.buscarProfissionalsPorCodCliente();
    this.recuperaDadosServico();
  }

  recuperaCodUsu() {
    this.codusuresp = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }

  recuperaCodCli() {
    this.codcli = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODCLI;
  }

  recuperaServico() {
    this.servico = this.servicoService.getServico();
  }


  recuperaDadosServico() {
    this.nomeprod = this.servico.nomeprod!;
    this.descrprod = this.servico.descrprod!;
    this.codprod = this.servico.codprod!;
    this.exibeNome = this.servico.nomeprod!;
    this.preco = this.servico.preco!;
    this.tempom = this.servico.tempom!;
    this.codemp = this.servico.codemp!;
    this.buscarProfissionalsPorCodEmp();
    this.codfunc = this.servico.codfunc!;
    this.estabelecimentoSelecionado = this.codemp;
    this.profissionalSelecionado = this.codfunc;
    this.valorSelecionadoServico = this.servico.ativo!;
    this.comissao = this.servico.comissao!;
    this.categoriaSelecionada = this.servico.categoria;
    console.log(this.codprod)
  }


  mostraDialogServico() {
    /**VERIFICA SE TEM PERMISSÃO PARA ESSA AÇÃO */
    if (!this.alterar && this.permissao != undefined && this.permissao.length > 0 && this.exibeNome != '' && this.exibeNome != undefined) {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
    this.showServico = true;
  }


  mostraDialogCategoria() {
    /**VERIFICA SE TEM PERMISSÃO PARA ESSA AÇÃO */
    if (!this.alterar && this.permissao != undefined && this.permissao.length > 0 && this.exibeNome != '' && this.exibeNome != undefined) {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }

    if (this.codprod > 0 && this.codprod != undefined) {
      this.showCategoria = true;
    }
  }


  // mostraDialogAtivarDesativar() {
  //   /**VERIFICA SE TEM PERMISSÃO PARA ESSA AÇÃO */
  //   if (!this.inativar && this.permissao != undefined && this.permissao.length > 0 && this.exibeNome != '' && this.exibeNome != undefined) {
  //     this.showError('Atenção', 'Ação não permitida.')
  //     return
  //   }
  //   console.log(this.codprod)
  //   if (this.codprod > 0 && this.codprod != undefined) {
  //     this.showAtivarDesativar = true;
  //   }
  // }

  mostraDialogAtivarDesativarServico() {
    //if (this.codfunc != undefined && this.codfunc != null && this.codfunc != '') {
    /**VERIFICA SE TEM PERMISSÃO PARA ESSA AÇÃO */
    if (!this.inativar && this.permissao != undefined && this.permissao.length > 0) {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
    console.log(this.codprod)
    this.buscaServicoPorCodClient();

    if (this.codprod > 0 && this.codprod != undefined) {
      this.showAtivarDesativarServico = true;
    }

    //}
  }




  salvarServico() {
    console.log(this.codprod)
    if (this.comissao === undefined || this.comissao === null) {
      this.comissao = 0;
    }
    this.loading = true;
    if (this.utilitariosService.validaCampo([this.nomeprod, this.descrprod, this.preco, this.estabelecimentoSelecionado, this.profissionalSelecionado, this.tempom])) {
      this.showError('Atenção', 'É necessário preencher todos os campos.')
      this.loading = false;
      return;
    }

    if (this.utilitariosService.validaHora(this.tempom, '')) {
      this.showError('Atenção', 'Horário do Serviço informado não é válido.')
      this.loading = false;
      return;
    }


    console.log(this.nomeprod + ': nomeprod')
    console.log(this.descrprod + ': descrprod')
    console.log(this.preco + ': preco')
    console.log(this.estabelecimentoSelecionado + ': estabelecimentoSelecionado')
    console.log(this.profissionalSelecionado + ': profissionalSelecionado')
    console.log(this.tempom + ': hora')

    this.servicoService.preencherServico(this.codprod, this.nomeprod, this.descrprod, this.estabelecimentoSelecionado, this.codcli,
      this.tempom, this.preco, this.profissionalSelecionado, this.codusuresp, this.comissao).subscribe((res) => {
        this.codprod = Object(res).codprod;
        this.exibeNome = this.nomeprod;
        this.showServico = false;
        this.loading = false;
        this.alertService.alert('Informações do serviço alteradas!', 'success')
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.loading = false;
          this.showServico = false;
          this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')
        });
  }



  /**METODO QUE RECUPERA A LISTA DE BARBEARIAS */
  buscarEstabelecimentosPorCodCliente() {
    this.estabelecimentoService.buscarEstabelecimentosporCodCli(this.codcli, this.codusuresp).subscribe((res) => {
      this.estabelecimento = res;
    },
      (error) => {
        console.log(error)
        console.log('deu ruim')

      })


  }

  /**METODO QUE RECUPERA A LISTA DE BARBEIROS */
  buscarProfissionalsPorCodEmp() {
    this.teveRespostaEstabelecimento = true;
    setTimeout(() => {
      this.profissionalService.buscarProfissionalporCodEmp(this.estabelecimentoSelecionado, this.codusuresp).subscribe((res) => {
        this.teveRespostaEstabelecimento = false;
        this.profissional = res;
      },
        (error) => {
          this.teveRespostaEstabelecimento = false;
          console.log(error)
          console.log('deu ruim')

        })
    }, 500);
  }

  /**METODO QUE RECUPERA AS CATEGORIAS */
  buscarCategorias() {
    this.servicoService.buscarCategoriasProd(environment.CODTIPSEG).subscribe((res) => {
      console.log(res);
      this.categorias = res.filter((x: any) => x.categoria != null); //RETIRA A CATEGORIA NULL
    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
      })
  }

  /**METODO QUE ATUALIZA A CATEGORIA DO SERVIÇO */
  atualizaCategoria() {
    this.loading = true;
    this.servicoService.updateCategoriaProd(this.codprod, this.categoriaSelecionada, this.codusuresp).subscribe((res) => {
      console.log(res);
      this.loading = false;
      this.showCategoria = false;
      this.alertService.alert('Categoria do serviço alterada!', 'success');
    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
        this.loading = false;
        this.showCategoria = false;
        this.alertService.alert('Algo deu errado.', 'error');
      })
  }

  ativaDesativaServico() {  //this.produto.filter(x => x.CODPROD === 10803)
    this.loading = true;
    if (this.servico.ativo != this.valorSelecionadoServico) {
      this.servicoService.ativarDesativarServicoPorCodProd(this.codprod, this.codusuresp).subscribe((res) => {
        console.log(res)
        this.showAtivarDesativarServico = false;
        if (Object(res).ativo === 'S') {
          this.showAtivarDesativarServico = false;
          this.loading = false;
          this.alertService.alert('Serviço ativado!', 'success')
        }
        if (Object(res).ativo === 'N') {
          this.showAtivarDesativarServico = false;
          this.loading = false;
          this.alertService.alert('Serviço desativado!', 'success')
        }

      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.loading = false;
          this.showAtivarDesativarServico = false;
          this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')
        });
    } else {
      this.loading = false;
    }
  }

  buscaServicoPorCodClient() {
    this.servicoService.buscarServicoPorCodCli(this.codcli, this.codusuresp).subscribe((res) => {
      this.servico = res;
      this.servico = Object(res.filter((x: any) => x.codfunc === this.codfunc))[0]
      console.log(this.servico)
      this.recuperaDadosServico();
    });
  }

  /**METODO DE TOAST */
  showError(titulo: string, mensagem: string) {
    if (screen.width < 640 || screen.height < 480) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else if (screen.width < 1024 || screen.height < 768) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else {
      this.messageService.add({
        key: 'tr',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    }
  }
  /**RECUPERA AS PERMISSÕES */
  recuperaPermissao() {
    console.log('recuperei as permissoes')
    this.permissao = this.permissoesService.getPermissao();
    console.log(this.permissao)
    if (this.permissao != undefined && this.permissao.length > 0) {
      if (this.permissao[2].acao[0].PERMITE === 'S') {
        this.cadastrar = true;
      }
      if (this.permissao[2].acao[1].PERMITE === 'S') {
        this.alterar = true;
      }
      if (this.permissao[2].acao[2].PERMITE === 'S') {
        this.inativar = true;
      }
      /**ATRIBUI O CODCLI VINCULADO NA PERMISSAO DO FUNCIONARIO, PARA FAZER AS OPERAÇÕES DA TELA */
      this.codcli = this.permissao[0].codcli;
    }
  }


}




// console.log('igejigfegrf')
// console.log(this.codfunc)
// this.servicoService.buscarServicoPorCodCli(this.codcli).subscribe((res) => {
//   this.servico = res;
//   this.servico = Object(res.filter((x: any) => x.codfunc === this.codfunc))[0]
//   console.log(this.servico)
//   if (this.servico.ativo != this.valorSelecionadoServico) {
//     this.servicoService.ativarDesativarServicoPorCodProd(this.codprod).subscribe((res) => {
//       console.log(res)
//     });
//   }
// });
