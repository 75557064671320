import { ParceirosService } from './../parceiros.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { UsuarioService } from 'src/usuario.service';
import { Cliente } from 'src/assets/cliente';
import { EstabelecimentoService } from 'src/estabelecimento.service';
import { UtilitariosService } from 'src/assets/utilitarios.service';
import { AlertService } from 'src/alert.service';
import { TraducaoService } from 'src/traducao.service';
import { Estabelecimento } from 'src/assets/estabelecimento';
import { NgxMaskModule } from 'ngx-mask';
import { LoginService } from 'src/login/login.service';
import { environment } from 'src/environments/environment';

declare var cordova: any;

@Component({
  selector: 'app-clientes-filiados',
  templateUrl: './clientes-filiados.component.html',
  styleUrls: ['./clientes-filiados.component.scss'],
  providers: [ConfirmationService, MessageService],
  encapsulation: ViewEncapsulation.None
})
export class ClientesFiliadosComponent implements OnInit {

  showCadastra: boolean = false;
  loading: boolean = false;

  clientes: Cliente[] = [];

  /**MENU */
  itemsClientes: MenuItem[] = [];
  itemsRelatorio: MenuItem[] = [];

  codfunc: number = 0;
  codusu: string = '';
  codcli: string = '';
  codparc: number = 0;

  nuClientes: number = 0;
  nuParceiros: number = 0;

  /**DATA */
  hoje: Date;

  /**VARIAVEIS DA LISTAGEM DE BARBEARIAS */
  estabelecimentos: Estabelecimento[] = [];
  estabelecimentoSelecionada: any;

  /**VARIAVEIS DE CADASTRO */
  tipoUsuario: any[];
  tipoUsuarioSelecionado: string = 'PARCEIRO';
  nome: string = '';
  sobrenome: string = '';
  dtNasc: any;
  email: any;
  telefone: any;
  cpfcnpj: any;
  cpfCli: any;
  detalhes: string = '';
  senha: string = '';
  desabilitaTipo: boolean = false;
  desabilitaCampos: boolean = false;
  codusuCli: number = 0;

  usuarioSelec: any;


  isCpf: boolean = false;
  //mascara: string = '000.000.000-00';
  mascara: string = '';




  constructor(private parceirosService: ParceirosService, private decodedTokenService: DecodedTokenService, private usuarioService: UsuarioService, private confirmationService: ConfirmationService,
    private messageService: MessageService, private estabelecimentoService: EstabelecimentoService, private utilitariosService: UtilitariosService, private alertService: AlertService,
    private traducaoService: TraducaoService, private loginService: LoginService) {
    /**TRADUÇÃO */
    this.traducaoService.changeLang();

    this.hoje = new Date();

    this.tipoUsuario = [{ 'tipo': 'CLIENTE' }, { 'tipo': 'PARCEIRO' }];


  }

  ngOnInit(): void {
    this.recuperaCodCli();
    this.recuperaCodUsu();
    this.recuperaClientes()

    this.itemsClientes = [{
      items: [{
        label: 'Detalhes',
        command: () => {
          this.preencheDadosDialogUsuario();
        },
      },
      {
        label: 'Banir',
        styleClass: 'linhaMenu',
        command: () => {
          this.confirmaBaneParceiro();
        },
      }
      ]
    }];

    this.itemsRelatorio = [{
      items: [{
        label: 'Baixar Excel',
        command: () => {
          this.geraRelatorioExcelDesempenho();
        },
      },
      {
        label: 'Baixar PDF',
        styleClass: 'linhaMenu',
        command: () => {
          this.geraRelatorioPDFDesempenho();
        },
      }
      ]
    }];
  }
  recuperaCodCli() {
    this.codcli = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODCLI;
    this.buscarEstabelecimentosPorCodCliente();
  }

  recuperaCodUsu() {
    this.codusu = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }

  mostraDialogCliParc() {
    console.log(this.estabelecimentoSelecionada)
    this.desabilitaTipo = false;
    this.desabilitaCampos = false;
    this.tipoUsuarioSelecionado = 'PARCEIRO';
    this.nome = '';
    this.sobrenome = '';
    this.estabelecimentoSelecionada = null;
    this.dtNasc = null;
    this.email = null;
    this.telefone = null;
    this.cpfcnpj = null;
    this.cpfCli = null;
    this.detalhes = '';
    this.showCadastra = true;
  }

  /**METODO QUE RECUPERA OS FUNCIONARIOS DA REDE (CODCLI) */
  /**É PASSADO CODFUNC = 0*/
  recuperaClientes() {
    this.nuClientes = 0;
    this.nuParceiros = 0;
    this.parceirosService.buscaClientesGeralPorCodFunc('0', this.codcli, this.codusu).subscribe((res) => {
      this.clientes = res;

      /**CONTA O NUMERO DE CLIENTES E PARCEIROS DA REDE */
      for (let i = 0; i < this.clientes.length; i++) {
        if (this.clientes[i].tipcli === 'CLIENTE') {
          this.nuClientes++;
        } else {
          this.nuParceiros++;
          console.log(this.clientes[i])
        }
      }
      // console.log(res)
    })
  }

  /**RECUPERA AS BARBEARIAS */
  buscarEstabelecimentosPorCodCliente() {
    /**VERIFICA SE VAI FAZER A BUSCA PELO CODCLI DO TOKEN OU DA PERMISSÃO */
    this.estabelecimentoService.buscarEstabelecimentosporCodCli(Number(this.codcli), this.codusu).subscribe((res) => {
      this.estabelecimentos = res;

    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
      })

  }

  /**METODO DE TOAST */
  showError(titulo: string, mensagem: string) {
    if (screen.width < 640 || screen.height < 480) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else if (screen.width < 1024 || screen.height < 768) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else {
      this.messageService.add({
        key: 'tr',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    }
  }




  /** METODO PARA FAZER A BUSCA DE CLIENTES/PARCEIROS */
  buscarCliParc() {
    let filter = (document.getElementById('pesquisa') as HTMLInputElement)!.value.toUpperCase();
    let myTable = document.getElementById('tabela');
    let tr = myTable?.getElementsByTagName('tr');
    for (let i = 0; i < tr!.length; i++) {
      let td = tr![i].getElementsByTagName('td')[0];
      if (td) {
        let textv = td.textContent || td.innerHTML;
        if (textv.toUpperCase().indexOf(filter) > -1) {
          console.log(textv)
          tr![i].style.display = "";
        } else {
          tr![i].style.display = "none";
        }
      }
    }
  }


  /**ATRIBUI UMA BARBEARIA PARA O CLIENTE SER FILIADO*/
  escolheCliParc() {
    if (this.tipoUsuarioSelecionado === 'CLIENTE') {
      this.estabelecimentoSelecionada = this.estabelecimentos[0].codemp;
    } else {
      this.estabelecimentoSelecionada = null;
    }
  }

  /**METODO PARA CADASTRAR/EDITAR USUARIO */
  preencheUsuario() {
    this.loading = true;

    /**VERIFICA O TIPO DE USUARIO A SER CADASTRADO/EDITADO */
    /**CADASTRA/EDITA PARCEIRO */
    if (this.tipoUsuarioSelecionado === 'PARCEIRO') {
      if (this.utilitariosService.validaCampo([this.nome, this.telefone, this.estabelecimentoSelecionada])) {
        this.showError('Atenção', 'É necessário preencher todos os campos.');
        this.loading = false;
        return;
      }


      console.log(this.dtNasc)
      /**AJUSTA A DATA DE NASCIMENTOPARA FICAR DO JEITO QUE O BANCO PRECISA, SE NÃO É ENVIADO A DATA COMO NULL */
      let auxData = null;
      if (this.dtNasc != null) {
        console.log('a')
        auxData = this.utilitariosService.ajustaDataBanco(this.dtNasc);
      }

      /**RETIRA O ESPAÇO NO INICIO DA STRING, CASO TIVER */
      this.nome = this.nome.trimStart();

      /**VERIFICA SE O PARCEIRO ESTÁ SENDO CRIADO (CODPARC = 0) OU EDITADO (CODPARC > 0) */
      if (this.codparc > 0) {
        this.parceirosService.cadastraParceiro(this.codparc, this.nome, this.email, this.telefone, auxData, this.estabelecimentoSelecionada,
          this.detalhes, this.cpfcnpj, this.codusu).subscribe((res) => {
            this.alertService.alert('Parceiro alterado!', 'success')
            this.loading = false;
            this.showCadastra = false;
            this.recuperaClientes();
            // console.log(res);
          },
            (error) => {
              console.log(error)
              console.log('deu ruim')
              this.loading = false;
              this.showCadastra = false;
              this.alertService.alert('Algo deu errado.', 'error');
            })
      } else {
        this.parceirosService.cadastraParceiro(null, this.nome, this.email, this.telefone, auxData, this.estabelecimentoSelecionada,
          this.detalhes, this.cpfcnpj, this.codusu).subscribe((res) => {
            this.alertService.alert('Parceiro cadastrado!', 'success')
            this.loading = false;
            this.showCadastra = false;
            this.recuperaClientes();
            //  console.log(res);
          },
            (error) => {
              console.log(error)
              console.log('deu ruim')
              this.loading = false;
              this.showCadastra = false;
              this.alertService.alert('Algo deu errado.', 'error');
            })
      }


      /**CADASTRA CLIENTE */
    }

    if (this.tipoUsuarioSelecionado === 'CLIENTE') {
      if (this.codusuCli > 0) {
        console.log('edita')
        if (this.usuarioSelec.email !== null) {
          this.showError('Atenção', 'Não é permitido editar clientes que já possuem e-mail.');
          this.loading = false;
          return;
        }

        /**VERIFICA SE OS CAMPOS OBRIGATORIOS FORAM PREENCHIDOS */
        if (this.utilitariosService.validaCampo([this.nome, this.telefone])) {
          this.showError('Atenção', 'É necessário preencher todos os campos.');
          this.loading = false;
          return;
        }

        /**RETIRA O ESPAÇO NO INICIO DA STRING, CASO TIVER */
        this.nome = this.nome.trimStart();
        this.loginService.updateUser(this.codusuCli, this.nome, this.email, this.telefone, this.utilitariosService.ajustaDataBanco(this.dtNasc), null, this.cpfCli, this.codusu).subscribe((res) => {
          this.alertService.alert('Cliente alterado!', 'success')
          this.loading = false;
          this.showCadastra = false;
          this.recuperaClientes();
          //  console.log(res);
        },
          (error) => {
            console.log(error)
            console.log('deu ruim')
            this.loading = false;
            this.showCadastra = false;
            this.alertService.alert('Algo deu errado.', 'error');
          })

      } else {
        console.log('cadastra')

        /**VERIFICA SE OS CAMPOS OBRIGATORIOS FORAM PREENCHIDOS */
        if (this.utilitariosService.validaCampo([this.nome, this.telefone])) {
          this.showError('Atenção', 'É necessário preencher todos os campos.');
          this.loading = false;
          return;
        }

        /**RETIRA O ESPAÇO NO INICIO DA STRING, CASO TIVER */
        this.nome = this.nome.trimStart();

        this.loginService.createUser(this.nome, this.email, this.telefone, this.utilitariosService.ajustaDataBanco(this.dtNasc), '123456', this.estabelecimentoSelecionada, this.cpfCli, environment.CODTIPSEG).subscribe((res) => {
          this.alertService.alert('Cliente cadastrado!', 'success')
          this.loading = false;
          this.showCadastra = false;
          this.recuperaClientes();
          //  console.log(res);
        },
          (error) => {
            console.log(error)
            console.log('deu ruim')
            this.loading = false;
            this.showCadastra = false;
            this.alertService.alert('Algo deu errado.', 'error');
          })
      }
    }
  }

  /**METODO QUE PREENCHE O DIALOG DE USUARIO APOS CLICAR EM 'Detalhes'*/
  usuarioSelecionado(usuario: any) {
    this.usuarioSelec = usuario;
    console.log(this.usuarioSelec)
  }

  /**METODO QUE PREENCHE O DIALOG DE USUARIO APOS CLICAR EM 'Detalhes'*/
  preencheDadosDialogUsuario() {
    this.codparc = this.usuarioSelec.codparc;
    this.tipoUsuarioSelecionado = this.usuarioSelec.tipcli;
    this.nome = this.usuarioSelec.nomeparc;

    if (this.usuarioSelec.dtnasc !== null) {
      this.dtNasc = new Date(this.usuarioSelec.dtnasc + 'GMT-0300'); //'GMT-0300' PARA FICAR NO FUSO HORÁRIO DO BRASIL (SE NÃO FIZER ISSO FICA 1 DIA ANTERIOR)
    } else {
      this.dtNasc = null;
    }

    if (this.usuarioSelec.email !== null) {
      this.email = this.usuarioSelec.email;
    } else {
      this.email = null;
    }
    this.telefone = this.usuarioSelec.telefone.replace(/\D/g, '');
    this.desabilitaTipo = true;
    this.estabelecimentoSelecionada = this.usuarioSelec.codemp;

    /**DESABILITA OS CAMPOS PARA QUE NÃO ALTERE O CLIENTE */
    if (this.tipoUsuarioSelecionado === 'CLIENTE') {
      this.cpfCli = this.usuarioSelec.cgc_cpfcnpj;
      this.desabilitaCampos = true;
      this.codusuCli = this.usuarioSelec.codusu;
    } else {
      this.cpfcnpj = this.usuarioSelec.cgc_cpfcnpj;
      this.desabilitaCampos = false;
    }
    this.showCadastra = true;
  }

  /**METODO QUE AJUSTA A MASCARA PARA CPF/CPNJ */
  ajustarMascara() {
    const valorSemMascara = this.cpfcnpj.replace(/[^\d]/g, '');
    if (valorSemMascara.length <= 11) {
      this.isCpf = true;
    }
    else {
      this.isCpf = false;
    }
  }

  confirmaBaneParceiro() {
    this.confirmationService.confirm({
      message: 'Deseja mesmo excluir o parceiro: ' + this.usuarioSelec.nomeparc + ' ? Esta é uma ação irreversível!',
      header: 'Banir parceiro',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.excluiContaPorNucon();
      },
      reject: () => {
      }
    });
  }

  /**METODOS DE EXCLUSÃO DA CONTA */
  excluiContaPorNucon() {
    this.parceirosService.baneParceiro(this.usuarioSelec.codusu, this.usuarioSelec.codemp, this.codusu).subscribe((res) => {
      console.log(res);
      this.clientes = this.clientes.filter((x: any) => x.codusu != res.codusu); //FILTRA TUDO O QUE É DIFERENTE DO QUE FOI EXCLUIDO

    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
        this.loading = false;
        this.showCadastra = false;
        this.alertService.alert('Algo deu errado.', 'error');
      });
  }



  /**RELATORIOS DE CLIENTE/PARCEIRO */
  geraRelatorioPDFDesempenho() {
    /**CHECA SE ESTÁ NA VERSÃO MOBILE */
    if (environment.MOBILE) {
      this.parceirosService.geraRelatorioPDFClienteParceiro(0, this.codcli, this.codusu).subscribe((res) => {
        let url = environment.urlRelatorio + res[0];
        cordova.InAppBrowser.open(url, '_system');
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.alertService.alert('Algo deu errado.', 'error');
        })
    } else {
      this.parceirosService.geraRelatorioPDFClienteParceiro(0, this.codcli, this.codusu).subscribe((res) => {
        let url = environment.urlRelatorio + res[0];
        window.open(url);
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.alertService.alert('Algo deu errado.', 'error');
        })
    }
  }


  geraRelatorioExcelDesempenho() {
    /**CHECA SE ESTÁ NA VERSÃO MOBILE */
    if (environment.MOBILE) {
      this.parceirosService.geraRelatorioExcelClienteParceiro(0, this.codcli, this.codusu).subscribe((res) => {
        let url = environment.urlRelatorio + res[0]
        cordova.InAppBrowser.open(url, '_system');
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.alertService.alert('Algo deu errado.', 'error');
        })
    } else {
      this.parceirosService.geraRelatorioExcelClienteParceiro(0, this.codcli, this.codusu).subscribe((res) => {
        let url = environment.urlRelatorio + res[0]
        window.open(url);
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.alertService.alert('Algo deu errado.', 'error');
        })
    }

  }
}



