import { ServicoService } from './../servico.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { Servico } from 'src/assets/servico';
import { ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';
import { PermissoesService } from 'src/permissoes.service';

@Component({
  selector: 'app-cadastrar-servicos',
  templateUrl: './cadastrar-servicos.component.html',
  styleUrls: ['./cadastrar-servicos.component.scss'],
  providers: [ConfirmationService, MessageService],
  encapsulation: ViewEncapsulation.None
})
export class CadastrarServicosComponent implements OnInit {
  servico: Servico[] = [];
  itemsServicos: MenuItem[] = [];


  /* variaveis do componente */
  codcli: number = 0;
  servicoSelec: Servico = {};
  codusuresp: string = '';

  /**VARIAVEIS DE PERMISSÕES */
  permissao: any;
  alterar: boolean = false;
  excluir: boolean = false;
  inativar: boolean = false;
  cadastrar: boolean = false

  tamanhoTabela: string = '';

  constructor(private decodedTokenService: DecodedTokenService, private servicoService: ServicoService, private confirmationService: ConfirmationService,
    private router: Router, private permissoesService: PermissoesService, private messageService: MessageService) {

  }

  ngOnInit(): void {
    this.recuperaCodUsu();
    this.recuperaCodCli();
    this.recuperaPermissao();
    this.buscarServicoPorCodCliente();


    this.itemsServicos = [{
      items: [{
        label: 'Detalhes',
        command: () => {
          this.editarServico(this.servicoSelec)
        },
        // routerLink: ['/servico'],
      }
      ]
    },
    {
      items: [{
        label: 'Excluir',
        styleClass: 'linhaMenu',
        command: () => {
          this.confirmaExclusaoServico()
        }
      }
      ]
    }
    ];

    this.verificaResolucao();
  }

  recuperaCodUsu() {
    this.codusuresp = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }

  recuperaCodCli() {
    this.codcli = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODCLI;
  }

  buscarServicoPorCodCliente() {
    /**VERIFICA SE VAI FAZER A BUSCA PELO CODCLI DO TOKEN OU DA PERMISSÃO */
    if (this.permissao != undefined && this.permissao.length > 0) {
      this.servicoService.buscarServicoPorCodCli(this.permissao[0].codcli, this.codusuresp).subscribe((res) => {
        this.servico = res;
      });
    }
    else {
      this.servicoService.buscarServicoPorCodCli(this.codcli, this.codusuresp).subscribe((res) => {
        this.servico = res;
      });
    }
  }


  servicoSelecionado(servico: Servico) {
    this.servicoSelec = servico;
  }

  editarServico(servico: Servico) {
    /**VERIFICA SEM TEM PERMISSÃO PARA ESSA AÇÃO */
    if ((this.alterar || this.inativar) || this.codcli > 0) {
      this.servicoService.setServico(servico);
      this.router.navigate([('/servico')]);
    } else {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }

  }

  cadastrarServico() {
    /**VERIFICA SEM TEM PERMISSÃO PARA ESSA AÇÃO */
    if (this.cadastrar || this.codcli > 0) {
      var auxServico: Servico = {}
      this.servicoService.setServico(auxServico);
      this.router.navigate([('/servico')]);
    } else {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
  }


  excluiServicoPorCodProd() {
    this.servicoService.excluiServicoPorCodProd(this.servicoSelec.codprod!, this.codusuresp).subscribe((res) => {
      this.servico = this.servico.filter((x: any) => x.codprod != res.codprod); //FILTRA TUDO O QUE É DIFERENTE DO QUE FOI EXCLUIDO
      console.log(this.servico)
    });
  }


  confirmaExclusaoServico() {
    /**VERIFICA SEM TEM PERMISSÃO PARA ESSA AÇÃO */
    if (this.excluir || this.codcli > 0) {
      this.confirmationService.confirm({
        message: 'Deseja mesmo excluir ' + this.servicoSelec.nomeprod + ' ?',
        header: 'Excluir Serviço',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.excluiServicoPorCodProd();
        },
        reject: () => {
        }
      });
    } else {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
  }


  /**RECUPERA AS PERMISSÕES */
  recuperaPermissao() {
    this.permissao = this.permissoesService.getPermissao();
    console.log(this.permissao)
    if (this.permissao != undefined && this.permissao.length > 0) {
      if (this.permissao[2].acao[0].PERMITE === 'S') {
        this.cadastrar = true;
      }
      if (this.permissao[2].acao[1].PERMITE === 'S') {
        this.alterar = true;
      }
      if (this.permissao[2].acao[2].PERMITE === 'S') {
        this.inativar = true;
      }
      if (this.permissao[2].acao[3].PERMITE === 'S') {
        this.excluir = true;
      }
    }
    console.log(this.cadastrar, this.alterar, this.excluir)
  }


  /**METODO DE TOAST */
  showError(titulo: string, mensagem: string) {
    if (screen.width < 640 || screen.height < 480) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else if (screen.width < 1024 || screen.height < 768) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else {
      this.messageService.add({
        key: 'tr',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    }
  }

  verificaResolucao() {
    if (screen.width < 1024 || screen.height < 768) {
      this.tamanhoTabela = '70vh';
    } else {
      this.tamanhoTabela = '80vh';
    }

    window.addEventListener('resize', () => {
      if (screen.width < 1024 || screen.height < 768) {
        this.tamanhoTabela = '70vh';
      } else {
        this.tamanhoTabela = '80vh';
      }
    });

  }
}
