import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './environments/environment';
import { Observable, first, tap } from 'rxjs';
import { LoginService } from './login/login.service';

@Injectable({
  providedIn: 'root'
})
export class DescontoService {

  constructor(private http: HttpClient, private loginService: LoginService) { }

  createCupom(nucup: any, nomecup: string, descrcup: string, codcli: number, codemp: any, codfunc: number, produtos: any, qtdmax: number,
    dtvigini: any, dtvigfin: any, tipdesc: string, desc: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const cupom = {
      "nucup": nucup, "nomecup": nomecup, "descrcup": descrcup, "codcli": codcli, "codemp": codemp,
      "codfunc": codfunc, "produtos": produtos, "qtdmax": qtdmax, "dtvigini": dtvigini, "dtvigfin": dtvigfin,
      "tipdesc": tipdesc, "desc": desc, "codusuresp": codusuresp
    }

    return this.http
      .post<any>(`${environment.BASE_URL}/desconto/createcupom`, cupom, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  buscaCuponsPorCodCli(codcli: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const cliente = { "codcli": codcli, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/desconto/getcuponsbycodcli`, cliente, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  excluiCupomPorNucup(nucup: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const cupom = { "nucup": nucup, "codusuresp": codusuresp }

    return this.http
      .put<any>(`${environment.BASE_URL}/desconto/excluicupom`, cupom, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  ativarDesativarCupomPorNucup(nucup: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const cupom = { "nucup": nucup, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/desconto/ativardesativarcupom`, cupom, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  atualizarDescontoProduto(codprod: number, tipdesc: string, desc: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const produto = { "codprod": codprod, "tipdesc": tipdesc, "desc": desc, "codusuresp": codusuresp }

    return this.http
      .put<any>(`${environment.BASE_URL}/desconto/atualizardescontoproduto`, produto, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  atualizarDescontoProfissional(codfunc: any, tipdesc: string, desc: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const profissional = { "codfunc": codfunc, "tipdesc": tipdesc, "desc": desc, "codusuresp": codusuresp }

    return this.http
      .put<any>(`${environment.BASE_URL}/desconto/atualizardescprofissional`, profissional, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  createDescontoTotal(nudes: any, nomedesconto: string, codcli: number, codemp: number, codfunc: number, liminf: number, limsup: number,
    tipdesc: string, desc: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const desconto = {
      "nudes": nudes, "nomedesconto": nomedesconto, "codcli": codcli, "codemp": codemp, "codfunc": codfunc, "liminf": liminf,
      "limsup": limsup, "tipdesc": tipdesc, "desc": desc, "codusuresp": codusuresp
    }

    return this.http
      .post<any>(`${environment.BASE_URL}/desconto/createdesctotal`, desconto, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  buscaDescontoTotalPorCodCli(codcli: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const cliente = { "codcli": codcli, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/desconto/getdesctotalbycodcli`, cliente, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  excluiDescontoTotalPorNudes(nudes: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const desconto = { "nudes": nudes, "codusuresp": codusuresp }

    return this.http
      .put<any>(`${environment.BASE_URL}/desconto/excluidesctotal`, desconto, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  ativarDesativarDescontoTotalPorNudes(nudes: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const desconto = { "nudes": nudes, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/desconto/ativardesativardesctotal`, desconto, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  atualizaPontosPronduto(codprod: number, habilitapont: string, pont: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const produto = { "codprod": codprod, "habilitapont": habilitapont, "pont": pont, "codusuresp": codusuresp }

    return this.http
      .put<any>(`${environment.BASE_URL}/desconto/atualizarpontosproduto`, produto, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  validaCupom(nomecupom: string, codemp: any, codfunc: any, codprod: string, codusu: string, tipo: string, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const cupom = { "nomecupom": nomecupom, "codemp": codemp, "codfunc": codfunc, "codprod": codprod, "codusu": codusu, "tipo": tipo, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/desconto/validacupomdesconto`, cupom, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  buscaDescontoTotal(vlrtot: number, codemp: any, codfunc: any, codusu: string, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const cupom = { "vlrtot": vlrtot, "codemp": codemp, "codfunc": codfunc, "codusu": codusu, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/desconto/buscadesctotal`, cupom, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }
}
