<body id="esqSenha">
    <div class="flex flex-column  align-items-center  redimensionamentoEsqSenha">
        <div class="col-12 sm-12 md-4  xl:col-5 lg:col-5 md:col-5 sm:col-5">
            <div class="flex align-items-center justify-content-center">
                <div class="flex align-items-center justify-content-center">

                    <img src="./assets/logo.svg" />
                </div>
            </div>
            <div class="">
                <div *ngIf="teveErro" id="erroEsqSenha">
                    <p-messages severity="error" id="erroEsqSenha">
                        <ng-template pTemplate id="erroEsqSenha">
                            <div class="">{{msgErro}}</div>
                        </ng-template>
                    </p-messages>
                </div>
                <div class="col-12 flex align-items-center justify-content-center divPers">

                    <input id="inputEsqueceuSenha" class="col-12" type="text" pInputText placeholder="E-mail"
                        [(ngModel)]="email" />
                </div>
                <div class="col-12 divPers">
                    <div class=" grid p-flup-2 flex align-items-center justify-content-center pt-2">
                        <div class="field col  gap-2">
                            <button pButton type="button" label="Voltar" class="col-12" routerLink="/login"
                                id="botaoEsqSenha"></button>
                        </div>
                        <div class="field col">

                            <div *ngIf="!loading" [style]="{width: '100%'}">
                                <button id="botaoEsqSenha" pButton type="button" label="Recuperar Conta" class="col-12"
                                    (click)="criaCodigo()"></button>
                            </div>
                            <div *ngIf="loading" [style]="{width: '100%'}">
                                <button id="botaoLoadingEsqSenha" [disabled]="true" pButton type="button"
                                    label="" class="col-12"></button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</body>