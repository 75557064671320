import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';


@Injectable({
  providedIn: 'root'
})
export class DecodedTokenService {

  constructor() { }

  getDecodedAccessToken() {
    var token = localStorage.getItem('token')!;
    try {
      return jwt_decode(token);
    } catch(Error) {
      return null;
    }
  }
}
