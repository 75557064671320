import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { AgendaService } from 'src/agenda.service';
import { AlertService } from 'src/alert.service';
import { Estabelecimento } from 'src/assets/estabelecimento';
import { Profissional } from 'src/assets/profissional';
import { HorariosDisponiveis } from 'src/assets/horario';
import { Servico } from 'src/assets/servico';
import { UtilitariosService } from 'src/assets/utilitarios.service';
import { EstabelecimentoService } from 'src/estabelecimento.service';
import { ProfissionalService } from 'src/profissional.service';
import { environment } from 'src/environments/environment';
import { HorarioService } from 'src/horario.service';
import { ServicoService } from 'src/servico.service';
import { TraducaoService } from 'src/traducao.service';

@Component({
  selector: 'app-agendamento-sem-conta',
  templateUrl: './agendamento-sem-conta.component.html',
  styleUrls: ['./agendamento-sem-conta.component.scss'],
  providers: [MessageService],
  encapsulation: ViewEncapsulation.None
})
export class AgendamentoSemContaComponent implements OnInit {


  /**VARIAVEL DAS ETAPAS DE AGENDAMENTO */
  passos: number = 0;


  todasEstabelecimentos: Estabelecimento[] = [];
  estabelecimentoBuscaSelecionada: Estabelecimento = {};


  /**********************
   * VARIAVEIS DO PASSO 1 
   **********************/
  servicoEstabelecimentoSelecionada: Servico[] = [];
  servicosEstabelecimento: Servico[] = [];

  /**********************
   * VARIAVEIS DO PASSO 3 
   **********************/

  /**MENU */
  activeIndex: number = 0;

  /**DATA */
  data: Date;
  ontem: Date;

  /**DROPDOWN */
  profissionalSelecionado: any;

  /**DIALOG */
  mostraHorarios: boolean = false;

  /**HORARIOS */
  horariosDisponiveis: HorariosDisponiveis[] = [];
  //horariosDisponiveis: any[] = [];

  /**VARIAVEIS DO COMPONENT */
  hashEmpresa: string = '';
  nuid: any;
  codemp: number = 0;
  codcli: number = 0;
  nucup: any;
  total: number = 0;
  totalSemDesc: number = 0;
  diaSelecionado: any;
  horarioMatriz: any = [];
  auxMatriz: any = [];
  horDisponiveis: any = [];
  horarioSelecionado: any = "HH:MM";
  codUsu: string = '';
  totalMin: number = 0;
  auxDia: any;
  codfunc: number = 0;
  codusu: string = '';
  titulo: string = '';
  descr: string = '';
  nomefunc: string = ''
  receberNotificacao: boolean = false;
  showConfirmado: boolean = false;
  descDialogConfirm: string = ''
  teveRespostaProfissional: boolean = false;
  cupom: any;
  listaProdutos: string = '';
  tempoTotalServico: number = 0;

  /**CHECKBOX */
  valorSelecionado: Servico[] = [];

  /**VARIAVEIS DA EMPRESA */
  nomeemp: string = ' ';
  logradouro: string = ' ';
  numero: string = ' ';
  bairro: string = ' ';
  nomecid: string = ' ';
  telefone: string = ' ';
  complemento: string = ' ';
  filename: string = '';


  profissional: Profissional[] = [];
  profissionalSelec: Profissional = {}
  servico: Servico[] = [];
  estabelecimento: any //Estabelecimento[] = [];


  /**VARIAVEIS DO CONTATO DO CLIENTE */
  showDialogContato: boolean = false;
  nomeCliente: string = '';
  telefoneCliente: string = '';
  cpfCliente: any = null;
  emailCliente: any = null;
  loading: boolean = false;

  constructor(private estabelecimentoService: EstabelecimentoService, private servicoService: ServicoService, public primeNGConfig: PrimeNGConfig, private profissionalService: ProfissionalService,
    private horarioService: HorarioService, private utilitariosService: UtilitariosService, private agendaService: AgendaService, private router: Router,
    private messageService: MessageService, private alertService: AlertService, private traducaoService: TraducaoService, private route: ActivatedRoute) {
    /** NÃO PERMITE QUE DATAS ANTERIORES A HOJE SEJAM ESCOLHIDAS */
    this.data = new Date();
    this.ontem = new Date(this.data);
    this.ontem.setDate(this.data.getDate());

    /**TRADUÇÃO */
    this.traducaoService.changeLang();
  }

  ngOnInit(): void {
    // this.buscarEstabelecimentos();
    this.recuperaEmpresa();

  }

  clickAgendar() {
    this.passos += 1;
  }

  clickVoltar() {
    this.passos = this.passos - 1;
  }

  buscarServicoPorCodEmp() {
    this.servicoService.buscarServicoporCodEmp(this.codemp, this.codusu).subscribe((res) => {
      console.log(res);
      this.servicosEstabelecimento = res;

      for (let i = 0; i < this.servicosEstabelecimento.length; i++) {
        this.servicosEstabelecimento[i].minutos = this.tempoEmMinutos(this.servicosEstabelecimento[i].tempom);
      }

    })
  }

  /**METODO DE TOAST */
  showError(titulo: string, mensagem: string) {
    if (screen.width < 640 || screen.height < 480) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else if (screen.width < 1024 || screen.height < 768) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else {
      this.messageService.add({
        key: 'tr',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    }
  }

  recuperaEmpresa() {
    this.route.paramMap.subscribe(params => {
      this.hashEmpresa = params.get('hashEmpresa')!;

      this.buscarEstabelecimentoPorHash();

      // this.estabelecimentoService.buscaEmpresaPorHash(this.hashEmpresa).subscribe((res) => {
      //   console.log(res)
      // },
      //   (error) => {
      //     this.router.navigate(['/login'])
      //     console.log(error)
      //     console.log('deu ruim')
      //     // this.alertService.alert('Algo deu errado.', 'error');
      //     //this.showError('Atenção', 'Algo deu errado.')
      //   });

      // this.codemp = Number(params.get('hashEmpresa')!);
      // Use this.nomeEstabelecimento para carregar os agendamentos da estabelecimento no seu site

      // this.buscarServicoPorCodEmp();
      // this.buscarEstabelecimentoPorCodEmp();
      // this.buscarProfissionalPorCodEmpresa();
    });
  }



  buscarEstabelecimentoPorHash() {
    this.estabelecimentoService.buscaEmpresaPorHash(this.hashEmpresa).subscribe((res) => {
      if (res.length === 0) {
        this.router.navigate(['/login'])
      }

      this.codemp = Object(res)[0].codemp;
      this.nomeemp = Object(res)[0].nomeemp;
      this.logradouro = Object(res)[0].logradouro;
      this.complemento = Object(res)[0].complemento;
      if (Object(res)[0].numero) {
        this.numero = ', ' + Object(res)[0].numero;
      }
      this.bairro = Object(res)[0].bairro;
      this.nomecid = Object(res)[0].nomecid;
      this.telefone = Object(res)[0].telefone;

      this.filename = environment.imgEmpresa + Object(res)[0].filename;

      this.buscarServicoPorCodEmp();
      this.buscarProfissionalPorCodEmpresa();
    },
      (error) => {
        this.router.navigate(['/login'])
        console.log(error)
        console.log('deu ruim')
        // this.alertService.alert('Algo deu errado.', 'error');
        //this.showError('Atenção', 'Algo deu errado.')
      });

  }


  tempoEmMinutos(tempo: any) {
    console.log(tempo)
    const [horas, minutos] = tempo.split(':').map(Number);
    const totalMinutos = (horas * 60) + minutos;
    return totalMinutos;
  }


  /**METODOS DO MENU */
  voltarPagina() {
    this.activeIndex = this.activeIndex - 1;
    if (this.activeIndex === 1) {
      this.somaTotal();
    }
  }

  irPagina2() {
    console.log(this.profissionalSelecionado.codfunc)
    if (this.profissionalSelecionado.codfunc === undefined) {
      this.showError('Atenção', 'Selecione um profissional.')
      //this.alertService.showError1('Atenção', 'Selecione um profissional.');
    }
    else {
      this.activeIndex = this.activeIndex + 1;
      this.somaTotal();
    }
  }

  irPagina3() {
    if (!this.valorSelecionado.length) {
      this.showError('Atenção', 'Selecione um serviço.')
    }
    else {
      this.activeIndex = this.activeIndex + 1;
      this.diaSelecionado = null;
      this.horarioSelecionado = "HH:MM";

    }
  }

  irPagina4() {
    if (this.diaSelecionado != undefined && this.horarioSelecionado === "HH:MM") {
      this.showError('Atenção', 'Selecione um horário.')

    }
    else if (this.diaSelecionado === undefined && this.horarioSelecionado === "HH:MM") {
      this.showError('Atenção', 'Selecione um dia e um horário.')
    } else {
      this.activeIndex = this.activeIndex + 1;
    }
  }



  buscarProfissionalPorCodEmpresa() {

    setTimeout(() => {
      this.profissionalService.buscarProfissionalporCodEmp(this.codemp, '0').subscribe((res) => {
        // this.buscarEstabelecimentoPorCodEmp();
        this.teveRespostaProfissional = false;
        console.log('entrei no busca profissional')
        this.profissional = res;

        for (let i = 0; i < this.profissional.length; i++) {
          if (this.profissional[i].filename !== null) {
            this.profissional[i].filename = environment.imgProfissional + this.profissional[i].filename;
          }
        }
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.teveRespostaProfissional = false;
        })
    }, 500);
  }


  buscarServicoPorCodFuncionario() {
    var auxiNomeFunc = this.profissional.filter(x => x.codfunc === this.profissionalSelecionado.codfunc);
    this.nomefunc = auxiNomeFunc[0].apelido!

    this.servicoService.buscarServicoPorCodFunc(this.profissionalSelecionado.codfunc, this.codusu).subscribe((res) => {
      console.log(res);
      this.servico = res;
      this.valorSelecionado = [];

      for (let i = 0; i < this.servico.length; i++) {
        this.servico[i].minutos = this.tempoEmMinutos(this.servico[i].tempom);
      }
    });
  }

  buscaHorarioPorCodFuncEDia(dia: any) {
    if (this.nuid) {
      console.log('entrei no reagendamento')
      this.horarioService.buscaHorarioPorCodFuncEDiaReagendamento(this.profissionalSelecionado.codfunc, dia, this.nuid, this.codusu).subscribe((res) => {
        this.auxMatriz = [];
        this.horDisponiveis = []
        this.horariosDisponiveis = res;
        this.verificaHorariosEncaixe();
        for (var i in this.horDisponiveis) {
          this.auxMatriz.push([i, this.horDisponiveis[i]])
        }
        this.fazMatriz();
      });
    } else {
      this.horarioService.buscaHorarioPorCodFuncEDia(this.profissionalSelecionado.codfunc, dia, this.codusu).subscribe((res) => {
        console.log('entrei no agendamento')
        this.auxMatriz = [];
        this.horDisponiveis = []
        this.horariosDisponiveis = res;
        this.verificaHorariosEncaixe();
        for (var i in this.horDisponiveis) {
          this.auxMatriz.push([i, this.horDisponiveis[i]])
        }
        this.fazMatriz();
      });
    }


  }

  fazMatriz() {
    this.horarioMatriz = []; //    this.horarioMatriz = this.horDisponiveis;
    const linhas = 4;
    for (let i = 0; i < this.auxMatriz.length; i += linhas) {
      this.horarioMatriz.push(this.auxMatriz.slice(i, i + linhas));
    }
  }

  selecionouDia() {
    this.horarioMatriz = [];
    console.log(this.diaSelecionado)
    //this.auxDia = this.diaSelecionado.getUTCFullYear() + '-' + (this.diaSelecionado.getUTCMonth() + 1) + '-' + this.diaSelecionado.getUTCDate();
    //this.auxDia = this.diaSelecionado.getFullYear() + '-' + ('0' + (this.diaSelecionado.getMonth() + 1)).slice(-2) + '-' + ('0' + this.diaSelecionado.getDate()).slice(-2);
    this.auxDia = this.utilitariosService.ajustaDataBanco(this.diaSelecionado)
    console.log(this.auxDia)
    this.buscaHorarioPorCodFuncEDia(this.auxDia);

    this.horarioSelecionado = "HH:MM";
  }

  /**DIALOG */
  horarios() {
    this.mostraHorarios = true;
  }

  somaTotal() {
    this.total = 0;

    this.totalMin = 0;
    for (let i = 0; i < this.valorSelecionado.length; i++) {
      console.log(Number(this.valorSelecionado[i].tempom!.substr(0, 2)) * 60 + 'HORAS') //HORAS
      console.log(Number(this.valorSelecionado[i].tempom!.substr(3, 2)) + 'MINUTOS') //MINUTOS
      this.totalMin += (Number(this.valorSelecionado[i].tempom!.substr(0, 2)) * 60) + Number(this.valorSelecionado[i].tempom!.substr(3, 2))

      this.total += this.valorSelecionado[i].preco!;
    }
    console.log(this.totalMin)
    console.log(this.total)
  }

  agendaHorario(horario: any) {
    this.mostraHorarios = false;
    this.horarioSelecionado = horario;
  }


  /**METODO QUE VERIFICA OS HORARIOS DISPONIVEIS PARA O SERVIÇO */
  verificaHorariosEncaixe() {
    var bloquinhos = this.totalMin / 15;
    var horAux = [];
    var horarioCandidato: any;
    var countDisp = 0;
    if (bloquinhos - Math.trunc(bloquinhos) > 0) {
      bloquinhos = Math.trunc(bloquinhos) + 1;
    }
    // console.log('bloquinhos: ' + bloquinhos)
    // console.log('HOR DISPONIVEL: ')
    // console.log(this.horariosDisponiveis)
    for (let i = 0; i < this.horariosDisponiveis.length; i++) {

      horarioCandidato = this.horariosDisponiveis[i].horario
      // console.log('FORA ******* do for: ')
      // console.log(horarioCandidato)
      for (let j = 0; j < bloquinhos; j++) {
        //horAux.push(horarioCandidato)
        var auxFiltro = this.horariosDisponiveis.filter((x: any) => (x.horario).toString() === horarioCandidato)
        countDisp += auxFiltro.length;
        horarioCandidato = this.utilitariosService.somaMinutos(horarioCandidato, 15);
        // console.log('dentro do for: ')
        // console.log(horarioCandidato)
      }
      // console.log('bloquinhos:  **********' + bloquinhos)
      // console.log('countDisp:  **********' + countDisp)
      if (countDisp === bloquinhos) {
        this.horDisponiveis.push(this.horariosDisponiveis[i]);
        // console.log('CRIANDO O HORARIO QUE DEU BOM +++++: ')
        // console.log(this.horariosDisponiveis[i])
      }
      countDisp = 0;
    }
  }

  criaTitulo() {
    var auxTitulo = []
    console.log(this.valorSelecionado)
    for (let i = 0; i < this.valorSelecionado.length; i++) {
      if (this.valorSelecionado[i]) {

        if (i == 0) {
          auxTitulo[i] = this.valorSelecionado[i].nomeprod;
        } else {
          auxTitulo[i] = ' ' + this.valorSelecionado[i].nomeprod;
        }
      }
    }
    this.titulo = auxTitulo.toString()
    console.log(this.titulo)
  }

  mostraDialogContatoCliente() {
    this.showDialogContato = true;

    /**VERIFICA SE O CLIENTE JÁ FEZ UM AGENDAMENTO ANTERIORMENTE E ATRIBUI AS INFORMAÇÕES NOS CAMPOS DO POPUP */
    if (localStorage.getItem('infoUsu')) {
      let infoUsu = JSON.parse(localStorage.getItem('infoUsu')!);
      this.nomeCliente = infoUsu.nome;
      this.telefoneCliente = infoUsu.telefone;
      this.cpfCliente = infoUsu.cpf;
      this.emailCliente = infoUsu.email;
    }

  }


  criaAgenda() {
    this.loading = true;
    /**VERIFICA SE OS CAMPOS OBRIGATORIOS FORAM PREENCHIDOS */
    if (this.utilitariosService.validaCampo([this.nomeCliente, this.telefoneCliente])) {
      this.showError('Atenção', 'É necessário preencher todos os campos obrigatórios.');
      this.loading = false;
      return;
    }

    /**VERIFICIA SE DIGITOU SOBRENOME */
    let testaNome = this.nomeCliente.split(' ');
    if (testaNome.length < 2) {
      this.showError('Atenção', 'É necessário informar um sobrenome.');
      this.loading = false;
      return;
    }


    /**VERIFICA SE CASO DIGITOU UM EMAIL, ELE É VÁLIDO*/
    if (this.emailCliente !== '' && this.emailCliente !== null) {
      if (!this.utilitariosService.validaEmail(this.emailCliente)) {
        this.showError('Atenção', 'É necessário informar uma e-mail válido.');
        this.loading = false;

        return;
      }
    }

    this.emailCliente = this.utilitariosService.tornaNull(this.emailCliente);
    this.cpfCliente = this.utilitariosService.tornaNull(this.cpfCliente);
    console.log(this.emailCliente)
    console.log(this.cpfCliente)


    this.agendaService.createAgendaDeslogado(this.horarioSelecionado, this.utilitariosService.somaMinutos(this.horarioSelecionado, this.totalMin),
      this.auxDia, this.profissionalSelecionado.codfunc, null, this.codemp, Number(this.total.toFixed(2)), 'AGENDADO', 'S', this.valorSelecionado,
      this.titulo, this.nomeCliente, null, 0, null, this.nomeCliente, this.telefoneCliente.replace(/\D/g, ''), this.emailCliente, this.cpfCliente, environment.CODTIPSEG).
      subscribe((res) => {
        console.log(res);

        this.loading = false;
        this.showDialogContato = false;

        this.alertService.alert('Seu agendamento foi realizado com sucesso!', 'success');

        //GUARDA AS INFORMAÇÕES DO CLIENTE PARA RECUPERAR NO PRÓXIMO AGENDAMENTO
        let infoUsu = { "nome": this.nomeCliente, "telefone": this.telefoneCliente, "email": this.emailCliente, "cpf": this.cpfCliente };
        localStorage.setItem('infoUsu', JSON.stringify(infoUsu));

        /**VAI PARA O RESUMO DO AGENDAMENTO */
        this.passos += 1;
      },
        (error) => {
          this.loading = false;

          this.showDialogContato = false;
          console.log(error)
          console.log('deu ruim')
          this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')
        });

  }

  //METODO QUE RESETA OS PASSOS PARA SER FEITO UM NOVO AGENDAMENTO
  novoAgendamento() {
    this.passos = 0;
    this.activeIndex = 0;
    this.profissionalSelecionado = [];
    this.valorSelecionado = [];
    this.somaTotal();
    this.diaSelecionado = null;
    this.horarioSelecionado = "HH:MM";
  }
}
