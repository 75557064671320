import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { delay, first, Observable, Subject, tap } from 'rxjs';
import { environment } from './environments/environment';
import { AppServiceService } from './app-service.service';
import { LoginService } from './login/login.service';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  status: any;

  // usuario: any[] = [];
  // public status$ = new Subject(); //EXIBIR ERRO
  constructor(private http: HttpClient, private loginService: LoginService) { }

  getPerfil(codUsu: string, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    console.log(codUsu)
    var user = { "codusu": codUsu, "codusuresp": codusuresp };

    return this.http
      .post(`${environment.BASE_URL}/login/meuperfil`, user, { headers: headers })
      .pipe(
        first(),
        // delay(5000),
        tap((r) => {
          console.log(r);
          // if (sessionStorage.getItem('usuario')) {
          //   this.usuario = JSON.parse(sessionStorage.getItem('usuario')!);
          // }
          // sessionStorage.setItem('usuario', JSON.stringify(r))
        }, (e: any) => {
          this.loginService.verificaToken(e.status);
        })
      );
  }

  // getUsuarioSessionStorage() {
  //   if (sessionStorage.getItem('usuario')) {
  //     this.usuario = JSON.parse(sessionStorage.getItem('usuario')!);
  //   }
  //   return this.usuario;
  // }


}
