import { Informativo } from 'src/assets/informativo';
import { InformativoService } from './../informativo.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { Router } from '@angular/router';
import { PermissoesService } from 'src/permissoes.service';

@Component({
  selector: 'app-cadastrar-informativos',
  templateUrl: './cadastrar-informativos.component.html',
  styleUrls: ['./cadastrar-informativos.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ConfirmationService, MessageService],
})
export class CadastrarInformativosComponent implements OnInit {

  itemsInformativos: MenuItem[] = [];

  informativos: any[] = [];
  informativo: Informativo[] = [];
  informativoSelec: Informativo = {}


  codusuresp: string = '';
  codcli: number = 0;

  /**VARIAVEIS DE PERMISSÕES */
  permissao: any;
  alterar: boolean = false;
  excluir: boolean = false;
  cadastrar: boolean = false


  constructor(private informativoSerice: InformativoService, private decodedTokenService: DecodedTokenService, private confirmationService: ConfirmationService,
    private router: Router, private permissoesService: PermissoesService, private messageService: MessageService) {

    // this.informativos = [{
    //   'titulo': 'Barbas Preferidas',
    // },
    // {
    //   'titulo': 'Novos Horários de Atendimento'
    // }]
  }

  ngOnInit(): void {
    this.recuperaCodCli();
    this.recuperaPermissao();
    this.buscarInformativosPorCodCliente();
    this.recuperaCodUsu();

    this.itemsInformativos = [{
      items: [{
        label: 'Detalhes',
        command: () => {
          this.editarInformativo(this.informativoSelec)
        },
        //routerLink: ['/informativos'],
      }
      ]
    },
    {
      items: [{
        label: 'Excluir',
        styleClass: 'linhaMenu',
        command: () => {
          // console.log(this.barbeariaSelec.codemp);
          this.confirmaExclusaoInformativo();
        }
      }
      ]
    }
    ];
  }

  recuperaCodUsu() {
    this.codusuresp = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }

  recuperaCodCli() {
    this.codcli = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODCLI;
  }


  buscarInformativosPorCodCliente() {
    this.informativo = [];

    if (this.permissao != undefined && this.permissao.length > 0) {
      this.informativoSerice.buscaInformativosPorCodCli(this.permissao[0].codcli, this.codusuresp).subscribe((res) => {
        console.log(res);
        this.informativo = res;
      });
    }
    else {
      this.informativoSerice.buscaInformativosPorCodCli(this.codcli, this.codusuresp).subscribe((res) => {
        console.log(res);
        this.informativo = res;
      });
    }

  }

  cadastrarInformativo() {
    /**VERIFICA SEM TEM PERMISSÃO PARA ESSA AÇÃO */
    if (this.cadastrar || this.codcli > 0) {
      var auxInformativo: Informativo = {}
      this.informativoSerice.setInformativo(auxInformativo);
      this.router.navigate([('/informativos')]);
    } else {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }

  }

  editarInformativo(informativo: Informativo) {
    /**VERIFICA SEM TEM PERMISSÃO PARA ESSA AÇÃO */
    if (this.alterar || this.codcli > 0) {
      this.informativoSerice.setInformativo(informativo);
      this.router.navigate([('/informativos')]);
    } else {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
  }

  informativoSelecionado(informativo: Informativo) {
    this.informativoSelec = informativo;
    console.log(this.informativoSelec)
  }



  excluiInformativoPorNuInf() {
    this.informativoSerice.excluiInformativoPorNuInf(this.informativoSelec.nuinf!, this.codusuresp).subscribe((res) => {
      this.informativo = this.informativo.filter((x: any) => x.nuinf != Object(res).nuinf); //FILTRA TUDO O QUE É DIFERENTE DO QUE FOI EXCLUIDO
      console.log(this.informativo)
    });


  }


  confirmaExclusaoInformativo() {
    /**VERIFICA SEM TEM PERMISSÃO PARA ESSA AÇÃO */
    if (this.excluir || this.codcli > 0) {
      this.confirmationService.confirm({
        message: 'Deseja mesmo excluir ' + this.informativoSelec.titulo + ' ?',
        header: 'Excluir Barbeiro',
        icon: 'pi pi-exclamation-triangle',

        accept: () => {
          this.excluiInformativoPorNuInf();
        },
        reject: () => {
        }
      });
    } else {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
  }

  /**RECUPERA AS PERMISSÕES */
  recuperaPermissao() {
    this.permissao = this.permissoesService.getPermissao();
    console.log(this.permissao)
    if (this.permissao != undefined && this.permissao.length > 0) {
      if (this.permissao[4].acao[0].PERMITE === 'S') {
        this.cadastrar = true;
      }
      if (this.permissao[4].acao[1].PERMITE === 'S') {
        this.alterar = true;
      }

      if (this.permissao[4].acao[2].PERMITE === 'S') {
        this.excluir = true;
      }
    }
    console.log(this.cadastrar, this.alterar, this.excluir)
  }


  /**METODO DE TOAST */
  showError(titulo: string, mensagem: string) {
    if (screen.width < 640 || screen.height < 480) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else if (screen.width < 1024 || screen.height < 768) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else {
      this.messageService.add({
        key: 'tr',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    }
  }
}
