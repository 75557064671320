<p-toast position="bottom-center" key="bc"></p-toast>
<p-toast position="top-right" key="tr"></p-toast>
<div class="pt-7">


    <div class="flex flex-column  pb-3">
        <div class="flex align-items-center justify-content-center">
            <img src="./assets/iconeCadPlanos.svg" class="iconeClientes" />
        </div>

        <div class="flex justify-content-evenly flex-wrap">
            <div class="posicaoClientes flex align-items-center justify-content-center ">
                <span class="titulonegritoCadPlanoPacote ">Planos/ Pacotes</span>
            </div>

            <div>


                <div class="contornoQtdPlanos">
                    <div class="flex justify-content-center flex-wrap">

                        <div class="flex align-items-center justify-content-start">
                            <span class="fontPlanos container line-height-1">Planos
                                em uso</span>
                        </div>
                        <div class="flex align-items-center justify-content-end">
                            <span class="fontTotalPlanos ">
                                32
                            </span>
                        </div>
                    </div>
                </div>
                <div class="pb-2"></div>
                <div class="contornoQtdPlanos">
                    <div class="flex justify-content-center flex-wrap">

                        <div class="flex align-items-center justify-content-start">
                            <span class="fontPlanos container line-height-1">Planos
                                ativos</span>
                        </div>
                        <div class="flex align-items-center justify-content-end">
                            <span class="fontTotalPlanos ">
                                {{planos.length}}
                            </span>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="flex flex-column  pb-3">
        <div class="flex justify-content-center flex-wrap ">

            <div class="scroll-divCadPlanoPacote">
                <table id="tabela">
                    <tr *ngFor="let planos of planos">
                        <td>
                            <div class="linhahorizontalCadPlanoPacote pt-1"></div>
                            <div class="col-12 sm-12 md-4 ">
                                <div class="flex flex-column">

                                    <div class="flex align-items-center justify-content-center tamanhoLinhaTabela">
                                        <div class="col ">
                                            <div class="fontTituloCadPlanoPacote">
                                                <span class="xl:text-xl">{{planos.nome}}</span>
                                            </div>
                                            <div class="formgroup-inline justify-content-between flex-wrap">
                                                <div class="fontDescrPlanoPacote pb-2 ">
                                                    <div class="flex gap-2">
                                                        <div
                                                            class="flex align-items-center justify-content-center gap-2 ">
                                                            <img src="./assets/iconePlanoPacote.svg" />



                                                            <div class="flex flex-column  ">
                                                                <span class="xl:text-base">
                                                                    {{planos.descr}}</span>


                                                                <span class="xl:text-base pt-1"> Duração do plano:
                                                                    {{planos.duracao}} dias</span>

                                                                <span class="xl:text-base"> Valor do plano:
                                                                    {{planos.preco | currency : 'BRL'}}</span>

                                                                <span class="xl:text-base"> Quantidade do plano:
                                                                    {{planos.qtd}}</span>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex align-items-center justify-content-center ">
                                            <img class="cursor-pointer" src="./assets/iconeMenuAdm.png"
                                                (click)="menu.toggle($event);planoSelecionado(planos)" />
                                            <p-menu #menu [popup]="true" [model]="itemsPlanoPacote"
                                                styleClass="menuCadPlanoPacote"></p-menu>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </td>
                    </tr>
                </table>

            </div>
            <p-confirmDialog #cd [closable]="false" id="confirmCadPlanoPacote">
                <ng-template pTemplate="footer">
                    <button type="button" pButton icon="pi pi-times" label="Retornar" (click)="cd.reject()"></button>
                    <button type="button" pButton icon="pi pi-check" label="Confirmar" (click)="cd.accept()"></button>
                </ng-template>
            </p-confirmDialog>



        </div>


        <div class="flex justify-content-center flex-wrap ">
            <div class="flex align-items-center justify-content-center">

                <footer class="xl:w-5" id="footerCadPlanoPacote">
                    <p-button label="Cadastrar" id="botaoCadPlanoPacote" (click)="cadastrarPlanoPacote()"
                        [style]="{width: '100%'}">
                    </p-button>
                </footer>
            </div>
        </div>
    </div>