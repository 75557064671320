import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-cadastrar-notificacoes',
  templateUrl: './cadastrar-notificacoes.component.html',
  styleUrls: ['./cadastrar-notificacoes.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CadastrarNotificacoesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
