import { environment } from 'src/environments/environment';
import { AppServiceService } from './../app-service.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-sobre',
  templateUrl: './sobre.component.html',
  styleUrls: ['./sobre.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SobreComponent implements OnInit {
  dialog: boolean = false;

  nomeapp: string = ' ';
  site: string = ' ';
  telefone1: string = ' ';
  telefone2: string = ' ';
  email1: string = ' ';
  email2: string = ' ';
  versao: string = ' ';
  termos: string = ' ';

  constructor(private appServiceService: AppServiceService) { }

  ngOnInit(): void {
    this.sobre();
  }


  sobre() {
    this.appServiceService.buscarSobre(environment.CODTIPSEG).subscribe((res) => {
      console.log(res)
      this.nomeapp = Object(res)[0].nomeapp;
      this.site = Object(res)[0].site;
      this.telefone1 = Object(res)[0].telefone1;
      this.telefone2 = Object(res)[0].telefone2;
      this.email1 = Object(res)[0].email1;
      this.email2 = Object(res)[0].email2;
      this.versao = Object(res)[0].versao;
      this.termos = Object(res)[0].termos;
    })
  }


  mostraSobre() {
    this.dialog = true;
  }



}
