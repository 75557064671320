import { InformativoRecuperado } from './assets/informativo';
import { Informativo } from 'src/assets/informativo';
import { Injectable } from '@angular/core';
import { environment } from './environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { delay, first, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LoginService } from './login/login.service';




@Injectable({
  providedIn: 'root'
})
export class InformativoService {
  informativo: Informativo = {}
  informativoHome: InformativoRecuperado = {};

  constructor(private http: HttpClient, private loginService: LoginService) { }


  // createInformativo(titulo: string, subtitulo: string, codemp: number, codcli: number, img: any) {
  createInformativo(img: any) {
    //const imagem = { "codemp": codemp, "imagem_empresa": img }
    // const headers =  { 'Content-Type': 'multipart/form-data' }

     const token = localStorage.getItem('token');
    // let headers = new HttpHeaders().append('Authorization', token!);

    console.log(img)
    let headers = new HttpHeaders({
      'enctype': 'multipart/form-data',
      'Authorization': token!
    });
    return this.http
      .post<any>(`${environment.BASE_URL}/informativo/createinf`, img, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  preencheNoticia(nuinf: number, noticia: string, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const informativo = { "nuinf": nuinf, "noticia": noticia, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/informativo/preenchenot`, informativo, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  preencheVigencia(nuinf: number, dtvigini: any, dtvigfin: any, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const vigencia = { "nuinf": nuinf, "dtvigini": dtvigini, "dtvigfin": dtvigfin, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/informativo/preenchevigencia`, vigencia, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  buscaInformativosPorCodEmp(codemp: number, codusuresp: string, codtipseg: number): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const empresa = { "codemp": codemp, "codusuresp": codusuresp, "codtipseg": codtipseg }

    return this.http
      .post<any>(`${environment.BASE_URL}/informativo/buscainformativosbycodemp`, empresa, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  buscaInformativosPorCodCli(codcli: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const cliente = { "codcli": codcli, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/informativo/buscainformativosbycodcli`, cliente, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }



  excluiInformativoPorNuInf(nuinf: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const informativo = { "nuinf": nuinf, "codusuresp": codusuresp }

    return this.http
      .put<any>(`${environment.BASE_URL}/informativo/excluiinformativo`, informativo, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  public setInformativo(i: Informativo) {//INFORMATIVO A SER EDITADO
    this.informativo = i;
  }

  public getInformativo() {
    return this.informativo;
  }


  public setInformativoHome(i: InformativoRecuperado) {//NOTICIA QUE SERA MOSTRADA NA TELA
    this.informativoHome = i;
  }

  public getInformativoHome() {
    return this.informativoHome;
  }

}
