<p-toast position="bottom-center" key="bc"></p-toast>
<p-toast position="top-right" key="tr"></p-toast>
<div class="pt-7">

    <div class="flex flex-column  pb-3">
        <div class="flex align-items-center justify-content-center">
            <img src="./assets/iconeVenda.svg" class="iconeServico" />
        </div>
        <div class="tituloNegritoVenda flex align-items-center justify-content-center">
            <span>Venda Direta</span>
        </div>
    </div>

    <!-- PRIMEIRA TELA DO VENDAS            -->

    <div *ngIf="activeIndex == 0">
        <div class="col-12 sm-12 md-4 ">
            <div class=" flex align-items-center justify-content-center">
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input type="text" class="col-12" pInputText placeholder="Pesquisar" id="pesquisa"
                        (keydown)="buscarProdutos()">
                </span>
            </div>
        </div>

        <div class="flex flex-column pb-3 ">
            <div class="flex justify-content-center flex-wrap ">
                <div class="scroll-divCadVenda ">
                    <table id="tabela">
                        <tr *ngFor="let produtos of produtos">
                            <td>
                                <div class="linhahorizontalVenda "></div>
                                <div class="flex flex-column">
                                    <div class="col tamanhoLinhaTabelaVenda ">
                                        <div class="">
                                            <div class="flex justify-content-between flex-wrap">
                                                <div class=" formgroup-inline gap-5">
                                                    <div class="flex justify-content-center flex-wrap">
                                                        <div
                                                            class="flex align-items-center justify-content-center ml-2 ">
                                                            <div>
                                                                <img [src]="produtos.filename" class="imgProduto" />
                                                            </div>
                                                        </div>
                                                        <div class="flex flex-column  align-items-start">
                                                            <div class="col">
                                                                <div class="fontNomeProd">
                                                                    <span>{{produtos.nomeprod}}</span>
                                                                </div>
                                                                <div
                                                                    class="fontDscrProd pb-2 overflow-hidden text-overflow-ellipsis">
                                                                    <div class="text-container">
                                                                        <p class="text-content">
                                                                            <span> {{produtos.descrprod}}</span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <!-- <div class="flex align-items-center flex-wrap">
                                                                    <div class="formgroup-inline gap-2 pt-1">
                                                                        <div class="contornoValorDesconto">
                                                                            <div
                                                                                class="fontCorDesconto  flex justify-content-center flex-wrap">
                                                                                <span>-{{5|currency
                                                                                    :'BRL'}}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> -->
                                                                <div class="formgroup-inline ">
                                                                    <div
                                                                        class="flex align-items-center justify-content-center  gap-3">
                                                                        <!-- <div
                                                                            class="flex align-items-center justify-content-center">
                                                                            <span
                                                                                class="fontPrecoDescontoProd line-through ">
                                                                                {{produtos.preco|currency
                                                                                :'BRL'}}</span>
                                                                        </div> -->
                                                                        <div
                                                                            class="flex align-items-center justify-content-center">
                                                                            <span class="fontPrecoProd ">
                                                                                {{produtos.preco|currency
                                                                                :'BRL'}}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="!resMobile">
                                                    <p-inputNumber [(ngModel)]="produtos.qtdneg" [showButtons]="true"
                                                        [min]=0 id="botaoQtd" buttonLayout="horizontal"
                                                        spinnerMode="horizontal"
                                                        decrementButtonClass="p-button-secondary"
                                                        incrementButtonClass="p-button-secondary"
                                                        incrementButtonIcon="pi pi-plus"
                                                        decrementButtonIcon="pi pi-minus"
                                                        (click)="somaTotal(produtos); criaTitulo()"></p-inputNumber>
                                                </div>

                                                <div *ngIf="resMobile">
                                                    <p-inputNumber [(ngModel)]="produtos.qtdneg" [showButtons]="true"
                                                        [min]=0 id="botaoQtd" buttonLayout="vertical"
                                                        spinnerMode="vertical" decrementButtonClass="p-button-secondary"
                                                        incrementButtonClass="p-button-secondary"
                                                        incrementButtonIcon="pi pi-plus"
                                                        decrementButtonIcon="pi pi-minus"
                                                        (click)="somaTotal(produtos); criaTitulo()"></p-inputNumber>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>



        <div class="flex justify-content-end flex-wrap pt-3">
            <button pButton type="button" label="Próximo" class="col-12" id="botaoProximo"
                (click)="irPagina2()"></button>
        </div>
    </div>

    <!-- SEGUNDA TELA DO VENDAS            -->

    <div *ngIf="activeIndex == 1">
        <div
            class="xl:flex xl:justify-content-center xl:flex-wrap  md:flex md:justify-content-center md:flex-wrap sm:flex sm:justify-content-center sm:flex-wrap">
            <div class="xl:col-5 sm:col-5 md:col-5 lg:col-5">
                <div class="fontCinzaTela2 col pt-1">
                    <span>Selecionar Barbearia</span>
                </div>
                <div class="linhahorizontalVenda "></div>
                <div class="col">
                    <p-dropdown id="dropVenda" [options]="estabelecimento" [(ngModel)]="estabelecimentoSelec"
                        placeholder="- Escolher Barbearia -" optionLabel="nome" [style]="{'width': '100%'}"
                        optionValue="codemp" scrollHeight="130px" (onChange)="buscaClientesPorCodemp()">
                    </p-dropdown>
                </div>


                <div class="fontCinzaTela2 col pt-1">
                    <span>Selecionar Cliente</span>
                </div>
                <div class="linhahorizontalVenda "></div>
                <div *ngIf="teveRespostaEstabelecimento">
                    <div class="col">
                        <p-button id="botaoSpinnerVenda" label="- Escolher Profissional -" [disabled]="true"
                            class="p-button-text">
                            <i class="pi pi-spin pi-spinner teste flex-order-1" style="font-size: 1rem"></i>
                        </p-button>
                    </div>
                </div>

                <div *ngIf="!teveRespostaEstabelecimento">
                    <div class="col">
                        <p-dropdown id="dropVenda" [options]="cliente" [(ngModel)]="clienteSelec"
                            placeholder="- Escolher Cliente -" optionLabel="nomeparc" [style]="{'width': '100%'}"
                            scrollHeight="130px" (onChange)="print()">
                        </p-dropdown>
                    </div>
                </div>

                <div class="fontCinzaTela2 col pt-1">
                    <span>Desconto</span>
                </div>
                <div class="linhahorizontalVenda "></div>
                <div class="col p-fluid">
                    <!-- <input type="number" [(ngModel)]="descontoManual" pInputText placeholder="Informar Desconto"
                        [style]="{'width': '100%'}" id="inputDesconto" (input)="calculaDesconto()"> -->
                    <p-inputNumber [(ngModel)]="descontoManual" mode="currency" currency="BRL" locale="pt-BR"
                        placeholder="Informar Desconto" [minFractionDigits]="2" id="inputDesconto">
                    </p-inputNumber>
                </div>

                <div class="fontCinzaTela2 col pt-1">
                    <span>Detalhes</span>
                </div>
                <div class="linhahorizontalVenda "></div>
                <div class="col">
                    <!-- <input type="text" [(ngModel)]="detalhes" pInputText 
                    [style]="{'width': '100%'}" class="uppercase" id="inputDesconto"> -->
                    <textarea pInputTextarea [(ngModel)]="detalhes" [autoResize]="true"
                        [style]="{'width': '100%', 'border-radius': '11px'}"></textarea>
                </div>
                <div class="flex justify-content-evenly " [style]="{'gap': '9rem'}">
                    <button pButton type="button" label="Voltar" class="col-12" (click)="voltarPagina()"
                        id="voltar"></button>
                    <button pButton type="button" label="Concluir" class="col-12" id="botaoConcluir"
                        (click)="criaVenda()"></button>
                </div>
            </div>

        </div>


    </div>

    <!--                          DIALOG DE RESUMO DA COMANDA              -->
    <p-dialog id="dialogResumoPedido" header="Resumo da Venda" [(visible)]="showResumoPedido" [draggable]="false"
        [modal]="true" [style]="{'width': '500px', ' max-height': '570px'}" [closable]="false">

        <div class="pt-2">
            <span class="fontCinzaResumoPedido">Detalhes </span>

            <div class="fontCinzaInfoResumoPedido col-12 flex flex-column  ">
                <div *ngFor="let carrinho of carrinho">
                    <div>
                        <span> {{carrinho.nomeprod}} ( {{carrinho.descrprod}} ) - {{carrinho.preco | currency :
                            'BRL'}}</span>
                    </div>
                    <div class="pb-2">
                        <span> Quantidade: {{carrinho.qtdneg}}</span>
                    </div>
                </div>
            </div>

            <div class="mt-2">
                <span class="fontCinzaResumoPedido">Cliente </span>
                <div class="fontCinzaInfoResumoPedido col-12 " *ngIf="!clienteSelec">
                    <span> - </span>
                </div>
                <div class="fontCinzaInfoResumoPedido col-12 flex flex-column  " *ngIf="clienteSelec">
                    <span>{{clienteSelec.nomeparc}}</span>
                </div>
            </div>


            <div class="">
                <span class="fontCinzaResumoPedido">Detalhes </span>

                <div class="fontCinzaInfoResumoPedido col-12 " *ngIf="detalhes === ''">
                    <span> - </span>
                </div>
                <div class="fontCinzaInfoResumoPedido col-12 " *ngIf="detalhes != ''">
                    <span> {{detalhes}}</span>
                </div>
            </div>

            <div class="">
                <span class="fontCinzaResumoPedido">Resumo </span>

                <div class="fontCinzaInfoResumoPedido col-12 flex flex-column  " *ngIf="temDescontoCarrinho">
                    <span>Vlr Total:
                        <span
                            [ngStyle]="descontoManual > 0?{'text-decoration': 'line-through'} : {'text-decoration': 'none'}">{{vlrTotalComDesconto
                            + descontoManual | currency : 'BRL'}}
                        </span>
                    </span>
                    <span *ngIf="descontoManual > 0">Vlr Desc: {{descontoManual | currency : 'BRL'}} </span>
                    <span *ngIf="descontoManual > 0">Vlr Final:
                        <span class="font-bold"> {{vlrTotalComDesconto | currency : 'BRL'}}</span>
                    </span>


                    <!-- <span>Vlr Total:
                        <span>{{total | currency : 'BRL'}}
                        </span>
                    </span> -->
                </div>
                <div class="fontCinzaInfoResumoPedido col-12 flex flex-column  " *ngIf="!temDescontoCarrinho">
                    <span>Vlr Total:
                        <span>{{total | currency : 'BRL'}}
                        </span>
                    </span>
                </div>
            </div>

        </div>




        <button pButton type="button" label="Voltar" class="col-12 mt-3" (click)="fechaDialogResumoPedido()"
            [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)','border-radius': '11px', 'border': 'none'}"></button>
    </p-dialog>






    <!-- <div class="cursor-pointer">
        <img src="./assets/iconePedidos.svg" class="iconeComanda" (click)="mostraDialogResumoPedido()"
            [ngClass]="{'iconeComanda': activeIndex === 0, 'iconeComanda2': activeIndex === 1}" />
    </div> -->


    <footer id="footerVenda">


        <div class="col">

            <div clas="flex justify-content-center flex-wrap">
                <div class="flex align-items-center justify-content-center">
                    <div class="col ">
                        <!-- <span class="fontmarronnegrito">Carrinho:</span> -->
                        <button pButton type="button" label="Resumo" id="carrinho"
                            (click)="mostraDialogResumoPedido()"></button>
                    </div>
                </div>


                <div class="flex align-items-center justify-content-center">
                    <div class="formgroup-inline gap-1">

                        <div class="fontmarrontotal">
                            <span>Qtd Itens: </span>
                        </div>
                        <div class="fontmarronvalores">
                            <span>{{qtdItens}} itens</span>
                        </div>
                    </div>
                </div>


                <div class="flex align-items-center justify-content-center" *ngIf="!temDescontoCarrinho">
                    <div class="formgroup-inline gap-3">
                        <div class="fontmarrontotal">
                            <span>Valor: </span>
                        </div>
                        <div class="fontmarronvalores">
                            <span>{{total | currency : 'BRL'}}</span>
                        </div>
                    </div>
                </div>


                <div class="flex align-items-center flex-wrap justify-content-center" *ngIf="temDescontoCarrinho">
                    <div class="formgroup-inline pl-5 gap-3">
                        <div class="fontmarrontotal">
                            <span>Valor: </span>
                        </div>
                        <div class="formgroup-inline gap-2">


                            <div class="flex align-items-center justify-content-center">
                                <span class="fontmarronvalores line-through">{{totalGeral|
                                    currency :
                                    'BRL'}}</span>
                            </div>
                            <div class="flex align-items-center justify-content-center">
                                <span class="fontmarronvaloresDescontoTotal">{{vlrTotalComDesconto|
                                    currency :
                                    'BRL'}}</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </footer>

</div>