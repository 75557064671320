import { DetalheNotificacaoComponent } from './../detalhe-notificacao/detalhe-notificacao.component';
import { ComissaoComponent } from './../comissao/comissao.component';
import { CadastrarComissaoComponent } from './../cadastrar-comissao/cadastrar-comissao.component';
import { FaturasComponent } from './../faturas/faturas.component';
import { NotificacoesComponent } from './../notificacoes/notificacoes.component';
import { CadastrarNotificacoesComponent } from './../cadastrar-notificacoes/cadastrar-notificacoes.component';
import { PesquisaComponent } from './../pesquisa/pesquisa.component';
import { CadastrarPesquisaComponent } from './../cadastrar-pesquisa/cadastrar-pesquisa.component';
import { DetalheInformativoComponent } from './../detalhe-informativo/detalhe-informativo.component';
import { CadastrarInformativosComponent } from './../cadastrar-informativos/cadastrar-informativos.component';
import { InformativosComponent } from './../informativos/informativos.component';
import { RelatoriosComponent } from './../relatorios/relatorios.component';
import { ClientesFiliadosComponent } from './../clientes-filiados/clientes-filiados.component';
import { FiliarEstabelecimentoComponent } from '../filiar-estabelecimento/filiar-estabelecimento.component';
import { ServicoComponent } from './../servico/servico.component';
import { CadastrarServicosComponent } from './../cadastrar-servicos/cadastrar-servicos.component';
import { AgendaComponent } from '../agenda/agenda.component';
import { ProfissionalComponent } from '../profissional/profissional.component';
import { CadastrarProfissionaisComponent } from '../cadastrar-profissionais/cadastrar-profissionais.component';
import { EstabelecimentoComponent } from '../estabelecimento/estabelecimento.component';
import { CadastrarEstabelecimentoComponent } from '../cadastrar-estabelecimento/cadastrar-estabelecimento.component';
import { MenuAdministrativoComponent } from './../menu-administrativo/menu-administrativo.component';
import { BuscarEstabelecimentoComponent } from '../buscar-estabelecimento/buscar-estabelecimento.component';
import { SobreComponent } from './../sobre/sobre.component';
import { LoginModule } from 'src/login/login.module';
import { HomeModule } from './../home/home.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { registerLocaleData } from '@angular/common';
import localePT from '@angular/common/locales/pt';

//IMPORT COMPONENTS
import { LoginComponent } from 'src/login/login.component';
import { HomeComponent } from 'src/home/home.component';
import { EsqueceuSenhaComponent } from 'src/esqueceu-senha/esqueceu-senha.component';
import { CadastroComponent } from 'src/cadastro/cadastro.component';
import { PerfilComponent } from './../perfil/perfil.component';
import { ContatoComponent } from './../contato/contato.component';
import { AlterarSenhaComponent } from './../alterar-senha/alterar-senha.component';
import { MinhaAgendaComponent } from './../minha-agenda/minha-agenda.component';
import { AgendarComponent } from './../agendar/agendar.component';
import { DescontosComponent } from 'src/descontos/descontos.component';
import { CadastrarHorariosComponent } from 'src/cadastrar-horarios/cadastrar-horarios.component';
import { HorariosComponent } from 'src/horarios/horarios.component';
import { AlterarSenhaDeslogComponent } from 'src/alterar-senha-deslog/alterar-senha-deslog.component';
import { CodigoRecuperacaoComponent } from 'src/codigo-recuperacao/codigo-recuperacao.component';
import { CadastrarCupomComponent } from 'src/cadastrar-cupom/cadastrar-cupom.component';
import { DescontoTotalComponent } from 'src/desconto-total/desconto-total.component';

//IMPORTS PRIMENG
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { ToolbarModule } from 'primeng/toolbar';
import { SidebarModule } from 'primeng/sidebar';
import { PanelMenuModule } from 'primeng/panelmenu';
import { AvatarModule } from 'primeng/avatar';
import { MenuModule } from 'primeng/menu';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ToastModule } from 'primeng/toast';
import { PasswordModule } from 'primeng/password';
import { TabViewModule } from 'primeng/tabview';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { StepsModule } from 'primeng/steps';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputNumberModule } from 'primeng/inputnumber';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { FullCalendarModule } from 'primeng/fullcalendar';
import { MessagesModule } from 'primeng/messages';
import { FileUploadModule } from 'primeng/fileupload';
import { MultiSelectModule } from 'primeng/multiselect';
import { EditorModule } from 'primeng/editor';
import { ChipModule } from 'primeng/chip';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ChartModule } from 'primeng/chart';
import { CarouselModule  as OwlCarouselModule } from 'ngx-owl-carousel-o';
import { CarouselModule } from 'primeng/carousel';
import { RatingModule } from 'primeng/rating';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { InputSwitchModule } from 'primeng/inputswitch';
import { LandingPageComponent } from 'src/landing-page/landing-page.component';
import { FinanceiroComponent } from 'src/financeiro/financeiro.component';
import { AcessosComponent } from 'src/acessos/acessos.component';
import { PainelBarberComponent } from 'src/painel-barber/painel-barber.component';
import { CadastrarContaComponent } from 'src/cadastrar-conta/cadastrar-conta.component';
import { ConfiguracaoFinanceiraComponent } from 'src/configuracao-financeira/configuracao-financeira.component';
import { CategoriasComponent } from 'src/categorias/categorias.component';

import { PushNotificationsService } from 'ng-push'; //import the module
import { NgxMaskModule } from 'ngx-mask';
import { CadastrarProdutosComponent } from 'src/cadastrar-produtos/cadastrar-produtos.component';
import { ProdutosComponent } from 'src/produtos/produtos.component';
import { MeusResultadosComponent } from 'src/meus-resultados/meus-resultados.component';
import { TipoNegociacaoComponent } from 'src/tipo-negociacao/tipo-negociacao.component';
import { VendaDiretaComponent } from 'src/venda-direta/venda-direta.component';
import { PedidosComponent } from 'src/pedidos/pedidos.component';
import { AgendamentoSemContaComponent } from 'src/agendamento-sem-conta/agendamento-sem-conta.component';
import { ConfiguracaoBaixaComponent } from 'src/configuracao-baixa/configuracao-baixa.component';
import { CadastrarPlanoPacoteComponent } from 'src/cadastrar-plano-pacote/cadastrar-plano-pacote.component';
import { DetalhePlanoPacoteComponent } from 'src/detalhe-plano-pacote/detalhe-plano-pacote.component';


registerLocaleData(localePT);


export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    CadastroComponent,
    EsqueceuSenhaComponent,
    ContatoComponent,
    PerfilComponent,
    AlterarSenhaComponent,
    MinhaAgendaComponent,
    SobreComponent,
    AgendarComponent,
    BuscarEstabelecimentoComponent,
    MenuAdministrativoComponent,
    CadastrarEstabelecimentoComponent,
    EstabelecimentoComponent,
    CadastrarProfissionaisComponent,
    ProfissionalComponent,
    AgendaComponent,
    CadastrarServicosComponent,
    ServicoComponent,
    CadastrarHorariosComponent,
    HorariosComponent,
    AlterarSenhaDeslogComponent,
    FiliarEstabelecimentoComponent,
    CodigoRecuperacaoComponent,
    ClientesFiliadosComponent,
    RelatoriosComponent,
    CadastrarInformativosComponent,
    InformativosComponent,
    DetalheInformativoComponent,
    CadastrarPesquisaComponent,
    PesquisaComponent,
    CadastrarNotificacoesComponent,
    NotificacoesComponent,
    FaturasComponent,
    DetalheInformativoComponent,
    CadastrarComissaoComponent,
    ComissaoComponent,
    DetalheNotificacaoComponent,
    DescontosComponent,
    CadastrarCupomComponent,
    LandingPageComponent,
    DescontoTotalComponent,
    FinanceiroComponent,
    AcessosComponent,
    PainelBarberComponent,
    CadastrarContaComponent,
    ConfiguracaoFinanceiraComponent,
    CategoriasComponent,
    CadastrarProdutosComponent,
    ProdutosComponent,
    MeusResultadosComponent,
    TipoNegociacaoComponent,
    VendaDiretaComponent,
    PedidosComponent,
    AgendamentoSemContaComponent,
    ConfiguracaoBaixaComponent,
    CadastrarPlanoPacoteComponent,
    DetalhePlanoPacoteComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    LoginModule,
    CardModule,
    ButtonModule,
    InputTextModule,
    InputMaskModule,
    HomeModule,
    ToolbarModule,
    SidebarModule,
    PanelMenuModule,
    AvatarModule,
    MenuModule,
    ToastModule,
    PasswordModule,
    TabViewModule,
    CheckboxModule,
    DialogModule,
    StepsModule,
    CalendarModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    RadioButtonModule,
    FullCalendarModule,
    ConfirmDialogModule,
    InputTextareaModule,
    InputNumberModule,
    ProgressSpinnerModule,
    MessagesModule,
    FileUploadModule,
    MultiSelectModule,
    EditorModule,
    SelectButtonModule,
    ChartModule,
    CarouselModule,
    OwlCarouselModule,
    RatingModule,
    OverlayPanelModule,
    InputSwitchModule,
    NgxMaskModule.forRoot(),
    ChipModule

  ],
  providers: [{ provide: LOCALE_ID, useValue: 'pt-br' }, PushNotificationsService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
