import { Component, OnInit, ViewEncapsulation } from '@angular/core';
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class LandingPageComponent implements OnInit {
  resMobile: boolean = false;
  opcoesPlanos: any[];
  planoSelecionado: string = 'M';

  valorPlano1SemDesconto: string = "R$ 45,00/mês";
  valorPlano2SemDesconto: string = "R$ 60,00/mês";
  valorPlano3SemDesconto: string = "R$ 80,00/mês";

  valorPlano1Reais: string = "45,";
  valorPlano1Centavos: string = "00";
  valorPlano2Reais: string = "60,";
  valorPlano2Centavos: string = "00";
  valorPlano3Reais: string = "80,";
  valorPlano3Centavos: string = "00";

  msgPlano1: string = 'https://api.whatsapp.com/send?phone=556493352776&text=Ol%C3%A1,%20quero%20saber%20mais%20sobre%20o%20Plano%20Starter%20mensal%20do%20Barber%20Express!';
  msgPlano2: string = 'https://api.whatsapp.com/send?phone=556493352776&text=Ol%C3%A1,%20quero%20saber%20mais%20sobre%20o%20Plano%20Top%20mensal%20do%20Barber%20Express!';
  msgPlano3: string = 'https://api.whatsapp.com/send?phone=556493352776&text=Ol%C3%A1,%20quero%20saber%20mais%20sobre%20o%20Plano%20Master%20mensal%20do%20Barber%20Express!';


  constructor() {
    this.verificaResolucao();

    this.opcoesPlanos = [{ 'plano': 'M', 'nome': 'Mensal' },
    { 'plano': 'S', 'nome': 'Semestral' },
    ]

  }

  ngOnInit(): void {

  }

  verificaResolucao() {
    if (screen.width < 1024 || screen.height < 768) {
      this.resMobile = true;
    } else {
      this.resMobile = false;
    }
    window.addEventListener('resize', () => {
      if (screen.width < 1024 || screen.height < 768) {
        this.resMobile = true;
      } else {
        this.resMobile = false;
      }
    });
  }

  mudaValorPlanos() {
    if (this.planoSelecionado === 'M') {
      this.valorPlano1Reais = "45,";
      this.valorPlano1Centavos = "00";
      this.valorPlano2Reais = "60,";
      this.valorPlano2Centavos = "00";
      this.valorPlano3Reais = "80,";
      this.valorPlano3Centavos = "00";

      this.msgPlano1 = 'https://api.whatsapp.com/send?phone=556493352776&text=Ol%C3%A1,%20quero%20saber%20mais%20sobre%20o%20Plano%20Starter%20mensal%20do%20Barber%20Express!';
      this.msgPlano2 = 'https://api.whatsapp.com/send?phone=556493352776&text=Ol%C3%A1,%20quero%20saber%20mais%20sobre%20o%20Plano%20Top%20mensal%20do%20Barber%20Express!';
      this.msgPlano3 = 'https://api.whatsapp.com/send?phone=556493352776&text=Ol%C3%A1,%20quero%20saber%20mais%20sobre%20o%20Plano%20Master%20mensal%20do%20Barber%20Express!';

    } else {
      this.valorPlano1Reais = "42,";
      this.valorPlano1Centavos = "75";
      this.valorPlano2Reais = "57,";
      this.valorPlano2Centavos = "00";
      this.valorPlano3Reais = "76,";
      this.valorPlano3Centavos = "00";

      this.msgPlano1 = 'https://api.whatsapp.com/send?phone=556493352776&text=Ol%C3%A1,%20quero%20saber%20mais%20sobre%20o%20Plano%20Starter%20semestral%20do%20Barber%20Express!';
      this.msgPlano2 = 'https://api.whatsapp.com/send?phone=556493352776&text=Ol%C3%A1,%20quero%20saber%20mais%20sobre%20o%20Plano%20Top%20semestral%20do%20Barber%20Express!';
      this.msgPlano3 = 'https://api.whatsapp.com/send?phone=556493352776&text=Ol%C3%A1,%20quero%20saber%20mais%20sobre%20o%20Plano%20Master%20semestral%20do%20Barber%20Express!';
    }
  }


  mensal() {
    const btn = document.getElementById('btn') as HTMLButtonElement | null;

    if (btn) {
      btn.style.left = '0';
    }
    this.planoSelecionado = 'M';
    this.mudaValorPlanos();

  }

  semestral() {
    const btn = document.getElementById('btn') as HTMLButtonElement | null;

    if (btn) {
      btn.style.left = '147px';
    }
    this.planoSelecionado = 'S'
    this.mudaValorPlanos();

  }
}





