import { DiaSemana } from './../assets/diaSemana';
import { HorarioDias } from './../assets/horario';
import { HorarioService } from './../horario.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Profissional } from 'src/assets/profissional';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { Horario } from 'src/assets/horario';
import { ProfissionalService } from 'src/profissional.service';
import { MessageService } from 'primeng/api';
import { UtilitariosService } from 'src/assets/utilitarios.service';
import { AlertService } from 'src/alert.service';
import { TraducaoService } from 'src/traducao.service';
import { PermissoesService } from 'src/permissoes.service';

@Component({
  selector: 'app-horarios',
  templateUrl: './horarios.component.html',
  styleUrls: ['./horarios.component.scss'],
  providers: [MessageService],
  encapsulation: ViewEncapsulation.None
})
export class HorariosComponent implements OnInit {
  showHorarioProfissional: boolean = false;
  showDiaSemana: boolean = false;
  horario: Horario = {};
  //horarioDias: HorarioDias = {};
  horarioDias: HorarioDias[] = [];
  profissional: Profissional[] = [];
  profissionalSelecionado: any;

  /** VARIAVEIS DO COMPONENT */
  codusuresp: string = '';
  codcli: number = 0;
  codfunc: number = 0;
  nomehor: string = '';
  codhor: number = 0;
  exibeNomeHorario: string = '';
  diaDaSemana: DiaSemana = {}
  loading: boolean = false;

  /**VARIAVEIS DE PERMISSÕES */
  alterar: boolean = false;
  cadastrar: boolean = false;
  permissao: any;

  constructor(private profissionalService: ProfissionalService, private decodedTokenService: DecodedTokenService, private horarioService: HorarioService,
    private utilitariosService: UtilitariosService, private messageService: MessageService, private alertService: AlertService,
    private traducaoSerice: TraducaoService, private permissoesService: PermissoesService) {
    /**TRADUÇÃO */
    this.traducaoSerice.changeLang();
  }

  ngOnInit(): void {
    this.recuperaCodUsu();
    this.recuperaCodCli();
    this.recuperaHorario();
    this.recuperaPermissao();
    this.buscarProfissionalsPorCodCliente();
    this.recuperaDadosHorarioFuncionario();
    this.buscaHorarioDiaPorCodHor();


  }

  recuperaCodUsu() {
    this.codusuresp = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }

  recuperaCodCli() {
    this.codcli = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODCLI;
  }

  recuperaHorario() {
    this.horario = this.horarioService.getServico();
  }

  mostraDialogProfissional() {
    /**VERIFICA SE TEM PERMISSÃO PARA ESSA AÇÃO */
    if (!this.alterar && this.permissao != undefined && this.permissao.length > 0 && this.exibeNomeHorario != '' && this.exibeNomeHorario != undefined) {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
    this.showHorarioProfissional = true;
  }

  mostraDialogDiaSemana() {
    if (this.codhor != undefined && this.codhor != null && this.codhor != 0) {
      if (!this.alterar && this.permissao != undefined && this.permissao.length > 0 && this.exibeNomeHorario != '' && this.exibeNomeHorario != undefined) {
        this.showError('Atenção', 'Ação não permitida.')
        return
      }
      this.showDiaSemana = true;
    }
  }


  /**METODO QUE RECUPERA A LISTA DE BARBEIROS */
  buscarProfissionalsPorCodCliente() {
    this.profissionalService.buscarProfissionalporCodCli(this.codcli, this.codusuresp).subscribe((res) => {
      this.profissional = res;
    });
  }

  buscaHorarioDiaPorCodHor() {
    console.log(this.codhor + 'ESTOU ONDE QUERO')
    this.horarioService.buscarHorarioDiaPorCodHor(this.codhor, this.codusuresp).subscribe((res) => {
      this.horarioDias = res;
      for (let i = 0; i < this.horarioDias.length; i++) {
        if (this.horarioDias[i].diasemana === 'SEG') {
          this.diaDaSemana.seg1 = this.horarioDias[i].hor1!;
          this.diaDaSemana.seg2 = this.horarioDias[i].hor2!;
          this.diaDaSemana.seg3 = this.horarioDias[i].hor3!;
          this.diaDaSemana.seg4 = this.horarioDias[i].hor4!;
        }
        if (this.horarioDias[i].diasemana === 'TER') {
          this.diaDaSemana.ter1 = this.horarioDias[i].hor1!;
          this.diaDaSemana.ter2 = this.horarioDias[i].hor2!;
          this.diaDaSemana.ter3 = this.horarioDias[i].hor3!;
          this.diaDaSemana.ter4 = this.horarioDias[i].hor4!;
        }
        if (this.horarioDias[i].diasemana === 'QUA') {
          this.diaDaSemana.qua1 = this.horarioDias[i].hor1!;
          this.diaDaSemana.qua2 = this.horarioDias[i].hor2!;
          this.diaDaSemana.qua3 = this.horarioDias[i].hor3!;
          this.diaDaSemana.qua4 = this.horarioDias[i].hor4!;
        }
        if (this.horarioDias[i].diasemana === 'QUI') {
          this.diaDaSemana.qui1 = this.horarioDias[i].hor1!;
          this.diaDaSemana.qui2 = this.horarioDias[i].hor2!;
          this.diaDaSemana.qui3 = this.horarioDias[i].hor3!;
          this.diaDaSemana.qui4 = this.horarioDias[i].hor4!;
        }
        if (this.horarioDias[i].diasemana === 'SEX') {
          this.diaDaSemana.sex1 = this.horarioDias[i].hor1!;
          this.diaDaSemana.sex2 = this.horarioDias[i].hor2!;
          this.diaDaSemana.sex3 = this.horarioDias[i].hor3!;
          this.diaDaSemana.sex4 = this.horarioDias[i].hor4!;
        }
        if (this.horarioDias[i].diasemana === 'SAB') {
          this.diaDaSemana.sab1 = this.horarioDias[i].hor1!;
          this.diaDaSemana.sab2 = this.horarioDias[i].hor2!;
          this.diaDaSemana.sab3 = this.horarioDias[i].hor3!;
          this.diaDaSemana.sab4 = this.horarioDias[i].hor4!;
        }
        if (this.horarioDias[i].diasemana === 'DOM') {
          this.diaDaSemana.dom1 = this.horarioDias[i].hor1!;
          this.diaDaSemana.dom2 = this.horarioDias[i].hor2!;
          this.diaDaSemana.dom3 = this.horarioDias[i].hor3!;
          this.diaDaSemana.dom4 = this.horarioDias[i].hor4!;
        }
      }
    });
  }

  recuperaDadosHorarioFuncionario() {
    console.log(this.horarioDias)
    this.nomehor = this.horario.nomehor!;
    this.codfunc = this.horario.codfunc!;
    this.profissionalSelecionado = this.horario.codfunc!;
    this.exibeNomeHorario = this.nomehor;
    this.codhor = this.horario.codhor!;
  }

  /**METODO QUE CRIA/EDITA O HORARIO DO FUNCIONARIO*/
  salvarHorarioFuncionario() {
    this.loading = true;

    if (this.utilitariosService.validaCampo([this.nomehor])) {
      console.log('É necessário informar um nome de horário.')
      this.loading = false;
      this.showError('Atenção', 'É necessário informar um nome de horário.')
      return;
    }

    if (this.utilitariosService.validaCampo([this.profissionalSelecionado])) {
      this.loading = false;
      this.showError('Atenção', 'Selecione um profissional.')
      return;
    }

    this.horarioService.preencherHorarioFuncionario(this.codhor, this.nomehor, this.profissionalSelecionado, this.codusuresp).subscribe((res) => {
      this.codhor = Object(res).codhor;
      this.exibeNomeHorario = this.nomehor;
      this.loading = false;
      this.showHorarioProfissional = false;
      this.alertService.alert('Informações do Horário alteradas!', 'success');
    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
        this.loading = false;
        this.showHorarioProfissional = false;

        this.alertService.alert('Algo deu errado.', 'error');
        // this.showError('Atenção', 'Algo deu errado.')
      });
  }

  /**METODO QUE CRIA/EDITA O HORARIO DO DIA DA SEMANA*/
  salvarHorarioDiaSemana() {

    this.loading = true;


    // this.showDiaSemana = false;
    const horarioDia = {
      "codhor": this.codhor,
      "codusuresp": this.codusuresp,
      "seg1": this.diaDaSemana.seg1!, "seg2": this.diaDaSemana.seg2!, "seg3": this.diaDaSemana.seg3!, "seg4": this.diaDaSemana.seg4!,
      "ter1": this.diaDaSemana.ter1!, "ter2": this.diaDaSemana.ter2!, "ter3": this.diaDaSemana.ter3!, "ter4": this.diaDaSemana.ter4!,
      "qua1": this.diaDaSemana.qua1!, "qua2": this.diaDaSemana.qua2!, "qua3": this.diaDaSemana.qua3!, "qua4": this.diaDaSemana.qua4!,
      "qui1": this.diaDaSemana.qui1!, "qui2": this.diaDaSemana.qui2!, "qui3": this.diaDaSemana.qui3!, "qui4": this.diaDaSemana.qui4!,
      "sex1": this.diaDaSemana.sex1!, "sex2": this.diaDaSemana.sex2!, "sex3": this.diaDaSemana.sex3!, "sex4": this.diaDaSemana.sex4!,
      "sab1": this.diaDaSemana.sab1!, "sab2": this.diaDaSemana.sab2!, "sab3": this.diaDaSemana.sab3!, "sab4": this.diaDaSemana.sab4!,
      "dom1": this.diaDaSemana.dom1!, "dom2": this.diaDaSemana.dom2!, "dom3": this.diaDaSemana.dom3!, "dom4": this.diaDaSemana.dom4!,
    }
    console.log(horarioDia)
    const vetorHorario = [
      this.diaDaSemana.seg1, this.diaDaSemana.seg2, this.diaDaSemana.seg3, this.diaDaSemana.seg4,
      this.diaDaSemana.ter1, this.diaDaSemana.ter2, this.diaDaSemana.ter3, this.diaDaSemana.ter4,
      this.diaDaSemana.qua1, this.diaDaSemana.qua2, this.diaDaSemana.qua3, this.diaDaSemana.qua4,
      this.diaDaSemana.qui1, this.diaDaSemana.qui2, this.diaDaSemana.qui3, this.diaDaSemana.qui4,
      this.diaDaSemana.sex1, this.diaDaSemana.sex2, this.diaDaSemana.sex3, this.diaDaSemana.sex4,
      this.diaDaSemana.sab1, this.diaDaSemana.sab2, this.diaDaSemana.sab3, this.diaDaSemana.sab4,
      this.diaDaSemana.dom1, this.diaDaSemana.dom2, this.diaDaSemana.dom3, this.diaDaSemana.dom4
    ]

    console.log(this.utilitariosService.validaHoraFunc(vetorHorario, horarioDia));
    if (this.utilitariosService.validaHoraFunc(vetorHorario, horarioDia)) {
      this.showError('Atenção', 'É necessário preencher um horário válido.')
      this.loading = false;
      return;
    }
    console.log(horarioDia)
    this.horarioService.preencherHorarioDia(horarioDia, this.codusuresp).subscribe((res) => {
      this.codhor = Object(res).codhor;
      this.loading = false;
      this.showDiaSemana = false;
      this.alertService.alert('Configurações do Horário alteradas!', 'success');

    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
        this.loading = false;
        this.showDiaSemana = false;
        this.alertService.alert('Algo deu errado.', 'error');
        // this.showError('Atenção', 'Algo deu errado.')
      });
  }


  /**METODO DE TOAST */
  showError(titulo: string, mensagem: string) {
    if (screen.width < 640 || screen.height < 480) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else if (screen.width < 1024 || screen.height < 768) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else {
      this.messageService.add({
        key: 'tr',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    }
  }



  /**RECUPERA AS PERMISSÕES */
  recuperaPermissao() {
    console.log('recuperei as permissoes')
    this.permissao = this.permissoesService.getPermissao();
    console.log(this.permissao)
    if (this.permissao != undefined && this.permissao.length > 0) {
      if (this.permissao[3].acao[0].PERMITE === 'S') {
        this.cadastrar = true;
      }
      if (this.permissao[3].acao[1].PERMITE === 'S') {
        this.alterar = true;
      }

      /**ATRIBUI O CODCLI VINCULADO NA PERMISSAO DO FUNCIONARIO, PARA FAZER AS OPERAÇÕES DA TELA */
      this.codcli = this.permissao[0].codcli;
    }
  }

}
