import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { InformativoRecuperado } from 'src/assets/informativo';
import { environment } from 'src/environments/environment';
import { InformativoService } from 'src/informativo.service';

@Component({
  selector: 'app-detalhe-informativo',
  templateUrl: './detalhe-informativo.component.html',
  styleUrls: ['./detalhe-informativo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DetalheInformativoComponent implements OnInit {

  informativo: InformativoRecuperado = {};
  imagemNoticia: string = '';

  constructor(private informativoService: InformativoService, private router: Router) { }

  ngOnInit(): void {
    this.recuperaInformativo();
  }


  recuperaInformativo() {
    var htmlNotcia = document.querySelector('#htmlNotcia');
    this.informativo = this.informativoService.getInformativo();
    console.log(this.informativo)
    this.imagemNoticia = this.informativo.filename!;
    console.log(this.imagemNoticia)
    htmlNotcia!.innerHTML = this.informativo.noticia!;
  }


  clickVoltar(){
    this.router.navigate(['/']);
  }

}
