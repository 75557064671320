import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Estabelecimento } from 'src/assets/estabelecimento';
import { EstabelecimentoService } from 'src/estabelecimento.service';

@Injectable({
  providedIn: 'root'
})
export class MetricasGuard implements CanActivate {
  estabelecimento: Estabelecimento = {}
  
  constructor(private estabelecimentoService: EstabelecimentoService, private router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    this.estabelecimento = this.estabelecimentoService.getestabelecimento();

    if (!this.estabelecimento.codemp) {
      this.router.navigate([('/')]);
      return false;
    }
    return true;
  }

}
