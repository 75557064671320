<p-toast position="bottom-center" key="bc"></p-toast>
<p-toast position="top-right" key="tr"></p-toast>
<div class="pt-7">

    <div class="flex flex-column  pb-3">
        <div class="flex align-items-center justify-content-center">
            <img src="./assets/iconeCadastrarComissao.svg" />
        </div>
        <div class=" titulonegritoCadComissao flex align-items-center justify-content-center">
            <span>Comissões</span>
        </div>
    </div>

    <div class="flex flex-column  pb-3">
        <div class="flex justify-content-center flex-wrap ">

            <div class="scroll-divComissao">
                <table id="tabela">
                    <tr *ngFor="let comissao of comissao">
                        <td>
                            <div class="linhahorizontalCadastrarComissao pt-1"></div>
                            <div class="col-12 sm-12 md-4 ">
                                <div class="flex flex-column">

                                    <div
                                        class="flex align-items-center justify-content-center tamanhoLinhaTabelaComissao">
                                        <div class="col ">
                                            <div class="fontTituloCadComissao">
                                                <span class="xl:text-xl">Fechamento {{comissao.dtini
                                                    |date:'dd/MM/yyyy'}} - {{comissao.dtfin | date:'dd/MM/yyyy'}}</span>
                                                <img *ngIf="!comissao.fechado" src="./assets/bolinhaVerde.svg" class="pl-1" />
                                                <img *ngIf="comissao.fechado" src="./assets/bolinhaLaranja.svg" class="pl-1" />
                                            </div>
                                            <div class="formgroup-inline justify-content-between flex-wrap">
                                                <div class="fontApelido pb-2 ">
                                                    <div class="flex gap-2">
                                                        <div
                                                            class="flex align-items-center justify-content-center gap-1 ">
                                                            <img class="fontApelido" src="./assets/avatar.svg" />
                                                            <span class="xl:text-base">Profissional:
                                                                {{comissao.apelido}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex align-items-center justify-content-center ">
                                            <img class="cursor-pointer" src="./assets/iconeMenuAdm.png"
                                                (click)="menu.toggle($event); comissaoSelecionada(comissao)" />
                                            <p-menu #menu [popup]="true" [model]="itemsComissao"
                                                styleClass="menuCadComissao"></p-menu>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </td>
                    </tr>
                </table>

            </div>
            <p-confirmDialog #cd [closable]="false" id="confirmCadastraComissao">
                <ng-template pTemplate="footer">
                    <button type="button" pButton icon="pi pi-times" label="Retornar" (click)="cd.reject()"></button>
                    <button type="button" pButton icon="pi pi-check" label="Confirmar" (click)="cd.accept()"></button>
                </ng-template>
            </p-confirmDialog>



        </div>
        <div class="flex justify-content-center flex-wrap ">
            <div class="flex align-items-center justify-content-center">
                <footer class="xl:w-5" id="footerCadastrarComissao">
                    <p-button label="Cadastrar fechamento de comissão" id="botaoCadastrarComissao"
                         (click)="cadastrarComissao()" [style]="{width: '100%'}">
                    </p-button>
                </footer>
            </div>
        </div>
    </div>


</div>