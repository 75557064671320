import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { UsuarioService } from 'src/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class FuncionarioGuard implements CanActivate {

  codfunc: number = 0;
  codUsu: string = '';
  codcli: number = 0;

  constructor(private usuarioService: UsuarioService, private decodedTokenService: DecodedTokenService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): any {
    this.codUsu = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
    this.codcli = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODCLI;

    this.usuarioService.getPerfil(this.codUsu, this.codUsu).subscribe((res) => {
      this.codfunc = Object(res)[0].codfunc;
      
      if (this.codfunc > 0 || this.codcli > 0) {

        return true;
      } else {
        this.router.navigate([('/')]);
        return false;
      }
    })

 
  }

}
