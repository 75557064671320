<p-toast position="bottom-center" key="bc"></p-toast>
<p-toast position="top-right" key="tr"></p-toast>
<div class="pt-7">
    <div class=" flex justify-content-center flex-wrap">
        <div class="flex align-items-center justify-content-center">
            <div class="flex flex-column  pb-3">
                <div class="flex align-items-center justify-content-center">
                    <img src="./assets/iconeAlterarSenha.svg" />
                </div>
                <div class=" titulonegrito ">
                    <span> Alterar Senha</span>
                </div>
            </div>
        </div>
    </div>



    <div class="flex justify-content-center flex-wrap pb-2">
        <div class="col-10   xl:col-5">
            <div class="shadow-4">
                <p-card header="Preencha os dados abaixo" id="cardAlterarSenha" class="shadow-4 ">
                    <div class="flex justify-content-center flex-wrap">
                        <span class="fontCinzaAlterarSenha pb-2 xl:text-base">Informe a senha antiga e a nova
                            senha</span>

                        <input id="alterarSenha" type="text" pInputText type="password" placeholder="Senha Antiga"
                            [(ngModel)]="oldpassword" [style]="{width: '100%'}">
                        <input id="alterarSenha" type="text" pInputText type="password" placeholder="Nova Senha"
                            [(ngModel)]="newpassword1" [style]="{width: '100%'}" class="mt-1 mb-1">
                        <input id="alterarSenha" type="text" pInputText type="password"
                            placeholder="Confirmar Nova Senha" [(ngModel)]="newpassword2" [style]="{width: '100%'}"
                            class=" mb-3">

                    </div>



                    <div class="col-12">

                        <div class=" flex justify-content-between flex-wrap">
                            <div class="flex align-items-center justify-content-center">
                                <button id="botaoVoltar" pButton type="button" label="Voltar" class="col-12"
                                    (click)="clickVoltar()"></button>
                            </div>
                            <div class="flex align-items-center justify-content-center">
                                <div *ngIf="!loading" [style]="{width: '100%'}">
                                    <button id="botaoAlterarPassLog" pButton type="button" label="Salvar" class="col-12"
                                        (click)=" alterarSenha()"></button>
                                </div>
                                <div *ngIf="loading" [style]="{width: '100%'}">
                                    <button id="botaoLoadingAltPassLog" [disabled]="true" pButton type="button"
                                        label="" class="col-12"></button>
                                </div>
                            </div>

                        </div>
                    </div>


                </p-card>
            </div>
        </div>
    </div>
</div>