<p-toast position="bottom-center" key="bc"></p-toast>
<p-toast position="top-right" key="tr"></p-toast>
<div class="scroll-divRelatorios">
    <div class="flex align-items-center justify-content-center retanguloRelatorios">
        <span class="fontTituloRelatorios">Gestão {{nomeEstabelecimento}}</span>
    </div>


    <div class="pt-10">

        <!-- CARD DO DIA -->
        <div class="flex justify-content-center flex-wrap">
            <p-card id="cardDia">
                <div class="align-items-center justify-content-start">
                    <div class="flex align-items-center flex-wrap gap-2 ">
                        <div class="flex align-items-center justify-content-center">
                            <img src="./assets/iconeMenuRelatorios.svg" />
                        </div>
                        <div class="formgroup-inline justify-content-between flex-wrap tamanhoCard">
                            <div class="flex align-items-center justify-content-center">
                                <span class="fontRelatoriosDia">{{prefixoData}} {{this.dataMostrada | date :
                                    'mediumDate' :
                                    '' : 'pt-BR'}}</span>
                            </div>
                            <div class="flex align-items-center justify-content-center" (click)="mostraDialogAlterar()">
                                <span class="fontCinzaRelatorios cursor-pointer">Alterar</span>
                            </div>
                        </div>
                    </div>
                </div>
            </p-card>
        </div>

        <!-- DIALOG ALTERAR DIA -->
        <p-dialog header="Período" [(visible)]="showAlterar" [modal]="true" [style]="{'width': '350px'}"
            [closable]="true" [resizable]="false" id="dialogAlterar"
            [ngStyle]="{'height': valorSelecionadoAlterar == 'outroPeriodo' ? '340px' : '280px' }">
            <div class="p-field-radiobutton pb-1" (click)="clickOutroPeriodo()">
                <p-radioButton value="hoje" [(ngModel)]="valorSelecionadoAlterar"></p-radioButton>
                <label class="pl-2 fontCinzaPopUp">Hoje</label>
            </div>
            <div class="p-field-radiobutton pb-1" (click)="clickOutroPeriodo()">
                <p-radioButton value="ontem" [(ngModel)]="valorSelecionadoAlterar"></p-radioButton>
                <label class="pl-2 fontCinzaPopUp">Ontem</label>
            </div>
            <div class="p-field-radiobutton pb-1" (click)="clickOutroPeriodo()">
                <p-radioButton value="7Dias" [(ngModel)]="valorSelecionadoAlterar"></p-radioButton>
                <label class="pl-2 fontCinzaPopUp">Últimos 7 dias</label>
            </div>
            <div class="p-field-radiobutton pb-1" (click)="clickOutroPeriodo()">
                <p-radioButton value="15Dias" [(ngModel)]="valorSelecionadoAlterar"></p-radioButton>
                <label class="pl-2 fontCinzaPopUp">Últimos 15 dias</label>
            </div>
            <div class="p-field-radiobutton pb-1" (click)="clickOutroPeriodo()">
                <p-radioButton value="ultimoMes" [(ngModel)]="valorSelecionadoAlterar"></p-radioButton>
                <label class="pl-2 fontCinzaPopUp">Últimos mês</label>
            </div>
            <div class="p-field-radiobutton pb-1" (click)="clickOutroPeriodo()">
                <p-radioButton value="outroPeriodo" [(ngModel)]="valorSelecionadoAlterar"></p-radioButton>
                <label class="pl-2 fontCinzaPopUp">Outro período</label>
            </div>

            <div *ngIf="valorSelecionadoAlterar==='outroPeriodo'">
                <div class="formgrid grid">
                    <div class="field col">
                        <p-calendar [touchUI]="true" [readonlyInput]="false" inputId="touchui" dateFormat="dd/mm/yy"
                            [maxDate]="hoje" [readonlyInput]="true" id="calendarioRelatorios" [(ngModel)]="dataInicial"
                            placeholder="Data Inicial" [style]="{ width: '100%' }" [disabled]="liberaCalendario1"
                            (click)="clickCalendario1()">
                        </p-calendar>
                    </div>
                    <div class="field col">
                        <p-calendar [touchUI]="true" [readonlyInput]="false" inputId="touchui" dateFormat="dd/mm/yy"
                            [readonlyInput]="true" id="calendarioRelatorios" [(ngModel)]="dataFinal"
                            [minDate]="dataInicial" placeholder="Data Final" [style]="{ width: '100%' }"
                            [disabled]="liberaCalendario2">
                        </p-calendar>
                    </div>
                </div>
            </div>



            <div *ngIf="!loading" [style]="{width: '100%'}">
                <button pButton type="button" label="Confirmar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                'border-radius': '11px'}" (click)="escolhePeriodo()"></button>
            </div>
            <div *ngIf="loading" [style]="{width: '100%'}">
                <button id="botaoLoadingRelatorios" pButton [disabled]="true" type="button" label="Carregando"
                    class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                'border-radius': '11px'}"></button>
            </div>

        </p-dialog>







        <!-- CARDS DOS RELATORIOS -->
        <div class="pt-3 flex justify-content-center flex-wrap gap-3 ">
            <!-- CARD RECEITA -->
            <p-card id="cardRelatorios" class="">
                <div class="flex justify-content-center flex-wrap  gap-2">
                    <div class="flex align-items-center justify-content-center">
                        <img src="./assets/iconeReceita.svg" />
                    </div>
                    <div class="flex align-items-center justify-content-center">
                        <span class="fontTitulosCards ">Receita</span>
                    </div>
                </div>
                <div class="flex justify-content-center flex-wrap  gap-2 pt-3">
                    <div class="flex align-items-start flex-wrap  gap-1">
                        <div class="flex align-items-center justify-content-center">
                            <span class="fontTitulosCards">R$</span>
                        </div>
                        <div class="flex align-items-center justify-content-center">
                            <span class="fontValoresCards">{{receita| currency:'':''}}</span>
                        </div>
                    </div>
                </div>

            </p-card>




            <!-- CARD Qtd. de Agendamentos -->
            <p-card id="cardRelatorios">
                <div class="flex justify-content-center flex-wrap gap-2 mr-4">
                    <div class="flex align-items-center justify-content-center">
                        <img src="./assets/iconeQtdAgendamentos.svg" />
                    </div>

                    <div class="flex align-items-center justify-content-center">
                        <span class="fontTitulosCards voltaLinhaTexto text-center">Qtd. de Agendamentos</span>
                    </div>
                </div>
                <div class="flex align-items-center justify-content-center pt-1">
                    <span class="fontValoresCards">{{qtdAgendamentos}}</span>
                </div>
            </p-card>






            <!-- CARD POSITIVAÇÃO -->
            <p-card id="cardRelatorios">
                <div class="flex justify-content-center flex-wrap gap-2">
                    <div class="flex align-items-center justify-content-center">
                        <img src="./assets/iconePositivacao.svg" />
                    </div>
                    <div class="flex align-items-center justify-content-center">
                        <span class="fontTitulosCards">Positivação</span>
                    </div>
                </div>
                <div class="flex justify-content-center flex-wrap">
                    <div class="align-items-center justify-content-center">
                        <span class="fontItalicaCards">{{difCli}} / {{qtdClienteAtivos}}</span>
                    </div>
                </div>
                <div class="flex justify-content-center flex-wrap gap-2">
                    <div class="flex align-items-start flex-wrap gap-1">
                        <div class="flex align-items-center justify-content-center">
                            <span class="fontTitulosCards">%</span>
                        </div>
                        <div class="flex align-items-center justify-content-center">
                            <span class="fontValoresCards">{{positivacao}}</span>
                        </div>
                    </div>
                </div>
            </p-card>


            <!-- CARD TICKET MEDIO -->
            <p-card id="cardRelatorios">
                <div class="flex justify-content-center flex-wrap gap-2">
                    <div class="flex align-items-center justify-content-center">
                        <img src="./assets/iconeTicket.svg" />
                    </div>
                    <div class="flex align-items-center justify-content-center">
                        <span class="fontTitulosCards">Ticket Médio</span>
                    </div>
                </div>

                <div class="flex justify-content-center flex-wrap">
                    <div class="align-items-center justify-content-center">
                        <span class="fontItalicaCards">R$ {{receita| currency:'':''}}/ {{qtdAgendamentos}}</span>
                    </div>
                </div>

                <div class="flex justify-content-center flex-wrap">
                    <div class="flex align-items-start flex-wrap gap-1">
                        <div class="flex align-items-center justify-content-center">
                            <span class="fontTitulosCards">R$</span>
                        </div>
                        <div class="flex align-items-center justify-content-center">
                            <span class="fontValoresCards">{{ticketMedio| currency:'':''}}</span>
                        </div>
                    </div>
                </div>
            </p-card>


        </div>

        <!-- CARD DOS GRAFICOS TOP20 -->
        <div class="pt-3 flex justify-content-center flex-wrap ">
            <p-card id="cardGraficos">
                <span class="fontCardGrafico ml-6">Top 20 - Clientes</span>
                <div class="pt-2 flex justify-content-center flex-wrap tamanhoCardGraficos pb-1 ">
                    <p-selectButton [options]="opcoes" [(ngModel)]="opcaoSelecionada" optionLabel="nome"
                        optionValue="opcao" (onChange)="selecionaTipoBarras()"></p-selectButton>
                </div>
                <div class=" pb-1">
                    <div *ngIf="opcaoSelecionada === 'R'">
                        <div *ngIf="resMobile">
                            <p-chart type="bar" [data]="graficoBarra" [options]="optionBarra"></p-chart>
                        </div>
                        <div *ngIf="resPc">
                            <p-chart type="bar" [data]="graficoBarra" [options]="optionBarra" height="43vh"></p-chart>
                        </div>
                    </div>
                    <div *ngIf="opcaoSelecionada === 'Q'">
                        <div *ngIf="resMobile">
                            <p-chart type="bar" [data]="graficoBarra" [options]="optionBarra"></p-chart>
                        </div>
                        <div *ngIf="resPc">
                            <p-chart type="bar" [data]="graficoBarra" [options]="optionBarra" height="43vh"></p-chart>
                        </div>
                    </div>
                    <div *ngIf="opcaoSelecionada === 'T'">
                        <div *ngIf="resMobile">
                            <p-chart type="bar" [data]="graficoBarra" [options]="optionBarra"></p-chart>
                        </div>
                        <div *ngIf="resPc">
                            <p-chart type="bar" [data]="graficoBarra" [options]="optionBarra" height="43vh"></p-chart>
                        </div>
                    </div>
                </div>
                <div class="linhaHorizontalRelatorios "></div>
                <div class="col ">
                    <div class="formgroup-inline justify-content-between flex-wrap align-items-center">
                        <div class="flex justify-content-center flex-wrap">
                            <span class="fontCardGrafico">Mais detalhes: </span>
                        </div>
                        <!-- <img src="./assets/imgEstabelecimentoPopup.png" /> -->
                        <div class="flex justify-content-center flex-wrap p-fluid xl:col-4">
                            <button pButton id="botaoBaixaRelatorio" type="button" label="Baixar PDF"
                                (click)="geraRelatorioPDFAgd()"></button>
                        </div>
                        <div class="flex justify-content-center flex-wrap p-fluid xl:col-4">
                            <button pButton id="botaoBaixaRelatorio" type="button" label="Baixar Excel"
                                (click)="geraRelatorioExcelAgdPorCodemp()"></button>
                        </div>
                    </div>
                </div>
            </p-card>
        </div>
        <br>

        <!-- CARD DOS GRAFICOS TOP5 SERVIÇOS -->
        <div class="pt-3 flex justify-content-center flex-wrap">
            <p-card id="cardGraficos">
                <span class="fontCardGrafico ml-6">Top 5 - Serviços</span>
                <div class="pt-2 flex justify-content-center flex-wrap tamanhoCardGraficos pb-1">
                    <p-selectButton [options]="opcoesDonut" [(ngModel)]="opcaoSelecionadaDonut" optionLabel="nome"
                        optionValue="opcao" (onChange)="selecionaTipoDonut()"></p-selectButton>
                </div>
                <div class=" pb-1">
                    <div *ngIf="opcaoSelecionadaDonut === 'R'">
                        <div *ngIf="resMobile">
                            <p-chart type="doughnut" [data]="graficoDonut" [options]="optionDonut" width="323px"
                                height="163px"></p-chart>
                        </div>
                        <div *ngIf="resPc">
                            <div class="flex align-items-center justify-content-center">
                                <p-chart type="doughnut" [data]="graficoDonut" [options]="optionDonut" class="dounut"
                                    width="30vw" height="40vh"></p-chart>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="opcaoSelecionadaDonut === 'Q'">
                        <div *ngIf="resMobile">
                            <p-chart type="doughnut" [data]="graficoDonut" [options]="optionDonut" width="323px"
                                height="163px"></p-chart>
                        </div>
                        <div *ngIf="resPc">
                            <div class="flex align-items-center justify-content-center">
                                <p-chart type="doughnut" [data]="graficoDonut" [options]="optionDonut" class="dounut"
                                    width="30vw" height="40vh"></p-chart>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="opcaoSelecionadaDonut === 'T'">
                        <div *ngIf="resMobile">
                            <p-chart type="doughnut" [data]="graficoDonut" [options]="optionDonut" width="323px"
                                height="163px"></p-chart>
                        </div>
                        <div *ngIf="resPc">
                            <div class="flex align-items-center justify-content-center">
                                <p-chart type="doughnut" [data]="graficoDonut" [options]="optionDonut" class="dounut"
                                    width="30vw" height="40vh"></p-chart>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="linhaHorizontalRelatorios "></div>
                <div class="col ">
                    <div class="formgroup-inline justify-content-between flex-wrap align-items-center">
                        <div class="flex justify-content-center flex-wrap">
                            <span class="fontCardGrafico">Mais detalhes: </span>
                        </div>
                        <div class="flex justify-content-center flex-wrap p-fluid xl:col-4">
                            <button pButton id="botaoBaixaRelatorio" type="button" label="Baixar PDF"
                                (click)="geraRelatorioPDFServico()"></button>
                        </div>
                        <div class="flex justify-content-center flex-wrap p-fluid xl:col-4">
                            <button pButton id="botaoBaixaRelatorio" type="button" label="Baixar Excel"
                                (click)="geraRelatorioExcelServicoPorCodemp()"></button>
                        </div>
                    </div>
                </div>
            </p-card>
        </div>
        <br>


        <br>

        <!-- CARD DOS GRAFICOS COMPARATIVO DE OCORRENCIAS-->
        <div class="pt-3 flex justify-content-center flex-wrap">
            <p-card id="cardGraficos">
                <span class="fontCardGrafico ml-6">Comparativo de Ocorrências</span>
                <div class="pt-2 flex justify-content-center flex-wrap tamanhoCardGraficos pb-1">
                    <p-selectButton [options]="opcoesOcorrencia" [(ngModel)]="opcaoSelecionadaOcorrencia"
                        optionLabel="nome" optionValue="opcao" (onChange)="selecionaTipoOcorrencia()"></p-selectButton>
                </div>
                <!-- <div class=" ">
                    <p-chart type="pie" [data]="dataPizza" [options]="optionsPizza"></p-chart>
                </div> -->

                <div *ngIf="resMobile">
                    <p-chart type="pie" [data]="dataPizza" [options]="optionsPizza" width="323px"
                        height="163px"></p-chart>
                </div>
                <div *ngIf="resPc">
                    <div class="flex align-items-center justify-content-center">
                        <p-chart type="pie" [data]="dataPizza" [options]="optionsPizza" class="dounut" width="30vw"
                            height="40vh"></p-chart>
                    </div>
                </div>



                <div class="linhaHorizontalRelatorios "></div>
                <div class="col ">
                    <div class="formgroup-inline justify-content-between flex-wrap align-items-center">
                        <div class="flex justify-content-center flex-wrap">
                            <span class="fontCardGrafico">Mais detalhes: </span>
                        </div>
                        <!-- <img src="./assets/imgEstabelecimentoPopup.png" /> -->
                        <div class="flex justify-content-center flex-wrap p-fluid xl:col-4">
                            <button pButton id="botaoBaixaRelatorio" type="button" label="Baixar PDF"
                                (click)="geraRelatorioPDFComparativo()"></button>
                        </div>
                        <div class="flex justify-content-center flex-wrap p-fluid xl:col-4">
                            <button pButton id="botaoBaixaRelatorio" type="button" label="Baixar Excel"
                                (click)="geraRelatorioExcelComparativoPorCodemp()"></button>
                        </div>
                    </div>
                </div>
            </p-card>
        </div>
        <br>

        <!-- CARD DO FATURAMENTO POR TIPO NEGOCIACAO-->
        <div class="pt-3 flex justify-content-center flex-wrap ">
            <p-card id="cardGraficos">
                <span class="fontCardGrafico ml-6">Faturamento por Tipo de negociação</span>
                <div class="pt-2 flex justify-content-center flex-wrap tamanhoCardGraficos pb-1">
                    <p-selectButton [options]="opcoesTipNeg" [(ngModel)]="opcaoSelecionadaTipNeg"
                        optionLabel="nome" optionValue="opcao" (onChange)="selecionaTipoNegociacao()"></p-selectButton>
                </div>
                <div class=" pb-1">
                    <div *ngIf="resMobile">
                        <p-chart type="bar" [data]="graficoEmpilhado" [options]="optionEmpilhado"></p-chart>
                    </div>
                    <div *ngIf="resPc">
                        <p-chart type="bar" [data]="graficoEmpilhado" [options]="optionEmpilhado" height="43vh"></p-chart>
                    </div>
                </div>
                <div class="linhaHorizontalRelatorios "></div>
                <div class="col ">
                    <div class="formgroup-inline justify-content-between flex-wrap align-items-center">
                        <div class="flex justify-content-center flex-wrap">
                            <span class="fontCardGrafico">Mais detalhes: </span>
                        </div>
                        <!-- <img src="./assets/imgEstabelecimentoPopup.png" /> -->
                        <div class="flex justify-content-center flex-wrap p-fluid xl:col-4">
                            <button pButton id="botaoBaixaRelatorio" type="button" label="Baixar PDF"
                                (click)="geraRelatorioPDFAgd()"></button>
                        </div>
                        <div class="flex justify-content-center flex-wrap p-fluid xl:col-4">
                            <button pButton id="botaoBaixaRelatorio" type="button" label="Baixar Excel"
                                (click)="geraRelatorioExcelAgdPorCodemp()"></button>
                        </div>
                    </div>
                </div>
            </p-card>
        </div>
        <br>


        <!-- GRÁFICO DESEMPENHO POR PROFISSIONAL -->
        <div class="pt-3 flex justify-content-center flex-wrap ">
            <p-card id="cardGraficos">
                <span class="fontCardGrafico ml-6">Desempenho por profissional</span>

                <div class=" pb-1">
                    <div *ngIf="opcaoSelecionada === 'R'">
                        <div *ngIf="resMobile">
                            <p-chart type="bar" [data]="graficoBarraDeitado" [options]="optionBarraDeitado"></p-chart>
                        </div>
                        <div *ngIf="resPc">
                            <p-chart type="bar" [data]="graficoBarraDeitado" [options]="optionBarraDeitado"
                                height="43vh"></p-chart>
                        </div>
                    </div>
                    <div *ngIf="opcaoSelecionada === 'Q'">
                        <div *ngIf="resMobile">
                            <p-chart type="bar" [data]="graficoBarraDeitado" [options]="optionBarraDeitado"></p-chart>
                        </div>
                        <div *ngIf="resPc">
                            <p-chart type="bar" [data]="graficoBarraDeitado" [options]="optionBarraDeitado"
                                height="43vh"></p-chart>
                        </div>
                    </div>
                    <div *ngIf="opcaoSelecionada === 'T'">
                        <div *ngIf="resMobile">
                            <p-chart type="bar" [data]="graficoBarraDeitado" [options]="optionBarraDeitado"></p-chart>
                        </div>
                        <div *ngIf="resPc">
                            <p-chart type="bar" [data]="graficoBarraDeitado" [options]="optionBarraDeitado"
                                height="43vh"></p-chart>
                        </div>
                    </div>
                </div>
                <div class="linhaHorizontalRelatorios "></div>
                <div class="col ">
                    <div class="formgroup-inline justify-content-between flex-wrap align-items-center">
                        <div class="flex justify-content-center flex-wrap">
                            <span class="fontCardGrafico">Mais detalhes: </span>
                        </div>
                        <!-- <img src="./assets/imgEstabelecimentoPopup.png" /> -->
                        <div class="flex justify-content-center flex-wrap p-fluid xl:col-4">
                            <button pButton id="botaoBaixaRelatorio" type="button" label="Baixar PDF"
                                (click)="geraRelatorioPDFDesempenho()"></button>
                        </div>
                        <div class="flex justify-content-center flex-wrap p-fluid xl:col-4">
                            <button pButton id="botaoBaixaRelatorio" type="button" label="Baixar Excel"
                                (click)="geraRelatorioExcelDesempenho()"></button>
                        </div>
                    </div>
                </div>
            </p-card>
        </div>
        <br>

        <!-- CARD DOS GRAFICOS POR DIA DA SEMANA -->
        <div class="pt-3 flex justify-content-center flex-wrap ">
            <p-card id="cardGraficos">
                <span class="fontCardGrafico ml-6">Desempenho por dia da semana</span>

                <div class=" pb-1">
                    <div *ngIf="opcaoSelecionada === 'R'">
                        <div *ngIf="resMobile">
                            <p-chart type="bar" [data]="graficoBarraEmPe" [options]="optionBarraEmPe"></p-chart>
                        </div>
                        <div *ngIf="resPc">
                            <p-chart type="bar" [data]="graficoBarraEmPe" [options]="optionBarraEmPe"
                                height="43vh"></p-chart>
                        </div>
                    </div>
                    <div *ngIf="opcaoSelecionada === 'Q'">
                        <div *ngIf="resMobile">
                            <p-chart type="bar" [data]="graficoBarraEmPe" [options]="optionBarraEmPe"></p-chart>
                        </div>
                        <div *ngIf="resPc">
                            <p-chart type="bar" [data]="graficoBarraEmPe" [options]="optionBarraEmPe"
                                height="43vh"></p-chart>
                        </div>
                    </div>
                    <div *ngIf="opcaoSelecionada === 'T'">
                        <div *ngIf="resMobile">
                            <p-chart type="bar" [data]="graficoBarraEmPe" [options]="optionBarraEmPe"></p-chart>
                        </div>
                        <div *ngIf="resPc">
                            <p-chart type="bar" [data]="graficoBarraEmPe" [options]="optionBarraEmPe"
                                height="43vh"></p-chart>
                        </div>
                    </div>
                </div>
                <div class="linhaHorizontalRelatorios "></div>
                <div class="col ">
                    <div class="formgroup-inline justify-content-between flex-wrap align-items-center">
                        <div class="flex justify-content-center flex-wrap">
                            <span class="fontCardGrafico">Mais detalhes: </span>
                        </div>
                        <!-- <img src="./assets/imgEstabelecimentoPopup.png" /> -->
                        <div class="flex justify-content-center flex-wrap p-fluid xl:col-4">
                            <button pButton id="botaoBaixaRelatorio" type="button" label="Baixar PDF"></button>
                        </div>
                        <div class="flex justify-content-center flex-wrap p-fluid xl:col-4">
                            <button pButton id="botaoBaixaRelatorio" type="button" label="Baixar Excel"></button>
                        </div>
                    </div>
                </div>
            </p-card>
        </div>
        <br>
        <!--                          DIALOG DO CARREGANDO               -->
        <p-dialog id="dialogCarregandoRealatorios" [(visible)]="showCarregando" [modal]="true"
            [style]="{'width': '150px', 'height': '140px'}" [closable]="false">
            <div class="flex flex-column align-items-center mt-2">
                <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
                    fill="var(--surface-ground)" animationDuration="1s"></p-progressSpinner>
                <div class="fontCarregando">
                    <span>Carregando...</span>
                </div>
            </div>
        </p-dialog>





    </div>
</div>