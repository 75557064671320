<p-toast position="bottom-center" key="bc"></p-toast>
<p-toast position="top-right" key="tr"></p-toast>
<div class="pt-7">

    <div class="flex flex-column  pb-3">
        <div class="flex align-items-center justify-content-center">
            <img src="./assets/iconeCadastrarHorario.svg" class="iconeHorario" />
        </div>
        <div class=" titulonegritoCadHorario flex align-items-center justify-content-center">
            <span>Horários</span>
        </div>
    </div>

    <div class="flex flex-column  pb-3">
        <div class="flex justify-content-center flex-wrap ">

            <div class="scroll-divCadHorarios">
                <table id="tabela">
                    <tr *ngFor="let horario of horario">
                        <td>
                            <div class="linhahorizontalCadastrarHorarios pt-1"></div>
                            <div class="col-12 sm-12 md-4 ">
                                <div class="flex flex-column">
                                    <div class="flex align-items-center justify-content-center tamanhoLinhaTabela">
                                        <div class="col">
                                            <div class="fontTituloCadHorario">
                                                <span class="xl:text-xl">{{ horario.nomehor}}</span>
                                            </div>
                                        </div>
                                        <div class="flex align-items-center justify-content-center ">
                                            <img class="cursor-pointer" src="./assets/iconeMenuAdm.png"
                                                (click)="menu.toggle($event); horarioSelecionado(horario)" />
                                            <p-menu #menu [popup]="true" [model]="itemsHorarios"
                                                styleClass="menuCadHorarios"></p-menu>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <p-confirmDialog #cd [closable]="false" id="confirmCadastraHorarios">
            <ng-template pTemplate="footer">
                <button type="button" pButton icon="pi pi-times" label="Retornar" (click)="cd.reject()"></button>
                <button type="button" pButton icon="pi pi-check" label="Confirmar" (click)="cd.accept()"></button>
            </ng-template>
        </p-confirmDialog>

        <div class="flex justify-content-center flex-wrap ">
            <div class="flex align-items-center justify-content-center">
                <footer class="xl:w-5" id="footerCadastrarHorarios">
                    <p-button label="Cadastrar" id="botaoCadastrarHorario" (click)="cadastrarHorario()"
                        [style]="{width: '100%'}">
                    </p-button>
                </footer>
            </div>
        </div>


    </div>

</div>