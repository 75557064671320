import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, Observable, tap } from 'rxjs';
import { environment } from './environments/environment';
import { LoginService } from './login/login.service';

@Injectable({
  providedIn: 'root'
})
export class AgendaService {
  cabecalho: any;
  nuid: number = 0;

  constructor(private http: HttpClient, private loginService: LoginService) { }


  createAgenda(horini: string, horfin: string, dia: string, codfunc: any, codusu: any, codemp: any,
    vlrtot: number, status: string, notifica: string, itens: any, titulo: string, descricao: string, codusuresp: string, vlrdesc: number, nucup: any, plano: any): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    var agenda = {
      "horini": horini, "horfin": horfin, "dia": dia, "codfunc": codfunc, "codusu": codusu, "codemp": codemp,
      "vlrtot": vlrtot, "status": status, "notifica": notifica, "itens": itens, "titulo": titulo, "descricao": descricao, "codusuresp": codusuresp,
      "vlrdesc": vlrdesc, "nucup": nucup, "plano": plano
    }
    console.log(agenda)
    return this.http
      .post(`${environment.BASE_URL}/agenda/createagd`, agenda, { headers: headers })
      .pipe(
        first(),
        // delay(5000),
        tap((r) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  createAgendaDeslogado(horini: string, horfin: string, dia: string, codfunc: any, codusu: any, codemp: any,
    vlrtot: number, status: string, notifica: string, itens: any, titulo: string, descricao: string, codusuresp: any, vlrdesc: number, nucup: any, nome: string, telefone: string,
    email: any, cpf: any, codtipseg: number): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    var agenda = {
      "horini": horini, "horfin": horfin, "dia": dia, "codfunc": codfunc, "codusu": codusu, "codemp": codemp,
      "vlrtot": vlrtot, "status": status, "notifica": notifica, "itens": itens, "titulo": titulo, "descricao": descricao, "codusuresp": codusuresp,
      "vlrdesc": vlrdesc, "nucup": nucup, "nome": nome, "telefone": telefone, "email": email, "cpf": cpf, "codtipseg": codtipseg
    }
    console.log(agenda)
    return this.http
      .post(`${environment.BASE_URL}/agenda/createagddeslogado`, agenda)
      .pipe(
        first(),
        // delay(5000),
        tap((r) => console.log(r),
          (e: any) => {
            // this.loginService.verificaToken(e.status);
          })
      );
  }

  createAgendamentoEncaixe(horini: string, horfin: string, dia: string, codfunc: any, codusu: any, codemp: any,
    vlrtot: number, status: string, itens: any, titulo: string, descricao: string, obs: string, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    var agenda = {
      "horini": horini, "horfin": horfin, "dia": dia, "codfunc": codfunc, "codusu": codusu, "codemp": codemp,
      "vlrtot": vlrtot, "status": status, "itens": itens, "titulo": titulo, "descricao": descricao, "obs": obs, "codusuresp": codusuresp
    }

    return this.http
      .post(`${environment.BASE_URL}/agenda/createagdencaixe`, agenda, { headers: headers })
      .pipe(
        first(),
        // delay(5000),
        tap((r) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  createBloqueio(horini: string, horfin: string, dia: string, codfunc: any, codusu: any, codemp: any,
    status: string, titulo: string, descricao: string, obs: string, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    var agenda = {
      "horini": horini, "horfin": horfin, "dia": dia, "codfunc": codfunc, "codusu": codusu, "codemp": codemp,
      "status": status, "titulo": titulo, "descricao": descricao, "obs": obs, "codusuresp": codusuresp
    }

    return this.http
      .post(`${environment.BASE_URL}/agenda/createagdbloqueio`, agenda, { headers: headers })
      .pipe(
        first(),
        // delay(5000),
        tap((r) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  buscaEventosporCodCliCodFunc(codcli: number, codfunc: string, codemp: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    var agenda = { "codcli": Number(codcli), "codfunc": Number(codfunc), "codemp": codemp, "codusuresp": codusuresp }

    return this.http
      .post(`${environment.BASE_URL}/agenda/listeventsagd`, agenda, { headers: headers })
      .pipe(
        first(),
        // delay(5000),
        tap((r) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  listaAgendaUsuario(codusu: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    var agenda = { "codusu": codusu, "codusuresp": codusuresp }

    return this.http
      .post(`${environment.BASE_URL}/agenda/listagdusu`, agenda, { headers: headers })
      .pipe(
        first(),
        // delay(5000),
        tap((r) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  cancelarAgendamento(nuid: number, codusuresp: string, status: any): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    var agenda = { "nuid": nuid, "codusuresp": codusuresp, "status": status }

    return this.http
      .post(`${environment.BASE_URL}/agenda/cancelaragd`, agenda, { headers: headers })
      .pipe(
        first(),
        // delay(5000),
        tap((r) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  refazerAgenda(nuid: number, horini: string, horfin: string, dia: string, codfunc: any, codusu: any, codemp: any,
    vlrtot: number, status: string, notifica: string, itens: any, titulo: string, descricao: string, codusuresp: string, vlrdesc: number, nucup: any, plano: any): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    var agenda = {
      "nuid": nuid, "horini": horini, "horfin": horfin, "dia": dia, "codfunc": codfunc, "codusu": codusu, "codemp": codemp,
      "vlrtot": vlrtot, "status": status, "notifica": notifica, "itens": itens, "titulo": titulo, "descricao": descricao, "codusuresp": codusuresp,
      "vlrdesc": vlrdesc, "nucup": nucup, "plano": plano
    }

    return this.http
      .post(`${environment.BASE_URL}/agenda/refazeragd`, agenda, { headers: headers })
      .pipe(
        first(),
        // delay(5000),
        tap((r) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  encaixaServicoAgendamento(nuid: number, codprod: number, json: any, titulo: string, codusuresp: string, nupla: number, nuplac: number, seq: number): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    var agenda = {
      "nuid": nuid, "codprod": codprod, "json": json, "titulo": titulo, "codusuresp": codusuresp, "nupla": nupla,
      "nuplac": nuplac, "seq": seq
    }

    return this.http
      .post(`${environment.BASE_URL}/agenda/encaixaservicoagd`, agenda, { headers: headers })
      .pipe(
        first(),
        // delay(5000),
        tap((r) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  removeServicoAgendamento(nuid: number, codprod: number, json: any, titulo: string, codusuresp: string, nuplac: number, seq: number, nupla: number): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    var agenda = {
      "nuid": nuid, "codprod": codprod, "json": json, "titulo": titulo, "codusuresp": codusuresp,
      "nuplac": nuplac, "seq": seq, "nupla": nupla
    }

    return this.http
      .post(`${environment.BASE_URL}/agenda/removeservicoagd`, agenda, { headers: headers })
      .pipe(
        first(),
        // delay(5000),
        tap((r) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  buscaAgendaByNuid(nuid: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    var agenda = { "nuid": nuid, "codusuresp": codusuresp }

    return this.http
      .post(`${environment.BASE_URL}/agenda/listeventagdbynuid`, agenda, { headers: headers })
      .pipe(
        first(),
        // delay(5000),
        tap((r) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  registraLogAGD(dtserv: string, hragd: any, codusu: string, codusuresp: string, codemp: any, codfunc: any, codprods: any, minutos: number, fila: any): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    var logFila = {
      "dtserv": dtserv, "hragd": hragd, "codusu": codusu, "codusuresp": codusuresp, "codemp": codemp, "codfunc": codfunc,
      "codprods": codprods, "minutos": minutos, "fila": fila
    }

    return this.http
      .post(`${environment.BASE_URL}/agenda/registralogagd`, logFila, { headers: headers })
      .pipe(
        first(),
        // delay(5000),
        tap((r) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  public setCabAgenda(cabecalho: any) { //SETA O CABEÇALHO DE AGENDAMENTO NO VETOR
    this.cabecalho = cabecalho;
  }

  public getCabAgenda() { //RECUPERA O CABEÇALHO PARA REAGENDAMENTO
    return this.cabecalho;
  }



  public setNuid(nuid: number) { //SETA O NUID
    this.nuid = nuid;
  }

  public getNuid() { //RECUPERA O NUID DO AGENDAMENTO PARA SER RECUPERADO NA TELA FINANCEIRA
    return this.nuid;
  }

}


