import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AlertService } from 'src/alert.service';
import { Estabelecimento } from 'src/assets/estabelecimento';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { EstabelecimentoService } from 'src/estabelecimento.service';

@Component({
  selector: 'app-configuracao-baixa',
  templateUrl: './configuracao-baixa.component.html',
  styleUrls: ['./configuracao-baixa.component.scss'],
  providers: [MessageService, ConfirmationService],
  encapsulation: ViewEncapsulation.None
})
export class ConfiguracaoBaixaComponent implements OnInit {
  estabelecimento: Estabelecimento[] = [];

  /* variaveis do componente */
  codusuresp: string = '';
  codcli: number = 0;
  loading: boolean = false;
  acaoBaixa: any;


  constructor(private estabelecimentoService: EstabelecimentoService, private decodedTokenService: DecodedTokenService, private alertService: AlertService,
    private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    this.recuperaCodCli();
    this.recuperaCodUsu();
    this.buscarEstabelecimentosPorCodCliente();
  }

  /**RECUPERA O CODCLI UTILIZADO NA BUSCA DE BARBEARIAS */
  recuperaCodCli() {
    this.codcli = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODCLI;
  }

  /**RECUPERA O CODUSU UTILIZADO NA BUSCA DE BARBEARIAS */
  recuperaCodUsu() {
    this.codusuresp = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }

  /**RECUPERA AS BARBEARIAS DA REDE*/
  buscarEstabelecimentosPorCodCliente() {
    this.estabelecimento = [];
    // if (this.permissao != undefined && this.permissao.length > 0) {
    this.estabelecimentoService.buscarEstabelecimentosporCodCli(this.codcli, this.codusuresp).subscribe((res) => {
      console.log(res);
      this.estabelecimento = res;

      for (let i = 0; i < this.estabelecimento.length; i++) {
        if (this.estabelecimento[i].baixaagdaut === 'S') {
          this.estabelecimento[i].acaoBaixa = true;
        } else {
          this.estabelecimento[i].acaoBaixa = false;
        }
      }
    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
        this.alertService.alert('Algo deu errado.', 'error');
        //this.showError('Atenção', 'Algo deu errado.')
      });
    // } else {
    //   this.estabelecimentoService.buscarEstabelecimentosporCodCli(this.codcli, this.codusuresp).subscribe((res) => {
    //     console.log(res);
    //     this.estabelecimento = res;
    //   });
    // }
  }

  /**METODO QUE REALIZA A ATUALIZAÇÃO DO STATUS DE BAIXA DOS AGENDAMENTOS E VENDAS */
  atualizaBaixa() {
    this.loading = true;

    let contadorErros = 0;
    for (let i = 0; i < this.estabelecimento.length; i++) {
      let acao;

      if (this.estabelecimento[i].acaoBaixa === true) {
        acao = 'S';
      } else {
        acao = 'N';
      }

      this.estabelecimentoService.atualizaBaixaEstabelecimento(acao, this.estabelecimento[i].codemp!, this.codusuresp).subscribe((res) => {
        this.loading = false;
        console.log(res);
        // this.alertService.alert('Configurações de baixa atualizadas!', 'success');
      },
        (error) => {
          this.loading = false;
          console.log(error)
          console.log('deu ruim')
          //  this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')
          contadorErros++;
        });
    }

    /**SE TIVER ALGUM ERRO DURANTE A REQUISIÇÃO É PRINTADO O ALERTA */
    if (contadorErros > 0) {
      this.alertService.alert('Algo deu errado.', 'error');
    } else {
      this.alertService.alert('Configurações de baixa atualizadas!', 'success');
    }
  }


  /**METODO DE CONFIRMAÇÃO DE AÇÃO */
  confirmaExclusaoEstabelecimento() {
    this.confirmationService.confirm({
      message: `Agendamentos futuros já feitos não teram seus status de baixa alterados. <br> Ao realizar essa ação você está ciente que afetará diretamente o 
      funcionamento do financeiro de sua empresa.`,
      header: 'Atenção!',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.atualizaBaixa();
      },
      reject: () => {
      }
    });


  }

}
