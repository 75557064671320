import { PesquisaService } from './../pesquisa.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Pergunta, Pesquisa } from 'src/assets/pesquisa';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { UtilitariosService } from 'src/assets/utilitarios.service';
import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';
import { UsuarioService } from 'src/usuario.service';
import { AlertService } from 'src/alert.service';
import { EstabelecimentoService } from 'src/estabelecimento.service';
import { Estabelecimento } from 'src/assets/estabelecimento';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { TraducaoService } from 'src/traducao.service';
import { environment } from 'src/environments/environment';
import * as FileSaver from 'file-saver';
import { PermissoesService } from 'src/permissoes.service';

declare var cordova: any;

@Component({
  selector: 'app-pesquisa',
  templateUrl: './pesquisa.component.html',
  styleUrls: ['./pesquisa.component.scss'],
  providers: [MessageService, ConfirmationService],
  encapsulation: ViewEncapsulation.None
})
export class PesquisaComponent implements OnInit {
  showNome: boolean = false;
  showCadPer: boolean = false;
  showVigencia: boolean = false;
  showPerguntas: boolean = false;
  showRelatorio: boolean = false;
  mostraTabelaRelatorio: boolean = false;

  loading: boolean = false;

  /**DATA */
  data: Date;
  ontem: Date;
  dataInicial: any;
  dataFinal: any;
  dataInicialRel: any;
  dataFinalRel: any;

  /**PESQUISA */
  pesquisa: Pesquisa = {};
  nomePesquisa: string = '';
  pesquisaTitulo: string = '';
  todasEstabelecimento: any;
  teveRespostaEstabelecimento: boolean = false;
  estabelecimento: Estabelecimento[] = [];
  estabelecimentoSelecionada: any;



  /**PERGUNTA */
  //perguntas: Pergunta = {};
  perguntas: Pergunta[] = [];
  perg: string = ''
  tipoRespSelecionado: any;
  tipoPerSelecionado: any;
  tipoEspSelecionado: any;
  caracteresRestantes: number = 0;
  //perguntas: any[];
  perguntaSelecionadaExcluir: Pergunta[] = [];
  selecionouTodasPerguntasExcluir: Pergunta[] = [];
  perguntaSelecionadaEditar: Pergunta = {};
  tipoResp: any[] = [];
  tipoPer: any[] = [];
  tipoEsp: any[] = [];
  showVarEmp: boolean = false;
  showVarProfissional: boolean = false;
  showVarServico: boolean = false;
  varEmp: string = 'Variáveis disponíveis para a construção da pergunta: $BARBEARIA$, $NOMECLIENTE$, $DTATUAL$';
  varProfissional: string = 'Variáveis disponíveis para a construção da pergunta: $PROFISSIONAL$, $NOMECLIENTE$, $DTATUAL$';
  varServico: string = 'Variáveis disponíveis para a construção da pergunta: $SERVICOS$, $NOMECLIENTE$, $DTATUAL$';
  relatorioPesquisa: any[] = [];

  /**CODIGOS */
  codusuresp: string = '';
  codcli: number = 0;
  codemp: number = 0;
  nupes: number = 0;
  nuper: number = 0;

  indice: number = 0;

  /**TRADUCAO */
  lang: string = "en";
  //subscription: Subscription;

  /**VARIAVEIS DE PERMISSÕES */
  alterar: boolean = false;
  cadastrar: boolean = false;
  permissao: any;

  constructor(private decodedTokenService: DecodedTokenService, private pesquisaService: PesquisaService, private utilitariosService: UtilitariosService,
    private messageService: MessageService, private usuarioService: UsuarioService, private alertService: AlertService, private confirmationService: ConfirmationService,
    private estabelecimentoService: EstabelecimentoService, public translate: TranslateService, public primeNGConfig: PrimeNGConfig, private traducaoSerice: TraducaoService,
    private permissoesService: PermissoesService) {
    this.data = new Date();
    this.ontem = new Date(this.data);
    this.ontem.setDate(this.data.getDate());

    this.todasEstabelecimento = { "nome": "Todas Estabelecimentos", "codemp": 0 };

    this.tipoResp = [{ 'tipo': 'E', 'resposta': '5 Estrelas' }, { 'tipo': 'I', 'resposta': 'Nº Inteiro' }, { 'tipo': 'N', 'resposta': 'Nº Decimal' }, { 'tipo': 'T', 'resposta': 'Texto' }, { 'tipo': 'D', 'resposta': 'Data' }]
    this.tipoPer = [{ 'tipo': 'E', 'pergunta': 'Empresa' }, { 'tipo': 'P', 'pergunta': 'Profissional' }, { 'tipo': 'S', 'pergunta': 'Serviço' }]
    //this.tipoEsp = [ { 'tipo': 'N', 'especial': 'Nenhum' }, { 'tipo': 'R', 'especial': 'Review' }]
    this.tipoEsp = [{ 'tipo': 'N', 'especial': 'Nenhum' }]

    /**TRADUÇÃO */
    this.traducaoSerice.changeLang();
    // translate.addLangs(['en', 'pt']);
    // translate.setDefaultLang('pt');
    // const browserLang = translate.getBrowserLang();
    // let lang = browserLang!.match(/en|pt/) ? browserLang : 'pt';
    // this.changeLang(lang!);
    // this.subscription = this.translate.stream('primeng').subscribe(data => {
    //   this.primeNGConfig.setTranslation(data);
    // });

  }

  ngOnInit(): void {
    this.recuperaCodUsu();
    this.recuperaCodCli();
    this.recuperaPermissao();
    this.recuperaPesquisa();
    this.recuperaDadosPesquisa();
  }

  /**TRADUÇÃO */
  changeLang(lang: string) {
    this.translate.use(lang);
  }

  recuperaCodUsu() {
    this.codusuresp = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }

  recuperaCodCli() {
    this.codcli = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODCLI;
  }

  recuperaPesquisa() {
    this.pesquisa = this.pesquisaService.getPesquisa();
  }


  /**RECUPERA AS BARBEARIAS */
  buscarEstabelecimentosPorCodCliente() {
    this.teveRespostaEstabelecimento = true;
    this.estabelecimentoService.buscarEstabelecimentosporCodCli(this.codcli, this.codusuresp).subscribe((res) => {
      this.estabelecimento = res;
      this.estabelecimento.push(this.todasEstabelecimento);
      this.estabelecimento.sort(function (x, y) {
        return Number(x.codemp) - Number(y.codemp);
      })
      this.teveRespostaEstabelecimento = false;
    },
      (error) => {
        console.log(error.message)
        console.log('deu ruim')
        this.teveRespostaEstabelecimento = false;

      })
  }


  /**RECUPERA AS PERGUNTAS */
  recuperaPerguntas() {
    this.pesquisaService.buscarPerguntasPorNupes(this.nupes, this.codusuresp).subscribe((res) => {
      this.perguntas = res;
      console.log(this.perguntas)
      for (let i = 0; i < this.perguntas.length; i++) {
        if (this.perguntas[i].tiporesp === 'A') {
          this.perguntas[i].tipor = '5 Estrelas';
        }
        if (this.perguntas[i].tiporesp === 'B') {
          this.perguntas[i].tipor = '4 Estrelas';
        }
        if (this.perguntas[i].tiporesp === 'C') {
          this.perguntas[i].tipor = '3 Estrelas';
        }

        if (this.perguntas[i].tipopergunta === 'D') {
          this.perguntas[i].tipop = 'Empresa';
        }
        if (this.perguntas[i].tipopergunta === 'E') {
          this.perguntas[i].tipop = 'Profissional';
        }
        if (this.perguntas[i].tipopergunta === 'F') {
          this.perguntas[i].tipop = 'Serviço';
        }

        if (this.perguntas[i].tipopes === 'G') {
          this.perguntas[i].tipoe = 'Habilita Ranking';
        }
        if (this.perguntas[i].tipopes === 'H') {
          this.perguntas[i].tipoe = 'Nenhum';
        }
        if (this.perguntas[i].tipopes === 'I') {
          this.perguntas[i].tipoe = 'Review';
        }
      }
    })
  }

  /**RECUPERA OS DADOS DA PESQUISA */
  recuperaDadosPesquisa() {
    this.pesquisaTitulo = this.pesquisa.nome!;
    this.nomePesquisa = this.pesquisa.nome!;
    this.nupes = this.pesquisa.nupes!;
    this.estabelecimentoSelecionada = this.pesquisa.codemp!;
    if (this.pesquisa.dtvigini != null && this.pesquisa.dtvigfin != null) {
      this.dataInicial = new Date(this.pesquisa.dtvigini + 'GMT-0300'); //'GMT-0300' PARA FICAR NO FUSO HORÁRIO DO BRASIL (SE NÃO FIZER ISSO FICA 1 DIA ANTERIOR)
      this.dataFinal = new Date(this.pesquisa.dtvigfin + 'GMT-0300'); //'GMT-0300' PARA FICAR NO FUSO HORÁRIO DO BRASIL (SE NÃO FIZER ISSO FICA 1 DIA ANTERIOR)
      console.log(this.dataInicial)
      console.log(this.dataFinal)
    }
    this.recuperaPerguntas();
  }

  mostraDialogNome() {
    /**VERIFICA SE TEM PERMISSÃO PARA ESSA AÇÃO */
    if (!this.alterar && this.permissao != undefined && this.permissao.length > 0 && this.pesquisaTitulo != '' && this.pesquisaTitulo != undefined) {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
    this.showNome = true;
    this.buscarEstabelecimentosPorCodCliente();
  }

  mostraDialogVigencia() {
    /**VERIFICA SE TEM PERMISSÃO PARA ESSA AÇÃO */
    if (!this.alterar && this.permissao != undefined && this.permissao.length > 0) {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
    this.showVigencia = true;
  }

  mostraDialogPerguntas() {
    /**VERIFICA SE TEM PERMISSÃO PARA ESSA AÇÃO */
    if (!this.alterar && this.permissao != undefined && this.permissao.length > 0) {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
    this.showPerguntas = true;
  }

  mostraDialogRelatorio() {
    /**VERIFICA SE TEM PERMISSÃO PARA ESSA AÇÃO */
    if (!this.alterar && this.permissao != undefined && this.permissao.length > 0) {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
    this.showRelatorio = true;
  }


  clicouVoltar() {
    this.showPerguntas = false;
  }


  /**ABRE O DIALOG PARA CADASTRAR NOVA PERGUNTA */
  mostraDialogCadPer() {
    this.perguntaSelecionadaEditar = {};
    this.showCadPer = true;
    this.perg = '';
    this.tipoRespSelecionado = [];
    this.tipoPerSelecionado = [];
    this.tipoEspSelecionado = [];
    this.caracteresRestantes = 0;
  }

  /**ABRE O DIALOG PARA EDITAR UMA PERGUNTA */
  mostraDialogEdtPer() {
    this.showCadPer = true;

  }

  /**CRIA/EDITA A PESQUISA */
  salvaPesquisa() {
    this.loading = true;
    console.log(this.nupes)
    if (this.utilitariosService.validaCampo([this.nomePesquisa]) || this.estabelecimentoSelecionada === undefined) {
      console.log('É necessário informar todos os dados Pesquisa.')
      this.loading = false;
      this.showError('Atenção', 'É necessário informar todos os dados Pesquisa.')
      return;
    }
    console.log(this.estabelecimentoSelecionada)
    this.pesquisaService.preencherPesquisa(this.nupes, this.nomePesquisa, this.codcli, this.estabelecimentoSelecionada, this.codusuresp).subscribe((res) => {
      this.nupes = Object(res).nupes;
      this.pesquisaTitulo = this.nomePesquisa;
      this.alertService.alert('Nome da Pesquisa alterado!', 'success')
      this.showNome = false;
      this.loading = false;
    },
      (error) => {

        console.log('deu ruim')
        this.loading = false;
        this.showNome = false;
        this.alertService.alert('Algo deu errado.', 'error');

      });

  }

  /**CRIA/EDITA A DATA DE VIGENCIA */
  criaDataVigencia() {
    console.log(this.dataInicial)
    console.log(this.dataFinal)

    if (this.utilitariosService.validaCampo([this.dataInicial, this.dataFinal])) {
      console.log('É necessário informar as datas de vigência da notícia.')
      this.loading = false;
      this.showError('Atenção', 'É necessário informar as datas de vigência da notícia.')
      return;
    }

    /**DEIXA AS DATAS NO PADRÃO QUE O BANCO ESPERA: YYYY-MM-DD */
    // let auxInicial = this.dataInicial.getFullYear() + '-' + ('0' + (this.dataInicial.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataInicial.getDate()).slice(-2);
    // let auxFinal = this.dataFinal.getFullYear() + '-' + ('0' + (this.dataFinal.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataFinal.getDate()).slice(-2);

    let auxInicial = this.utilitariosService.ajustaDataBanco(this.dataInicial);
    let auxFinal = this.utilitariosService.ajustaDataBanco(this.dataFinal);
    
    console.log(auxInicial)
    console.log(auxFinal)

    this.pesquisaService.preencheVigencia(this.nupes, auxInicial, auxFinal, this.codusuresp).subscribe((res) => {
      this.alertService.alert('Data de Vigência alterada!', 'success')
      this.loading = false;
      this.showVigencia = false;
      console.log(res)
    },
      (error) => {
        console.log('deu ruim')
        this.loading = false;
        this.showVigencia = false;
        this.alertService.alert('Algo deu errado.', 'error');
      });
  }

  /**CRIA/EDIATA A PERGUNTA */
  salvaPergunta() {
    this.loading = true;
    if (this.perguntaSelecionadaEditar.nuper) {
      console.log(this.perg)
      if (this.utilitariosService.validaCampo([this.perg, this.tipoRespSelecionado, this.tipoPerSelecionado, this.tipoEspSelecionado])) {
        console.log(this.perg)
        console.log('É necessário informar todos os campos para sua Pergunta.')
        this.loading = false;
        this.showError('Atenção', 'É necessário informar todos os campos para sua Pergunta.')
        return;
      }

      this.pesquisaService.preencherPergunta(this.perguntaSelecionadaEditar.nuper, this.perg, this.tipoRespSelecionado, this.nupes, this.tipoEspSelecionado, this.tipoPerSelecionado, this.codusuresp).subscribe((res) => {
        console.log(res);
        this.nuper = Object(res).nuper;
        this.alertService.alert('Pergunta alterada!', 'success')
        this.showCadPer = false;
        this.loading = false;
        this.recuperaPerguntas();
      },
        (error) => {
          console.log('deu ruim')
          this.loading = false;
          this.showVigencia = false;
          this.alertService.alert('Algo deu errado.', 'error');
        })
    } else {
      // if (this.utilitariosService.validaCampo([this.perg, this.tipoRespSelecionado, this.tipoPerSelecionado, this.tipoEspSelecionado])) {
      //   console.log('É necessário informar todos os campos para sua Pergunta.')
      //   this.loading = false;
      //   this.showError('Atenção', 'É necessário informar todos os campos para sua Pergunta.')
      //   return;
      // }

      this.pesquisaService.preencherPergunta(null, this.perg, this.tipoRespSelecionado, this.nupes, this.tipoEspSelecionado, this.tipoPerSelecionado, this.codusuresp).subscribe((res) => {
        console.log(res);
        this.nuper = Object(res).nuper;
        this.alertService.alert('Pergunta cadastrada!', 'success')
        this.showCadPer = false;
        this.loading = false;
        this.recuperaPerguntas();
      },
        (error) => {
          console.log('deu ruim')
          this.loading = false;
          this.showVigencia = false;
          this.alertService.alert('Algo deu errado.', 'error');
        })
    }


  }

  exportExcel() {
    var string = JSON.stringify(this.relatorioPesquisa);
    string = string.replace(/\"nomepesquisa\":/g, "\"Pesquisa\":");
    string = string.replace(/\"pergunta\":/g, "\"Pergunta\":");
    string = string.replace(/\"resposta\":/g, "\"Resposta\":");
    string = string.replace(/\"dtresposta\":/g, "\"Data da Resposta\":");
    string = string.replace(/\"nomeusu\":/g, "\"Usuário\":");
    var relatorioExcel = JSON.parse(string);
    for (let i = 0; i < relatorioExcel.length; i++) {
      delete relatorioExcel[i]["codentrevistado"]
    }
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(relatorioExcel);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.salvaExcel(excelBuffer, 'RelatórioPesquisa');
    });
  }

  salvaExcel(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }


  /**METODO DE EXCLUSÃO DE UMA PERGUNTA */
  excluiPerguntaPorNuper() {

    /**VERIFICA SE A PESSOA QUER EXCLUIR TODAS AS PERGUNTAS  */
    if (this.perguntas.length != this.perguntaSelecionadaExcluir.length) {
      for (let i = 0; i < this.perguntaSelecionadaExcluir.length; i++) {
        this.pesquisaService.excluiPerguntaPorNuper(this.perguntaSelecionadaExcluir[i].nuper!, this.codusuresp).subscribe((res) => {
          console.log(res);
          /**FILTRA PARA RETIRAR A PERGUNTA QUE FOI EXCLUIDO */
          this.perguntas = this.perguntas.filter((x: any) => x.nuper != res.nuper);
          console.log(this.perguntas)
          this.perguntaSelecionadaExcluir = [];
        },
          (error) => {
            console.log('deu ruim')
            this.loading = false;
            this.alertService.alert('Algo deu errado.', 'error');
          })
      }
    } else {
      this.pesquisaService.excluiTodasPergunta(this.nupes, this.codusuresp).subscribe((res) => {
        console.log(res);
        this.perguntas = [];
        this.selecionouTodasPerguntasExcluir = [];
      },
        (error) => {
          console.log('deu ruim')
          this.loading = false;
          this.alertService.alert('Algo deu errado.', 'error');
        })
    }
  }

  /**SELECIONOU A PERGUNTA PARA EXCLUSÃO (CHECKBOX) */
  selecionouPergExclusao() {
    console.log(this.perguntaSelecionadaExcluir)
  }

  /**SELECIONOU A PERGUNTA PARA EDIÇÃO (LAPIZINHO) */
  selecionouPergEdicao(pergunta: Pergunta) {
    console.log(pergunta)
    this.perguntaSelecionadaEditar = pergunta;
    this.perg = pergunta.pergunta!;
    this.tipoRespSelecionado = pergunta.tiporesp;
    this.tipoPerSelecionado = pergunta.tipopergunta;
    this.tipoEspSelecionado = pergunta.tipopes;
    this.mostraDialogEdtPer();
    this.contaCaracter();
  }

  /**CONFIRMA EXCLUSÃO DE UMA PERGUNTA */
  confirmaExclusaoPergunta() {
    /**VERIFICA SE FOI SELECIONADO ALGUMA PERGUNTA PARA EXCLUIR E ABRIR O POPUP*/
    if (this.perguntaSelecionadaExcluir.length) {

      /**VERIFICA SE A PESSOA QUER EXCLUIR TODAS AS PERGUNTAS  */
      if (this.perguntas.length != this.perguntaSelecionadaExcluir.length) {
        let auxNome = '';

        for (let i = 0; i < this.perguntaSelecionadaExcluir.length; i++) {
          if (i === 0) {
            auxNome = this.perguntaSelecionadaExcluir[i].pergunta!;
          } else {

            auxNome = auxNome + ', ' + this.perguntaSelecionadaExcluir[i].pergunta;
          }
        }

        this.confirmationService.confirm({
          message: 'Deseja mesmo excluir ' + auxNome + ' ?',
          header: 'Excluir Pergunta',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.excluiPerguntaPorNuper();
          },
          reject: () => {
          }
        });
      } else {
        this.confirmationService.confirm({
          message: 'Deseja mesmo excluir todas as perguntas ?',
          header: 'Excluir Pergunta',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.excluiPerguntaPorNuper();
          },
          reject: () => {
          }
        });
      }
    }
  }


  /**METODO PARA CONTAR OS CARACTERES DA PERGUNTA */
  contaCaracter() {
    if (this.caracteresRestantes < 151) {
      this.caracteresRestantes = this.perg.length;
    } else {
      return;
    }
  }


  /** METODO PARA FAZER A BUSCA DE PERGUNTAS */
  filtraPerguntas() {
    let filter = (document.getElementById('campoPesquisa') as HTMLInputElement)!.value.toUpperCase();
    let myTable = document.getElementById('tabela');
    let tr = myTable?.getElementsByTagName('tr');
    //console.log(tr)
    for (let i = 0; i < tr!.length; i++) {
      let td = tr![i].getElementsByTagName('td')[2];
      if (td) {
        let textv = td.textContent || td.innerHTML;
        console.log(textv)
        if (textv.toUpperCase().indexOf(filter) > -1) {
          console.log(textv)
          tr![i].style.display = "";
        } else {
          tr![i].style.display = "none";
        }
      }
    }
  }


  clicouCheckbox() {
    this.perguntaSelecionadaExcluir = [];
    console.log(JSON.stringify(this.selecionouTodasPerguntasExcluir));
    console.log(this.selecionouTodasPerguntasExcluir)
    let auxTodasPerguntas = JSON.stringify(this.selecionouTodasPerguntasExcluir)
    auxTodasPerguntas = auxTodasPerguntas.substring(0, auxTodasPerguntas.length - 1);
    auxTodasPerguntas = auxTodasPerguntas.substring(1);
    auxTodasPerguntas = JSON.parse(auxTodasPerguntas);
    console.log(Object(auxTodasPerguntas[0]).nuper)
    console.log(this.perguntas)
    for (let i = 0; i < this.perguntas.length; i++) {
      for (let j = 0; j < auxTodasPerguntas.length; j++) {
        if (Object(auxTodasPerguntas[j]).nuper === this.perguntas[i].nuper) {
          this.perguntaSelecionadaExcluir.push(this.perguntas[i]);
        }
      }
    }
    console.log(this.perguntaSelecionadaExcluir)
    console.log(this.perguntaSelecionadaExcluir.length + ' checkbox')
    console.log(this.perguntas.length + ' vetor de pergunta')
  }

  /**MOSTRA AS INFORMAÇÕES DE VARIAVIES A SER USADAS, DE ACORDO COM O TIPO PERGUNTA SELECIONADO*/
  showVariaveis() {
    if (this.tipoPerSelecionado === 'E') {
      this.showVarEmp = true;
      this.tipoEsp = [{ 'tipo': 'N', 'especial': 'Nenhum' }, { 'tipo': 'H', 'especial': 'Habilita Ranking' }]
    } else {
      this.tipoEsp = [{ 'tipo': 'N', 'especial': 'Nenhum' }]
      this.showVarEmp = false;
    }
    if (this.tipoPerSelecionado === 'P') {
      this.showVarProfissional = true;
    } else {
      this.showVarProfissional = false;
    }
    if (this.tipoPerSelecionado === 'S') {
      this.showVarServico = true;
    } else {
      this.showVarServico = false;
    }
  }


  geraTabelaRelatorioPesquisa() {
    this.loading = true;

    if (this.utilitariosService.validaCampo([this.dataInicialRel, this.dataFinalRel])) {
      console.log('É necessário informar as datas de vigência da notícia.')
      this.loading = false;
      this.showError('Atenção', 'É necessário informar as datas de vigência da notícia.')
      return;
    }

    /**DEIXA AS DATAS NO PADRÃO QUE O BANCO ESPERA: YYYY-MM-DD */
    // let auxInicial = this.dataInicialRel.getFullYear() + '-' + ('0' + (this.dataInicialRel.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataInicialRel.getDate()).slice(-2);
    // let auxFinal = this.dataFinalRel.getFullYear() + '-' + ('0' + (this.dataFinalRel.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataFinalRel.getDate()).slice(-2);
    let auxInicial = this.utilitariosService.ajustaDataBanco(this.dataInicialRel);
    let auxFinal = this.utilitariosService.ajustaDataBanco(this.dataFinalRel);
    

    this.pesquisaService.tabelaRelatorioPorNupes(this.nupes, auxInicial, auxFinal, this.codusuresp).subscribe((res) => {
      console.log(res)
      this.relatorioPesquisa = res;
      this.loading = false;
      this.mostraTabelaRelatorio = true;
    },
      (error) => {
        console.log('deu ruim')
        this.loading = false;
        this.showRelatorio = false;
        this.alertService.alert('Algo deu errado.', 'error');
      });
  }

  geraRelatorioExcelPesquisa() {
    this.loading = true;

    if (this.utilitariosService.validaCampo([this.dataInicialRel, this.dataFinalRel])) {
      console.log('É necessário informar as datas de vigência da notícia.')
      this.loading = false;
      this.showError('Atenção', 'É necessário informar as datas de vigência da notícia.')
      return;
    }

    /**DEIXA AS DATAS NO PADRÃO QUE O BANCO ESPERA: YYYY-MM-DD */
    // let auxInicial = this.dataInicialRel.getFullYear() + '-' + ('0' + (this.dataInicialRel.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataInicialRel.getDate()).slice(-2);
    // let auxFinal = this.dataFinalRel.getFullYear() + '-' + ('0' + (this.dataFinalRel.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataFinalRel.getDate()).slice(-2);
    let auxInicial = this.utilitariosService.ajustaDataBanco(this.dataInicialRel);
    let auxFinal = this.utilitariosService.ajustaDataBanco(this.dataFinalRel);


    /**CHECA SE ESTÁ NA VERSÃO MOBILE */
    if (environment.MOBILE) {
      this.pesquisaService.geraRelatorioExcelPorNupes(this.nupes, auxInicial, auxFinal, this.codusuresp).subscribe((res) => {
        let url = environment.urlRelatorio + res[0]
        cordova.InAppBrowser.open(url, '_system');
      },
        (error) => {
          console.log('deu ruim')
          this.alertService.alert('Algo deu errado.', 'error');
        })
    } else {
      this.pesquisaService.geraRelatorioExcelPorNupes(this.nupes, auxInicial, auxFinal, this.codusuresp).subscribe((res) => {
        console.log(res)

        // var blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        //FileSaver.saveAs(blob);
        // var blobURL = URL.createObjectURL(blob);
        // console.log(blobURL)
        let url = environment.urlRelatorio + res[0];
        window.open(url);

        // this.relatorioPesquisa = res;
        this.loading = false;
        this.mostraTabelaRelatorio = true;
      },
        (error) => {
          console.log('deu ruim')
          this.loading = false;
          this.showRelatorio = false;
          this.alertService.alert('Algo deu errado.', 'error');
        });
    }
  }


  geraRelatorioPesquisa() {
    this.loading = true;

    if (this.utilitariosService.validaCampo([this.dataInicialRel, this.dataFinalRel])) {
      console.log('É necessário informar as datas de vigência da notícia.')
      this.loading = false;
      this.showError('Atenção', 'É necessário informar as datas de vigência da notícia.')
      return;
    }

    /**DEIXA AS DATAS NO PADRÃO QUE O BANCO ESPERA: YYYY-MM-DD */
    // let auxInicial = this.dataInicialRel.getFullYear() + '-' + ('0' + (this.dataInicialRel.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataInicialRel.getDate()).slice(-2);
    // let auxFinal = this.dataFinalRel.getFullYear() + '-' + ('0' + (this.dataFinalRel.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataFinalRel.getDate()).slice(-2);
    let auxInicial = this.utilitariosService.ajustaDataBanco(this.dataInicialRel);
    let auxFinal = this.utilitariosService.ajustaDataBanco(this.dataFinalRel);


    console.log(auxInicial)
    console.log(auxFinal)

    /**CHEGA SE ESTÁ NA VERSÃO MOBILE */
    if (environment.MOBILE) {
      this.pesquisaService.geraRelatorioUrlPorNupes(this.nupes, auxInicial, auxFinal, this.codusuresp).subscribe((res) => {
        console.log(res)
        // this.relatorioPesquisa = res;
        this.loading = false;
        // this.mostraTabelaRelatorio = true;
        let url = environment.urlRelatorio + res[0]
        cordova.InAppBrowser.open(url, '_system');
        console.log(url)
        // window.open(url)
      },
        (error) => {
          console.log('deu ruim')
          this.loading = false;
          this.showRelatorio = false;
          this.alertService.alert('Algo deu errado.', 'error');
        });
    }
    else {
      // this.pesquisaService.geraRelatorioPorNupes(this.nupes, auxInicial, auxFinal, this.codusuresp).subscribe((res) => {
      //   console.log(res)
      //   // this.relatorioPesquisa = res;
      //   this.loading = false;
      //   // this.mostraTabelaRelatorio = true;
      //   var blob = new Blob([res], { type: 'application/pdf' });
      //   var blobURL = URL.createObjectURL(blob);
      //   console.log(blobURL)
      //   window.open(blobURL);
      // },
      //   (error) => {
      //     console.log('deu ruim')
      //     this.loading = false;
      //     this.showRelatorio = false;
      //     this.alertService.alert('Algo deu errado.', 'error');
      //   });

      this.pesquisaService.geraRelatorioUrlPorNupes(this.nupes, auxInicial, auxFinal, this.codusuresp).subscribe((res) => {
        console.log(res)
        this.loading = false;
        let url = environment.urlRelatorio + res[0]
        window.open(url)
      },
        (error) => {
          console.log('deu ruim')
          this.loading = false;
          this.showRelatorio = false;
          this.alertService.alert('Algo deu errado.', 'error');
        });

    }

  }

  escolheData() {
    this.mostraTabelaRelatorio = false;
  }

  /**METODO DE TOAST */
  showError(titulo: string, mensagem: string) {
    if (screen.width < 640 || screen.height < 480) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else if (screen.width < 1024 || screen.height < 768) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else {
      this.messageService.add({
        key: 'tr',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    }
  }

  /**RECUPERA AS PERMISSÕES */
  recuperaPermissao() {
    console.log('recuperei as permissoes')
    this.permissao = this.permissoesService.getPermissao();
    console.log(this.permissao)
    if (this.permissao != undefined && this.permissao.length > 0) {
      if (this.permissao[5].acao[0].PERMITE === 'S') {
        this.cadastrar = true;
      }
      if (this.permissao[5].acao[1].PERMITE === 'S') {
        this.alterar = true;
      }

      /**ATRIBUI O CODCLI VINCULADO NA PERMISSAO DO FUNCIONARIO, PARA FAZER AS OPERAÇÕES DA TELA */
      this.codcli = this.permissao[0].codcli;
    }
  }
}
