import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './environments/environment';
import { Observable, first, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WhatsappService {

  constructor(private http: HttpClient) { }

  criaSessao(nome: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const nomewpp = { "nome": nome }

    return this.http
      .post<any>(`${environment.BASE_URL}/whatsapp/create-session`, nomewpp, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r))
      );
  }

  buscaQRCode(nome: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const nomewpp = { "nome": nome }

    return this.http
      .post<any>(`${environment.BASE_URL}/whatsapp/search-qr`, nomewpp, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r))
      );
  }

}
