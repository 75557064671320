<p-toast position="bottom-center" key="bc"></p-toast>

<body id="contato">


    <div class="pt-7">
        <div class=" flex justify-content-center flex-wrap">
            <div class="flex align-items-center justify-content-center">
                <div class="flex flex-column  pb-3">
                    <div class="flex align-items-center justify-content-center">
                        <img src="./assets/iconeWpp.svg" class="iconeContato" />
                    </div>
                    <div class=" fontNomeEstabelecimentoContato flex align-items-center justify-content-center">
                        <span> Contato</span>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class="linhahorizontalContato2 "></div>


 
        <!-- <div class="paddingContato"> -->
        <div class=" flex justify-content-center flex-wrap">

            <div class="col-10 sm-8 md-6 xl:col-5 lg:col-5 md:col-5">


                <div class="flex justify-content-center flex-wrap ">
                    <img [src]="filename" class="imgRedondaContato  " />
                </div>

                <div class="shadow-4">
                    <p-card id="cardContato" class="shadow-4">
                        <div class="flex flex-column pt-7 col-12">
                            <div
                                class="fontNomeEstabelecimentoContato flex justify-content-center align-items-center flex-wrap pb-1">
                                <span class="xl:text-4xl"> {{nomeemp}}</span>
                            </div>
                            <div class="fontInfo flex justify-content-center flex-wrap pb-1">
                                <span class="xl:text-2xl">Informações</span>
                            </div>
                            <div class="flex justify-content-center flex-wrap">
                                <div class="linhahorizontalContato1 pb-5 xl:w-26rem"></div>
                            </div>

                            <div class="flex justify-content-center flex-wrap">
                                <div class="flex flex-column  xl:w-26rem">
                                    <!-- <div class="flex flex-column tamanhoContato"> -->
                                    <div class="col ">
                                        <div class="formgroup-inline justify-content-between flex-wrap pb-5 ">
                                            <div class="fonteCinzaContato">
                                                <span class="xl:text-base">Telefone</span>
                                                <div>
                                                    <span class="fonteDadosContato xl:text-base">{{telefone}} </span>
                                                </div>
                                            </div>
                                            <div class="flex gap-1">
                                                <img src="./assets/iconeContatoTel.svg" class=" xl:w-2rem" />
                                                <img src="./assets/iconeContatoWpp.svg" class=" xl:w-2rem" />
                                            </div>
                                        </div>

                                        <div class=" formgroup-inline justify-content-between flex-wrap pb-5">
                                            <div class="fonteCinzaContato">
                                                <span class="xl:text-base">E-mail</span>
                                                <div>
                                                    <span class="fonteDadosContato xl:text-base"> {{email}}</span>
                                                </div>
                                            </div>
                                            <div class="flex">
                                                <img src="./assets/iconeContatoEmail.svg" class=" xl:w-2rem" />
                                            </div>
                                        </div>

                                        <div class="formgroup-inline justify-content-between flex-wrap flex ">



                                            <div class="fonteCinzaContato  ">
                                                <span class="xl:text-base">Endereço</span>
                                                <div>
                                                    <span class="fonteDadosContato xl:text-base">{{logradouro}}
                                                        {{numero}}
                                                    </span>
                                                </div>
                                                <div>
                                                    <span class="fonteDadosContato xl:text-base"> {{complemento}}</span>
                                                </div>
                                                <div>
                                                    <span class="fonteDadosContato xl:text-base">{{bairro}} -
                                                        {{nomecid}}</span>
                                                </div>
                                            </div>
                                            <div class="flex align-items-center ">
                                                <img src="./assets/iconeContatoEndereco.svg" class=" xl:w-2rem" />
                                            </div>

                                        </div>

                                        <div class="formgroup-inline justify-content-evenly  flex-wrap pt-5">
                                            <div *ngIf="temFacebook">
                                                <img src="./assets/facebook.svg" (click)="abreFacebook()"
                                                    class="cursor-pointer" />
                                            </div>

                                            <div *ngIf="temInstagram">
                                                <img src="./assets/instagram.svg" (click)="abreInstagram()"
                                                    class="cursor-pointer" />
                                            </div>

                                            <div *ngIf="temTiktok">
                                                <img src="./assets/tiktok.svg" (click)="abreTikTok()"
                                                    class="cursor-pointer" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </p-card>
                </div>
            </div>
        </div>
        <br>
   

</body>





















<!--                          DIALOG DO CARREGANDO               -->
<p-dialog id="dialogCarregandoContato" [(visible)]="mostraCarregando" [modal]="true"
    [style]="{'width': '150px', 'height': '140px'}" [closable]="false">
    <div class="flex flex-column align-items-center mt-2">
        <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
            fill="var(--surface-ground)" animationDuration="1s"></p-progressSpinner>
        <div class="fontCarregando">
            <span>Carregando...</span>
        </div>
    </div>
</p-dialog>