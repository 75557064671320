import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-configuracao-financeira',
  templateUrl: './configuracao-financeira.component.html',
  styleUrls: ['./configuracao-financeira.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfiguracaoFinanceiraComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
