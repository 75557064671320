import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, Observable, tap } from 'rxjs';
import { Profissional } from './assets/profissional';
import { environment } from './environments/environment';
import { LoginService } from './login/login.service';

@Injectable({
  providedIn: 'root'
})
export class ProfissionalService {
  profissional: Profissional = {};

  constructor(private http: HttpClient, private loginService: LoginService) { }




  buscarProfissionalporCodCli(codcli: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const cliente = { "codcli": codcli, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/funcionario`, cliente, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  buscarProfissionalporCodEmp(codemp: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const empresa = { "codemp": codemp, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/funcionario/getfuncbyemp`, empresa)
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
          //  this.loginService.verificaToken(e.status);
          })
      );
  }

  ativaProfissional(codfunc: string, email: string, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const funcionario = { "codfunc": codfunc, "email": email, "codusuresp": codusuresp }

    return this.http
      .put<any>(`${environment.BASE_URL}/funcionario/ativarfunc`, funcionario, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  desativaProfissional(codfunc: string, email: string, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const funcionario = { "codfunc": codfunc, "email": email, "codusuresp": codusuresp }

    return this.http
      .put<any>(`${environment.BASE_URL}/funcionario/desativarfunc`, funcionario, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  preencherFuncionario(codfunc: string, nomefunc: string, apelido: string, codemp: string, email: string, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const funcionario = { "codfunc": codfunc, "nomefunc": nomefunc, "apelido": apelido, "codemp": codemp, "email": email, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/funcionario/createfunc`, funcionario, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  public setProfissional(p: Profissional) { //SETA O Profissional NO VETOR
    this.profissional = p;
  }

  public getProfissional() { //RECUPERA O CONTEUDO DO Profissional PARA VISUALIZAÇÃO
    return this.profissional;
  }


  excluiFuncionarioPorCodFunc(codfunc: string, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const funcionario = { "codfunc": codfunc, "codusuresp": codusuresp }

    return this.http
      .put<any>(`${environment.BASE_URL}/funcionario/excluirfunc`, funcionario, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  uploadImagemFunc(codfunc: string, img: any) {
    //const imagem = { "codemp": codemp, "imagem_empresa": img }
    // const headers =  { 'Content-Type': 'multipart/form-data' }

    const token = localStorage.getItem('token');
    //let headers = new HttpHeaders().append('Authorization', token!);

    let headers = new HttpHeaders({
      'enctype': 'multipart/form-data',
      'Authorization': token!
    });
    return this.http
      .post<any>(`${environment.BASE_URL}/upload/imagem-profissional`, img, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

}
