import { HomeComponent } from './../home/home.component';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Notificacao } from 'src/assets/notificacao';
import { NotificacoesService } from 'src/notificacoes.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Subject } from 'rxjs';
import { DecodedTokenService } from 'src/assets/decoded-token.service';

@Component({
  selector: 'app-detalhe-notificacao',
  templateUrl: './detalhe-notificacao.component.html',
  styleUrls: ['./detalhe-notificacao.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DetalheNotificacaoComponent implements OnInit {
  notLida: number = 0;
  codusuresp: string = '';

  public notLida$ = new Subject();

  notificacao: Notificacao = {}
  constructor(private notificacoesService: NotificacoesService, private home: HomeComponent, private router: Router,
    private decodedTokenService: DecodedTokenService) {
  }

  ngOnInit(): void {
    this.recuperaCodUsu();
    this.recuperaNotificacao();
  }

  recuperaCodUsu() {
    this.codusuresp = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }
  recuperaNotificacao() {
    this.notificacao = this.notificacoesService.getNotificacao();

    /**QUANDO SELECIONAR UMA NOVA NOTIFICAÇÃO A VARIAVEL INSCRITA FARÁ O PRINT DA NOVA NOTIFICAÇÃO E ELA FICARA COMO LIDA*/
    this.notificacoesService.notificacao$.subscribe((data) => {
      this.notificacao = Object(data);
      this.notificacoesService.leNotificacao(this.notificacao.nunot2!, this.codusuresp).subscribe((res) => {
        this.home.buscaNotificacoes();
      })
    })

    /**MARCA COMO LIDA A NOTIFICAÇÃO SELECIONADA NA TELA ANTERIOR */
    this.notificacoesService.leNotificacao(this.notificacao.nunot2!, this.codusuresp).subscribe((res) => {
      console.log(res)
      this.home.buscaNotificacoes();
    })
  }


}
