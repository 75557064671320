import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { PlanoService } from 'src/plano.service';

@Component({
  selector: 'app-cadastrar-plano-pacote',
  templateUrl: './cadastrar-plano-pacote.component.html',
  styleUrls: ['./cadastrar-plano-pacote.component.scss'],
  providers: [ConfirmationService, MessageService],
  encapsulation: ViewEncapsulation.None
})
export class CadastrarPlanoPacoteComponent implements OnInit {

  /**VARIAVEIS DO COMPONENT */
  planos: any[] = [];
  itemsPlanoPacote: MenuItem[] = [];
  planoSelec: any;

  codusuresp: string = '';
  codcli: number = 0;

  constructor(private messageService: MessageService, private decodedTokenService: DecodedTokenService, private confirmationService: ConfirmationService,
    private router: Router, private planoService: PlanoService) {
  }

  ngOnInit(): void {
    this.recuperaCodUsu();
    this.recuperaCodCli();
    //this.recuperaPermissao();
    this.buscarPlanosPorCodCliente();


    this.itemsPlanoPacote = [{
      items: [{
        label: 'Detalhes',
        command: () => {
          this.editarPlano(this.planoSelec);
        },
        // routerLink: ['/comissao'],
      }
      ]
    },
    {
      items: [{
        label: 'Excluir',
        styleClass: 'linhaMenu',
        command: () => {
          this.confirmaExclusaoPlano();
        }
      }
      ]
    }
    ];
  }

  /**METODOS PARA RECUPERAÇÃO DE INFORMAÇÕES DO TOKEN */
  recuperaCodCli() {
    this.codcli = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODCLI;
  }

  recuperaCodUsu() {
    this.codusuresp = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }





  buscarPlanosPorCodCliente() {
    this.planos = [];
    //if (this.permissao != undefined && this.permissao.length > 0) {
    // this.planoService.buscaPlanoPorCodCli(this.permissao[0].codcli, this.codusuresp).subscribe((res) => {
    //   console.log(res);
    //   this.planos = res;
    // });
    // } else {
    this.planoService.buscaPlanoPorCodCli(this.codcli, this.codusuresp).subscribe((res) => {
      console.log(res);
      this.planos = res;
    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
      });
    // }
  }

  planoSelecionado(plano: any) {
    this.planoSelec = plano;
    console.log(this.planoSelec);
  }


  excluiPlanoPorNupla() {
    this.planoService.excluiPlano(this.planoSelec.nupla!, this.codusuresp).subscribe((res) => {
      console.log(res);
      this.planos = this.planos.filter((x: any) => x.nupla != res.nupla); //FILTRA TUDO O QUE É DIFERENTE DO QUE FOI EXCLUIDO
      console.log(this.planos)
    });
  }




  editarPlano(plano: any) {
    /**VERIFICA SEM TEM PERMISSÃO PARA ESSA AÇÃO */
    // if (this.alterar || this.codcli > 0) {
    this.planoService.setPlano(plano);
    this.router.navigate([('/detalhePlano')]);
    //}
    // else {
    //   this.showError('Atenção', 'Ação não permitida.')
    //   return
    // }
  }





  cadastrarPlanoPacote() {
    /**VERIFICA SEM TEM PERMISSÃO PARA ESSA AÇÃO */
    // if (this.cadastrar || this.codcli > 0) {
    var auxPlano = {}
    this.planoService.setPlano(auxPlano);
    this.router.navigate([('/detalhePlano')]);
    // }
    // else {
    //   this.showError('Atenção', 'Ação não permitida.')
    //   return
    // }
  }

  confirmaExclusaoPlano() {

    /**VERIFICA SEM TEM PERMISSÃO PARA ESSA AÇÃO */
    //if (this.inativar || this.codcli > 0) {
    this.confirmationService.confirm({
      message: 'Deseja mesmo excluir ' + this.planoSelec.nome + ' ?',
      header: 'Excluir Plano',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.excluiPlanoPorNupla();
      },
      reject: () => {
      }
    });
    // }
    // else {
    //   this.showError('Atenção', 'Ação não permitida.')
    //   return
    // }
  }


  /**RECUPERA AS PERMISSÕES */
  // recuperaPermissao() {
  //   this.permissao = this.permissoesService.getPermissao();
  //   console.log(this.permissao)
  //   if (this.permissao != undefined && this.permissao.length > 0) {
  //     if (this.permissao[0].acao[0].PERMITE === 'S') {
  //       this.cadastrar = true;
  //     }
  //     if (this.permissao[0].acao[1].PERMITE === 'S') {
  //       this.alterar = true;
  //     }
  //     if (this.permissao[0].acao[2].PERMITE === 'S') {
  //       this.inativar = true;
  //     }
  //   }
  // }






  /**METODO DE TOAST */
  showError(titulo: string, mensagem: string) {
    if (screen.width < 640 || screen.height < 480) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else if (screen.width < 1024 || screen.height < 768) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else {
      this.messageService.add({
        key: 'tr',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    }
  }
}


