<p-toast position="bottom-center" key="bc"></p-toast>
<p-toast position="top-right" key="tr"></p-toast>
<div class="pt-7 overflow-hidden" id="pagina">

    <div
        class="xl:flex xl:justify-content-center xl:flex-wrap lg:flex lg:justify-content-center lg:flex-wrap md:flex md:justify-content-center md:flex-wrap sm:flex sm:justify-content-center sm:flex-wrap">
        <div class="xl:col-9 lg:col-9 md:col-9 sm:col-9">
            <!-- <div>
            <div> -->
            <!-- <div class="pb-2">
                <div *ngIf="teveRespostaprofissionalGeral">
                    <p-button id="botaoSpinnerGeral" label="Estabelecimento" [disabled]="true" class="p-button-text">
                        <i class="pi pi-spin pi-spinner teste flex-order-1" style="font-size: 1rem"></i>
                    </p-button>
                </div>

                <div *ngIf="!teveRespostaprofissionalGeral">
                    <p-dropdown id="dropGeral" [options]="estabelecimento" [(ngModel)]="estabelecimentoSelecionada"
                        placeholder="Estabelecimento" optionLabel="nome" optionValue="codemp" scrollHeight="130px"
                        (onChange)="buscarprofissionalPorCodEmp(estabelecimentoSelecionada)">
                    </p-dropdown>
                </div>
            </div> -->
            <!-- 
            <div class="col">
         <p-dropdown [options]="clientes" [(ngModel)]="clienteSelecionado" placeholder="Clientes" optionLabel="nomeparc"
              optionValue="codusu" [style]="{'width': '100%',  'height': '20px'}" id="drop" scrollHeight="130px">
        </p-dropdown>
            </div> -->



            <div class="col-12"
                *ngIf="(this.codcli > 0 && this.permissao === undefined && !codcliFunc) || (this.verTodasAgenda && this.permissao != undefined && this.permissao.length > 0)">
                <div class=" grid p-flup-2 flex align-items-center justify-content-center">
                    <div class="field botaoAbertoFechado">
                        <button id="botaoAgenda" pButton type="button" label="Visualização Agrupada" class="col-12"
                            (click)="clickAgrupada()"
                            [ngStyle]="aba?{'background-color': 'var(--cor1)', 'color': 'var(--cor7)'} : {'background-color': 'var(--cor2)', 'color': 'var(--cor1)'}"></button>
                    </div>
                    <div class="field botaoAbertoFechado">
                        <button id="botaoAgenda" pButton type="button" label="Visualização Individual" class="col-12"
                            (click)="clickIndividual()"
                            [ngStyle]="aba?{'background-color': 'var(--cor2)', 'color': 'var(--cor1)'} : {'background-color': 'var(--cor1)', 'color': 'var(--cor7)'}"></button>
                    </div>
                </div>
            </div>

            <!-- AGRUPADO -->
            <div
                *ngIf="aba && (this.codcli > 0 && this.permissao === undefined) || (aba && this.verTodasAgenda && this.permissao != undefined && this.permissao.length > 0)">
                <div class="pb-2">
                    <div *ngIf="teveRespostaprofissionalGeral">
                        <p-button id="botaoSpinnerGeral" label="Estabelecimento" [disabled]="true"
                            class="p-button-text">
                            <i class="pi pi-spin pi-spinner teste flex-order-1" style="font-size: 1rem"></i>
                        </p-button>
                    </div>

                    <div *ngIf="!teveRespostaprofissionalGeral">
                        <p-dropdown id="dropGeral" [options]="estabelecimento" [(ngModel)]="estabelecimentoSelecionada"
                            placeholder="Estabelecimento" optionLabel="nome" optionValue="codemp" scrollHeight="130px"
                            (onChange)="buscaprofissional(estabelecimentoSelecionada);resourcesprofissional()">
                        </p-dropdown>
                    </div>
                </div>

                <full-calendar #calendar [options]='calendarOptions'></full-calendar>

            </div>

            <!-- INDIVIDUAL -->
            <!-- <div *ngIf="!aba || (codcli === 0 && codfunc != '0') "> -->
            <div
                *ngIf="!aba || (codcli === 0 && codfunc != '0'  && codfunc != null) || ( aba && !this.verTodasAgenda && codfunc != '0'  && codfunc != null)">
                <div class="pb-2">
                    <div *ngIf="teveRespostaprofissionalGeral">
                        <p-button id="botaoSpinnerGeral" label="Profissional" [disabled]="true" class="p-button-text">
                            <i class="pi pi-spin pi-spinner teste flex-order-1" style="font-size: 1rem"></i>
                        </p-button>
                    </div>

                    <div *ngIf="!teveRespostaprofissionalGeral">
                        <p-dropdown id="dropGeral" [options]="profissionalGeral"
                            [(ngModel)]="profissionalGeralSelecionado" placeholder="Profissional" optionLabel="apelido"
                            optionValue="codfunc" scrollHeight="130px" (onChange)="buscaEventos()">
                        </p-dropdown>
                    </div>
                </div>

                <full-calendar #calendar [options]='calendarOptions'></full-calendar>
            </div>


            <!-- <p-tabView>
                <p-tabPanel header="Visualização Agrupada">
                    <div class="pb-2">
                        <div *ngIf="teveRespostaprofissionalGeral">
                            <p-button id="botaoSpinnerGeral" label="Estabelecimento" [disabled]="true" class="p-button-text">
                                <i class="pi pi-spin pi-spinner teste flex-order-1" style="font-size: 1rem"></i>
                            </p-button>
                        </div>

                        <div *ngIf="!teveRespostaprofissionalGeral">
                            <p-dropdown id="dropGeral" [options]="estabelecimento" [(ngModel)]="estabelecimentoSelecionada"
                                placeholder="Estabelecimento" optionLabel="nome" optionValue="codemp" scrollHeight="130px"
                                (onChange)="buscarprofissionalPorCodEmp(estabelecimentoSelecionada)">
                            </p-dropdown>
                        </div>
                    </div>
                    <full-calendar [options]='calendarOptions'></full-calendar>
                </p-tabPanel>
                <p-tabPanel header="Visualização Individual">
                    <div class="pb-2">
                        <div *ngIf="teveRespostaprofissionalGeral">
                            <p-button id="botaoSpinnerGeral" label="Profissional" [disabled]="true"
                                class="p-button-text">
                                <i class="pi pi-spin pi-spinner teste flex-order-1" style="font-size: 1rem"></i>
                            </p-button>
                        </div>

                        <div *ngIf="!teveRespostaprofissionalGeral">
                            <p-dropdown id="dropGeral" [options]="profissionalGeral" [(ngModel)]="profissionalGeralSelecionado"
                                placeholder="Profissional" optionLabel="apelido" optionValue="codfunc"
                                scrollHeight="130px" (onChange)="buscaEventos()">
                            </p-dropdown>
                        </div>
                    </div>

                    <full-calendar [options]='calendarOptions'></full-calendar>




                </p-tabPanel>

            </p-tabView> -->






            <!-- <p-dialog header="Agenda" [(visible)]="showAgendamento" [modal]="true"
        [style]="{'width': '350px', 'height': '360px'}">
        <div class="col-12">
            <div class="fontDialog flex align-items-center justify-content-center ">
                <span>Informe os dados do Agendamento</span>
            </div>
        </div>
        <input type="text" pInputText placeholder="Titulo" [(ngModel)]="titulo" [style]="{width: '100%'}">
        <textarea pInputTextarea placeholder="Descrição" [(ngModel)]="descricao" [autoResize]="false" class="mt-1 mb-1"
            [style]="{'width': '100%', 'height': '20%'}"></textarea>
        <div class="grid p-fluid col">
            <div class="w-6 ">
                <p-inputMask mask="99:99" placeholder="Horario Inicial" [(ngModel)]="horaIni" [style]="{width: '100%'}">
                </p-inputMask>
            </div>

            <div class="w-6 ">
                <p-inputMask mask="99:99" placeholder="Horario Final" [(ngModel)]="horaFin" [style]="{width: '100%'}">
                </p-inputMask>
            </div>
        </div>
        <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                'border-radius': '11px'}" (click)="salvarEvento()"></button>
    </p-dialog> -->




            <!--                          DIALOG DO BLOQUEIO               -->
            <p-dialog header="Bloqueio" [(visible)]="showBloqueio" [modal]="true"
                [style]="{'width': '350px', 'height': '430px'}" id="headerAgenda">
                <div class="col-12">
                    <div class="fontDialogAgenda flex align-items-center justify-content-center ">
                        <span>Tornar horários indisponíveis</span>
                    </div>
                </div>
                <input type="text" pInputText placeholder="Título" [(ngModel)]="tituloBlq" [style]="{width: '100%'}"
                    class="mb-1">

                <p-dropdown id="dropAgenda" [options]="profissionalGeral" [(ngModel)]="profissionalSelecionadoBlq"
                    placeholder="Profissional" optionLabel="apelido" optionValue="codfunc" [style]="{'width': '100%'}"
                    scrollHeight="130px">
                </p-dropdown>

                <p-calendar [touchUI]="true" [readonlyInput]="false" inputId="touchui" dateFormat="dd/mm/yy"
                    [minDate]="ontem" [readonlyInput]="true" id="calendarioAgenda" [(ngModel)]="diaSelecionadoBlq"
                    placeholder="Selecionar dia">
                </p-calendar>
                <div class="grid p-fluid col espacamentoDialogBloqueio ">
                    <div class="w-6 ">
                        <p-inputMask mask="99:99" placeholder="Horario Inicial" [(ngModel)]="horaIniBlq"
                            [style]="{width: '100%'}">
                        </p-inputMask>
                    </div>
                    <div class="w-6 ">
                        <p-inputMask mask="99:99" placeholder="Horario Final" [(ngModel)]="horaFinBlq"
                            [style]="{width: '100%'}">
                        </p-inputMask>
                    </div>
                </div>
                <textarea pInputTextarea placeholder="Descrição (Observação)" [(ngModel)]="descricaoBlq"
                    [autoResize]="false" class="" [style]="{'width': '100%', 'height': '20%'}"></textarea>

                <div *ngIf="loading" [style]="{width: '100%'}">
                    <button id="botaoSalvando" pButton [disabled]="true" type="button" label="Salvando"
                        class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                'border-radius': '11px'}"></button>
                </div>

                <div *ngIf="!loading" [style]="{width: '100%'}">
                    <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
            'border-radius': '11px'}" (click)="criarBloqueio()"></button>
                </div>

            </p-dialog>


            <!--                          DIALOG DO AGENDAR               -->
            <p-dialog header="Agendar" [(visible)]="showAgendar" [modal]="true" [closable]="true" [resizable]="false"
                id="headerAgenda">
                <!-- <div class="flex flex-column  align-items-center"> -->
                <div class="col-12">
                    <div class="fontDialogAgenda flex align-items-center justify-content-center ">
                        <span>Confirme os dados para agendamento</span>
                    </div>
                </div>



                <div *ngIf="teveRespostaCliente">
                    <p-button id="botaoSpinner" label="Cliente" [disabled]="true" class="p-button-text">
                        <i class="pi pi-spin pi-spinner teste flex-order-1" style="font-size: 1rem"></i>
                    </p-button>
                </div>

                <div *ngIf="!teveRespostaCliente">
                    <p-dropdown id="dropAgenda" [options]="clientes" [(ngModel)]="clienteSelecionado"
                        placeholder="Cliente" optionLabel="nomeparc" [style]="{'width': '100%'}" scrollHeight="130px"
                        [filter]="true" filterBy="nomeparc,telefone"
                        (onChange)="buscarprofissionalPorCodEmpresa(clienteSelecionado.codemp)">

                        <ng-template let-clientes pTemplate="item">
                            <div class="flex align-items-center ">
                                <span
                                    class="maximoCaracterNome white-space-nowrap overflow-hidden text-overflow-ellipsis">{{
                                    clientes.nomeparc }}</span>
                            </div>
                            <div class="flex align-items-center ">
                                <span class="fontNuTel">{{ clientes.telefone }}</span>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>


                <div *ngIf="teveRespostaProfissional">
                    <p-button id="botaoSpinner" label="Profissional" [disabled]="true" class="p-button-text">
                        <i class="pi pi-spin pi-spinner teste flex-order-1" style="font-size: 1rem"></i>
                    </p-button>
                </div>

                <div *ngIf="!teveRespostaProfissional">
                    <p-dropdown id="dropAgenda" [options]="profissional" [(ngModel)]="profissionalSelecionado"
                        placeholder="Profissional" optionLabel="apelido" optionValue="codfunc"
                        [style]="{'width': '100%'}" scrollHeight="130px"
                        (onChange)="buscarServicoPorCodFuncionario(profissionalSelecionado); listaPlanosComprados(clienteSelecionado.codusu, profissionalSelecionado, clienteSelecionado.codemp)">
                    </p-dropdown>
                </div>


                <div *ngIf="teveRespostaServico">
                    <p-button id="botaoSpinner" label="Serviço" [disabled]="true" class="p-button-text">
                        <i class="pi pi-spin pi-spinner teste flex-order-1" style="font-size: 1rem"></i>
                    </p-button>
                </div>

                <div *ngIf="!teveRespostaServico">
                    <p-multiSelect [options]="servico" [(ngModel)]="servicoSelecionado" optionLabel="nomeprod"
                        scrollHeight="130px" [style]="{'width': '100%'}" placeholder="Serviço" [filter]="false"
                        [showHeader]="false" (onChange)="somaTotalMin(); mudaServico(); "></p-multiSelect>
                </div>


                <!-- PLANOS DO CLIENTE -->
                <div *ngIf="planosClienteDrop.length > 0">
                    <div *ngIf="teveRespostaServico">
                        <p-button id="botaoSpinner" label="Planos Cliente" [disabled]="true" class="p-button-text">
                            <i class="pi pi-spin pi-spinner teste flex-order-1" style="font-size: 1rem"></i>
                        </p-button>
                    </div>

                    <div *ngIf="!teveRespostaServico">
                        <p-multiSelect [options]="planosClienteDrop" [(ngModel)]="planosClienteDropSelecionado"
                            scrollHeight="130px" [style]="{'width': '100%'}" placeholder="Planos Cliente"
                            [filter]="false" [showHeader]="false" [group]="true" optionGroupChildren="servicos"
                            optionLabel="nomeprod" (onChange)="somaTotalMin(); mudaServico(); printPlanos(); ">
                            <ng-template let-group pTemplate="group">
                                <div class="flex align-items-center">
                                    <span>{{ group.nome }}</span>
                                </div>
                            </ng-template>
                        </p-multiSelect>
                    </div>
                </div>

                <!-- PLANOS DISPONIVEIS PARA COMPRA -->
                <div *ngIf="planosDisponiveisDrop.length > 0">
                    <div *ngIf="teveRespostaServico">
                        <p-button id="botaoSpinner" label="Planos Disponíveis" [disabled]="true" class="p-button-text">
                            <i class="pi pi-spin pi-spinner teste flex-order-1" style="font-size: 1rem"></i>
                        </p-button>
                    </div>

                    <div *ngIf="!teveRespostaServico">
                        <p-multiSelect [options]="planosDisponiveisDrop" [(ngModel)]="planosDisponiveisDropSelecionado"
                            scrollHeight="150px" [style]="{'width': '100%'}" placeholder="Planos Disponíveis"
                            [filter]="false" [showHeader]="false" [group]="true" optionGroupChildren="servicos"
                            optionLabel="nomeprod"
                            (onChange)="adicionaPlanoAgd(planosDisponiveisDropSelecionado);somaTotalMin(); mudaServico(); printPlanos();">
                            <ng-template let-group pTemplate="group">
                                <div class="flex align-items-center">
                                    <span>{{ group.nome }}</span>
                                </div>
                            </ng-template>
                        </p-multiSelect>
                    </div>
                </div>


                <p-calendar [touchUI]="true" [readonlyInput]="false" inputId="touchui" dateFormat="dd/mm/yy"
                    [readonlyInput]="true" id="calendarioAgenda" [(ngModel)]="diaSelecionadoAgd"
                    placeholder="Selecionar dia" (click)="selecionouDiaAgd();">
                </p-calendar>


                <button pButton pRipple type="button" [label]=horarioSelecionado class="p-button-text"
                    id="horariosButtonAgenda" (click)="horarios()"></button>


                <p-dialog id="dialogHorAgenda" [(visible)]="mostraHorarios" [modal]="true" header="Horários"
                    [style]="{'width': '350px', 'height': '232px'}">
                    <div class="flex justify-content-center">
                        <div *ngIf="horarioMatriz.length===0">
                            <div class="flex align-items-center justify-content-center">
                                <div class="flex flex-column  align-items-center">
                                    <div class="">
                                        <div class="fontEsgotados flex align-items-center justify-content-center">
                                            <img class="iconeSemHorarios" src="./assets/semHorarios.png" />
                                        </div>
                                        <div class="fontEsgotados flex align-items-center justify-content-center">
                                            <span>Hórarios para atendimentos esgotados</span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex align-items-center justify-content-center">
                            <table class="">
                                <tr *ngFor="let horarioMatriz of horarioMatriz">
                                    <td *ngFor="let horario of horarioMatriz">
                                        <p-button label='{{horario[1].horario }}'
                                            (click)="agendaHorario(horario[1].horario)" id="horarioEscolhido"
                                            [style]="{'background-color': 'var(--cor2)', 'color': 'var(--cor1)'}"
                                            [ngStyle]="horarioSelecionado == horario[1].horario?{  'color': 'var(--cor7)'} : { 'color': 'var(--cor1)'}">
                                        </p-button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </p-dialog>

                <div class="flex flex-column align-items-start">
                    <div class="col">
                        <div class="fontcinzaNotificacao">
                            <div class="flex gap-2">
                                <p-checkbox [binary]="true" [(ngModel)]="receberNotificacao"></p-checkbox>
                                <div class="flex align-items-center justify-content-center">
                                    <span>Receber alerta do atendimento</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="loading" [style]="{width: '100%'}">
                    <button id="botaoSalvando" pButton [disabled]="true" type="button" label="Salvando"
                        class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
            'border-radius': '11px'}"></button>
                </div>
                <div *ngIf="!loading" [style]="{width: '100%'}">
                    <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                'border-radius': '11px'}" (click)="criarAgenda()"></button>
                </div>
                <!-- </div> -->
            </p-dialog>

            <!--                          DIALOG DO ENCAIXE               -->
            <p-dialog header="Encaixe" [(visible)]="showEncaixe" [modal]="true" [closable]="true" id="headerAgenda">
                <!-- <div class="flex flex-column  align-items-center"> -->
                <div class="col-12">
                    <div class="fontDialogAgenda flex align-items-center justify-content-center ">
                        <span>Obs: Encaixe não inibe choque de horários</span>
                    </div>
                </div>


                <div *ngIf="teveRespostaCliente">
                    <p-button id="botaoSpinner" label="Cliente" [disabled]="true" class="p-button-text">
                        <i class="pi pi-spin pi-spinner teste flex-order-1" style="font-size: 1rem"></i>
                    </p-button>
                </div>

                <div *ngIf="!teveRespostaCliente">
                    <p-dropdown id="dropAgenda" [options]="clientes" [(ngModel)]="clienteSelecionado"
                        placeholder="Cliente" optionLabel="nomeparc" [style]="{'width': '100%'}" scrollHeight="130px"
                        [filter]="true" filterBy="nomeparc,telefone"
                        (onChange)="buscarprofissionalPorCodEmpresa(clienteSelecionado.codemp)">

                        <ng-template let-clientes pTemplate="item">
                            <div class="flex align-items-center ">
                                <span
                                    class="maximoCaracterNome white-space-nowrap overflow-hidden text-overflow-ellipsis">{{
                                    clientes.nomeparc }}</span>
                            </div>
                            <div class="flex align-items-center ">
                                <span class="fontNuTel">{{ clientes.telefone }}</span>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>

                <div *ngIf="teveRespostaProfissional">
                    <p-button id="botaoSpinner" label="Profissional" [disabled]="true" class="p-button-text">
                        <i class="pi pi-spin pi-spinner teste flex-order-1" style="font-size: 1rem"></i>
                    </p-button>
                </div>

                <div *ngIf="!teveRespostaProfissional">
                    <p-dropdown id="dropAgenda" [options]="profissional" [(ngModel)]="profissionalSelecionado"
                        placeholder="Profissional" optionLabel="apelido" optionValue="codfunc"
                        [style]="{'width': '100%'}" scrollHeight="130px"
                        (onChange)="buscarServicoPorCodFuncionario(profissionalSelecionado)">
                    </p-dropdown>
                </div>


                <div *ngIf="teveRespostaServico">
                    <p-button id="botaoSpinner" label="Serviço" [disabled]="true" class="p-button-text">
                        <i class="pi pi-spin pi-spinner teste flex-order-1" style="font-size: 1rem"></i>
                    </p-button>
                </div>

                <div *ngIf="!teveRespostaServico">
                    <p-multiSelect [options]="servico" [(ngModel)]="servicoSelecionado" optionLabel="nomeprod"
                        scrollHeight="130px" [style]="{'width': '100%'}" placeholder="Serviço" [filter]="false"
                        [showHeader]="false" (onChange)="somaTotalMin()"></p-multiSelect>
                </div>


                <p-calendar [touchUI]="true" [readonlyInput]="false" inputId="touchui" dateFormat="dd/mm/yy"
                    [readonlyInput]="true" id="calendarioAgenda" [(ngModel)]="diaSelecionadoEnc"
                    placeholder="Selecionar dia" (click)="selecionouDiaEnc(); ">
                </p-calendar>

                <div class="grid p-fluid col espacamentoDialogBloqueio ">
                    <div class="w-6 ">
                        <p-inputMask mask="99:99" placeholder="Horario Inicial" [(ngModel)]="horaIniEnc"
                            [style]="{width: '100%'}">
                        </p-inputMask>
                    </div>
                    <div class="w-6 ">
                        <p-inputMask mask="99:99" placeholder="Horario Final" [(ngModel)]="horaFinEnc"
                            [style]="{width: '100%'}">
                        </p-inputMask>
                    </div>
                </div>
                <textarea pInputTextarea placeholder="Descrição (Observação)" [(ngModel)]="descricaoEnc"
                    [autoResize]="false" class="" [style]="{'width': '100%', 'height': '20%'}"></textarea>





                <div *ngIf="loading" [style]="{width: '100%'}">
                    <button id="botaoSalvando" pButton [disabled]="true" type="button" label="Salvando"
                        class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                    'border-radius': '11px'}"></button>
                </div>


                <div *ngIf="!loading" [style]="{width: '100%'}">
                    <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                'border-radius': '11px'}" (click)="criarEncaixe()"></button>
                </div>

                <!-- </div> -->
            </p-dialog>


            <!--                          DIALOG DO EVENTO AGENDADO/ENCAIXE              -->
            <p-dialog [(visible)]="showEventoAgdEnc" [modal]="true" [style]="{'width': '350px'}" id="dialogEvento">
                <div class="col-12">
                    <div class="fontDialogAgenda flex align-items-center justify-content-center ">
                        <span>Informações desta programação</span>
                    </div>
                    <div class="flex justify-content-center flex-wrap fontHeaderEvento">
                        {{headerEvento}}
                    </div>
                </div>

                <div *ngIf="origemEvento==='A'">
                    <div class="eventoAgendado">
                        <div class="fontCorEvento  flex justify-content-center flex-wrap">
                            <span>Agendado</span>
                        </div>
                    </div>
                </div>

                <div *ngIf="origemEvento==='E'">
                    <div class="eventoEncaixe">
                        <div class="fontCorEvento  flex justify-content-center flex-wrap">
                            <span>Encaixe</span>
                        </div>
                    </div>
                </div>
                <div class="">
                    <span class="fontCinzaTitulo">Detalhes </span>

                    <div class="fontCinzaInfo col-12 flex flex-column  ">
                        <div *ngFor="let itensCab of itensCab">
                            <span> {{itensCab}} </span>
                        </div>
                    </div>
                </div>

                <div class="flex justify-content-center flex-wrap">
                    <button pButton id="botaoEditaServico" type="button" label="Incluir ou Excluir produto/serviço"
                        class="col-12"
                        (click)="mostraDialogEditaComanda(); listaPlanosComprados(codusuEvento, codfuncEvento, codempEvento);"></button>
                </div>


                <div class="mt-2">
                    <span class="fontCinzaTitulo">Cliente </span>

                    <div class="fontCinzaInfo col-12 flex flex-column  ">
                        <span>{{cliente}}</span>
                        <span>{{email}}</span>
                        <span>{{contato}}</span>
                    </div>
                </div>

                <div class="">
                    <span class="fontCinzaTitulo">Observações </span>

                    <div class="fontCinzaInfo col-12 " *ngIf="obsCab === ''">
                        <span> - </span>
                    </div>
                    <div class="fontCinzaInfo col-12 " *ngIf="obsCab != ''">
                        <span> {{obsCab}}</span>
                    </div>
                </div>

                <div class="">
                    <span class="fontCinzaTitulo">Resumo </span>

                    <div class="fontCinzaInfo col-12 flex flex-column  ">
                        <span>Tipo negociação: {{tipnegdescr}}</span>
                        <span>Vlr Total:
                            <span
                                [ngStyle]="vlrdesc > 0?{'text-decoration': 'line-through'} : {'text-decoration': 'none'}">{{vlrtot
                                + vlrdesc | currency : 'BRL'}}
                            </span>
                        </span>
                        <span *ngIf="vlrdesc > 0">Vlr Desc: {{vlrdesc | currency : 'BRL'}} </span>
                        <span *ngIf="vlrdesc > 0">Vlr Final:
                            <span class="font-bold"> {{vlrtot | currency : 'BRL'}}</span>
                        </span>
                        <span>Tempo médio: {{tempoTotalAgd}} min </span>
                    </div>
                </div>


                <div class="flex justify-content-center flex-wrap">
                    <button pButton id="botaoMovFin" type="button" label="Ir para mov. financeiro" class="col-12"
                        (click)="redirecionaMovFinanceiro()"></button>
                </div>

                <div class="grid p-flup-2 flex align-items-center justify-content-center mt-2 ">
                    <div class="field col ">
                        <button pButton type="button" label="Não compareceu" class="col-12" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                'border-radius': '11px', 'border': 'none'}" (click)="confirmaFaltaAgdEnc(nuidEvento)"></button>
                    </div>
                    <div class="balaoAgenda field col">
                        <button type="button" pButton icon="pi pi-info-circle" label="Cancelar" class="col-12" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
            'border-radius': '11px', 'border': 'none'}" (click)="confirmaCancelamentoAgdEnc(nuidEvento)"></button>
                        <div class="msgBalaoAgenda">
                            O cancelamento serve somente a caráter de urgência. Se deseja cancelar, entre em contato
                            para
                            informar o ocorrido ao cliente.
                        </div>
                    </div>
                </div>



            </p-dialog>

            <!--                          DIALOG DO EVENTO BLOQUEADO              -->
            <p-dialog id="dialogNoScroll" [(visible)]="showEventoBloc" [modal]="true"
                [style]="{'width': '350px', 'height': '310px'}" id="dialogEvento">
                <div class="col-12">
                    <div class="fontDialogAgenda flex align-items-center justify-content-center ">
                        <span>Informações desta programação</span>
                    </div>
                    <div class="flex justify-content-center flex-wrap fontHeaderEvento">
                        {{headerEvento}}
                    </div>
                </div>


                <div class="eventoBloqueado">
                    <div class="fontCorEvento  flex justify-content-center flex-wrap">
                        <span>Bloqueado</span>
                    </div>
                </div>



                <div class="">
                    <span class="fontCinzaTitulo">Título </span>

                    <div class="fontCinzaInfo col-12 ">
                        <span>{{tituloEvento}}</span>
                    </div>
                </div>

                <div class="">
                    <span class="fontCinzaTitulo">Descrição </span>

                    <div class="fontCinzaInfo col-12 ">
                        <span>{{obsCab}}</span>

                    </div>
                </div>

                <button id="excluiBloqueio" pButton type="button" label="Excluir Bloqueio" class="col-12 mt-3"
                    [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)','border-radius': '11px', 'border': 'none'}"
                    (click)="confirmaCancelamentoBlq(nuidEvento)"></button>
            </p-dialog>







            <!--                          DIALOG DE CADASTRO DE CLIENTE             -->
            <p-dialog header="Cadastrar Cliente" [(visible)]="showCadastra" [modal]="true" id="dialogCadastroCliParc"
                [draggable]="false">
                <div class="flex justify-content-center flex-wrap col">
                    <div class="fontDialogAgenda flex align-items-center justify-content-center">
                        <span>Informações do Cliente</span>
                    </div>
                </div>

                <input type="text" pInputText placeholder="*Nome" [(ngModel)]="nomeCliente" [style]="{'width': '100%'}">



                <div class="mt-1">
                    <p-calendar [touchUI]="true" [readonlyInput]="false" inputId="touchui" dateFormat="dd/mm/yy"
                        [required]="true" [readonlyInput]="true" id="calendarioAgenda" [(ngModel)]="dtNasc"
                        placeholder="*Data de Nascimento" [maxDate]="hoje">
                    </p-calendar>
                </div>

                <div class="mt-1">
                    <input type="text" pInputText placeholder="*Email" [(ngModel)]="emailCliente"
                        [style]="{'width': '100%'}">
                </div>

                <div class="mt-1 p-fluid mb-1">
                    <!-- <input type="text" pInputText placeholder="*Telefone" type="number" [disabled]="desabilitaCampos"
            [(ngModel)]="telefone" [style]="{'width': '100%'}"> -->
                    <p-inputMask mask="(99) 99999-9999" [(ngModel)]="telefoneCliente" placeholder="*Telefone"
                        id="telefone"></p-inputMask>
                </div>

                <input type="text" [(ngModel)]="cpfCli" pInputText [mask]=" '000.000.000-00'" placeholder="CPF"
                    [style]="{'width': '100%'}">

                <!-- <div *ngIf="tipoUsuarioSelecionado==='PARCEIRO'"> -->
                <p-dropdown id="dropAgenda" [options]="estabelecimento"
                    [(ngModel)]="estabelecimentoCadClienteSelecionada" placeholder="*Estabelecimento" optionLabel="nome"
                    optionValue="codemp" [style]="{'width': '100%'}" scrollHeight="130px" [filter]="false">
                </p-dropdown>
                <!-- </div> -->


                <div class="mt-1">
                    <input type="text" pInputText placeholder="Senha: 123456" [(ngModel)]="senhaCliente"
                        [style]="{'width': '100%'}" [disabled]="true">
                </div>



                <div *ngIf="!loading" [style]="{width: '100%'}">
                    <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
          'border-radius': '11px'}" (click)="preencheUsuario()"></button>
                </div>
                <div *ngIf="loading" [style]="{width: '100%'}">
                    <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando"
                        class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
          'border-radius': '11px'}"></button>
                </div>
            </p-dialog>






            <!--                          DIALOG DE EDIÇÃO DOS SERVICOS              -->
            <p-dialog id="dialogEditaServico" header="Incluir ou Remover serviço" [(visible)]="showEditarServico"
                [draggable]="false" [modal]="true" [style]="{'width': '350px', ' max-height': '570px'}"
                [closable]="false">
                <div class="col-12">
                    <div class="fontDialogAgenda flex align-items-center justify-content-center ">
                        <span>Inclua ou remova serviço deste agenamento</span>
                    </div>
                </div>



                <div class="contornoAbas formgroup-inline gap-3 ">
                    <div (click)="clickServicos()">
                        <span class="col cursor-pointer fontAbas" [ngClass]="{'linhahorizontalAba': selecionouServicos}"
                            [ngStyle]="selecionouServicos?{  'color:': 'var(--cor22) !imporant', 'font-weight': 700} : {'color:': 'var(--cor1)', 'font-weight': 400}">Serviços</span>
                    </div>
                    <div class=" flex justify-content-center flex-wrap " *ngIf="meusPlanos.length > 0 || planosDisponiveis.length > 0 ">
                        <div class="vertical">
                        </div>
                    </div>
                    <div (click)="clickPlanos()" *ngIf="meusPlanos.length > 0 || planosDisponiveis.length > 0 ">
                        <span class="col cursor-pointer fontAbas" [ngClass]="{'linhahorizontalAba': selecionouPlanos}"
                            [ngStyle]="selecionouPlanos?{'color:': 'var(--cor22) !imporant', 'font-weight': 700} : {'color:': 'var(--cor1)', 'font-weight': 400}">Planos</span>
                    </div>
                </div>



                <!-- SERVIÇOS  -->
                <div *ngIf="selecionouServicos">
                    <div *ngFor="let servico of servico">
                        <div class="contornoServico mt-1 flex justify-content-between col">
                            <div class="flex justify-content-start flex-column">


                                <div class="fontServicoEdita">
                                    <span>{{servico.nomeprod}} ({{servico.descrprod}})</span>
                                </div>
                                <div class="fontPrecoEdita">
                                    <span>{{servico.preco| currency :'BRL'}} - {{servico.minutos}} min</span>
                                </div>

                            </div>

                            <div class="flex align-items-center justify-content-center">
                                <img *ngIf="servico.noCabecalho === 'N' && servico.desabilitaServico === 'N' && !servico.adicionandoServico "
                                    src="./assets/adicionaServico.svg" class="cursor-pointer"
                                    (click)="adicionaServicoAgendamento(servico)" />
                                <i *ngIf="servico.adicionandoServico" class="pi pi-spin pi-spinner"
                                    style="font-size: 22px"></i>

                                <img *ngIf="servico.noCabecalho === 'S' && !servico.removendoServico"
                                    src="./assets/removeServico.svg" class="cursor-pointer"
                                    (click)="removeServicoAgendamento(servico)" />
                                <i *ngIf="servico.removendoServico" class="pi pi-spin pi-spinner"
                                    style="font-size: 22px"></i>

                                <img *ngIf="servico.desabilitaServico === 'S' && servico.noCabecalho === 'N' && !servico.removendoServico"
                                    src="./assets/desabilitaServico.svg" />
                                <!-- <i *ngIf="servico.removendoServico" class="pi pi-spin pi-spinner"
                                    style="font-size: 22px"></i> -->
                            </div>
                        </div>
                    </div>
                </div>

                <!-- PLANOS  -->
                <div *ngIf="selecionouPlanos">
                    <div *ngIf="meusPlanos.length > 0">
                        <!-- PLANOS DISPONIVEIS DO CLIENTE -->
                        <div>
                            <span class="fontCinzaTituloPlanos">Planos do Cliente</span>
                        </div>
                        <div *ngFor="let meusPlanos of meusPlanos">

                            <div>
                                <span class="fontPlanosAgenda">{{meusPlanos.nome}}</span>
                            </div>


                            <ng-container *ngFor="let servicosMeusPlanos of servicosMeusPlanos">
                                <div *ngIf="servicosMeusPlanos.nupla == meusPlanos.nupla">
                                    <div class="contornoServico mt-1 flex justify-content-between col">
                                        <div class="flex justify-content-start flex-column">


                                            <div class="fontServicoEdita">
                                                <span>{{servicosMeusPlanos.nomeprod}}
                                                    ({{servicosMeusPlanos.descrprod}})</span>
                                            </div>
                                            <div class="fontPrecoEdita" (click)=" printa(servicosMeusPlanos)">
                                                <span>{{servicosMeusPlanos.minutos}} min</span>
                                            </div>

                                        </div>

                                        <div class="flex align-items-center justify-content-center">
                                            <!-- <img  -->
                                            <img *ngIf="servicosMeusPlanos.noCabecalho === 'N' && servicosMeusPlanos.status === 'LIVRE' 
                                            && servicosMeusPlanos.desabilitaServico === 'N'&& !servicosMeusPlanos.adicionandoServico "
                                                src="./assets/adicionaServico.svg" class="cursor-pointer"
                                                (click)="adicionaServicoAgendamento(servicosMeusPlanos)" />
                                            <i *ngIf="servicosMeusPlanos.adicionandoServico"
                                                class="pi pi-spin pi-spinner" style="font-size: 22px"></i>

                                                <!-- <img  -->
                                             <img *ngIf="servicosMeusPlanos.noCabecalho === 'S' && !servicosMeusPlanos.removendoServico"   
                                                src="./assets/removeServico.svg" class="cursor-pointer"
                                                (click)="removeServicoAgendamento(servicosMeusPlanos)" />
                                            <i *ngIf="servicosMeusPlanos.removendoServico" class="pi pi-spin pi-spinner"
                                                style="font-size: 22px"></i>

                                            <img *ngIf="servicosMeusPlanos.noCabecalho === 'N'  && servicosMeusPlanos.desabilitaServico === 'S' && !servicosMeusPlanos.removendoServico"
                                                
                                                src="./assets/desabilitaServico.svg" />
                                            <!-- <i *ngIf="servicosMeusPlanos.removendoServico" class="pi pi-spin pi-spinner"
                                                style="font-size: 22px"></i> -->
                                        </div>
                                    </div>

                                </div>
                            </ng-container>
                        </div>
                    </div>

                    <!-- PLANOS DISPONIVEIS PARA COMPRA -->
                    <div *ngIf="planosDisponiveis.length > 0">
                        <div>
                            <span class="fontCinzaTituloPlanos">Planos Disponíveis</span>
                        </div>
                        <div *ngFor="let planosDisponiveis of planosDisponiveis">

                            <div>
                                <span class="fontPlanosAgenda">{{planosDisponiveis.nome}}</span>
                            </div>


                            <ng-container *ngFor="let servicosPlanosDisponiveis of servicosPlanosDisponiveis">
                                <div *ngIf="servicosPlanosDisponiveis.nupla == planosDisponiveis.nupla">
                                    <div class="contornoServico mt-1 flex justify-content-between col">
                                        <div class="flex justify-content-start flex-column">


                                            <div class="fontServicoEdita">
                                                <span>{{servicosPlanosDisponiveis.nomeprod}}
                                                    ({{servicosPlanosDisponiveis.descrprod}})</span>
                                            </div>
                                            <div class="fontPrecoEdita">
                                                <span>{{servicosPlanosDisponiveis.minutos}} min</span>
                                            </div>

                                        </div>

                                        <div class="flex align-items-center justify-content-center">
                                            <img *ngIf="servicosPlanosDisponiveis.noCabecalho === 'N' && servicosPlanosDisponiveis.desabilitaServico === 'N' && !servicosPlanosDisponiveis.adicionandoServico"
                                                src="./assets/adicionaServico.svg" class="cursor-pointer"
                                                (click)="adicionaPlano(servicosPlanosDisponiveis); adicionaServicoAgendamento(servicosPlanosDisponiveis)" />
                                            <i *ngIf="servicosPlanosDisponiveis.adicionandoServico"
                                                class="pi pi-spin pi-spinner" style="font-size: 22px"></i>

                                            <img *ngIf="servicosPlanosDisponiveis.noCabecalho === 'S' && !servicosPlanosDisponiveis.removendoServico"
                                                src="./assets/removeServico.svg" class="cursor-pointer"
                                                (click)="removePlano(servicosPlanosDisponiveis); removeServicoAgendamento(servicosPlanosDisponiveis)" />
                                            <i *ngIf="servicosPlanosDisponiveis.removendoServico"
                                                class="pi pi-spin pi-spinner" style="font-size: 22px"></i>

                                            <img *ngIf="servicosPlanosDisponiveis.desabilitaServico === 'S' && servicosPlanosDisponiveis.noCabecalho === 'N' && !servicosPlanosDisponiveis.removendoServico"
                                                src="./assets/desabilitaServico.svg" />
                                            <!-- <i *ngIf="servicosPlanosDisponiveis.removendoServico"
                                                class="pi pi-spin pi-spinner" style="font-size: 22px"></i> -->
                                        </div>
                                    </div>

                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>



                <button pButton type="button" label="Voltar" class="col-12 mt-3" (click)="fechaDialogEditaComanda()"
                    [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)','border-radius': '11px', 'border': 'none'}"></button>
            </p-dialog>



















        </div>




    </div>
</div>
<div
    class="xl:flex xl:justify-content-center xl:flex-wrap lg:flex lg:justify-content-center lg:flex-wrap md:flex md:justify-content-center md:flex-wrap sm:flex sm:justify-content-center sm:flex-wrap">
    <div class="xl:col-5 lg:col-5 md:col-5 sm:col-5">

        <footer class="col-12" id="footerAgenda">
            <div class=" ml-3">
                <div class="retangulo ">
                    <div class="formgroup-inline fontLegenda pl-1">
                        <div class="">
                            <div class="circulo1">
                                <span class="pl-3">Agendado</span>
                            </div>
                        </div>

                        <div class="pl-7">
                            <div class="circulo2">
                                <span class="pl-3">Encaixe</span>
                            </div>
                        </div>

                        <div class="pl-6">
                            <div class="circulo3">
                                <span class="pl-3">Bloqueado</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <!-- [ngStyle]="cliclouDentro?{'background: 'var(--cor1)', 'color': 'var(--cor7)'} : {'background-color': 'var(--cor2)', 'color': 'var(--cor1)'}" -->
            <div class="flex justify-content-end flex-wrap">
                <div class="menu">
                    <button class="menu_botao menu_botao--primary" (click)="abreMenu()">
                        <img class="iconeMenu" src="./assets/iconeMenuAgenda.png" />
                    </button>
                    <div class="menu_opcoes">
                        <button class="menu_botao" (click)="mostraDialogAgendar()">
                            <span>Agendar (F8)</span>
                        </button>
                        <button class="menu_botao" (click)="mostraDialogEncaixe()">
                            <span>Encaixe (F9)</span>
                        </button>
                        <button class="menu_botao" (click)="mostraDialogBloqueio()">
                            <span>Bloqueio (F10)</span>
                        </button>
                        <button class="menu_botao" (click)="mostraDialogCadastroCli()">
                            <span>Cadastro (F2)</span>
                        </button>
                    </div>
                </div>
            </div>
        </footer>

    </div>
    <!-- DIALOG DE CONFIRMAÇÃO DE CANCELAR AGENDAMENTO -->
    <p-confirmDialog #cd [closable]="false" id="confirmCancela" styleClass="text-justify">
        <ng-template pTemplate="footer">
            <button type="button" pButton icon="pi pi-times" label="Retornar" (click)="cd.reject()"></button>
            <button type="button" pButton icon="pi pi-check" label="Confirmar" (click)="cd.accept()"></button>
        </ng-template>
    </p-confirmDialog>



    <!--                          DIALOG DO CARREGANDO SERVIÇOS              -->
    <p-dialog id="dialogCarregandoAgenda" [(visible)]="mostraCarregandoServicos" [modal]="true"
        [style]="{'width': '150px', 'height': '140px'}" [closable]="false">
        <div class="flex flex-column align-items-center mt-2">
            <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
                fill="var(--surface-ground)" animationDuration="1s"></p-progressSpinner>
            <div class="fontCarregando text-center">
                <span>Carregando serviços...</span>
            </div>
        </div>
    </p-dialog>



    <div class="flex align-items-center justify-content-center">
        <div>

        </div>
    </div>