import { Component, OnInit } from '@angular/core';
import { Estabelecimento } from 'src/assets/estabelecimento';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { EstabelecimentoService } from 'src/estabelecimento.service';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-buscar-estabelecimento',
  templateUrl: './buscar-estabelecimento.component.html',
  styleUrls: ['./buscar-estabelecimento.component.scss']
})
export class BuscarEstabelecimentoComponent implements OnInit {
  /* variaveis do formulário */
  estabelecimento: Estabelecimento[] = [];

  /* variaveis do componente */
  estabelecimentoSelec: Estabelecimento = {};

  constructor(private estabelecimentoService: EstabelecimentoService) {

  }

  ngOnInit(): void {
    this.buscarEstabelecimentos();
  }

  /** METODO PARA FAZER A BUSCA DE BARBEARIAS */
  filtroEstabelecimento() {
    let filter = (document.getElementById('pesquisa') as HTMLInputElement)!.value.toUpperCase();
    let myTable = document.getElementById('tabela');
    let tr = myTable?.getElementsByTagName('tr');
    for (let i = 0; i < tr!.length; i++) {
      let td = tr![i].getElementsByTagName('td')[0];
      if (td) {
        let textv = td.textContent || td.innerHTML;
        if (textv.toUpperCase().indexOf(filter) > -1) {
          console.log(textv)
          tr![i].style.display = "";
        } else {
          tr![i].style.display = "none";
        }
      }
    }
  }


  buscarEstabelecimentos() {
    this.estabelecimentoService.buscaEstabelecimentos(environment.CODTIPSEG).subscribe((res) => {
      //console.log(res);
      this.estabelecimento = res;
      for (let i = 0; i < this.estabelecimento.length; i++) {
        if (this.estabelecimento[i].filename! === '0' || this.estabelecimento[i].filename! === undefined || this.estabelecimento[i].filename! === null) {
          this.estabelecimento[i].filename = './assets/imgMascaraInformativo.png';
         // this.estabelecimento[i].filename = './assets/FotoPadraoEmp.png'
        } else {
          this.estabelecimento[i].filename = environment.imgEmpresa + this.estabelecimento[i].filename;
        }


      }
    });
  }


  estabelecimentoSelecionada(estabelecimento: Estabelecimento) {
    this.estabelecimentoSelec = estabelecimento;
    console.log(estabelecimento)
  }


  editarEstabelecimento(estabelecimento: Estabelecimento) {
    this.estabelecimentoService.setestabelecimento(estabelecimento);
  }



}
