<p-toast position="bottom-center" key="bc"></p-toast>
<p-toast position="top-right" key="tr"></p-toast>
<div class="pt-7">

    <div class="flex flex-column  pb-3">
        <div class="flex align-items-center justify-content-center">
            <img src="./assets/iconeConfigFinanceiro.svg" class="iconeNegociacao" />
        </div>
        <div class=" titulonegritoNegociacao flex align-items-center justify-content-center">
            <span>Tipos de Negociação</span>
        </div>


        <div class="flex justify-content-center flex-wrap ">
            <div class="flex align-items-center justify-content-center">
                <div class="scroll-divCadTpNegociacao">
                    <table id="tabela">
                        <tr *ngFor="let negociacao of negociacao">
                            <td>
                                <div class="linhahorizontalNegociacao pt-1"></div>
                                <div class="col-12 sm-12 md-4 ">
                                    <div class="flex flex-column">

                                        <div
                                            class="flex align-items-center justify-content-center tamanhoLinhaNegociacao">
                                            <div class="col ">
                                                <div class="fontTituloNegociacao">
                                                    <span class="xl:text-xl">{{negociacao.descr}}</span>
                                                </div>
                                            </div>
                                            <div class="flex align-items-center justify-content-center ">
                                                <img class="cursor-pointer" src="./assets/iconeMenuAdm.png"
                                                    (click)="menu.toggle($event); tipoNegociacaoSelecionada(negociacao)" />
                                                <p-menu #menu [popup]="true" [model]="itemsNegociacao"
                                                    styleClass="menuNegociacao"></p-menu>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>

            <p-confirmDialog #cd [closable]="false" id="confirmNegociacao">
                <ng-template pTemplate="footer">
                    <button type="button" pButton icon="pi pi-times" label="Retornar" (click)="cd.reject()"
                        id="confirmBotaoNegociacao"></button>
                    <button type="button" pButton icon="pi pi-check" label="Confirmar" (click)="cd.accept()"
                        id="confirmBotaoNegociacao"></button>
                </ng-template>
            </p-confirmDialog>

        </div>
        <div class="flex justify-content-center flex-wrap ">
            <div class="flex align-items-center justify-content-center">
                <footer class="xl:w-5" id="footerNegociacao">
                    <p-button label="Cadastrar" id="botaoNegociacao" [style]="{width: '100%'}"
                        (click)="mostraDialogTipoNegociacao()">
                    </p-button>
                </footer>
            </div>
        </div>
    </div>
</div>

<!--                          DIALOG DE CRIAÇÃO DO TIPO DE NEGOCIAÇÃO            -->
<p-dialog header="Tipo de Negociação" [(visible)]="showCadastroTipoNegociacao" [modal]="true"
    [breakpoints]="{'960px': '339px', '640px': '327px'}" id="dialogNegociacao">
    <div class="flex justify-content-center flex-wrap col">
        <div class="fontDialogNegociacao flex align-items-center justify-content-center">
            <span>Informações sobre o tipo de negociação</span>
        </div>
    </div>

    <input type="text" [(ngModel)]="descr" pInputText placeholder="Tipo de Negociação" [style]="{width: '100%'}"
        class="mt-2 mb-2">


    <div class="pb-3">
        <div class="flex gap-4">
            <p-checkbox [binary]="true" [(ngModel)]="negpadrao"></p-checkbox>
            <div class="flex align-items-center justify-content-center fontDialogCheck">
                <span>Tornar tipo de negociação como padrão.</span>
            </div>
        </div>
    </div>


    <div *ngIf="!loading" [style]="{width: '100%'}">
        <button pButton type="button" label="Salvar" class="col-12 " [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
    'border-radius': '11px'}" (click)="criaTipoNegociacao()"></button>
    </div>
    <div *ngIf="loading" [style]="{width: '100%'}">
        <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 " [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
    'border-radius': '11px'}"></button>
    </div>
</p-dialog>