import { NotificacoesService } from './../notificacoes.service';
import { Estabelecimento } from 'src/assets/estabelecimento';
import { EstabelecimentoService } from 'src/estabelecimento.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { AlertService } from 'src/alert.service';
import { Cliente } from 'src/assets/cliente';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { ParceirosService } from 'src/parceiros.service';
import { UsuarioService } from 'src/usuario.service';
import { UtilitariosService } from 'src/assets/utilitarios.service';
import { TranslateService } from '@ngx-translate/core'; //TRADUÇÃO
import { count, empty, Observable, Subscription } from 'rxjs';//TRADUÇÃO
import { TraducaoService } from 'src/traducao.service';
import { PermissoesService } from 'src/permissoes.service';

@Component({
  selector: 'app-notificacoes',
  templateUrl: './notificacoes.component.html',
  styleUrls: ['./notificacoes.component.scss'],
  providers: [MessageService],
  encapsulation: ViewEncapsulation.None
})
export class NotificacoesComponent implements OnInit {
  showWhats: boolean = false;
  showPush: boolean = false;
  showRetorno: boolean = false;
  showAniver: boolean = false;
  showTransmi: boolean = false;
  showConfirmaAgd: boolean = false;
  showLembrete: boolean = false;

  /**RADIO BUTTON */
  valorSelecionadoWhats: string = '';
  valorSelecionadoPush: string = '';

  /**VARIAVEIS DO BOTAO DE LOADING */
  teveRespostaCliente: boolean = false;

  /**VARIAVEIS DA LISTAGEM DE CLIENTES */
  clientes: Cliente[] = [];
  clienteSelecionado: any;

  /**VARIAVEIS DA LISTAGEM DE BARBEARIAS */
  estabelecimentos: Estabelecimento[] = [];
  estabelecimentoSelecionada: any;


  /** VARIAVEIS DO COMPONENT */
  loading: boolean = false;
  tipoEnvio: any[];
  tipoEnvioSelecRet: string = '';
  tipoEnvioSelecAni: string = '';
  tipoEnvioSelecTra: string = '';
  tipoEnvioSelecConfirmaAgd: string = '';
  tipoEnvioSelecLembrete: string = '';
  caracteresRestantesRetorno: number = 0;
  caracteresRestantesAniver: number = 0;
  caracteresRestantesTransmi: number = 0;
  caracteresRestantesConfirmaAgd: number = 0;
  caracteresRestantesLembrete: number = 0;
  notificacaoRetorno: string = '';
  notificacaoAniver: string = '';
  notificacaoTransmi: string = '';
  notificacaoConfirmaAgd: string = '';
  notificacaoLembrete: string = '';
  opcaoSelecionadaRetorno: string = 'D';
  opcaoSelecionadaAniver: string = 'D';
  opcaoSelecionadaConfirmaAgd: string = 'D';
  opcaoSelecionadaLembrete: string = 'D';
  opcoesNot: any[];
  opcoesCliente: any[];
  opcaoSelecionadaCliente: number = 0;
  codcli: string = '';
  codusu: string = '';
  codfunc: string = '';
  statusNot: any;
  statusPush: boolean = false;
  statusWpp: boolean = false;
  statusRet: boolean = true;
  statusCampoRet: boolean = true;
  statusAni: boolean = true;
  statusCampoAni: boolean = true;
  statusTra: boolean = false;
  statusAgd: boolean = false;
  statusCampoAgd: boolean = true;
  statusCampoLembrete: boolean = true;
  statusLembrete: boolean = false;

  /**TRADUCAO */
  lang: string = "en";
  //subscription: Subscription;

  /**VARIAVEIS DE PERMISSÕES */
  permissao: any;
  cadastrar: boolean = false;
  alterar: boolean = false;
  envTransmissao: boolean = false;

  constructor(private decodedTokenService: DecodedTokenService, private parceirosService: ParceirosService, private alertService: AlertService,
    private messageService: MessageService, private usuarioService: UsuarioService, private estabelecimentoService: EstabelecimentoService, private permissoesService: PermissoesService,
    private notificacoesService: NotificacoesService, private utilitariosService: UtilitariosService, public translate: TranslateService, public primeNGConfig: PrimeNGConfig,
    private traducaoSerice: TraducaoService) {
    this.tipoEnvio = [{ 'nuTipo': '1', 'tipo': 'WhatsApp' }, { 'nuTipo': '2', 'tipo': 'Push' }, { 'nuTipo': '3', 'tipo': 'WhatsApp + Push' }];
    this.opcoesNot = [{ 'opTipo': 'A', 'opcao': 'Ativar' }, { 'opTipo': 'D', 'opcao': 'Desativar' }];
    this.opcoesCliente = [{ 'opcao': 1, 'descr': 'Todos' }, { 'opcao': 2, 'descr': 'Clientes novos' }, { 'opcao': 3, 'descr': 'Clientes sem vir há 20 dias' }, { 'opcao': 4, 'descr': 'Clientes sem vir há 40 dias' },];
    /**TRADUÇÃO */
    this.traducaoSerice.changeLang();
    // translate.addLangs(['en', 'pt']);
    // translate.setDefaultLang('pt');
    // const browserLang = translate.getBrowserLang();
    // let lang = browserLang!.match(/en|pt/) ? browserLang : 'pt';
    // this.changeLang(lang!);
    // this.subscription = this.translate.stream('primeng').subscribe(data => {
    //   this.primeNGConfig.setTranslation(data);
    // });
  }



  ngOnInit(): void {
    this.recuperaCodCli();
    this.recuperaCodUsu();
    this.recuperaPerfil();
  }

  /**TRADUÇÃO */
  changeLang(lang: string) {
    this.translate.use(lang);
  }

  recuperaCodUsu() {
    this.codusu = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }

  recuperaCodCli() {
    this.codcli = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODCLI;
    this.recuperaPermissao();
    this.buscaStatusNot();
  }


  mostraDialogWhats() {
    /**VERIFICA SEM TEM PERMISSÃO PARA ESSA AÇÃO */
    if (this.alterar || Number(this.codcli) > 0) {
      this.showWhats = true;
    } else {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
  }

  mostraDialogPush() {
    /**VERIFICA SEM TEM PERMISSÃO PARA ESSA AÇÃO */
    if (this.alterar || Number(this.codcli) > 0) {
      this.showPush = true;
    } else {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
  }

  mostraDialogRetorno() {
    /**VERIFICA SEM TEM PERMISSÃO PARA ESSA AÇÃO */
    if ((this.alterar && this.notificacaoRetorno != '') || (this.cadastrar && this.notificacaoRetorno === '') || Number(this.codcli) > 0) {
      this.showRetorno = true;
      this.buscaStatusNot();
      this.contaCaracter();
    } else {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
  }

  mostraDialogAniver() {
    /**VERIFICA SEM TEM PERMISSÃO PARA ESSA AÇÃO */
    if ((this.alterar && this.notificacaoAniver != '') || (this.cadastrar && this.notificacaoAniver === '') || Number(this.codcli) > 0) {
      this.showAniver = true;
      this.buscaStatusNot();
      this.contaCaracter();
    } else {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
  }

  mostraDialogTransmi() {
    /**VERIFICA SEM TEM PERMISSÃO PARA ESSA AÇÃO */
    if (this.envTransmissao || Number(this.codcli) > 0) {
      this.showTransmi = true;
      this.contaCaracter();
    } else {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
  }

  mostraDialogConfirmaAgd() {
    /**VERIFICA SEM TEM PERMISSÃO PARA ESSA AÇÃO */
    //if (this.envTransmissao || Number(this.codcli) > 0) {
    this.showConfirmaAgd = true;
    this.contaCaracter();
    // } else {
    //   this.showError('Atenção', 'Ação não permitida.')
    //   return
    // }
  }

  mostraDialogLembrete() {
    /**VERIFICA SEM TEM PERMISSÃO PARA ESSA AÇÃO */
    // if (this.envTransmissao || Number(this.codcli) > 0) {
    this.showLembrete = true;
    this.contaCaracter();
    // } else {
    //   this.showError('Atenção', 'Ação não permitida.')
    //   return
    // }
  }


  recuperaPerfil() {
    this.usuarioService.getPerfil(this.codusu, this.codusu).subscribe((res) => {
      this.codfunc = Object(res)[0].codfunc;
      this.recuperaEstabelecimentos();
      //  this.recuperaClientes();
    })
  }

  /**METODO PARA CONTAR OS CARACTERES DA PERGUNTA */
  contaCaracter() {
    if (this.showRetorno) {
      if (this.caracteresRestantesRetorno < 351) {
        this.caracteresRestantesRetorno = this.notificacaoRetorno.length;
      } else {
        return;
      }
    }

    if (this.showAniver) {
      if (this.caracteresRestantesAniver < 351) {
        this.caracteresRestantesAniver = this.notificacaoAniver.length;
      } else {
        return;
      }
    }

    if (this.showTransmi) {
      if (this.caracteresRestantesTransmi < 351) {
        this.caracteresRestantesTransmi = this.notificacaoTransmi.length;
      } else {
        return;
      }
    }

    if (this.showConfirmaAgd) {
      if (this.caracteresRestantesConfirmaAgd < 351) {
        this.caracteresRestantesConfirmaAgd = this.notificacaoConfirmaAgd.length;
      } else {
        return;
      }
    }

    if (this.showLembrete) {
      if (this.caracteresRestantesLembrete < 351) {
        this.caracteresRestantesLembrete = this.notificacaoLembrete.length;
      } else {
        return;
      }
    }
  }

  /**CLIENTES QUE VÃO NO DROPPDOWN */
  recuperaClientes() {
    this.teveRespostaCliente = true;

    let auxCodCli = 0;

    if (this.envTransmissao) {
      auxCodCli = this.permissao[0].codcli;
    } else {
      auxCodCli = Number(this.codcli);
    }

    setTimeout(() => {
      this.parceirosService.buscaClientesGeralEspecial(0, auxCodCli, this.estabelecimentoSelecionada, this.opcaoSelecionadaCliente, this.codusu).subscribe((res) => {
        this.teveRespostaCliente = false;
        this.clientes = res;
        console.log(res)
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.teveRespostaCliente = false;
          this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')
        })
    }, 500);
  }

  recuperaEstabelecimentos() {
    /**VERIFICA SE VAI FAZER A BUSCA PELO CODCLI DO TOKEN OU DA PERMISSÃO */
    if (this.permissao != undefined && this.permissao.length > 0) {
      this.estabelecimentoService.buscarEstabelecimentosporCodCli(this.permissao[0].codcli, this.codusu).subscribe((res) => {
        this.estabelecimentos = res;
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')
        })
    } else {
      this.estabelecimentoService.buscarEstabelecimentosporCodCli(Number(this.codcli), this.codusu).subscribe((res) => {
        this.estabelecimentos = res;
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')
        })
    }
  }


  ativaDesativaNotWpp() {
    this.loading = true;
    /**VERIFICA SE VAI FAZER A BUSCA PELO CODCLI DO TOKEN OU DA PERMISSÃO */
    if (this.permissao != undefined && this.permissao.length > 0) {
      this.notificacoesService.buscaParametrosNotificacoesPorCodcli(this.permissao[0].codcli, this.codusu).subscribe((res) => { //VERIFICA O STATUS DA NOTIFICAÇÃO
        this.statusNot = res;
        if (this.statusNot[0].ativanotwpp != this.valorSelecionadoWhats) { //SE A OPÇÃO SELECIONADA PELO USUARIO FOR DIFERENTE ENTÃO FAZ A REQUISIÇÃO DE ATV/DSTV
          this.notificacoesService.ativarDesativarNotWpp(this.permissao[0].codcli, this.codusu).subscribe((res) => {
            this.showWhats = false;
            this.loading = false;
            // if (this.statusNot[0].ativanotwpp === 'S') {
            if (this.valorSelecionadoWhats === 'S') {
              this.alertService.alert('Notificação por whatsapp ativada!', 'success')
              this.statusWpp = true;
              console.log(this.statusWpp)
            }
            //if (this.statusNot[0].ativanotpush === 'N') {
            else if (this.valorSelecionadoWhats === 'N') {
              this.alertService.alert('Notificação por whatsapp desativada!', 'success')
              this.statusWpp = false;
              console.log(this.statusWpp)
            }
            console.log(res)
          },
            (error) => {
              console.log(error)
              console.log('deu ruim')
              this.loading = false;
              this.showWhats = false;
              this.alertService.alert('Algo deu errado.', 'error');
            });
        }
        this.loading = false;
      })
    } else {
      this.notificacoesService.buscaParametrosNotificacoesPorCodcli(this.codcli, this.codusu).subscribe((res) => { //VERIFICA O STATUS DA NOTIFICAÇÃO
        this.statusNot = res;
        if (this.statusNot[0].ativanotwpp != this.valorSelecionadoWhats) { //SE A OPÇÃO SELECIONADA PELO USUARIO FOR DIFERENTE ENTÃO FAZ A REQUISIÇÃO DE ATV/DSTV
          this.notificacoesService.ativarDesativarNotWpp(this.codcli, this.codusu).subscribe((res) => {
            this.showWhats = false;
            this.loading = false;
            // if (this.statusNot[0].ativanotwpp === 'S') {
            if (this.valorSelecionadoWhats === 'S') {
              this.alertService.alert('Notificação por whatsapp ativada!', 'success')
              this.statusWpp = true;
              console.log(this.statusWpp)
            }
            //if (this.statusNot[0].ativanotpush === 'N') {
            else if (this.valorSelecionadoWhats === 'N') {
              this.alertService.alert('Notificação por whatsapp desativada!', 'success')
              this.statusWpp = false;
              console.log(this.statusWpp)
            }
            console.log(res)
          },
            (error) => {
              console.log(error)
              console.log('deu ruim')
              this.loading = false;
              this.showWhats = false;
              this.alertService.alert('Algo deu errado.', 'error');
            });
        }
        this.loading = false;
      })
    }
  }

  ativaDesativaNotPush() {
    this.loading = true;
    /**VERIFICA SE VAI FAZER A BUSCA PELO CODCLI DO TOKEN OU DA PERMISSÃO */
    if (this.permissao != undefined && this.permissao.length > 0) {
      this.notificacoesService.buscaParametrosNotificacoesPorCodcli(this.permissao[0].codcli, this.codusu).subscribe((res) => { //VERIFICA O STATUS DA NOTIFICAÇÃO
        this.statusNot = res;
        if (this.statusNot[0].ativanotpush != this.valorSelecionadoPush) { //SE A OPÇÃO SELECIONADA PELO USUARIO FOR DIFERENTE ENTÃO FAZ A REQUISIÇÃO DE ATV/DSTV
          this.notificacoesService.ativarDesativarNotPush(this.permissao[0].codcli, this.codusu).subscribe((res) => {
            this.showPush = false;
            this.loading = false;
            if (this.valorSelecionadoPush === 'S') {
              console.log(this.statusNot[0].ativanotpush)
              this.alertService.alert('Notificação por push ativada!', 'success')
              this.statusPush = true;
              console.log(this.statusPush)
            }
            if (this.valorSelecionadoPush === 'N') {
              console.log(this.statusNot[0].ativanotpush)
              this.alertService.alert('Notificação por push desativada!', 'success')
              this.statusPush = false;
              console.log(this.statusPush)
            }
            console.log(res)
          },
            (error) => {
              console.log(error)
              console.log('deu ruim')
              this.loading = false;
              this.showPush = false;
              this.alertService.alert('Algo deu errado.', 'error');
              //this.showError('Atenção', 'Algo deu errado.')
            });
        }
        this.loading = false;
      })
    } else {
      this.notificacoesService.buscaParametrosNotificacoesPorCodcli(this.codcli, this.codusu).subscribe((res) => { //VERIFICA O STATUS DA NOTIFICAÇÃO
        this.statusNot = res;
        if (this.statusNot[0].ativanotpush != this.valorSelecionadoPush) { //SE A OPÇÃO SELECIONADA PELO USUARIO FOR DIFERENTE ENTÃO FAZ A REQUISIÇÃO DE ATV/DSTV
          this.notificacoesService.ativarDesativarNotPush(this.codcli, this.codusu).subscribe((res) => {
            this.showPush = false;
            this.loading = false;
            if (this.valorSelecionadoPush === 'S') {
              console.log(this.statusNot[0].ativanotpush)
              this.alertService.alert('Notificação por push ativada!', 'success')
              this.statusPush = true;
              console.log(this.statusPush)
            }
            if (this.valorSelecionadoPush === 'N') {
              console.log(this.statusNot[0].ativanotpush)
              this.alertService.alert('Notificação por push desativada!', 'success')
              this.statusPush = false;
              console.log(this.statusPush)
            }
            console.log(res)
          },
            (error) => {
              console.log(error)
              console.log('deu ruim')
              this.loading = false;
              this.showPush = false;
              this.alertService.alert('Algo deu errado.', 'error');
              //this.showError('Atenção', 'Algo deu errado.')
            });
        }
        this.loading = false;
      })
    }
  }

  /**VERIFICA SE A NOTIFICAÇÕES ESTÃO OU NÃO ATIVADAS E RECUPERA SEUS CAMPOS */
  buscaStatusNot() {

    /**VERIFICA SE VAI FAZER A BUSCA PELO CODCLI DO TOKEN OU DA PERMISSÃO */
    if (this.permissao != undefined && this.permissao.length > 0) {

      this.notificacoesService.buscaParametrosNotificacoesPorCodcli(this.permissao[0].codcli, this.codusu).subscribe((res) => {
        this.statusNot = res;
        /**STATUS NOTIFICAÇÃO PUSH */
        if (this.statusNot[0].ativanotpush === 'S') {
          this.statusPush = true;
          this.valorSelecionadoPush = 'S';
        } else {
          this.statusPush = false;
          this.valorSelecionadoPush = 'N';
        }

        /**STATUS NOTIFICAÇÃO WPP */

        if (this.statusNot[0].ativanotwpp === 'S') {
          this.statusWpp = true;
          this.valorSelecionadoWhats = 'S';
        } else {
          this.statusWpp = false;
          this.valorSelecionadoWhats = 'N';
        }

        /**STATUS NOTIFICAÇÃO RETORNO */
        if (this.statusNot[0].tipnotretorno != null) {
          this.opcaoSelecionadaRetorno = 'A';
          this.statusRet = true;
          this.statusCampoRet = false;
          this.tipoEnvioSelecRet = this.statusNot[0].tipnotretorno;
          this.notificacaoRetorno = this.statusNot[0].notretorno;
          console.log(this.opcaoSelecionadaRetorno)
        } else {
          this.opcaoSelecionadaRetorno = 'D';
          this.statusRet = false;
          this.statusCampoRet = true;
        }

        /**STATUS NOTIFICAÇÃO ANIVERSARIANTE */
        if (this.statusNot[0].tipnotaniversariante != null) {
          this.opcaoSelecionadaAniver = 'A';
          this.statusAni = true;
          this.statusCampoAni = false;
          this.tipoEnvioSelecAni = this.statusNot[0].tipnotaniversariante;
          this.notificacaoAniver = this.statusNot[0].notaniversariante;
        } else {
          this.statusAni = false;
          this.statusCampoAni = true;
          this.opcaoSelecionadaAniver = 'D';
        }

        /**STATUS NOTIFICAÇÃO LEMBRETE */
        if (this.statusNot[0].tipnotlembrete != null) {
          this.opcaoSelecionadaLembrete = 'A';
          this.statusLembrete = true;
          this.statusCampoLembrete = false;
          this.tipoEnvioSelecLembrete = this.statusNot[0].tipnotlembrete;
          this.notificacaoLembrete = this.statusNot[0].notlembrete;
        } else {
          this.statusLembrete = false;
          this.statusCampoLembrete = true;
          this.opcaoSelecionadaLembrete = 'D';
        }

        /**STATUS NOTIFICAÇÃO AGENDAMENTO */
        if (this.statusNot[0].tipnotagd != null) {
          this.opcaoSelecionadaConfirmaAgd = 'A';
          this.statusAgd = true;
          this.statusCampoAgd = false;
          this.tipoEnvioSelecConfirmaAgd = this.statusNot[0].tipnotagd;
          this.notificacaoConfirmaAgd = this.statusNot[0].notagd;
        } else {
          this.statusAgd = false;
          this.statusCampoAgd = true;
          this.opcaoSelecionadaConfirmaAgd = 'D';
        }
      })
    } else {

      this.notificacoesService.buscaParametrosNotificacoesPorCodcli(this.codcli, this.codusu).subscribe((res) => {
        this.statusNot = res;
        /**STATUS NOTIFICAÇÃO PUSH */
        if (this.statusNot[0].ativanotpush === 'S') {
          this.statusPush = true;
          this.valorSelecionadoPush = 'S';
        } else {
          this.statusPush = false;
          this.valorSelecionadoPush = 'N';
        }

        /**STATUS NOTIFICAÇÃO WPP */

        if (this.statusNot[0].ativanotwpp === 'S') {
          this.statusWpp = true;
          this.valorSelecionadoWhats = 'S';
        } else {
          this.statusWpp = false;
          this.valorSelecionadoWhats = 'N';
        }

        /**STATUS NOTIFICAÇÃO RETORNO */
        if (this.statusNot[0].tipnotretorno != null) {
          this.opcaoSelecionadaRetorno = 'A';
          this.statusRet = true;
          this.statusCampoRet = false;
          this.tipoEnvioSelecRet = this.statusNot[0].tipnotretorno;
          this.notificacaoRetorno = this.statusNot[0].notretorno;
          console.log(this.opcaoSelecionadaRetorno)
        } else {
          this.opcaoSelecionadaRetorno = 'D';
          this.statusRet = false;
          this.statusCampoRet = true;
        }

        /**STATUS NOTIFICAÇÃO ANIVERSARIANTE */
        if (this.statusNot[0].tipnotaniversariante != null) {
          this.opcaoSelecionadaAniver = 'A';
          this.statusAni = true;
          this.statusCampoAni = false;
          this.tipoEnvioSelecAni = this.statusNot[0].tipnotaniversariante;
          this.notificacaoAniver = this.statusNot[0].notaniversariante;
        } else {
          this.statusAni = false;
          this.statusCampoAni = true;
          this.opcaoSelecionadaAniver = 'D';
        }

        /**STATUS NOTIFICAÇÃO LEMBRETE */
        if (this.statusNot[0].tipnotlembrete != null) {
          this.opcaoSelecionadaLembrete = 'A';
          this.statusLembrete = true;
          this.statusCampoLembrete = false;
          this.tipoEnvioSelecLembrete = this.statusNot[0].tipnotlembrete;
          this.notificacaoLembrete = this.statusNot[0].notlembrete;
        } else {
          this.statusLembrete = false;
          this.statusCampoLembrete = true;
          this.opcaoSelecionadaLembrete = 'D';
        }

        /**STATUS NOTIFICAÇÃO AGENDAMENTO */
        if (this.statusNot[0].tipnotagd != null) {
          this.opcaoSelecionadaConfirmaAgd = 'A';
          this.statusAgd = true;
          this.statusCampoAgd = false;
          this.tipoEnvioSelecConfirmaAgd = this.statusNot[0].tipnotagd;
          this.notificacaoConfirmaAgd = this.statusNot[0].notagd;
        } else {
          this.statusAgd = false;
          this.statusCampoAgd = true;
          this.opcaoSelecionadaConfirmaAgd = 'D';
        }
      })
    }
  }

  /**ATIVA/DESATIVA OS CAMPOS DE NOTIFICAÇÕES */
  ativaCampoNot() {
    if (this.opcaoSelecionadaRetorno != 'A') {
      this.tipoEnvioSelecRet = '';
      this.notificacaoRetorno = '';
      this.statusCampoRet = true;
    } else {
      this.statusCampoRet = false;
    }

    if (this.opcaoSelecionadaAniver != 'A') {
      this.tipoEnvioSelecAni = '';
      this.notificacaoAniver = '';
      this.statusCampoAni = true;
    } else {
      this.statusCampoAni = false;
    }

    if (this.opcaoSelecionadaConfirmaAgd != 'A') {
      this.tipoEnvioSelecConfirmaAgd = '';
      this.notificacaoConfirmaAgd = '';
      this.statusCampoAgd = true;
    } else {
      this.statusCampoAgd = false;
    }

    if (this.opcaoSelecionadaLembrete != 'A') {
      this.tipoEnvioSelecLembrete = '';
      this.notificacaoLembrete = '';
      this.statusCampoLembrete = true;
    } else {
      this.statusCampoLembrete = false;
    }
  }

  criaNotRetorno() {
    this.loading = true;


    /**VERIFICA SE VAI FAZER A BUSCA PELO CODCLI DO TOKEN OU DA PERMISSÃO */
    if (this.permissao != undefined && this.permissao.length > 0) {

      /**USA O CODCLI DA REQUISIÇÃO DE PERMISSÕES */
      if (this.opcaoSelecionadaRetorno === 'A') {
        if (this.utilitariosService.validaCampo([this.tipoEnvioSelecRet, this.notificacaoRetorno])) {
          console.log('É necessário preencher todos os campos de sua notificação.')
          this.loading = false;
          this.showError('Atenção', 'É necessário preencher todos os campos de sua notificação.')
          return;
        }

        this.notificacoesService.atualizarNotificacaoRetorno(this.tipoEnvioSelecRet, this.notificacaoRetorno, this.permissao[0].codcli, this.codusu).subscribe((res) => {
          console.log(res);
          this.alertService.alert('Notificação de retorno atualizada!', 'success');
          this.showRetorno = false;
          this.statusRet = true;
          this.loading = false;
        },
          (error) => {
            console.log(error)
            console.log('deu ruim')
            this.loading = false;
            this.showRetorno = false
            this.alertService.alert('Algo deu errado.', 'error');
            //this.showError('Atenção', 'Algo deu errado.')
          });
      } else {
        this.notificacoesService.atualizarNotificacaoRetorno(null, null, this.permissao[0].codcli, this.codusu).subscribe((res) => {
          console.log(res);
          this.alertService.alert('Notificação de retorno desativada!', 'success');
          this.statusRet = false;
          this.tipoEnvioSelecRet = '';
          this.notificacaoRetorno = '';
          this.showRetorno = false
          this.loading = false;
        },
          (error) => {
            console.log(error)
            console.log('deu ruim')
            this.loading = false;
            this.showRetorno = false
            this.alertService.alert('Algo deu errado.', 'error');
            //this.showError('Atenção', 'Algo deu errado.')
          });
      }

    }

    /**USA O CODCLI DO ADMIN */
    else {
      if (this.opcaoSelecionadaRetorno === 'A') {
        if (this.utilitariosService.validaCampo([this.tipoEnvioSelecRet, this.notificacaoRetorno])) {
          console.log('É necessário preencher todos os campos de sua notificação.')
          this.loading = false;
          this.showError('Atenção', 'É necessário preencher todos os campos de sua notificação.')
          return;
        }

        this.notificacoesService.atualizarNotificacaoRetorno(this.tipoEnvioSelecRet, this.notificacaoRetorno, this.codcli, this.codusu).subscribe((res) => {
          console.log(res);
          this.alertService.alert('Notificação de retorno atualizada!', 'success');
          this.showRetorno = false;
          this.statusRet = true;
          this.loading = false;
        },
          (error) => {
            console.log(error)
            console.log('deu ruim')
            this.loading = false;
            this.showRetorno = false
            this.alertService.alert('Algo deu errado.', 'error');
            //this.showError('Atenção', 'Algo deu errado.')
          });
      } else {
        this.notificacoesService.atualizarNotificacaoRetorno(null, null, this.codcli, this.codusu).subscribe((res) => {
          console.log(res);
          this.alertService.alert('Notificação de retorno desativada!', 'success');
          this.statusRet = false;
          this.tipoEnvioSelecRet = '';
          this.notificacaoRetorno = '';
          this.showRetorno = false
          this.loading = false;
        },
          (error) => {
            console.log(error)
            console.log('deu ruim')
            this.loading = false;
            this.showRetorno = false
            this.alertService.alert('Algo deu errado.', 'error');
            //this.showError('Atenção', 'Algo deu errado.')
          });
      }
    }
  }

  criaNotAniversario() {
    this.loading = true;

    /**VERIFICA SE VAI FAZER A BUSCA PELO CODCLI DO TOKEN OU DA PERMISSÃO */
    if (this.permissao != undefined && this.permissao.length > 0) {
      /**USA O CODCLI DA REQUISIÇÃO DE PERMISSÕES */

      if (this.opcaoSelecionadaAniver === 'A') {
        if (this.utilitariosService.validaCampo([this.tipoEnvioSelecAni, this.notificacaoAniver])) {
          console.log('É necessário preencher todos os campos de sua notificação.')
          this.loading = false;
          this.showError('Atenção', 'É necessário preencher todos os campos de sua notificação.')
          return;
        }

        this.notificacoesService.atualizarNotificacaoAniversariante(this.tipoEnvioSelecAni, this.notificacaoAniver, this.permissao[0].codcli, this.codusu).subscribe((res) => {
          console.log(res);
          this.alertService.alert('Notificação de aniversariante atualizada!', 'success');
          this.statusAni = true;
          this.showAniver = false;
          this.loading = false;
        },
          (error) => {
            console.log(error)
            console.log('deu ruim')
            this.loading = false;
            this.showAniver = false
            this.alertService.alert('Algo deu errado.', 'error');
            //this.showError('Atenção', 'Algo deu errado.')
          });
      } else {
        this.notificacoesService.atualizarNotificacaoAniversariante(null, null, this.permissao[0].codcli, this.codusu).subscribe((res) => {
          console.log(res);
          this.alertService.alert('Notificação de aniversariante desativada!', 'success');
          this.statusAni = false;
          this.tipoEnvioSelecAni = '';
          this.notificacaoAniver = '';
          this.showAniver = false;
          this.loading = false;
        },
          (error) => {
            console.log(error)
            console.log('deu ruim')
            this.loading = false;
            this.showAniver = false
            this.alertService.alert('Algo deu errado.', 'error');
            //this.showError('Atenção', 'Algo deu errado.')
          });
      }
    }

    /**USA O CODCLI DO ADMIN */
    else {
      if (this.opcaoSelecionadaAniver === 'A') {
        if (this.utilitariosService.validaCampo([this.tipoEnvioSelecAni, this.notificacaoAniver])) {
          console.log('É necessário preencher todos os campos de sua notificação.')
          this.loading = false;
          this.showError('Atenção', 'É necessário preencher todos os campos de sua notificação.')
          return;
        }

        this.notificacoesService.atualizarNotificacaoAniversariante(this.tipoEnvioSelecAni, this.notificacaoAniver, this.codcli, this.codusu).subscribe((res) => {
          console.log(res);
          this.alertService.alert('Notificação de aniversariante atualizada!', 'success');
          this.statusAni = true;
          this.showAniver = false;
          this.loading = false;
        },
          (error) => {
            console.log(error)
            console.log('deu ruim')
            this.loading = false;
            this.showAniver = false
            this.alertService.alert('Algo deu errado.', 'error');
            //this.showError('Atenção', 'Algo deu errado.')
          });
      } else {
        this.notificacoesService.atualizarNotificacaoAniversariante(null, null, this.codcli, this.codusu).subscribe((res) => {
          console.log(res);
          this.alertService.alert('Notificação de aniversariante desativada!', 'success');
          this.statusAni = false;
          this.tipoEnvioSelecAni = '';
          this.notificacaoAniver = '';
          this.showAniver = false;
          this.loading = false;
        },
          (error) => {
            console.log(error)
            console.log('deu ruim')
            this.loading = false;
            this.showAniver = false
            this.alertService.alert('Algo deu errado.', 'error');
            //this.showError('Atenção', 'Algo deu errado.')
          });
      }
    }
  }


  criaNotificacaoInstantanea() {
    this.loading = true;

    if (this.utilitariosService.validaCampo([this.tipoEnvioSelecTra, this.estabelecimentoSelecionada, this.clienteSelecionado, this.notificacaoTransmi])) {
      console.log('É necessário preencher todos os campos de sua notificação.')
      this.loading = false;
      this.showError('Atenção', 'É necessário preencher todos os campos de sua notificação.')
      return;
    }
    for (let i = 0; i < this.clienteSelecionado.length; i++) {
      this.notificacoesService.criaNotificacaoInstantanea(this.clienteSelecionado[i], this.tipoEnvioSelecTra, this.notificacaoTransmi, this.codusu).subscribe((res) => {
        console.log(res);
        this.alertService.alert('Pronto!', 'success');
        this.loading = false;
        this.showTransmi = false;
        this.tipoEnvioSelecTra = '';
        this.estabelecimentoSelecionada = '';
        this.clienteSelecionado = '';
        this.notificacaoTransmi = '';
        this.opcaoSelecionadaCliente = 0;
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.loading = false;
          this.showAniver = false
          this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')
        });

    }

  }

  /**RECUPERA AS PERMISSÕES */
  recuperaPermissao() {
    this.permissao = this.permissoesService.getPermissao();
    console.log(this.permissao)
    if (this.permissao != undefined && this.permissao.length > 0) {
      if (this.permissao[6].acao[0].PERMITE === 'S') {
        this.cadastrar = true;
      }
      if (this.permissao[6].acao[1].PERMITE === 'S') {
        this.alterar = true;
      }
      if (this.permissao[6].acao[2].PERMITE === 'S') {
        this.envTransmissao = true;
      }
    }
  }





  /**METODO DE TOAST */
  showError(titulo: string, mensagem: string) {
    if (screen.width < 640 || screen.height < 480) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else if (screen.width < 1024 || screen.height < 768) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else {
      this.messageService.add({
        key: 'tr',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    }
  }


  criaNotAgendamento() {
    this.loading = true;

    console.log(this.opcaoSelecionadaLembrete)
    /**VERIFICA SE VAI FAZER A BUSCA PELO CODCLI DO TOKEN OU DA PERMISSÃO */
    if (this.permissao != undefined && this.permissao.length > 0) {

      /**USA O CODCLI DA REQUISIÇÃO DE PERMISSÕES */
      if (this.opcaoSelecionadaLembrete === 'A') {
        if (this.utilitariosService.validaCampo([this.tipoEnvioSelecConfirmaAgd, this.notificacaoConfirmaAgd])) {
          console.log('É necessário preencher todos os campos de sua notificação.')
          this.loading = false;
          this.showError('Atenção', 'É necessário preencher todos os campos de sua notificação.')
          return;
        }

        this.notificacoesService.atualizarNotificacaoAgendamento(this.tipoEnvioSelecConfirmaAgd, this.notificacaoConfirmaAgd, this.permissao[0].codcli, this.codusu).subscribe((res) => {
          console.log(res);
          this.alertService.alert('Notificação de confirmação de agendamento atualizada!', 'success');
          this.showConfirmaAgd = false;
          this.statusAgd = true;
          this.loading = false;
        },
          (error) => {
            console.log(error)
            console.log('deu ruim')
            this.loading = false;
            this.showConfirmaAgd = false
            this.alertService.alert('Algo deu errado.', 'error');
            //this.showError('Atenção', 'Algo deu errado.')
          });
      } else {
        this.notificacoesService.atualizarNotificacaoAgendamento(null, null, this.permissao[0].codcli, this.codusu).subscribe((res) => {
          console.log(res);
          this.alertService.alert('Notificação de confirmação de agendamento desativada!', 'success');
          this.statusAgd = false;
          this.tipoEnvioSelecConfirmaAgd = '';
          this.notificacaoConfirmaAgd = '';
          this.showConfirmaAgd = false
          this.loading = false;
        },
          (error) => {
            console.log(error)
            console.log('deu ruim')
            this.loading = false;
            this.showConfirmaAgd = false
            this.alertService.alert('Algo deu errado.', 'error');
            //this.showError('Atenção', 'Algo deu errado.')
          });
      }

    }

    /**USA O CODCLI DO ADMIN */
    else {
      if (this.opcaoSelecionadaLembrete === 'A') {
        if (this.utilitariosService.validaCampo([this.tipoEnvioSelecConfirmaAgd, this.notificacaoConfirmaAgd])) {
          console.log('É necessário preencher todos os campos de sua notificação.')
          this.loading = false;
          this.showError('Atenção', 'É necessário preencher todos os campos de sua notificação.')
          return;
        }

        this.notificacoesService.atualizarNotificacaoAgendamento(this.tipoEnvioSelecConfirmaAgd, this.notificacaoConfirmaAgd, this.codcli, this.codusu).subscribe((res) => {
          console.log(res);
          this.alertService.alert('Notificação de confirmação de agendamento atualizada!', 'success');
          this.showConfirmaAgd = false;
          this.statusAgd = true;
          this.loading = false;
        },
          (error) => {
            console.log(error)
            console.log('deu ruim')
            this.loading = false;
            this.showConfirmaAgd = false
            this.alertService.alert('Algo deu errado.', 'error');
            //this.showError('Atenção', 'Algo deu errado.')
          });
      } else {
        this.notificacoesService.atualizarNotificacaoAgendamento(null, null, this.codcli, this.codusu).subscribe((res) => {
          console.log(res);
          this.alertService.alert('Notificação de confirmação de agendamento desativada!', 'success');
          this.statusAgd = false;
          this.tipoEnvioSelecConfirmaAgd = '';
          this.notificacaoConfirmaAgd = '';
          this.showConfirmaAgd = false
          this.loading = false;
        },
          (error) => {
            console.log(error)
            console.log('deu ruim')
            this.loading = false;
            this.showConfirmaAgd = false
            this.alertService.alert('Algo deu errado.', 'error');
            //this.showError('Atenção', 'Algo deu errado.')
          });
      }
    }
  }

  criaNotLembrete() {
    this.loading = true;


    /**VERIFICA SE VAI FAZER A BUSCA PELO CODCLI DO TOKEN OU DA PERMISSÃO */
    if (this.permissao != undefined && this.permissao.length > 0) {

      /**USA O CODCLI DA REQUISIÇÃO DE PERMISSÕES */
      if (this.opcaoSelecionadaLembrete === 'A') {
        if (this.utilitariosService.validaCampo([this.tipoEnvioSelecLembrete, this.notificacaoLembrete])) {
          console.log('É necessário preencher todos os campos de sua notificação.')
          this.loading = false;
          this.showError('Atenção', 'É necessário preencher todos os campos de sua notificação.')
          return;
        }

        this.notificacoesService.atualizarNotificacaoLembrete(this.tipoEnvioSelecLembrete, this.notificacaoLembrete, this.permissao[0].codcli, this.codusu).subscribe((res) => {
          console.log(res);
          this.alertService.alert('Notificação de lembrete de agendamento atualizada!', 'success');
          this.showLembrete = false;
          this.statusLembrete = true;
          this.loading = false;
        },
          (error) => {
            console.log(error)
            console.log('deu ruim')
            this.loading = false;
            this.showLembrete = false
            this.alertService.alert('Algo deu errado.', 'error');
            //this.showError('Atenção', 'Algo deu errado.')
          });
      } else {
        this.notificacoesService.atualizarNotificacaoLembrete(null, null, this.permissao[0].codcli, this.codusu).subscribe((res) => {
          console.log(res);
          this.alertService.alert('Notificação de lembrete de agendamento desativada!', 'success');
          this.statusLembrete = false;
          this.tipoEnvioSelecLembrete = '';
          this.notificacaoLembrete = '';
          this.showLembrete = false
          this.loading = false;
        },
          (error) => {
            console.log(error)
            console.log('deu ruim')
            this.loading = false;
            this.showLembrete = false
            this.alertService.alert('Algo deu errado.', 'error');
            //this.showError('Atenção', 'Algo deu errado.')
          });
      }

    }

    /**USA O CODCLI DO ADMIN */
    else {
      if (this.opcaoSelecionadaLembrete === 'A') {
        if (this.utilitariosService.validaCampo([this.tipoEnvioSelecLembrete, this.notificacaoLembrete])) {
          console.log('É necessário preencher todos os campos de sua notificação.')
          this.loading = false;
          this.showError('Atenção', 'É necessário preencher todos os campos de sua notificação.')
          return;
        }

        this.notificacoesService.atualizarNotificacaoLembrete(this.tipoEnvioSelecLembrete, this.notificacaoLembrete, this.codcli, this.codusu).subscribe((res) => {
          console.log(res);
          this.alertService.alert('Notificação de lembrete de agendamento atualizada!', 'success');
          this.showLembrete = false;
          this.statusLembrete = true;
          this.loading = false;
        },
          (error) => {
            console.log(error)
            console.log('deu ruim')
            this.loading = false;
            this.showLembrete = false
            this.alertService.alert('Algo deu errado.', 'error');
            //this.showError('Atenção', 'Algo deu errado.')
          });
      } else {
        this.notificacoesService.atualizarNotificacaoLembrete(null, null, this.codcli, this.codusu).subscribe((res) => {
          console.log(res);
          this.alertService.alert('Notificação de lembrete de agendamento desativada!', 'success');
          this.statusLembrete = false;
          this.tipoEnvioSelecLembrete = '';
          this.notificacaoLembrete = '';
          this.showLembrete = false
          this.loading = false;
        },
          (error) => {
            console.log(error)
            console.log('deu ruim')
            this.loading = false;
            this.showLembrete = false
            this.alertService.alert('Algo deu errado.', 'error');
            //this.showError('Atenção', 'Algo deu errado.')
          });
      }
    }
  }



}

