<p-toast position="bottom-center" key="bc"></p-toast>
<p-toast position="top-right" key="tr"></p-toast>
<div class="pt-7">

    <div class="flex flex-column  pb-3">
        <div class="flex align-items-center justify-content-center">
            <img src="./assets/iconePesquisa.svg" />
        </div>
        <div class=" titulonegritoCadPesquisa flex align-items-center justify-content-center">
            <span>Pesquisas</span>
        </div>
    </div>


    <div class="flex flex-column  pb-3">
        <div class="flex justify-content-center flex-wrap ">


            <div class="scroll-divCadPesquisa">
                <div class="">
                    <table id="tabela">
                        <tr *ngFor="let pesquisa of pesquisa">
                            <td>
                                <div class="linhahorizontalCadastrarPesquisa pt-1"></div>
                                <div class="col-12 sm-12 md-4 ">
                                    <div class="flex flex-column">
                                        <div class="flex align-items-center justify-content-center tamanhoLinhaTabela">
                                            <div class="col">
                                                <div class="fontTituloCadPesquisa">
                                                    <span class="xl:text-xl">{{pesquisa.nome}}</span>
                                                </div>
                                                <!-- <div class="fontlocalizacao pb-2 ">
                                                    <div class="flex gap-2">
                                                        <img src="./assets/localizacao.svg" class="iconeLocalizacao " />
                                                        <div
                                                            class="flex align-items-center justify-content-center gap-1">
                                                            <span class="xl:text-base"> {{barbearia.rua}}
                                                                {{barbearia.complemento}},
                                                                {{barbearia.numero}}</span>
                                                            <div>
                                                                <span class="xl:text-base"> {{barbearia.setor}} -
                                                                    {{barbearia.cidade}} -
                                                                    {{barbearia.uf}} </span>
                                                            </div>
                                                            <div>
                                                                <span class="xl:text-base"> {{barbearia.contato}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> -->

                                            </div>
                                            <div class="flex align-items-center justify-content-center ">
                                                <img class="cursor-pointer" src="./assets/iconeMenuAdm.png"
                                                    (click)="menu.toggle($event); pesquisaSelecionada(pesquisa)" />
                                                <p-menu #menu [popup]="true" [model]="itemsPesquisas"
                                                    styleClass="menuCadPesquisas"></p-menu>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

        <p-confirmDialog #cd [closable]="false" id="confirmCadastraPesquisas">
            <ng-template pTemplate="footer">
                <button type="button" pButton icon="pi pi-times" label="Retornar" (click)="cd.reject()"></button>
                <button type="button" pButton icon="pi pi-check" label="Confirmar" (click)="cd.accept()"></button>
            </ng-template>
        </p-confirmDialog>






        <div class="flex flex-column  pb-3">
            <div class="flex justify-content-center flex-wrap ">
                <div class="flex justify-content-center flex-wrap ">
                    <div class="flex align-items-center justify-content-center">
                        <footer class="xl:w-5" id="footerCadastrarPesquisa">
                            <p-button label="Cadastrar" id="botaoCadastrarPesquisa" (click)="cadastrarPesquisa()">
                            </p-button>
                        </footer>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>