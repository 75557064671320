import { InformativoService } from './../informativo.service';
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Estabelecimento } from 'src/assets/estabelecimento';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { EstabelecimentoService } from 'src/estabelecimento.service';
import { UtilitariosService } from 'src/assets/utilitarios.service';
import { MessageService } from 'primeng/api';
import { AlertService } from 'src/alert.service';
import { Informativo } from 'src/assets/informativo';
import { environment } from 'src/environments/environment';
import { TraducaoService } from 'src/traducao.service';
import { PermissoesService } from 'src/permissoes.service';

@Component({
  selector: 'app-informativos',
  templateUrl: './informativos.component.html',
  styleUrls: ['./informativos.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MessageService],
})
export class InformativosComponent implements OnInit {
  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef;


  /**DATA */
  data: Date;
  ontem: Date;

  estabelecimento: Estabelecimento[] = [];
  informativo: Informativo = {}
  todasEstabelecimento: any;

  showCard: boolean = false;
  showNoticia: boolean = false;
  showVigencia: boolean = false;
  loading: boolean = false;

  /* variaveis do componente */
  titulo: string = '';
  subtitulo: string = '';
  noticia: string = '';
  dataInicial: any;
  dataFinal: any;
  codcli: number = 0;
  estabelecimentoSelecionada: any;
  teveRespostaEstabelecimento: boolean = false;
  nomeImagem: string = 'Escolha uma foto';
  informativoTitulo: string = '';
  tituloPadrao: string = 'Título';
  subTituloPadrao: string = 'Subtítulo ...'
  nuinf: number = 0;
  imagem: string = '';
  codusuresp: string = '';


  /**VARIAVEIS DE PERMISSÕES */
  alterar: boolean = false;
  cadastrar: boolean = false;
  permissao: any;

  constructor(private estabelecimentoService: EstabelecimentoService, private decodedTokenService: DecodedTokenService, private informativoService: InformativoService, private permissoesService: PermissoesService,
    private utilitariosService: UtilitariosService, private messageService: MessageService, private alertService: AlertService, private traducaoSerice: TraducaoService) {
    this.data = new Date();
    this.ontem = new Date(this.data);
    this.ontem.setDate(this.data.getDate());

    this.todasEstabelecimento = { "nome": "Todas Estabelecimentos", "codemp": 0 };

    /**TRADUÇÃO */
    this.traducaoSerice.changeLang();
  }

  ngOnInit(): void {
    this.recuperaCodUsu();
    this.recuperaCodCli();
    this.recuperaPermissao();
    this.recuperaInformativo();

  }

  recuperaCodUsu() {
    this.codusuresp = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }

  recuperaCodCli() {
    this.codcli = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODCLI;
  }

  recuperaInformativo() {
    this.informativo = this.informativoService.getInformativo();
    console.log(this.informativo);
    if (this.informativo.nuinf) {
      this.nuinf = this.informativo.nuinf;
      this.informativoTitulo = this.informativo.titulo!;
      this.titulo = this.informativo.titulo!;
      this.subtitulo = this.informativo.subtitulo!;
      this.estabelecimentoSelecionada = this.informativo.codemp;
      this.noticia = this.informativo.noticia!;
      this.imagem = environment.imgInformativo + this.informativo.filename;
      this.tituloPadrao = this.titulo;
      this.subTituloPadrao = this.subtitulo;
      if (this.informativo.dtvigini != null && this.informativo.dtvigfin != null) {
        this.dataInicial = new Date(this.informativo.dtvigini + 'GMT-0300'); //'GMT-0300' PARA FICAR NO FUSO HORÁRIO DO BRASIL (SE NÃO FIZER ISSO FICA 1 DIA ANTERIOR)
        this.dataFinal = new Date(this.informativo.dtvigfin + 'GMT-0300'); //'GMT-0300' PARA FICAR NO FUSO HORÁRIO DO BRASIL (SE NÃO FIZER ISSO FICA 1 DIA ANTERIOR)
        console.log(this.dataInicial)
        console.log(this.dataFinal)
      }
    }
    else {
      this.imagem = './assets/imgMascaraInformativo.png'
    }
  }

  /**RECUPERA AS BARBEARIAS */
  buscarEstabelecimentosPorCodCliente() {
    this.teveRespostaEstabelecimento = true;
    this.estabelecimentoService.buscarEstabelecimentosporCodCli(this.codcli, this.codusuresp).subscribe((res) => {
      this.estabelecimento = res;
      this.estabelecimento.push(this.todasEstabelecimento);
      this.estabelecimento.sort(function (x, y) {
        return Number(x.codemp) - Number(y.codemp);
      })

      this.teveRespostaEstabelecimento = false;
    },
      (error) => {
        console.log(error.message)
        console.log('deu ruim')
        this.teveRespostaEstabelecimento = false;

      })
  }

  mostraDialogCard() {
    /**VERIFICA SE TEM PERMISSÃO PARA ESSA AÇÃO */
    if (!this.alterar && this.permissao != undefined && this.permissao.length > 0 && this.informativoTitulo != '' && this.informativoTitulo != undefined) {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
    if (!this.informativo.nuinf) {
      this.titulo = '';
      this.tituloPadrao = 'Título';
      this.subTituloPadrao = 'Subtítulo ...';
      this.subtitulo = '';
      this.estabelecimento = [];
    }
    this.buscarEstabelecimentosPorCodCliente();

    this.showCard = true;
  }

  mostraDialogNoticia() {
    /**VERIFICA SE TEM PERMISSÃO PARA ESSA AÇÃO */
    if (!this.alterar && this.permissao != undefined && this.permissao.length > 0) {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
    if (this.nuinf != undefined && this.nuinf != null && this.nuinf > 0) {
      this.showNoticia = true;
    }
  }

  mostraDialogVigencia() {
    /**VERIFICA SE TEM PERMISSÃO PARA ESSA AÇÃO */
    if (!this.alterar && this.permissao != undefined && this.permissao.length > 0) {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
    if (this.nuinf != undefined && this.nuinf != null && this.nuinf > 0) {
      this.showVigencia = true;
    }
  }

  mudaTituloSubTitulo() {
    console.log(this.titulo)
    if (this.titulo != '') {
      this.tituloPadrao = this.titulo;
    }
    if (this.subtitulo != '') {
      this.subTituloPadrao = this.subtitulo;
    }
  }

  mudaNomeFoto() {
    const imageBlob = this.fileInput!.nativeElement.files[0];
    this.nomeImagem = imageBlob.name;
    if (this.nomeImagem.length > 24) {
      console.log('oiwf')
      this.nomeImagem = this.nomeImagem.substr(0, 24) + '...';
    }
    console.log(this.nomeImagem.substr(0, 20) + '...')
  }

  previewImagem() {
    let imagem = this.fileInput!.nativeElement.files[0]
    let preview = <HTMLImageElement>document.querySelector('img[name=preview]')
    let reader = new FileReader();
    console.log(reader)
    reader.onloadend = function () {
      preview.src = URL.createObjectURL(imagem);
    }
    if (imagem) {
      reader.readAsDataURL(imagem);
    } else {
      preview!.src = '';
    }

  }


  criaInformativo() {
    this.loading = true;

    console.log(this.estabelecimentoSelecionada)
    console.log(this.titulo)
    console.log(this.subtitulo)
    if (this.utilitariosService.validaCampo([this.titulo, this.subtitulo]) || this.estabelecimentoSelecionada === undefined) {
      console.log('É necessário preencher todos os campos.')
      this.loading = false;
      this.showError('Atenção', 'É necessário preencher todos os campos.')
      return;
    }



    console.log('este é o codcli: ' + this.codcli)

    const imageBlob = this.fileInput!.nativeElement.files[0];

    const file = new FormData();
    console.log(imageBlob);
    if (imageBlob) {
      file.append('imagem_card', imageBlob);
      this.nomeImagem = imageBlob.name;
    }
    if (!this.informativo.nuinf) {
      file.append('nuinf', 'null');
    } else {
      file.append('nuinf', this.informativo.nuinf.toString());
      file.append('nomearquivo', this.informativo.filename!);
      //enviar nome da imagem
    }
    file.append('titulo', this.titulo);
    file.append('subtitulo', this.subtitulo);
    file.append('codemp', this.estabelecimentoSelecionada);
    file.append('codcli', this.codcli.toString());
    file.append('codusuresp', this.codusuresp)

    this.informativoService.createInformativo(file).subscribe((res) => {
      console.log(res)
      this.alertService.alert('Card de Notícia criado!', 'success')
      this.loading = false;
      this.informativoTitulo = this.titulo;
      this.nuinf = Object(res).nuinf;
      this.showCard = false;
      console.log(res)
    },
      (error) => {
        console.log('deu ruim')
        this.showNoticia = false;
        this.loading = false;
        this.alertService.alert('Algo deu errado.', 'error');
      });
  }

  criaNoticia() {
    this.loading = true;
    console.log(this.noticia)
    console.log(this.informativo.nuinf)

    if (this.utilitariosService.validaCampo([this.noticia])) {
      console.log('É necessário informar o conteúdo da notícia.')
      this.loading = false;
      this.showError('Atenção', 'É necessário informar o conteúdo da notícia.')
      return;
    }

    this.informativoService.preencheNoticia(this.nuinf, this.noticia, this.codusuresp).subscribe((res) => {
      this.alertService.alert('Notícia criada!', 'success')
      this.loading = false;
      this.showNoticia = false;
      console.log(res)
    },
      (error) => {
        console.log('deu ruim')
        this.loading = false;
        this.showNoticia = false;
        this.alertService.alert('Algo deu errado.', 'error');
      });

  }

  criaDataVigencia() {
    console.log(this.dataInicial)
    console.log(this.dataFinal)

    if (this.utilitariosService.validaCampo([this.dataInicial, this.dataFinal])) {
      console.log('É necessário informar as datas de vigência da notícia.')
      this.loading = false;
      this.showError('Atenção', 'É necessário informar as datas de vigência da notícia.')
      return;
    }

    /**DEIXA AS DATAS NO PADRÃO QUE O BANCO ESPERA: YYYY-MM-DD */
    let auxInicial = this.dataInicial.getFullYear() + '-' + ('0' + (this.dataInicial.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataInicial.getDate()).slice(-2);
    let auxFinal = this.dataFinal.getFullYear() + '-' + ('0' + (this.dataFinal.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataFinal.getDate()).slice(-2);
    console.log(auxInicial)
    console.log(auxFinal)


    this.informativoService.preencheVigencia(this.nuinf, auxInicial, auxFinal, this.codusuresp).subscribe((res) => {
      this.alertService.alert('Data de Vigência alterada!', 'success')
      this.loading = false;
      this.showVigencia = false;
      console.log(res)
    },
      (error) => {
        console.log('deu ruim')
        this.loading = false;
        this.showVigencia = false;
        this.alertService.alert('Algo deu errado.', 'error');
      });



  }








  /**METODO DE TOAST */
  showError(titulo: string, mensagem: string) {
    if (screen.width < 640 || screen.height < 480) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else if (screen.width < 1024 || screen.height < 768) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else {
      this.messageService.add({
        key: 'tr',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    }
  }


  /**RECUPERA AS PERMISSÕES */
  recuperaPermissao() {
    console.log('recuperei as permissoes')
    this.permissao = this.permissoesService.getPermissao();
    console.log(this.permissao)
    if (this.permissao != undefined && this.permissao.length > 0) {
      if (this.permissao[4].acao[0].PERMITE === 'S') {
        this.cadastrar = true;
      }
      if (this.permissao[4].acao[1].PERMITE === 'S') {
        this.alterar = true;
      }

      /**ATRIBUI O CODCLI VINCULADO NA PERMISSAO DO FUNCIONARIO, PARA FAZER AS OPERAÇÕES DA TELA */
      this.codcli = this.permissao[0].codcli;
    }
  }
}
