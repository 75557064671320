import { Injectable } from '@angular/core';
import { delay, first, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './environments/environment';
import { Estabelecimento } from './assets/estabelecimento';
import { LoginService } from './login/login.service';

@Injectable({
  providedIn: 'root'
})
export class EstabelecimentoService {
  estabelecimento: Estabelecimento = {};
  estabelecimentoPorCodCli: Estabelecimento[] = [];

  constructor(private http: HttpClient, private loginService: LoginService) { }


  buscarCep(cep: string): Observable<any> {
    return this.http.get(`https://viacep.com.br/ws/${cep}/json/`).pipe(
      first(),
      delay(5000),
      tap((data: any) => console.log(data)));
    // return this.http.get(`https://viacep.com.br/ws/${cep}/json/`).pipe(map(res => res));
  }

  preencherEnd(codemp: any, cep: any, logradouro: any, numero: any, complemento: any,
    bairro: any, cidade: any, uf: any, codusuresp: string, codtipseg: number): Observable<any> {

    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const endereco = {
      "codemp": codemp, "cep": cep, "logradouro": logradouro, "numero": numero, "complemento": complemento,
      "bairro": bairro, "cidade": cidade, "uf": uf, "codusuresp": codusuresp, "codtipseg": codtipseg
    };

    return this.http
      .post<any>(`${environment.BASE_URL}/empresa/preencheend`, endereco, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );

  }

  buscaEstabelecimentos(codtipseg: number): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const segmento = {
      "codtipseg": codtipseg
    }

    return this.http
      .post<any>(`${environment.BASE_URL}/empresa/`, segmento, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  contatoEstabelecimento(codemp: string, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const empresa = { "codemp": codemp, "codusuresp": codusuresp };

    return this.http
      .post<any>(`${environment.BASE_URL}/empresa/empporid`, empresa, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  preencherNomeEstabelecimento(codemp: any, nome: string, codcli: any, codusuresp: string, codtipseg: number): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const empresa = { "codemp": codemp, "nomeemp": nome, "codcli": codcli, "codusuresp": codusuresp, "codtipseg": codtipseg }

    return this.http
      .post<any>(`${environment.BASE_URL}/empresa/createemp`, empresa, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  preencherContatoEstabelecimento(codemp: string, telefone: string, email: string, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const empresa = { "codemp": codemp, "telefone": telefone, "email": email, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/empresa/preenchecont`, empresa, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  buscarEstabelecimentosporCodCli(codcli: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const cliente = { "codcli": codcli, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/empresa/empporcodcli`, cliente, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => {
          console.log(r)
          // if (sessionStorage.getItem('EstabelecimentosPorCodCli')) {
          //   this.barbeariaPorCodCli = JSON.parse(sessionStorage.getItem('barbeariasPorCodCli')!);
          // }
          // sessionStorage.setItem('barbeariasPorCodCli', JSON.stringify(r))
        },
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  // getEstabelecimentoSessionStorage() {
  //   if (sessionStorage.getItem('barbeariasPorCodCli')) {
  //     this.barbeariaPorCodCli = JSON.parse(sessionStorage.getItem('barbeariasPorCodCli')!);
  //   }
  //   return this.barbeariaPorCodCli;
  // }



  ativarDesativarEmpresaPorCodEmp(codemp: string, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const empresa = { "codemp": codemp, "codusuresp": codusuresp }

    return this.http
      .put<any>(`${environment.BASE_URL}/empresa/ativdesatemp`, empresa, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  excluiEmpresaPorCodEmp(codemp: string, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const empresa = { "codemp": codemp, "codusuresp": codusuresp }

    return this.http
      .put<any>(`${environment.BASE_URL}/empresa/excluiemp`, empresa, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  public setestabelecimento(e: Estabelecimento) { //SETA A estabelecimento NO VETOR
    this.estabelecimento = e;
  }

  public getestabelecimento() { //RECUPERA O CONTEUDO DA estabelecimento PARA VISUALIZAÇÃO
    return this.estabelecimento;
  }

  uploadImagemEmpresa(codemp: string, img: any) {
    //const imagem = { "codemp": codemp, "imagem_empresa": img }
    // const headers =  { 'Content-Type': 'multipart/form-data' }

    const token = localStorage.getItem('token');
    //let headers = new HttpHeaders().append('Authorization', token!);

    let headers = new HttpHeaders({
      'enctype': 'multipart/form-data',
      'Authorization': token!
    });
    return this.http
      .post<any>(`${environment.BASE_URL}/upload/imagem-empresa`, img, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  preencheRedeSocial(linkinsta: string, linkfacebook: string, linktiktok: string, codemp: string, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const redeSocial = { "linkinsta": linkinsta, "linkfacebook": linkfacebook, "linktiktok": linktiktok, "codemp": codemp, "codusuresp": codusuresp }

    return this.http
      .put<any>(`${environment.BASE_URL}/empresa/preencheclinkredesocial`, redeSocial, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  buscaEmpresaPorHash(hash: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const empresa = { "hash": hash };

    return this.http
      .post<any>(`${environment.BASE_URL}/empresa/empporhash`, empresa)
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            // this.loginService.verificaToken(e.status);
          })
      );
  }


  preencherHashEstabelecimento(hash: string, codemp: string, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const empresa = { "hash": hash, "codemp": codemp, "codusuresp": codusuresp }

    return this.http
      .put<any>(`${environment.BASE_URL}/empresa/preenchehash`, empresa, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  atualizaBaixaEstabelecimento(baixaagdaut: string, codemp: string, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const empresa = { "baixaagdaut": baixaagdaut, "codemp": codemp, "codusuresp": codusuresp }

    return this.http
      .put<any>(`${environment.BASE_URL}/empresa/atualizabaixaautomaticaagd`, empresa, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }
}
