import { RelatorioGuard } from './../guards/relatorio.guard';
import { ServicoAdmGuard } from './../guards/servico-adm.guard';
import { InformativoAdmGuard } from './../guards/informativo-adm.guard';
import { HorarioAdmGuard } from './../guards/horario-adm.guard';
import { BarbeariaAdmGuard } from './../guards/barbearia-adm.guard';
import { BarbeiroAdmGuard } from './../guards/barbeiro-adm.guard';
import { EsqueceuSenhaComponent } from 'src/esqueceu-senha/esqueceu-senha.component';
import { CadastroComponent } from 'src/cadastro/cadastro.component';
import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { HomeComponent } from 'src/home/home.component';
import { LoginComponent } from 'src/login/login.component';
import { LoginGuard } from 'src/login/login.guard';
import { AlterarSenhaDeslogComponent } from 'src/alterar-senha-deslog/alterar-senha-deslog.component';
import { CodigoRecuperacaoComponent } from 'src/codigo-recuperacao/codigo-recuperacao.component';
import { FuncionarioGuard } from 'src/guards/funcionario.guard';
import { AdministradorGuard } from 'src/guards/administrador.guard';
import { LandingPageComponent } from 'src/landing-page/landing-page.component';
import { TelasGuard } from 'src/guards/telas.guard';
import { MetricasGuard } from 'src/guards/metricas.guard';
import { AgendamentoSemContaComponent } from 'src/agendamento-sem-conta/agendamento-sem-conta.component';



const routes: Routes = [
  {
    path: '', component: HomeComponent, children: [
      {
        path: 'contato',
        loadChildren: () => import('../contato/contato.module').then(m => m.ContatoModule)
      },
      {
        path: 'perfil',
        loadChildren: () => import('../perfil/perfil.module').then(m => m.PerfilModule)
      },
      {
        path: 'minhaAgenda',
        loadChildren: () => import('../minha-agenda/minha-agenda.module').then(m => m.MinhaAgendaModule)
      },
      {
        path: 'sobre',
        loadChildren: () => import('../sobre/sobre.module').then(m => m.SobreModule)
      },
      {
        path: 'agendar',
        loadChildren: () => import('../agendar/agendar.module').then(m => m.AgendarModule)
      },
      {
        path: 'alterarSenha',
        loadChildren: () => import('../alterar-senha/alterar-senha.module').then(m => m.AlterarSenhaModule)
      }
      ,
      {
        path: 'buscarEstabelecimento',
        loadChildren: () => import('../buscar-estabelecimento/buscar-estabelecimento.module').then(m => m.BuscarEstabelecimentoModule)
      },
      {
        path: 'detalheInformativo',
        loadChildren: () => import('../detalhe-informativo/detalhe-informativo.module').then(m => m.DetalheInformativoModule)
      },
      {
        path: 'menuAdministrativo',
        loadChildren: () => import('../menu-administrativo/menu-administrativo.module').then(m => m.MenuAdministrativoModule),
        canActivate: [AdministradorGuard]
      },
      {
        path: 'painelBarber',
        loadChildren: () => import('../painel-barber/painel-barber.module').then(m => m.PainelBarberModule),
        canActivate: [FuncionarioGuard]
      },
      {
        path: 'cadastrarEstabelecimento',
        loadChildren: () => import('../cadastrar-estabelecimento/cadastrar-estabelecimento.module').then(m => m.CadastrarEstabelecimentoModule),
        canActivate: [TelasGuard]
      },
      {
        path: 'estabelecimento',
        loadChildren: () => import('../estabelecimento/estabelecimento.module').then(m => m.EstabelecimentoModule),
        canActivate: [TelasGuard]
      },
      {
        path: 'cadastrarProfissionais',
        loadChildren: () => import('../cadastrar-profissionais/cadastrar-profissionais.module').then(m => m.CadastrarProfissionaisModule),
        canActivate: [TelasGuard]
      },
      {
        path: 'profissional',
        loadChildren: () => import('../profissional/profissional.module').then(m => m.ProfissionalModule),
        canActivate: [TelasGuard]
      },
      {
        path: 'agenda',
        loadChildren: () => import('../agenda/agenda.module').then(m => m.AgendaModule),
        canActivate: [FuncionarioGuard]
      },
      {
        path: 'cadastrarServicos',
        loadChildren: () => import('../cadastrar-servicos/cadastrar-servicos.module').then(m => m.CadastrarServicosModule),
        canActivate: [TelasGuard]
      },
      {
        path: 'servico',
        loadChildren: () => import('../servico/servico.module').then(m => m.ServicoModule),
        canActivate: [TelasGuard]
      },
      {
        path: 'cadastrarProdutos',
        loadChildren: () => import('../cadastrar-produtos/cadastrar-produtos.module').then(m => m.CadastrarProdutosModule)
      },
      {
        path: 'produto',
        loadChildren: () => import('../produtos/produtos.module').then(m => m.ProdutosModule)
      },
      {
        path: 'relatorios',
        loadChildren: () => import('../relatorios/relatorios.module').then(m => m.RelatoriosModule),
        canActivate: [AdministradorGuard, MetricasGuard]
      },
      {
        path: 'cadastrarHorarios',
        loadChildren: () => import('../cadastrar-horarios/cadastrar-horarios.module').then(m => m.CadastrarHorariosModule),
        canActivate: [TelasGuard]
      },
      {
        path: 'horarios',
        loadChildren: () => import('../horarios/horarios.module').then(m => m.HorariosModule),
        canActivate: [TelasGuard]
      },
      {
        path: 'filiarEstabelecimento',
        loadChildren: () => import('../filiar-estabelecimento/filiar-estabelecimento.module').then(m => m.FiliarEstabelecimentoModule)
      },
      {
        path: 'clientesFiliados',
        loadChildren: () => import('../clientes-filiados/clientes-filiados.module').then(m => m.ClientesFiliadosModule),
        canActivate: [AdministradorGuard]
      },
      {
        path: 'noticias',
        loadChildren: () => import('../noticias/noticias.module').then(m => m.NoticiasModule),
        canActivate: [AdministradorGuard]
      },
      {
        path: 'cadastrarInformativos',
        loadChildren: () => import('../cadastrar-informativos/cadastrar-informativos.module').then(m => m.CadastrarInformativosModule),
        canActivate: [TelasGuard]
      },
      {
        path: 'informativos',
        loadChildren: () => import('../informativos/informativos.module').then(m => m.InformativosModule),
        canActivate: [TelasGuard]
      },
      {
        path: 'cadastrarPesquisa',
        loadChildren: () => import('../cadastrar-pesquisa/cadastrar-pesquisa.module').then(m => m.CadastrarPesquisaModule),
        canActivate: [TelasGuard]
      },
      {
        path: 'pesquisa',
        loadChildren: () => import('../pesquisa/pesquisa.module').then(m => m.PesquisaModule),
        canActivate: [TelasGuard]
      },
      // {
      //   path: 'cadastrarNotificacoes',
      //   loadChildren: () => import('../cadastrar-notificacoes/cadastrar-notificacoes.module').then(m => m.CadastrarNotificacoesModule)
      // },
      {
        path: 'notificacoes',
        loadChildren: () => import('../notificacoes/notificacoes.module').then(m => m.NotificacoesModule),
        canActivate: [TelasGuard]
      },
      {
        path: 'faturas',
        loadChildren: () => import('../faturas/faturas.module').then(m => m.FaturasModule)
      },
      {
        path: 'detalheNotificacao',
        loadChildren: () => import('../detalhe-notificacao/detalhe-notificacao.module').then(m => m.DetalheNotificacaoModule)
      },
      {
        path: 'cadastrarComissao',
        loadChildren: () => import('../cadastrar-comissao/cadastrar-comissao.module').then(m => m.CadastrarComissaoModule),
        canActivate: [TelasGuard]
      },
      {
        path: 'comissao',
        loadChildren: () => import('../comissao/comissao.module').then(m => m.ComissaoModule),
        canActivate: [TelasGuard]
      },
      {
        path: 'cadastrarCupom',
        loadChildren: () => import('../cadastrar-cupom/cadastrar-cupom.module').then(m => m.CadastrarCupomModule),
        canActivate: [TelasGuard]
      },
      {
        path: 'descontos',
        loadChildren: () => import('../descontos/descontos.module').then(m => m.DescontosModule),
        canActivate: [TelasGuard]
      },
      {
        path: 'descontoTotal',
        loadChildren: () => import('../desconto-total/desconto-total.module').then(m => m.DescontoTotalModule),
        canActivate: [TelasGuard]
      },
      {
        path: 'financeiro',
        loadChildren: () => import('../financeiro/financeiro.module').then(m => m.FinanceiroModule),
        canActivate: [TelasGuard]
      },
      {
        path: 'acessos',
        loadChildren: () => import('../acessos/acessos.module').then(m => m.AcessosModule),
        canActivate: [TelasGuard]
      },
      {
        path: 'categorias',
        loadChildren: () => import('../categorias/categorias.module').then(m => m.CategoriasModule)
      },
      {
        path: 'configuracaoFinanceira',
        loadChildren: () => import('../configuracao-financeira/configuracao-financeira.module').then(m => m.ConfiguracaoFinanceiraModule)
      },
      {
        path: 'configuracaoBaixa',
        loadChildren: () => import('../configuracao-baixa/configuracao-baixa.module').then(m => m.ConfiguracaoBaixaModule)
      },
      {
        path: 'contas',
        loadChildren: () => import('../cadastrar-conta/cadastrar-conta.module').then(m => m.CadastrarContaModule)
      },
      {
        path: 'meusResultados',
        loadChildren: () => import('../meus-resultados/meus-resultados.module').then(m => m.MeusResultadosModule)
      },
      {
        path: 'tipoNegociacao',
        loadChildren: () => import('../tipo-negociacao/tipo-negociacao.module').then(m => m.TipoNegociacaoModule)
      },
      {
        path: 'pedidos',
        loadChildren: () => import('../pedidos/pedidos.module').then(m => m.PedidosModule)
      },
      {
        path: 'vendaDireta',
        loadChildren: () => import('../venda-direta/venda-direta.module').then(m => m.VendaDiretaModule)
      },
      {
        path: 'cadastrarPlano',
        loadChildren: () => import('../cadastrar-plano-pacote/cadastrar-plano-pacote.module').then(m => m.CadastrarPlanoPacoteModule)
      },
      {
        path: 'detalhePlano',
        loadChildren: () => import('../detalhe-plano-pacote/detalhe-plano-pacote.module').then(m => m.DetalhePlanoPacoteModule)
      },
    ],
    canActivate: [LoginGuard]
  },

  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'site', component: LandingPageComponent
  },
  {
    path: 'cadastro', component: CadastroComponent
  },
  {
    path: 'esqueceuSenha', component: EsqueceuSenhaComponent
  },
  {
    path: 'alterarSenhaDeslog', component: AlterarSenhaDeslogComponent
  },
  {
    path: 'codigoRecuperacao', component: CodigoRecuperacaoComponent
  },
  {
    path: 'agendamento/:hashEmpresa', component: AgendamentoSemContaComponent
  },
  { path: '**', component: LoginComponent },


];

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
