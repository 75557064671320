<p-toast position="bottom-center" key="bc"></p-toast>
<p-toast position="top-right" key="tr"></p-toast>
<div class="pt-7">

    <div class="flex flex-column  pb-3">
        <div class="flex align-items-center justify-content-center">
            <img src="./assets/iconeCadastrarComissao.svg" />
        </div>
        <div class=" tituloNegritoComissao flex align-items-center justify-content-center">
            <span>Fechamento:</span>

        </div>
        <div class=" tituloNegritoComissao flex align-items-center justify-content-center">
            <span>{{tituloFechametoIni |date:'dd/MM/yyyy'}} - {{tituloFechametoFin |date:'dd/MM/yyyy'}}</span>
        </div>
    </div>

    <div class="flex justify-content-center flex-wrap ">


        <div class="xl:col-5 lg:col-5 md:col-5 sm:col-5">
            <div class="shadow-4">


                <p-card [header]="headerCard" id="cardComissao">
                    <div>
                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            (click)="mostraDialogFechamento()">
                            <div class="flex gap-2 ">
                                <img src="./assets/Fechamento.svg" class=" xl:w-3rem" />
                                <div class="flex flex-column align-items-start">
                                    <div class=" fontTituloComissao">
                                        <span class="xl:text-xl">Fechamento</span>
                                    </div>
                                    <div class="fontCinzaComissao">
                                        <span class="xl:text-base">Informe o período e o profissional</span>
                                    </div>
                                </div>
                            </div>
                            <div class="align-items-center justify-content-center  ">
                                <img src="./assets/imgBarbeariaPopup.png" />
                            </div>
                        </div>

                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            (click)="mostraDialogServicos()">
                            <div class="flex gap-2 ">
                                <img src="./assets/Serviços.svg" class=" xl:w-3rem" />
                                <div class="flex flex-column  align-items-start">
                                    <div class=" fontTituloComissao">
                                        <span class="xl:text-xl">Serviços</span>
                                    </div>
                                    <div class="fontCinzaComissao">
                                        <span class="xl:text-base">Selecione os serviços/produtos para o
                                            fechamento</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex align-items-center justify-content-center ">
                                <img src="./assets/imgBarbeariaPopup.png" class="iconePopUpEstabelecimento" />
                            </div>
                        </div>

                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            (click)="mostraDialogAjustes()">
                            <div class="flex gap-2 ">
                                <img src="./assets/Ajustes.svg" class=" xl:w-3rem" />
                                <div class="flex flex-column align-items-start">
                                    <div class="fontTituloComissao">
                                        <span class="xl:text-xl">Ajustes</span>
                                    </div>
                                    <div class="fontCinzaComissao">
                                        <span class="xl:text-base">Informe ajuste se necessário</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex align-items-center justify-content-end  ">
                                <img src="./assets/imgBarbeariaPopup.png" class="iconePopUpEstabelecimento" />
                            </div>
                        </div>

                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            (click)="geraRelatorioPDFComissao()">
                            <div class="flex gap-2 ">
                                <img src="./assets/DownloadPDF.svg" class=" xl:w-3rem" />
                                <div class="flex flex-column align-items-start">
                                    <div class="fontTituloComissao">
                                        <span class="xl:text-xl">Relatório (PDF)</span>
                                    </div>
                                    <div class="fontCinzaComissao">
                                        <span class="xl:text-base">Relatório de fechamento de comissão</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex align-items-center justify-content-end ">
                                <img src="./assets/imgBarbeariaPopup.png" />
                            </div>
                        </div>

                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            (click)="geraRelatorioExcelComissao()">
                            <div class="flex gap-2 ">
                                <img src="./assets/DownloadExcel.svg" class=" xl:w-3rem" />
                                <div class="flex flex-column align-items-start">
                                    <div class="fontTituloComissao">
                                        <span class="xl:text-xl">Relatório (Excel)</span>
                                    </div>
                                    <div class="fontCinzaComissao">
                                        <span class="xl:text-base">Relatório de fechamento de comissão</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex align-items-center justify-content-end ">
                                <img src="./assets/imgBarbeariaPopup.png" />
                            </div>
                        </div>

                    </div>
                </p-card>
            </div>
        </div>
    </div>
    <br>

    <!--                 DIALOG DE FECHAMENTO             -->
    <p-dialog header="Fechamento" [(visible)]="showFechamento" [modal]="true" [style]="{'width': '327px'}"
        id="dialogFechamento">
        <div class="flex justify-content-center flex-wrap col">
            <div class="fontDialogComissao flex align-items-center justify-content-center">
                <span>Informações para o fechamento de comissão</span>
            </div>
        </div>

        <div class="">
            <p-calendar [touchUI]="true" [readonlyInput]="false" inputId="touchui" dateFormat="dd/mm/yy"
                [required]="true" [readonlyInput]="true" id="calendarioPesquisa" [(ngModel)]="dataInicialCom"
                placeholder="Data Inicial" [disabled]="comissaoStatus" [maxDate]="hoje">
            </p-calendar>
            <div class="mt-1">
                <p-calendar [touchUI]="true" [readonlyInput]="false" inputId="touchui" dateFormat="dd/mm/yy"
                    [minDate]="dataInicialCom" [required]="true" [readonlyInput]="true" id="calendarioPesquisa"
                    [(ngModel)]="dataFinalCom" placeholder="Data Final" [disabled]="comissaoStatus" [maxDate]="hoje">
                </p-calendar>
            </div>

        </div>
        <p-dropdown id="dropComissao" [options]="estabelecimento" [(ngModel)]="estabelecimentoSelecionada" placeholder="Estabelecimento"
            optionLabel="nome" optionValue="codemp" [style]="{'width': '100%'}" scrollHeight="130px" [filter]="false"
            (onChange)="buscaProfissionalPorCodEmp()" [disabled]="comissaoStatus">
        </p-dropdown>

        <div class="pb-2">
            <div *ngIf="teveRespostaEstabelecimento">
                <p-button id="botaoSpinner" label="Profissional" [disabled]="true" class="p-button-text">
                    <i class="pi pi-spin pi-spinner teste flex-order-1" style="font-size: 1rem"></i>
                </p-button>
            </div>

            <div *ngIf="!teveRespostaEstabelecimento">
                <p-dropdown id="dropComissao" [options]="profissional" [(ngModel)]="profissionalSelecionado"
                    placeholder="Profissional" optionLabel="apelido" optionValue="codfunc" scrollHeight="80px"
                    [disabled]="comissaoStatus" [style]="{'width': '100%'}">
                </p-dropdown>
            </div>
        </div>


        <div *ngIf="!loading" [style]="{width: '100%'}">
            <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
     'border-radius': '11px'}" (click)="salvaComissao()"></button>
        </div>
        <div *ngIf="loading" [style]="{width: '100%'}">
            <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 mt-3"
                [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
     'border-radius': '11px'}"></button>
        </div>
    </p-dialog>


    <!--                 DIALOG DE SERVICOS             -->
    <p-dialog header="Serviços" [(visible)]="showServicos" [modal]="true"
        [breakpoints]="{'960px': '534px', '640px': '360px'}" id="dialogServicos">
        <div class="flex justify-content-center flex-wrap col">
            <div class="fontDialogComissao flex align-items-center justify-content-center">
                <span>Selecione os serviços que estarão no fechamento de comissão</span>
            </div>
        </div>

        <div class="pt-2 pb-1">
            <p-table [value]="servico" [scrollable]="true" scrollHeight="300px" id="tabela">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width:1px"> <p-checkbox [value]="servico" [(ngModel)]="selecionouTodosServicos"
                                [disabled]="comissaoStatus" (click)="cliclouTodosServicos();somaTotal()">
                            </p-checkbox>
                        </th>
                        <th class="fontTituloTabela" style="width:1px">Nº</th>
                        <th class="fontTituloTabela" style="min-width:100px">Resumo</th>
                        <th class="fontTituloTabela" style="min-width:80px">Valor</th>
                        <th class="fontTituloTabela" style="min-width:80px">Comissão</th>
                        <th class="fontTituloTabela" style="min-width:100px">Dt. Serviço</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-servico let-indice="rowIndex">
                    <tr>
                        <td style="width:1px">
                            <p-checkbox [value]="servico" [(ngModel)]="servicoSelecionadoFechamento"
                                [disabled]="comissaoStatus" (click)="somaTotal()">
                            </p-checkbox>
                        </td>
                        <td class="fontConteudoTabela" style="width:1px">{{servico.nuid}}</td>
                        <td class="fontConteudoTabela" style="min-width:100px">{{servico.titulo}}</td>
                        <td class="fontConteudoTabela" style="min-width:80px">{{servico.vlrtot | currency : 'BRL'}}</td>
                        <td class="fontConteudoTabela" style="min-width:80px">{{servico.comissao | currency : 'BRL'}}
                        </td>
                        <td class="fontConteudoTabela" style="min-width:100px">{{servico.dtserv | date:'dd/MM/yyyy'}}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div class="flex justify-content-center flex-wrap flex flex-column ">


            <div class="pt-1 flex align-items-center justify-content-center gap-1">
                <span class="fontDialogServicos">Valor total: </span>
                <span class="fontDialogServicosValores">{{vlrSoma | currency : 'BRL'}}</span>
            </div>

            <div class="pt-1 flex align-items-center justify-content-center gap-1">
                <span class="fontDialogServicos">Comissão: </span>
                <span class="fontDialogServicosValores">{{vlrComissao | currency : 'BRL'}}</span>
                <span class="fontDialogServicosValores">({{percentComissao}}%)</span>
            </div>
        </div>
        <div *ngIf="!loading" [style]="{width: '100%'}">
            <button pButton type="button" label="Atualizar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
     'border-radius': '11px'}" (click)="atualizaValorComissao()"></button>
        </div>
        <div *ngIf="loading" [style]="{width: '100%'}">
            <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 mt-3"
                [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
     'border-radius': '11px'}"></button>
        </div>
    </p-dialog>


    <!--                 DIALOG DE AJUSTES             -->
    <p-dialog header="Ajustes" [(visible)]="showAjustes" [modal]="true"
        [breakpoints]="{'960px': '534px', '640px': '360px'}" id="dialogAjuste">
        <div class="flex justify-content-center flex-wrap col">
            <div class="fontDialogComissao flex align-items-center justify-content-center">
                <span>Crie, edite ou remova ajustes na comissão</span>
            </div>
        </div>

        <div class="contornoBotoesComissao">
            <div class="flex flex-wrap gap-2 pl-2 pb-2">
                <button id="cadastrarAjuste" pButton type="button" label="Cadastrar" class="col-12 mt-2"
                    (click)="mostraDialogCadAjustes()"
                    [style]="{'width':'100px', 'height': '24px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)','border-radius': '8px', 'border': 'none', 'font-size': '10px'}"></button>
                <button id="excluirAjuste" pButton type="button" label="Excluir" class="col-12 mt-2 "
                    (click)="confirmaExclusaoAjuste()"
                    [style]="{'width':'100px', 'height': '24px', 'background-color': 'var(--cor2)', 'color': 'var(--cor7)','border-radius': '8px', 'border': 'none', 'font-size': '10px'}"></button>
            </div>

        </div>

        <div class="pt-2 pb-1">
            <p-table [value]="ajuste" [scrollable]="true" scrollHeight="300px" id="tabela">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width:1px"> <p-checkbox [value]="ajuste" [(ngModel)]="selecionouTodosAjustesExcluir"
                                [disabled]="comissaoStatus" (click)="clicouTodosAjustes()">
                            </p-checkbox>
                        </th>
                        <th class="fontTituloTabela" style="width:1px">Nº</th>
                        <th class="fontTituloTabela" style="min-width:160px">Descrição</th>
                        <th class="fontTituloTabela" style="min-width:80px">Valor</th>
                        <th style="min-width:1px"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-ajuste let-indice="rowIndex">
                    <tr>
                        <td style="width:1px">
                            <p-checkbox [value]="ajuste" [(ngModel)]="ajusteSelecionadoExcluir"
                                [disabled]="comissaoStatus">
                            </p-checkbox>
                        </td>
                        <td class="fontConteudoTabela" style="width:1px">{{indice+1}}</td>
                        <td class="fontConteudoTabela" style="min-width:140px">{{ajuste.descricao}}</td>
                        <td class="fontConteudoTabela" style="min-width:80px">{{ajuste.valor | currency : 'BRL'}}</td>
                        <td style="min-width:1px" alignFrozen="right" pFrozenColumn>
                            <img src="./assets/iconePesquisaTabela.svg" (click)="selecionouAjusteEdicao(ajuste)" />
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>

        <div [style]="{width: '100%'}">
            <button pButton type="button" label="Voltar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
     'border-radius': '11px'}" (click)="clicouVoltar()"></button>
        </div>
    </p-dialog>



    <!--                          DIALOG DE CADASTRO DE AJUSTES               -->
    <p-dialog header="Ajustes" [(visible)]="showCadAjuste" [modal]="true"
        [style]="{'width': '350px', 'height': '317px'}" [closable]="true" id="dialogCadastroAjuste">

        <div class="flex flex-column align-items-center">
            <div class="col-12">
                <div class="fontDialogPesquisa flex align-items-center justify-content-center ">
                    <span>Cadastro / Edição de ajuste</span>
                </div>
            </div>
        </div>


        <div class="pb-2 pt-1">
            <textarea pInputTextarea placeholder="Descrição" [(ngModel)]="descricaoAjuste" [autoResize]="true"
                maxlength="150" class="expandeTextArea" (ngModelChange)="contaCaracter()"
                [disabled]="comissaoStatus"></textarea>
            <div class="flex justify-content-end flex-wrap fontContadorCaracter">{{caracteresRestantes}}/150</div>
        </div>
<!-- 
        <div class="pt-1">
            <p-inputNumber [(ngModel)]="valorAjuste" placeholder="Valor" [maxFractionDigits]="2" id="inputPreco"
                [disabled]="comissaoStatus">
            </p-inputNumber>
        </div> -->

        <div class="p-fluid pt-1">
            <p-inputNumber [(ngModel)]="valorAjuste" mode="currency" currency="BRL" locale="pt-BR"
                placeholder="Valor" [minFractionDigits]="2" id="inputPreco">
            </p-inputNumber>
        </div>


        <div *ngIf="!loading" [style]="{width: '100%'}">
            <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
   'border-radius': '11px'}" (click)="criaAjuste()" [disabled]="comissaoStatus"></button>
        </div>
        <div *ngIf="loading" [style]="{width: '100%'}">
            <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 mt-3"
                [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
   'border-radius': '11px'}"></button>
        </div>
    </p-dialog>



    <!-- CONFIRMDIALOG DE EXCLUSÃO -->
    <p-confirmDialog #cd [closable]="false" id="confirmComissao">
        <ng-template pTemplate="footer">
            <button type="button" pButton icon="pi pi-times" label="Retornar" (click)="cd.reject()"></button>
            <button type="button" pButton icon="pi pi-check" label="Confirmar" (click)="cd.accept()"></button>
        </ng-template>
    </p-confirmDialog>
































    <div class="flex justify-content-center flex-wrap ">
        <div class="flex align-items-center justify-content-center">
            <footer class="xl:w-5" id="footerComissao">
                <p-button label="Fechar Comissão" id="botaoFecharComissao" [style]="{width: '100%'}"
                    (click)="fechaComissao()">
                </p-button>
            </footer>
        </div>
    </div>

</div>