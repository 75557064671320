import { HorarioService } from './../horario.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { Horario } from 'src/assets/horario';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';
import { PermissoesService } from 'src/permissoes.service';

@Component({
  selector: 'app-cadastrar-horarios',
  templateUrl: './cadastrar-horarios.component.html',
  styleUrls: ['./cadastrar-horarios.component.scss'],
  providers: [ConfirmationService, MessageService],
  encapsulation: ViewEncapsulation.None
})
export class CadastrarHorariosComponent implements OnInit {
  // barbeiro: Barbeiro[];
  itemsHorarios: MenuItem[] = [];

  horario: Horario[] = [];

  /* variaveis do componente */
  codcli: number = 0;
  horarioSelec: Horario = {}
  codhor: number = 0;
  codusuresp: string = '';

  /**VARIAVEIS DE PERMISSÕES */
  permissao: any;
  alterar: boolean = false;
  excluir: boolean = false;
  inativar: boolean = false;
  cadastrar: boolean = false

  constructor(private decodedTokenService: DecodedTokenService, private horarioService: HorarioService, private confirmationService: ConfirmationService,
    private router: Router, private permissoesService: PermissoesService, private messageService: MessageService) {

  }

  ngOnInit(): void {
    this.recuperaCodUsu();
    this.recuperaCodCli();
    this.recuperaPermissao();
    this.buscarHorarioPorCodCliente();


    this.itemsHorarios = [{
      items: [{
        label: 'Detalhes',
        command: () => {
          this.editarHorario(this.horarioSelec)
        },
        // routerLink: ['/horarios']
      }
      ]
    },
    {
      items: [{
        label: 'Excluir',
        styleClass: 'linhaMenu',
        command: () => {
          this.confirmaExclusaoHorario();
        }
      }
      ]
    }
    ];
  }

  recuperaCodUsu() {
    this.codusuresp = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }

  recuperaCodCli() {
    this.codcli = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODCLI;
  }

  buscarHorarioPorCodCliente() {
    console.log(this.codcli)

    if (this.permissao != undefined && this.permissao.length > 0) {
      this.horarioService.buscarHorarioFuncPorCodCli(this.permissao[0].codcli, this.codusuresp).subscribe((res) => {
        console.log(res);
        this.horario = res;
      });
    } else {
      this.horarioService.buscarHorarioFuncPorCodCli(this.codcli, this.codusuresp).subscribe((res) => {
        console.log(res);
        this.horario = res;
      });
    }
  }

  horarioSelecionado(horario: Horario) {
    this.horarioSelec = horario;
  }


  editarHorario(horario: Horario) {
    if (this.alterar || this.codcli > 0) {
      this.horarioService.setHorario(horario);
      this.router.navigate([('/horarios')]);
    } else {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
  }

  cadastrarHorario() {
    if (this.cadastrar || this.codcli > 0) {
      var auxHorario: Horario = {}
      this.horarioService.setHorario(auxHorario);
      this.router.navigate([('/horarios')]);
    } else {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }

  }

  excluiHorarioPorCodHor() {
    this.horarioService.excluiHorarioPorCodHor(this.horarioSelec.codhor!, this.codusuresp).subscribe((res) => {
      console.log(res);
      this.horario = this.horario.filter((x: any) => x.codhor != res.result); //FILTRA TUDO O QUE É DIFERENTE DO QUE FOI EXCLUIDO
      console.log(this.horario)
    });
  }


  confirmaExclusaoHorario() {
    /**VERIFICA SEM TEM PERMISSÃO PARA ESSA AÇÃO */
    if (this.excluir || this.codcli > 0) {
      this.confirmationService.confirm({
        message: 'Deseja mesmo excluir ' + this.horarioSelec.nomehor + ' ?',
        header: 'Excluir Horário',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.excluiHorarioPorCodHor();
        },
        reject: () => {
        }
      });
    } else {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }

  }



  /**RECUPERA AS PERMISSÕES */
  recuperaPermissao() {
    this.permissao = this.permissoesService.getPermissao();
    console.log(this.permissao)
    if (this.permissao != undefined && this.permissao.length > 0) {
      if (this.permissao[3].acao[0].PERMITE === 'S') {
        this.cadastrar = true;
      }
      if (this.permissao[3].acao[1].PERMITE === 'S') {
        this.alterar = true;
      }
      if (this.permissao[3].acao[2].PERMITE === 'S') {
        this.excluir = true;
      }
    }
    console.log(this.cadastrar, this.alterar, this.excluir)
  }


  /**METODO DE TOAST */
  showError(titulo: string, mensagem: string) {
    if (screen.width < 640 || screen.height < 480) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else if (screen.width < 1024 || screen.height < 768) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else {
      this.messageService.add({
        key: 'tr',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    }
  }
}
