import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Comissao } from 'src/assets/comissao';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { ComissaoService } from 'src/comissao.service';
import { PermissoesService } from 'src/permissoes.service';

@Component({
  selector: 'app-cadastrar-comissao',
  templateUrl: './cadastrar-comissao.component.html',
  styleUrls: ['./cadastrar-comissao.component.scss'],
  providers: [ConfirmationService, MessageService],
  encapsulation: ViewEncapsulation.None
})
export class CadastrarComissaoComponent implements OnInit {
  codcli: number = 0;
  comissao: Comissao[] = [];
  comissaoSelec: Comissao = {}
  itemsComissao: MenuItem[] = [];
  codusuresp: string = '';

  /**VARIAVEIS DE PERMISSÕES */
  permissao: any;
  alterar: boolean = false;
  excluir: boolean = false;
  cadastrar: boolean = false

  constructor(private comissaoService: ComissaoService, private decodedTokenService: DecodedTokenService,
    private confirmationService: ConfirmationService, private router: Router, private permissoesService: PermissoesService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.recuperaCodUsu();
    this.recuperaCodCli();
    this.recuperaPermissao();
    this.buscaComissao();


    this.itemsComissao = [{
      items: [{
        label: 'Detalhes',
        command: () => {
          this.editarComissao(this.comissaoSelec)
        },
        // routerLink: ['/comissao'],
      }
      ]
    },
    {
      items: [{
        label: 'Excluir',
        styleClass: 'linhaMenu',
        command: () => {
          this.confirmaExclusaoPesquisa();
        }
      }
      ]
    }
    ];
  }

  recuperaCodCli() {
    this.codcli = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODCLI;
  }

  recuperaCodUsu() {
    this.codusuresp = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }


  buscaComissao() {
    /**VERIFICA SE VAI FAZER A BUSCA PELO CODCLI DO TOKEN OU DA PERMISSÃO */
    if (this.permissao != undefined && this.permissao.length > 0) {
      this.comissaoService.buscaComissaoPorCodcli(this.permissao[0].codcli, this.codusuresp).subscribe((res) => {
        this.comissao = res;
      })
    } else {
      this.comissaoService.buscaComissaoPorCodcli(this.codcli, this.codusuresp).subscribe((res) => {
        this.comissao = res;
      })
    }
  }


  comissaoSelecionada(comissao: Comissao) {
    this.comissaoSelec = comissao;
  }

  editarComissao(comissao: Comissao) {
    if (this.alterar || this.codcli > 0) {
      this.comissaoService.setComissao(comissao);
      this.router.navigate([('/comissao')]);
    } else {
      this.showError('Atenção', 'Ação não permitida.');
      return
    }
  }

  cadastrarComissao() {
    /**VERIFICA SEM TEM PERMISSÃO PARA ESSA AÇÃO */
    if (this.cadastrar || this.codcli > 0) {
      var auxComissao: Comissao = {}
      this.comissaoService.setComissao(auxComissao);
      this.router.navigate([('/comissao')]);
    } else {
      this.showError('Atenção', 'Ação não permitida.');
      return
    }
  }

  excluiComissaoPorNucom() {
    this.comissaoService.excluiComissao(this.comissaoSelec.nucom!, this.codusuresp).subscribe((res) => {
      console.log(res.result);
      this.comissao = this.comissao.filter((x: any) => x.nucom != res.result); //FILTRA TUDO O QUE É DIFERENTE DO QUE FOI EXCLUIDO
    });
  }

  confirmaExclusaoPesquisa() {
    /**VERIFICA SEM TEM PERMISSÃO PARA ESSA AÇÃO */
    if (this.excluir || this.codcli > 0) {
      this.confirmationService.confirm({
        message: 'Deseja mesmo excluir o Fechamento: ' + new Date(this.comissaoSelec.dtini!).toLocaleDateString('pt-BR', { timeZone: 'UTC' }) + ' - ' +
          new Date(this.comissaoSelec.dtfin!).toLocaleDateString('pt-BR', { timeZone: 'UTC' }) + ' ? (Essa ação não poderá ser revertida)',
        header: 'Excluir Pesquisa',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.excluiComissaoPorNucom();
        },
        reject: () => {
        }
      });
    } else {
      this.showError('Atenção', 'Ação não permitida.');
      return
    }
  }


  /**RECUPERA AS PERMISSÕES */
  recuperaPermissao() {
    this.permissao = this.permissoesService.getPermissao();
    console.log(this.permissao)
    if (this.permissao != undefined && this.permissao.length > 0) {
      if (this.permissao[7].acao[0].PERMITE === 'S') {
        this.cadastrar = true;
      }
      if (this.permissao[7].acao[1].PERMITE === 'S') {
        this.alterar = true;
      }
      if (this.permissao[7].acao[2].PERMITE === 'S') {
        this.excluir = true;
      }
    }
    console.log(this.cadastrar, this.alterar, this.excluir)
  }


  /**METODO DE TOAST */
  showError(titulo: string, mensagem: string) {
    if (screen.width < 640 || screen.height < 480) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else if (screen.width < 1024 || screen.height < 768) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else {
      this.messageService.add({
        key: 'tr',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    }
  }
}
