import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, first, tap } from 'rxjs';
import { environment } from './environments/environment';
import { LoginService } from './login/login.service';

@Injectable({
  providedIn: 'root'
})
export class FinanceiroService {

  constructor(private http: HttpClient, private loginService: LoginService) { }


  preencheFinanceiro(nufin: any, tipo: string, origem: string, codconta: number, codcat: number, vlrlanc: number, dtvenc: any,
    codparc: number, detalhe: string, codusuresp: string, codcli: number, codemp: any, codfunc: number, dtbaixa: any,
    codusubaixa: string, vlrbaixa: number, codtipneg: number): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const financeiro = {
      "nufin": nufin, "tipo": tipo, "origem": origem, "codconta": codconta, "codcat": codcat, "vlrlanc": vlrlanc, "dtvenc": dtvenc,
      "codparc": codparc, "detalhe": detalhe, "codusuresp": codusuresp, "codcli": codcli, "codemp": codemp, "codfunc": codfunc,
      "dtbaixa": dtbaixa, "codusubaixa": codusubaixa, "vlrbaixa": vlrbaixa, "codtipneg": codtipneg
    }

    return this.http
      .post<any>(`${environment.BASE_URL}/financeiro/createfinanceiro`, financeiro, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  desmembraFinanceiro(nufin: any, tipo: string, origem: string, codconta: number, codcat: number, vlrlanc: number, dtvenc: any,
    codparc: number, detalhe: string, codusuresp: string, codcli: number, codemp: any, codfunc: number, dtbaixa: any,
    codusubaixa: string, vlrbaixa: any, codtipneg: number): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const financeiro = {
      "nufin": nufin, "tipo": tipo, "origem": origem, "codconta": codconta, "codcat": codcat, "vlrlanc": vlrlanc, "dtvenc": dtvenc,
      "codparc": codparc, "detalhe": detalhe, "codusuresp": codusuresp, "codcli": codcli, "codemp": codemp, "codfunc": codfunc,
      "dtbaixa": dtbaixa, "codusubaixa": codusubaixa, "vlrbaixa": vlrbaixa, "codtipneg": codtipneg
    }

    return this.http
      .post<any>(`${environment.BASE_URL}/financeiro/desmembrafinanceiro`, financeiro, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  baixarFinanceiro(nufin: number, vlrbaixa: number, dtbaixa: any, codusubaixa: string, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const financeiro = { "nufin": nufin, "vlrbaixa": vlrbaixa, "dtbaixa": dtbaixa, "codusubaixa": codusubaixa, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/financeiro/baixarfinanceiro`, financeiro, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  estornarFinanceiro(nufin: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const financeiro = { "nufin": nufin, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/financeiro/estornarfinanceiro`, financeiro, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  getOpcOrigem(): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);


    return this.http
      .get<any>(`${environment.BASE_URL}/financeiro/getopcorigem`, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  buscaCategoriaPorCodCli(codcli: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const cliente = { "codcli": codcli, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/financeiro/getcategoriabycodcli`, cliente, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  createCategoriaFinanceira(nucat: any, descr: string, codcli: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const categoria = { "nucat": nucat, "descr": descr, "codcli": codcli, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/financeiro/createcategoriafin`, categoria, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  excluiCategoriaFinanceira(nucat: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const categoria = { "nucat": nucat, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/financeiro/excluicategoriafin`, categoria, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  buscaContaPorCodCli(codcli: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const cliente = { "codcli": codcli, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/financeiro/getcontabycodcli`, cliente, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  createContaFinanceira(nucon: any, descr: string, codcli: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const conta = { "nucon": nucon, "descr": descr, "codcli": codcli, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/financeiro/createcontafin`, conta, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  excluiConta(nucon: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const conta = { "nucon": nucon, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/financeiro/excluiconta`, conta, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }
  //dtlancini, dtlancfin, dtvencini, dtvencfin, dtbaixaini, dtbaixafin, pendente, provisao

  buscaMovFinanceiro(tipo: any, origem: string, codconta: number, codcat: number, codparc: number, detalhe: string, codemp: any, codfunc: number, codcli: number,
    dtlancini: any, dtlancfin: any, dtvencini: any, dtvencfin: any, dtbaixaini: any, dtbaixafin: any, pendente: any, provisao: any, codusuresp: string, codtipneg: number): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const financeiro = {
      "tipo": tipo, "origem": origem, "codconta": codconta, "codcat": codcat, "codparc": codparc,
      "detalhe": detalhe, "codemp": codemp, "codfunc": codfunc, "codcli": codcli, "dtlancini": dtlancini, "dtlancfin": dtlancfin, "dtvencini": dtvencini,
      "dtvencfin": dtvencfin, "dtbaixaini": dtbaixaini, "dtbaixafin": dtbaixafin, "pendente": pendente, "provisao": provisao, "codusuresp": codusuresp,
      "codtipneg": codtipneg
    }

    return this.http
      .post<any>(`${environment.BASE_URL}/financeiro/getmovfinanceiro`, financeiro, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  geraRelatorioFinanceiroExcel(tipo: any, origem: string, codconta: number, codcat: number, codparc: number, detalhe: string, codemp: any, codfunc: number, codcli: number,
    dtlancini: any, dtlancfin: any, dtvencini: any, dtvencfin: any, dtbaixaini: any, dtbaixafin: any, pendente: any, provisao: any, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const financeiro = {
      "tipo": tipo, "origem": origem, "codconta": codconta, "codcat": codcat, "codparc": codparc,
      "detalhe": detalhe, "codemp": codemp, "codfunc": codfunc, "codcli": codcli, "dtlancini": dtlancini, "dtlancfin": dtlancfin, "dtvencini": dtvencini,
      "dtvencfin": dtvencfin, "dtbaixaini": dtbaixaini, "dtbaixafin": dtbaixafin, "pendente": pendente, "provisao": provisao, "codusuresp": codusuresp
    }

    return this.http
      .post<any>(`${environment.BASE_URL}/financeiro/relatoriofinanceiro`, financeiro, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  buscaTipoNegociacaoPorCodCli(codcli: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const cliente = { "codcli": codcli, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/financeiro/gettiponegbycodcli`, cliente, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  createTipoNegociacaoFinanceira(codtipneg: any, descr: string, codcli: number, negpadrao: any, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const conta = { "codtipneg": codtipneg, "descr": descr, "codcli": codcli, "negpadrao": negpadrao, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/financeiro/preenchetiponeg`, conta, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  excluiTipoNegociacao(codtipneg: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const negociacao = { "codtipneg": codtipneg, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/financeiro/excluitiponeg`, negociacao, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  getMovFinanceiroByNufinPrimario(codcli: number, nufinprimario: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const financeiro = { "codcli": codcli, "nufinprimario": nufinprimario, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/financeiro/getmovfinanceirobynufinprimario`, financeiro, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  getMovFinanceiroByNuid(codcli: number, nuid: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const financeiro = { "codcli": codcli, "nuid": nuid, "codusuresp": codusuresp }

    return this.http
      .post<any>(`${environment.BASE_URL}/financeiro/getmovfinanceirobynuid`, financeiro, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  excluiFinanceiro(nufin: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const financeiro = { "nufin": nufin, "codusuresp": codusuresp }

    return this.http
      .delete<any>(`${environment.BASE_URL}/financeiro/excluifinanceiro`, { headers: headers, body: financeiro })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

}
