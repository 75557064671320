<p-toast position="bottom-center" key="bc"></p-toast>
<p-toast position="top-right" key="tr"></p-toast>
<div class="pt-7">


    <div class="flex flex-column  pb-3">
        <div class="flex align-items-center justify-content-center">
            <img src="./assets/iconePesquisa.svg" />
        </div>
        <div class=" flex align-items-center justify-content-center">
            <span
                class=" tituloNegritoPesquisa maximoCaracter white-space-nowrap overflow-hidden text-overflow-ellipsis">
                {{pesquisaTitulo}}</span>
        </div>

    </div>


    <div class="flex justify-content-center flex-wrap ">


        <div class="xl:col-5 lg:col-5 md:col-5 sm:col-5">
            <div class="shadow-4">


                <p-card header="Dados da Pesquisa" id="cardPesquisa">
                    <div>
                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            (click)="mostraDialogNome()">
                            <div class="flex gap-2 ">
                                <img src="./assets/imgNomePesquisa.svg" class=" xl:w-3rem" />
                                <div class="flex flex-column align-items-start">
                                    <div class=" fontTituloPesquisa">
                                        <span class="xl:text-xl">Nome</span>
                                    </div>
                                    <div class="fontCinzaPesquisa containerPesquisa">
                                        <span class="xl:text-base">Será como você identificará suas perguntas
                                            cadastradas</span>
                                    </div>
                                </div>
                            </div>
                            <div class="align-items-center justify-content-center  ">
                                <img src="./assets/imgBarbeariaPopup.png" />
                            </div>
                        </div>

                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            (click)="mostraDialogVigencia()">
                            <div class="flex gap-2 ">
                                <img src="./assets/imgPesquisaVigencia.svg" class=" xl:w-3rem" />
                                <div class="flex flex-column  align-items-start">
                                    <div class=" fontTituloPesquisa">
                                        <span class="xl:text-xl">Vigência </span>
                                    </div>
                                    <div class="fontCinzaPesquisa containerPesquisa">
                                        <span class="xl:text-base">Preencher o período da vigência da pesquisa</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex align-items-center justify-content-center ">
                                <img src="./assets/imgBarbeariaPopup.png" class="iconePopUpBarbearia" />
                            </div>
                        </div>

                        <div class="formgroup-inline justify-content-between flex-wrap pb-2"
                            (click)="mostraDialogPerguntas()">
                            <div class="flex gap-2 ">
                                <img src="./assets/imgPerguntas.svg" class=" xl:w-3rem" />
                                <div class="flex flex-column align-items-start">
                                    <div class="fontTituloPesquisa">
                                        <span class="xl:text-xl">Perguntas</span>
                                    </div>
                                    <div class="fontCinzaPesquisa containerPesquisa">
                                        <span class="xl:text-base">Serão as perguntas que seus clientes
                                            responderão</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex align-items-center justify-content-end  ">
                                <img src="./assets/imgBarbeariaPopup.png" class="iconePopUpBarbearia" />
                            </div>
                        </div>

                        <div class="formgroup-inline justify-content-between flex-wrap"
                            (click)="mostraDialogRelatorio() ">
                            <div class="flex gap-2 ">
                                <img src="./assets/Resultados.svg" class=" xl:w-3rem" />
                                <div class="flex flex-column align-items-start">
                                    <div class="fontTituloPesquisa">
                                        <span class="xl:text-xl">Resultados</span>
                                    </div>
                                    <div class="fontCinzaPesquisa containerPesquisa">
                                        <span class="xl:text-base">Aqui você visualiza as respostas das perguntas</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex align-items-center justify-content-end ">
                                <img src="./assets/imgBarbeariaPopup.png" />
                            </div>
                        </div>

                        <!-- <div class="formgroup-inline justify-content-between flex-wrap">
                            <div class="flex gap-2 ">
                                <img src="./assets/imgBlackList.svg" class=" xl:w-3rem" />
                                <div class="flex flex-column align-items-start">
                                    <div class="fontTituloPesquisa">
                                        <span class="xl:text-xl">Blacklist</span>
                                    </div>
                                    <div class="fontCinzaPesquisa containerPesquisa">
                                        <span class="xl:text-base">Clientes que não respoderão sua pergunta</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex align-items-center justify-content-end ">
                                <img src="./assets/imgBarbeariaPopup.png" />
                            </div>
                        </div> -->
                    </div>
                </p-card>
            </div>
        </div>
    </div>
    <br>


    <!--                          DIALOG DO NOME               -->
    <p-dialog header="Nome da Pesquisa" [(visible)]="showNome" [modal]="true"
        [style]="{'width': '350px', 'height': '290px'}" [closable]="true" id="dialogPesquisaNome">

        <div class="col-12">
            <div class="fontDialogPesquisa flex align-items-center justify-content-center ">
                <span>Informe um nome para identificar sua pesquisa</span>
            </div>
        </div>
        <input type="text" [(ngModel)]="nomePesquisa" pInputText placeholder="Nome da Pesquisa"
            [style]="{width: '100%'}" class="mt-2">


        <div *ngIf="teveRespostaEstabelecimento">
            <p-button id="botaoSpinnerPesquisa" label="Estabelecimento" [disabled]="true" class="p-button-text">
                <i class="pi pi-spin pi-spinner teste flex-order-1" style="font-size: 1rem"></i>
            </p-button>
        </div>

        <div *ngIf="!teveRespostaEstabelecimento">
            <p-dropdown id="dropPergunta" [options]="estabelecimento" [(ngModel)]="estabelecimentoSelecionada"
                placeholder="Barbearia" optionLabel="nome" optionValue="codemp" [style]="{'width': '100%'}"
                scrollHeight="100px">
            </p-dropdown>
        </div>



        <div *ngIf="!loading" [style]="{width: '100%'}">
            <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                'border-radius': '11px'}" (click)="salvaPesquisa()"></button>
        </div>
        <div *ngIf="loading" [style]="{width: '100%'}">
            <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 mt-3"
                [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
                'border-radius': '11px'}"></button>
        </div>


    </p-dialog>

    <!--                          DIALOG DE VIGENCIA               -->
    <p-dialog header="Vigência" [(visible)]="showVigencia" [modal]="true"
        [style]="{'width': '350px', 'height': '260px'}" [closable]="true" id="dialogPesquisaVigencia">
        <div class="flex flex-column align-items-center">
            <div class="col-12">
                <div class="fontDialogPesquisa flex align-items-center justify-content-center ">
                    <span>Informe o período que a pesquisa estará vigente</span>
                </div>
            </div>
        </div>

        <div class="">
            <p-calendar [touchUI]="true" [readonlyInput]="false" inputId="touchui" dateFormat="dd/mm/yy"
                [minDate]="ontem" [required]="true" [readonlyInput]="true" id="calendarioPesquisa"
                [(ngModel)]="dataInicial" placeholder="Data Inicial">
            </p-calendar>
            <div class="mt-1">
                <p-calendar [touchUI]="true" [readonlyInput]="false" inputId="touchui" dateFormat="dd/mm/yy"
                    [minDate]="dataInicial" [required]="true" [readonlyInput]="true" id="calendarioPesquisa"
                    [(ngModel)]="dataFinal" placeholder="Data Final">
                </p-calendar>
            </div>

        </div>

        <div *ngIf="!loading" [style]="{width: '100%'}">
            <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
     'border-radius': '11px'}" (click)="criaDataVigencia()"></button>
        </div>
        <div *ngIf="loading" [style]="{width: '100%'}">
            <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 mt-3"
                [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
     'border-radius': '11px'}"></button>
        </div>
    </p-dialog>


    <!--                          DIALOG DE PERGUNTAS               -->
    <p-dialog header="Perguntas" [(visible)]="showPerguntas" [modal]="true"
        [breakpoints]="{'960px': '534px', '640px': '360px'}" [closable]="true" id="dialogPesquisaPerguntas">
        <div class="flex flex-column align-items-center">
            <div class="col-12">
                <div class="fontDialogPesquisa flex align-items-center justify-content-center ">
                    <span>Crie, edite ou remova as perguntas que seus clientes responderão</span>
                </div>
            </div>
        </div>

        <div class="contornoBotoes">


            <div class="flex flex-wrap gap-2 pl-2 pb-2">
                <button id="cadastrarPesquisa" pButton type="button" label="Cadastrar" class="col-12 mt-2"
                    (click)="mostraDialogCadPer()"
                    [style]="{'width':'100px', 'height': '24px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)','border-radius': '8px', 'border': 'none', 'font-size': '10px'}"></button>
                <button id="excluirPesquisa" pButton type="button" label="Excluir" class="col-12 mt-2 "
                    (click)="confirmaExclusaoPergunta()"
                    [style]="{'width':'100px', 'height': '24px', 'background-color': 'var(--cor2)', 'color': 'var(--cor7)','border-radius': '8px', 'border': 'none', 'font-size': '10px'}"></button>
            </div>

        </div>
        <div class="pt-2"></div>
        <div class="contornoCampos ">
            <div class="col">
                <div class="flex  align-items-center justify-content-between gap-3">

                    <div class=" ">


                        <span class="fontTituloTabela " style="white-space: nowrap;">
                            Lista de perguntas
                        </span>
                    </div>
                    <div>

                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input type="text" class="col-12" pInputText placeholder="Pesquisar" id="campoPesquisa"
                                (keydown)="filtraPerguntas()">
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="pt-2">
            <p-table [value]="perguntas" [scrollable]="true" scrollHeight="300px" id="tabela">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width:1px"> <p-checkbox [value]="perguntas"
                                [(ngModel)]="selecionouTodasPerguntasExcluir" (click)="clicouCheckbox()">
                            </p-checkbox>
                        </th>
                        <th class="fontTituloTabela" style="width:1px">Nº</th>
                        <th class="fontTituloTabela" style="min-width:140px">Pergunta</th>
                        <th class="fontTituloTabela" style="min-width:80px">Tipo Resposta</th>
                        <th class="fontTituloTabela" style="min-width:80px">Tipo Pergunta</th>
                        <th class="fontTituloTabela" style="min-width:80px">Tipo Especial</th>
                        <th style="min-width:1px"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-perguntas let-indice="rowIndex">
                    <tr>
                        <td style="width:1px">
                            <p-checkbox [value]="perguntas" [(ngModel)]="perguntaSelecionadaExcluir"
                                (click)="selecionouPergExclusao()">
                            </p-checkbox>
                        </td>
                        <td class="fontConteudoTabela" style="width:1px">{{indice+1}}</td>
                        <td class="fontConteudoTabela" style="min-width:140px">{{perguntas.pergunta}}</td>
                        <td class="fontConteudoTabela" style="min-width:80px">{{perguntas.tipor}}</td>
                        <td class="fontConteudoTabela" style="min-width:80px">{{perguntas.tipop}}</td>
                        <td class="fontConteudoTabela" style="min-width:80px">{{perguntas.tipoe}}</td>
                        <td style="min-width:1px" alignFrozen="right" pFrozenColumn>
                            <img src="./assets/iconePesquisaTabela.svg" class="cursor-pointer" (click)="selecionouPergEdicao(perguntas)" />
                        </td>
                    </tr>
                </ng-template>
            </p-table>


        </div>

        <div [style]="{width: '100%'}">
            <button pButton type="button" label="Voltar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
     'border-radius': '11px'}" (click)="clicouVoltar()"></button>
        </div>

    </p-dialog>


    <!--                          DIALOG DE CADASTRO DE PERGUNTAS               -->
    <p-dialog header="Perguntas" [(visible)]="showCadPer" [modal]="true"
        [breakpoints]="{'960px': '534px', '640px': '360px'}" [closable]="true" id="dialogPesquisaPerguntas">

        <div class="flex flex-column align-items-center">
            <div class="col-12">
                <div class="fontDialogPesquisa flex align-items-center justify-content-center ">
                    <span>Cadastro / Edição de perguntas</span>
                </div>
            </div>
        </div>
        <div class="pb-1">
            <p-dropdown id="dropPergunta" [options]="tipoPer" [(ngModel)]="tipoPerSelecionado"
                placeholder="Tipo pergunta" optionLabel="pergunta" optionValue="tipo" [style]="{'width': '100%'}"
                scrollHeight="130px" [filter]="false" (onChange)="showVariaveis()">
            </p-dropdown>
        </div>

        <div class="flex align-items-center justify-content-center retanguloPesquisa" *ngIf="showVarEmp">
            <span class="fontRetanguloPesquisa">{{varEmp}}</span>
        </div>

        <div class="flex align-items-center justify-content-center retanguloPesquisa" *ngIf="showVarProfissional">
            <span class="fontRetanguloPesquisa">{{varProfissional}}</span>
        </div>

        <div class="flex align-items-center justify-content-center retanguloPesquisa" *ngIf="showVarServico">
            <span class="fontRetanguloPesquisa">{{varServico}}</span>
        </div>



        <div class="pb-2 pt-1">

            <textarea pInputTextarea placeholder="Pergunta" [(ngModel)]="perg" [autoResize]="true" maxlength="150"
                class="expandeTextArea" (ngModelChange)=" contaCaracter()"></textarea>
            <div class="flex justify-content-end flex-wrap fontContadorCaracter">{{caracteresRestantes}}/150</div>
        </div>

        <p-dropdown id="dropPergunta" [options]="tipoResp" [(ngModel)]="tipoRespSelecionado"
            placeholder="Tipo de resposta" optionLabel="resposta" optionValue="tipo" [style]="{'width': '100%'}"
            scrollHeight="130px" [filter]="false">
        </p-dropdown>

        <p-dropdown id="dropPergunta" [options]="tipoEsp" [(ngModel)]="tipoEspSelecionado" placeholder="Tipo especial"
            optionLabel="especial" optionValue="tipo" [style]="{'width': '100%'}" scrollHeight="80px" [filter]="false">
        </p-dropdown>

        <div *ngIf="!loading" [style]="{width: '100%'}">
            <button pButton type="button" label="Salvar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
        'border-radius': '11px'}" (click)="salvaPergunta()"></button>
        </div>
        <div *ngIf="loading" [style]="{width: '100%'}">
            <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 mt-3"
                [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
        'border-radius': '11px'}"></button>
        </div>
    </p-dialog>



    <!--                          DIALOG DE RELATORIO DA PESQUISA               -->
    <p-dialog header="Relatório" [(visible)]="showRelatorio" [modal]="true"
        [breakpoints]="{'960px': '534px', '640px': '360px'}" [closable]="true" id="dialogRelatorioPesquisa">
        <div *ngIf="!mostraTabelaRelatorio">
            <div class="flex flex-column align-items-center">
                <div class="col-12">
                    <div class="fontDialogPesquisa flex align-items-center justify-content-center ">
                        <span>Informe o período de vigência de sua pesquisa.</span>
                    </div>
                </div>
            </div>

            <div class="">
                <p-calendar [touchUI]="true" [readonlyInput]="false" inputId="touchui" dateFormat="dd/mm/yy"
                    [required]="true" [readonlyInput]="true" id="calendarioPesquisa" [(ngModel)]="dataInicialRel"
                    placeholder="Data Inicial">
                </p-calendar>
                <div class="mt-1">
                    <p-calendar [touchUI]="true" [readonlyInput]="false" inputId="touchui" dateFormat="dd/mm/yy"
                        [minDate]="dataInicialRel" [required]="true" [readonlyInput]="true" id="calendarioPesquisa"
                        [(ngModel)]="dataFinalRel" placeholder="Data Final">
                    </p-calendar>
                </div>

            </div>

            <div *ngIf="!loading" [style]="{width: '100%'}">
                <button pButton type="button" label="Pesquisar" class="col-12 mt-3" [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
     'border-radius': '11px'}" (click)="geraTabelaRelatorioPesquisa()"></button>
            </div>
            <div *ngIf="loading" [style]="{width: '100%'}">
                <button id="botaoLoading" pButton [disabled]="true" type="button" label="Salvando" class="col-12 mt-3"
                    [style]="{'width':'100%', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
     'border-radius': '11px'}"></button>
            </div>
        </div>

        <div *ngIf="mostraTabelaRelatorio">
            <p-button id="botaoPDF" styleClass="p-button-outlined" (click)="geraRelatorioPesquisa()" class="col-6 mt-2">
                <span class="ml-2 font-bold">Baixar PDF</span>
                <img alt="logo" class=" pl-2" src="./assets/Download PDF_BUTTON.svg" />
            </p-button>
            <p-button id="botaoPDF" styleClass="p-button-outlined" (click)="geraRelatorioExcelPesquisa()"
                class="col-6 mt-2">
                <span class="ml-2 font-bold">Baixar Excel</span>
                <img alt="logo" class=" pl-2" src="./assets/DownloadExcel_BUTTON.svg" />
            </p-button>
            <div class="pt-2">
                <p-table [value]="relatorioPesquisa" [scrollable]="true" scrollHeight="300px" id="tabela">
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="fontTituloTabela" style="width:1px">Nº</th>
                            <th class="fontTituloTabela" style="min-width:80px">Pesquisa</th>
                            <th class="fontTituloTabela" style="min-width:140px">Pergunta</th>
                            <th class="fontTituloTabela" style="min-width:80px">Resposta</th>
                            <th class="fontTituloTabela" style="min-width:80px">Data da Resposta</th>
                            <th class="fontTituloTabela" style="min-width:80px">Usuário</th>

                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-relatorioPesquisa let-indice="rowIndex">
                        <tr>
                            <td class="fontConteudoTabela" style="width:1px">{{indice+1}}</td>
                            <td class="fontConteudoTabela" style="min-width:80px">{{relatorioPesquisa.nomepesquisa}}
                            </td>
                            <td class="fontConteudoTabela" style="min-width:140px">{{relatorioPesquisa.pergunta}}</td>
                            <td class="fontConteudoTabela" style="min-width:80px">{{relatorioPesquisa.resposta}}</td>
                            <td class="fontConteudoTabela" style="min-width:80px">{{relatorioPesquisa.dtresposta|
                                date:'dd/MM/yyyy'}}</td>
                            <td class="fontConteudoTabela" style="min-width:80px">{{relatorioPesquisa.nomeusu}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>



            <button pButton type="button" label="Voltar" class="col-12 mt-3" [style]="{ 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)',
             'border-radius': '11px'}" (click)="escolheData()"></button>


        </div>
    </p-dialog>
</div>

<!-- CONFIRMDIALOG DE EXCLUSÃO -->
<p-confirmDialog #cd [closable]="false" id="confirmPesquisas">
    <ng-template pTemplate="footer">
        <button type="button" pButton icon="pi pi-times" label="Retornar" (click)="cd.reject()"></button>
        <button type="button" pButton icon="pi pi-check" label="Confirmar" (click)="cd.accept()"></button>
    </ng-template>
</p-confirmDialog>