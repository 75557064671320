import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { AlertService } from 'src/alert.service';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { UtilitariosService } from 'src/assets/utilitarios.service';
import { FinanceiroService } from 'src/financeiro.service';

@Component({
  selector: 'app-categorias',
  templateUrl: './categorias.component.html',
  styleUrls: ['./categorias.component.scss'],
  providers: [MessageService, ConfirmationService],
  encapsulation: ViewEncapsulation.None
})
export class CategoriasComponent implements OnInit {
  showCadastroCategoria: boolean = false;
  loading: boolean = false;

  /**MENU */
  itemsCategoria: MenuItem[] = [];

  /**CODIGOS */
  codcli: number = 0;
  nucat: any;
  descr: string = '';
  codusuresp: string = '';

  categorias: any;
  categoriasSelec: any;

  constructor(private financeiroService: FinanceiroService, private decodedTokenService: DecodedTokenService, private confirmationService: ConfirmationService,
    private utilitariosService: UtilitariosService, private alertService: AlertService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.recuperaCodCli();


    this.itemsCategoria = [{
      items: [{
        label: 'Detalhes',
        command: () => {
          this.preencheDadosDialogCategoria();
        }
      }
      ]
    },
    {
      items: [{
        label: 'Excluir',
        styleClass: 'linhaMenu',
        command: () => {
          this.confirmaExclusaoCategoria();
        }
      }
      ]
    }
    ];
  }

  /**RECUPERA O CODUSU PARA SER USADO NA AUDITORIA DOS METODOS */
  recuperaCodUsu() {
    this.codusuresp = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }


  /**ABRE O DIALOG PARA CADASTRO DE UMA NOVA CATEGORIA*/
  mostraDialogCategoria() {
    this.nucat = null;
    this.descr = '';
    this.showCadastroCategoria = true;
  }

  /**METODO QUE PREENCHE O DIALOG DA CATEGORIA APOS CLICAR EM 'Detalhes'*/
  preencheDadosDialogCategoria() {
    this.nucat = this.categoriasSelec.nucat;
    this.descr = this.categoriasSelec.descr;

    this.showCadastroCategoria = true;
  }

  /**RECUPERA O CODUSU PARA FAZER AÇÕES DOS METODOS */
  recuperaCodCli() {
    this.codcli = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODCLI;
    this.buscaCategoriaPorCodcli();
  }



  /**METODO DE CRIAÇÃO/EDIÇÃO DA CATEGORIA */
  criaCategoria() {
    this.loading = true;

    /**FAZ VALIDAÇÃO DOS CAMPOS */
    if (this.utilitariosService.validaCampo([this.descr])) {
      console.log('É necessário preencher todos os campos.')
      this.loading = false;
      this.showError('Atenção', 'É necessário preencher todos os campos.')
      return;
    }

    if (this.nucat > 0) {
      this.financeiroService.createCategoriaFinanceira(this.nucat, this.descr, this.codcli, this.codusuresp).subscribe((res) => {
        this.alertService.alert('Categoria alterada!', 'success')
        this.loading = false;
        this.showCadastroCategoria = false;
        this.buscaCategoriaPorCodcli();
        console.log(res)
      },
        (error) => {
          console.log('deu ruim')
          this.loading = false;
          this.showCadastroCategoria = false;
          this.alertService.alert('Algo deu errado.', 'error');
        })
    } else {
      console.log(this.descr)
      console.log(this.codcli)
      this.financeiroService.createCategoriaFinanceira(null, this.descr, this.codcli, this.codusuresp).subscribe((res) => {
        this.alertService.alert('Categoria criada!', 'success')
        this.loading = false;
        this.showCadastroCategoria = false;
        this.buscaCategoriaPorCodcli();
        console.log(res)
      },
        (error) => {
          console.log('deu ruim')
          this.loading = false;
          this.showCadastroCategoria = false;
          this.alertService.alert('Algo deu errado.', 'error');
        })
    }
  }

  /**METODOS PARA BUSCAS AS CATEGORIAS DO CLIENTE E MOSTRA-LAS NA TABELA */
  buscaCategoriaPorCodcli() {
    this.categorias = [];
    this.financeiroService.buscaCategoriaPorCodCli(this.codcli, this.codusuresp).subscribe((res) => {
      this.categorias = res;
    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
      })
  }

  /**METODOS PARA PREENCHIMENTO DOS DADOS DA CATEGORIA SELECIONADA EM 'Detalhes' */
  categoriaSelecionada(categoria: any) {
    this.categoriasSelec = categoria;
    console.log(this.categoriasSelec)
  }


  /**METODOS DE EXCLUSÃO DA CONTA */
  excluiCategoriaPorNucon() {
    this.financeiroService.excluiCategoriaFinanceira(this.categoriasSelec.nucat!, this.codusuresp).subscribe((res) => {
      console.log(res);
      this.categorias = this.categorias.filter((x: any) => x.nucat != res.nucat); //FILTRA TUDO O QUE É DIFERENTE DO QUE FOI EXCLUIDO

    });
  }

  confirmaExclusaoCategoria() {
    this.confirmationService.confirm({
      message: 'Deseja mesmo excluir a Categoria: ' + this.categoriasSelec.descr,
      header: 'Excluir Categoria',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.excluiCategoriaPorNucon();
      },
      reject: () => {
      }
    });
  }











  /**METODO DE TOAST */
  showError(titulo: string, mensagem: string) {
    if (screen.width < 640 || screen.height < 480) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else if (screen.width < 1024 || screen.height < 768) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else {
      this.messageService.add({
        key: 'tr',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    }
  }
}
