import { ProdutoService } from 'src/produto.service';
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AlertService } from 'src/alert.service';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { PermissoesService } from 'src/permissoes.service';
import { MessageService } from 'primeng/api';
import { UtilitariosService } from 'src/assets/utilitarios.service';
import { Produto } from 'src/assets/produto';
import { ServicoService } from 'src/servico.service';

@Component({
  selector: 'app-produtos',
  templateUrl: './produtos.component.html',
  styleUrls: ['./produtos.component.scss'],
  providers: [MessageService],
  encapsulation: ViewEncapsulation.None
})
export class ProdutosComponent implements OnInit {
  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef;


  showProduto: boolean = false;
  showEstoque: boolean = false;
  showFotoProduto: boolean = false;
  showAtivarDesativarProduto: boolean = false;
  produto: Produto = {};

  /**RADIO BUTTON */
  valorSelecionadoProduto: string = '';


  /** VARIAVEIS DO COMPONENT */
  codusuresp: string = '';
  codcli: number = 0;
  codprod: number = 0;
  nomeprod: string = '';
  descrprod: string = '';
  preco: number = 0;
  saldo: number = 0;
  exibeNome: string = ''
  loading: boolean = false;
  comissao: any;
  nomeImagem: string = 'Escolha uma foto';

  /**VARIAVEIS DE PERMISSÕES */
  alterar: boolean = false;
  cadastrar: boolean = false;
  inativar: boolean = false;
  permissao: any;


  constructor(private produtoService: ProdutoService, private decodedTokenService: DecodedTokenService, private messageService: MessageService, private utilitariosService: UtilitariosService,
    private alertService: AlertService, private servicoService: ServicoService, private permissoesService: PermissoesService) { }

  ngOnInit(): void {
    this.recuperaCodUsu();
    this.recuperaCodCli();
    this.recuperaServico();
    this.recuperaDadosProduto();
  }



  /**METODO QUE RECUPERA O CODUSU DO JWT */
  recuperaCodUsu() {
    this.codusuresp = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }

  /**METODO QUE RECUPERA O CODCLI DO JWT */
  recuperaCodCli() {
    this.codcli = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODCLI;
  }

  /**RECUPERA O PRODUTO SELECIONADO NA TELA ANTERIOR */
  recuperaServico() {
    this.produto = this.produtoService.getProduto();
  }


  /**ATRIBUI OS VALORES DO PRODUTO RECUPERADO AOS CAMPOS DA TELA */
  recuperaDadosProduto() {
    this.nomeprod = this.produto.nomeprod!;
    this.descrprod = this.produto.descrprod!;
    this.codprod = this.produto.codprod!;
    this.exibeNome = this.produto.nomeprod!;
    this.preco = this.produto.preco!;
    this.comissao = this.produto.comissao!;
    this.valorSelecionadoProduto = this.produto.ativo!;
    this.saldo = this.produto.saldo!;
  }


  /**MOSTRA O DIALOG DE PRODUTO */
  mostraDialogProduto() {
    /**VERIFICA SE TEM PERMISSÃO PARA ESSA AÇÃO */
    // if (!this.alterar && this.permissao != undefined && this.permissao.length > 0 && this.exibeNome != '' && this.exibeNome != undefined) {
    //   this.showError('Atenção', 'Ação não permitida.')
    //   return
    // }
    this.showProduto = true;
  }

  /**MOSTRA O DIALOG DO SALDO DE ESTOQUE */
  mostraDialogEstoque() {
    if (this.codprod) {
      this.showEstoque = true;
    } else {
      return
    }
  }

  /**METODO DE CADASTRO/EDIÇÃO DE PRODUTO */
  salvarProduto() {
    if (this.comissao === undefined || this.comissao === null) {
      this.comissao = 0;
    }
    this.loading = true;
    if (this.utilitariosService.validaCampo([this.nomeprod, this.descrprod, this.preco,])) {
      this.showError('Atenção', 'É necessário preencher todos os campos.')
      this.loading = false;
      return;
    }
    this.produtoService.preencherProduto(this.codprod, this.nomeprod, this.descrprod, this.codcli,
      this.preco, this.comissao, this.codusuresp).subscribe((res) => {
        this.codprod = Object(res).codprod;
        this.exibeNome = this.nomeprod;
        this.showProduto = false;
        this.loading = false;
        this.alertService.alert('Informações do produto alteradas!', 'success')
      },
        (error) => {
          console.log(error.message)
          console.log('deu ruim')
          this.loading = false;
          this.showProduto = false;
          this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')
        });
  }

  /**METODO DE CADASTRO/EDIÇÃO DE PRODUTO */
  preencheEstoque() {
    this.loading = true;
    if (this.utilitariosService.validaCampo([this.saldo])) {
      this.showError('Atenção', 'É necessário preencher todos os campos.')
      this.loading = false;
      return;
    }
    this.produtoService.preencherSaldoProduto(this.codprod, this.saldo, this.codusuresp).subscribe((res) => {
      console.log(res);
      this.loading = false;
      this.showEstoque = false;
      this.alertService.alert('Estoque alterado!', 'success')
    },
      (error) => {
        console.log(error.message)
        console.log('deu ruim')
        this.loading = false;
        this.showEstoque = false;
        this.alertService.alert('Algo deu errado.', 'error');
        //this.showError('Atenção', 'Algo deu errado.')
      });
  }


  /**MOSTRA O DIALOG DE ATIVAR/DESATIVAR O PRODUTO */
  mostraDialogAtivarDesativarProduto() {
    //if (this.codfunc != undefined && this.codfunc != null && this.codfunc != '') {
    /**VERIFICA SE TEM PERMISSÃO PARA ESSA AÇÃO */
    // if (!this.inativar && this.permissao != undefined && this.permissao.length > 0) {
    //   this.showError('Atenção', 'Ação não permitida.')
    //   return
    // }
    if (this.codprod > 0 && this.codprod != undefined) {
      this.showAtivarDesativarProduto = true;
      this.buscaProdutoPorCodCliente();
    }



    //}
  }

  /**MOSTRA O DIALOG DE CADASTRAR A FOTO DO PRODUTO */
  mostraDialogFotoProduto() {
    //if (this.codfunc != undefined && this.codfunc != null && this.codfunc != '') {
    /**VERIFICA SE TEM PERMISSÃO PARA ESSA AÇÃO */
    // if (!this.inativar && this.permissao != undefined && this.permissao.length > 0) {
    //   this.showError('Atenção', 'Ação não permitida.')
    //   return
    // }
    //   if (this.codprod > 0 && this.codprod != undefined) {
    this.showFotoProduto = true;
    //}



    //}
  }



  /**RECUPERA O PRODUTO PARA SABER SE ESTÁ ATIVO/INATIVO */
  buscaProdutoPorCodCliente() {
    this.produtoService.buscarProdutosPorCodCli(this.codcli, this.codusuresp).subscribe((res) => {
      console.log(res)
      this.produto = res;
      this.produto = Object(res.filter((x: any) => x.codprod === this.codprod))[0];
      this.recuperaDadosProduto();
    });
  }


  /**METODO QUE ATIVA OU DESATIVA O PRODUTO */
  ativaDesativaProduto() {  //this.produto.filter(x => x.CODPROD === 10803)
    this.loading = true;
    if (this.produto.ativo != this.valorSelecionadoProduto) {
      this.servicoService.ativarDesativarServicoPorCodProd(this.codprod, this.codusuresp).subscribe((res) => {
        console.log(res)
        this.showAtivarDesativarProduto = false;
        if (Object(res).ativo === 'S') {
          this.showAtivarDesativarProduto = false;
          this.loading = false;
          this.alertService.alert('Produto ativado!', 'success')
        }
        if (Object(res).ativo === 'N') {
          this.showAtivarDesativarProduto = false;
          this.loading = false;
          this.alertService.alert('Produto desativado!', 'success')
        }

      },
        (error) => {
          console.log(error.message)
          console.log('deu ruim')
          this.loading = false;
          this.showAtivarDesativarProduto = false;
          this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')
        });
    } else {
      this.loading = false;
    }
  }

  /**METODO DE TOAST */
  showError(titulo: string, mensagem: string) {
    if (screen.width < 640 || screen.height < 480) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else if (screen.width < 1024 || screen.height < 768) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else {
      this.messageService.add({
        key: 'tr',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    }
  }

  /**METODO QUE ATRIBUI O NOME DO ARQUIVO DA IMAGME DO PRODUTO */
  mudaNomeFoto() {
    const imageBlob = this.fileInput!.nativeElement.files[0];
    this.nomeImagem = imageBlob.name;
    if (this.nomeImagem.length > 24) {
      console.log('oiwf')
      this.nomeImagem = this.nomeImagem.substr(0, 24) + '...';
    }
    console.log(this.nomeImagem.substr(0, 20) + '...')
  }


  /**METODO QUE FAZ O UPLOAD DA IMAGEM DO PRODUTO */
  onFileUpload() {
    this.loading = true;
    const imageBlob = this.fileInput!.nativeElement.files[0];
    this.nomeImagem = imageBlob.name;
    const file = new FormData();
    file.append('imagem_produto', imageBlob);
    file.append('codprod', this.codprod.toString());
    file.append('codusuresp', this.codusuresp);
    console.log(file)
    console.log(this.codprod)
    this.servicoService.uploadImagemProduto(this.codprod.toString(), file).subscribe((res) => {
      this.alertService.alert('Imagem do Produto alterado!', 'success')

      console.log(res)

      this.showFotoProduto = false;
      this.loading = false;
      this.nomeImagem = "Escolha uma foto";
    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
        this.loading = false;
        this.showFotoProduto = false;
        this.alertService.alert('Algo deu errado.', 'error');
        //this.showError('Atenção', 'Algo deu errado.')
      });
  }

}
