import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilitariosService {

  constructor() { }


  somaMinutos(horario: string, minQtd: number) {
    var minutos: any;
    var horas: any;

    minutos = (Number(horario.substr(3, 2))) + (Number(horario.substr(0, 2)) * 60) + minQtd;

    horas = Math.trunc(minutos / 60);
    minutos = ((minutos / 60) - horas) * 60;

    if (horas < 10) {
      horas = "0" + horas;
    }
    if (minutos < 10) {
      minutos = "0" + (minutos === 0 ? "0" : minutos);
    }

    return horas + ":" + minutos;
  }


  validaHora(horaIni: string, horaFin: string) {
    if (horaFin != '') {
      if (this.validaHoraMaior(horaIni, horaFin) || this.validaMin59(horaIni) || this.validaMin59(horaFin)) {
        return true;
      } else {
        return false;
      }
    } else {
      if (this.validaHora23(horaIni) || this.validaMin59(horaIni) || (Number((horaIni).substr(0, 2)) === 0 && Number((horaIni).substr(3, 2)) === 0)) {
        return true;
      } else {
        return false;
      }
    }
  }

  validaMin59(horario: string) { //minutos não pode ser maior que 59
    if (Number((horario).substr(3, 2)) > 59) {
      return true;
    } else {
      return false;
    }
  }

  validaHora23(horario: string) { //horas não pode ser maior que 23
    if (Number((horario).substr(0, 2)) > 23) {
      return true;
    } else {
      return false;
    }
  }


  validaMin15(horario: string) {//so permite minutos de 15 em 15
    if (!(Number((horario).substr(3, 2)) === 0 || Number((horario).substr(3, 2)) === 15 ||
      Number((horario).substr(3, 2)) === 30 || Number((horario).substr(3, 2)) === 45)) {
      return true;
    } else {
      return false;
    }
  }

  validaHoraMaior(horaIni: string, horaFin: string) {//valida para que a segunda hora não seja maior que a primeira
    if (Number((horaIni).substr(0, 2)) > 23 || Number((horaFin).substr(0, 2)) > 23 || Number((horaFin).substr(0, 2)) < Number((horaIni).substr(0, 2)) ||
      ((Number((horaFin).substr(0, 2)) === Number((horaIni).substr(0, 2))) && (Number((horaFin).substr(3, 2)) < Number((horaIni).substr(3, 2))))) {
      return true;
    } else {
      return false;
    }
  }

  validaHoraVazia(horarios: any = []) {
    // console.log(horarios[0])
    // console.log(horarios[1])
    // console.log(horarios[2])
    // console.log(horarios[3])

    if ((horarios[0] === undefined || horarios[0] === '') && (horarios[1] != undefined || horarios[1] != '')) { //hor1 vazio mas hor2 existe
      console.log('a')
      return true;
    }
    if ((horarios[0] != undefined || horarios[0] != '') && (horarios[1] === undefined || horarios[1] === '')) { //hor1 existe e hor2 vazio
      console.log('b')
      return true;
    }
    /**PEDIR AJUDA */
    // if ((horarios[0] != undefined || horarios[0] != '') && (horarios[1] != undefined || horarios[1] != '') &&
    //   (horarios[2] != undefined || horarios[2] != '') && (horarios[3] === undefined || horarios[3] === '')) { //hor3 existe e hor 4 vazio
    //   console.log('c')
    //   return true;
    // }
    if ((horarios[0] != undefined) && (horarios[1] != undefined) &&
      (horarios[2] != undefined) && (horarios[3] === undefined)) { //hor3 existe e hor 4 vazio
      console.log('c')
      return true;
    }

    if ((horarios[0] === undefined || horarios[0] === '') && (horarios[1] === undefined || horarios[1] === '') && //hor1 e hor2 vazio mas hor3 ou hor4 existe
      ((horarios[2] != undefined || horarios[2] != '') || (horarios[3] != undefined || horarios[3] != ''))) {
      console.log('d')
      return true;
    } else {
      return false;
    }


  }

  validaHoraFunc(vetorHorario: any, horarioDia: any) {
    let count = 0;

    /**SEGUNDA */
    if ((horarioDia.seg1 != undefined && horarioDia.seg1 != '') && (horarioDia.seg2 != undefined && horarioDia.seg2 != '')) {//VALIDA HOR1 E HOR2
      if (this.validaMin15(horarioDia.seg1) || this.validaMin15(horarioDia.seg2) || this.validaHoraMaior(horarioDia.seg1, horarioDia.seg2)) {
        return true;
      }
    }
    if ((horarioDia.seg2 != undefined && horarioDia.seg2 != '') && (horarioDia.seg3 != undefined && horarioDia.seg3 != '')) {//VALIDA HOR2 E HOR3
      if (this.validaMin15(horarioDia.seg2) || this.validaMin15(horarioDia.seg3) || this.validaHoraMaior(horarioDia.seg2, horarioDia.seg3)) {
        return true;
      }
    }
    if ((horarioDia.seg3 != undefined && horarioDia.seg3 != '') && (horarioDia.seg4 != undefined && horarioDia.seg4 != '')) {//VALIDA HOR3 E HOR4
      if (this.validaMin15(horarioDia.seg3) || this.validaMin15(horarioDia.seg4) || this.validaHoraMaior(horarioDia.seg3, horarioDia.seg4)) {
        return true
      }
    }
    if ((horarioDia.seg1 != undefined && horarioDia.seg1 != '') || (horarioDia.seg2 != undefined && horarioDia.seg2 != '') ||
      (horarioDia.seg3 != undefined && horarioDia.seg3 != '') || (horarioDia.seg4 != undefined && horarioDia.seg4 != '')) {
      if (this.validaHoraVazia([horarioDia.seg1, horarioDia.seg2, horarioDia.seg3, horarioDia.seg4])) {//VALIDA HORARIOS VAZIOS
        return true;
      }
    }


    /**TERÇA */
    if ((horarioDia.ter1 != undefined && horarioDia.ter1 != '') && (horarioDia.ter2 != undefined && horarioDia.ter2 != '')) {//VALIDA HOR1 E HOR2
      if (this.validaMin15(horarioDia.ter1) || this.validaMin15(horarioDia.ter2) || this.validaHoraMaior(horarioDia.ter1, horarioDia.ter2)) {
        return true;
      }
    }
    if ((horarioDia.ter2 != undefined && horarioDia.ter2 != '') && (horarioDia.ter3 != undefined && horarioDia.ter3 != '')) {//VALIDA HOR2 E HOR3
      if (this.validaMin15(horarioDia.ter2) || this.validaMin15(horarioDia.ter3) || this.validaHoraMaior(horarioDia.ter2, horarioDia.ter3)) {
        return true;
      }
    }
    if ((horarioDia.ter3 != undefined && horarioDia.ter3 != '') && (horarioDia.ter4 != undefined && horarioDia.ter4 != '')) {//VALIDA HOR3 E HOR4
      if (this.validaMin15(horarioDia.ter3) || this.validaMin15(horarioDia.ter4) || this.validaHoraMaior(horarioDia.ter3, horarioDia.ter4)) {
        return true
      }
    }
    if ((horarioDia.ter1 != undefined && horarioDia.ter1 != '') || (horarioDia.ter2 != undefined && horarioDia.ter2 != '') ||
      (horarioDia.ter3 != undefined && horarioDia.ter3 != '') || (horarioDia.ter4 != undefined && horarioDia.ter4 != '')) {
      if (this.validaHoraVazia([horarioDia.ter1, horarioDia.ter2, horarioDia.ter3, horarioDia.ter4])) {//VALIDA HORARIOS VAZIOS
        return true;
      }
    }

    /**QUARTA */
    if ((horarioDia.qua1 != undefined && horarioDia.qua1 != '') && (horarioDia.qua2 != undefined && horarioDia.qua2 != '')) {//VALIDA HOR1 E HOR2
      if (this.validaMin15(horarioDia.qua1) || this.validaMin15(horarioDia.qua2) || this.validaHoraMaior(horarioDia.qua1, horarioDia.qua2)) {
        return true;
      }
    }
    if ((horarioDia.qua2 != undefined && horarioDia.qua2 != '') && (horarioDia.qua3 != undefined && horarioDia.qua3 != '')) {//VALIDA HOR2 E HOR3
      if (this.validaMin15(horarioDia.qua2) || this.validaMin15(horarioDia.qua3) || this.validaHoraMaior(horarioDia.qua2, horarioDia.qua3)) {
        return true;
      }
    }
    if ((horarioDia.qua3 != undefined && horarioDia.qua3 != '') && (horarioDia.qua4 != undefined && horarioDia.qua4 != '')) {//VALIDA HOR3 E HOR4
      if (this.validaMin15(horarioDia.qua3) || this.validaMin15(horarioDia.qua4) || this.validaHoraMaior(horarioDia.qua3, horarioDia.qua4)) {
        return true
      }
    }
    if ((horarioDia.qua1 != undefined && horarioDia.qua1 != '') || (horarioDia.qua2 != undefined && horarioDia.qua2 != '') ||
      (horarioDia.qua3 != undefined && horarioDia.qua3 != '') || (horarioDia.qua4 != undefined && horarioDia.qua4 != '')) {
      if (this.validaHoraVazia([horarioDia.qua1, horarioDia.qua2, horarioDia.qua3, horarioDia.qua4])) {//VALIDA HORARIOS VAZIOS
        return true;
      }
    }

    /**QUINTA */
    if ((horarioDia.qui1 != undefined && horarioDia.qui1 != '') && (horarioDia.qui2 != undefined && horarioDia.qui2 != '')) {//VALIDA HOR1 E HOR2
      if (this.validaMin15(horarioDia.qui1) || this.validaMin15(horarioDia.qui2) || this.validaHoraMaior(horarioDia.qui1, horarioDia.qui2)) {
        return true;
      }
    }
    if ((horarioDia.qui2 != undefined && horarioDia.qui2 != '') && (horarioDia.qui3 != undefined && horarioDia.qui3 != '')) {//VALIDA HOR2 E HOR3
      if (this.validaMin15(horarioDia.qui2) || this.validaMin15(horarioDia.qui3) || this.validaHoraMaior(horarioDia.qui2, horarioDia.qui3)) {
        return true;
      }
    }
    if ((horarioDia.qui3 != undefined && horarioDia.qui3 != '') && (horarioDia.qui4 != undefined && horarioDia.qui4 != '')) {//VALIDA HOR3 E HOR4
      if (this.validaMin15(horarioDia.qui3) || this.validaMin15(horarioDia.qui4) || this.validaHoraMaior(horarioDia.qui3, horarioDia.qui4)) {
        return true
      }
    }
    if ((horarioDia.qui1 != undefined && horarioDia.qui1 != '') || (horarioDia.qui2 != undefined && horarioDia.qui2 != '') ||
      (horarioDia.qui3 != undefined && horarioDia.qui3 != '') || (horarioDia.qui4 != undefined && horarioDia.qui4 != '')) {
      if (this.validaHoraVazia([horarioDia.qui1, horarioDia.qui2, horarioDia.qui3, horarioDia.qui4])) {//VALIDA HORARIOS VAZIOS
        return true;
      }
    }


    /**SEXTA */
    if ((horarioDia.sex1 != undefined && horarioDia.sex1 != '') && (horarioDia.sex2 != undefined && horarioDia.sex2 != '')) {//VALIDA HOR1 E HOR2
      if (this.validaMin15(horarioDia.sex1) || this.validaMin15(horarioDia.sex2) || this.validaHoraMaior(horarioDia.sex1, horarioDia.sex2)) {
        return true;
      }
    }
    if ((horarioDia.sex2 != undefined && horarioDia.sex2 != '') && (horarioDia.sex3 != undefined && horarioDia.sex3 != '')) {//VALIDA HOR2 E HOR3
      if (this.validaMin15(horarioDia.sex2) || this.validaMin15(horarioDia.sex3) || this.validaHoraMaior(horarioDia.sex2, horarioDia.sex3)) {
        return true;
      }
    }
    if ((horarioDia.sex3 != undefined && horarioDia.sex3 != '') && (horarioDia.sex4 != undefined && horarioDia.sex4 != '')) {//VALIDA HOR3 E HOR4
      if (this.validaMin15(horarioDia.sex3) || this.validaMin15(horarioDia.sex4) || this.validaHoraMaior(horarioDia.sex3, horarioDia.sex4)) {
        return true
      }
    }
    if ((horarioDia.sex1 != undefined && horarioDia.sex1 != '') || (horarioDia.sex2 != undefined && horarioDia.sex2 != '') ||
      (horarioDia.sex3 != undefined && horarioDia.sex3 != '') || (horarioDia.sex4 != undefined && horarioDia.sex4 != '')) {
      if (this.validaHoraVazia([horarioDia.sex1, horarioDia.sex2, horarioDia.sex3, horarioDia.sex4])) {//VALIDA HORARIOS VAZIOS
        return true;
      }
    }

    /**SABADO */
    if ((horarioDia.sab1 != undefined && horarioDia.sab1 != '') && (horarioDia.sab2 != undefined && horarioDia.sab2 != '')) {//VALIDA HOR1 E HOR2
      if (this.validaMin15(horarioDia.sab1) || this.validaMin15(horarioDia.sab2) || this.validaHoraMaior(horarioDia.sab1, horarioDia.sab2)) {
        return true;
      }
    }
    if ((horarioDia.sab2 != undefined && horarioDia.sab2 != '') && (horarioDia.sab3 != undefined && horarioDia.sab3 != '')) {//VALIDA HOR2 E HOR3
      if (this.validaMin15(horarioDia.sab2) || this.validaMin15(horarioDia.sab3) || this.validaHoraMaior(horarioDia.sab2, horarioDia.sab3)) {
        return true;
      }
    }
    if ((horarioDia.sab3 != undefined && horarioDia.sab3 != '') && (horarioDia.sab4 != undefined && horarioDia.sab4 != '')) {//VALIDA HOR3 E HOR4
      if (this.validaMin15(horarioDia.sab3) || this.validaMin15(horarioDia.sab4) || this.validaHoraMaior(horarioDia.sab3, horarioDia.sab4)) {
        return true
      }
    }
    if ((horarioDia.sab1 != undefined && horarioDia.sab1 != '') || (horarioDia.sab2 != undefined && horarioDia.sab2 != '') ||
      (horarioDia.sab3 != undefined && horarioDia.sab3 != '') || (horarioDia.sab4 != undefined && horarioDia.sab4 != '')) {
      if (this.validaHoraVazia([horarioDia.sab1, horarioDia.sab2, horarioDia.sab3, horarioDia.sab4])) {//VALIDA HORARIOS VAZIOS
        return true;
      }
    }

    /**DOMINGO */
    if ((horarioDia.dom1 != undefined && horarioDia.dom1 != '') && (horarioDia.dom2 != undefined && horarioDia.dom2 != '')) {//VALIDA HOR1 E HOR2
      if (this.validaMin15(horarioDia.dom1) || this.validaMin15(horarioDia.dom2) || this.validaHoraMaior(horarioDia.dom1, horarioDia.dom2)) {
        return true;
      }
    }
    if ((horarioDia.dom2 != undefined && horarioDia.dom2 != '') && (horarioDia.dom3 != undefined && horarioDia.dom3 != '')) {//VALIDA HOR2 E HOR3
      if (this.validaMin15(horarioDia.dom2) || this.validaMin15(horarioDia.dom3) || this.validaHoraMaior(horarioDia.dom2, horarioDia.dom3)) {
        return true;
      }
    }
    if ((horarioDia.dom3 != undefined && horarioDia.dom3 != '') && (horarioDia.dom4 != undefined && horarioDia.dom4 != '')) {//VALIDA HOR3 E HOR4
      if (this.validaMin15(horarioDia.dom3) || this.validaMin15(horarioDia.dom4) || this.validaHoraMaior(horarioDia.dom3, horarioDia.dom4)) {
        return true
      }
    }
    if ((horarioDia.dom1 != undefined && horarioDia.dom1 != '') || (horarioDia.dom2 != undefined && horarioDia.dom2 != '') ||
      (horarioDia.dom3 != undefined && horarioDia.dom3 != '') || (horarioDia.dom4 != undefined && horarioDia.dom4 != '')) {
      if (this.validaHoraVazia([horarioDia.dom1, horarioDia.dom2, horarioDia.dom3, horarioDia.dom4])) {//VALIDA HORARIOS VAZIOS
        return true;
      }
    }

    for (let i = 0; i < vetorHorario.length; i++) {
      if (vetorHorario[i] === undefined || vetorHorario[i] === '') {//tudo vazio
        count++;
      }
    }
    if (count === vetorHorario.length) {//tudo vazio
      return true;
    } else {
      return false;
    }

    // if (this.validaHoraVazia([horarioDia.seg1, horarioDia.seg2, horarioDia.seg3, horarioDia.seg4])) {//VALIDA HORARIOS VAZIOS
    //   return true;
    // } else {
    //   return false;
    // }



  }



  validaCampo(campo: any = []) {
    let count = 0;
    for (let i = 0; i < campo.length; i++) {
      if (campo[i] === '' || !(campo[i] != '') || campo[i] === "HH:MM" || campo[i] === undefined || campo[i] === 0 || campo[i] == null) {
        count++;
      }
    }
    if (count > 0) {
      return true;
    } else {
      return false;
    }
  }

  validaDataNasc(campo: string) {
    if (Number(campo.substr(0, 2)) === 0 || Number(campo.substr(0, 2)) > 31 || Number(campo.substr(3, 2)) > 12 || (Number(campo.substr(6, 4)) > Number(new Date().getFullYear()) || Number(campo.substr(6, 4)) < 1900) ||
      ((Number(campo.substr(3, 2)) === 4 || Number(campo.substr(3, 2)) === 6 || Number(campo.substr(3, 2)) === 9 ||
        Number(campo.substr(3, 2)) === 11) && Number(campo.substr(0, 2)) > 30) || ((Number(campo.substr(3, 2)) === 2) && (Number(campo.substr(0, 2)) > 28) && ((Number(campo.substr(6, 4))) % 4 != 0))) {
      return true;
    } else {
      return false;
    }
  }

  validaEmail(campo: string) {
    var regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // if (!regex.test(campo)) {
    //   return true;
    // } else {
    //  return false;
    //}
    return regex.test(campo);
  }


  /**VALIDA OS CHECKBOX DE TIPO TELA DE BUSCA FINANCEIRO */
  validaCheckboxTipo(check1: any, check2: any) {
    console.log(check1, check2)

    if (check1.length === 0 && check2.length === 0)
      return null;
    else
      if ((check1.length > 0 && check2 === null) || (check1.length > 0 && check2.length === 0))
        return 'R';
      else
        if ((check1 === null && check2.length > 0) || (check1.length === 0 && check2.length > 0))
          return 'D';
        else
          return null;
  }

  validaCheckboxProvisaoPendente(check1: any, check2: any) {
    console.log(check1, check2)

    if (check1.length === 0 && check2.length === 0)
      return null;
    else
      if ((check1.length > 0 && check2 === null) || (check1.length > 0 && check2.length === 0))
        return 'N';
      else
        if ((check1 === null && check2.length > 0) || (check1.length === 0 && check2.length > 0))
          return 'S';
        else
          return null;
  }


  ajustaDataBanco(data: any) {
    if (data === null) {
      return null
    } else {
      return data.getFullYear() + '-' + ('0' + (data.getMonth() + 1)).slice(-2) + '-' + ('0' + data.getDate()).slice(-2);
    }
  }

  tornaNull(campo: any) {
    if (campo === '') {
      return null;
    } else {
      return campo
    }
  }
}
