import { UtilitariosService } from './../assets/utilitarios.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { AlertService } from 'src/alert.service';
import { Estabelecimento } from 'src/assets/estabelecimento';
import { Profissional } from 'src/assets/profissional';
import { Cliente } from 'src/assets/cliente';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { EstabelecimentoService } from 'src/estabelecimento.service';
import { ProfissionalService } from 'src/profissional.service';
import { FinanceiroService } from 'src/financeiro.service';
import { ParceirosService } from 'src/parceiros.service';
import { PermissoesService } from 'src/permissoes.service';
import { TraducaoService } from 'src/traducao.service';
import { environment } from 'src/environments/environment';
import { AgendaService } from 'src/agenda.service';

declare var cordova: any;

@Component({
  selector: 'app-financeiro',
  templateUrl: './financeiro.component.html',
  styleUrls: ['./financeiro.component.scss'],
  providers: [MessageService, ConfirmationService],
  encapsulation: ViewEncapsulation.None
})
export class FinanceiroComponent implements OnInit {
  showLancamento: boolean = false;
  showDesmembraFinanceiro: boolean = false;
  showBaixa: boolean = false;
  showDesmembramento: boolean = false;

  expandeFiltros: boolean = false;
  desabilitaCamposLancamento: boolean = false;
  desabilitaCamposAGDVND: boolean = false;

  /**VARIAVEIS DO BOTAO DE LOADING */
  teveRespostaCliente: boolean = false;
  teveRespostaEstabelecimento: boolean = false;
  loading: boolean = false;
  mostraCarregandoCopia: boolean = false;
  mostraCarregandoBusca: boolean = false;

  /**DATA */
  hoje: Date;
  ontem: Date;

  /**vencimento */
  dataInicialVenc: any;
  dataFinalVenc: any;
  /**lancamento */
  dataInicialLanc: any;
  dataFinalLanc: any;
  /**baixa */
  dataInicialBaixa: any;
  dataFinalBaixa: any;
  dataParaBaixa: any;

  /**data popup */
  dataBaixa: any = null;
  dataVenc: any;
  dataLanc: any;

  //lancamento: Lancamento[] = [];
  // lancamentoSelecionadoExcluir: Lancamento[] = [];
  // selecionouTodosLancamentosExcluir: Lancamento[] = [];
  // lancamentoSelecionadoEditar: Lancamento = {};

  lancamento: any;
  lancamentoSelecionado: any;
  lancamentoSelecionadoEditar: any;
  selecionouTodosLancamentosExcluir: any;

  /**CODIGOS */
  codusuresp: string = '';
  codcli: number = 0;
  codemp: number = 0;
  nufin: any;
  nuid: number = 0;



  /**CHECKBOX DE BUSCA */
  receitas: any = [];
  real: any = [];
  pendentes: any = [];
  despesas: any = [];
  provisao: any = [];
  baixados: any = [];


  /**VARIAVEIS PARA A BUSCA */
  estabelecimentoSelecionadaBusca: any = null;
  parceiroSelecionadoBusca: any = null;
  profissionalSelecionadoBusca: any = null;
  categoriaSelecionadaBusca: any = null;
  origemSelecionadaBusca: any = null;
  contaSelecionadaBusca: any = null;
  detalhesBusca: any = null;
  negociacaoBusca: any;
  negociacaoBuscaSelec: any;

  /**COPIA */
  botaoCopia: boolean = false;

  /**VARIAVEIS DA LISTAGEM DE BARBEARIAS */
  estabelecimento: Estabelecimento[] = [];
  estabelecimentoSelecionada: any;

  /**POPUP */
  tipoLancamento: any;
  conta: any;
  categoria: any;
  tipoNegociacao: any;
  tipoNegociacaoSelecionada: any = null;
  origem: any;
  tipoSelecionado: any;
  categoriaSelecionada: any;
  contaSelecionada: any;
  realProvi: any;
  realProviSelecionado: any;
  origemLancamento: string = '';

  /**VARIAVEIS DA LISTAGEM DE PROFISSIONAIS */
  profissional: Profissional[] = [];
  profissionalSelecionado: any;

  /**VARIAVEIS DA LISTAGEM DE CLIENTES */
  clientes: Cliente[] = [];
  clienteSelecionado: any;

  vlrLancamento: any;
  vlrBaixa: any = null;
  detalhesLancamento: string = '';
  checked: boolean = false;

  /**FINANCEIRO */
  vlrParaBaixa: any;
  vlrJaBaixado: number = 0;
  vlrTotalBaixa: number = 0;

  /**TOTAIS */
  totalReceitas: number = 0;
  totalDespesas: number = 0;
  totalBaixado: number = 0;
  totalABaixar: number = 0;
  totalBaixadoReceita: number = 0;
  totalBaixadoDespesa: number = 0;
  totalABaixarReceita: number = 0;
  totalABaixarDespesa: number = 0;
  vlrRestanteDesmembramento: number = 0;

  /**VARIAVEIS DE PERMISSÕES */
  permissao: any;
  alterar: boolean = false;
  excluir: boolean = false;
  cadastrar: boolean = false;
  baixa: boolean = false;
  estornar: boolean = false;

  itemsRelatorio: MenuItem[] = [];

  constructor(private estabelecimentoService: EstabelecimentoService, private decodedTokenService: DecodedTokenService, public translate: TranslateService, private profissionalService: ProfissionalService,
    private traducaoService: TraducaoService, private parceirosService: ParceirosService, private financeiroService: FinanceiroService, private messageService: MessageService,
    private alertService: AlertService, private utilitariosService: UtilitariosService, private permissoesService: PermissoesService, private confirmationService: ConfirmationService,
    private agendaService: AgendaService) {
    this.hoje = new Date();
    this.ontem = new Date(this.hoje);
    this.ontem.setDate(this.hoje.getDate() - 1);

    this.dataInicialVenc = this.hoje;
    this.dataFinalVenc = this.hoje;

    /**TRADUÇÃO */
    this.traducaoService.changeLang();


    this.tipoLancamento = [
      { 'tipo': 'R', 'nome': 'Receita' },
      { 'tipo': 'D', 'nome': 'Despesa' }
    ]


    // this.realProvi = [
    //   { 'tipo': 'R', 'nome': 'Real' },
    //   { 'tipo': 'P', 'nome': 'Provisao' }
    // ]


  }

  ngOnInit(): void {
    this.recuperaCodUsu();
    this.recuperaCodCli();
    this.recuperaPermissao();
    /**BUSCA AS OPÇÕES DO DROPDOWN */
    this.buscarEstabelecimentosPorCodCliente();
    this.buscaCategoriaPorCodcli();
    this.buscaContaPorCodcli();
    this.buscaTipoNegociacaoPorCodcli();
    this.buscaOrigem();
    this.recuperaClientes();
    this.recuperaNuid();
    //this.buscaCategoriasPorCodCliente();
    //this.buscaContasPorCodCliente();


    this.itemsRelatorio = [{
      items: [{
        label: 'Baixar Excel',
        command: () => {
          this.geraRelatorioExcel();
        },
      },
        // {
        //   label: 'Baixar PDF',
        //   styleClass: 'linhaMenu',
        //   command: () => {
        //     this.geraRelatorioPDFDesempenho();
        //   },
        // }
      ]
    }];
  }


  /**METODO DE TOAST */
  showError(titulo: string, mensagem: string) {
    if (screen.width < 640 || screen.height < 480) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else if (screen.width < 1024 || screen.height < 768) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else {
      this.messageService.add({
        key: 'tr',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    }
  }
  /**RECUPERA O CODUSU PARA FAZER BUSCAS */
  recuperaCodUsu() {
    this.codusuresp = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }

  /**RECUPERA O CODUSU PARA FAZER BUSCAS */
  recuperaCodCli() {
    this.codcli = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODCLI;
  }

  /**RECUPERA AS BARBEARIAS */
  buscarEstabelecimentosPorCodCliente() {
    this.estabelecimentoService.buscarEstabelecimentosporCodCli(this.codcli, this.codusuresp).subscribe((res) => {
      this.estabelecimento = res;
    },
      (error) => {
        console.log(error.message)
        console.log('deu ruim')
      })
  }

  /**RECUPERA AS ORIGEMS PARAO  DROPDOWN */
  buscaOrigem() {
    this.financeiroService.getOpcOrigem().subscribe((res) => {
      console.log(res);
      this.origem = res;
    })
  }


  /**RECUPERA AS CATEGORIAS DO CLIENTE PARA O DROPDOWN */
  buscaCategoriasPorCodCliente() {
    this.financeiroService.buscaCategoriaPorCodCli(this.codcli, this.codusuresp).subscribe((res) => {
      this.categoria = res;
    })
  }

  /**RECUPERA AS CONTAS DO CLIENTE PARA O DROPDOWN */
  buscaContasPorCodCliente() {
    this.financeiroService.buscaContaPorCodCli(this.codcli, this.codusuresp).subscribe((res) => {
      this.conta = res;
    })
  }

  /**METODO QUE RECUPERA A LISTA DE BARBEIROS E OS COLOCA NO DROPDOWN*/
  buscarProfissionals(codemp: number) {
    this.teveRespostaEstabelecimento = true;

    setTimeout(() => {
      this.profissionalService.buscarProfissionalporCodEmp(codemp, this.codusuresp).subscribe((res) => {
        this.teveRespostaEstabelecimento = false;
        this.profissional = res;
      },
        (error) => {
          this.teveRespostaEstabelecimento = false;
          console.log(error.message)
          console.log('deu ruim')

        })
    }, 500);
  }

  /**METODO QUE RECUPERA A LISTA DE CLIENTES DA BARBEARIA SELECIONADA E OS COLOCA NO DROPDOWN*/
  recuperaClientes() {
    this.teveRespostaCliente = true;
    setTimeout(() => {
      console.log(this.estabelecimentoSelecionada)
      this.parceirosService.buscaClientesGeralPorCodFunc('0', this.codcli, this.codusuresp).subscribe((res) => {
        this.teveRespostaCliente = false;
        this.clientes = res;
        console.log(res)
      },
        (error) => {
          console.log(error.message)
          console.log('deu ruim')
          this.teveRespostaCliente = false;
          //this.showError('Atenção', 'Algo deu errado.')
        })
    }, 500);
  }


  /**METODO PARA MOSTRAR O DIALOG DE CADASTRO DE LANÇAMENTOS */
  mostraDialogCadLancamento() {
    if ((this.cadastrar && this.permissao != undefined && this.permissao.length > 0) || (this.codcli > 0 && (this.permissao === undefined || this.permissao.length === 0))) {
      this.nufin = null;

      this.tipoSelecionado = null;   /**AJUSTA A DATA PARA O INPUT DO PRIMENG */
      this.dataVenc = null;

      this.contaSelecionada = null;
      this.categoriaSelecionada = null;
      this.estabelecimentoSelecionada = null;

      this.buscarProfissionals(this.estabelecimentoSelecionada);
      this.recuperaClientes();
      this.dataLanc = this.hoje;
      this.profissionalSelecionado = null;
      this.clienteSelecionado = null;
      this.detalhesLancamento = '';
      this.vlrLancamento = null;
      this.vlrBaixa = null;
      this.dataBaixa = null;
      this.tipoNegociacaoSelecionada = null;

      /**FILTRA OS TIPOS DE NEGOCIAÇÃO E ATRIBUI AO CAMPO A QUE ESTÁ COMO PADRÃO */
      this.tipoNegociacaoSelecionada = this.tipoNegociacao.filter((x: any) => x.negpadrao === 'S')[0].codtipneg;


      this.desabilitaCamposLancamento = false;
      this.desabilitaCamposAGDVND = false;
      this.showLancamento = true;
    } else {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
  }

  /**METODO PARA MOSTRAR O DIALOG DE EDIÇÃO DE LANÇAMENTOS */
  mostraDialogEditLancamento() {
    this.showLancamento = true;
  }

  mostraFiltros() {
    this.expandeFiltros = true;
    console.log(this.expandeFiltros)
  }

  guardaFiltros() {
    this.expandeFiltros = false;
  }


  /**MOSTRA O DIALOG PARA FAZER A BAIXA DO LANÇAMENTO */
  mostraDialogBaixa() {
    if (this.lancamentoSelecionado[0].vlrlanc === this.lancamentoSelecionado[0].vlrbaixa) {
      this.showError('Atenção', 'Este lançamento já foi totalmente baixado.')
      return
    }
    if ((this.baixa && this.permissao != undefined && this.permissao.length > 0) || (this.codcli > 0 && (this.permissao === undefined || this.permissao.length === 0))) {
      this.vlrParaBaixa = null;
      this.dataParaBaixa = null;
      if (this.lancamentoSelecionado.length > 0) {
        this.showBaixa = true;
        if (this.lancamentoSelecionado[0].vlrbaixa === null) {
          this.vlrJaBaixado = 0;
        } else {
          this.vlrJaBaixado = this.lancamentoSelecionado[0].vlrbaixa;
        }
      }
    } else {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
  }

  /**METODO QUE CRIA/EDITA OS LANÇAMENTOS FINANCEIROS */
  criaFinanceiro() {
    this.loading = true;
    let auxDtBaixa = null;

    if (this.lancamentoSelecionadoEditar) {
      if (this.lancamentoSelecionadoEditar.vlrbaixa > 0 && this.lancamentoSelecionadoEditar.origem != 'AGD') {
        this.loading = false;
        this.showError('Atenção', 'Não é permitido editar um lançamento já baixado.')
        return
      }
    }

    /**VALIDA SE OS VALORES DE BAIXA E LANÇAMENTOS SÃO MAIORES QUE 0 */
    // if (this.vlrBaixa <= 0 && this.utilitariosService.validaCampo([this.dataBaixa])) {
    //   this.loading = false;
    //   //this.showBaixa = false;
    //   this.vlrBaixa = null;
    //   this.showError('Atenção', 'O valor de baixa não pode ser zero.')
    //   return;
    // }

    if (this.vlrLancamento <= 0) {
      this.loading = false;
      //this.showBaixa = false;
      this.vlrBaixa = null;
      this.showError('Atenção', 'O valor de lançamento não pode ser zero.')
      return;
    }

    /**VALIDA OS CAMPOS */
    if (this.utilitariosService.validaCampo([this.tipoSelecionado, this.vlrLancamento, this.dataVenc, this.clienteSelecionado])) {
      this.loading = false;
      this.showError('Atenção', 'É necessário preencher todos os campos obrigatórios do Lançamento.')
      return;
    }


    if ((this.utilitariosService.validaCampo([this.vlrBaixa]) && !this.utilitariosService.validaCampo([this.dataBaixa])) ||
      (!this.utilitariosService.validaCampo([this.vlrBaixa]) && this.utilitariosService.validaCampo([this.dataBaixa]))) {
      this.loading = false;
      this.showError('Atenção', 'É necessário preencher todos os campos de baixa.')
      return;
    }




    // /**VALIDA OS CAMPOS DE BAIXA PARA QUE SEJA PREENCHIDO O VALOR E A DATA*/
    // if ((this.utilitariosService.validaCampo([this.vlrBaixa]) && !this.utilitariosService.validaCampo([this.dataBaixa])) ||
    //   (!this.utilitariosService.validaCampo([this.vlrBaixa]) && this.utilitariosService.validaCampo([this.dataBaixa]))) {

    //   this.loading = false;
    //   this.showError('Atenção', 'É necessário preencher todos os campos do Lançamento.')
    //   return;
    // }

    console.log(this.baixa, this.vlrBaixa, this.dataBaixa)

    if ((!this.baixa && this.permissao != undefined && this.permissao.length > 0)) {
      this.loading = false;
      this.showError('Atenção', 'Não é permitido lançar valor de baixa.')
      return
    }

    // if (!this.baixa && (this.vlrBaixa != null || this.dataBaixa != null)) {
    //   this.loading = false;
    //   this.showError('Atenção', 'Não é permitido lançar valor de baixa.')
    //   return
    // }

    if (this.vlrBaixa > this.vlrLancamento) {
      this.loading = false;
      this.vlrBaixa = null;
      this.showError('Atenção', 'O valor de baixa não pode ser maior que o valor de lançamento.')
      return
    }

    // /**DEIXA AS DATAS NO PADRÃO QUE O BANCO ESPERA: YYYY-MM-DD */
    let auxDtVenc = this.dataVenc.getFullYear() + '-' + ('0' + (this.dataVenc.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataVenc.getDate()).slice(-2);
    console.log(auxDtVenc)
    if (this.dataBaixa) {
      auxDtBaixa = this.dataBaixa.getFullYear() + '-' + ('0' + (this.dataBaixa.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataBaixa.getDate()).slice(-2);
      console.log(auxDtBaixa)
    } else {
      auxDtBaixa = null;
    }


    if (this.nufin > 0) {
      this.financeiroService.preencheFinanceiro(this.nufin, this.tipoSelecionado, 'FIN', this.contaSelecionada, this.categoriaSelecionada, this.vlrLancamento, auxDtVenc,
        this.clienteSelecionado, this.detalhesLancamento, this.codusuresp, this.codcli, this.estabelecimentoSelecionada, this.profissionalSelecionado, auxDtBaixa, this.codusuresp, this.vlrBaixa, this.tipoNegociacaoSelecionada).subscribe((res) => {
          this.alertService.alert('Lançamento alterado.', 'success');
          this.loading = false;
          this.showLancamento = false;

          /**APOS FAZER A REQUISIÇÃO BUSCA O LANÇAMENTO REFERENTE E PLOTA NA TABELA */
          this.buscaLancamentoPorNufin(res.nufin);

          console.log(res)
        },
          (error) => {
            console.log('deu ruim')
            this.loading = false;
            this.showLancamento = false;
            this.alertService.alert('Algo deu errado.', 'error');
          })
    } else {
      console.log(this.dataBaixa)
      console.log(this.codusuresp)
      console.log(this.vlrBaixa)
      this.financeiroService.preencheFinanceiro(null, this.tipoSelecionado, 'FIN', this.contaSelecionada, this.categoriaSelecionada, this.vlrLancamento, auxDtVenc,
        this.clienteSelecionado, this.detalhesLancamento, this.codusuresp, this.codcli, this.estabelecimentoSelecionada, this.profissionalSelecionado, auxDtBaixa, this.codusuresp, this.vlrBaixa, this.tipoNegociacaoSelecionada).subscribe((res) => {
          this.alertService.alert('Lançamento criado.', 'success');
          this.loading = false;
          this.showLancamento = false;
          //this.filtraFinanceiro();

          /**APOS FAZER A REQUISIÇÃO BUSCA O LANÇAMENTO REFERENTE E PLOTA NA TABELA */
          this.buscaLancamentoPorNufin(res.nufin);

          console.log(res)
        },
          (error) => {
            console.log('deu ruim')
            this.loading = false;
            this.showLancamento = false;
            this.alertService.alert('Algo deu errado.', 'error');
          })
    }
  }


  /**METODO QUE BUSCA O LANÇAMENTO POR NUFIN PRIMARIO, APOS SER FEITA UMA REQUISIÇÃO */
  buscaLancamentoPorNufin(nufin: number) {
    this.financeiroService.getMovFinanceiroByNufinPrimario(this.codcli, nufin, this.codusuresp).subscribe((res) => {
      this.lancamento = res;
      this.calculaTotais();
    },
      (error) => {
        console.log('deu ruim')
        this.loading = false;
        this.showLancamento = false;
        this.alertService.alert('Algo deu errado.', 'error');
      })
  }

  /**METODO QUE BUSCA O LANÇAMENTO POR NUFIN PRIMARIO, APOS SER FEITA UMA REQUISIÇÃO */
  buscaLancamentoPorNuid(nuid: number) {
    this.financeiroService.getMovFinanceiroByNuid(this.codcli, nuid, this.codusuresp).subscribe((res) => {
      this.lancamento = res;
      this.calculaTotais();
    },
      (error) => {
        console.log('deu ruim')
        this.loading = false;
        this.showLancamento = false;
        this.alertService.alert('Algo deu errado.', 'error');
      })
  }


  /**METODO QUE ESTORNA O LANÇAMENTOS FINANCEIRO */
  estornaFinanceiro() {
    this.loading = true;
    this.financeiroService.estornarFinanceiro(this.lancamentoSelecionado[0].nufin, this.codusuresp).subscribe((res) => {
      this.alertService.alert('Lançamento estornado', 'success');
      this.loading = false;

      /**APOS FAZER A REQUISIÇÃO BUSCA O LANÇAMENTO REFERENTE E PLOTA NA TABELA */
      this.buscaLancamentoPorNufin(res.nufin);

      //  this.showLancamento = false;
      console.log(res)
    },
      (error) => {
        console.log('deu ruim')
        this.loading = false;
        this.showLancamento = false;
        this.alertService.alert('Algo deu errado.', 'error');
      })
  }


  confirmaEstornaFinanceiro() {



    if ((this.estornar && this.permissao != undefined && this.permissao.length > 0) || (this.codcli > 0 && (this.permissao === undefined || this.permissao.length === 0))) {
      if (this.lancamentoSelecionado.length > 0) {

        /**VALIDA SE O LANÇAMENTO TEM UM VALOR DE BAIXA */
        if (this.lancamentoSelecionado[0].vlrbaixa <= 0) {
          this.loading = false;
          this.showError('Atenção', 'Não é permitido estornar um lançamento sem valor de baixa.')
          return
        }

        this.confirmationService.confirm({
          message: 'Deseja mesmo estornar o lançamento no valor de ' + this.lancamentoSelecionado[0].vlrbaixa.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', }) + ' ?',
          header: 'Estornar Financeiro',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.estornaFinanceiro();
          },
          reject: () => {
          }
        });
      }
    } else {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }
  }





  /**METODO QUE DA BAIXA NO LANÇAMENTOS FINANCEIRO */
  baixaFinanceiro() {
    this.loading = true;

    if (this.utilitariosService.validaCampo([this.vlrParaBaixa, this.dataParaBaixa])) {
      this.loading = false;
      this.showError('Atenção', 'É necessário preencher todos os campos da Baixa.')
      return;
    }

    this.vlrTotalBaixa = this.vlrJaBaixado + this.vlrParaBaixa;

    if (this.lancamentoSelecionado[0].vlrlanc < this.vlrParaBaixa || this.vlrTotalBaixa > this.lancamentoSelecionado[0].vlrlanc) {
      this.loading = false;
      this.showBaixa = false;
      this.vlrParaBaixa = null;
      this.dataParaBaixa = null;
      this.alertService.alert('O valor de baixa não pode ser maior que o valor de lançamento.', 'error');
      return;
    }



    this.financeiroService.baixarFinanceiro(this.lancamentoSelecionado[0].nufin, this.vlrTotalBaixa, this.ajustaData(this.dataParaBaixa), this.codusuresp, this.codusuresp).subscribe((res) => {
      // this.alertService.alert('Baixa no lançamento feita.', 'success');
      console.log(res)
      this.calculaTotais();
      this.showBaixa = false;
      this.loading = false;
      console.log(res);

      /**APOS FAZER A REQUISIÇÃO BUSCA O LANÇAMENTO REFERENTE E PLOTA NA TABELA */
      this.buscaLancamentoPorNufin(res.nufin);





    },
      (error) => {
        if (error.error.message != undefined) {
          console.log(error.error.message)
          const erro = error.error.message
          const testeRegex = erro.match(/(?:[^,]*, ){2}([^,]*)/)
          console.log(testeRegex)
          this.showBaixa = false;
          this.alertService.alert(testeRegex[1], 'error')
        } else {
          this.showBaixa = false;
          this.loading = false;
          this.alertService.alert('Algo deu errado.', 'error')
        }

      })
  }


  /**VALIDA SE O VALOR DA BAIXA É FULL OU SE TEM A POSSIBILIDADE DE FAZER UM DESMEMBRAMENTO */
  verificaAcaoBaixa() {

    /**SOMA O VALOR DA BAIXA JÁ FEITA COM O QUE O USUARIO DIGITOU NO CAMPO */
    this.vlrTotalBaixa = this.vlrJaBaixado + this.vlrParaBaixa;

    if (this.lancamentoSelecionado[0].vlrlanc < this.vlrParaBaixa || this.vlrTotalBaixa > this.lancamentoSelecionado[0].vlrlanc) {
      this.loading = false;
      //this.showBaixa = false;
      this.vlrParaBaixa = null;
      this.showError('Atenção', 'O valor de baixa não pode ser maior que o valor de lançamento.')
      return;
    }

    /**VALIDA SE O VALOR DE BAIXA É MAIOR QUE 0 */

    if (this.vlrParaBaixa <= 0) {
      this.loading = false;
      //this.showBaixa = false;
      this.vlrParaBaixa = null;
      this.showError('Atenção', 'O valor de baixa não pode ser zero.')
      return;
    }

    /**VALIDA OS CAMPOS DE BAIXA PARA QUE SEJA PREENCHIDO O VALOR E A DATA*/
    if ((this.utilitariosService.validaCampo([this.vlrParaBaixa]) && !this.utilitariosService.validaCampo([this.dataParaBaixa])) ||
      (!this.utilitariosService.validaCampo([this.vlrParaBaixa]) && this.utilitariosService.validaCampo([this.dataParaBaixa]))) {

      this.loading = false;
      this.showError('Atenção', 'É necessário preencher todos os campos da Baixa.')
      return;
    }

    /**SE O VALOR DA TOTAL DA BAIXA FOR IGUAL O VALOR DO LANÇAMENTO, ENTÃO FAZ A BAIXA DIRETO, SEM PASSAR PELO DESMEMBRAMENTO */
    if (this.vlrTotalBaixa < this.lancamentoSelecionado[0].vlrlanc) {
      this.confirmaDesmembramentoFinanceiro();
    } else {
      this.baixaFinanceiro();
      this.alertService.alert('Baixa no lançamento feita.', 'success');
    }
  }

  /**METODO DE DESMEMBRAMENTO DO LANÇAMENTO */
  confirmaDesmembramentoFinanceiro() {
    // if ((this.estornar && this.permissao != undefined && this.permissao.length > 0) || (this.codcli > 0 && (this.permissao === undefined || this.permissao.length === 0))) {
    // if (this.lancamentoSelecionado.length > 0) {
    this.vlrRestanteDesmembramento = this.lancamentoSelecionado[0].vlrlanc - this.vlrTotalBaixa;
    this.confirmationService.confirm({
      message: 'Deseja desmembrar esse lançamento no valor de ' + this.vlrRestanteDesmembramento.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', }) +
        ' ? (' + this.lancamentoSelecionado[0].vlrlanc.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', }) + ' - ' + this.vlrTotalBaixa.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', }) + ')',
      // ' ? ( Vlr. Lançamento - Vlr. Total Baixado)',
      header: 'Desmembrar lançamento financeiro',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.mostraDialogDesmembraFinanceiro();
      },
      reject: () => {
        this.baixaFinanceiro();
        this.alertService.alert('Baixa no lançamento feita.', 'success');
      }
    });
    // }
    // } else {
    //   this.showError('Atenção', 'Ação não permitida.')
    //   return
    // }
  }


  /**MOSTRA O DIALOG REFERENTE AO DESMEMBRAMENTO FINANCEIRO E ATRIBUI NOS CAMPOS OS VALORES DO LANÇAMENTO A SER DESMEMBRADO*/
  mostraDialogDesmembraFinanceiro() {
    this.baixaFinanceiro();

    this.showDesmembraFinanceiro = true;

    this.dataLanc = this.lancamentoSelecionado[0].dtlanc;

    this.nufin = this.lancamentoSelecionado[0].nufin;
    this.tipoSelecionado = this.lancamentoSelecionado[0].tipo;

    /**AJUSTA A DATA PARA O INPUT DO PRIMENG */
    this.dataVenc = new Date(this.lancamentoSelecionado[0].dtvenc + 'GMT-0300');


    this.contaSelecionada = this.lancamentoSelecionado[0].codconta;
    this.categoriaSelecionada = this.lancamentoSelecionado[0].codcat;
    this.estabelecimentoSelecionada = this.lancamentoSelecionado[0].codemp;

    this.buscarProfissionals(this.estabelecimentoSelecionada);
    this.recuperaClientes();

    this.profissionalSelecionado = this.lancamentoSelecionado[0].codfunc;
    this.clienteSelecionado = this.lancamentoSelecionado[0].codparc;
    this.detalhesLancamento = this.lancamentoSelecionado[0].detalhe;
    this.vlrLancamento = this.vlrRestanteDesmembramento;
    this.tipoNegociacaoSelecionada = this.lancamentoSelecionado[0].codtipneg;
    this.vlrBaixa = null;
    this.dataBaixa = null;

  }



  /**METODO PARA DA REQUISIÇAO PARA O DESMEMBRAMENTO FINANCEIRO */
  desmembraFinanceiro() {

    /**VALIDA SE OS VALORES DE BAIXA É MAIOR QUE 0 */
    // if (this.vlrBaixa <= 0 && this.utilitariosService.validaCampo([this.dataBaixa])) {
    //   this.loading = false;
    //   //this.showBaixa = false;
    //   this.vlrBaixa = null;
    //   this.showError('Atenção', 'O valor de baixa não pode ser zero.')
    //   return;
    // }


    /**VALIDA OS CAMPOS DE BAIXA PARA QUE SEJA PREENCHIDO O VALOR E A DATA*/
    if ((this.utilitariosService.validaCampo([this.vlrBaixa]) && !this.utilitariosService.validaCampo([this.dataBaixa])) ||
      (!this.utilitariosService.validaCampo([this.vlrBaixa]) && this.utilitariosService.validaCampo([this.dataBaixa]))) {

      this.loading = false;
      this.showError('Atenção', 'É necessário preencher todos os campos do Lançamento.')
      return;
    }

    /**VALIDAÇÃO DOS CAMPOS DE BAIXA */
    if (this.vlrBaixa > this.vlrLancamento) {
      this.showError('Atenção', 'O valor de baixa não pode ser maior que o valor de lançamento.')
      return;
    }

    // if (this.vlrBaixa <= 0) {
    //   this.loading = false;
    //   //this.showBaixa = false;
    //   this.vlrBaixa = null;
    //   this.showError('Atenção', 'O valor de lançamento não pode ser zero.')
    //   return;
    // }


    let auxDtBaixa = null;
    if (this.dataBaixa) {
      auxDtBaixa = this.dataBaixa.getFullYear() + '-' + ('0' + (this.dataBaixa.getMonth() + 1)).slice(-2) + '-' + ('0' + this.dataBaixa.getDate()).slice(-2);
    }
    console.log(auxDtBaixa)

    console.log(this.vlrLancamento)

    this.financeiroService.desmembraFinanceiro(this.nufin, this.tipoSelecionado, this.lancamentoSelecionado[0].origem, this.contaSelecionada, this.categoriaSelecionada, this.vlrLancamento, this.atribuiHoje(this.dataVenc),
      this.clienteSelecionado, this.detalhesLancamento, this.codusuresp, this.codcli, this.estabelecimentoSelecionada, this.profissionalSelecionado, auxDtBaixa, this.codusuresp,
      this.vlrBaixa, this.tipoNegociacaoSelecionada).subscribe((res) => {
        this.showDesmembraFinanceiro = false;
        // this.alertService.alert('Lançamento desmembrado no valor de ' + this.vlrRestanteDesmembramento.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', }) + '!', 'success');
        this.loading = false;

        this.nufin = res.nufin;
        /**APOS FAZER A REQUISIÇÃO BUSCA O LANÇAMENTO REFERENTE E PLOTA NA TABELA */
        this.buscaLancamentoPorNufin(res.nufin);
        this.confirmaDesmembramentoFinanceiroLoop();
        console.log(res)
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.loading = false;
          this.showDesmembraFinanceiro = false;
          this.alertService.alert('Algo deu errado.', 'error');
        })
  }


  /**METODOS PARA MOSTRAR O POPUP DO LOOP DO DESMEMBRAMENTO */
  mostraDialogDesmembraFinanceiroLoop() {
    this.showDesmembraFinanceiro = true;

    this.dataLanc = this.lancamentoSelecionado[0].dtlanc;

    this.tipoSelecionado = this.lancamentoSelecionado[0].tipo;

    /**AJUSTA A DATA PARA O INPUT DO PRIMENG */
    this.dataVenc = new Date(this.lancamentoSelecionado[0].dtvenc + 'GMT-0300');


    this.contaSelecionada = this.lancamentoSelecionado[0].codconta;
    this.categoriaSelecionada = this.lancamentoSelecionado[0].codcat;
    this.estabelecimentoSelecionada = this.lancamentoSelecionado[0].codemp;

    this.buscarProfissionals(this.estabelecimentoSelecionada);
    this.recuperaClientes();

    this.profissionalSelecionado = this.lancamentoSelecionado[0].codfunc;
    this.clienteSelecionado = this.lancamentoSelecionado[0].codparc;
    this.detalhesLancamento = this.lancamentoSelecionado[0].detalhe;
    this.vlrLancamento = this.vlrRestanteDesmembramento;
    this.tipoNegociacaoSelecionada = this.lancamentoSelecionado[0].codtipneg;
    this.vlrBaixa = null;
    this.dataBaixa = null;

  }

  /**METODO DE CONFIRMAÇÃO LOOP DESMEMBRAMENTO DO LANÇAMENTO */
  confirmaDesmembramentoFinanceiroLoop() {

    this.vlrRestanteDesmembramento = this.vlrLancamento - this.vlrBaixa;

    if (this.vlrRestanteDesmembramento === 0) {
      this.alertService.alert('Lançamento totalmente baixado!', 'success');
      return;
    }

    this.confirmationService.confirm({
      message: 'Deseja desmembrar esse lançamento no valor de ' + this.vlrRestanteDesmembramento.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', }) +
        ' ? (' + this.vlrLancamento.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', }) + ' - ' + this.vlrBaixa.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', }) + ')',
      // ' ? ( Vlr. Lançamento - Vlr. Total Baixado)',
      header: 'Desmembrar lançamento financeiro',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.mostraDialogDesmembraFinanceiroLoop();
      },
      reject: () => {
        this.alertService.alert('Lançamento desmembrado no valor de ' + this.vlrRestanteDesmembramento.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', }) + '!', 'success');
      }
    });
  }

  /**METODOS PARA BUSCAR AS CATEGORIAS DO CLIENTE E MOSTRA-LAS NO DROPDOWN */
  buscaCategoriaPorCodcli() {
    this.categoria = [];
    this.financeiroService.buscaCategoriaPorCodCli(this.codcli, this.codusuresp).subscribe((res) => {
      this.categoria = res;
    },
      (error) => {
        console.log(error.message)
        console.log('deu ruim')
      })
  }



  /**METODOS PARA BUSCAR AS CONTAS DO CLIENTE E MOSTRA-LAS NO DROPDOWN */
  buscaContaPorCodcli() {
    this.conta = [];
    this.financeiroService.buscaContaPorCodCli(this.codcli, this.codusuresp).subscribe((res) => {
      this.conta = res;
    },
      (error) => {
        console.log(error.message)
        console.log('deu ruim')
      })
  }


  /**METODOS PARA BUSCAR OS TIPOS DE NEGOCIAÇÃO E MOSTRA-LAS NO DROPDOWN */
  buscaTipoNegociacaoPorCodcli() {
    this.tipoNegociacao = [];
    this.financeiroService.buscaTipoNegociacaoPorCodCli(this.codcli, this.codusuresp).subscribe((res) => {
      this.tipoNegociacao = res;
      this.negociacaoBusca = res;
    },
      (error) => {
        console.log(error.message)
        console.log('deu ruim')
      })
  }


  /**METODO DE EXCLUIR FINANCEIRO */
  excluiFinanceiroPorNufin() {
    this.financeiroService.excluiFinanceiro(this.lancamentoSelecionado[0].nufin, this.codusuresp).subscribe((res) => {
      console.log(res);
      this.buscaLancamentoPorNufin(res.nufin);
      this.alertService.alert('Lançamento excluído', 'success');
    },
      (error) => {
        if (error.error.message != undefined) {
          console.log(error.error.message)
          const erro = error.error.message
          const testeRegex = erro.match(/(?:[^,]*, ){2}([^,]*)/)
          console.log(testeRegex)
          // this.loading = false;
          this.alertService.alert(testeRegex[1], 'error')
        } else {
          this.loading = false;
          this.alertService.alert('Algo deu errado.', 'error')
        }

      })
  }


  /**METODO QUE VERIFICA SE TODOS OS LANÇAMENTOS DE UM DESMEMBRAMENTO ESTÃO ESTORNADOS (VLRBAIXA = 0) */
  verificaStatusLancamentos() {
    let count = 0;
    for (let i = 0; i < this.lancamento.length; i++) {
      if (this.lancamento[i].vlrbaixa > 0) {
        count++;
        console.log(this.lancamento[i].nufin)
        console.log(count)
      }
    }

    /**SE AINDA TIVER LANÇAMENTOS COM BAIXA */
    if (count > 0) {
      //  this.showError('Atenção', 'Ainda precisa estornar algum lançamento');
      return true;
    } else {
      return false;
    }
  }

  /**METODO DE DESMEMBRAMENTO DO LANÇAMENTO */
  confirmaExcluirFinanceiro() {

    /**VERIFICA SE O LANÇAMENTO É DESMEMBRADO, SE SIM BUSCA TODOS OS TITULOS GERADOS E VÊ SE ALGUM DELES POSSUI BAIXA*/
    if (this.lancamentoSelecionado[0].nufinorig > 0) {
      this.buscaLancamentoPorNufin(this.lancamentoSelecionado[0].nufinorig);
      if (this.verificaStatusLancamentos()) {
        this.showError('Atenção', 'Não é permitido excluir um lançamento desmembrado que esteja pendente. Por favor faça o estorno de todos os títulos relacionados a ele.');
        return
      }
    }


    /**VERIFICA A ORIGEM DO LANÇAMENTO */
    if (this.lancamentoSelecionado[0].origem != 'FIN') {
      this.showError('Atenção', 'Não é permitido excluir um lançamento de que a origem não seja do Financeiro.')
      return
    }

    /**VERIFICA O VALOR DE BAIXA DO LANÇAMENTO */
    else if (this.lancamentoSelecionado[0].vlrlanc === this.lancamentoSelecionado[0].vlrbaixa) {
      this.showError('Atenção', 'Não é permitido excluir um lançamento que esteja totalmente baixado.')
      return
    }


    // if ((this.estornar && this.permissao != undefined && this.permissao.length > 0) || (this.codcli > 0 && (this.permissao === undefined || this.permissao.length === 0))) {
    // if (this.lancamentoSelecionado.length > 0) {
    this.confirmationService.confirm({
      message: 'Deseja excluir esse lançamento no valor de ' + this.lancamentoSelecionado[0].vlrlanc.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', }) + ' ?',
      header: 'Excluir Financeiro',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.excluiFinanceiroPorNufin();
      },
      reject: () => {
      }
    });
    // }
    // } else {
    //   this.showError('Atenção', 'Ação não permitida.')
    //   return
    // }
  }


  /**METODO QUE ATRIBUI O DIA ATUAL CASO NÃO INFORMADO A  Dt. Venc. Final*/
  atribuiHoje(data: any) {
    if (data === undefined) {
      return this.utilitariosService.ajustaDataBanco(this.hoje);
    } else {
      return this.utilitariosService.ajustaDataBanco(data);
    }
  }

  /**METODO QUE AJUSTA A DATA NO FORMATO QUE O BANCO ESPERA PARA O FILTRO */
  ajustaData(data: any) {
    if (data === undefined) {
      return null;
    } else {
      return this.utilitariosService.ajustaDataBanco(data);
    }
  }


  /**METODO QUE FAZ O FILTRO DA FINANÇA */
  filtraFinanceiro() {
    this.mostraCarregandoBusca = true;

    /**VALIDA O RETORNO DOS CHECKBOX E OS COLOCAR NUM AUX PARA FACILITAR A CHAMADA DA REQUISIÇÃO */
    let auxCheckboxTipo = this.utilitariosService.validaCheckboxTipo(this.receitas, this.despesas);
    let auxCheckboxProvisao = this.utilitariosService.validaCheckboxProvisaoPendente(this.real, this.provisao);
    let auxCheckboxPendente = this.utilitariosService.validaCheckboxProvisaoPendente(this.baixados, this.pendentes);

    console.log(auxCheckboxTipo)
    console.log(auxCheckboxProvisao)
    console.log(auxCheckboxPendente)
    if (this.detalhesBusca === '') {
      this.detalhesBusca = null;
    }

    this.financeiroService.buscaMovFinanceiro(auxCheckboxTipo, this.origemSelecionadaBusca, this.contaSelecionadaBusca, this.categoriaSelecionadaBusca,
      this.parceiroSelecionadoBusca, this.detalhesBusca, this.estabelecimentoSelecionadaBusca, this.profissionalSelecionadoBusca, this.codcli, this.ajustaData(this.dataInicialLanc), this.ajustaData(this.dataFinalLanc),
      this.atribuiHoje(this.dataInicialVenc), this.atribuiHoje(this.dataFinalVenc), this.ajustaData(this.dataInicialBaixa), this.ajustaData(this.dataFinalBaixa), auxCheckboxPendente, auxCheckboxProvisao, this.codusuresp, this.negociacaoBuscaSelec).subscribe((res) => {
        this.lancamento = res;
        console.log(this.lancamento)
        this.mostraCarregandoBusca = false;
        this.guardaFiltros();
        this.calculaTotais();
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.mostraCarregandoBusca = false;
        })


    // console.log(this.utilitariosService.validaCheckbox(this.receitas, this.despesas))
    // console.log('↑')
    // console.log(this.receitas)
    // console.log(this.despesas)
    // console.log(this.estabelecimentoSelecionadaBusca)
    // console.log(this.parceiroSelecionadoBusca)
    // console.log(this.profissionalSelecionadoBusca)
    // console.log(this.origemSelecionadaBusca)
    // console.log(this.contaSelecionadaBusca)
    // console.log(this.detalhesBusca)

  }

  geraRelatorioExcel() {
    /**VALIDA O RETORNO DOS CHECKBOX E OS COLOCAR NUM AUX PARA FACILITAR A CHAMADA DA REQUISIÇÃO */
    let auxCheckboxTipo = this.utilitariosService.validaCheckboxTipo(this.receitas, this.despesas);
    let auxCheckboxProvisao = this.utilitariosService.validaCheckboxProvisaoPendente(this.real, this.provisao);
    let auxCheckboxPendente = this.utilitariosService.validaCheckboxProvisaoPendente(this.pendentes, this.baixados);

    console.log(auxCheckboxTipo)
    console.log(auxCheckboxProvisao)
    console.log(auxCheckboxPendente)
    if (this.detalhesBusca === '') {
      this.detalhesBusca = null;
    }

    /**CHECA SE ESTÁ NA VERSÃO MOBILE */
    if (environment.MOBILE) {
      this.financeiroService.geraRelatorioFinanceiroExcel(auxCheckboxTipo, this.origemSelecionadaBusca, this.contaSelecionadaBusca, this.categoriaSelecionadaBusca,
        this.parceiroSelecionadoBusca, this.detalhesBusca, this.estabelecimentoSelecionadaBusca, this.profissionalSelecionadoBusca, this.codcli, this.ajustaData(this.dataInicialLanc), this.ajustaData(this.dataFinalLanc),
        this.atribuiHoje(this.dataInicialVenc), this.atribuiHoje(this.dataFinalVenc), this.ajustaData(this.dataInicialBaixa), this.ajustaData(this.dataFinalBaixa), auxCheckboxProvisao, auxCheckboxPendente, this.codusuresp).subscribe((res) => {

          let url = environment.urlRelatorio + res[0]
          cordova.InAppBrowser.open(url, '_system');
        },
          (error) => {
            console.log(error.message)
            console.log('deu ruim')
            this.mostraCarregandoBusca = false;
          })
    } else {
      this.financeiroService.geraRelatorioFinanceiroExcel(auxCheckboxTipo, this.origemSelecionadaBusca, this.contaSelecionadaBusca, this.categoriaSelecionadaBusca,
        this.parceiroSelecionadoBusca, this.detalhesBusca, this.estabelecimentoSelecionadaBusca, this.profissionalSelecionadoBusca, this.codcli, this.ajustaData(this.dataInicialLanc), this.ajustaData(this.dataFinalLanc),
        this.atribuiHoje(this.dataInicialVenc), this.atribuiHoje(this.dataFinalVenc), this.ajustaData(this.dataInicialBaixa), this.ajustaData(this.dataFinalBaixa), auxCheckboxProvisao, auxCheckboxPendente, this.codusuresp).subscribe((res) => {

          let url = environment.urlRelatorio + res[0]
          window.open(url);
        },
          (error) => {
            console.log(error.message)
            console.log('deu ruim')
            this.mostraCarregandoBusca = false;
          })
    }
  }

  /**METODO PARA LIMPAR OS FILTROS DO LANÇAMENTO FINANCEIRO */
  limpaFiltros() {
    this.receitas = [];
    this.despesas = [];
    this.real = [];
    this.pendentes = [];
    this.provisao = [];
    this.baixados = [];
    this.estabelecimentoSelecionadaBusca = null;
    this.parceiroSelecionadoBusca = null;
    this.profissionalSelecionadoBusca = null;
    this.origemSelecionadaBusca = null;
    this.contaSelecionadaBusca = null;
    this.dataInicialVenc = this.hoje;
    this.dataFinalVenc = this.hoje;
    this.dataInicialLanc = null;
    this.dataFinalLanc = null;
    this.dataInicialBaixa = null;
    this.dataFinalBaixa = null;
    this.detalhesBusca = '';
    console.log(this.dataInicialLanc)
    console.log(this.dataFinalLanc)
  }


  /**ABRE O POPUP DE EDIÇÃO E ATRIBUI OS DADOS DO LANÇAMENTO AOS CAMPOS */
  selecionouLancamentoEdicao(lancamento: any) {

    // if (lancamento.origem === 'AGD') {
    //   this.showError('Atenção', 'Não é permitido editar um lançamento de agendamento.')
    //   return
    // } else 
    // if (lancamento.origem === 'VEN') {
    //   this.showError('Atenção', 'Não é permitido editar um lançamento de venda.')
    //   return
    // } else




    /*DESABILITA CAMPOS, CASO SEJA UM LANÇAMENTO DE Agendamento OU Venda */
    if (lancamento.origem === 'AGD' || lancamento.origem === 'VEN') {
      this.desabilitaCamposAGDVND = true;
    } else {
      this.desabilitaCamposAGDVND = false;
    }

    if ((this.alterar && this.permissao != undefined && this.permissao.length > 0) || (this.codcli > 0 && (this.permissao === undefined || this.permissao.length === 0))) {

      console.log(lancamento)

      this.lancamentoSelecionadoEditar = lancamento;


      this.nufin = lancamento.nufin;
      this.tipoSelecionado = lancamento.tipo;

      /**AJUSTA A DATA PARA O INPUT DO PRIMENG */
      this.dataVenc = new Date(lancamento.dtvenc + 'GMT-0300');
      console.log(lancamento.dtbaixa)
      if (lancamento.dtbaixa) {
        this.dataBaixa = new Date(lancamento.dtbaixa + 'GMT-0300');
      } else {
        this.dataBaixa = null;
      }

      this.contaSelecionada = lancamento.codconta;
      this.categoriaSelecionada = lancamento.codcat;
      this.estabelecimentoSelecionada = lancamento.codemp;

      this.buscarProfissionals(this.estabelecimentoSelecionada);
      this.recuperaClientes();

      this.profissionalSelecionado = lancamento.codfunc;
      this.clienteSelecionado = lancamento.codparc;
      this.detalhesLancamento = lancamento.detalhe;
      this.vlrLancamento = lancamento.vlrlanc;
      this.vlrBaixa = lancamento.vlrbaixa;
      this.dataLanc = lancamento.dtlanc;
      this.tipoNegociacaoSelecionada = lancamento.codtipneg;
      this.origemLancamento = lancamento.origem;

      this.desabilitaCamposLancamento = true;
      this.mostraDialogEditLancamento();
    } else {
      this.showError('Atenção', 'Ação não permitida.');
      return;
    }
  }


  /**EXIBE O BOTÃO DE COPIA DE LANÇAMENTO */
  clicouMenuCopiaLancamento() {
    if (!(this.nufin > 0)) {
      return;
    }
    if (this.botaoCopia) {
      this.botaoCopia = false;
    } else {
      this.botaoCopia = true;
    }
  }


  /**ATRIBUI O NUFIN COMO NULL PARA QUE SEJA CRIADO UM NOVO LANÇAMENTO COM OS MESMOS DADOS */
  copiaLancamento() {
    this.mostraCarregandoCopia = true;
    setTimeout(() => {
      this.nufin = null;
      // this.vlrBaixa = null;
      //this.dataBaixa = null;
      this.mostraCarregandoCopia = false;
      this.desabilitaCamposLancamento = false;
      this.desabilitaCamposAGDVND = false;
    }, 1000);
  }

  /**PERMITE QUE APENAS UM CHECKBOX SEJA SELECIONADO */
  verificaCheckboxBaixa() {
    if (this.lancamentoSelecionado.length > 0) {

      this.lancamentoSelecionado = (this.lancamento.filter((x: any) => x.nufin === this.lancamentoSelecionado[this.lancamentoSelecionado.length - 1].nufin));
    }
    console.log(this.lancamentoSelecionado)
  }




  /**CALCULA OS VALORES PARA SER MOSTRADOS NOS CARDS */
  calculaTotais() {
    this.totalReceitas = 0;
    this.totalDespesas = 0;
    this.totalBaixado = 0;
    this.totalABaixar = 0;
    this.totalBaixadoDespesa = 0;
    this.totalBaixadoReceita = 0;
    this.totalABaixarReceita = 0;
    this.totalABaixarDespesa = 0;

    /**PERCORE O VETOR DOS LANÇAMENTOS */
    for (let i = 0; i < this.lancamento.length; i++) {

      if (this.lancamento[i].tipo === 'R') {
        this.totalReceitas += this.lancamento[i].vlrlanc;
        this.totalBaixadoReceita += this.lancamento[i].vlrbaixa;
      } else {
        this.totalBaixadoDespesa += this.lancamento[i].vlrbaixa;
        this.totalDespesas += this.lancamento[i].vlrlanc;
      }
      //this.totalABaixar += this.lancamento[i].vlrlanc;
    }


    /**DESCONTA A RECEITA - DESPESA */

    /**A BAIXAR */
    this.totalABaixarReceita = this.totalReceitas - this.totalBaixadoReceita;
    this.totalABaixarDespesa = this.totalDespesas - this.totalBaixadoDespesa;

    /**BAIXADO */
    this.totalBaixado = this.totalBaixadoReceita - this.totalBaixadoDespesa;
    this.totalABaixar = this.totalABaixarReceita - this.totalABaixarDespesa;





    /**ANTIGO */
    // this.totalReceitas = 0;
    // this.totalDespesas = 0;
    // this.totalBaixado = 0;
    // this.totalABaixar = 0;
    // this.totalBaixadoDespesa = 0;
    // this.totalBaixadoReceita = 0;

    // for (let i = 0; i < this.lancamento.length; i++) {
    //   if (this.lancamento[i].tipo === 'R') {
    //     this.totalReceitas += this.lancamento[i].vlrlanc;
    //     this.totalBaixadoReceita += this.lancamento[i].vlrbaixa;

    //   } else {
    //     this.totalBaixadoDespesa += this.lancamento[i].vlrbaixa;
    //     this.totalDespesas += this.lancamento[i].vlrlanc;

    //   }
    //   this.totalBaixado += this.lancamento[i].vlrbaixa;
    //   this.totalABaixar += this.lancamento[i].vlrlanc;
    // }
    // this.totalABaixar = this.totalABaixar - this.totalBaixado;

    // console.log(this.totalBaixadoReceita)
    // console.log('↑ receita')
    // console.log(this.totalDespesas)
    // console.log('↑ despesa')

    // console.log(this.totalReceitas)
    // console.log(this.totalDespesas)
    // console.log(this.totalBaixado)
    // console.log(this.totalABaixar)
  }





  /**RECUPERA AS PERMISSÕES */
  recuperaPermissao() {
    console.log('recuperei as permissoes')
    this.permissao = this.permissoesService.getPermissao();
    console.log(this.permissao)
    if (this.permissao != undefined && this.permissao.length > 0) {
      if (this.permissao[9].acao[0].PERMITE === 'S') {
        this.cadastrar = true;
      }
      if (this.permissao[9].acao[1].PERMITE === 'S') {
        this.alterar = true;
      }
      if (this.permissao[9].acao[2].PERMITE === 'S') {
        this.excluir = true;
      }
      if (this.permissao[9].acao[3].PERMITE === 'S') {
        this.baixa = true;
      }
      if (this.permissao[9].acao[4].PERMITE === 'S') {
        this.estornar = true;
      }
      // /**ATRIBUI O CODCLI VINCULADO NA PERMISSAO DO FUNCIONARIO, PARA FAZER AS OPERAÇÕES DA TELA */
      this.codcli = this.permissao[0].codcli;
    }
  }


  /**RECUPERA O NUID DO 'Programações' PARA QUE SEJA POSSIVEL EDITAR O TIPO DE NEGOCIAÇÃO DO CABEÇALHO */
  recuperaNuid() {
    this.nuid = this.agendaService.getNuid();

    if (this.nuid > 0) {
      this.buscaLancamentoPorNuid(this.nuid);
    }

    console.log(this.nuid);
  }



  geraRelatorioExcelFinanceiro() {
    /**VERIFICA SE A COMISSÃO JÁ ESTÁ FECHADA */
    // if (this.comissao.fechado === 'S' || this.comissaoFechada != 0) {

    //   /**CHECA SE ESTÁ NA VERSÃO MOBILE */
    //   if (environment.MOBILE) {
    //     this.comissaoService.geraRelatorioExcel(this.nucom).subscribe((res) => {
    //       let url = environment.urlRelatorio + res[0]
    //       cordova.InAppBrowser.open(url, '_system');
    //     },
    //       (error) => {
    //         console.log('deu ruim')
    //         this.alertService.alert('Algo deu errado.', 'error');
    //       })
    //   } else {
    //     this.comissaoService.geraRelatorioExcel(this.nucom).subscribe((res) => {
    //       // var blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    //       // var blobURL = URL.createObjectURL(blob);
    //       // console.log(blobURL)
    //       let url = environment.urlRelatorio + res[0]
    //       window.open(url);
    //     },
    //       (error) => {
    //         console.log('deu ruim')
    //         this.alertService.alert('Algo deu errado.', 'error');
    //       })
    //   }
    // }
  }
  
}
