import { LoginService } from 'src/login/login.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'src/alert.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-codigo-recuperacao',
  templateUrl: './codigo-recuperacao.component.html',
  styleUrls: ['./codigo-recuperacao.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CodigoRecuperacaoComponent implements OnInit {

  codigo: any[] = ['', '', '', '']
  colado: string = ''
  email: string = ''
  msgErro: string = '';
  teveErro: boolean = false;
  loading: boolean = false;

  /**CRONOMETRO */
  timeLeft: number = 180;
  interval: any;
  temporizador: string = '';

  constructor(private loginService: LoginService, private router: Router, private alertService: AlertService) { }

  ngOnInit(): void {
    this.cronometro();
    this.email = this.loginService.getEmail();
    console.log(this.email)

  }

  /**MUDA PARA O PROXIMO INPUT */
  apertou(event: any) {
    let next = event.target.nextElementSibling;
    let previous = event.target.previousElementSibling;
    console.log(this.codigo + '  a')
    console.log(this.codigo.length + ' ↑')
    for (let i = 0; i < this.codigo.length; i++) {
      if (event.key != 'Backspace') {
        console.log('NÃO É  Backspace')
        next.focus();
        console.log(this.codigo)
      }
      else {
        console.log('É Backspace')
        previous.focus();
        console.log(this.codigo)
      }
    }
  }

  /**PERMITE CTRLV EM TODOS OS 4 INPUTS */
  colar(event: ClipboardEvent) {
    let clipboardData = event.clipboardData || (window as any).clipboardData;
    let pastedText = clipboardData.getData('text');
    for (let i = 0; i < pastedText.length; i++) {
      const character = pastedText.charAt(i);
      this.codigo[i] = character
    }

  }

  /**FAZ O CRONOMETRO */
  cronometro() {
    let minutos;
    let segundos;
    this.interval = setInterval(() => {
      if (this.timeLeft >= 0) {
        minutos = Math.trunc(this.timeLeft / 60);
        segundos = this.timeLeft - (minutos * 60);
        if (minutos < 10) {
          minutos = "0" + minutos;
        }
        if (segundos < 10) {
          segundos = "0" + segundos;
        }
        this.temporizador = minutos + ":" + segundos
        this.timeLeft--;
      }
    }, 1000)
  }


  enviaCodigo() {
    let codigoCompleto = '';
    this.loading = true;
    for (let i in this.codigo) {
      codigoCompleto += this.codigo[i]
    }
    console.log(codigoCompleto)
    this.loginService.validaCodRecuperarConta(this.email, codigoCompleto, environment.CODTIPSEG).subscribe((res) => {
      console.log(res[0].result);
      if (res[0].result === "CÓDIGO VALIDADO") {
        this.loginService.setCodigo(codigoCompleto);
        this.router.navigate(['/alterarSenhaDeslog']);
        this.loading = false
      } else {
        this.teveErro = true;
        this.codigo = [];
        this.msgErro = "Código informado não é válido."
        this.loading = false;
      }
    },
      (e) => {
        console.log(e.error.message)
        this.teveErro = true;
        this.codigo = [];
        this.msgErro = "Código informado não é válido."
        this.loading = false;

      })

  }
}
