import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, NavigationEnd, NavigationStart, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, filter } from 'rxjs';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { PermissoesService } from 'src/permissoes.service';
import { UsuarioService } from 'src/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class TelasGuard implements CanActivate {
  codfunc: number = 0;
  codUsu: string = '';
  codcli: number = 0;
  rota: string = '';
  permissao: any;
  temAcesso: boolean = false;

  constructor(private usuarioService: UsuarioService, private decodedTokenService: DecodedTokenService, private permissoesService: PermissoesService, private router: Router) { }



  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): any {
    // this.recuperaCodUsu();
    this.recuperaCodCli();
    //  this.buscaCodfunc();
    this.recuperaRota();
    console.log(this.rota)
    // console.log(this.permissoesService.validaPermissao(this.rota))


    if (this.codcli > 0) {
      return true;
    }
    if (!this.temAcesso) {
      this.router.navigate([('/painelBarber')]);
      return false;
    } else {
      return true;
    }

  }





  /**BUSCA O CODCLI */
  recuperaCodCli() {
    this.codcli = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODCLI;
  }
  // /**BUSCA O CODUSU */
  // recuperaCodUsu() {
  //   this.codUsu = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  // }

  /**DESCOBRE A ROTA QUE ESTÁ TENTANDO ACESSAR */
  recuperaRota() {
    // this.router.events
    //   .pipe(filter((event) => event instanceof NavigationEnd))
    //   .subscribe((event) => {
    //     this.rota = Object(event).url;
    //     console.log(this.rota)
    //     this.temAcesso = this.permissoesService.validaPermissao(this.rota);
    //   })

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        console.log('Próxima rota:', event.url);
        this.rota = Object(event).url;
        console.log(this.rota)
        this.temAcesso = this.permissoesService.validaPermissao(this.rota)!;
        console.log(this.temAcesso)
      }
    });

  }


  // /**RECUPERA O CODFUNC PARA FAZER A BUSCA DE PERMISSOES */
  // buscaCodfunc() {
  //   this.temAcesso = 0;
  //   this.recuperaRota();

  //   this.usuarioService.getPerfil(this.codUsu, this.codUsu).subscribe((res) => {
  //     this.codfunc = Object(res)[0].codfunc;
  //     console.log(this.codfunc)

  //     console.log(this.permissoesService.validaPermissao(this.codfunc, this.rota))
  //     return this.permissoesService.validaPermissao(this.codfunc, this.rota);
  //   },
  //     (error) => {
  //       console.log(error.message)
  //       console.log('deu ruim')
  //       return false;
  //       //this.showError('Atenção', 'Algo deu errado.')
  //     });

  // }

  // /**LIBERA O ACESSO A TELA */
  // liberaAcesso() {
  //   console.log(this.temAcesso)
  //   console.log('↑ dentro do metodo')
  //   if (this.temAcesso === 0) {
  //     this.router.navigate([('/')]);
  //   }
  // }



  // /**METODO QUE RECUPERA AS PERMISSÕES DO FUNCIONARIO PARA LIBERAR A TELA */
  // recuperaPermissoes() {
  //   this.permissoesService.bucaPermissaoPorCodfunc(this.codfunc).subscribe((res) => {
  //     this.permissao = res;
  //     console.log(this.rota + ' rota na recuperação de permissoes')

  //     /**PERCORRE O VETOR DE PERMISSOES PARA VERIFICAR O ACESSO A TELA */
  //     for (let i = 0; i < this.permissao.length; i++) {

  //       /**RETIRA OS ESPAÇOS EM BRANCO */
  //       this.permissao[i].habilita = this.permissao[i].habilita.replace(/\s/g, '');

  //       //**SE A ROTA FOR A MESMA QUE ESTÁ NO BANCO, ENTÃO LIBERA O ACESSO */
  //       // if (this.permissao[i].rota === this.rota && this.permissao[i].habilita === 'S') {
  //       if (this.permissao[i].descr === 'BARBEARIAS' && this.permissao[i].habilita === 'S' && this.rota === '/cadastrarBarbearia') {
  //         console.log(this.permissao[i]);
  //         console.log(this.rota);
  //         this.temAcesso++;
  //       }
  //     }
  //     console.log(this.permissao)
  //     this.liberaAcesso();
  //   },
  //     (error) => {
  //       console.log(error.message)
  //       console.log('deu ruim')
  //       this.liberaAcesso();
  //       //this.showError('Atenção', 'Algo deu errado.')
  //     });

  // }
}
