import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { delay, first, Observable, tap } from 'rxjs';
import { environment } from './environments/environment';
import { LoginService } from './login/login.service';


@Injectable({
  providedIn: 'root'
})
export class AppServiceService {

  constructor(private http: HttpClient, private loginService: LoginService) { }


  buscarSobre(codtipseg: number): Observable<any> {

    const segmento = { "codtipseg": codtipseg }

    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    return this.http
      .post(`${environment.BASE_URL}/sobre`, segmento, { headers: headers })
      .pipe(
        first(),
        tap((r) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }
}
