// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  /**LOCAL*/
  // production: true,
  // API: 'http://localhost:3000/',
  // BASE_URL: 'http://localhost:3000', // /api
  // HOMOLOGACAO: false,     //para logar sem api
  // imgEmpresa: 'http://localhost:3000/uploads/imagens/empresas/',
  // imgInformativo: 'http://localhost:3000/uploads/imagens/cards_home/',
  // imgProfissional: 'http://localhost:3000/uploads/imagens/profissionais/',
  // imgProduto: 'http://localhost:3000/uploads/imagens/produtos/',
  // urlRelatorio: 'http://localhost:3000/uploads/relatorios/',
  // CODTIPSEG: 1,
  // MOBILE: false

  /**SERVIDOR */
  // production: true,
  // API: 'https://appbarberexpress.com:8443',
  // BASE_URL: 'https://appbarberexpress.com:8443', // /api
  // HOMOLOGACAO: false, //para logar sem api
  // imgEmpresa: 'https://appbarberexpress.com:8443/uploads/imagens/empresas/',
  // imgProfissional: 'https://appbarberexpress.com:8443/uploads/imagens/profissionais/',
  // imgInformativo: 'https://appbarberexpress.com:8443/uploads/imagens/cards_home/',
  //imgProduto: 'http://appbarberexpress.com:8443/uploads/imagens/produtos/',
  // urlRelatorio: 'https://appbarberexpress.com:8443/uploads/relatorios/',
  //CODTIPSEG: 1,
  // MOBILE: false


    /** API */
    production: true,
    API: 'https://api.appbarberexpress.com',
    BASE_URL: 'https://api.appbarberexpress.com', // /api
    HOMOLOGACAO: false, //para logar sem api
    imgEmpresa: 'https://api.appbarberexpress.com/uploads/imagens/empresas/',
    imgProfissional: 'https://api.appbarberexpress.com/uploads/imagens/profissionais/',
    imgInformativo: 'https://api.appbarberexpress.com/uploads/imagens/cards_home/',
    urlRelatorio: 'https://api.appbarberexpress.com/uploads/relatorios/',
    imgProduto: 'http://api.appbarberexpress.com/uploads/imagens/produtos/',
    MOBILE: false,
    CODTIPSEG: 1,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
