import { EstabelecimentoService } from '../estabelecimento.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { environment } from 'src/environments/environment';
import { UsuarioService } from 'src/usuario.service';
import { MessageService } from 'primeng/api';
import { AlertService } from 'src/alert.service';

declare var cordova: any;
@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.scss'],
  providers: [MessageService],
  encapsulation: ViewEncapsulation.None
})
export class ContatoComponent implements OnInit {

  /* variáveis do formulário */
  nomeemp: string = ' ';
  logradouro: string = ' ';
  complemento: string = ' ';
  numero: string = ' ';
  bairro: string = ' ';
  nomecid: string = ' ';
  uf: string = ' ';
  telefone: string = ' ';
  email: string = ' ';
  filename: string = '';
  codusu: string = '';
  temFacebook: boolean = false;
  temInstagram: boolean = false;
  temTiktok: boolean = false;
  linkFacebook: string = '';
  linkInstagram: string = '';
  linkTiktok: string = '';
  /* variáveis do componente */
  codemp: number = 0;

  mostraCarregando: boolean = true;

  constructor(private decodedTokenService: DecodedTokenService, private estabelecimentoService: EstabelecimentoService, private usuarioService: UsuarioService,
    private messageService: MessageService, private alertService: AlertService) { }

  ngOnInit(): void {
    this.recuperaCodUsu();
    this.recuperaCodEmp();
  }

  recuperaCodUsu() {
    this.codusu = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }

  recuperaCodEmp() {
    this.usuarioService.getPerfil(this.codusu, this.codusu).subscribe((res) => {
      this.codemp = Object(res)[0].codemp;
      this.buscarContatoEstabelecimento();
    },
      (error) => {
        console.log(error.message)
        console.log('deu ruim')
        this.mostraCarregando = false;
        this.alertService.alert('Algo deu errado.', 'error');
        //this.showError('Atenção', 'Algo deu errado.')
      });
  }

  buscarContatoEstabelecimento() {
    this.estabelecimentoService.contatoEstabelecimento(this.codemp.toString(), this.codusu).subscribe((res) => {
      this.mostraCarregando = false;
      console.log(res)
      this.nomeemp = Object(res)[0].nomeemp;
      this.logradouro = Object(res)[0].logradouro;
      this.complemento = Object(res)[0].complemento;
      if (Object(res)[0].numero) {
        this.numero = ', ' + Object(res)[0].numero;
      }
      this.bairro = Object(res)[0].bairro;
      this.nomecid = Object(res)[0].nomecid;
      this.uf = Object(res)[0].uf;
      this.telefone = Object(res)[0].telefone;
      this.email = Object(res)[0].email;
      this.filename = environment.imgEmpresa + Object(res)[0].filename;
      if (Object(res)[0].linkfacebook) {
        this.temFacebook = true;
        this.linkFacebook = Object(res)[0].linkfacebook;
      }
      if (Object(res)[0].linkinsta) {
        this.temInstagram = true;
        this.linkInstagram = Object(res)[0].linkinsta;
      }
      if (Object(res)[0].linktiktok) {
        this.temTiktok = true;
        this.linkTiktok = Object(res)[0].linktiktok;
      }
    },
      (error) => {
        console.log(error.message)
        console.log('deu ruim')
        this.mostraCarregando = false;
        this.alertService.alert('Algo deu errado.', 'error');
        //this.showError('Atenção', 'Algo deu errado.')
      });
  }

  /**METODO DE TOAST */
  showError(titulo: string, mensagem: string) {
    this.messageService.add({
      key: 'bc',
      severity: 'error',
      summary: titulo,
      detail: mensagem
    });
  }


  abreTikTok() {
    if (environment.MOBILE) {
      cordova.InAppBrowser.open(this.linkTiktok, '_system', 'location=yes');
    } else {
      window.open(this.linkTiktok)
    }
  }
  abreFacebook() {
    if (environment.MOBILE) {
      cordova.InAppBrowser.open(this.linkFacebook, '_system', 'location=yes');
    } else {
      window.open(this.linkFacebook)
    }
  }
  abreInstagram() {
    if (environment.MOBILE) {
      cordova.InAppBrowser.open(this.linkInstagram, '_system', 'location=yes');
    } else {
      window.open(this.linkInstagram)
    }
  }

}
