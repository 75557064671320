import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AlertService } from 'src/alert.service';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { Servico } from 'src/assets/servico';
import { UtilitariosService } from 'src/assets/utilitarios.service';
import { ProfissionalService } from 'src/profissional.service';
import { PlanoService } from 'src/plano.service';
import { ServicoService } from 'src/servico.service';
import { TraducaoService } from 'src/traducao.service';

@Component({
  selector: 'app-detalhe-plano-pacote',
  templateUrl: './detalhe-plano-pacote.component.html',
  styleUrls: ['./detalhe-plano-pacote.component.scss'],
  providers: [MessageService, ConfirmationService],
  encapsulation: ViewEncapsulation.None
})
export class DetalhePlanoPacoteComponent implements OnInit {
  showInfoGerais: boolean = false;
  showAtivarDesativarPlanoPacote: boolean = false;
  showLista: boolean = false;
  showAdcProd: boolean = false;

  /**RADIO BUTTON */
  valorSelecionadoRadioButton: string = '';

  loading: boolean = false;

  nome: string = '';
  descrplano: string = '';
  caracteresRestantes: number = 0;
  preco: number = 0;
  qtd: any;
  duracaoSelecionada: any;
  duracao: any[];
  codUsu: string = '';
  codcli: number = 0;
  comissao: number = 100;
  plano: any;
  servicos: Servico[] = [];
  servicoSelecionado: any;
  auxServicos: any;
  descrProds: string = '';

  listaSelecionadaExcluir: any[] = [];
  selecionouTodasListaExcluir: any[] = [];

  nupla: number = 0;
  nulista: any;
  nomePlanoTitulo: string = '';
  listaServicos: any;


  constructor(private alertService: AlertService, private traducaoService: TraducaoService, private profissionalService: ProfissionalService, private decodedTokenService: DecodedTokenService,
    private messageService: MessageService, private servicoService: ServicoService, private planoService: PlanoService, private utilitariosService: UtilitariosService,
    private confirmationService: ConfirmationService) {

    this.duracao = [
      // {
      //   'nome': 'Ilimitado', 'tempo': 999
      // },
      {
        'nome': '7 dias', 'tempo': 7
      },
      {
        'nome': '15 dias', 'tempo': 15
      },
      {
        'nome': '30 dias', 'tempo': 30
      },
      {
        'nome': '31 dias', 'tempo': 31
      },
      {
        'nome': '60 dias', 'tempo': 60
      },
      {
        'nome': '90 dias', 'tempo': 90
      },
      {
        'nome': '180 dias', 'tempo': 180
      },
      {
        'nome': '1 ano', 'tempo': 365
      }
    ]
  }

  ngOnInit(): void {
    this.recuperaCodCli();
    this.recuperaCodUsu();
    this.recuperaServicos();
    this.recuperaPlano();
  }


  /**METODO QUE RECUPERA INFORMAÇÕES DO TOKEN */
  recuperaCodCli() {
    this.codcli = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODCLI;
  }

  recuperaCodUsu() {
    this.codUsu = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }


  mostraDialogInfoGerais() {
    this.showInfoGerais = true;
  }

  mostraDialogLista() {
    this.showLista = true;
  }

  mostraDialogAtvDest() {
    this.showAtivarDesativarPlanoPacote = true;
  }

  clicouVoltar() {
    this.showLista = false;
  }

  mostraDialogAdcProd() {
    this.showAdcProd = true;
    this.servicoSelecionado = [];
    this.qtd = null;
    this.nulista = null;
  }




  /**METODO QUE RECUPERA O PLANO PARA EDIÇÃO */
  recuperaPlano() {
    this.plano = this.planoService.getPlano();
    console.log(this.preco)
    console.log(this.plano.nupla)


    if (this.plano != undefined) {
      this.nupla = this.plano.nupla;
    }

    /**SE TIVER UM NUPLA FAZ O PROCESSO DE EDIÇÃO */
    if (this.nupla != undefined) {
      // this.nupla = this.plano.nupla;
      this.nomePlanoTitulo = this.plano.nome;
      this.nome = this.plano.nome;
      this.descrplano = this.plano.descr;
      this.comissao = this.plano.comissao;
      this.preco = this.plano.preco;
      this.duracaoSelecionada = Number(this.plano.duracao);
      console.log(this.duracaoSelecionada)
      this.buscaListaServicos();
    }
    console.log(this.preco)
  }

  /**METODO PARA CONTAR OS CARACTERES DA PERGUNTA */
  contaCaracter() {
    if (this.caracteresRestantes < 151) {
      this.caracteresRestantes = this.descrplano.length;
    } else {
      return;
    }
  }

  /**METODO QUE RECUPERA OS SERVIÇOS DA REDE */
  recuperaServicos() {
    this.servicoService.buscarServicoPorCodCli(this.codcli, this.codUsu).subscribe((res) => {
      console.log(res);
      this.servicos = res;
    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
      })
  }

  criaPlano() {
    //this.loading = true;

    //ATRIBUI COMISSÃO COMO 0 CASO NÃO SEJA PREENCHIDA
    if (this.comissao === undefined || this.comissao === null) {
      this.comissao = 100;
    }

    if (this.utilitariosService.validaCampo([this.nome, this.descrplano, this.preco, this.duracaoSelecionada])) {
      console.log('É necessário preencher todos os campos.')
      this.loading = false;
      this.showError('Atenção', 'É necessário preencher todos os campos.')
      return;
    }

    this.planoService.preencherPlano(this.nupla, this.nome, this.descrplano, this.preco, this.duracaoSelecionada,
      this.comissao, this.codUsu, this.codcli).subscribe((res) => {
        console.log(res);
        this.nupla = Object(res).nupla;
        this.nomePlanoTitulo = this.nome;
        this.loading = false;
        this.showInfoGerais = false;
        this.alertService.alert('Informações do plano alteradas!', 'success')
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.loading = false;
          this.showInfoGerais = false;
          this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')
        });
  }

  /**METODO QUE BUSCA A LISTA DOS SERVIÇOS DO PLANO */
  buscaListaServicos() {
    this.planoService.buscaListasPlanoPorNupla(this.nupla).subscribe((res) => {
      console.log(res);
      this.listaServicos = res;
    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
        this.alertService.alert('Algo deu errado.', 'error');
        //this.showError('Atenção', 'Algo deu errado.')
      });
  }

  /**********************************************************************************
  *METODO QUE LISTA TODAS AS INFORMAÇÕES DOS SERVIÇOS SELECIONADOS ******************
  *É PRECISO FAZER ISSO POIS USA OPTIONVALUE NO DROPDOWN PARA SER ENVIADO AO BANCO***
  ***********************************************************************************/
  listaServicosSelecionados() {
    this.auxServicos = [];
    this.auxServicos = this.servicos.filter(x => this.servicoSelecionado.includes(x.codprod))
    console.log(this.auxServicos)
    // objetos.filter(objeto => idsFiltrados.includes(objeto.id));
  }

  /**METODO QUE CRIA A DESCRIÇÃO DOS SERVIÇOS SELECIONADOS */
  criaDescrProds() {
    this.listaServicosSelecionados();
    var auxDescrProds = []
    this.descrProds = '';
    console.log(this.auxServicos)
    for (let i = 0; i < this.auxServicos.length; i++) {
      if (this.auxServicos[i]) {

        if (i == 0) {
          auxDescrProds[i] = this.auxServicos[i].nomeprod + ' - ' + this.auxServicos[i].apelido;
        } else {
          auxDescrProds[i] = ' ' + this.auxServicos[i].nomeprod + ' - ' + this.auxServicos[i].apelido;
        }
      }
    }
    this.descrProds = auxDescrProds.toString()
    console.log(this.descrProds)
  }

  // verificaProfissionalRepetido() {
  //   let codfunc = 0
  //   for (let i = 0; i < this.servicoSelecionado.length; i++) {
  //     if (this.servicoSelecionado[i].codfunc === codfunc) {
  //       this.showError('Atenção', 'Escolha apenas um serviço pro profissional.');
  //       return;
  //     }
  //     codfunc = this.servicoSelecionado[i].codfunc;
  //   }
  // }



  /**METODO QUE ADICIONA OS SERVIÇOS A LISTA DO PLANO */
  cadastraListaPlano() {
    if (this.utilitariosService.validaCampo([this.servicoSelecionado, this.qtd]) || this.servicoSelecionado.length === 0) {
      console.log('É necessário preencher todos os campos.')
      this.loading = false;
      this.showError('Atenção', 'É necessário preencher todos os campos.')
      return;
    }
    console.log(this.servicoSelecionado)


    /**VERIFICA SE TEM MAIS DE UM SERVIÇOS SELECIONADOS DE UM MESMO PROFISSIONAL POR LISTA*/
    let auxServicos = []
    for (let i = 0; i < this.servicoSelecionado.length; i++) {
      for (let j = 0; j < this.servicos.length; j++) {
        if (this.servicoSelecionado[i] === this.servicos[j].codprod) {
          auxServicos.push(this.servicos[j]);
        }
      }
    }

    let auxCodfunc: any = [];

    for (let j = 0; j < auxServicos.length; j++) {
      auxCodfunc.push(auxServicos[j].codfunc);
    }

    if (auxCodfunc.some((x: any) => auxCodfunc.indexOf(x) !== auxCodfunc.lastIndexOf(x))) {
      this.loading = false;
      this.showError('Atenção', 'Escolha apenas um serviço pro profissional.');
      return;
    }





    this.planoService.preencherListaPlano(this.nulista, this.nupla, this.servicoSelecionado, this.descrProds, this.qtd, this.codUsu).subscribe((res) => {
      console.log(res);
      this.showAdcProd = false;
      this.buscaListaServicos();
    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
        // this.loading = false;

        this.alertService.alert('Algo deu errado.', 'error');
        //this.showError('Atenção', 'Algo deu errado.')
      });
  }


  selecionouServicoEdicao(listaServicos: any) {
    console.log(listaServicos);
    //TRANSFORMA A STRING DOS CODPRODS EM ARRAY PARA O MULTISELECT
    this.servicoSelecionado = listaServicos.codprods.split(",").map(Number);
    this.qtd = listaServicos.qtd;
    this.nulista = listaServicos.nulista;
    this.criaDescrProds();
    this.showAdcProd = true;
  }



  /**METODO DE TOAST */
  showError(titulo: string, mensagem: string) {
    if (screen.width < 640 || screen.height < 480) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else if (screen.width < 1024 || screen.height < 768) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else {
      this.messageService.add({
        key: 'tr',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    }
  }

  /**METODO QUE SELECIONA TODAS AS LISTAS DE ITENS PARA EXCLUSÃO */
  clicouCheckbox() {
    this.listaSelecionadaExcluir = [];
    console.log(JSON.stringify(this.selecionouTodasListaExcluir));
    console.log(this.selecionouTodasListaExcluir)
    let auxTodasListas = JSON.stringify(this.selecionouTodasListaExcluir)
    auxTodasListas = auxTodasListas.substring(0, auxTodasListas.length - 1);
    auxTodasListas = auxTodasListas.substring(1);
    auxTodasListas = JSON.parse(auxTodasListas);
    console.log(Object(auxTodasListas[0]).nulista)
    console.log(this.listaServicos)
    for (let i = 0; i < this.listaServicos.length; i++) {
      for (let j = 0; j < auxTodasListas.length; j++) {
        if (Object(auxTodasListas[j]).nulista === this.listaServicos[i].nulista) {
          this.listaSelecionadaExcluir.push(this.listaServicos[i]);
        }
      }
    }
    console.log(this.listaSelecionadaExcluir)
    console.log(this.listaSelecionadaExcluir.length + ' checkbox')
    console.log(this.listaServicos.length + ' vetor de pergunta')
  }



  /**METODO DE EXCLUSÃO DE UMA PERGUNTA */
  excluiListaItensPorNulista() {

    /**VERIFICA SE A PESSOA QUER EXCLUIR TODAS AS PERGUNTAS  */
    if (this.listaServicos.length != this.listaSelecionadaExcluir.length) {
      for (let i = 0; i < this.listaSelecionadaExcluir.length; i++) {
        this.planoService.excluiListaItensPlano(this.listaSelecionadaExcluir[i].nulista!, this.codUsu).subscribe((res) => {
          console.log(res);
          /**FILTRA PARA RETIRAR A PERGUNTA QUE FOI EXCLUIDO */
          this.listaServicos = this.listaServicos.filter((x: any) => x.nulista != res.nulista);
          console.log(this.listaServicos)
          this.listaSelecionadaExcluir = [];
        },
          (error) => {
            console.log(error)
            console.log('deu ruim')
            this.loading = false;
            this.alertService.alert('Algo deu errado.', 'error');
          })
      }
    } else {
      this.planoService.excluiTodasListasItensPlano(this.nupla, this.codUsu).subscribe((res) => {
        console.log(res);
        this.listaServicos = [];
        this.selecionouTodasListaExcluir = [];
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.loading = false;
          this.alertService.alert('Algo deu errado.', 'error');
        })
    }
  }




  /**CONFIRMA EXCLUSÃO DE UMA PERGUNTA */
  confirmaExclusaoLista() {
    console.log(this.listaSelecionadaExcluir)
    /**VERIFICA SE FOI SELECIONADO ALGUMA PERGUNTA PARA EXCLUIR E ABRIR O POPUP*/
    if (this.listaSelecionadaExcluir.length) {

      /**VERIFICA SE A PESSOA QUER EXCLUIR TODAS AS PERGUNTAS  */
      if (this.listaServicos.length != this.listaSelecionadaExcluir.length) {
        let auxNome = '';

        for (let i = 0; i < this.listaSelecionadaExcluir.length; i++) {
          if (i === 0) {
            auxNome = this.listaSelecionadaExcluir[i].pergunta!;
          } else {

            auxNome = auxNome + ', ' + this.listaSelecionadaExcluir[i].pergunta;
          }
        }

        this.confirmationService.confirm({
          message: 'Deseja mesmo excluir essa lista de itens ?',
          header: 'Excluir Pergunta',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.excluiListaItensPorNulista();
          },
          reject: () => {
          }
        });
      } else {
        this.confirmationService.confirm({
          message: 'Deseja mesmo excluir todas as listas de itens do seu plano ?',
          header: 'Excluir Pergunta',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.excluiListaItensPorNulista();
          },
          reject: () => {
          }
        });
      }
    }
  }


}
