import { LoginService } from './../login/login.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { AlertService } from 'src/alert.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-esqueceu-senha',
  templateUrl: './esqueceu-senha.component.html',
  styleUrls: ['./esqueceu-senha.component.scss'],
  providers: [MessageService],
  encapsulation: ViewEncapsulation.None
})
export class EsqueceuSenhaComponent implements OnInit {
  email: string = '';
  msgErro: string = '';
  teveErro: boolean = false;
  loading: boolean = false;

  constructor(private messageService: MessageService, private loginService: LoginService, private router: Router, private alertService: AlertService) { }

  ngOnInit(): void {
  }

  showSuccess(titulo: string, mensagem: string) {
    this.messageService.add({
      severity: 'success',
      summary: titulo,
      detail: mensagem,
      sticky: true
    });
  }


  criaCodigo() {
    this.loading = true;
    this.loginService.criaCodRecuperarConta(this.email, environment.CODTIPSEG).subscribe((res) => {
      this.loginService.setEmail(this.email);
      console.log(res);
      this.router.navigate(['/codigoRecuperacao']);
      this.loading = false;
    },
      (e) => {
        console.log(e.error.message)
        this.teveErro = true;
        const erro = e.error.message
        const testeRegex = erro.match(/[^,]*,[^,]*,  (.*\..*)/);
        if (testeRegex) {
          this.msgErro = testeRegex[1];
        }
        this.loading = false;
      })
  }


}

//^([^,]*,[^,]*),.*
// const str = 'Items Order order1';
// const matches = str.match(/Items\sOrder\s(\w+)/);
// if (matches) {
//     console.log(matches[1]); // ordem1
// }
