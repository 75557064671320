import { TraducaoService } from './../traducao.service';
import { AlertService } from './../alert.service';
import { EstabelecimentoService } from '../estabelecimento.service';
import { AgendaService } from './../agenda.service';
import { UtilitariosService } from './../assets/utilitarios.service';
import { UsuarioService } from './../usuario.service';
import { HorarioService } from './../horario.service';
import { ServicoService } from './../servico.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MenuItem, MessageService, PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core'; //TRADUÇÃO
import { count, empty, Observable, Subscription, filter } from 'rxjs';//TRADUÇÃO
import { ProfissionalService } from 'src/profissional.service';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { Profissional } from 'src/assets/profissional';
import { Servico } from 'src/assets/servico';
import { HorariosDisponiveis } from 'src/assets/horario';
import { Estabelecimento } from 'src/assets/estabelecimento';
import { Router } from '@angular/router';
import { DescontoService } from 'src/desconto.service';
import { environment } from 'src/environments/environment';
import { PlanoService } from 'src/plano.service';





@Component({
  selector: 'app-agendar',
  templateUrl: './agendar.component.html',
  styleUrls: ['./agendar.component.scss'],
  providers: [MessageService],
  encapsulation: ViewEncapsulation.None
})
export class AgendarComponent implements OnInit {
  profissional: Profissional[] = [];
  profissionalSelec: Profissional = {}
  servico: Servico[] = [];
  estabelecimento: any //Estabelecimento[] = [];

  /**MENU */
  activeIndex: number = 0;

  /**CHECKBOX */
  valorSelecionado: any[] = [];
  servicoPlanosDisponiveisSelecionado: any[] = [];
  planosDisponiveisSelecionado: any[] = [];


  /**VARIAVEIS DAS ABAS DE PLANOS */
  selecionouServicos: boolean = true;
  selecionouPlanos: boolean = false;

  selecionouMeusPlanos: boolean = true;
  selecionouPlanosDisponiveis: boolean = false;

  escondePlanos: boolean = true;  //VARIAVEL CRIADA PARA ESCONDER A ABA DE PLANOS ENQUANTO NÃO TEMOS AS REQUISIÇÕES PRONTAS

  /** */
  cupomValido: boolean = false;
  cupomInvalido: boolean = false;


  /**DATA */
  data: Date;
  ontem: Date;
  hoje: Date;

  /**DROPDOWN */
  profissionalSelecionado: any;

  /**TRADUCAO */
  lang: string = "en";
  //subscription: Subscription;

  /**DIALOG */
  mostraHorarios: boolean = false;
  mostraFilaEspera: boolean = false;

  /**HORARIOS */
  horariosDisponiveis: HorariosDisponiveis[] = [];
  //horariosDisponiveis: any[] = [];

  /**VARIAVEIS DO COMPONENT */
  nuid: any;
  codemp: number = 0;
  codcli: number = 0;
  nucup: any;
  total: number = 0;
  totalSemDesc: number = 0;
  diaSelecionado: any;
  horarioMatriz: any = [];
  auxMatriz: any = [];
  horDisponiveis: any = [];
  horarioSelecionado: any = "HH:MM";
  totalMin: number = 0;
  auxDia: any;
  codfunc: number = 0;
  codusu: string = '';
  titulo: string = '';
  descr: string = '';
  nomefunc: string = ''
  receberNotificacao: boolean = false;
  showConfirmado: boolean = false;
  descDialogConfirm: string = ''
  teveRespostaProfissional: boolean = false;
  cupom: any;
  listaProdutos: string = '';
  faixaHorariosFuncionario: any;
  horariosFuncionario: any;
  horariosFuncionarioFiltrado: any;
  diaSemana: any;

  horarioMatrizFilaEspera: any = [];
  auxMatrizFilaEspera: any = [];


  /**VARIAVEIS DA EMPRESA */
  nomeemp: string = ' ';
  logradouro: string = ' ';
  numero: string = ' ';
  bairro: string = ' ';
  nomecid: string = ' ';
  telefone: string = ' ';
  complemento: string = ' ';
  cabReagendamento: any;
  habilitacupom: string = '';

  temDesconto: boolean = false;
  temDescontoCarrinho: boolean = false;
  temDescontoCupom: boolean = false;
  desconto: number = 0;
  cupomUtilizado: string = '';
  totalComDesconto: number = 0;
  totalGeral: number = 0;
  totalGeralComDesconto: number = 0;
  descontoAcumulado: number = 0; //variavel para somar o valor de todos os descontos
  codprods: string = '';
  horarioSelecionadoFila: string = '';

  planos: any[] = [];
  servicosPlanos: any[] = [];

  /**PLANOS DISPONIVEIS */
  planosDisponiveis: any[] = [];
  servicosPlanosDisponiveis: any[] = [];
  nuplaServico: number = 0;

  /**PLANOS COMPRADOS */
  meusPlanos: any[] = [];
  servicosMeusPlanos: any[] = [];
  descrProds: string = '';

  constructor(public translate: TranslateService, public primeNGConfig: PrimeNGConfig, private profissionalService: ProfissionalService, private servicoService: ServicoService,
    private decodedTokenService: DecodedTokenService, private horarioService: HorarioService, private usuarioService: UsuarioService, private planoService: PlanoService,
    private utilitariosService: UtilitariosService, private agendaService: AgendaService, private estabelecimentoService: EstabelecimentoService, private descontoService: DescontoService,
    private messageService: MessageService, private router: Router, private alertService: AlertService, private traducaoService: TraducaoService) {

    /** NÃO PERMITE QUE DATAS ANTERIORES A HOJE SEJAM ESCOLHIDAS */
    this.data = new Date();
    this.ontem = new Date(this.data);
    this.ontem.setDate(this.data.getDate());
    this.hoje = new Date();

    /**TRADUÇÃO */
    this.traducaoService.changeLang();


    this.planos = [
      {
        'nome': 'Pacote Premium', 'descr': 'Pacote premium é a melhor escolha para quem quer deixar a barba e o cabelo sempre em estilo. Pacote premium é a melhor escolha para quem quer deixar a barba e o cabelo sempre em estilo.',
        'data': '25/08/2023 13:30', 'categoria': '2 Barbas + 1 corte de cabelo', 'preco': 110, 'expande': false
      },
      {
        'nome': 'Pacote Premium2', 'descr': 'Pacote premium é a melhor escolha para quem quer deixar a barba e o cabelo sempre em estilo. Pacote premium é a melhor escolha para quem quer deixar a barba e o cabelo sempre em estilo.',
        'data': '25/08/2023 13:30', 'categoria': '2 Barbas + 1 corte de cabelo', 'preco': 110, 'expande': false
      },
    ]

    this.servicosPlanos = [
      {
        'nomeprod': 'Barba', 'tempMin': 10, 'descrprod': 'Bigode, desenho', 'statusUso': 1
      },
      {
        'nomeprod': 'Barba', 'tempMin': 10, 'descrprod': 'Bigode, desenho', 'statusUso': 2
      },
      {
        'nomeprod': 'Corte simples', 'tempMin': 10, 'descrprod': 'Corte Máquina + Tesoura simples', 'statusUso': 3
      },
    ]
  }

  ngOnInit(): void {
    this.recuperaCodCli();
    this.recuperaCodUsu();
    this.recuperaInfo();
    // this.recuperaCabecalho();

  }


  recuperaInfo() {
    this.teveRespostaProfissional = true;

    this.usuarioService.getPerfil(this.codusu, this.codusu).subscribe((res) => {
      this.cabReagendamento = this.agendaService.getCabAgenda();

      /**VERIFICA SE TEM UM CABEÇALHO PRA REAGENDAMENTO */
      if (this.cabReagendamento != undefined) {
        this.nuid = this.cabReagendamento.nuid;
        console.log(this.nuid)
      }

      /**SE TIVER UM NUID FAZ O PROCESSO DE REAGENDAMENTO */
      if (this.nuid) {
        this.codemp = this.cabReagendamento.codemp;
        this.nucup = null;
        this.descontoAcumulado = 0;
        console.log(this.nucup, this.descontoAcumulado)
        console.log('wgrwe↑')
      }
      /**SE NÃO, PEGA O CODEMP EM QUE USUARIO ESTÁ FILIADO EM SEU PERFIL PARA FAZER A BUSCA DE BARBEIROS E INICIAR UM NOVO AGENDAMENTO */
      else {
        this.codemp = Object(res)[0].codemp;
        console.log(this.codemp)
      }

      this.descr = Object(res)[0].nomeparc; //nome do cliente que vai para o cabeçalho do pedido
      this.buscarProfissionalPorCodEmpresa();

      //this.buscarEstabelecimentoPorCodEmp();
    },
      (error) => {
        console.log(error.message)
        console.log('deu ruim')
        this.teveRespostaProfissional = false;
        this.alertService.alert('Algo deu errado.', 'error');
        //this.showError('Atenção', 'Algo deu errado.')
      });
  }


  recuperaCodCli() {
    this.codcli = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODCLI;
  }

  recuperaCodUsu() {
    this.codusu = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }


  buscarEstabelecimentoPorCodEmp() {
    this.estabelecimentoService.contatoEstabelecimento(this.codemp.toString(), this.codusu).subscribe((res) => {
      this.recuperaCabecalho();
      this.nomeemp = Object(res)[0].nomeemp;
      this.logradouro = Object(res)[0].logradouro;
      this.complemento = Object(res)[0].complemento;
      if (Object(res)[0].numero) {
        this.numero = ', ' + Object(res)[0].numero;
      }
      this.bairro = Object(res)[0].bairro;
      this.nomecid = Object(res)[0].nomecid;
      this.telefone = Object(res)[0].telefone;
      this.habilitacupom = Object(res)[0].habilitacupom;
    });
  }

  buscarProfissionalPorCodEmpresa() {

    setTimeout(() => {
      this.profissionalService.buscarProfissionalporCodEmp(this.codemp, this.codusu).subscribe((res) => {
        this.buscarEstabelecimentoPorCodEmp();
        //this.teveRespostaProfissional = false;
        console.log('entrei no busca profissional')
        this.profissional = res;

        for (let i = 0; i < this.profissional.length; i++) {
          if (this.profissional[i].filename !== null) {
            this.profissional[i].filename = environment.imgProfissional + this.profissional[i].filename;
          }
        }
      },
        (error) => {
          console.log(error.message)
          console.log('deu ruim')
          this.teveRespostaProfissional = false;
        })
    }, 500);
  }


  buscarServicoPorCodFuncionario() {

    console.log(this.profissional.filter(x => x.codfunc === this.profissionalSelecionado.codfunc))
    var auxiNomeFunc = this.profissional.filter(x => x.codfunc === this.profissionalSelecionado.codfunc);
    this.nomefunc = auxiNomeFunc[0].apelido!

    this.descontoAcumulado = 0;
    this.servicoService.buscarServicoPorCodFunc(this.profissionalSelecionado.codfunc, this.codusu).subscribe((res) => {
      console.log(res);
      this.servico = res;
      this.valorSelecionado = [];
      /**ATRIBUI DESCONTO AOS PRODUTOS POR PROFISSIONAL*/

      if (this.profissionalSelecionado.tipdesc === 'R') {
        if (this.profissionalSelecionado.desc > 0) {
          this.temDesconto = true;
        }
        for (let i = 0; i < this.servico.length; i++) {
          this.servico[i].valorDesconto = this.profissionalSelecionado.desc;
          this.servico[i].precoAposDesconto = this.servico[i].preco! - this.profissionalSelecionado.desc;
        }
      }

      if (this.profissionalSelecionado.tipdesc === 'P') {
        if (this.profissionalSelecionado.desc > 0) {
          this.temDesconto = true;
        }
        for (let i = 0; i < this.servico.length; i++) {
          this.servico[i].valorDesconto = (this.servico[i].preco! * this.profissionalSelecionado.desc) / 100;
          this.servico[i].precoAposDesconto = this.servico[i].preco! - this.servico[i].valorDesconto!;
          console.log(this.servico[i].valorDesconto);
          console.log(this.servico[i].precoAposDesconto);
        }
      }


      /**ATRIBUI DESCONTO AOS PRODUTOS POR PRODUTO*/

      for (let i = 0; i < this.servico.length; i++) {
        this.servico[i].minutos = this.tempoEmMinutos(this.servico[i].tempom);
        if (this.servico[i].tipdesc === 'R') {

          /**SE JÁ TIVER DESCONTO POR PROFISSIONAL */
          if (this.profissionalSelecionado.tipdesc === 'P' || this.profissionalSelecionado.tipdesc === 'R') {
            this.servico[i].precoAposDesconto = this.servico[i].precoAposDesconto! - this.servico[i].desc!;
            this.servico[i].valorDesconto! += this.servico[i].desc!;
            this.servico[i].temdesconto = true;
          } else {
            this.servico[i].temdesconto = true;
            this.servico[i].precoAposDesconto = this.servico[i].preco! - this.servico[i].desc!;
            this.servico[i].valorDesconto = this.servico[i].desc;
          }

          /**SE NÃO TIVER DESCONTO NÃO MOSTRAR O CAMPO DE VALOR DESCONTADO */
          if (this.servico[i].desc! === 0) {
            this.servico[i].temdesconto = false;
          }
        }
      }


      for (let i = 0; i < this.servico.length; i++) {
        if (this.servico[i].tipdesc === 'P') {

          /**SE JÁ TIVER DESCONTO POR PROFISSIONAL */
          if (this.profissionalSelecionado.tipdesc === 'P' || this.profissionalSelecionado.tipdesc === 'R') {
            this.servico[i].valorDesconto! += (this.servico[i].precoAposDesconto! * this.servico[i].desc!) / 100;
            this.servico[i].precoAposDesconto = this.servico[i].precoAposDesconto! - ((this.servico[i].precoAposDesconto! * this.servico[i].desc!) / 100);
            this.servico[i].temdesconto = true;
          } else {
            this.servico[i].temdesconto = true;
            this.servico[i].precoAposDesconto = this.servico[i].preco! - this.servico[i].valorDesconto!;
            this.servico[i].valorDesconto = (this.servico[i].preco! * this.servico[i].desc!) / 100;
          }

          /**SE NÃO TIVER DESCONTO NÃO MOSTRAR O CAMPO DE VALOR' DESCONTADO */
          if (this.servico[i].desc! === 0) {
            this.servico[i].temdesconto = false;
          }
        }
      }



      /********************************************************************************
      *                                     REAGENDADO                               *
      ********************************************************************************/
      if (this.nuid) {
        let tempCheckbox = JSON.parse(this.cabReagendamento.json).itens
        for (let i = 0; i < this.servico.length; i++) {
          for (let j = 0; j < tempCheckbox.length; j++) {
            console.log(this.valorSelecionado)
            if (tempCheckbox[j].codprod == this.servico[i].codprod && tempCheckbox[j].nupla == undefined && tempCheckbox[j].nuplac == undefined && tempCheckbox[j].seq == undefined) {
              this.valorSelecionado.push(this.servico[i]);
            }
          }
        }
      }
      console.log(this.valorSelecionado)

      var minutos: any;
      var horas: any;

      for (let i = 0; i < this.servico.length; i++) {
        minutos = (Number(this.servico[i].tempom!.substr(3, 2))) + (Number(this.servico[i].tempom!.substr(0, 2)) * 60)
        horas = Math.trunc(minutos / 60);
        minutos = ((minutos / 60) - horas) * 60;
        minutos = minutos.toFixed(0);
        this.servico[i].tempMin = minutos.toString();

      }

      this.criaTitulo();
      this.somaTotal();
    });
  }

  buscaHorarioPorCodFuncEDia(dia: any) {
    if (this.nuid) {
      console.log('entrei no reagendamento')
      this.horarioService.buscaHorarioPorCodFuncEDiaReagendamento(this.profissionalSelecionado.codfunc, dia, this.nuid, this.codusu).subscribe((res) => {
        this.auxMatriz = [];
        this.horDisponiveis = []
        this.horariosDisponiveis = res;
        this.verificaHorariosEncaixe();
        for (var i in this.horDisponiveis) {
          this.auxMatriz.push([i, this.horDisponiveis[i]])
        }
        this.fazMatriz();
      });
    } else {
      this.horarioService.buscaHorarioPorCodFuncEDia(this.profissionalSelecionado.codfunc, dia, this.codusu).subscribe((res) => {
        console.log('entrei no agendamento')
        this.auxMatriz = [];
        this.horDisponiveis = []
        this.horariosDisponiveis = res;

        /**ROTINA PARA RETIRAR OS HORARIOS INFORMADOS NA TGFHOR*/
        if (this.profissionalSelecionado.restringehor != null) {
          let removeHorarios = this.profissionalSelecionado.restringehor.split(",").map((x: any) => x.trim());
          this.horariosDisponiveis = this.horariosDisponiveis.filter((x) => !removeHorarios.some((y: any) => x.horario.endsWith(y)))
        }

        this.verificaHorariosEncaixe();
        for (var i in this.horDisponiveis) {
          this.auxMatriz.push([i, this.horDisponiveis[i]])
        }
        this.fazMatriz();
      });
    }
  }



  /**FAZ MATRIZ DE HORARIOS PARA AGENDAMENTOS */
  fazMatriz() {
    this.horarioMatriz = []; //    this.horarioMatriz = this.horDisponiveis;
    const linhas = 4;
    for (let i = 0; i < this.auxMatriz.length; i += linhas) {
      this.horarioMatriz.push(this.auxMatriz.slice(i, i + linhas));
    }
  }

  /**FAZ MATRIZ DE HORARIOS DA LISTA DE ESPERA */
  fazMatrizFilaEspera() {
    this.horarioMatrizFilaEspera = []; //    this.horarioMatriz = this.horDisponiveis;
    const linhas = 4;
    for (let i = 0; i < this.auxMatrizFilaEspera.length; i += linhas) {
      this.horarioMatrizFilaEspera.push(this.auxMatrizFilaEspera.slice(i, i + linhas));
    }
  }


  selecionouDia() {
    this.horarioMatriz = [];

    console.log(this.diaSelecionado)
    //this.auxDia = this.diaSelecionado.getUTCFullYear() + '-' + (this.diaSelecionado.getUTCMonth() + 1) + '-' + this.diaSelecionado.getUTCDate();
    //this.auxDia = this.diaSelecionado.getFullYear() + '-' + ('0' + (this.diaSelecionado.getMonth() + 1)).slice(-2) + '-' + ('0' + this.diaSelecionado.getDate()).slice(-2);
    this.auxDia = this.utilitariosService.ajustaDataBanco(this.diaSelecionado)
    console.log(this.auxDia)

    this.verificaDiaSemana();
    this.buscaHorarioPorCodFuncEDia(this.auxDia);

    this.horarioSelecionado = "HH:MM";


  }

  /**TRADUÇÃO */
  // changeLang() {
  //   this.translate.use('pt');
  // }

  /**METODOS DO MENU */
  irPagina2() {
    console.log(this.profissionalSelecionado.codfunc)
    if (this.profissionalSelecionado.codfunc === undefined) {
      this.showError('Atenção', 'Selecione um profissional.')
      //this.alertService.showError1('Atenção', 'Selecione um profissional.');
    }
    else {
      this.activeIndex = this.activeIndex + 1;
    }
  }

  irPagina3() {
    if (this.verificaPlanoRepetido()) {
      return;
    }

    if (this.verificaPlano()) {
      // this.showError('Atenção', 'Escolha o plano que contenha serviço selecionado.');
      return;
    }

    if (this.verificaServico()) {
      // this.showError('Atenção', 'Escolha pelo menos um serviço do plano selecionado.');
      return;
    }


    // let count = 0;
    // for (let i = 0; i < this.valorSelecionado.length; i++) {
    //   for (let j = 0; j < this.servicosMeusPlanos.length; j++) {
    //     if (this.valorSelecionado[i].seq === this.servicosMeusPlanos[j].seq && this.valorSelecionado[i].codprod === this.servicosMeusPlanos[j].codprod ) {
    //       count++
    //     }
    //     if (count > 1) {
    //       this.showError('Atenção', 'Não é permitido agendar serviços repetidos.');
    //       return;
    //     }
    //   }
    // }

    if (!this.valorSelecionado.length) {
      this.showError('Atenção', 'Selecione um serviço.')
    }
    else {
      this.activeIndex = this.activeIndex + 1;
      if (!this.nuid) {
        this.diaSelecionado = null;
        this.horarioSelecionado = "HH:MM";
      }
    }
  }



  formataData(data: any) {
    const dt = new Date(data);
    const dia = dt.getUTCDate().toString().padStart(2, '0');
    const mes = (dt.getUTCMonth() + 1).toString().padStart(2, '0');
    const ano = dt.getUTCFullYear().toString();

    return `${dia}/${mes}/${ano}`;
  }



  irPagina4() {

    //VALIDAÇÃO DE DATA DE EXPIRAÇÃO DO PLANO
    for (let i = 0; i < this.meusPlanos.length; i++) {
      for (let j = 0; j < this.valorSelecionado.length; j++) {
        if (this.meusPlanos[i].nupla === this.valorSelecionado[j].nupla) {
          let limitePlano = new Date(this.meusPlanos[i].dhexpiracao).toISOString().split('T')[0];
          if ((this.auxDia > limitePlano!)) {
            limitePlano = this.formataData(limitePlano)
            this.showError('Atenção', `Sua data de agendamento está além da cobertura do ${this.meusPlanos[i].nome}, que se encerra em ${limitePlano}.`)
            return;
          }
        }
      }
    }

    if (this.diaSelecionado != undefined && this.horarioSelecionado === "HH:MM") {
      this.showError('Atenção', 'Selecione um horário.')

    }
    else if (this.diaSelecionado === null && this.horarioSelecionado === "HH:MM") {
      this.showError('Atenção', 'Selecione um dia e um horário.')
    } else {
      this.activeIndex = this.activeIndex + 1;
    }
    this.cupom = '';
    this.cupomUtilizado = '';
    this.resetaCupom();
  }

  /**ZERA O CUPOM UTILIZADO */
  resetaCupom() {
    this.desconto = 0;

    this.nucup = null;
    this.somaTotal();
    this.totalGeralComDesconto = this.total;
    //this.totalGeral = this.total;

    this.descontoTotal();

    /**DEIXA FALSO AS VARIAVEIS DA MSG DO CUPOM */
    this.cupomValido = false;
    this.cupomInvalido = false;
  }

  voltarPagina() {
    this.activeIndex = this.activeIndex - 1;
    if (this.activeIndex === 1) {
      this.total += this.desconto;
      this.somaTotal();
    }
  }

  /**DIALOG */
  horarios() {
    this.mostraHorarios = true;
  }


  somaTotal() {
    this.total = 0;
    //this.totalComDesconto = 0;
    this.descontoAcumulado = 0;

    // if (this.servicoPlanosDisponiveisSelecionado.length > 0){
    //   for (let j = 0; j < this.servicoPlanosDisponiveisSelecionado.length; j++) {
    //     this.valorSelecionado.push(this.servicoPlanosDisponiveisSelecionado[j])
    //   }
    // }

    console.log(this.valorSelecionado)

    /**FAZ O CALCULO DO TOTAL E TOTAL COM DESCONTO */
    for (let i = 0; i < this.valorSelecionado.length; i++) {
      this.total += this.valorSelecionado[i].preco!;
      if (this.valorSelecionado[i].valorDesconto! > 0) {
        this.descontoAcumulado += this.valorSelecionado[i].valorDesconto!;
        console.log(this.descontoAcumulado)
      }
    }
    this.somaPlano();
    this.totalSemDesc = this.total;
    this.totalGeral = this.total;
    this.total = this.total - this.descontoAcumulado;

    /**SE O PRODUTO TIVER DESCONTO ENTÃO APARECE O TOTAL COM DESCONTO */
    if (this.descontoAcumulado > 0) {
      this.temDescontoCarrinho = true;
    } else {
      this.temDescontoCarrinho = false;
    }


    this.totalMin = 0;
    for (let i = 0; i < this.valorSelecionado.length; i++) {
      console.log(Number(this.valorSelecionado[i].tempom!.substr(0, 2)) * 60 + 'HORAS') //HORAS
      console.log(Number(this.valorSelecionado[i].tempom!.substr(3, 2)) + 'MINUTOS') //MINUTOS
      this.totalMin += (Number(this.valorSelecionado[i].tempom!.substr(0, 2)) * 60) + Number(this.valorSelecionado[i].tempom!.substr(3, 2))
    }
    console.log(this.totalMin)
    console.log(this.total)

    // this.somaPlano();
  }


  /**METODO QUE SOMA O VALOR DO PLANO NA COMANDA */
  somaPlano() {
    if (this.planosDisponiveisSelecionado.length > 0) {
      for (let i = 0; i < this.planosDisponiveisSelecionado.length; i++) {
        this.total += this.planosDisponiveisSelecionado[i].preco!;
      }
    }
    console.log(this.total)
  }

  /*****************************************************************************************
   * METODO QUE VERIFICA O VETOR DE SERVIÇOS SELECIONADOS PARA DESABILITAR O CHECKBOX CASO O
   * SERVIÇO TENHA SIDO ADICIONADO PELA ABA DE 'Planos' OU DE 'Serviços'
   ****************************************************************************************/
  checaServico(servico: any, opc: number) {
    var filtroServico;
    filtroServico = this.valorSelecionado.filter((x: any) => x.codprod === servico.codprod);

    if (opc === 1) {
      if (filtroServico.length > 0) {


        /**DESABILITA CHECKBOX PLANOS DISPONIVEIS*/
        for (let i = 0; i < this.servicosPlanosDisponiveis.length; i++) {
          if (this.servicosPlanosDisponiveis[i].codprod === filtroServico[0].codprod) {
            this.servicosPlanosDisponiveis[i].desabilita = true;
          }
        }

        /**DESABILITA CHECKBOX MEUS PLANOS */
        for (let i = 0; i < this.servicosMeusPlanos.length; i++) {
          if (this.servicosMeusPlanos[i].codprod === filtroServico[0].codprod) {
            this.servicosMeusPlanos[i].desabilita = true;
          }
        }
      }
      else {

        /**LIBERA OS CHECKBOX PLANOS DISPONIVEIS*/
        for (let i = 0; i < this.servicosPlanosDisponiveis.length; i++) {
          if (this.servicosPlanosDisponiveis[i].codprod === servico.codprod) {
            this.servicosPlanosDisponiveis[i].desabilita = false;
          }
        }

        /**LIBERA CHECKBOX MEUS PLANOS */
        for (let i = 0; i < this.servicosMeusPlanos.length; i++) {
          if (this.servicosMeusPlanos[i].codprod === servico.codprod) {
            this.servicosMeusPlanos[i].desabilita = false;
          }
        }
      }

    } else {
      if (filtroServico.length > 0) {

        /**DESABILITA O CHECKBOX DE TODOS OS SERVIÇOS DE PLANOS QUE TENHAM ESSE MESMO PRODUTO */
        for (let i = 0; i < this.servicosPlanosDisponiveis.length; i++) {
          if (this.servicosPlanosDisponiveis[i].nupla != filtroServico[0].nupla && this.servicosPlanosDisponiveis[i].codprod === filtroServico[0].codprod) {
            this.servicosPlanosDisponiveis[i].desabilita = true;
          }
        }

        /**DESABILITA CHECKBOX MEUS PLANOS */
        for (let i = 0; i < this.servicosMeusPlanos.length; i++) {
          if (this.servicosMeusPlanos[i].codprod === filtroServico[0].codprod && this.servicosMeusPlanos[i].seq != filtroServico[0].seq) {
            this.servicosMeusPlanos[i].desabilita = true;
          }
        }

        /**DESABILITA SERVICOS*/
        for (let i = 0; i < this.servico.length; i++) {
          if (this.servico[i].codprod === filtroServico[0].codprod) {
            this.servico[i].desabilita = true;
          }
        }
      }
      else {

        /**LIBERA OS CHECKBOX SERVIÇOS*/
        for (let i = 0; i < this.servico.length; i++) {
          if (this.servico[i].codprod === servico.codprod) {
            this.servico[i].desabilita = false;
          }
        }

        /**LIBERA OS CHECKBOX PLANOS DISPONIVEIS*/
        for (let i = 0; i < this.servicosPlanosDisponiveis.length; i++) {
          if (this.servicosPlanosDisponiveis[i].codprod === servico.codprod) {
            this.servicosPlanosDisponiveis[i].desabilita = false;
          }
        }

        /**LIBERA CHECKBOX MEUS PLANOS */
        for (let i = 0; i < this.servicosMeusPlanos.length; i++) {
          if (this.servicosMeusPlanos[i].codprod === servico.codprod && this.servicosMeusPlanos[i].status != "USADO") {
            this.servicosMeusPlanos[i].desabilita = false;
          }
        }

      }
    }

  }

  /**METODO QUE VERIFICA SE TEM ALGUM SERVIÇO SEM PLANO SELECIONADO*/
  verificaPlano() {
    let auxServicosPlanos = this.valorSelecionado.filter(x => x.nupla !== undefined && x.nuplac === undefined);
    let auxServicosSemPlanos = []
    console.log(this.valorSelecionado)

    for (let i = 0; i < this.planosDisponiveisSelecionado.length; i++) {
      auxServicosSemPlanos = auxServicosPlanos.filter(x => x.nupla != this.planosDisponiveisSelecionado[i].nupla);
      auxServicosPlanos = auxServicosSemPlanos;
    }

    if (auxServicosSemPlanos.length > 0 || (this.planosDisponiveisSelecionado.length === 0 && auxServicosPlanos.length > 0)) {
      this.showError('Atenção', `Selecione o plano que contempla o serviço ${auxServicosPlanos[0].nomeprod}`);
      return true;
    } else {
      return false;
    }
  }

  /**METODO QUE VERIFICA SE ESTÁ TENTANDO ADQUIRIR UM PLANO QUE JÁ ESTEJA ATIVO NO PERFIL DO USUARIO*/
  verificaPlanoRepetido() {
    let count = 0;
    for (let i = 0; i < this.planosDisponiveisSelecionado.length; i++) {
      for (let j = 0; j < this.meusPlanos.length; j++) {
        if (this.planosDisponiveisSelecionado[i].nupla === this.meusPlanos[j].nupla) {
          this.showError('Atenção', `Você já possui o plano ${this.planosDisponiveisSelecionado[i].nome}!`);
          count = 1
        } else {
          count = 0;
        }
      }
    }

    if (count === 1) {
      return true;
    } else {
      return false;
    }
  }


  /**METODO QUE VERIFICA SE TEM ALGUM PLANO SEM SERVIÇO SELECIONADO */
  verificaServico() {
    let auxPlanos = this.planosDisponiveisSelecionado;
    let auxServicos = this.valorSelecionado.filter(x => x.nupla !== undefined);
    let auxPlanosSemServicos = []



    for (let i = 0; i < auxServicos.length; i++) {
      auxPlanosSemServicos = auxPlanos.filter(x => (x.nupla != auxServicos[i].nupla) && (auxServicos[i].nupla != undefined));
      auxPlanos = auxPlanosSemServicos;
    }

    if (auxPlanosSemServicos.length > 0 || (auxServicos.length === 0 && auxPlanos.length > 0)) {
      this.showError('Atenção', `Escolha pelo menos um serviço do plano ${auxPlanos[0].nome}.`);
      return true;
    } else {
      return false;
    }
  }


  agendaHorario(horario: any) {
    this.mostraHorarios = false;
    this.horarioSelecionado = horario;
  }


  /**METODO QUE ADICIONA O PLANO NO AGENDAMENTO */
  adicionaPlano(servicoPlano: any) {
    let auxPlano = []
    let indexPlano = 0;
    let estaNoVetor = [];

    /**VERIFICA SE O SERVIÇO SELECIONADO AINDA ESTÁ NO VETOR */
    estaNoVetor = this.valorSelecionado.filter(x => x.codprod === servicoPlano.codprod)


    //SE NÃO TIVER NENHUM PLANO SELECIONADO
    if (this.planosDisponiveisSelecionado.length === 0 && estaNoVetor.length > 0) {
      indexPlano = this.planosDisponiveis.findIndex((x) => x.nupla === servicoPlano.nupla);
      auxPlano.push(this.planosDisponiveis[indexPlano])
      this.planosDisponiveisSelecionado = auxPlano
    }

    /**VERIFICA SE TEM ALGUM SERVIÇO DE PLANO NO VETOR */
    let indexServicoPlano = 0;
    indexServicoPlano = this.valorSelecionado.findIndex((x) => x.nupla);
    if (indexServicoPlano == -1) {
      this.planosDisponiveisSelecionado = [];
      return;
    }

    /**VERIFICA SE TEM MAIS ALGUM SERVIÇO DE PLANO NO VETOR */
    let indexServicosPlano = [];
    indexServicosPlano = this.valorSelecionado.filter((x) => x.nupla === servicoPlano.nupla);
    console.log(indexServicosPlano)
    if (indexServicosPlano.length === 0) {
      this.planosDisponiveisSelecionado = this.planosDisponiveisSelecionado.filter(x => x.nupla != servicoPlano.nupla);
      return;
    }

    //SE JÁ TIVER ALGUM PLANO SELECIONADO
    /**VERIFICA SE NO VETOR DE PLANOS SELECIONADOS, JÁ TEM O PLANO */
    auxPlano = this.planosDisponiveisSelecionado.filter(x => x.nupla === servicoPlano.nupla);

    if (auxPlano.length > 0) {
      return;
    }
    /**SE NÃO TIVER: ADICIONA ELE AO VETOR */
    else {
      indexPlano = this.planosDisponiveis.findIndex((x) => x.nupla === servicoPlano.nupla);

      for (let i = 0; i < this.planosDisponiveisSelecionado.length; i++) {
        auxPlano.push(this.planosDisponiveisSelecionado[i])
      }

      auxPlano.push(this.planosDisponiveis[indexPlano])
      this.planosDisponiveisSelecionado = auxPlano
    }
  }


  /**METODO QUE VERIFICA OS HORARIOS DISPONIVEIS PARA O SERVIÇO */
  verificaHorariosEncaixe() {
    var bloquinhos = this.totalMin / 15;
    var horAux = [];
    var horarioCandidato: any;
    var countDisp = 0;
    if (bloquinhos - Math.trunc(bloquinhos) > 0) {
      bloquinhos = Math.trunc(bloquinhos) + 1;
    }
    // console.log('bloquinhos: ' + bloquinhos)
    // console.log('HOR DISPONIVEL: ')
    // console.log(this.horariosDisponiveis)
    for (let i = 0; i < this.horariosDisponiveis.length; i++) {

      horarioCandidato = this.horariosDisponiveis[i].horario
      // console.log('FORA ******* do for: ')
      // console.log(horarioCandidato)
      for (let j = 0; j < bloquinhos; j++) {
        //horAux.push(horarioCandidato)
        var auxFiltro = this.horariosDisponiveis.filter((x: any) => (x.horario).toString() === horarioCandidato)
        countDisp += auxFiltro.length;
        horarioCandidato = this.utilitariosService.somaMinutos(horarioCandidato, 15);
        // console.log('dentro do for: ')
        // console.log(horarioCandidato)
      }
      // console.log('bloquinhos:  **********' + bloquinhos)
      // console.log('countDisp:  **********' + countDisp)
      if (countDisp === bloquinhos) {
        this.horDisponiveis.push(this.horariosDisponiveis[i]);
        // console.log('CRIANDO O HORARIO QUE DEU BOM +++++: ')
        // console.log(this.horariosDisponiveis[i])
      }
      countDisp = 0;
    }
  }

  criaTitulo() {
    var auxTitulo = []
    console.log(this.valorSelecionado)
    for (let i = 0; i < this.valorSelecionado.length; i++) {
      if (this.valorSelecionado[i]) {
        if (i == 0) {
          auxTitulo[i] = this.valorSelecionado[i].nomeprod;
        } else {
          auxTitulo[i] = ' ' + this.valorSelecionado[i].nomeprod;
        }
      }
    }


    /**ADICIONA A DESCRIÇÃO DOS PLANOS */
    if (this.planosDisponiveisSelecionado.length > 0) {
      for (let i = 0; i < this.planosDisponiveisSelecionado.length; i++) {
        if (this.planosDisponiveisSelecionado[i]) {

          if (i == 0 && this.valorSelecionado.length === 0) {
            auxTitulo[i] = this.planosDisponiveisSelecionado[i].nome;
          } else {
            auxTitulo[this.valorSelecionado.length + i] = ' ' + this.planosDisponiveisSelecionado[i].nome;
          }
        }
      }
    }


    this.titulo = auxTitulo.toString()
    console.log(this.titulo)
  }

  confirmaAgdRgd() {
    this.router.navigate(['/minhaAgenda']);
  }

  criarAgenda() {
    //se não existir um cabeçalho para recuperar eu atribuo um vazio
    // let vazio = {}
    // if (this.cabReagendamento === undefined) {
    //   this.cabReagendamento === vazio;
    // }

    /**checkbox*/
    let auxCheckbox;
    if (this.receberNotificacao === true) {
      auxCheckbox = 'S'
    } else {
      auxCheckbox = 'N'
    }
    console.log(this.cabReagendamento + ' NUID DO CABEÇALHO A SER REAG')

    /**CASO O USUÁRIO NÃO MUDE O DIA DO REAGENDAMENTO*/
    if (this.auxDia === undefined) {
      this.auxDia = this.diaSelecionado.getFullYear() + '-' + ('0' + (this.diaSelecionado.getMonth() + 1)).slice(-2) + '-' + ('0' + this.diaSelecionado.getDate()).slice(-2);
    }

    console.log(this.descontoAcumulado)
    console.log(this.nucup)



    console.log(this.total)
    console.log('↑ total final')

    /********************************************************************************
     *                                     REAGENDADO                               *
     ********************************************************************************/
    if (this.nuid) {


      for (let i = 0; i < this.planosDisponiveisSelecionado.length; i++) {
        let auxCodprods = [];
        for (let j = 0; j < this.valorSelecionado.length; j++) {
          if (this.valorSelecionado[j].nupla !== undefined) {
            if (this.planosDisponiveisSelecionado[i].nupla === this.valorSelecionado[j].nupla) {
              auxCodprods.push(this.valorSelecionado[j].codprod)
            }
          }
        }
        this.planosDisponiveisSelecionado[i].codprods = auxCodprods;
      }


      console.log('REAGENDADO')
      this.agendaService.refazerAgenda(this.nuid, this.horarioSelecionado, this.utilitariosService.somaMinutos(this.horarioSelecionado, this.totalMin),
        this.auxDia, this.profissionalSelecionado.codfunc, this.codusu, this.codemp, Number(this.total.toFixed(2)), 'AGENDADO', auxCheckbox, this.valorSelecionado,
        this.titulo, this.descr, this.codusu, this.descontoAcumulado, this.nucup, this.planosDisponiveisSelecionado).
        subscribe((res) => {
          console.log(res);
          // this.descDialogConfirm = 'Seu reagendamento foi realizado com sucesso!'
          // this.showConfirmado = true;

          this.alertService.alert('Seu reagendamento foi realizado com sucesso!', 'success');
          setTimeout(() => {
            this.router.navigate(['/minhaAgenda']);
          }, 1500)
        },
          (error) => {
            console.log(error)
            console.log('deu ruim')
            this.alertService.alert('Algo deu errado.', 'error');
            //this.showError('Atenção', 'Algo deu errado.')
          });

    } else {

      /********************************************************************************
      *                                     AGENDADO                                  *
     ********************************************************************************/
      console.log('AGENDADO')


      //ADICIONA OS SERVIÇOS DO PLANO A SEREM REALIZADOS, NO VETOR DE PLANO
      for (let i = 0; i < this.planosDisponiveisSelecionado.length; i++) {
        let auxCodprods = [];
        for (let j = 0; j < this.valorSelecionado.length; j++) {
          if (this.valorSelecionado[j].nupla !== undefined) {
            if (this.planosDisponiveisSelecionado[i].nupla === this.valorSelecionado[j].nupla) {
              auxCodprods.push(this.valorSelecionado[j].codprod)
            }
          }
        }
        this.planosDisponiveisSelecionado[i].codprods = auxCodprods;
      }


      this.agendaService.createAgenda(this.horarioSelecionado, this.utilitariosService.somaMinutos(this.horarioSelecionado, this.totalMin),
        this.auxDia, this.profissionalSelecionado.codfunc, this.codusu, this.codemp, Number(this.total.toFixed(2)), 'AGENDADO', auxCheckbox, this.valorSelecionado,
        this.titulo, this.descr, this.codusu, this.descontoAcumulado, this.nucup, this.planosDisponiveisSelecionado).
        subscribe((res) => {
          // this.descDialogConfirm = 'Seu agendamento foi realizado com sucesso!'
          // this.showConfirmado = true;
          this.alertService.alert('Seu agendamento foi realizado com sucesso!', 'success');
          console.log(this.descontoAcumulado)
          setTimeout(() => {
            this.router.navigate(['/minhaAgenda']);
          }, 1500)
          console.log(res);
        },
          (error) => {
            console.log(error)
            console.log('deu ruim')
            this.alertService.alert('Algo deu errado.', 'error');
            //this.showError('Atenção', 'Algo deu errado.')
          });
    }

  }




  /**METODO DE TOAST */
  showError(titulo: string, mensagem: string) {
    if (screen.width < 640 || screen.height < 480) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else if (screen.width < 1024 || screen.height < 768) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else {
      this.messageService.add({
        key: 'tr',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    }
  }

  showSuccess(titulo: string, mensagem: string) {
    if (screen.width < 640 || screen.height < 480) {
      this.messageService.add({
        key: 'bc',
        severity: 'success',
        summary: titulo,
        detail: mensagem
      });
    } else if (screen.width < 1024 || screen.height < 768) {
      this.messageService.add({
        key: 'bc',
        severity: 'success',
        summary: titulo,
        detail: mensagem
      });
    } else {
      this.messageService.add({
        key: 'tr',
        severity: 'success',
        summary: titulo,
        detail: mensagem
      });
    }
  }

  /**RECUPERA O CABEÇALHO PARA REAGENDAMENTO   */
  recuperaCabecalho() {
    this.teveRespostaProfissional = false;
    this.cabReagendamento = this.agendaService.getCabAgenda();
    if (this.nuid) {
      this.profissionalSelecionado = this.profissional.filter(x => x.codfunc === this.cabReagendamento.codfunc!)[0]; //RECUPERA O PROFISSIONAL SELECIONADO
      this.buscarServicoPorCodFuncionario();
      this.listaPlanosComprados();
      this.horariosFuncionarioSelecionado();

      //this.valorSelecionado = JSON.parse(this.cabReagendamento.json).itens;//RECUPERA OS SERVIÇOS SELECIONADOS

      console.log(JSON.parse(this.cabReagendamento.json))
      console.log('↑ JSON DO REAGENDAMENTO ↑')


      console.log(this.valorSelecionado)
      console.log('CHECKBOX ↑')
      console.log(this.servico)

      /**FAZ OS CALCULOS DE TEMPO/VALOR E TITULO */

      /**RECUPERA A DATA E HORÁRIO */
      this.diaSelecionado = new Date(this.cabReagendamento.dia + 'GMT-0300'); //'GMT-0300' PARA FICAR NO FUSO HORÁRIO DO BRASIL (SE NÃO FIZER ISSO FICA 1 DIA ANTERIOR)
      this.buscaHorarioPorCodFuncEDia(this.diaSelecionado)
      // this.horarioSelecionado = this.cabReagendamento.horini;

      if (this.cabReagendamento.notifica === 'S') {
        console.log('recebe notif')
        this.receberNotificacao = true;
      }



    }
  }

  /**METODO PARA VALIDAR O CUPOM */
  validaCupom() {
    let auxListaProdutosString = [];
    let auxListaProdutosPreco: any[] = [];

    if (this.cupom === this.cupomUtilizado) {
      return;
    } else {
      this.resetaCupom();
    }



    /**LISTA DE PRODUTOS DO CARRINHO PARA VALIDAR O CUPOM */
    for (let i = 0; i < this.valorSelecionado.length; i++) {
      if (this.valorSelecionado[i]) {

        if (i == 0) {
          auxListaProdutosString[i] = this.valorSelecionado[i].codprod;

        } else {
          auxListaProdutosString[i] = '' + this.valorSelecionado[i].codprod;
        }
        auxListaProdutosPreco.push(this.valorSelecionado[i].codprod);
      }
    }
    this.listaProdutos = auxListaProdutosString.toString()
    console.log(this.listaProdutos);
    console.log(auxListaProdutosPreco);

    /*************************************************************/
    /****VERIFICA SE ESTA REAGENDANDO AO USAR O CUPOM ***********/
    /***********************************************************/
    if (this.nuid) {
      this.descontoService.validaCupom(this.cupom.toUpperCase(), this.codemp, this.profissionalSelecionado.codfunc, this.listaProdutos, this.codusu, 'R', this.codusu).subscribe((res) => {
        let auxCupom = JSON.parse(res[0].fn_valida_desc_cupom)
        console.log(auxCupom)
        this.temDescontoCupom = true;
        this.desconto = 0;

        /**VERIFICA SE O CUPOM É VÁLIDO */
        if (auxCupom.NUCUP === 0) {
          //this.showError('Atenção', 'Cupom inválido, expirado ou já utilizado.');
          this.cupomValido = false;
          this.cupomInvalido = true;
          return;
        }
        this.cupomInvalido = false;
        this.cupomValido = true;

        /**RECEBE O NUMERO DO CUPOM VALIDADO */
        this.nucup = auxCupom.NUCUP;
        console.log(this.nucup + '  numero do cupom')

        console.log(auxCupom.CODPROD[0])
        /**VERIFICA SE O CUPOM ESTÁ 'PARA TODOS OS SERVIÇOS' (CODPROD=0) */
        if (auxCupom.CODPROD[0] === 0) {

          /**VERIFICA SE OS ITENS NO CARRINHO JÁ TEM DESCONTO (PROFISSIONAL/PRODUTO) */
          if (this.descontoAcumulado === 0) {
            if (auxCupom.TIPDESC === 'R') {
              this.desconto += auxCupom.DESC;
            } else {
              this.desconto = (this.total * auxCupom.DESC) / 100;
            }
          }
          else {
            if (auxCupom.TIPDESC === 'R') {
              this.desconto += auxCupom.DESC;
            } else {
              this.desconto = (this.totalGeralComDesconto * auxCupom.DESC) / 100;
            }
          }

        } else {
          /**VERIFICA SE OS ITENS NO CARRINHO ESTÃO APTOS PARA O CUPOM */
          for (let i = 0; i < this.valorSelecionado.length; i++) {
            for (let j = 0; j < auxCupom.CODPROD.length; j++) {
              if (this.valorSelecionado[i].codprod === auxCupom.CODPROD[j]) {
                /**APLICA O CUPOM A ITENS QUE ANTES NÃO TINHA DESCONTO */
                if (this.descontoAcumulado === 0) {
                  if (auxCupom.TIPDESC === 'R') { /** R = REAL */
                    this.desconto += auxCupom.DESC;
                    // this.total -= this.desconto;
                    // this.desconto = 0;
                  } else {
                    this.desconto += (this.valorSelecionado[i].preco! * auxCupom.DESC) / 100;
                    // this.total -= this.desconto;
                    // this.desconto = 0;
                  }
                }
                else {
                  if (auxCupom.TIPDESC === 'R') {  /** R = REAL */
                    this.desconto += auxCupom.DESC;
                    // this.total -= this.desconto;
                    // this.desconto = 0;
                  } else {
                    this.desconto += (this.valorSelecionado[i].precoAposDesconto! * auxCupom.DESC) / 100;
                    console.log()
                    // this.total -= this.desconto;
                    // this.desconto = 0;
                  }
                }
              }
            }
          }
        }

        /**DEBITA OS DESCONTOS */
        this.descontoAcumulado += this.desconto; //VALOR DO DESCONTO
        this.totalGeralComDesconto -= this.desconto; //TOTAL COM DESCONTO

        /**DEIXA O TOTAL ZERADO CASO ELE ESTEJA NEGATIVO */
        if (this.totalGeralComDesconto < 0) {
          this.totalGeralComDesconto = 0;
        }
        this.total -= this.desconto; //TOTAL SEM DESCONTO

        /**NÃO DEIXA USAR O MESMO CUPOM MAIS DE UMA VEZ */
        this.cupomUtilizado = this.cupom;
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          //this.showError('Atenção', 'Algo deu errado.')
        })
    }
    /*************************************************************/
    /*******************CUPOM AGENDAMENTO************************/
    /***********************************************************/
    else {
      this.descontoService.validaCupom(this.cupom.toUpperCase(), this.codemp, this.profissionalSelecionado.codfunc, this.listaProdutos, this.codusu, 'A', this.codusu).subscribe((res) => {
        let auxCupom = JSON.parse(res[0].fn_valida_desc_cupom)
        console.log(auxCupom)
        this.temDescontoCupom = true;
        this.desconto = 0;

        /**VERIFICA SE O CUPOM É VÁLIDO */
        if (auxCupom.NUCUP === 0) {
          //this.showError('Atenção', 'Cupom inválido, expirado ou já utilizado.');
          this.cupomValido = false;
          this.cupomInvalido = true;
          return;
        }
        this.cupomInvalido = false;
        this.cupomValido = true;

        /**RECEBE O NUMERO DO CUPOM VALIDADO */
        this.nucup = auxCupom.NUCUP;
        console.log(this.nucup + '  numero do cupom')

        console.log(auxCupom.CODPROD[0])
        /**VERIFICA SE O CUPOM ESTÁ 'PARA TODOS OS SERVIÇOS' (CODPROD=0) */
        if (auxCupom.CODPROD[0] === 0) {

          /**VERIFICA SE OS ITENS NO CARRINHO JÁ TEM DESCONTO (PROFISSIONAL/PRODUTO) */
          if (this.descontoAcumulado === 0) {
            if (auxCupom.TIPDESC === 'R') {
              this.desconto += auxCupom.DESC;
            } else {
              this.desconto = (this.total * auxCupom.DESC) / 100;
            }
          }
          else {
            if (auxCupom.TIPDESC === 'R') {
              this.desconto += auxCupom.DESC;
            } else {
              this.desconto = (this.totalGeralComDesconto * auxCupom.DESC) / 100;
            }
          }

        } else {
          /**VERIFICA SE OS ITENS NO CARRINHO ESTÃO APTOS PARA O CUPOM */
          for (let i = 0; i < this.valorSelecionado.length; i++) {
            for (let j = 0; j < auxCupom.CODPROD.length; j++) {
              if (this.valorSelecionado[i].codprod === auxCupom.CODPROD[j]) {
                /**APLICA O CUPOM A ITENS QUE ANTES NÃO TINHA DESCONTO */
                if (this.descontoAcumulado === 0) {
                  if (auxCupom.TIPDESC === 'R') { /** R = REAL */
                    this.desconto += auxCupom.DESC;
                    // this.total -= this.desconto;
                    // this.desconto = 0;
                  } else {
                    this.desconto += (this.valorSelecionado[i].preco! * auxCupom.DESC) / 100;
                    // this.total -= this.desconto;
                    // this.desconto = 0;
                  }
                }
                else {
                  if (auxCupom.TIPDESC === 'R') {  /** R = REAL */
                    this.desconto += auxCupom.DESC;
                    // this.total -= this.desconto;
                    // this.desconto = 0;
                  } else {
                    this.desconto += (this.valorSelecionado[i].precoAposDesconto! * auxCupom.DESC) / 100;
                    console.log()
                    // this.total -= this.desconto;
                    // this.desconto = 0;
                  }
                }
              }
            }
          }
        }

        /**DEBITA OS DESCONTOS */
        this.descontoAcumulado += this.desconto; //VALOR DO DESCONTO
        this.totalGeralComDesconto -= this.desconto; //TOTAL COM DESCONTO

        /**DEIXA O TOTAL ZERADO CASO ELE ESTEJA NEGATIVO */
        if (this.totalGeralComDesconto < 0) {
          this.totalGeralComDesconto = 0;
        }
        this.total -= this.desconto; //TOTAL SEM DESCONTO

        /**NÃO DEIXA USAR O MESMO CUPOM MAIS DE UMA VEZ */
        this.cupomUtilizado = this.cupom;
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          //this.showError('Atenção', 'Algo deu errado.')
        })
    }
  }

  /**METODO PARA O CALCULO DO DESCONTO TOTAL */
  descontoTotal() {
    // this.totalGeralComDesconto = this.totalComDesconto;
    // this.totalGeral = this.total;

    /**SOMA O VALOR TOTAL DOS PLANOS SELECIONADOS */
    let totalPlanos = 0
    for (let i = 0; i < this.planosDisponiveisSelecionado.length; i++) {
      totalPlanos += this.planosDisponiveisSelecionado[i].preco;
    }

    /**VERIFICA SE  USA O TOTAL SEM DESCONTO */
    if (this.descontoAcumulado === 0) {

      /**RETIRA O VALOR DOS PLANOS PARA FAZER A BUSCA DO DESCONTO DO VALOR TOTAL EM CIMA DOS SERVIÇOS SELECIONADOS (PLANO NÃO ENTRA NESSE DESCONTO) */
      let auxTotalGeral = 0;
      auxTotalGeral = this.totalGeral - totalPlanos;

      //this.descontoService.buscaDescontoTotal(this.totalGeral, this.codemp, this.profissionalSelecionado.codfunc, this.codusu, this.codusu).subscribe((res) => {
      this.descontoService.buscaDescontoTotal(auxTotalGeral, this.codemp, this.profissionalSelecionado.codfunc, this.codusu, this.codusu).subscribe((res) => {

        let auxDescTotal = JSON.parse(res[0].fn_busca_desctot)

        /**VERIFICA SE TEM DESCONTO TOTAL */
        if (auxDescTotal.NUDES > 0) {
          if (auxDescTotal.TIPDESC === 'R') {
            this.desconto += auxDescTotal.DESC;
          } else {
            this.desconto = (this.total * auxDescTotal.DESC) / 100;
          }
          /**DEBITA OS DESCONTOS */
          this.descontoAcumulado += this.desconto; //VALOR DO DESCONTO
          this.totalGeralComDesconto -= this.desconto; //TOTAL COM DESCONTO
          this.total -= this.desconto; //TOTAL SEM DESCONTO
        }

      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          //this.showError('Atenção', 'Algo deu errado.')
        });

    } else {

      /**RETIRA O VALOR DOS PLANOS PARA FAZER A BUSCA DO DESCONTO DO VALOR TOTAL EM CIMA DOS SERVIÇOS SELECIONADOS (PLANO NÃO ENTRA NESSE DESCONTO) */
      let auxTotalGeralDesconto = 0;
      auxTotalGeralDesconto = this.totalGeralComDesconto - totalPlanos;

      this.descontoService.buscaDescontoTotal(auxTotalGeralDesconto, this.codemp, this.profissionalSelecionado.codfunc, this.codusu, this.codusu).subscribe((res) => {
        let auxDescTotal = JSON.parse(res[0].fn_busca_desctot)
        console.log(auxDescTotal)

        /**VERIFICA SE TEM DESCONTO TOTAL */
        if (auxDescTotal.NUDES > 0) {
          if (auxDescTotal.TIPDESC === 'R') {
            this.desconto += auxDescTotal.DESC;
          } else {
            this.desconto = (this.totalGeralComDesconto * auxDescTotal.DESC) / 100;
          }

          /**DEBITA OS DESCONTOS */
          this.descontoAcumulado += this.desconto; //VALOR DO DESCONTO
          this.totalGeralComDesconto -= this.desconto; //TOTAL COM DESCONTO
          this.total -= this.desconto; //TOTAL SEM DESCONTO
        }

      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          //this.showError('Atenção', 'Algo deu errado.')
        });

    }
    console.log(this.descontoAcumulado)
  }


  /**METODO PARA TRANSFORMAR O TEMPO DE SERVIÇO RECEBIDO DO BANCO DE DADOS EM MINUTOS */
  tempoEmMinutos(tempo: any) {
    console.log(tempo)
    const [horas, minutos] = tempo.split(':').map(Number);

    const totalMinutos = (horas * 60) + minutos;
    return totalMinutos;

  }



  /**METODOS QUE CONTROLA A SELEÇÃO DA ABA (SERVIÇOS OU PLANOS) */
  clickServicos() {
    this.selecionouServicos = true;
    this.selecionouPlanos = false;
  }

  clickPlanos() {
    this.selecionouServicos = false;
    this.selecionouPlanos = true;
  }


  /**METODOS QUE CONTROLA A SELEÇÃO DA ABA DE PLANOS (MEUS OU DISPONIVEIS) */
  clickMeusPlanos() {
    this.selecionouMeusPlanos = true;
    this.selecionouPlanosDisponiveis = false;
  }

  clickPlanosDisponiveis() {
    this.selecionouMeusPlanos = false;
    this.selecionouPlanosDisponiveis = true;
  }

  iniciaAba() {
    if (this.meusPlanos.length > 0) {
      this.selecionouMeusPlanos = true;
      this.selecionouPlanosDisponiveis = false;
    } else {
      this.selecionouMeusPlanos = false;
      this.selecionouPlanosDisponiveis = true;
    }
    console.log(this.selecionouMeusPlanos)
  }

  expandeInfoPlano(plano: any) {
    console.log(plano)
    plano.expande = true;
    console.log(plano)
  }

  guardaInfoPlano(plano: any) {
    console.log(plano)
    plano.expande = false;
  }


  /**METODO QUE TRAZ TODOS OS PLANOS DA BARBEARIA */
  listaPlanosDisponiveis() {
    this.planoService.listaPlanoPorCodemp(this.codemp, this.codusu).subscribe((res) => {
      console.log(res);
      this.planosDisponiveis = res;

      this.planosDisponiveis = this.transformarVetor(this.planosDisponiveis);

      /**RETIRA OS PLANOS QUE O CLIENTE JÁ COMPROU */
      if (this.meusPlanos.length > 0) {
        for (let i = 0; i < this.meusPlanos.length; i++) {
          this.planosDisponiveis = this.planosDisponiveis.filter(x => x.nupla != this.meusPlanos[i].nupla);
        }
      }

      /**RETIRA OS PLANOS QUE O CLIENTE COMPROU EM AGENDAMENTOS QUE AINDA NÃO ACONTECEU */
      if (!this.nuid) {
        this.planosDisponiveis = this.planosDisponiveis.filter(x => x.dhaquisicao == null);
      }
      /**SE FOR UM REAGENDAMENTO*/
      else {
        let planosCabReagendamento = JSON.parse(this.cabReagendamento.json).plano;

        /**VERIFICA SE FOI COMPRADO ALGUM PLANO*/
        if (planosCabReagendamento.length > 0) {
          let auxPlanos = [];

          /**ATRIBUI OS PLANOS COMPRADOS AO VETOR AUXILIAR*/
          for (let i = 0; i < this.planosDisponiveis.length; i++) {
            for (let j = 0; j < planosCabReagendamento.length; j++) {
              if (planosCabReagendamento[j].nupla == this.planosDisponiveis[i].nupla) {
                auxPlanos.push(this.planosDisponiveis[i])
              }
            }
          }


          /**UNE OS PLANOS COMPRADOS NO AGENDAMENTO COM OS PLANOS DISPONIVEIS*/
          this.planosDisponiveis = this.planosDisponiveis.filter(x => x.dhaquisicao == null);
          this.planosDisponiveis = this.planosDisponiveis.concat(auxPlanos);

        }
        /**SE FOI COMPRADO NENHUM PLANO, ATRIBUI SOMENTE OS PLANOS DISPONIVEIS*/
        else {
          this.planosDisponiveis = this.planosDisponiveis.filter(x => x.dhaquisicao == null);
        }

      }

      this.listaServicosPlanosDisponiveis();
    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
      });
  }

  /**METODO QUE CONCATENA A DESCRIÇÃO DE LISTA DOS PLANOS */
  transformarVetor(planos: any) {
    const resultado: any = [];

    planos.forEach((plano: any) => {
      const planoExistente = resultado.find((p: any) => p.nupla === plano.nupla);

      if (planoExistente) {
        const descrprods = plano.descrprods.split(', ').filter((descrprods: any) => !planoExistente.descrprods.includes(descrprods));

        planoExistente.descrprods += `, ${descrprods.join(', ')}`;
      } else {
        resultado.push({ ...plano });
      }
    });

    return resultado;
  }

  /**METODO QUE LISTA OS SERVIÇOS DO PLANO DISPONIVEL QUE O BARBEIRO ATENDE */
  listaServicosPlanosDisponiveis() {
    this.planoService.listaServicosPlanoPorCodfunc(this.profissionalSelecionado.codfunc).subscribe((res) => {
      console.log(res);
      this.servicosPlanosDisponiveis = res;


      /**SOMA OS MINUTOS DO PLANO */
      for (let i = 0; i < this.servicosPlanosDisponiveis.length; i++) {
        this.servicosPlanosDisponiveis[i].minutos = this.tempoEmMinutos(this.servicosPlanosDisponiveis[i].tempom);
      }
      for (let i = 0; i < this.planosDisponiveis.length; i++) {
        let auxMinutos = 0;
        for (let j = 0; j < this.servicosPlanosDisponiveis.length; j++) {
          if (this.servicosPlanosDisponiveis[j].nupla === this.planosDisponiveis[i].nupla) {
            auxMinutos += this.servicosPlanosDisponiveis[j].minutos;
          }
        }
        this.planosDisponiveis[i].minutos = auxMinutos;
      }



      /********************************************************************************
      *                                     REAGENDADO                               *
      ********************************************************************************/
      if (this.nuid) {
        /** ATRIBUI O PLANO AO CHECKBOX */
        let tempCheckboxPlanos = JSON.parse(this.cabReagendamento.json).plano;
        if (tempCheckboxPlanos.length > 0) {


          for (let i = 0; i < this.planosDisponiveis.length; i++) {
            for (let j = 0; j < tempCheckboxPlanos.length; j++) {
              if (tempCheckboxPlanos[j].nupla === this.planosDisponiveis[i].nupla) {
                this.planosDisponiveisSelecionado.push(this.planosDisponiveis[i]);
              }
            }
          }

          /** ATRIBUI O SERVIÇO DO PLANO AO CHECKBOX */
          let tempCheckbox = JSON.parse(this.cabReagendamento.json).itens
          for (let i = 0; i < this.servicosPlanosDisponiveis.length; i++) {
            for (let j = 0; j < tempCheckbox.length; j++) {
              if (tempCheckbox[j].codprod === this.servicosPlanosDisponiveis[i].codprod && tempCheckbox[j].nupla === this.servicosPlanosDisponiveis[i].nupla) {
                this.valorSelecionado.push(this.servicosPlanosDisponiveis[i]);
                /**DESABILITA A SELEÇÃO DOS SERVIÇOS COM MESMO CODPROD */
                this.checaServico(this.servicosPlanosDisponiveis[i], 2);
              }
              if (tempCheckbox[j].codprod != this.servicosPlanosDisponiveis[i].codprod && tempCheckbox[j].nupla === this.servicosPlanosDisponiveis[i].nupla) {
                this.servicosPlanosDisponiveis[i].desabilita = true;
              }
            }
          }
        }


        console.log(this.planosDisponiveisSelecionado)
        console.log('↑ CHECKBOX DOS PLANOS ↑')

        this.criaTitulo();
        this.somaTotal();
      }

    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
      });
  }

  /**METODO QUE LISTA OS SERVIÇOS DO MEU PLANO QUE O BARBEIRO ATENDE */
  listaServicosMeusPlanosDisponiveis() {
    this.planoService.listaServicosMeusPlanosPorCodfunc(this.profissionalSelecionado.codfunc).subscribe((res) => {
      console.log(res);
      this.servicosMeusPlanos = res;


      this.servicosMeusPlanos = this.servicosMeusPlanos.filter(x => x.status != null)

      /**AJUSTA OS MINUTOS DOS SERVICOS */
      for (let i = 0; i < this.servicosMeusPlanos.length; i++) {
        this.servicosMeusPlanos[i].minutos = this.tempoEmMinutos(this.servicosMeusPlanos[i].tempom);
        this.servicosMeusPlanos[i].status = this.servicosMeusPlanos[i].status.replace(/\s+/g, '')


        /**DESABILITA OS SERVIÇOS QUE JÁ FORAM USADOS */
        if (this.servicosMeusPlanos[i].status === "USADO") {
          this.servicosMeusPlanos[i].desabilita = true;
        }

      }


      /********************************************************************************
      *                                     REAGENDADO                               *
      ********************************************************************************/
      if (this.nuid) {
        /** ATRIBUI O SERVIÇO DO PLANO DO CLIENTE AO CHECKBOX */
        let tempCheckbox = JSON.parse(this.cabReagendamento.json).itens
        for (let i = 0; i < this.servicosMeusPlanos.length; i++) {
          for (let j = 0; j < tempCheckbox.length; j++) {
            if (tempCheckbox[j].seq === this.servicosMeusPlanos[i].seq) {
              this.servicosMeusPlanos[i].status = 'LIVRE';
              this.valorSelecionado.push(this.servicosMeusPlanos[i]);

              /**DESABILITA A SELEÇÃO DOS SERVIÇOS COM MESMO CODPROD */
              this.checaServico(this.servicosMeusPlanos[i], 2);
              this.servicosMeusPlanos[i].desabilita = false;
            }
          }
        }



        console.log(this.planosDisponiveisSelecionado)
        console.log('↑ CHECKBOX DOS PLANOS ↑')

        this.criaTitulo();
        this.somaTotal();
      }





    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
      });
  }



  /**METODO QUE TRAZ TODOS OS PLANOS COMPRADOS */
  listaPlanosComprados() {
    this.planoService.listaPlanoCompradoPorCodusu(this.codusu, null).subscribe((res) => {
      console.log(res);
      this.meusPlanos = res;

      // this.meusPLanos = this.transformarVetor2(this.meusPLanos)
      console.log(this.meusPlanos)
      this.listaServicosMeusPlanosDisponiveis();
      this.listaPlanosDisponiveis();
      this.iniciaAba();
      this.criaDescrProds();
    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
      });
  }


  printaServicos() {
    console.log(this.valorSelecionado)
  }




  /**METODO QUE RECUPERA O HORARIO DE TRABALHO DO FUNCIONARIO */
  horariosFuncionarioSelecionado() {
    const diasSemana = ["dom", "seg", "ter", "qua", "qui", "sex", "sab"];
    const horariosDeTrabalho: Record<string, string[]> = {};

    diasSemana.forEach((dia, index) => {
      const horarioDia = this.profissionalSelecionado[`h_${dia}`];
      if (horarioDia && horarioDia !== "-") {
        const horarios = horarioDia.split("\r\n");
        horariosDeTrabalho[diasSemana[index]] = horarios;
      }
    });

    this.faixaHorariosFuncionario = horariosDeTrabalho;
    // console.log(this.faixaHorariosFuncionario)

    this.processarHorarios(this.faixaHorariosFuncionario);

  }

  adicionar15Minutos(hora: string): string {
    const [horas, minutos] = hora.split(':').map(Number);
    const totalMinutos = horas * 60 + minutos + 15;
    const novaHora = `${Math.floor(totalMinutos / 60).toString().padStart(2, '0')}:${(totalMinutos % 60).toString().padStart(2, '0')}`;
    return novaHora;
  }

  // Função para gerar os horários entre um horário de início e fim
  gerarHorarios(inicio: string, fim: string, dia: string): { dia: string, horario: string }[] {
    const horarios: { dia: string, horario: string }[] = [];
    let horaAtual = inicio;

    while (horaAtual < fim) {
      horarios.push({ dia, horario: horaAtual });
      horaAtual = this.adicionar15Minutos(horaAtual);
    }

    return horarios;
  }

  // Função para processar o objeto horarioFunc
  processarHorarios(horarioFunc: { [dia: string]: string[] }): { dia: string, horario: string }[] {
    const resultado: { dia: string, horario: string }[] = [];

    for (const dia in horarioFunc) {
      if (horarioFunc.hasOwnProperty(dia)) {
        horarioFunc[dia].forEach((faixa) => {
          if (faixa !== '-') {
            const [inicio, fim] = faixa.split('-');
            const horariosGerados = this.gerarHorarios(inicio, fim, `h_${dia}`);
            resultado.push(...horariosGerados);
          }
        });
      }
    }
    this.horariosFuncionario = resultado

    /**ROTINA PARA RETIRAR OS HORARIOS INFORMADOS NA TGFHOR*/
    if (this.profissionalSelecionado.restringehor != null) {
      let removeHorarios = this.profissionalSelecionado.restringehor.split(",").map((x: any) => x.trim());
      this.horariosFuncionario = this.horariosFuncionario.filter((x: any) => !removeHorarios.some((y: any) => x.horario.endsWith(y)))
    }

    //  console.log(this.horariosFuncionario)
    return resultado;
  }


  /**METODO QUE VERIFICA O DIA DA SEMANA E TRAZ OS HORARIOS DOS FUNCIONARIOS PARA A FILA DE ESPERA */
  verificaDiaSemana() {
    /**DIAS DA SEMANA EM TYPESCRIPT 
    0: segunda
    1: terça
    2: quarta
    3: quinta
    4: sexta
    5: sabado
    6: domingo*/

    let auxData = new Date(this.auxDia);
    this.diaSemana = auxData.getDay();

    console.log(this.profissional.filter(x => x.codfunc === this.profissionalSelecionado.codfunc))

    //SEGUNDA
    if (this.diaSemana === 0) {
      this.horariosFuncionarioFiltrado = this.horariosFuncionario.filter((x: any) => x.dia === 'h_seg')
    }

    //TERÇA
    if (this.diaSemana === 1) {
      this.horariosFuncionarioFiltrado = this.horariosFuncionario.filter((x: any) => x.dia === 'h_ter')
    }

    //QUARTA
    if (this.diaSemana === 2) {
      this.horariosFuncionarioFiltrado = this.horariosFuncionario.filter((x: any) => x.dia === 'h_qua')
    }

    //QUINTA
    if (this.diaSemana === 3) {
      this.horariosFuncionarioFiltrado = this.horariosFuncionario.filter((x: any) => x.dia === 'h_qui')
    }

    //SEXTA
    if (this.diaSemana === 4) {
      this.horariosFuncionarioFiltrado = this.horariosFuncionario.filter((x: any) => x.dia === 'h_sex')
    }

    //SABADO
    if (this.diaSemana === 5) {
      this.horariosFuncionarioFiltrado = this.horariosFuncionario.filter((x: any) => x.dia === 'h_sab')
    }

    //DOMINGO
    if (this.diaSemana === 6) {
      this.horariosFuncionarioFiltrado = this.horariosFuncionario.filter((x: any) => x.dia === 'h_dom')
    }


    this.auxMatrizFilaEspera = [];

    for (var i in this.horariosFuncionarioFiltrado) {
      this.auxMatrizFilaEspera.push([i, this.horariosFuncionarioFiltrado[i]])
    }

    this.fazMatrizFilaEspera();

    console.log(this.horariosFuncionarioFiltrado)
  }

  /**METODO QUE CRIA LOG AUTOMATICO DE FILA (AO ABRIR POPUP DE HORARIOS) */
  logAutomaticoFila() {
    let listacodprods: any = [];
    this.codprods = '';

    /**CRIA A STRING DOS CODPRODS */
    for (const produto of this.valorSelecionado) {
      listacodprods.push(produto.codprod);
    }

    this.codprods = listacodprods.join(', ');
    console.log(this.codprods)


    this.agendaService.registraLogAGD(this.auxDia, null, this.codusu, this.codusu, this.codemp, this.profissionalSelecionado.codfunc, this.codprods, this.totalMin, null).subscribe((res) => {
      console.log(res)

    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
      })
  }

  horarioParaFila(horario: any) {
    this.horarioSelecionadoFila = horario;
  }


  /**METODO QUE CRIA UMA POSIÇÃO NA FILA (AO SELECIONAR HORARIO DA FILA) */
  queroEntrarFila() {


    this.agendaService.registraLogAGD(this.auxDia, this.horarioSelecionadoFila, this.codusu, this.codusu, this.codemp, this.profissionalSelecionado.codfunc, this.codprods, this.totalMin, 'S').subscribe((res) => {
      console.log(res)
      this.mostraFilaEspera = false;
      this.alertService.alert('Você está na fila de espera! Entraremos em contato, caso surja um horário livre.', 'success')
    },
      (error) => {
        this.mostraFilaEspera = false;
        this.alertService.alert('Algo deu errado.', 'error');
        console.log(error)
        console.log('deu ruim')
      })
  }


  /**metodo que abre o dialog para mostrar os horarios da fila de espera */
  showDialogFila() {
    //profissional, serviço, dia
    // console.log(this.profissionalSelecionado.codfunc)
    // console.log(this.valorSelecionado)
    // console.log(this.auxDia)
    // console.log(this.totalMin)
    // console.log(this.utilitariosService.somaMinutos("00:00", this.totalMin))

    this.mostraHorarios = false;
    this.mostraFilaEspera = true;

  }


  /**METODO QUE CRIA A DESCRIÇÃO DOS MEUS PLANOS*/
  criaDescrProds() {
    var auxDescrProds = []
    this.descrProds = '';
    let descrIgual = '';
    for (let i = 0; i < this.meusPlanos.length; i++) {
      for (let j = 0; j < this.meusPlanos[i].itens.length; j++) {
        if (this.meusPlanos[i].itens) {
          if (descrIgual != this.meusPlanos[i].itens[j].descrprods) {

            if (j == 0) {
              auxDescrProds[j] = this.meusPlanos[i].itens[j].descrprods;
            } else {
              auxDescrProds[j] = ' ' + this.meusPlanos[i].itens[j].descrprods;
            }
            console.log(auxDescrProds[j])
            descrIgual = this.meusPlanos[i].itens[j].descrprods;
          }

        }
      }
      this.meusPlanos[i].descrprods = auxDescrProds.toString()
    }
    //this.descrProds = auxDescrProds.toString()
    //console.log(this.descrProds)
  }
}





