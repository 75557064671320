import { Injectable } from '@angular/core';
import { Produto } from './assets/produto';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './environments/environment';
import { Observable, first, tap } from 'rxjs';
import { LoginService } from './login/login.service';

@Injectable({
  providedIn: 'root'
})
export class PlanoService {
  plano: any;
  constructor(private http: HttpClient, private loginService: LoginService) { }


  preencherPlano(nupla: number, nome: string, descr: string, preco: number, duracao: string, comissao: number, codusuresp: string, codcli: number): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const plano = {
      "nupla": nupla, "nome": nome, "descr": descr, "preco": preco,
      "duracao": duracao, "comissao": comissao, "codusuresp": codusuresp, "codcli": codcli
    }

    return this.http
      .post<any>(`${environment.BASE_URL}/plano/createplano`, plano, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  excluiPlano(nupla: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const plano = {
      "nupla": nupla, "codusuresp": codusuresp,
    }

    return this.http
      .post<any>(`${environment.BASE_URL}/plano/excluiplano`, plano, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  buscaPlanoPorCodCli(codcli: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const cliente = {
      "codcli": codcli, "codusuresp": codusuresp
    }

    return this.http
      .post<any>(`${environment.BASE_URL}/plano/listplanosbycodcli`, cliente, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  preencherListaPlano(nulista: number, nupla: number, codprods: any, descrprods: any, qtd: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const lista = {
      "nulista": nulista, "nupla": nupla, "codprods": codprods, "descrprods": descrprods, "qtd": qtd, "codusuresp": codusuresp
    }

    return this.http
      .post<any>(`${environment.BASE_URL}/plano/createlistaplano`, lista, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  buscaListasPlanoPorNupla(nupla: number): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const plano = {
      "nupla": nupla
    }

    return this.http
      .post<any>(`${environment.BASE_URL}/plano/listlistasbynupla`, plano, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  excluiListaItensPlano(nulista: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const lista = {
      "nulista": nulista, "codusuresp": codusuresp,
    }

    return this.http
      .post<any>(`${environment.BASE_URL}/plano/excluilistaitensplano`, lista, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  excluiTodasListasItensPlano(nupla: number, codusuresp: string): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const plano = {
      "nupla": nupla, "codusuresp": codusuresp,
    }

    return this.http
      .post<any>(`${environment.BASE_URL}/plano/excluitodaslistas`, plano, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  listaServicosPlanoPorCodemp(codemp: number): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);
    const empresa = {
      "codemp": codemp
    }

    return this.http
      .post<any>(`${environment.BASE_URL}/plano/listservicosplanobycodemp`, empresa, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  listaServicosPlanoPorCodfunc(codfunc: number): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);
    const funcionario = {
      "codfunc": codfunc
    }

    return this.http
      .post<any>(`${environment.BASE_URL}/plano/listservicosplanobycodfunc`, funcionario, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  listaPlanoPorCodemp(codemp: number, codusu: any): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const empresa = {
      "codemp": codemp, "codusu": codusu
    }

    return this.http
      .post<any>(`${environment.BASE_URL}/plano/listplanobycodemp`, empresa, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  
  listaPlanoCompradoPorCodusu(codusu: any, nuid: any): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);

    const empresa = {
      "codusu": codusu, "nuid": nuid
    }

    return this.http
      .post<any>(`${environment.BASE_URL}/plano/listplanoscompradosbycodusu`, empresa, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }

  listaServicosMeusPlanosPorCodfunc(codfunc: number): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders().append('Authorization', token!);
    const funcionario = {
      "codfunc": codfunc
    }

    return this.http
      .post<any>(`${environment.BASE_URL}/plano/listservicosmeusplanosbycodfunc`, funcionario, { headers: headers })
      .pipe(
        first(),
        //delay(5000),
        tap((r: any) => console.log(r),
          (e: any) => {
            this.loginService.verificaToken(e.status);
          })
      );
  }


  public setPlano(plano: any) { //SETA A BARBEARIA NO VETOR
    this.plano = plano;
  }

  public getPlano() { //RECUPERA O CONTEUDO DA BARBEARIA PARA VISUALIZAÇÃO
    return this.plano;
  }
}
