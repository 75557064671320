<body id="login">
    <div class="flex flex-column align-items-center  redimensionamento">
        <div class="col-12 sm-12 md-4 xl:col-5 lg:col-5 md:col-5 sm:col-5">
            <div class="flex align-items-center justify-content-center">
                <div class="flex align-items-center justify-content-center">
                    <img src="./assets/logo.svg" />
                </div>
            </div>
            <div class="subir">
                <div class="align-items-center justify-content-center ">
                    <div *ngIf="this.credInvalida">
                        <p-messages severity="error">
                            <ng-template pTemplate>
                                <div>{{msgErro}}</div>
                            </ng-template>
                        </p-messages>
                    </div>
                </div>
                <div class="col-12 flex align-items-center justify-content-center divPersonalizada">

                    <input class="col-12" id="inputLogin" [(ngModel)]="email" type="text" pInputText
                        placeholder="E-mail" />
                </div>
                <div class="col-12 flex align-items-center justify-content-center divPersonalizada">
                    <input class="col-12" id="inputLogin" [(ngModel)]="senha" type="password" pInputText
                        placeholder="Senha" />
                </div>
                <div class="col-12 divPersonalizada">
                    <div class=" grid p-flup-2 flex align-items-center justify-content-center pt-2">
                        <div class="field col  gap-2">
                            <button id="botaoLogin" pButton type="button" label="Criar Conta" class="col-12"
                                routerLink="/cadastro"></button>
                        </div>
                        <div class="field col">
                            <div *ngIf="!loading" [style]="{width: '100%'}">
                                <button id="botaoLogin" pButton type="button" label="Entrar" class="col-12"
                                    (click)="doLogin()"></button>
                            </div>
                            <div *ngIf="loading" [style]="{width: '100%'}">
                                <button id="botaoLoadingLogin" [disabled]="true" pButton type="button" label=""
                                    class="col-12"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex align-items-center justify-content-center fontEsqueceuSenha ">
                    <span class="cursor-pointer" routerLink="/esqueceuSenha">Esqueceu senha?</span>
                </div>
            </div>
        </div>
    </div>


</body>