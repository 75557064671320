import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AgendaService } from 'src/agenda.service';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { ConfirmationService } from 'primeng/api';
import { UsuarioService } from 'src/usuario.service';
import { AlertService } from 'src/alert.service';
import { AnyCatcher } from 'rxjs/internal/AnyCatcher';
import { CarouselModule } from 'primeng/carousel';

@Component({
  selector: 'app-minha-agenda',
  templateUrl: './minha-agenda.component.html',
  styleUrls: ['./minha-agenda.component.scss'],
  providers: [ConfirmationService],
  encapsulation: ViewEncapsulation.None
})
export class MinhaAgendaComponent implements OnInit {
  aba: boolean = true;
  // receberNotificacao: any[] = [];
  cabecalho: any;
  cabecalhoFechado: any[] = [];
  cabecalhoAberto: any[] = [];
  cabecalhoVazio = {};
  agendamentoSelecionado = {}

  /**VARIAVEIS DO COMPONENT */
  codusu: number = 0;
  codemp: number = 0;
  mostraCarregando: boolean = true;

  /**VARIAVEIS DA EMPRESA */
  nomeEmp: string = ' ';
  logradouro: string = ' ';
  numero: string = ' ';
  bairro: string = ' ';
  nomecid: string = ' ';
  telefone: string = ' ';
  complemento: string = ' ';
  nomeBarbeiro: string = ' ';
  totalMinutos: number = 0;
  titulo: string = '';
  x: any[] = [];
  y: number = 0


  constructor(private agendaService: AgendaService, private decodedTokenService: DecodedTokenService, private router: Router, private confirmationService: ConfirmationService,
    private usuarioService: UsuarioService, private alertService: AlertService) {
    this.x[0] = "a";
    this.x[1] = "b";

  }
  ngOnInit(): void {
    this.recuperaCodUsu();
    this.listarAgendaUsuario();
    console.log(this.cabecalhoAberto.length)
   
  }

  recuperaCodUsu() {
    this.codusu = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }


  clickAberto() {
    this.aba = true;
    //this.fechado = false;
  }
  clickFechado() {
    this.aba = false;
    // this.fechado = true;
  }

  soma() {
    if (this.y <= this.x.length) {

      this.y = 1 + this.y;
    }
  }
  sleep(ms: any) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  listarAgendaUsuario() {
    //setTimeout(() => {
    this.agendaService.listaAgendaUsuario(this.codusu, this.codusu.toString()).subscribe((res) => {

      this.mostraCarregando = false;
      this.cabecalho = Object(res.filter((x: any) => x.origem === 'A' || x.origem === 'E')); //SO RECUPERA AGENDAMENTOS DA REQUISIÇÃO
      console.log(this.cabecalho)

      /**PEGA O DIA E A HORA ATUAL DO SERVIDOR  */
      let auxDate: any = new Date();
      auxDate = auxDate.getFullYear() + "/" + (auxDate.getMonth() + 1) + "/" + auxDate.getDate() + " " + auxDate.getHours() + ":" + auxDate.getMinutes();
      for (let i = 0; i < this.cabecalho.length; i++) {

        /**COMPARA A DATA ATUAL COM A DO CABEÇALHO  */
        if (Date.parse(auxDate) > Date.parse((this.cabecalho[i].dia).toString() + ' ' + (this.cabecalho[i].horini).toString())) {
          this.cabecalhoFechado[i] = this.cabecalho[i];
        } else {
          this.cabecalhoAberto[i] = this.cabecalho[i];
        }
      }

      /*****************************************************************/
      /*                        CABEÇALHO ABERTO                       */
      /*****************************************************************/

      this.cabecalhoAberto = this.cabecalhoAberto.filter(x => x.nuid > 0)

      for (let i = 0; i < this.cabecalhoAberto.length; i++) {
        if (this.cabecalhoAberto[i].notifica === 'S') {
          this.cabecalhoAberto[i].RECEBERNOTIFICACAO = true;
        } else {
          this.cabecalhoAberto[i].RECEBERNOTIFICACAO = false;
        }
      }
      /** FAZ O CALCULO DE QUANTOS MINUTOS FORAM GASTOS EM CADA ATENDIMENTO EM ABERTO*/
      for (let i = 0; i < this.cabecalhoAberto.length; i++) {
        let totalMinIni = 0;
        let totalMinFin = 0;
        totalMinIni += (Number((this.cabecalhoAberto[i].horini).substr(0, 2)) * 60) + Number((this.cabecalhoAberto[i].horini).substr(3, 2))
        totalMinFin += (Number(this.cabecalhoAberto[i].horfin.substr(0, 2)) * 60) + Number(this.cabecalhoAberto[i].horfin.substr(3, 2))
        this.cabecalhoAberto[i].TOTALMIN = totalMinFin - totalMinIni
      }

      /** MOSTRA OS SERVIÇOS FEITOS EM CADA ATENDIMENTO EM ABERTO*/
      for (let i = 0; i < this.cabecalhoAberto.length; i++) {
        console.log('entrei onde faz titulo em aberto')
        this.cabecalhoAberto[i].TITULO = this.criaTitulo(JSON.parse(this.cabecalhoAberto[i].json).itens)
        console.log(JSON.parse(this.cabecalhoAberto[i].json).itens)

      }



      /*****************************************************************/
      /*                         CABEÇALHO FECHADO                     */
      /*****************************************************************/

      this.cabecalhoFechado = this.cabecalhoFechado.filter(x => x.nuid > 0)
      /** FAZ O CALCULO DE QUANTOS MINUTOS FORAM GASTOS EM CADA ATENDIMENTO EM FECHADO*/
      for (let i = 0; i < this.cabecalhoFechado.length; i++) {
        let totalMinIni = 0;
        let totalMinFin = 0;
        totalMinIni += (Number((this.cabecalhoFechado[i].horini).substr(0, 2)) * 60) + Number((this.cabecalhoFechado[i].horini).substr(3, 2))
        totalMinFin += (Number(this.cabecalhoFechado[i].horfin.substr(0, 2)) * 60) + Number(this.cabecalhoFechado[i].horfin.substr(3, 2))
        this.cabecalhoFechado[i].TOTALMIN = totalMinFin - totalMinIni

      }
      /** MOSTRA OS SERVIÇOS FEITOS EM CADA ATENDIMENTO EM FECHADO*/
      for (let i = 0; i < this.cabecalhoFechado.length; i++) {
        /** NÃO APARECE TODOS PORQUE NO BACKEND MUDAMOS A PALAVRA 'items' para 'itens' depois que alguns agendamentos já tinham passado do tempo (com a palavra 'ITEM') */
        // console.log('NÃO APARECE TODOS PORQUE NO BACKEND MUDAMOS A PALAVRA "items" para "itens" depois que alguns agendamentos já tinham passado do tempo (com a palavra "ITEM")')
        this.cabecalhoFechado[i].TITULO = this.criaTitulo(JSON.parse(this.cabecalhoFechado[i].json).itens)
        // console.log(JSON.parse(this.cabecalhoFechado[i].json).itens)
      }
      console.log(this.cabecalhoFechado)
      console.log('fechado ↑')
      this.selecionouAgendamento('');
    },
      (error) => {
        console.log(error.message)
        console.log('deu ruim')
        this.mostraCarregando = false;
        this.alertService.alert('Algo deu errado.', 'error');
        //this.showError('Atenção', 'Algo deu errado.')
      })
    // }, 1000);

  }


  criaTitulo(itens: any) {
    var auxTitulo = []
    for (let i = 0; i < itens.length; i++) {
      if (i == 0) {
        auxTitulo[i] = itens[i].nomeprod;
      } else {
        auxTitulo[i] = ' ' + itens[i].nomeprod;
      }
    }
    return auxTitulo.toString();
  }

  selecionouAgendamento(page: any) {
    console.log(page)
    console.log(this.cabecalhoAberto)
    for(let i = 0; i < this.cabecalhoAberto.length; i++){
      if(page.page === i){
        this.agendamentoSelecionado = this.cabecalhoAberto[i];
        console.log(this.cabecalhoAberto[i]);
      }
      if(page === ''){
        this.agendamentoSelecionado = this.cabecalhoAberto[0];
        console.log(this.cabecalhoAberto[0]);
      }
    } 

  }
  // criaReagendamento(cabecalho: any) {
  //   this.agendaService.setCabAgenda(cabecalho);
  //   console.log(cabecalho);
  //   this.router.navigate(['/agendar']);
  // }
   criaReagendamento() {
    this.agendaService.setCabAgenda(this.agendamentoSelecionado);
    console.log(this.agendamentoSelecionado);
    this.router.navigate(['/agendar']);
  }


  cancelarAgendamento(cabecalho: any) {
    console.log(cabecalho.nuid)

    // this.agendaService.cancelarAgendamento(Number(cabecalho.nuid), this.codusu.toString()).subscribe((res) => {
      this.agendaService.cancelarAgendamento(Number(Object(this.agendamentoSelecionado).nuid), this.codusu.toString(), null).subscribe((res) => {
      console.log(res);
      this.cabecalhoAberto = this.cabecalhoAberto.filter(x => x.nuid != cabecalho.nuid)
      this.selecionouAgendamento('');
    })
    console.log(cabecalho.nuid)
  }


  // confirmaCancelamentoAgd(cabecalho: any) {
  //   this.confirmationService.confirm({
  //     message: 'Deseja mesmo cancelar esse agendamento ?',
  //     header: 'Cancelar',
  //     icon: 'pi pi-exclamation-triangle',
  //     accept: () => {
  //       this.cancelarAgendamento(cabecalho);
  //     },
  //     reject: () => {
  //     }
  //   });
  // }
  confirmaCancelamentoAgd() {
    console.log(this.agendamentoSelecionado)
    this.confirmationService.confirm({
      message: 'Deseja mesmo cancelar esse agendamento ?',
      header: 'Cancelar',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.cancelarAgendamento(this.agendamentoSelecionado);
      },
      reject: () => {
      }
    });
  }


  clickAgenda() {
    this.usuarioService.getPerfil((this.codusu).toString(), (this.codusu).toString()).subscribe((res) => {
      this.codemp = Object(res)[0].codemp;
      if (this.codemp != 0) {
        this.agendaService.setCabAgenda(this.cabecalhoVazio);
        this.router.navigate(['/agendar']);
      } else {
        this.router.navigate(['/buscarEstabelecimento']);
      }
    })
  }
}
