<body id="CodRec">
    <div class="flex justify-content-center redimensionamentoRecCodigo ">
        <div class="flex align-items-center justify-content-center ">
            <p-card
                [ngStyle]="teveErro ===true?{'width': '350px', 'height': '450px'} : {'width': '350px', 'height': '350px'}">
                <!-- [style]="{'width': '350px', 'height': '350px'}" -->
                <div class=" flex justify-content-center flex-wrap">
                    <div class="flex align-items-center justify-content-center fontTituloRecCodigo">
                        <span>Informe o código de segurança</span>
                    </div>
                    <div class="pt-2">
                        <span>Seu código expira em: {{temporizador}}</span>
                    </div>
                    <div *ngIf="teveErro">
                        <p-messages severity="error" id="erroCodRec">
                            <ng-template pTemplate>
                                <div class="ml-6" class="erroCodRec">{{msgErro}}</div>
                            </ng-template>
                        </p-messages>
                    </div>
                    <div class="flex justify-content-center flex-wrap mt-5">
                        <div class="flex align-items-center justify-content-center gap-3">
                            <input class="text-center" type="text" id="input1" maxlength="1" pInputText
                                inputmode="numeric" [style]="{'width': '20%'}" [(ngModel)]="codigo[0]"
                                (paste)=" colar($event)" (keyup)="apertou($event)">
                            <input class="text-center" type="text" id="input2" pInputText maxlength="1"
                                inputmode="numeric" [style]="{'width': '20%'}" [(ngModel)]="codigo[1]"
                                (keyup)="apertou($event)">
                            <input class="text-center" type="text" id="input3" pInputText maxlength="1"
                                inputmode="numeric" [style]="{'width': '20%'}" [(ngModel)]="codigo[2]"
                                (keyup)="apertou($event)">
                            <input class="text-center" type="text" id="input4" pInputText maxlength="1"
                                inputmode="numeric" [style]="{'width': '20%'}" [(ngModel)]="codigo[3]"
                                (keyup)="apertou($event)">
                        </div>
                    </div>
                </div>

                <div class=" grid p-flup-2 flex align-items-center justify-content-center rodape pt-5">
                    <div class="field col" [style]="{width: '100%'}">
                        <button pButton type="button" label="Cancelar" class="col-12" id="botaoRecCodigoCancelar"
                            routerLink="/login"></button>
                    </div>

                    <div class="field col" [style]="{width: '100%'}">
                        <div *ngIf="!loading" [style]="{width: '100%'}">
                            <button pButton type="button" label="Confirmar" class="col-12" id="botaoRecCodigoConfirmar"
                                (click)="enviaCodigo()"></button>
                        </div>
                        <div *ngIf="loading" [style]="{width: '100%'}">
                            <button pButton type="button" label="Carregando" class="col-12" id="botaoLoadingCodRec"
                                [disabled]="true"></button>
                        </div>
                    </div>
                </div>

            </p-card>


        </div>
    </div>
</body>