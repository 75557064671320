<div class="pt-7 overflow-hidden">

    <div class="flex flex-column  pb-3">
        <div class="flex align-items-center justify-content-center  ">
            <div class="flex flex-column ">
                <div class="flex align-items-center justify-content-center">
                    <img src="./assets/iconeBarbearia.svg" class="icone" />
                </div>
                <div class="subtitulo  flex align-items-center justify-content-center">
                    <span>Barbearias</span>
                </div>


                <div class=" negrito">
                    <span> Explorar</span>
                </div>
            </div>
        </div>

        <div class="col-12 sm-12 md-4 ">
            <div class="col-12 flex align-items-center justify-content-center">
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input type="text" class="col-12" pInputText placeholder="Pesquisar" id="pesquisa"
                        (keydown)="filtroEstabelecimento()">
                </span>
            </div>
        </div>

        <div class="flex flex-column pb-3 ">
            <div class="flex justify-content-center flex-wrap ">
                <div class="scroll-div ">
                    <table id="tabela">
                        <tr *ngFor="let estabelecimento of estabelecimento">
                            <td>
                                <div class="linhahorizontalBuscar "></div>
                                <div class="flex flex-column">
                                    <div class="col tamanhoLinhaTabelaBB ">
                                        <div class="formgroup-inline "
                                            (click)="estabelecimentoSelecionada(estabelecimento); editarEstabelecimento(estabelecimento)"
                                            routerLink="/filiarEstabelecimento">
                                            <div class="col-8  field">
                                                <div class="flex flex-column  align-items-start">
                                                    <div class="col">
                                                        <div class="fontcinzanegrita">
                                                            <span>{{estabelecimento.nome}}</span>
                                                        </div>
                                                        <div class="fontcinza pb-2 ">
                                                            <span> {{estabelecimento.rua}}, {{estabelecimento.numero}}</span>
                                                            <div>
                                                                <span> {{estabelecimento.setor}} - {{estabelecimento.cidade}}</span>
                                                            </div>
                                                            <div>
                                                                <span> {{estabelecimento.contato}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="flex flex-column align-items-center ml-4 col-2 field">
                                                <div>
                                                    <img [src]="estabelecimento.filename" class="imgEstabelecimentoBusca" />
                                                </div>
                                                <div class="flex pt-1">
                                                    <div
                                                        class="flex align-items-center justify-content-center fontAvalicacao">
                                                        5
                                                        <div>

                                                            <img src="./assets/iconeAvaliacao.png" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>