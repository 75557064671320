import { UsuarioService } from './../usuario.service';
import { DecodedTokenService } from './../assets/decoded-token.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AlertService } from 'src/alert.service';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss'],
  providers: [MessageService],
  encapsulation: ViewEncapsulation.None
})
export class PerfilComponent implements OnInit {
  codUsu: string = '';
  nome: any = '\xa0\xa0\xa0\xa0\xa0\xa0\xa0'; //CODIGO UFT-8 PARA TER ESPAÇOS EM BRANCO ENQUANTO ESPERA A REQUISIÇÃO
  dtnasc: any = '\xa0\xa0\xa0\xa0\xa0\xa0\xa0';
  email: any = '\xa0\xa0\xa0\xa0\xa0\xa0\xa0';
  telefone: any = '\xa0\xa0\xa0\xa0\xa0\xa0\xa0';

  mostraCarregando: boolean = true;

  constructor(private decodedTokenService: DecodedTokenService, private usuarioService: UsuarioService, private messageService: MessageService,
              private alertService: AlertService) { }

  ngOnInit(): void {
    this.recuperaCodUsu();
    this.meuPerfil();
  }

  recuperaCodUsu() {
    this.codUsu = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }



  meuPerfil(): void {
    this.usuarioService.getPerfil(this.codUsu, this.codUsu).subscribe((res) => {
      this.mostraCarregando = false;
      this.nome = Object(res)[0].nomeparc;
      this.dtnasc = Object(res)[0].dtnasc;
      //console.log(Object(res)[0].dtnasc.substr(8, 2) + '/' + Object(res)[0].dtnasc.substr(5, 2) + '/' + Object(res)[0].dtnasc.substr(0, 4));
      this.telefone = Object(res)[0].telefone;
      this.email = Object(res)[0].email;
    },
      (error) => {
        console.log(error.message)
        console.log('deu ruim')
        this.mostraCarregando = false;
        this.alertService.alert('Algo deu errado.', 'error');
        //this.showError('Atenção', 'Algo deu errado.')
      });
  }


  /**METODO DE TOAST */
  showError(titulo: string, mensagem: string) {
    this.messageService.add({
      key: 'bc',
      severity: 'error',
      summary: titulo,
      detail: mensagem
    });
  }
}
