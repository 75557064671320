<p-toast position="bottom-center" key="bc"></p-toast>
<p-toast position="top-right" key="tr"></p-toast>
<div class="pt-7">

    <div class="flex flex-column  pb-3">
        <div class="flex align-items-center justify-content-center">
            <img src="./assets/iconeCadastrarProdutos.svg" class="iconeProduto" />
        </div>
        <div class=" titulonegritoCadProduto flex align-items-center justify-content-center">
            <span>Produtos</span>
        </div>
    </div>

    <div class="flex flex-column  pb-3">
        <div class="flex justify-content-center flex-wrap ">
            <div class="scroll-divCadProdutos">
                <table id="tabela">
                    <tr *ngFor="let produto of produto">
                        <td>
                            <div class="linhahorizontalCadastrarProduto pt-1"></div>
                            <div class="col-12 sm-12 md-4 ">
                                <div class="flex flex-column">
                                    <div class="flex align-items-center justify-content-center tamanhoLinhaTabela">
                                        <div class="col"
                                            [ngStyle]="produto.ativo==='S'?{'opacity': ' 1'} : {'opacity': ' 0.5'}">
                                            <div class="fontTituloCadProduto">
                                                <span class="xl:text-xl">{{produto.nomeprod}}</span>
                                            </div>
                                            <div class="formgroup-inline justify-content-between flex-wrap">
                                                <div class="fontDescricao pb-2 ">
                                                    <div class="flex gap-2">
                                                        <div
                                                            class="flex align-items-center justify-content-center gap-1 ">
                                                            <img class="" src="./assets/iconeServico.svg" />
                                                            <span class="xl:text-base"> {{produto.descrprod}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex align-items-center justify-content-center ">
                                            <img class="cursor-pointer" src="./assets/iconeMenuAdm.png"
                                                (click)="menu.toggle($event); produtoSelecionado(produto)" />
                                            <p-menu #menu [popup]="true" [model]="itemsProduto"
                                                styleClass="menuCadProdutos"></p-menu>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <p-confirmDialog #cd [closable]="false" id="confirmCadastraProdutos">
            <ng-template pTemplate="footer">
                <button type="button" pButton icon="pi pi-times" label="Retornar" (click)="cd.reject()"></button>
                <button type="button" pButton icon="pi pi-check" label="Confirmar" (click)="cd.accept()"></button>
            </ng-template>
        </p-confirmDialog>

        <div class="flex justify-content-center flex-wrap ">
            <div class="flex align-items-center justify-content-center">
                <footer class="xl:w-5" id="footerCadastrarProdutos">
                    <p-button label="Cadastrar" id="botaoCadastrarProduto" (click)="cadastrarProduto()"
                        [style]="{width: '100%'}">
                    </p-button>
                </footer>
            </div>
        </div>

    </div>
</div>