import { AdministradorGuard } from './../guards/administrador.guard';
import { AgendaService } from './../agenda.service';
import { ProfissionalService } from '../profissional.service';
import { UsuarioService } from './../usuario.service';
import { Component, ElementRef, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

import { CalendarOptions, DateSelectArg, defineFullCalendarElement, EventApi, EventClickArg, EventInput } from '@fullcalendar/web-component';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionGridPlugin from '@fullcalendar/interaction';

import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';

import scrollGridPlugin from '@fullcalendar/scrollgrid';


import { ConfirmationService, MenuItem, MessageService, PrimeNGConfig } from 'primeng/api';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { Profissional } from 'src/assets/profissional';
import { Servico } from 'src/assets/servico';
import { ServicoService } from 'src/servico.service';
import { TranslateService } from '@ngx-translate/core';//TRADUÇÃO
import { Subscription } from 'rxjs';//TRADUÇÃO
import { HorariosDisponiveis } from 'src/assets/horario';
import { HorarioService } from 'src/horario.service';
import { UtilitariosService } from 'src/assets/utilitarios.service';
import { ParceirosService } from 'src/parceiros.service';
import { Cliente } from 'src/assets/cliente';
import { AlertService } from 'src/alert.service';
import { TraducaoService } from 'src/traducao.service';
import { Estabelecimento } from 'src/assets/estabelecimento';
import { PermissoesService } from 'src/permissoes.service';
import { Router } from '@angular/router';
import { LoginService } from 'src/login/login.service';
import { environment } from 'src/environments/environment';
import { Produto } from 'src/assets/produto';
import { EstabelecimentoService } from 'src/estabelecimento.service';
import { PlanoService } from 'src/plano.service';

defineFullCalendarElement();

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss'],
  providers: [ConfirmationService, MessageService],
  encapsulation: ViewEncapsulation.None
})
export class AgendaComponent implements OnInit {

  cliclouDentro: boolean = false;
  cliclou: any;
  count: number = 0;

  //confirmationService: any;

  titulo: string = '';
  descricao: string = '';
  horaIni: string = '';
  horaFin: string = '';

  intervaloSelecionado: any;
  nome: string = ''

  /**VARIAVEIS DE DIALOG */
  // showAgendamento: boolean = false;
  showAgendar: boolean = false;
  showBloqueio: boolean = false;
  showEncaixe: boolean = false;
  showEventoAgdEnc: boolean = false;
  showEventoBloc: boolean = false;
  showCadastra: boolean = false;
  showEditarServico: boolean = false;

  /**VARIAVEIS DO COMPONENT */
  codcli: number = 0;
  codfunc: string = '';
  codusu: string = '';
  codemp: number = 0;

  calendarOptions: any = {};



  currentEvents: EventApi[] = [];

  /**MENU */
  lang: string = "en";
  // subscription: Subscription;

  /**DATA */
  data: Date;
  ontem: Date;

  /**DROPDOWN */
  profissionalSelecionado: any;
  profissionalSelecionadoBlq: any;
  profissionalGeralSelecionado: any;
  profissional: Profissional[] = [];
  profissionalGeral: Profissional[] = [];
  profissionalSelec: Profissional = {}
  todasestabelecimentos: any;

  servicoSelecionado: any;
  servico: Servico[] = [];
  servicoSelec: Servico = {}

  /**VARIAVEIS DO AGENDAMENTO */
  auxDia: any;
  diaSelecionadoAgd: any;
  horarioSelecionado: any = "HH:MM";
  mostraHorarios: boolean = false;
  horarioMatriz: any = [];
  auxMatriz: any = [];
  horDisponiveis: any = [];
  horariosDisponiveis: HorariosDisponiveis[] = [];
  totalMin: number = 0;
  total: number = 0;
  tituloAgendamento: string = ''
  receberNotificacao: boolean = false;

  /**VARIAVEIS DO BLOQUEIO */
  diaSelecionadoBlq: any;
  tituloBlq: string = '';
  descricaoBlq: string = '';
  horaIniBlq: string = '';
  horaFinBlq: string = '';

  /**VARIAVEIS DO ENCAIXE */
  diaSelecionadoEnc: any
  descricaoEnc: string = '';
  horaIniEnc: string = '';
  horaFinEnc: string = '';

  /**VARIAVEIS DO POPUP DO EVENTO */
  planosCabecalho: any[] = [];
  servicosCabecalho: Servico[] = [];
  headerEvento: string = '';
  cliente: string = '';
  contato: string = '';
  email: string = '';
  tipnegdescr: string = '';
  origemEvento: string = '';
  corEvento: string = '';
  tituloEvento: string = '';
  nuidEvento: number = 0;
  descEvento: string = '';
  itensCab: any[] = [];
  tempoTotalAgd: number = 0;
  auxItemsCab: any[] = [];
  obsCab: string = '';
  vlrtot: number = 0;
  vlrdesc: number = 0;
  diaAgd: any;
  servicosEdicao: Produto[] = [];
  cabecalhoSelecionado: any[] = [];
  cabecalho: any[] = [];
  itensCabecalhoEdicao: string = '';
  novoCabecalho: any[] = [];
  codusuEvento: number = 0;
  codempEvento: number = 0;

  totalMinAjuste: number = 0;
  totalAjuste: number = 0;


  /**VARIAVEIS DA LISTAGEM DE CLIENTES */
  clientes: Cliente[] = [];
  clienteSelecionado: any;

  /**VARIAVEIS DO BOTAO DE LOADING */
  teveRespostaCliente: boolean = false;
  teveRespostaProfissional: boolean = false;
  teveRespostaServico: boolean = false;
  teveRespostaprofissionalGeral: boolean = false;
  loading: boolean = false;
  mostraCarregandoServicos: boolean = false;
  adicionandoServico: boolean = true;
  removendoServico: boolean = false;

  /**VARIAVEIS DE Estabelecimento */
  estabelecimento: Estabelecimento[] = [];
  estabelecimentoSelecionada: any;


  /**VARIAVEIS DE CADASTRO DE CLIENTE */
  nomeCliente: any;
  dtNasc: any;
  emailCliente: any;
  telefoneCliente: any;
  cpfCli: any;
  senhaCliente: string = '';
  hoje: Date;
  estabelecimentosCadCliente: Estabelecimento[] = [];
  estabelecimentoCadClienteSelecionada: any;

  aba: boolean = true;
  diaSemana: number = 0;

  /**VARIAVEIS DE PERMISSÕES */
  criaCliente: boolean = false;
  verAgenda: boolean = false;
  verTodasAgenda: boolean = false;
  agendarSi: boolean = false;
  agendarTodos: boolean = false;
  encaixarSi: boolean = false;
  encaixarTodos: boolean = false;
  bloquearSi: boolean = false;
  bloquearTodos: boolean = false;
  faltaSi: boolean = false;
  faltaTodos: boolean = false;
  cancelarSi: boolean = false;
  cancelarTodos: boolean = false;
  permissao: any;

  /**VARIAVEIS DO FUNCIONARIO */
  apelido: string = '';
  codcliFunc: boolean = false;
  codfuncEvento: number = 0;
  h_dom: string = '';
  h_seg: string = '';
  h_ter: string = '';
  h_qua: string = '';
  h_qui: string = '';
  h_sex: string = '';
  h_sab: string = '';
  fotoProfissional: string = '';


  /**VARIAVEIS DAS ABAS DE PLANOS */
  selecionouServicos: boolean = true;
  selecionouPlanos: boolean = false;
  escondePlanos: boolean = true;

  /**VARIAVEIS DE PLANOS */
  planos: any[] = [];
  servicosPlanos: any[] = [];

  /**PLANOS DISPONIVEIS */
  planosDisponiveis: any[] = [];
  servicosPlanosDisponiveis: any[] = [];
  nuplaServico: number = 0;
  planosDisponiveisDrop: any[] = []; //VETOR COM PLANOS (Planos Disponiveis) E SERVIÇOS DO DROPDOWN
  planosDisponiveisDropSelecionado: any[] = [];

  /**PLANOS COMPRADOS */
  meusPlanos: any[] = [];
  servicosMeusPlanos: any[] = [];
  planosClienteDrop: any[] = []; //VETOR COM PLANOS (Planos Do Cliente) E SERVIÇOS DO DROPDOWN
  planosClienteDropSelecionado: any[] = [];

  //planosNovos: any[] = [];
  planoAgendamento: any[] = [];


  constructor(private confirmationService: ConfirmationService, private usuarioService: UsuarioService, private estabelecimentoService: EstabelecimentoService,
    private decodedTokenService: DecodedTokenService, private profissionalService: ProfissionalService, private agendaService: AgendaService,
    private servicoService: ServicoService, public translate: TranslateService, public primeNGConfig: PrimeNGConfig, private router: Router,
    private utilitariosService: UtilitariosService, private horarioService: HorarioService, private parceirosService: ParceirosService,
    private messageService: MessageService, private alertService: AlertService, private traducaoSerice: TraducaoService, private permissoesService: PermissoesService,
    private loginService: LoginService, private planoService: PlanoService) {
    /** NÃO PERMITE QUE DATAS ANTERIORES A HOJE SEJAM ESCOLHIDAS */
    this.data = new Date();
    this.ontem = new Date(this.data);
    this.ontem.setDate(this.data.getDate());
    this.hoje = new Date();


    /**TRADUÇÃO */
    this.traducaoSerice.changeLang();
    // translate.addLangs(['en', 'pt']);
    // translate.setDefaultLang('pt');
    // const browserLang = translate.getBrowserLang();
    // let lang = browserLang!.match(/en|pt/) ? browserLang : 'pt';
    // this.changeLang(lang!);
    // this.subscription = this.translate.stream('primeng').subscribe(data => {
    //   this.primeNGConfig.setTranslation(data);
    // });

    this.todasestabelecimentos = { "nome": "Todas estabelecimentos", "codemp": 0 };
  }

  ngOnInit(): void {
    this.teveRespostaprofissionalGeral = true;
    this.recuperaCodUsu();
    this.recuperaPerfil();
    //this.recuperaCodCli();

    //this.buscarestabelecimentosPorCodCliente();
    // this.buscaEventos();


  }



  recuperaPerfil() {
    this.usuarioService.getPerfil(this.codusu, this.codusu).subscribe((res) => {
      console.log(res)
      this.codemp = Object(res)[0].codemp;
      this.codfunc = Object(res)[0].codfunc;
      this.apelido = Object(res)[0].nomeparc;

      /**VERIFICA SE USARA O CODCLI DO ADM OU FUNCIONARIO */
      if (Object(res)[0].codcli > 0) {
        this.codcli = Object(res)[0].codcli;
        this.codcliFunc = false;
        this.atribuiCalendarOptions();
      } else {
        this.codcli = Object(res)[0].funccodcli;
        this.codcliFunc = true;
        this.h_dom = Object(res)[0].h_dom;
        this.h_seg = Object(res)[0].h_seg;
        this.h_ter = Object(res)[0].h_ter;
        this.h_qua = Object(res)[0].h_qua;
        this.h_qui = Object(res)[0].h_qui;
        this.h_sex = Object(res)[0].h_sex;
        this.h_sab = Object(res)[0].h_sab;
        this.fotoProfissional = Object(res)[0].filename;








      }

      this.buscarestabelecimentosPorCodCliente();
      this.recuperaClientes();
      //this.buscarprofissionalPorCodCliente();
      this.recuperaPermissao();

      console.log(this.codcli + ' → codcli')

      /**SE FOR ADM BUSCA profissionalS PELO CODCLI */
      // if (Number(this.codcli) > 0 ) {
      // if (!this.verTodasAgenda && this.permissao != undefined && this.permissao.length > 0) {
      //   //  this.buscarprofissionalPorCodCliente();
      //   this.profissionalGeral = [{ "apelido": this.apelido, "codfunc": this.codfunc }]
      //   this.profissionalGeralSelecionado = this.profissionalGeral[0].codfunc;
      //   this.resourcesprofissional();
      //   console.log(this.profissionalGeralSelecionado);
      //   this.buscaEventos();
      // }
      // /**SENÃO FUNCIONARIO BUSCA profissionalS PELO CODEMP */
      // else {
      //   this.buscarprofissionalPorCodCliente();
      //   // this.profissionalGeral = [{ "apelido": Object(res)[0].nomeparc, "codfunc": this.codfunc }]
      //   // this.profissionalGeralSelecionado = this.profissionalGeral[0].codfunc;
      //   // this.resourcesprofissional();
      //   // console.log(this.profissionalGeralSelecionado);
      //   // this.buscaEventos();
      // }


    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
        this.teveRespostaprofissionalGeral = false;
        this.alertService.alert('Algo deu errado.', 'error');
        //this.showError('Atenção', 'Algo deu errado.')
      });

  }


  /**METODO QUE DECIDE QUAL TIPO DE FULLCALENDAR VAI MOSTRAR PRIMEIRO*/
  atribuiCalendarOptions() {
    // this.codcli = Number(Object(this.decodedTokenService.getDecodedAccessToken()).data.CODCLI);
    // console.log(this.permissao)

    /**PELO CODCLI/PERMISSÕES DECIDE QUAL TIPO DE FULLCALENDAR VAI MOSTRAR */
    if ((this.codcli > 0 && this.permissao === undefined && !this.codcliFunc) || (this.verTodasAgenda && this.permissao != undefined && this.permissao.length > 0)) {
      this.optionsAdm();
    } else {
      this.optionsFunc();
    }


  }

  recuperaCodUsu() {
    this.codusu = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODUSU;
  }

  /**METODO QUE RECUPERA A LISTA DE estabelecimentoS */
  buscarestabelecimentosPorCodCliente() {

    //  this.estabelecimentoSelecionada = 0;
    this.estabelecimentoService.buscarEstabelecimentosporCodCli(Number(this.codcli), this.codusu).subscribe((res) => {
      this.estabelecimento = res;

      /**VERIFICA AS PERMISSÕES E BUSCA TODAS AS estabelecimentoS DA REDE (QUANDO TEM PERMISSÃO PARA VER TODAS AS AGENDAS) */
      if (this.codcli > 0 && !this.codcliFunc || (this.verTodasAgenda && this.permissao != undefined && this.permissao.length > 0)) {
        this.estabelecimentoSelecionada = this.estabelecimento[0].codemp;
        this.buscaprofissional(this.estabelecimentoSelecionada);
      }

      /**INSERE A OPÇÃO DE TODAS estabelecimentoS */
      // this.estabelecimento.push(this.todasestabelecimentos);
      // this.estabelecimento.sort(function (x, y) {
      //   return Number(x.codemp) - Number(y.codemp);
      // })
    });

  }


  abreMenu() {
    var menu = document.querySelector(".menu"); //recebe o elemento do html do menu
    var classList = "menu"; //é a classe css
    var elementoMenuHtml = menu!.getAttribute("class")!.indexOf("menu--active") !== -1; //verifica se o menu está aparecendo pela classe css

    if (this.cliclouDentro) { //se já estiver clicado
      document.querySelector(".menu")!.setAttribute("class", "menu"); //muda a classe css para fechar o menu
      this.cliclouDentro = false;
    }
    if (!elementoMenuHtml) { //se o menu não estiver aparecendo
      classList += " menu--active"; //adiciona a classe css que abre o menu na string
      menu!.setAttribute("class", classList);  //adiciona a classe css que abre o menu
      this.cliclouDentro = true;
    }
    var element = document.getElementById('pagina'); //pega todo o elemento da pagina
    element!.addEventListener('click', this.fechaMenu); //se clicou fora do botao de menu, fecha o menu
  }

  fechaMenu() {
    this.cliclouDentro = false;
    document.querySelector(".menu")!.setAttribute("class", "menu"); //fecha o menu
  }


  buscaEventos(): void {
    //console.log('entrei no busca de eventos')
    //RECEBEMOS O EVENTO DA REQUISIÇÃO E ATRIBUIMOS AO CALENDAROPTIONS DO HTML
    // console.log(this.profissionalGeralSelecionado + ' selecionado 1')
    let newEvents: any = []
    this.cabecalho = [];
    if (this.aba && this.codcli > 0 && !this.codcliFunc) {
      // if (this.estabelecimentoSelecionada === 0) {
      //for (let i = 0; i < this.profissionalGeral.length; i++) {
      // console.log(this.profissionalGeral[i])
      console.log(this.codcli, this.codfunc!, this.estabelecimentoSelecionada)
      this.agendaService.buscaEventosporCodCliCodFunc(this.codcli, this.codfunc!, this.estabelecimentoSelecionada, this.codusu).subscribe((res) => {
        //console.log(this.profissionalGeralSelecionado + ' selecionado 2')
        console.log(res)
        this.cabecalho = res;
        for (let i = 0; i < res.length; i++) {
          //A = AGENDADO; B = BLOQUEIO; E = ENCAIXE
          if (Object(res)[i].origem === 'B') {
            this.corEvento = 'var(--cor4)'
          } else if (Object(res)[i].origem === 'E') {
            this.corEvento = 'var(--cor11)'
          } else {
            this.corEvento = 'var(--cor1)'
          }
          this.headerEvento = 'Agenda: ' + Object(res)[i].horini + ' - ' + Object(res)[i].horfin
          // console.log(this.headerEvento)
          // JSON.parse(this.cabecalhoFechado[i].json).items
          newEvents.push({
            title: Object(res)[i].titulo, //resumo das tarefas
            date: Object(res)[i].dia,
            description: Object(res)[i].descr, //em quem vai fazer o serviço (cliente)
            start: Object(res)[i].dia + 'T' + Object(res)[i].horini,
            end: Object(res)[i].dia + 'T' + Object(res)[i].horfin,
            color: this.corEvento,
            agenda: this.headerEvento, //header do horario que vai ocorrer do atendimento 
            origem: Object(res)[i].origem, //origem da agenda (AGENDADO/BLOQUEADO/ENCAIXE) 
            itens: JSON.parse(Object(res)[i].json).itens, //items do cabeçalho da agenda
            planos: JSON.parse(Object(res)[i].json).plano, //planos do cabeçalho da agenda
            nuid: Object(res)[i].nuid,
            contato: Object(res)[i].contato,
            email: Object(res)[i].email,
            obs: Object(res)[i].obs,
            codfunc: Object(res)[i].codfunc,
            resourceId: Object(res)[i].codfunc,
            vlrtot: Object(res)[i].vlrtot,
            vlrdesc: Object(res)[i].vlrdesc,
            tipnegdescr: Object(res)[i].tipnegdescr,
            diaAgd: Object(res)[i].dia,
            qtdfinpend: Object(res)[i].qtdfinpend,
            codusu: Object(res)[i].codusu,
            codemp: Object(res)[i].codemp,
          })

        }
        console.log(newEvents)
        var newCalendarOptions = { ...this.calendarOptions, events: newEvents! }
        this.calendarOptions = newCalendarOptions;
        console.log(this.calendarOptions.events)
        this.teveRespostaprofissionalGeral = false;
      });

    } else {
      /**PEGA O CODEMP DO profissional SELECIONADO */
      var codemp = this.profissionalGeral.filter(x => x.codfunc === this.profissionalGeralSelecionado)[0].codemp;
      console.log(0, this.profissionalGeralSelecionado, Number(codemp))
      this.agendaService.buscaEventosporCodCliCodFunc(0, this.profissionalGeralSelecionado, Number(codemp), this.codusu).subscribe((res) => {
        //console.log(this.profissionalGeralSelecionado + ' selecionado 2')
        this.cabecalho = res;
        console.log(res)
        console.log(newEvents)
        console.log('↑ newevents ↑')
        newEvents = [];
        var newCalendarOptions = { ...this.calendarOptions, events: newEvents! }
        this.calendarOptions = newCalendarOptions;
        for (let i = 0; i < res.length; i++) {
          //A = AGENDADO; B = BLOQUEIO; E = ENCAIXE
          if (Object(res)[i].origem === 'B') {
            this.corEvento = 'var(--cor4)'
          } else if (Object(res)[i].origem === 'E') {
            this.corEvento = 'var(--cor11)'
          } else {
            this.corEvento = 'var(--cor1)'
          }
          this.headerEvento = 'Agenda: ' + Object(res)[i].horini + ' - ' + Object(res)[i].horfin
          // console.log(this.headerEvento)
          // JSON.parse(this.cabecalhoFechado[i].json).items
          newEvents.push({
            title: Object(res)[i].titulo, //resumo das tarefas
            date: Object(res)[i].dia,
            description: Object(res)[i].descr, //em quem vai fazer o serviço (cliente)
            start: Object(res)[i].dia + 'T' + Object(res)[i].horini,
            end: Object(res)[i].dia + 'T' + Object(res)[i].horfin,
            color: this.corEvento,
            agenda: this.headerEvento, //header do horario que vai ocorrer do atendimento 
            origem: Object(res)[i].origem, //origem da agenda (AGENDADO/BLOQUEADO/ENCAIXE) 
            itens: JSON.parse(Object(res)[i].json).itens, //items do cabeçalho da agenda
            planos: JSON.parse(Object(res)[i].json).plano, //planos do cabeçalho da agenda
            nuid: Object(res)[i].nuid,
            contato: Object(res)[i].contato,
            email: Object(res)[i].email,
            obs: Object(res)[i].obs,
            codfunc: Object(res)[i].codfunc,
            resourceId: Object(res)[i].codfunc,
            vlrtot: Object(res)[i].vlrtot,
            vlrdesc: Object(res)[i].vlrdesc,
            tipnegdescr: Object(res)[i].tipnegdescr,
            diaAgd: Object(res)[i].dia,
            qtdfinpend: Object(res)[i].qtdfinpend,
            codusu: Object(res)[i].codusu,
            codemp: Object(res)[i].codemp,
          })

        }
        console.log(newEvents)
        var newCalendarOptions = { ...this.calendarOptions, events: newEvents! }
        this.calendarOptions = newCalendarOptions;
        console.log(this.calendarOptions.events)
        this.teveRespostaprofissionalGeral = false;
      });


    }


    // console.log(this.calendarOptions.events)
  }

  buscaprofissional(estabelecimentoSelecionada: number) {
    if (this.estabelecimentoSelecionada === 0) {
      this.buscarprofissionalPorCodCliente();
    } else {
      this.buscarprofissionalPorCodEmp(estabelecimentoSelecionada);
    }
  }

  buscarprofissionalPorCodCliente() { //recupera os profissionals
    this.profissionalService.buscarProfissionalporCodCli(Number(this.codcli), this.codusu).subscribe((res) => {
      //  console.log(res)
      // this.codfunc = Object(res)[0].codfunc
      this.profissionalGeral = res;
      this.profissionalGeralSelecionado = this.profissionalGeral[0].codfunc;
      this.buscaEventos();
      //this.recuperaClientes();
      //   this.teveRespostaprofissionalGeral = false;
      this.resourcesprofissional();
      console.log('codfunc: ' + this.codfunc)
    },
      (error) => {
        console.log(error)
        // console.log('deu ruim')
        this.teveRespostaprofissionalGeral = false;
        this.alertService.alert('Algo deu errado.', 'error');
        //this.showError('Atenção', 'Algo deu errado.')
      });
  }

  /**RECUPERA OS profissionalS DE ACORDO COM A estabelecimento SELECIONADA NO DROPDOWN */
  buscarprofissionalPorCodEmp(estabelecimento: number) { //recupera os profissionals
    this.profissionalService.buscarProfissionalporCodEmp(estabelecimento, this.codusu).subscribe((res) => {
      //  console.log(res)
      // this.codfunc = Object(res)[0].codfunc
      this.profissionalGeral = res;
      this.profissionalGeralSelecionado = this.profissionalGeral[0].codfunc;
      this.buscaEventos();
      //this.recuperaClientes();
      //   this.teveRespostaprofissionalGeral = false;
      this.resourcesprofissional();
      console.log('codfunc: ' + this.codfunc)
    },
      (error) => {
        console.log(error)
        // console.log('deu ruim')
        this.teveRespostaprofissionalGeral = false;
        this.alertService.alert('Algo deu errado.', 'error');
        //this.showError('Atenção', 'Algo deu errado.')
      });
  }





  /**METODO QUE ATRIBUI AS AGENDAS DOS PROFISSIONAIS AO FULLCALENDAR */
  resourcesprofissional() {
    let resources = [];
    for (let i = 0; i < this.profissionalGeral.length; i++) {
      //   console.log(this.profissionalGeral[i])
      // if(this.diaSemana === 0){
      //   auxhor1 = this.profissionalGeral[i].h_dom;
      // }

      let fotoprofissional;
      if (this.profissionalGeral[i].filename !== null) {
        fotoprofissional = environment.imgProfissional + this.profissionalGeral[i].filename;
      } else {
        fotoprofissional = "./assets/logo.png";
      }

      resources.push({
        id: this.profissionalGeral[i].codfunc,
        title: this.profissionalGeral[i].apelido,
        //hor1: '08:00 - 12:00',
        hor1: this.horarioTrabalho(this.profissionalGeral[i]),
        //hor2: '13:00 - 20:00',
        // image: "./assets/logo.png"
        image: fotoprofissional
      })
    }

    var newCalendarOptions = { ...this.calendarOptions, resources: resources! }
    this.calendarOptions = newCalendarOptions
  }

  /**METODO QUE ATRIBUI O HORARIO DE TRABALHO DO FUNCIONARIO DE ACORDO COM O DIA DA SEMANA */
  horarioTrabalho(profissional: any) {
    let auxhor1;
    switch (this.diaSemana) {
      case 0:
        auxhor1 = profissional.h_dom;
        break;

      case 1:
        auxhor1 = profissional.h_seg;
        break;

      case 2:
        auxhor1 = profissional.h_ter;
        break;

      case 3:
        auxhor1 = profissional.h_qua;
        break;

      case 4:
        auxhor1 = profissional.h_qui;
        break;

      case 5:
        auxhor1 = profissional.h_sex;
        break;

      case 6:
        auxhor1 = profissional.h_sab;
        break;
    }

    return auxhor1;
  }



  // preencheAgenda() {
  //   var agenda2: any[] = [{
  //     title: 'wilsin', date: '2022-11-03',
  //     description: 'regertgertgb',
  //     start: '2022-11-05T20:30',
  //     end: '2022-11-05T20:35',
  //     color: 'var(--cor11)',
  //   },
  //   { title: 'event 2', date: '2022-11-02' }]
  //   return agenda2
  // }

  handleDateSelect(selectInfo: DateSelectArg) {
    //const title = prompt('Please enter a new title for your event');
    //console.log(selectInfo.view.calendar)
    this.intervaloSelecionado = selectInfo;
    // this.mostraDialogAgendamento();
    this.intervaloSelecionado.view.calendar.unselect();
    // console.log(selectInfo)
    if (this.titulo) {

      this.salvarEvento()
    }
  }

  salvarEvento() {
    const calendarApi = this.intervaloSelecionado.view.calendar;
    // clear date selection

    // console.log(this.intervaloSelecionado.startStr)
    // console.log(this.intervaloSelecionado.endStr)
    calendarApi.addEvent({
      color: 'var(--cor11)',
      id: '1',
      title: this.titulo,
      start: this.intervaloSelecionado.startStr.substr(0, 10) + 'T' + this.horaIni.substr(0, 2) + this.horaIni.substr(3, 4) + this.intervaloSelecionado.startStr.substr(19, 6),
      //start: this.r.startStr 
      //end: this.r.endStr
      //2022-10-14T11:45:00-03:00
      end: this.intervaloSelecionado.endStr.substr(0, 10) + 'T' + this.horaFin.substr(0, 2) + this.horaFin.substr(3, 4) + this.intervaloSelecionado.endStr.substr(19, 6),
      //allDay: this.r.allDay
    });
    this.intervaloSelecionado = [];
    this.titulo = '';
    this.horaIni = '';
    this.horaFin = '';
    // this.showAgendamento = false;
    console.log(calendarApi)
  }

  handleEventClick(clickInfo: EventClickArg) {
    // var x = Object(this.calendarOptions.events)
    // // for(let i = 0; i< x.length; i++){
    // //   console.log(x[i].nuid)
    // // }

    // x = x.filter((y: { nuid: number; }) => y.nuid != 151)
    // console.log(x)

    // console.log(this.calendarOptions.events)
    // console.log(Object(clickInfo.event.extendedProps).nuid)
    //this.auxItemsCab = []
    // console.log(Object(clickInfo.event.extendedProps).items.length)

    // this.auxItemsCab = Object(clickInfo.event.extendedProps).itens
    // console.log(auxItemsCab)
    this.itensCab = [];
    this.servicosCabecalho = Object(clickInfo.event.extendedProps).itens
    this.planosCabecalho = Object(clickInfo.event.extendedProps).planos;

    this.headerEvento = Object(clickInfo.event.extendedProps).agenda;
    this.cliente = Object(clickInfo.event.extendedProps).description;
    this.contato = Object(clickInfo.event.extendedProps).contato;
    this.email = Object(clickInfo.event.extendedProps).email;
    this.origemEvento = Object(clickInfo.event.extendedProps).origem;
    this.tituloEvento = clickInfo.event.title;
    this.nuidEvento = Object(clickInfo.event.extendedProps).nuid;
    this.descEvento = Object(clickInfo.event.extendedProps).description;
    this.obsCab = Object(clickInfo.event.extendedProps).obs;
    this.codfuncEvento = Object(clickInfo.event.extendedProps).codfunc;
    this.vlrtot = Object(clickInfo.event.extendedProps).vlrtot;
    this.vlrdesc = Object(clickInfo.event.extendedProps).vlrdesc;
    this.tipnegdescr = Object(clickInfo.event.extendedProps).tipnegdescr;
    this.diaAgd = Object(clickInfo.event.extendedProps).diaAgd;
    this.codusuEvento = Object(clickInfo.event.extendedProps).codusu;
    this.codempEvento = Object(clickInfo.event.extendedProps).codemp;

    console.log(this.servicosCabecalho);
    this.tempoTotalAgd = 0;
    if (this.origemEvento === 'A' || this.origemEvento === 'E') {
      for (let i = 0; i < this.servicosCabecalho.length; i++) {
        this.itensCab[i] = this.servicosCabecalho[i].nomeprod + ' (' + this.servicosCabecalho[i].descrprod + ') ' + '- ' + this.tempoEmMinutos(this.servicosCabecalho[i].tempom) + ' min';
        this.tempoTotalAgd += this.tempoEmMinutos(this.servicosCabecalho[i].tempom);
      }

      if (this.planosCabecalho != undefined) {
        for (let i = 0; i < this.planosCabecalho.length; i++) {
          this.itensCab[this.servicosCabecalho.length + (i + 1)] = this.planosCabecalho[i].nome + ' (' + this.planosCabecalho[i].descr + ') ';
        }
      }
    }


    /**ATRIBUI O CABEÇALHO SELECIONADO PARA SER USADO NA EDIÇÃO DA COMANDA */
    this.cabecalhoSelecionado = this.cabecalho.filter(x => x.nuid === this.nuidEvento);
    console.log(this.cabecalhoSelecionado)


    // if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
    //   clickInfo.event.remove();
    // }

    if (this.origemEvento === 'A' || this.origemEvento === 'E') {
      this.showEventoAgdEnc = true;
    }
    if (this.origemEvento === 'B') {
      this.showEventoBloc = true;
    }
  }


  preenchePopupEvento(cabecalho: any) {
    this.headerEvento = 'Agenda: ' + cabecalho.horini + ' - ' + cabecalho.horfin;
    this.cliente = cabecalho.descr;
    this.contato = cabecalho.contato;
    this.email = cabecalho.email;
    this.origemEvento = cabecalho.origem;
    this.tituloEvento = cabecalho.titulo;
    this.nuidEvento = cabecalho.nuid;

    this.obsCab = cabecalho.obs;
    this.codfuncEvento = cabecalho.codfunc;
    this.vlrtot = cabecalho.vlrtot;
    this.vlrdesc = cabecalho.vlrdesc;
    this.tipnegdescr = cabecalho.tipnegdescr;
    this.diaAgd = cabecalho.dia;



    this.servicosCabecalho = JSON.parse(cabecalho.json).itens;
    this.planosCabecalho = JSON.parse(cabecalho.json).plano;

    console.log(this.planosCabecalho)

    this.itensCab = [];
    this.tempoTotalAgd = 0;
    if (this.origemEvento === 'A' || this.origemEvento === 'E') {
      for (let i = 0; i < this.servicosCabecalho.length; i++) {
        console.log(this.servicosCabecalho[i])
        this.itensCab[i] = this.servicosCabecalho[i].nomeprod + ' (' + this.servicosCabecalho[i].descrprod + ') ' + '- ' + this.tempoEmMinutos(this.servicosCabecalho[i].tempom) + ' min';
        this.tempoTotalAgd += this.tempoEmMinutos(this.servicosCabecalho[i].tempom);
        console.log(this.tempoTotalAgd)
        console.log(this.servicosCabecalho[i].tempMin)
      }
    }

    if (this.origemEvento === 'A' || this.origemEvento === 'E') {
      console.log(this.origemEvento)
      this.showEventoAgdEnc = true;
    }
    if (this.origemEvento === 'B') {
      console.log(this.origemEvento)
      this.showEventoBloc = true;
    }
  }




  // organizaServicos() {
  //   for (let i = 0; i < this.auxItemsCab.length; i++) {
  //     this.itemsCab[i] = this.auxItemsCab[i].nomeprod + ' (' + this.auxItemsCab[i].descrprod + ') ' + '- ' + this.auxItemsCab[i].tempMin + ' min';
  //   }
  // }

  handleEvents(events: EventApi[]) {
    this.currentEvents = events;
  }


  mostraDialogAgendamento() {
    //this.showAgendamento = true;
  }


  /**METODO QUE REDIRECIONA PARA O MOV FINANCEIRO DE ACORDO COM O NUID SELECIONADO*/
  redirecionaMovFinanceiro() {
    console.log(this.nuidEvento);
    this.agendaService.setNuid(this.nuidEvento);
    this.router.navigate([('/financeiro')]);
  }

  /**RESETA OS CAMPOS DE DIA E HORARIO SEMPRE QUE ALTERAR OS SERVIÇOS */
  mudaServico() {
    this.horarioSelecionado = "HH:MM";
    this.diaSelecionadoAgd = [];
  }


  /**MOSTRA O AGENDAR AO APERTAR F8 */
  @HostListener('document:keydown.F8', ['$event'])
  handleF8KeyPress(event: KeyboardEvent) {
    this.profissional = [];
    this.servico = [];
    this.clienteSelecionado = [];
    this.servicoSelecionado = [];
    this.planosDisponiveisDropSelecionado = [];
    this.planosClienteDropSelecionado = [];
    this.profissionalSelecionado = [];
    this.diaSelecionadoAgd = [];
    this.horarioSelecionado = "HH:MM";
    this.showAgendar = true;
  }

  /**MOSTRA O ENCAIXAR AO APERTAR F9 */
  @HostListener('document:keydown.F9', ['$event'])
  handleF9KeyPress(event: KeyboardEvent) {
    this.profissional = [];
    this.servico = [];
    this.clienteSelecionado = [];
    this.servicoSelecionado = [];
    this.planosDisponiveisDropSelecionado = [];
    this.planosClienteDropSelecionado = [];
    this.profissionalSelecionado = []
    this.diaSelecionadoEnc = [];
    this.horaIniEnc = '';
    this.horaFinEnc = '';
    this.descricaoEnc = '';
    this.obsCab = '';
    this.showEncaixe = true;
  }

  /**MOSTRA O BLOQUEAR AO APERTAR F10 */
  @HostListener('document:keydown.F10', ['$event'])
  handleF10KeyPress(event: KeyboardEvent) {
    this.profissionalSelecionadoBlq = '';
    this.tituloBlq = '';
    this.horaIniBlq = '';
    this.horaFinBlq = '';
    this.diaSelecionadoBlq = [];
    this.descricaoBlq = '';
    this.obsCab = '';
    this.showBloqueio = true;
  }

  /**MOSTRA O BLOQUEAR AO APERTAR F2 */
  @HostListener('document:keydown.F2', ['$event'])
  handleF2KeyPress(event: KeyboardEvent) {
    this.nomeCliente = null;
    this.dtNasc = null;
    this.emailCliente = null;
    this.telefoneCliente = null;
    this.estabelecimentoCadClienteSelecionada = null;
    this.showCadastra = true;
  }


  mostraDialogBloqueio() {
    this.profissionalSelecionadoBlq = '';
    this.tituloBlq = '';
    this.horaIniBlq = '';
    this.horaFinBlq = '';
    this.diaSelecionadoBlq = [];
    this.descricaoBlq = '';
    this.obsCab = '';
    this.showBloqueio = true;
  }

  mostraDialogCadastroCli() {
    this.nomeCliente = null;
    this.dtNasc = null;
    this.emailCliente = null;
    this.telefoneCliente = null;
    this.estabelecimentoCadClienteSelecionada = null;
    this.showCadastra = true;
    this.cpfCli = null;
  }

  mostraDialogAgendar() {
    this.profissional = [];
    this.servico = [];
    this.clienteSelecionado = [];
    this.servicoSelecionado = [];
    this.planosDisponiveisDropSelecionado = [];
    this.planosClienteDropSelecionado = [];
    this.planosClienteDrop = [];
    this.planosDisponiveisDrop = [];
    this.profissionalSelecionado = [];
    this.diaSelecionadoAgd = [];
    this.horarioSelecionado = "HH:MM";
    this.showAgendar = true;
    this.receberNotificacao = false;
  }

  mostraDialogEncaixe() {
    this.profissional = [];
    this.servico = [];
    this.clienteSelecionado = [];
    this.servicoSelecionado = [];
    this.planosDisponiveisDropSelecionado = [];
    this.planosClienteDropSelecionado = [];
    this.profissionalSelecionado = []
    this.diaSelecionadoEnc = [];
    this.horaIniEnc = '';
    this.horaFinEnc = '';
    this.descricaoEnc = '';
    this.obsCab = '';
    this.showEncaixe = true;
  }

  /**DROPDOWN DE PROFISSIONAIS */


  /**METODOS PARA AGENDAMENTO */
  buscarprofissionalPorCodEmpresa(codemp: number) {
    this.teveRespostaProfissional = true;
    setTimeout(() => {
      this.profissionalService.buscarProfissionalporCodEmp(codemp, this.codusu).subscribe((res) => {
        this.teveRespostaProfissional = false;
        console.log(res)
        this.profissional = res;
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.teveRespostaProfissional = false;
          this.showAgendar = false;
          this.showEncaixe = false;
          this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')

        })
    }, 500);
  }

  buscarServicoPorCodFuncionario(profissionalSelecionado: any) {
    this.teveRespostaServico = true;
    setTimeout(() => {
      this.servicoService.buscarServicoPorCodFunc(profissionalSelecionado, this.codusu).subscribe((res) => {
        /**RESETA O VETOR DE SERVIÇOS PARA QUE NÃO ACUMULE SERVIÇO DE OUTRO PROFISSIONAL */
        this.servicoSelecionado = [];

        this.teveRespostaServico = false;
        this.servico = res;
        for (let i = 0; i < this.servico.length; i++) {
          this.servico[i].minutos = this.tempoEmMinutos(this.servico[i].tempom);
        }




        //VERIFICA QUAIS SERVIÇOS DO BARBEIRO ESTÃO NO PLANO DO CLIENTE E MONTA O VETOR DE SERVIÇOS (ADICIONA SEQ, NUPLA, STATUS,NUPLAC...)
        let aux = [];
        for (let i = 0; i < this.meusPlanos.length; i++) {
          // console.log(this.meusPlanos[i].itens)
          for (let j = 0; j < this.meusPlanos[i].itens.length; j++) {
            //console.log(this.meusPlanos[i].itens[j])

            let codprods = this.meusPlanos[i].itens[j].codprods.split(',').map(Number);
            //console.log(codprods)
            let filtraCodprods = this.servico.filter((x: any) => codprods.includes(x.codprod))
            //console.log(filtraCodprods)

            let auxStatus = this.meusPlanos[i].itens[j].status.replace(/\s+/g, '')

            filtraCodprods[0] = {
              ...filtraCodprods[0], nupla: this.meusPlanos[i].nupla, status: auxStatus,
              nuplac: this.meusPlanos[i].itens[j].nuplac, seq: this.meusPlanos[i].itens[j].seq
            }
            aux.push(filtraCodprods[0])
            // console.log(this.servicosMeusPlanos)
          }
        }
        this.servicosMeusPlanos = aux;
        //console.log(this.servicosMeusPlanos)
        this.verificaServicoCabecalho();
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.teveRespostaServico = false;
          this.showAgendar = false;
          this.showEncaixe = false;
          this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')

        })
    }, 500);
  }

  /**METODOS DE HORARIOS */
  agendaHorario(horario: any) {
    this.horarioSelecionado = horario;
    this.mostraHorarios = false;
  }
  horarios() {
    this.mostraHorarios = true;
  }



  buscaHorarioPorCodFuncEDia(dia: any) {
    this.horarioService.buscaHorarioPorCodFuncEDia(this.profissionalSelecionado, dia, this.codusu).subscribe((res) => {
      this.auxMatriz = [];
      this.horDisponiveis = []
      this.horariosDisponiveis = res;
      this.verificaHorariosEncaixe();
      for (var i in this.horDisponiveis) {
        this.auxMatriz.push([i, this.horDisponiveis[i]])
      }
      console.log(this.horDisponiveis)
      this.fazMatriz();
    });
  }

  fazMatriz() {
    this.horarioMatriz = []; //    this.horarioMatriz = this.horDisponiveis;
    const linhas = 4;
    for (let i = 0; i < this.auxMatriz.length; i += linhas) {
      this.horarioMatriz.push(this.auxMatriz.slice(i, i + linhas));
    }
    console.log(this.horarioMatriz)
  }

  selecionouDiaAgd() {
    this.horarioMatriz = [];
    // this.auxDia = this.diaSelecionadoAgd.getUTCFullYear() + '-' + (this.diaSelecionadoAgd.getUTCMonth() + 1) + '-' + this.diaSelecionadoAgd.getUTCDate();
    this.auxDia = this.utilitariosService.ajustaDataBanco(this.diaSelecionadoAgd)
    this.buscaHorarioPorCodFuncEDia(this.auxDia);
  }

  selecionouDiaEnc() {
    this.horarioMatriz = [];
    //  this.auxDia = this.diaSelecionadoEnc.getUTCFullYear() + '-' + (this.diaSelecionadoEnc.getUTCMonth() + 1) + '-' + this.diaSelecionadoEnc.getUTCDate();
    this.auxDia = this.utilitariosService.ajustaDataBanco(this.diaSelecionadoEnc)
    this.buscaHorarioPorCodFuncEDia(this.auxDia);
  }
  /**METODO QUE VERIFICA OS HORARIOS DISPONIVEIS PARA O SERVIÇO */
  verificaHorariosEncaixe() {
    var bloquinhos = this.totalMin / 15;
    var horAux = [];
    var horarioCandidato: any;
    var countDisp = 0;
    if (bloquinhos - Math.trunc(bloquinhos) > 0) {
      bloquinhos = Math.trunc(bloquinhos) + 1;
    }
    for (let i = 0; i < this.horariosDisponiveis.length; i++) {
      horarioCandidato = this.horariosDisponiveis[i].horario
      for (let j = 0; j < bloquinhos; j++) {
        horAux.push(horarioCandidato)
        var auxFiltro = this.horariosDisponiveis.filter((x: any) => (x.horario).toString() === horarioCandidato)
        countDisp += auxFiltro.length;
        horarioCandidato = this.utilitariosService.somaMinutos(horarioCandidato, 15);
        if (countDisp == bloquinhos) {
          this.horDisponiveis.push(this.horariosDisponiveis[i]);
        }
      }
      countDisp = 0;
    }
  }

  somaTotalMin() {
    console.log('fewfwf')
    this.total = 0;
    for (let i = 0; i < this.servicoSelecionado.length; i++) {
      this.total += this.servicoSelecionado[i].preco!;
    }

    this.totalMin = 0;
    for (let i = 0; i < this.servicoSelecionado.length; i++) {
      // console.log(Number(this.servicoSelecionado[i].tempom!.substr(0, 2)) * 60 + 'HORAS') //HORAS
      // console.log(Number(this.servicoSelecionado[i].tempom!.substr(3, 2)) + 'MINUTOS') //MINUTOS
      this.totalMin += (Number(this.servicoSelecionado[i].tempom!.substr(0, 2)) * 60) + Number(this.servicoSelecionado[i].tempom!.substr(3, 2))
      // console.log('↑ minutos serviço normal ------------------------------------')

    }
    this.somaPlanoAgd();
    //this.criaTituloAgendamento(this.servicoSelecionado);
    // console.log(this.totalMin)
    //console.log(this.total)
    // console.log(this.tituloAgendamento)
  }

  /**METODO QUE SOMA O VALOR DO PLANO NA COMANDA */
  somaPlanoAgd() {
    /**SOMA O TEMPO DO PLANO DO CLIENTE */
    for (let i = 0; i < this.planosClienteDropSelecionado.length; i++) {
      //   console.log(Number(this.planosClienteDropSelecionado[i].tempom!.substr(0, 2)) * 60 + 'HORAS') //HORAS
      // console.log(Number(this.planosClienteDropSelecionado[i].tempom!.substr(3, 2)) + 'MINUTOS') //MINUTOS
      this.totalMin += (Number(this.planosClienteDropSelecionado[i].tempom!.substr(0, 2)) * 60) + Number(this.planosClienteDropSelecionado[i].tempom!.substr(3, 2))
    }




    if (this.planosDisponiveisDropSelecionado.length > 0) {
      for (let i = 0; i < this.planoAgendamento.length; i++) {
        this.total += this.planoAgendamento[i].preco!;
      }
    }

    for (let i = 0; i < this.planosDisponiveisDropSelecionado.length; i++) {
      // console.log(Number(this.planosDisponiveisDropSelecionado[i].tempom!.substr(0, 2)) * 60 + 'HORAS') //HORAS
      // console.log(Number(this.planosDisponiveisDropSelecionado[i].tempom!.substr(3, 2)) + 'MINUTOS') //MINUTOS
      this.totalMin += (Number(this.planosDisponiveisDropSelecionado[i].tempom!.substr(0, 2)) * 60) + Number(this.planosDisponiveisDropSelecionado[i].tempom!.substr(3, 2))
      // console.log('↑ minutos do plano')
    }
    console.log(this.total)
  }



  /**VALIDA SE A FAIXA DE HORARIO PASSADO PARA O ENCAIXE É SUFICIENTE PARA FAZER OS SERVIÇOS*/
  validaHorarioEncaixe() {
    if (this.calcularDiferencaMinutos(this.horaIniEnc, this.horaFinEnc) >= this.totalMin) {
      return true;
    } else {
      return false;
    }
  }


  /**METODO QUE CALCULA QUANTOS MINUTOS SE PASSARAM ENTRE A HORA INICIAL E FINAL DO ENCAIXE */
  calcularDiferencaMinutos(horarioInicial: string, horarioFinal: string): number {
    const [horaInicial, minutoInicial] = horarioInicial.split(':').map(Number);
    const [horaFinal, minutoFinal] = horarioFinal.split(':').map(Number);

    const minutosIniciais = horaInicial * 60 + minutoInicial;
    const minutosFinais = horaFinal * 60 + minutoFinal;

    const diferencaMinutos = minutosFinais - minutosIniciais;

    return diferencaMinutos;
  }


  /**METODO QUE CRIA O TITULO A SER ENVIADO DO AGENDAMENTO */
  criaTituloAgendamento(servicoSelecionado: any) {
    this.tituloAgendamento = '';
    var auxTitulo = []
    console.log(servicoSelecionado)
    for (let i = 0; i < servicoSelecionado.length; i++) {
      if (i == 0) {
        auxTitulo[i] = servicoSelecionado[i].nomeprod;
      } else {
        auxTitulo[i] = ' ' + servicoSelecionado[i].nomeprod;
      }
    }

    /**ADICIONA A DESCRIÇÃO DOS PLANOS NO TITULO*/
    if (this.planos.length > 0) {
      for (let i = 0; i < this.planos.length; i++) {
        // if (this.planos[i]) {

        if (i == 0 && servicoSelecionado.length === 0) {
          auxTitulo[i] = this.planos[i].nome;
        } else {
          auxTitulo[servicoSelecionado.length + i] = ' ' + this.planos[i].nome;
        }
      }
      //}
    }

    if (this.planoAgendamento.length > 0) {
      for (let i = 0; i < this.planoAgendamento.length; i++) {
        // if (this.planoAgendamento[i]) {

        if (i == 0 && servicoSelecionado.length === 0) {
          auxTitulo[i] = this.planoAgendamento[i].nome;
        } else {
          auxTitulo[servicoSelecionado.length + i] = ' ' + this.planoAgendamento[i].nome;
        }
      }
      //}
    }




    this.tituloAgendamento = auxTitulo.toString()
    console.log(this.tituloAgendamento)
  }

  criarAgenda() {
    this.loading = true;

    let auxCheckbox;
    if (this.receberNotificacao === true) {
      auxCheckbox = 'S'
    } else {
      auxCheckbox = 'N'
    }

    /**VERIFICA SE TEM PERMISSÃO PARA ESSA AÇÃO */
    if (this.codcliFunc && this.permissao != undefined && this.permissao.length > 0 && !this.agendarSi && !this.agendarTodos && this.profissionalSelecionado === this.codfunc) {
      this.showError('Atenção', 'Ação não permitida.')
      this.loading = false;
      return
    }

    if (this.codcliFunc && !this.agendarTodos && this.permissao != undefined && this.permissao.length > 0 && this.profissionalSelecionado != this.codfunc) {
      this.showError('Atenção', 'Ação não permitida.')
      this.loading = false;
      return
    }

    if (this.utilitariosService.validaCampo([this.clienteSelecionado, this.profissionalSelecionado, this.diaSelecionadoAgd, this.horarioSelecionado])) {
      console.log('É necessário preencher todos os campos.')
      this.showError('Atenção', 'É necessário preencher todos os campos.')
      this.loading = false;
      return;
    }

    if (this.checaServicos()) {
      return;
    }


    //ADICIONA OS SERVIÇOS DO PLANO A SEREM REALIZADOS, NO VETOR DE PLANO
    for (let i = 0; i < this.planoAgendamento.length; i++) {
      let auxCodprods = [];
      for (let j = 0; j < this.planosDisponiveisDropSelecionado.length; j++) {
        if (this.planosDisponiveisDropSelecionado[j].nupla !== undefined) {
          if (this.planoAgendamento[i].nupla === this.planosDisponiveisDropSelecionado[j].nupla) {
            delete this.planoAgendamento[i].servicos;
            auxCodprods.push(this.planosDisponiveisDropSelecionado[j].codprod)
          }
        }
      }
      this.planoAgendamento[i].codprods = auxCodprods;
    }


    /**PEGA O CODEMP DO profissional SELECIONADO */
    var codemp = this.profissional.filter(x => x.codfunc === this.profissionalSelecionado)[0].codemp;

    /**ADICIONA OS SERVIÇOS DO PLANO COMPRADO */
    for (let i = 0; i < this.planosDisponiveisDropSelecionado.length; i++) {
      this.servicoSelecionado.push(this.planosDisponiveisDropSelecionado[i])
    }

    /**ADICIONA OS SERVIÇOS DO PLANO DO CLIENTE */
    for (let i = 0; i < this.planosClienteDropSelecionado.length; i++) {
      this.servicoSelecionado.push(this.planosClienteDropSelecionado[i])
    }

    /**CRIA O TITULO DO AGENDAMENTO (SERVIÇOS A SEREM FEITOS) */
    this.criaTituloAgendamento(this.servicoSelecionado);

    console.log(this.servicoSelecionado)

    this.agendaService.createAgenda(this.horarioSelecionado, this.utilitariosService.somaMinutos(this.horarioSelecionado, this.totalMin),
      this.auxDia, this.profissionalSelecionado, this.clienteSelecionado.codusu, codemp, Number(this.total.toFixed(2)), 'AGENDADO', auxCheckbox, this.servicoSelecionado,
      this.tituloAgendamento, this.clienteSelecionado.nomeparc, this.codusu, 0, null, this.planoAgendamento).
      subscribe((res) => {
        console.log(res);
        this.profissional = [];
        this.servico = [];
        this.clienteSelecionado = [];
        this.servicoSelecionado = [];
        this.planosDisponiveisDropSelecionado = [];
        this.planosClienteDropSelecionado = [];
        this.profissionalSelecionado = []
        this.diaSelecionadoAgd = [];
        this.horarioSelecionado = "HH:MM";
        this.showAgendar = false;
        this.loading = false;
        this.buscaEventos();
      },
        (error) => {
          console.log(error)
          this.profissional = [];
          this.servico = [];
          this.clienteSelecionado = [];
          this.servicoSelecionado = [];
          this.planosDisponiveisDropSelecionado = [];
          this.planosClienteDropSelecionado = [];
          this.profissionalSelecionado = []
          this.diaSelecionadoAgd = [];
          this.horarioSelecionado = "HH:MM";
          this.showAgendar = false;
          this.loading = false;
          this.alertService.alert('Algo deu errado.', 'error');
        });
  }
  criarEncaixe() {
    this.loading = true;



    /**VERIFICA SE TEM PERMISSÃO PARA ESSA AÇÃO */
    if (this.codcliFunc && this.permissao != undefined && this.permissao.length > 0 && !this.encaixarSi && !this.encaixarTodos && this.profissionalSelecionado === this.codfunc) {
      this.showError('Atenção', 'Ação não permitida.')
      this.loading = false;
      return
    }

    if (this.codcliFunc && !this.encaixarTodos && this.permissao != undefined && this.permissao.length > 0 && this.profissionalSelecionado != this.codfunc) {
      this.showError('Atenção', 'Ação não permitida.')
      this.loading = false;
      return
    }



    if (this.utilitariosService.validaCampo([this.clienteSelecionado, this.profissionalSelecionado, this.servicoSelecionado, this.horaFinEnc, this.horaIniEnc, this.diaSelecionadoEnc])) {
      console.log('É necessário preencher todos os campos.')
      this.showError('Atenção', 'É necessário preencher todos os campos.')
      this.loading = false;
      return;
    }

    if (this.utilitariosService.validaHora(this.horaIniEnc, this.horaFinEnc)) {
      console.log('Horário para Encaixe informado não é válido.')
      this.showError('Atenção', 'Horário para Encaixe informado não é válido.')
      this.loading = false;
      return;
    }
    //this.somaTotalMin();


    if (!this.validaHorarioEncaixe()) {
      this.showError('Atenção', 'Tempo de encaixe informado é insuficiente para realização dos serviços.')
      this.loading = false;
      return;
    }

    console.log(this.tituloAgendamento)
    /**PEGA O CODEMP DO profissional SELECIONADO */
    var codemp = this.profissional.filter(x => x.codfunc === this.profissionalSelecionado)[0].codemp;

    console.log(this.diaSelecionadoEnc)
    console.log(this.utilitariosService.ajustaDataBanco(this.diaSelecionadoEnc))

    /**CRIA O TITULO DO AGENDAMENTO (SERVIÇOS A SEREM FEITOS) */
    this.criaTituloAgendamento(this.servicoSelecionado);

    this.agendaService.createAgendamentoEncaixe(this.horaIniEnc, this.horaFinEnc, this.auxDia, this.profissionalSelecionado, this.clienteSelecionado.codusu,
      codemp, Number(this.total.toFixed(2)), 'ENCAIXE', this.servicoSelecionado, this.tituloAgendamento, this.clienteSelecionado.nomeparc, this.descricaoEnc, this.codusu).
      subscribe((res) => {
        console.log(res);
        this.profissional = [];
        this.servico = [];
        this.clienteSelecionado = [];
        this.servicoSelecionado = [];
        this.planosDisponiveisDropSelecionado = [];
        this.planosClienteDropSelecionado = [];
        this.profissionalSelecionado = []
        this.diaSelecionadoEnc = [];
        this.horaIniEnc = '';
        this.horaFinEnc = '';
        this.descricaoEnc = '';
        this.showEncaixe = false;
        this.loading = false;
        this.buscaEventos();
      },
        (error) => {
          console.log(error)
          this.profissional = [];
          this.servico = [];
          this.clienteSelecionado = [];
          this.servicoSelecionado = [];
          this.planosDisponiveisDropSelecionado = [];
          this.planosClienteDropSelecionado = [];
          this.profissionalSelecionado = []
          this.diaSelecionadoEnc = [];
          this.horaIniEnc = '';
          this.horaFinEnc = '';
          this.descricaoEnc = '';
          this.showEncaixe = false;
          this.loading = false;
          this.alertService.alert('Algo deu errado.', 'error');
        });
  }

  criarBloqueio() {
    this.loading = true;

    /**VERIFICA SE TEM PERMISSÃO PARA ESSA AÇÃO */
    if (this.codcliFunc && this.permissao != undefined && this.permissao.length > 0 && !this.bloquearSi && !this.bloquearTodos && this.profissionalSelecionadoBlq === this.codfunc) {
      this.showError('Atenção', 'Ação não permitida.')
      this.loading = false;
      return
    }

    if (this.codcliFunc && !this.bloquearTodos && this.permissao != undefined && this.permissao.length > 0 && this.profissionalSelecionadoBlq != this.codfunc) {
      this.showError('Atenção', 'Ação não permitida.')
      this.loading = false;
      return
    }



    if (this.utilitariosService.validaCampo([this.horaIniBlq, this.horaFinBlq, this.tituloBlq, this.diaSelecionadoBlq, this.profissionalSelecionadoBlq])) {
      console.log('É necessário preencher todos os campos.')
      this.showError('Atenção', 'É necessário preencher todos os campos.')
      this.loading = false;
      return;
    }

    if (this.utilitariosService.validaHora(this.horaIniBlq, this.horaFinBlq)) {
      console.log('Horário para Bloqueio informado não é válido.')
      this.showError('Atenção', 'Horário para Bloqueio informado não é válido.')
      this.loading = false;
      return;
    }

    console.log(this.tituloBlq + ': titulo')
    console.log(this.diaSelecionadoBlq + ': dia')
    console.log(this.horaIniBlq + ': horaIniBlq')
    console.log(this.horaFinBlq + ': horaFinBlq')
    console.log(this.descricaoBlq + ': descricaoBlq')

    /**PEGA O CODEMP DO profissional SELECIONADO */
    var codemp = this.profissionalGeral.filter(x => x.codfunc === this.profissionalSelecionadoBlq)[0].codemp;

    this.agendaService.createBloqueio(this.horaIniBlq, this.horaFinBlq, this.utilitariosService.ajustaDataBanco(this.diaSelecionadoBlq)!, this.profissionalSelecionadoBlq, this.codusu,
      codemp, 'BLOQUEIO', this.tituloBlq, this.descricaoBlq, this.descricaoBlq, this.codusu).subscribe((res) => {
        console.log(res)
        this.tituloBlq = '';
        this.horaIniBlq = '';
        this.horaFinBlq = '';
        this.diaSelecionadoBlq = [];
        this.descricaoBlq = '';
        this.profissionalSelecionadoBlq = '';
        this.showBloqueio = false;
        this.loading = false;

        this.buscaEventos();
      },
        (error) => {
          console.log(error)
          this.tituloBlq = '';
          this.horaIniBlq = '';
          this.horaFinBlq = '';
          this.diaSelecionadoBlq = [];
          this.profissionalSelecionadoBlq = '';
          this.descricaoBlq = '';
          this.showBloqueio = false;
          this.loading = false;
          this.alertService.alert('Algo deu errado.', 'error');
        })
  }


  /**METODO QUE RECUPERA OS CLIENTES DA REDE */
  recuperaClientes() {//fazer se codfunc = 0 passa codcli e vice-versa
    this.teveRespostaCliente = true;
    setTimeout(() => {
      this.parceirosService.buscaClientesGeralPorCodFunc('0', this.codcli, this.codusu).subscribe((res) => {
        this.teveRespostaCliente = false;
        // this.clientes = res;
        console.log(res)

        this.clientes = res.filter((x: any) => x.tipcli === 'CLIENTE');

        for (let i = 0; i < this.clientes.length; i++) {
          this.clientes[i].telefone = (this.clientes[i].telefone!).replace(/\D/g, '');
        }
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.teveRespostaCliente = false;
          this.showAgendar = false;
          this.showEncaixe = false;
          this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')
        })
    }, 500);
  }



  /**RECUPERA OS CLIENTES DE ACORDO COM A estabelecimento SELECIONADA NO DROPDOWN */
  recuperaClientesPorCodEmp(estabelecimento: number) {//fazer se codfunc = 0 passa codcli e vice-versa
    this.teveRespostaCliente = true;
    setTimeout(() => {
      this.parceirosService.buscaClientesGeralPorCodEmp(estabelecimento, this.codusu).subscribe((res) => {
        this.teveRespostaCliente = false;
        this.clientes = res;
        console.log(res)
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.teveRespostaCliente = false;
          this.showAgendar = false;
          this.showEncaixe = false;
          this.alertService.alert('Algo deu errado.', 'error');
          //this.showError('Atenção', 'Algo deu errado.')
        })
    }, 500);
  }


  cancelarAgendamento(nuid: number) {
    this.agendaService.cancelarAgendamento(nuid, this.codusu, 'NULL').subscribe((res) => {
      var oldEvents = Object(this.calendarOptions.events)
      var newEvents = oldEvents.filter((oldEvents: { nuid: number; }) => oldEvents.nuid != nuid)
      var newCalendarOptions = { ...this.calendarOptions, events: newEvents }
      this.calendarOptions = newCalendarOptions
    })
  }

  confirmarFalta(nuid: number) {
    this.agendaService.cancelarAgendamento(nuid, this.codusu, 'FALTA').subscribe((res) => {
      var oldEvents = Object(this.calendarOptions.events)
      var newEvents = oldEvents.filter((oldEvents: { nuid: number; }) => oldEvents.nuid != nuid)
      var newCalendarOptions = { ...this.calendarOptions, events: newEvents }
      this.calendarOptions = newCalendarOptions
    })
  }



  confirmaCancelamentoBlq(nuid: number) {

    /**VERIFICA SE TEM PERMISSÃO PARA ESSA AÇÃO */
    if (this.codcliFunc && this.permissao != undefined && this.permissao.length > 0 && !this.cancelarSi && !this.cancelarTodos && this.codfuncEvento.toString() == this.codfunc) {
      this.showError('Atenção', 'Ação não permitida.')
      this.loading = false;
      return
    }

    if (this.codcliFunc && !this.cancelarTodos && this.permissao != undefined && this.permissao.length > 0 && this.codfuncEvento.toString() != this.codfunc) {
      this.showError('Atenção', 'Ação não permitida.')
      this.loading = false;
      return
    }


    this.confirmationService.confirm({
      message: 'Deseja mesmo cancelar esse Bloqueio ?',
      header: 'Cancelar',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.cancelarAgendamento(nuid);
        this.showEventoBloc = false;
      },
      reject: () => {
      }
    });
  }

  confirmaCancelamentoAgdEnc(nuid: number) {
    /**VERIFICA SE O AGENDAMENTO É ANTERIOR AO DIA DE HOJE */
    const hoje = this.utilitariosService.ajustaDataBanco(this.hoje);
    if (!(this.diaAgd >= hoje!)) {
      this.showError('Atenção', 'Não é permitido cancelar agendamentos anteriores ao dia de hoje.')
      return;
    }

    /**VERIFICA SE TEM PERMISSÃO PARA ESSA AÇÃO */
    if (this.codcliFunc && this.permissao != undefined && this.permissao.length > 0 && !this.cancelarSi && !this.cancelarTodos && this.codfuncEvento.toString() == this.codfunc) {
      this.showError('Atenção', 'Ação não permitida.')
      this.loading = false;
      return
    }

    if (this.codcliFunc && !this.cancelarTodos && this.permissao != undefined && this.permissao.length > 0 && this.codfuncEvento.toString() != this.codfunc) {
      this.showError('Atenção', 'Ação não permitida.')
      this.loading = false;
      return
    }

    var evento = Object(this.calendarOptions.events).filter((x: { nuid: number; }) => x.nuid === nuid);
    console.log(evento)
    if (evento[0].origem === 'A') {
      console.log('oi')
      this.confirmationService.confirm({
        message: 'O cancelamento serve somente a caráter de urgência. Se deseja cancelar, entre em contato para informar o ocorrido ao cliente.',
        header: 'Cancelar',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.cancelarAgendamento(nuid);
          this.showEventoAgdEnc = false;
        },
        reject: () => {
        }
      });
    }
    if (evento[0].origem === 'E') {
      this.confirmationService.confirm({
        message: 'O cancelamento serve somente a caráter de urgência. Se deseja cancelar, entre em contato para informar o ocorrido ao cliente.',
        header: 'Cancelar',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.cancelarAgendamento(nuid);
          this.showEventoAgdEnc = false;
        },
        reject: () => {
        }
      });
    }
  }

  confirmaFaltaAgdEnc(nuid: number) {

    /**VERIFICA SE O AGENDAMENTO É ANTERIOR AO DIA DE HOJE */
    const hoje = this.utilitariosService.ajustaDataBanco(this.hoje);
    if (!(this.diaAgd >= hoje!)) {
      this.showError('Atenção', 'Não é permitido marcar falta para agendamentos anteriores ao dia de hoje.')
      return;
    }

    /**VERIFICA SE TEM PERMISSÃO PARA ESSA AÇÃO */
    if (this.codcliFunc && this.permissao != undefined && this.permissao.length > 0 && !this.faltaSi && !this.faltaTodos && this.codfuncEvento.toString() == this.codfunc) {
      this.showError('Atenção', 'Ação não permitida.')
      this.loading = false;
      return
    }

    if (this.codcliFunc && !this.faltaTodos && this.permissao != undefined && this.permissao.length > 0 && this.codfuncEvento.toString() != this.codfunc) {
      this.showError('Atenção', 'Ação não permitida.')
      this.loading = false;
      return
    }

    var evento = Object(this.calendarOptions.events).filter((x: { nuid: number; }) => x.nuid === nuid);
    console.log(evento)
    if (evento[0].origem === 'A') {
      console.log('oi')
      this.confirmationService.confirm({
        message: 'Registrar não comparecimento do cliente ?',
        header: 'Não comparecimento',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.confirmarFalta(nuid);
          this.showEventoAgdEnc = false;
        },
        reject: () => {
        }
      });
    }
    if (evento[0].origem === 'E') {
      this.confirmationService.confirm({
        message: 'Registrar não comparecimento do cliente ?',
        header: 'Não comparecimento',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.confirmarFalta(nuid);
          this.showEventoAgdEnc = false;
        },
        reject: () => {
        }
      });
    }
  }

  /**METODO DE TOAST */
  showError(titulo: string, mensagem: string) {
    if (screen.width < 640 || screen.height < 480) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else if (screen.width < 1024 || screen.height < 768) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    } else {
      this.messageService.add({
        key: 'tr',
        severity: 'error',
        summary: titulo,
        detail: mensagem
      });
    }
  }


  /**COLOCA O NOME DO CLIENTE NO TITULO DO EVENTO E ADICIONA O ICONE DE BAIXA*/
  customEventContent(arg: any) {
    // console.log(arg.event._def.extendedProps.qtdfinpend)
    //   console.log(arg.event._def.extendedProps.origem)
    let iconeEvento;
    let html = '';
    if (arg.event._def.extendedProps.qtdfinpend === null || arg.event._def.extendedProps.origem === 'B') {
      iconeEvento = null;
      html = `
      <div class="event">  
          <div class="flex align-items-center flex-wrap">
              <div class="flex align-items-center justify-content-center">
                    <span class="event-time">${arg.timeText}</span>
                    <span class="event-person"> - ${arg.event._def.extendedProps.description}</span>
               </div>
           </div>
           <ul>
              <li>*${arg.event.title}</li>
            </ul>
      </div>
      `
    }

    if (arg.event._def.extendedProps.qtdfinpend === 0 && arg.event._def.extendedProps.origem !== 'B') {
      iconeEvento = "./assets/financeiroBaixado.svg";

      html = `
      <div class="event">  
          <div class="flex align-items-center flex-wrap justify-content-between">
              <div class="flex align-items-center justify-content-center">
                    <span class="event-time">${arg.timeText}</span>
                    <span class="event-person"> - ${arg.event._def.extendedProps.description}</span>
               </div>
               <div class="flex align-items-center justify-content-end">
                  <span class="event-icon">
                    <img src=${iconeEvento} class="event-icon">
                  </span>
                </div>
          </div>
      </div>
           <ul>
              <li>*${arg.event.title}</li>
           </ul>
      </div>
      `
    }

    if (arg.event._def.extendedProps.qtdfinpend > 0 && arg.event._def.extendedProps.origem !== 'B') {
      iconeEvento = "./assets/financeiroPendente.svg";

      html = `
      <div class="event">  
          <div class="flex align-items-center flex-wrap justify-content-between">
              <div class="flex align-items-center justify-content-center">
                    <span class="event-time">${arg.timeText}</span>
                    <span class="event-person"> - ${arg.event._def.extendedProps.description}</span>
               </div>
               <div class="flex align-items-center justify-content-end">
                  <span class="event-icon">
                    <img src=${iconeEvento} class="event-icon">
                  </span>
                </div>
          </div>
      </div>
           <ul>
              <li>*${arg.event.title}</li>
           </ul>
      </div>
      `
    }

    return {
      html: html
    };
  }


  /**METODOS PARA MOSTRAR AS ABAS DO FULLCALENDAR */
  clickAgrupada() {
    this.aba = true;
    //this.fechado = false;

    this.calendarOptions = {};

    this.optionsAdm();

    this.resourcesprofissional();
    this.buscaEventos();
  }

  clickIndividual() {
    this.aba = false;
    this.calendarOptions = {};
    this.optionsFunc();
    this.resourcesprofissional();
    this.buscaEventos();
    // this.fechado = true;
  }


  /**METODO QUE PERSONALIZA A VISUALIZAÇÃO INDIVIDUAL (IMG+NOME+HORARIO)*/
  customizeDayHeaderContent = (args: any) => {
    const screenWidth = window.innerWidth;
    const dayHeaderText = args.text;
    const view = args.view.type;
    //console.log(args)
    const dayHeaderElement = document.createElement('div');

    const containerElement = document.createElement('div');
    containerElement.classList.add('day-header-container');


    /**filtra nome profissional */
    let profissional = (this.profissionalGeral.filter((x: any) => x.codfunc === this.profissionalGeralSelecionado));
    if (view === 'timeGridDay') {
      const imageElement = document.createElement('img');
      imageElement.src = "./assets/logo.png"; // substitua pelo caminho da sua imagem
      if (profissional[0].filename !== null) {
        imageElement.src = environment.imgProfissional + profissional[0].filename; // substitua pelo caminho da sua imagem
      } else {
        imageElement.src = "./assets/logo.png";
      }
      imageElement.style.width = '41px';
      imageElement.style.height = '41px';
      containerElement.appendChild(imageElement);
    }

    const textContainerElement = document.createElement('div');
    textContainerElement.classList.add('text-container');



    if (view === 'timeGridDay' || screenWidth > 768) {
      const text1Element = document.createElement('div');
      text1Element.classList.add('profissionalIndividual');
      text1Element.classList.add('fontNomeFuncionario'); // Adicione a classe para o primeiro texto
      text1Element.innerText = profissional[0].apelido!;
      textContainerElement.appendChild(text1Element);
    }

    if (view === 'timeGridDay') {
      const text2Element = document.createElement('div');
      text2Element.classList.add('profissionalIndividual');
      text2Element.classList.add('fontNomeHorFuncionario'); // Adicione a classe para o segundo e terceiro texto
      text2Element.innerText = this.horarioTrabalho(profissional[0]);
      textContainerElement.appendChild(text2Element);

      // const text3Element = document.createElement('div');
      // text3Element.classList.add('profissionalIndividual');
      // text3Element.classList.add('fontNomeHorFuncionario'); // Adicione a classe para o segundo e terceiro texto
      // text3Element.innerText = '13:00 - 20:00';
      // textContainerElement.appendChild(text3Element);
    }
    containerElement.appendChild(textContainerElement);

    dayHeaderElement.appendChild(containerElement);

    const textElement = document.createElement('div');
    textElement.innerText = dayHeaderText;
    dayHeaderElement.appendChild(textElement);

    return { domNodes: [dayHeaderElement] };
  };

  /**CALENDARIO AGRUPADO (ADM) */
  optionsAdm() {
    this.calendarOptions = {
      plugins: [dayGridPlugin, interactionGridPlugin, timeGridPlugin, resourceTimeGridPlugin, scrollGridPlugin],
      locale: 'pt-br',
      weekends: true,
      //editable: true,
      longPressDelay: 1, //para touch

      //selectable: true, //serve para poder clicar nas faixas de horario/dia...
      selectMirror: true,
      dayMaxEvents: true,
      slotDuration: '00:15', //parcelas de tempo a ser mostrado
      slotMinTime: '07:00', //hora inicial a ser mostrada
      headerToolbar: {
        left: 'prev,next',
        center: 'title',
        right: 'today,resourceTimeGridDay,resourceTimeGridWeek',
      },

      eventBackgroundColor: "#432611",
      //eventColor: '#378006',
      buttonText: {
        today: 'H',
        month: 'M',
        week: 'S',
        day: 'D',
        list: 'Lista',
      },

      dayHeaderContent: this.renderDayHeaderContent,


      //slotLabelFormat: 'HH:MM',
      allDayText: 'Dia Todo',
      allDaySlot: false, //TIRA O ALLDAY DO LAYOUT
      //columnFormat: 'dd',
      events: [], //this.preencheAgenda(), //AQUI QUE É ATRIBUIDO O EVENTO DO FULLCALENDAR
      select: this.handleDateSelect.bind(this),
      eventClick: this.handleEventClick.bind(this),

      schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
      resources: [],
      eventContent: this.customEventContent.bind(this),
      resourceLabelContent: (arg: any) => {
        console.log(arg.view.type)
        const container = document.createElement('div');
        container.classList.add('resource-container');

        const imageElement = document.createElement('img');
        imageElement.src = arg.resource.extendedProps.image;
        imageElement.classList.add('resource-image');

        const contentContainer = document.createElement('div');
        contentContainer.classList.add('content-container');

        const titleElement = document.createElement('div');
        titleElement.classList.add('resource-title');
        titleElement.innerText = arg.resource.title;

        const hor1Element = document.createElement('div');

        /**SO MOSTRA OS HORARIOS DO FUNCIONARIO NA OPÇÃO DE DIA A DIA */
        if (arg.view.type === 'resourceTimeGridDay') {
          hor1Element.classList.add('additional-text');
          hor1Element.innerText = arg.resource.extendedProps.hor1;
        }

        // const hor2Element = document.createElement('div');
        // hor2Element.classList.add('additional-text');
        // hor2Element.innerText = arg.resource.extendedProps.hor2;

        contentContainer.appendChild(titleElement);
        contentContainer.appendChild(hor1Element);
        //contentContainer.appendChild(hor2Element);

        container.appendChild(imageElement);
        container.appendChild(contentContainer);

        return { html: container.outerHTML };
      },

      initialView: "resourceTimeGridDay",
      dayMinWidth: 200,
      eventMinHeight: 35,
      eventShortHeight: 40,
      agendaEventMinHeight: 100,


      datesSet: function (this: any, info: any) {
        var currentDate = info.start;
        var dayOfWeek = currentDate.getDay();
        console.log('Dia da semana: ' + dayOfWeek);
        this.diaSemana = dayOfWeek;
        console.log(this.diaSemana)
        this.resourcesprofissional();

      }.bind(this),
      datesRender: function (this: any, info: any) {
        var currentDate = info.view.currentStart;
        var dayOfWeek = currentDate.getDay();
        console.log('Dia da semana: ' + dayOfWeek);
        this.diaSemana = dayOfWeek;
        console.log(this.diaSemana)
        this.resourcesprofissional();
      }.bind(this)
    }

  }

  renderDayHeaderContent(arg: any) {
    // const date = arg.date;
    // const options = { weekday: 'long' };
    // const diaDaSemana = date.toLocaleDateString('pt-BR', options);

    // console.log('Dia da semana:', diaDaSemana);
    const date = arg.date;
    const options: any = { weekday: 'long' };
    const locale = 'pt-BR';
    const formatter = new Intl.DateTimeFormat(locale, options);
    const diaDaSemana = formatter.format(date);

    console.log('Dia da semana:', diaDaSemana);
  }
  /**CALENDARIO FUNCIONARIO (INDIVIDUAL) */
  optionsFunc() {
    this.calendarOptions = {
      plugins: [dayGridPlugin, interactionGridPlugin, timeGridPlugin, resourceTimeGridPlugin, scrollGridPlugin],
      locale: 'pt-br',
      weekends: true,
      //editable: true,
      longPressDelay: 1, //para touch

      //selectable: true, //serve para poder clicar nas faixas de horario/dia...
      selectMirror: true,
      dayMaxEvents: true,
      slotDuration: '00:15', //parcelas de tempo a ser mostrado
      slotMinTime: '07:00', //hora inicial a ser mostrada
      headerToolbar: {
        left: 'prev,next',
        center: 'title',
        right: 'today,timeGridDay,dayGridWeek,dayGridMonth',
      },
      initialView: "timeGridDay",
      eventBackgroundColor: "#432611",
      //eventColor: '#378006',
      buttonText: {
        today: 'H',
        month: 'M',
        week: 'S',
        day: 'D',
        list: 'Lista',
      },

      /**SCROLL-HORIZONTAL */
      dayMinWidth: 100,
      stickyFooterScrollbar: true,


      //slotLabelFormat: 'HH:MM',
      allDayText: 'Dia Todo',
      allDaySlot: false, //TIRA O ALLDAY DO LAYOUT
      //columnFormat: 'dd',
      events: [], //this.preencheAgenda(), //AQUI QUE É ATRIBUIDO O EVENTO DO FULLCALENDAR
      select: this.handleDateSelect.bind(this),
      eventClick: this.handleEventClick.bind(this),
      schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',


      dayHeaderContent: this.customizeDayHeaderContent,
      //  dayMinWidth: 200,
      eventMinHeight: 35,
      eventShortHeight: 50,
      eventContent: this.customEventContent.bind(this),

      datesSet: function (this: any, info: any) {
        var currentDate = info.start;
        var dayOfWeek = currentDate.getDay();
        console.log('Dia da semana: ' + dayOfWeek);
        this.diaSemana = dayOfWeek;
        console.log(this.diaSemana)
        this.resourcesprofissional();
      }.bind(this),
      datesRender: function (this: any, info: any) {
        var currentDate = info.view.currentStart;
        var dayOfWeek = currentDate.getDay();
        console.log('Dia da semana: ' + dayOfWeek);
        this.diaSemana = dayOfWeek;
        console.log(this.diaSemana)
        this.resourcesprofissional();
      }.bind(this)
    }
  }



  /**RECUPERA AS PERMISSÕES */
  recuperaPermissao() {
    console.log('recuperei as permissoes')
    // this.permissao = this.permissoesService.getPermissao();


    this.permissoesService.recuperaPermissao(Number(this.codfunc));

    this.permissoesService.permissao$.subscribe((data) => {
      this.permissao = Object(data);
      console.log(this.permissao)



      console.log(this.permissao)
      if (this.permissao != undefined && this.permissao.length > 0) {
        if (this.permissao[11].acao[0].PERMITE === 'S') {
          this.criaCliente = true;
        }
        if (this.permissao[11].acao[1].PERMITE === 'S') {
          this.verTodasAgenda = true;
        }
        if (this.permissao[11].acao[2].PERMITE === 'S') {
          this.agendarSi = true;
        }
        if (this.permissao[11].acao[3].PERMITE === 'S') {
          this.agendarTodos = true;
        }
        if (this.permissao[11].acao[4].PERMITE === 'S') {
          this.encaixarSi = true;
        }
        if (this.permissao[11].acao[5].PERMITE === 'S') {
          this.encaixarTodos = true;
        }
        if (this.permissao[11].acao[6].PERMITE === 'S') {
          this.bloquearSi = true;
        }
        if (this.permissao[11].acao[7].PERMITE === 'S') {
          this.bloquearTodos = true;
        }
        if (this.permissao[11].acao[8].PERMITE === 'S') {
          this.faltaSi = true;
        }
        if (this.permissao[11].acao[9].PERMITE === 'S') {
          this.faltaTodos = true;
        }
        if (this.permissao[11].acao[10].PERMITE === 'S') {
          this.cancelarSi = true;
        }
        if (this.permissao[11].acao[11].PERMITE === 'S') {
          this.cancelarTodos = true;
        }
        /**ATRIBUI O CODCLI VINCULADO NA PERMISSAO DO FUNCIONARIO, PARA FAZER AS OPERAÇÕES DA TELA */
        // this.codcli = this.permissao[11].codcli;
        // console.log(this.codcli)

        this.atribuiCalendarOptions();
      }

      /**SE FOR FUNC E NÃO TIVER PERMISSÕES DE VER TODOS FUNCIONARIOS, BUSCA APENAS SEUS EVENTOS */
      if (!this.verTodasAgenda && this.permissao != undefined && this.permissao.length > 0) {

        /**MONTA O VETOR FAKE APENAS COM AS INFORMAÇÕES NECESSARIAS PARA FAZER AS BUSCAS DOS EVENTOS(codfunc e codemp) E O NOME DO profissional (apelido) */
        this.profissionalGeral = [{
          "apelido": this.apelido, "codfunc": this.codfunc, "codemp": this.permissao[0].codemp,
          "h_dom": this.h_dom, "h_seg": this.h_seg, "h_ter": this.h_ter, "h_qua": this.h_qua,
          "h_qui": this.h_qui, "h_sex": this.h_sex, "h_sab": this.h_sab, "filename": this.fotoProfissional
        }]
        //{ ...this.calendarOptions, events: newEvents! }
        this.profissionalGeralSelecionado = this.profissionalGeral[0].codfunc;
        this.resourcesprofissional();
        console.log(this.profissionalGeralSelecionado);
        this.buscaEventos();
      }
      /**SENÃO BUSCA TODAS AS estabelecimentoS PARA VER TODOS EVENTOS */
      else {
        this.buscarestabelecimentosPorCodCliente();
      }

    })
  }


  /**METODO PARA CADASTRAR USUARIO */
  preencheUsuario() {

    // /**VERIFICA SE TEM PERMISSÃO PARA ESSA AÇÃO */
    // if (this.codcliFunc && this.permissao != undefined && this.permissao.length > 0 && !this.cancelarSi && !this.cancelarTodos && this.codfuncEvento.toString() == this.codfunc) {
    //   this.showError('Atenção', 'Ação não permitida.')
    //   this.loading = false;
    //   return
    // }


    /**VERIFICA SE TEM PERMISSÃO PARA ESSA AÇÃO */
    if (!this.criaCliente && this.permissao != undefined && this.permissao.length > 0) {
      this.showError('Atenção', 'Ação não permitida.')
      return
    }



    /**VERIFICA O TIPO DE USUARIO A SER CADASTRADO/EDITADO */
    if (this.utilitariosService.validaCampo([this.nomeCliente, this.telefoneCliente,])) {
      this.showError('Atenção', 'É necessário preencher todos os campos.')
      return;
    }

    console.log(this.nomeCliente)
    /**RETIRA O ESPAÇO NO INICIO DA STRING, CASO TIVER */
    this.nomeCliente = this.nomeCliente.trimStart();


    this.loginService.createUser(this.nomeCliente, this.emailCliente, this.telefoneCliente, this.utilitariosService.ajustaDataBanco(this.dtNasc), '123456', this.estabelecimentoCadClienteSelecionada, this.cpfCli, environment.CODTIPSEG).subscribe((res) => {
      this.alertService.alert('Cliente cadastrado!', 'success')
      this.loading = false;
      this.showCadastra = false;
      this.recuperaClientes();
      //  console.log(res);
    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
        this.loading = false;
        this.showCadastra = false;
        this.alertService.alert('Algo deu errado.', 'error');
      })
  }



  /**METODO QUE CHAMA O POPUP DE EDIÇÃO DE COMANDA */
  mostraDialogEditaComanda() {
    this.mostraCarregandoServicos = true;
    this.buscarServicoPorCodFuncionario(this.codfuncEvento);

    /**DIALOG DE CARREGANDO... */
    setTimeout(() => {
      this.mostraCarregandoServicos = false;
      this.showEditarServico = true;
    }, 1000);
  }

  fechaDialogEditaComanda() {
    this.showEditarServico = false;
  }

  /**METODO PARA TRANSFORMAR O TEMPO DE SERVIÇO RECEBIDO DO BANCO DE DADOS EM MINUTOS */
  tempoEmMinutos(tempo: any) {
    const [horas, minutos] = tempo.split(':').map(Number);

    const totalMinutos = (horas * 60) + minutos;
    return totalMinutos;
  }

  /**METODO QUE VERIFICA SE O SERVIÇO JÁ ESTÁ OU NÃO NO CABEÇALHO (PARA EDIÇÃO DE COMANDA) */
  verificaServicoCabecalho() {
    console.log(this.servico)
    console.log('↑ SERVIÇOS NORMAIS')
    console.log(this.servicosCabecalho)
    console.log('↑ ESTÁ NA CAB')
    for (let i = 0; i < this.servico.length; i++) {

      this.servico[i].adicionandoServico = false;
      this.servico[i].removendoServico = false;

      let aux = [];
      aux = this.servicosCabecalho.filter((x: any) => x.codprod === this.servico[i].codprod);
      console.log(aux)
      if (aux.length > 0) {

        if (aux[0].nupla) {
          this.servico[i].desabilitaServico = 'S'
          this.servico[i].noCabecalho = 'N'
        } else {
          this.servico[i].desabilitaServico = 'N'
          this.servico[i].noCabecalho = 'S'
        }

      }
      else {
        this.servico[i].noCabecalho = 'N'
        this.servico[i].desabilitaServico = 'N'
      }



    }

    console.log(this.servicosMeusPlanos)
    console.log('↑ SERVIÇOS DO PLANO CLIENTE')
    /**SERVIÇOS DOS PLANOS DO CLIENTE */
    for (let i = 0; i < this.servicosMeusPlanos.length; i++) {

      this.servicosMeusPlanos[i].adicionandoServico = false;
      this.servicosMeusPlanos[i].removendoServico = false;

      let aux = [];
      aux = this.servicosCabecalho.filter((x: any) => x.codprod === this.servicosMeusPlanos[i].codprod);
      console.log(aux)

      if (aux.length > 0) {
        if (aux[0].seq != undefined) {
          if (aux[0].seq != this.servicosMeusPlanos[i].seq) {
            this.servicosMeusPlanos[i].desabilitaServico = 'S'
            this.servicosMeusPlanos[i].noCabecalho = 'N'
          } else {
            this.servicosMeusPlanos[i].desabilitaServico = 'N'
            this.servicosMeusPlanos[i].noCabecalho = 'S'
          }
        }
        else if (this.servicosMeusPlanos[i].status === 'USADO' && this.servicosMeusPlanos[i].codprod === aux[0].codprod && this.servicosMeusPlanos[i].nupla === aux[0].nupla) {
          this.servicosMeusPlanos[i].desabilitaServico = 'N'
          this.servicosMeusPlanos[i].noCabecalho = 'S'
        }
        else {
          this.servicosMeusPlanos[i].noCabecalho = 'N'
          this.servicosMeusPlanos[i].desabilitaServico = 'S'
        }
      }

      else if (this.servicosMeusPlanos[i].status === 'USADO') {
        this.servicosMeusPlanos[i].noCabecalho = 'N'
        this.servicosMeusPlanos[i].desabilitaServico = 'S'
      }
      else {
        this.servicosMeusPlanos[i].noCabecalho = 'N'
        this.servicosMeusPlanos[i].desabilitaServico = 'N'
      }
    }

    /**SERVIÇOS DOS PLANOS DISPONIVEIS */
    for (let i = 0; i < this.servicosPlanosDisponiveis.length; i++) {

      this.servicosPlanosDisponiveis[i].adicionandoServico = false;
      this.servicosPlanosDisponiveis[i].removendoServico = false;

      let aux = [];
      aux = this.servicosCabecalho.filter((x: any) => x.codprod === this.servicosPlanosDisponiveis[i].codprod);
      // console.log(aux)

      if (aux.length > 0) {
        if (aux[0].nupla != undefined) {

          if (aux[0].nupla != this.servicosPlanosDisponiveis[i].nupla) {
            this.servicosPlanosDisponiveis[i].desabilitaServico = 'S'
            this.servicosPlanosDisponiveis[i].noCabecalho = 'N'
          } else {
            this.servicosPlanosDisponiveis[i].desabilitaServico = 'N'
            this.servicosPlanosDisponiveis[i].noCabecalho = 'S'
          }
        }
        else {
          this.servicosPlanosDisponiveis[i].noCabecalho = 'N'
          this.servicosPlanosDisponiveis[i].desabilitaServico = 'S'
        }
      }
      else {
        this.servicosPlanosDisponiveis[i].noCabecalho = 'N'
        this.servicosPlanosDisponiveis[i].desabilitaServico = 'N'
      }
    }
    console.log(this.servicosPlanosDisponiveis)
    console.log('↑ SERVIÇOS DOS PLANOS DISPONIVEIS')

  }


  printa(a: any) {
    console.log(a)
  }


  /**METODO QUE CALCULA O VLRTOTAL E TOTAL DE MINUTOS DE UM CABEÇALHO QUE VAI SER AJUSTADO */
  somaTotalParaAjuste() {
    this.totalAjuste = 0;
    for (let i = 0; i < this.servicosCabecalho.length; i++) {
      this.totalAjuste += this.servicosCabecalho[i].preco!;
    }
    this.totalMinAjuste = 0;
    for (let i = 0; i < this.servicosCabecalho.length; i++) {
      console.log(Number(this.servicosCabecalho[i].tempom!.substr(0, 2)) * 60 + 'HORAS') //HORAS
      console.log(Number(this.servicosCabecalho[i].tempom!.substr(3, 2)) + 'MINUTOS') //MINUTOS
      this.totalMinAjuste += this.tempoEmMinutos(this.servicosCabecalho[i].tempom)
    }

    this.somaPlano();
  }

  // /**METODO QUE SOMA O VALOR DO PLANO NA COMANDA */
  somaPlano() {
    console.log(this.planos)
    if (this.planos.length > 0) {
      for (let i = 0; i < this.planos.length; i++) {
        console.log(this.planos[i])
        this.totalAjuste += this.planos[i].preco!;
      }
    }
    console.log(this.total)
  }

  adicionaPlano(servico: any) {
    let auxPlano = this.planosDisponiveis.filter(x => x.nupla === servico.nupla);
    console.log(auxPlano)
    console.log('↑ PLANOS A ADICIONAR')

    /**VERIFICA SE O PLANO JÁ ESTÁ NO CABEÇALHO, PARA NÃO DAR PUSH NELE NOVAMENTE */
    if (this.planos.filter(x => x.nupla === servico.nupla).length > 0) {
      console.log('não adiciona plano repetido')
      return;
    }

    this.planos.push(auxPlano[0]);

  }

  removePlano(servico: any) {
    console.log(this.planos.filter(x => x.nupla != servico.nupla))
    console.log('↑ PLANOS A REMOVER')
    this.planos = this.planos.filter(x => x.nupla != servico.nupla);
  }

  /**METODO QUE AJUSTA O CABEÇALHO  */
  ajustaJsonCabecalho() {
    // this.utilitariosService.somaMinutos(this.horarioSelecionado, this.totalMin)
    this.novoCabecalho = this.cabecalhoSelecionado;
    this.criaTituloAgendamento(this.servicosCabecalho)

    this.somaTotalParaAjuste();

    let auxJson;
    auxJson = JSON.parse(this.cabecalhoSelecionado[0].json)

    auxJson.horfin = this.utilitariosService.somaMinutos(auxJson.horini, this.totalMinAjuste);
    auxJson.codusuresp = this.codusu;
    auxJson.titulo = this.tituloAgendamento;
    auxJson.vlrtot = this.totalAjuste;
    auxJson.plano = this.planos;

    this.novoCabecalho[0].json = JSON.stringify(auxJson);

  }

  /********************
 * ADICIONAR SERVIÇO *
 *******************/

  /**METODO QUE ADICIONA O SERVIÇO AO JSON DO CABEÇALHO */
  ajustaCabecalhoEncaixa(servico: Servico) {

    this.novoCabecalho = this.cabecalhoSelecionado;
    console.log(this.novoCabecalho)
    let auxJson;

    /**CRIA NOVO TITULO */
    this.servicosCabecalho.push(servico);
    //this.criaTituloAgendamento(this.servicosCabecalho)
    this.ajustaJsonCabecalho();

    /**ATRIBUI O NOVO JSON DE ITENS AO NOVO CABEÇALHO */
    auxJson = JSON.parse(this.cabecalhoSelecionado[0].json)
    console.log(auxJson)
    auxJson.itens = this.servicosCabecalho;
    console.log(auxJson)
    this.novoCabecalho[0].json = JSON.stringify(auxJson)

  }










  /**METODO QUE ADICIONA SERVIÇOS AO AGENDAMENTO */
  adicionaServicoAgendamento(servico: Servico) {
    /**DESCOBRE O INDEX DO SERVIÇO A SER ADICIONADO E ATRIBUI COMO TRUE A VARIAVEL QUE CONTROLA O LOADING */
    let index = this.servico.findIndex(x => x.codprod === servico.codprod);
    this.servico[index].adicionandoServico = true;

    let indexServicoPlanosCliente = this.servicosMeusPlanos.findIndex(x => x.codprod === servico.codprod && x.nuplac === servico.nuplac && x.seq === servico.seq);
    if (servico.nuplac != undefined) {
      this.servicosMeusPlanos[indexServicoPlanosCliente].adicionandoServico = true;
    }

    this.ajustaCabecalhoEncaixa(servico)

    console.log(this.nuidEvento)
    console.log(servico.codprod)
    console.log(this.tituloAgendamento)

    if (servico.nupla === undefined) {
      servico.nupla = null;
    }

    if (servico.nuplac === undefined) {
      servico.nuplac = null;
    }

    if (servico.seq === undefined) {
      servico.seq = null;
    }

    console.log(servico.nupla)
    console.log(servico.nuplac)
    console.log(servico.seq)

    setTimeout(() => {
      this.agendaService.encaixaServicoAgendamento(this.nuidEvento, servico.codprod!, this.novoCabecalho[0].json, this.tituloAgendamento, this.codusu, servico.nupla, servico.nuplac, servico.seq).subscribe((res) => {

        console.log(res);
        this.buscaAgendaPorNuid(res.nuid);

        this.listaPlanosComprados(this.codusuEvento, this.codfuncEvento, this.codempEvento)

        this.servico[index].adicionandoServico = true;

        if (servico.nuplac != undefined) {
          this.servicosMeusPlanos[indexServicoPlanosCliente].adicionandoServico = true;
        }

        servico.adicionandoServico = true

      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.servico[index].adicionandoServico = true;

          if (servico.nuplac != undefined) {
            this.servicosMeusPlanos[indexServicoPlanosCliente].adicionandoServico = true;
          }

          this.alertService.alert('Algo deu errado.', 'error');
        })
    }, 500);
  }



  /********************
   * REMOVER SERVIÇO *
   *******************/

  /**METODO QUE REMOVE O SERVIÇO AO JSON DO CABEÇALHO */
  ajustaCabecalhoRemove(servico: Servico) {
    this.novoCabecalho = this.cabecalhoSelecionado;
    console.log(this.novoCabecalho)
    let auxJson;



    /**REMOVE O SERVIÇO DO CABEÇALHO */
    this.servicosCabecalho = this.servicosCabecalho.filter(x => x.codprod != servico.codprod);


    this.ajustaJsonCabecalho();
    /**CRIA NOVO TITULO */
    //this.criaTituloAgendamento(this.servicosCabecalho)


    /**ATRIBUI O NOVO JSON DE ITENS AO NOVO CABEÇALHO */
    auxJson = JSON.parse(this.cabecalhoSelecionado[0].json)
    console.log(auxJson)

    /**REMOVENDO OS PLANOS QUE NÃO POSSUEM SERVIÇOS */
    auxJson.plano = auxJson.plano.filter((x: any) => this.servicosCabecalho.some((y: any) => y.nupla === x.nupla))

    auxJson.itens = this.servicosCabecalho;
    console.log(auxJson)
    this.novoCabecalho[0].json = JSON.stringify(auxJson)

  }



  /**METODO QUE REMOVE SERVIÇOS AO AGENDAMENTO */
  removeServicoAgendamento(servico: Servico) {

    /**DESCOBRE O INDEX DO SERVIÇO A SER REMOVIDO E ATRIBUI COMO TRUE A VARIAVEL QUE CONTROLA O LOADING */
    let index = this.servico.findIndex(x => x.codprod === servico.codprod);
    this.servico[index].removendoServico = true;

    let indexServicoPlanosCliente = this.servicosMeusPlanos.findIndex(x => x.codprod === servico.codprod && x.nuplac === servico.nuplac && x.seq === servico.seq);
    if (servico.nuplac != undefined) {
      this.servicosMeusPlanos[indexServicoPlanosCliente].removendoServico = true;
    }



    /**NÃO PERMITE O CABEÇALHO FICAR VAZIO */
    if (this.servicosCabecalho.length === 1) {
      this.showError('Atenção', 'O atendimento não pode ficar sem serviços.');
      this.servico[index].removendoServico = false;

      if (servico.nuplac != undefined) {
        this.servicosMeusPlanos[indexServicoPlanosCliente].removendoServico = false;
      }

      return;
    }

    this.ajustaCabecalhoRemove(servico)

    console.log(this.nuidEvento)
    console.log(servico.codprod)
    console.log(this.tituloAgendamento)

    if (servico.nuplac === undefined) {
      servico.nuplac = null;
    }

    if (servico.seq === undefined) {
      servico.seq = null;
    }

    if (servico.nupla === undefined) {
      servico.nupla = null;
    }

    console.log(servico.nuplac)
    console.log(servico.seq)
    console.log(servico.nupla)

    setTimeout(() => {

      this.agendaService.removeServicoAgendamento(this.nuidEvento, servico.codprod!, this.novoCabecalho[0].json, this.tituloAgendamento, this.codusu, servico.nuplac, servico.seq, servico.nupla).subscribe((res) => {

        console.log(res);
        this.buscaAgendaPorNuid(res.nuid);

        this.listaPlanosComprados(this.codusuEvento, this.codfuncEvento, this.codempEvento)

        this.servico[index].removendoServico = false;

        if (servico.nuplac != undefined) {
          this.servicosMeusPlanos[indexServicoPlanosCliente].removendoServico = false;
        }

        servico.removendoServico = false;
        console.log(servico)
      },
        (error) => {
          console.log(error)
          console.log('deu ruim')
          this.alertService.alert('Algo deu errado.', 'error');
          this.servico[index].removendoServico = false;

          if (servico.nuplac != undefined) {
            this.servicosMeusPlanos[indexServicoPlanosCliente].removendoServico = false;
          }
        })
    }, 500);
  }


  // isRemovendo(servico: Servico) {
  //   for (let i = 0; i < this.servico.length; i++) {
  //     if (this.servico[i].codprod === servico.codprod) {
  //       this.servico[i].removendoServico = true;
  //     }
  //   }
  // }

  // isAdicionando(servico: Servico) {
  //   for (let i = 0; i < this.servico.length; i++) {
  //     if (this.servico[i].codprod === servico.codprod) {
  //       this.servico[i].adicionandoServico = true;
  //     }
  //   }
  // }




  /**BUSCA AGENDAMENTO POR NUID */
  buscaAgendaPorNuid(nuid: any) {
    this.agendaService.buscaAgendaByNuid(nuid, this.codusu).subscribe((res) => {
      console.log(res);
      console.log('▓4▓4↑x')
      /**ADICIONA AS INFORMAÇÕES DO CABEÇALHO AO POPUP DE EVENTO */
      this.preenchePopupEvento(res[0])

      // let auxJson = JSON.parse(res[0].json)
      // console.log(auxJson)
      // auxJson.itens = this.servicosCabecalho;


      // this.servicosCabecalho = res;
      this.buscaEventos();
      this.verificaServicoCabecalho();
    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
        this.loading = false;
        this.alertService.alert('Algo deu errado.', 'error');
      })
  }



  /**METODOS QUE CONTROLA A SELEÇÃO DA ABA (SERVIÇOS OU PLANOS) */
  clickServicos() {
    this.selecionouServicos = true;
    this.selecionouPlanos = false;
  }

  clickPlanos() {
    this.selecionouServicos = false;
    this.selecionouPlanos = true;
  }





  /**METODO QUE TRAZ TODOS OS PLANOS DA BARBEARIA */
  listaPlanosDisponiveis(codemp: any, codfunc: any) {
    this.planoService.listaPlanoPorCodemp(codemp, this.codusuEvento).subscribe((res) => {
      console.log(res);
      this.planosDisponiveis = res;

      this.planosDisponiveis = this.transformarVetor(this.planosDisponiveis);


      /**RETIRA OS PLANOS QUE O CLIENTE JÁ COMPROU */
      if (this.meusPlanos.length > 0) {
        for (let i = 0; i < this.meusPlanos.length; i++) {
          this.planosDisponiveis = this.planosDisponiveis.filter(x => x.nupla != this.meusPlanos[i].nupla);
        }
      }

      /**ATRIBUI OS PLANOS NA VARIAVEL DO DROPDOWN */
      this.planosDisponiveisDrop = this.planosDisponiveis;


      this.listaServicosPlanosDisponiveis(codfunc);
    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
      });
  }

  /**METODO QUE CONCATENA A DESCRIÇÃO DE LISTA DOS PLANOS */
  transformarVetor(planos: any) {
    const resultado: any = [];

    planos.forEach((plano: any) => {
      const planoExistente = resultado.find((p: any) => p.nupla === plano.nupla);

      if (planoExistente) {
        const descrprods = plano.descrprods.split(', ').filter((descrprods: any) => !planoExistente.descrprods.includes(descrprods));

        planoExistente.descrprods += `, ${descrprods.join(', ')}`;
      } else {
        resultado.push({ ...plano });
      }
    });

    return resultado;
  }

  /**METODO QUE LISTA OS SERVIÇOS DO PLANO DISPONIVEL QUE O BARBEIRO ATENDE */
  listaServicosPlanosDisponiveis(codfunc: any) {
    this.planoService.listaServicosPlanoPorCodfunc(codfunc).subscribe((res) => {
      console.log(res);
      this.servicosPlanosDisponiveis = res;


      /**SOMA OS MINUTOS DO PLANO */
      for (let i = 0; i < this.servicosPlanosDisponiveis.length; i++) {
        this.servicosPlanosDisponiveis[i].minutos = this.tempoEmMinutos(this.servicosPlanosDisponiveis[i].tempom);
      }
      for (let i = 0; i < this.planosDisponiveis.length; i++) {
        let auxMinutos = 0;
        for (let j = 0; j < this.servicosPlanosDisponiveis.length; j++) {
          if (this.servicosPlanosDisponiveis[j].nupla === this.planosDisponiveis[i].nupla) {
            auxMinutos += this.servicosPlanosDisponiveis[j].minutos;
          }
        }
        this.planosDisponiveis[i].minutos = auxMinutos;
      }

      /**ADICIONA OS SERVIÇOS DO PLANO AO VETOR DO DROPDOWN */
      for (let i = 0; i < this.planosDisponiveisDrop.length; i++) {
        let auxServicos = [];
        for (let j = 0; j < this.servicosPlanosDisponiveis.length; j++) {
          if (this.planosDisponiveisDrop[i].nupla === this.servicosPlanosDisponiveis[j].nupla) {
            auxServicos.push(this.servicosPlanosDisponiveis[j])
          }
        }
        this.planosDisponiveisDrop[i].servicos = auxServicos;
      }
      console.log(this.planosDisponiveisDrop)
      console.log('VETOR COM OS PLANOS E SEUS SERVIÇOS')
    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
      });
  }

  /**METODO QUE LISTA OS SERVIÇOS DO MEU PLANO QUE O BARBEIRO ATENDE */
  listaServicosMeusPlanosDisponiveis(codfunc: any) {
    this.planoService.listaServicosMeusPlanosPorCodfunc(codfunc).subscribe((res) => {
      console.log(res);
      this.servicosMeusPlanos = res;


      // for (let i = 0; i < this.meusPlanos.length; i++) {
      //   for (let j = 0; j < this.meusPlanos[i].itens.length; j++) {
      //     console.log(this.meusPlanos[i].itens[j])

      //     let codprods = this.meusPlanos[i].itens[j].codprods.split(',').map(Number);
      //     //console.log(codprods)
      //     let filtraCodprods = this.servico.filter((x: any) => codprods.includes(x.codprod))
      //     this.servicosMeusPlanos.push(filtraCodprods)
      //   }
      // }

      // console.log(this.servicosMeusPlanos)

      this.servicosMeusPlanos = this.servicosMeusPlanos.filter(x => x.status != null)

      /**AJUSTA OS MINUTOS DOS SERVICOS */
      for (let i = 0; i < this.servicosMeusPlanos.length; i++) {
        this.servicosMeusPlanos[i].minutos = this.tempoEmMinutos(this.servicosMeusPlanos[i].tempom);
        this.servicosMeusPlanos[i].status = this.servicosMeusPlanos[i].status.replace(/\s+/g, '')


        /**DESABILITA OS SERVIÇOS QUE JÁ FORAM USADOS */
        if (this.servicosMeusPlanos[i].status === "USADO") {
          this.servicosMeusPlanos[i].desabilita = true;
        }

      }




      /**ADICIONA OS SERVIÇOS DO PLANO AO VETOR DO DROPDOWN */
      for (let i = 0; i < this.planosClienteDrop.length; i++) {
        let auxServicos = [];
        for (let j = 0; j < this.servicosMeusPlanos.length; j++) {
          if (this.planosClienteDrop[i].nupla === this.servicosMeusPlanos[j].nupla && this.servicosMeusPlanos[j].status === 'LIVRE') {
            auxServicos.push(this.servicosMeusPlanos[j])
          }
        }
        this.planosClienteDrop[i].servicos = auxServicos;
      }
      console.log(this.planosClienteDrop)
      console.log('VETOR COM OS PLANOS DO CLIENTE E SEUS SERVIÇOS')





      //if (this.servicosMeusPlanos.length === 0) {
      //console.log(this.meusPlanos[0].itens)

      this.buscarServicoPorCodFuncionario(codfunc);
      //}


    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
      });
  }



  /**METODO QUE TRAZ TODOS OS PLANOS COMPRADOS */
  listaPlanosComprados(codusu: any, codfunc: any, codemp: any) {
    console.log(this.codusuEvento)
    this.planoService.listaPlanoCompradoPorCodusu(codusu, this.nuidEvento).subscribe((res) => {
      console.log(res);
      this.meusPlanos = res;

      // this.meusPLanos = this.transformarVetor2(this.meusPLanos)
      console.log(this.meusPlanos)

      this.planosClienteDrop = this.meusPlanos;

      /**ATRIBUI OS PLANOS DO CLIENTE NA VARIAVEL DO DROPDOWN */
      this.listaServicosMeusPlanosDisponiveis(codfunc);

      this.listaPlanosDisponiveis(codemp, codfunc);
    },
      (error) => {
        console.log(error)
        console.log('deu ruim')
      });
  }


  printPlanos() {
    console.log(this.planosClienteDropSelecionado)
    console.log('PLANOS DO CLIENTE ↑ §')
    console.log(this.planosDisponiveisDropSelecionado)
    console.log('PLANOS Disponiveis ↑ §')
    console.log(this.servicoSelecionado)
  }


  /**METODO QUE NÃO PERMITE TER SERVIÇOS REPETIDOS NO AGENDAMENTO */
  checaServicos() {
    this.loading = false;
    //servico - planos cliente
    if (this.servicoSelecionado.length > 0 && this.planosClienteDropSelecionado.length > 0) {
      console.log('wregrewg')
      for (let i = 0; i < this.servicoSelecionado.length; i++) {
        for (let j = 0; j < this.planosClienteDropSelecionado.length; j++) {
          if (this.servicoSelecionado[i].codprod === this.planosClienteDropSelecionado[j].codprod) {
            this.showError('Atenção', `Não é permitido ter mais de 1 ${this.planosClienteDropSelecionado[j].nomeprod} no mesmo agendamento!`);
            return true;
          }
        }
      }
    }

    //servico - planos disponiveis
    console.log(this.planosDisponiveisDropSelecionado)
    if (this.servicoSelecionado.length > 0 && this.planosDisponiveisDropSelecionado.length > 0) {
      for (let i = 0; i < this.servicoSelecionado.length; i++) {
        for (let j = 0; j < this.planosDisponiveisDropSelecionado.length; j++) {
          if (this.servicoSelecionado[i].codprod === this.planosDisponiveisDropSelecionado[j].codprod) {
            this.showError('Atenção', `Não é permitido ter mais de 1 ${this.planosDisponiveisDropSelecionado[j].nomeprod} no mesmo agendamento!`);
            return true;
          }
        }
      }
    }

    //planos cliente - planos disponiveis
    if (this.planosClienteDropSelecionado.length > 0 && this.planosDisponiveisDropSelecionado.length > 0) {
      for (let i = 0; i < this.planosClienteDropSelecionado.length; i++) {
        for (let j = 0; j < this.planosDisponiveisDropSelecionado.length; j++) {
          if (this.planosClienteDropSelecionado[i].codprod === this.planosDisponiveisDropSelecionado[j].codprod) {
            this.showError('Atenção', `Não é permitido ter mais de 1 ${this.planosDisponiveisDropSelecionado[j].nomeprod} no mesmo agendamento!`);
            return true;
          }
        }
      }
    }

    //serviços iguais no planos cliente 
    for (let i = 0; i < this.planosClienteDropSelecionado.length; i++) {
      for (let j = i + 1; j < this.planosClienteDropSelecionado.length; j++) {
        if (this.planosClienteDropSelecionado[i].codprod === this.planosClienteDropSelecionado[j].codprod) {
          this.showError('Atenção', `Não é permitido ter mais de 1 ${this.planosClienteDropSelecionado[j].nomeprod} no mesmo agendamento!`);
          return true;
        }
      }
    }

    //serviços iguais no planos disponiveis 
    for (let i = 0; i < this.planosDisponiveisDropSelecionado.length; i++) {
      for (let j = i + 1; j < this.planosDisponiveisDropSelecionado.length; j++) {
        if (this.planosDisponiveisDropSelecionado[i].codprod === this.planosDisponiveisDropSelecionado[j].codprod) {
          this.showError('Atenção', `Não é permitido ter mais de 1 ${this.planosDisponiveisDropSelecionado[j].nomeprod} no mesmo agendamento!`);
          return true;
        }
      }
    }

    return false;
  }

  /**METODO QUE ADICIONA O PLANO NO AGENDAMENTO */
  adicionaPlanoAgd(servicoPlano: any) {
    let auxPlano = []
    let indexPlano = 0;
    console.log(servicoPlano)

    /**ADICIONA OS PLANOS CORRESPONDENTES AO SERVIÇO DE PLANO SELECIONADO */
    for (let i = 0; i < servicoPlano.length; i++) {
      indexPlano = this.planosDisponiveis.findIndex((x) => x.nupla === servicoPlano[i].nupla);
      auxPlano.push(this.planosDisponiveis[indexPlano])
      this.planoAgendamento = auxPlano;
    }

    /**RETIRA OS PLANOS REPETIDOS */
    this.planoAgendamento = [...new Set(this.planoAgendamento)]
    //console.log(uniqueItems)

    //RETIRA O UNDEFINED
    this.planoAgendamento = this.planoAgendamento.filter((x) => x !== undefined);
    console.log(this.planoAgendamento)
  }


}
