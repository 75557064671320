<body class="">


    <div class="pt-7 ">
        <div class="flex justify-content-center flex-wrap pb-3">
            <div lass="flex align-items-center justify-content-center">
                <div class="flex flex-column">
                    <div class="flex align-items-center justify-content-center">
                        <img src="./assets/iconeAgenda.svg" />
                    </div>
                </div>
                <div class=" titulonegrito  flex align-items-center justify-content-center">
                    <span> Minha Agenda</span>
                </div>
            </div>
        </div>


        <div class="col-12">
            <div class=" grid p-flup-2 flex align-items-center justify-content-center">
                <div class="field botaoAbertoFechado">
                    <button id="botaoMA" pButton type="button" label="Em aberto" class="col-12" (click)="clickAberto()"
                        [ngStyle]="aba?{'background-color': 'var(--cor1)', 'color': 'var(--cor7)'} : {'background-color': 'var(--cor2)', 'color': 'var(--cor1)'}"></button>
                </div>
                <div class="field botaoAbertoFechado">
                    <button id="botaoMA" pButton type="button" label="Fechado" class="col-12" (click)="clickFechado()"
                        [ngStyle]="aba?{'background-color': 'var(--cor2)', 'color': 'var(--cor1)'} : {'background-color': 'var(--cor1)', 'color': 'var(--cor7)'}"></button>
                </div>
            </div>
        </div>



        <div class="col-12 sm-12 md-4" *ngIf="aba">


            <div *ngIf="cabecalhoAberto.length > 0 && mostraCarregando===false">
                <!-- <div class="testeScroll "> -->

                <!-- -----------------ABERTO------------------------------------      -->

                <!-- <p-card> -->
                <div class="xl:flex xl:justify-content-center xl:flex-wrap lg:flex lg:justify-content-center lg:flex-wrap md:flex md:justify-content-center
                     md:flex-wrap sm:flex sm:justify-content-center sm:flex-wrap">
                    <div class="xl:col-5 lg:col-5 md:col-5 sm:col-5">
                        <div class="shadow-4">

                            <p-carousel [value]="cabecalhoAberto" [numVisible]="1" [numScroll]="1" [circular]="false"
                                (onPage)="selecionouAgendamento($event)">
                                <ng-template let-cabecalhoAberto pTemplate="item">
                                    <!-- <div *ngFor="let cabecalhoAberto of cabecalhoAberto"> -->
                                    <!-- <div class="item"> -->

                                    <div class=" xl:justify-content-center">

                                        <div class="flex flex-column  align-items-center">
                                            <div class="col">
                                                <div class="fontcinzaMinhaAgenda ">
                                                    <span class="font-bold">Local </span>
                                                    <span>: {{cabecalhoAberto.nome}}</span>
                                                </div>
                                                <div class="fontcinzaMinhaAgenda ">
                                                    <span class="font-bold"> Profissional</span>
                                                    <span>: {{cabecalhoAberto.apelido}}</span>
                                                </div>
                                                <div class="fontcinzaMinhaAgenda ">
                                                    <span class="font-bold"> Duração Média</span>
                                                    <span>: {{cabecalhoAberto.TOTALMIN}} min</span>
                                                </div>
                                                <div class="fontcinzaMinhaAgenda ">
                                                    <span class="font-bold">Serviços</span>
                                                    <span>: {{cabecalhoAberto.TITULO}} </span>
                                                </div>
                                            </div>


                                            <div class="col">
                                                <div class="flex gap-6">
                                                    <div class="fontcinzaMinhaAgenda pb-2 ">
                                                        <div class="flex gap-2">
                                                            <img class="iconeAberto" src="./assets/calendario.svg" />
                                                            <div class="flex align-items-center justify-content-center">
                                                                <span> {{cabecalhoAberto.dia| date:
                                                                    'dd/MM/yyyy'}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="fontcinzaMinhaAgenda pb-2 ">
                                                        <div class="flex gap-2">
                                                            <img class="iconeAberto" src="./assets/relogio.svg" />
                                                            <div class="flex align-items-center justify-content-center">
                                                                <span> {{cabecalhoAberto.horini}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="fontcinzaMinhaAgenda pb-2">
                                                    <div class="flex gap-2">
                                                        <img class="iconeAberto" src="./assets/dol.svg" />
                                                        <div class="flex align-items-center justify-content-center">
                                                            <span> {{cabecalhoAberto.vlrtot | currency : 'BRL'}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <div class="flex flex-column sm:flex-row  align-items-start">
                                                        <div class="fontcinzaMinhaAgenda ">
                                                            <div class="flex gap-2">
                                                                <img class="iconeAberto"
                                                                    src="./assets/localizacao.svg" />
                                                                <div>
                                                                    <span> {{cabecalhoAberto.rua}},
                                                                        {{cabecalhoAberto.complemento}},
                                                                        Nº
                                                                        {{cabecalhoAberto.numero}} </span>
                                                                    <div class="">
                                                                        <span> {{cabecalhoAberto.setor}} -
                                                                            {{cabecalhoAberto.cidade}}</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>{{cabecalhoAberto.contato}}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="flex flex-column align-items-center">
                                        <div class="col">
                                            <div class="fontcinzaMinhaAgenda">
                                                <div class="flex gap-2">
                                                    <p-checkbox [binary]="true" [disabled]="true"
                                                        [(ngModel)]="cabecalhoAberto.RECEBERNOTIFICACAO"></p-checkbox>
                                                    <div class="flex align-items-center justify-content-center">
                                                        <span>Receber alerta do atendimento</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <div class="col-12">
                                        <div class=" formgrid grid align-items-center justify-content-center gap-4">
                                            <button id="botaoMA" pButton type="button" label="Cancelar Atendimento"
                                                class="col-12" [style]="{'width':'144px', 'height': '45px' }"
                                                (click)="confirmaCancelamentoAgd(cabecalhoAberto)"></button>
                                            <button id="botaoMA" pButton type="button" label="Reagendar" class="col-12"
                                                [style]="{'width':'144px', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)'}"
                                                (click)="criaReagendamento(cabecalhoAberto)"></button>
                                        </div>
                                    </div> -->
                                    <!-- </div> -->
                                    <!-- </div> -->
                                </ng-template>
                            </p-carousel>
                            <!-- </p-card> -->
                        </div>
                    </div>
                </div>
                <!-- </div> -->
                <div class="col-12">
                    <div class=" formgrid grid align-items-center justify-content-center gap-4">
                        <button id="botaoMA" pButton type="button" label="Cancelar Atendimento" class="col-12"
                            [style]="{'width':'144px', 'height': '45px' }" (click)="confirmaCancelamentoAgd()"></button>
                        <button id="botaoMA" pButton type="button" label="Reagendar" class="col-12"
                            [style]="{'width':'144px', 'height': '45px', 'background-color': 'var(--cor1)', 'color': 'var(--cor7)'}"
                            (click)="criaReagendamento()"></button>
                    </div>
                </div>
            </div>



        </div>

        <!-- AVISO QUE NÃO TEM AGENDAMENTOS -->

        <div class="flex justify-content-center flex-wrap ">


            <div *ngIf="cabecalhoAberto.length === 0 && mostraCarregando===false && aba" class=" col xl:col-5">
                <div class="flex justify-content-center flex-wrap">
                    <img src="./assets/semAgendamento.png" class="iconeSemAgendamento" />
                </div>
                <div class="flex justify-content-center flex-wrap flex-column">
                    <div class="fontSemAgendamento1 pt-2">
                        <span>Você não tem um agendamento em aberto.</span>
                    </div>
                    <div class="fontSemAgendamento2 flex justify-content-center flex-wrap">
                        <span>Deseja marcar um horário?</span>
                    </div>
                </div>
                <div class="flex justify-content-center flex-wrap pt-2">
                    <button pButton type="button" label="Agendar" class="col-12" id="botaoIrAgendar"
                        (click)="clickAgenda()"></button>
                </div>
            </div>
        </div>

        <!--                          DIALOG DO CARREGANDO               -->
        <p-dialog id="dialogCarregandoMA" [(visible)]="mostraCarregando" [modal]="true"
            [style]="{'width': '150px', 'height': '140px'}" [closable]="false">
            <div class="flex flex-column align-items-center mt-2">
                <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
                    fill="var(--surface-ground)" animationDuration="1s"></p-progressSpinner>
                <div class="fontCarregando">
                    <span>Carregando...</span>
                </div>
            </div>
        </p-dialog>

        <!-- -----------------FECHADO------------------------------------      -->
        <div class="flex flex-column pb-3 " *ngIf="!aba">
            <div class="flex justify-content-center flex-wrap ">
                <div class="scroll-divMinhaAgenda">
                    <table>
                        <tr *ngFor="let cabecalhoFechado of cabecalhoFechado">
                            <td>
                                <div class="col-12 sm-12 md-4 ">
                                    <div class="flex flex-column">
                                        <div class="col tamanhoLinhaTabelaMA">
                                            <div class="formgroup-inline justify-content-between flex-wrap">
                                                <div class="negritofechado">
                                                    <span>Local: {{cabecalhoFechado.nome}}</span>
                                                </div>
                                                <div class="fontcinzafechado  ">
                                                    <div class="flex ">
                                                        <img class="iconeFechado" src="./assets/dol.svg" />
                                                        <div class="flex align-items-center justify-content-center">
                                                            <span>{{cabecalhoFechado.vlrtot | currency :
                                                                'BRL'}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="formgroup-inline justify-content-between flex-wrap">
                                                <div class="fontcinzafechado ">
                                                    <span>Profissional: {{cabecalhoFechado.apelido}}</span>
                                                </div>
                                                <div class="fontcinzafechado ">
                                                    <div class="flex gap-1">
                                                        <img class="iconeFechado" src="./assets/calendario.svg" />
                                                        <div class="flex align-items-center justify-content-center">
                                                            <span>{{cabecalhoFechado.dia| date:
                                                                'dd/MM/yyyy'}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="formgroup-inline justify-content-between flex-wrap">
                                                <div class="fontcinzafechado ">
                                                    <span>Duração Média: {{cabecalhoFechado.TOTALMIN}}
                                                        minutos</span>
                                                </div>
                                                <div class="fontcinzafechado ">
                                                    <div class="flex gap-1">
                                                        <img class="iconeFechado" src="./assets/relogio.svg" />
                                                        <div class="flex align-items-center justify-content-center">
                                                            <span>{{cabecalhoFechado.horini}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="fontcinzafechado ">
                                                <span>Serviços: {{cabecalhoFechado.TITULO}} </span>
                                            </div>


                                            <div class="fontlocalizacao pt-1 ">
                                                <div class="flex gap-2">
                                                    <img class="iconeFechado" src="./assets/localizacao.svg" />
                                                    <div class="flex align-items-center justify-content-center ">
                                                        <span>{{cabecalhoFechado.rua}},
                                                            {{cabecalhoFechado.complemento}}
                                                            ,{{cabecalhoFechado.numero}} {{cabecalhoFechado.setor}}
                                                            -
                                                            {{cabecalhoFechado.cidade}} -
                                                            {{cabecalhoFechado.contato}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="linhahorizontalMinhaAgenda ">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</body>

<!-- DIALOG DE CONFIRMAÇÃO DE CANCELAR AGENDAMENTO -->
<p-confirmDialog #cd [closable]="false" id="confirmCancelAgd">
    <ng-template pTemplate="footer">
        <button type="button" pButton icon="pi pi-times" label="Retornar" (click)="cd.reject()"></button>
        <button type="button" pButton icon="pi pi-check" label="Confirmar" (click)="cd.accept()"></button>
    </ng-template>
</p-confirmDialog>