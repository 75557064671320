import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { DecodedTokenService } from 'src/assets/decoded-token.service';
import { UsuarioService } from 'src/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class AdministradorGuard implements CanActivate {

  codcli: number = 0;
  
  constructor(private decodedTokenService: DecodedTokenService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      this.codcli = Object(this.decodedTokenService.getDecodedAccessToken()).data.CODCLI;

    if (this.codcli > 0) {
      return true;
    } else {
      this.router.navigate([('/')]);
      return false;
    }
  }

}
